import { SetupDiscussionFromRequestComponent } from './../../setup-discussion-from-request/setup-discussion-from-request.component';
import { ViewProfileComponent } from './../../../admin-page/view-profile/view-profile.component';
import { NotificationService } from '../../../../../service/notification.service';
import { UpdateRequestedSessionComponent } from './../../update-requested-session/update-requested-session.component';
import { MatDialog } from '@angular/material/dialog';
import { MentorService } from '../../../../../service/mentor.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { message_properties } from '../../../../../../environments/message_properties';

import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';

import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountsService } from './../../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestsComponent implements OnInit, OnDestroy {
  throttle = 300;
  modalScrollDistance = 1;
  modalScrollThrottle = 50;
  requestForm: UntypedFormGroup;
  viewSharedSpace: boolean = false;
  loggedinUserid: number;
  requestData = [];
  request_count: number;
  current_timezone: string;
  page: number = 2;
  totalPage: number;
  accept_decline_button_show: boolean = true;
  go_btn: boolean = false;
  accept_reject_btn_show: boolean = true;
  handleSubscription: Subscription;
  declineSubscription: Subscription;
  allRequestSubscription: Subscription;
  constructor(
    private _cdr: ChangeDetectorRef,
    private mentorService: MentorService,
    private dialog: MatDialog,
    private toster: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedinUserid = this.accountService.muser;
    this.current_timezone = localStorage.getItem('current_time_zone');

    this.requestForm = this.formBuilder.group({
      member: new UntypedFormControl(this.loggedinUserid),
      select_value: new UntypedFormControl(1),
      page: new UntypedFormControl(1),
    });
    this.getAllRequestToMe(1);
  }

  returnImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }

  updateSession(requestor, requestor_dp, title, request_id, requestor_id) {
    this.dialog.open(UpdateRequestedSessionComponent, {
      disableClose: true,
      width: '60%',
      data: {
        requestor: requestor,
        requestor_dp: requestor_dp,
        title: title,
        ccId: request_id,
        requestor_id: requestor_id,
      },
    });
  }
  handleRequest(
    requestor_full_name,
    requestor_pic,
    requestor_title,
    collab_content_id,
    requestor_id,
    collb_content_type,
    rsvp,
    request
  ) {
    if (rsvp === 'A') {
      if (Number(collb_content_type) !== 2685) {
        this.addSetup(
          0,
          requestor_pic,
          requestor_id,
          requestor_full_name,
          requestor_title,
          collab_content_id,
          collb_content_type,
          rsvp,
          request
        );
      } else {
        this.handleSubscription = this.mentorService
          .updateCollabContent(
            collab_content_id,
            rsvp,
            collb_content_type,
            this.loggedinUserid
          )
          .subscribe((data) => {
            if (data.status === 204) {
              this.toster.showError(data.message, '');
            } else {
              request.active = 7;
              this.accept_reject_btn_show = false;
              this.toster.showSuccess(
                message_properties.MY_GUIDED_EXPLORER_REQUESTS_ACCEPT_SUCCESS,
                ''
              );
              this._cdr.detectChanges();
            }
          });
      }
    }
    if (rsvp === 'D') {
      Swal.fire({
        title: 'Please confirm!',
        text: 'You want to Decline this request.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.declineSubscription = this.mentorService
            .updateCollabContent(
              collab_content_id,
              rsvp,
              '',
              this.loggedinUserid
            )
            .subscribe((data) => {
              this.toster.showSuccess(
                message_properties.MY_GUIDED_EXPLORER_REQUESTS_DECLINED_SUCCESS,
                ''
              );
              this.getAllRequestToMe(1);
              this.dialog.closeAll();
            });
        }
      });
    }
  }
  refrshListFrominitial() {
    this.page = 2;
    this.requestForm.patchValue({
      page: 1,
    });
    this.requestData = [];
  }
  pushValue(ev?) {
    if (this.request_count !== this.requestData.length) {
      this.requestForm.patchValue({
        page: this.page,
      });
      this.getAllRequestToMe();
      this.page++;
    }
  }
  getAllRequestToMe(params = 0) {
    this.spinner.show();
    this.go_btn = true;
    if (params === 1) {
      this.requestForm.patchValue({
        page: 1,
      });
      this.requestData = [];
    }
    this.allRequestSubscription = this.mentorService
      .getRequestToMe(this.requestForm.value)
      .subscribe(
        (data) => {
          this.go_btn = false;
          this.requestData = [
            ...new Map(
              this.requestData
                .concat(data.results)
                .map((item) => [item['id'], item])
            ).values(),
          ];
          this.request_count = data.count;
          this.spinner.hide();
          this._cdr.detectChanges();
        },
        (error) => {
          this.spinner.hide();
          console.error('Technical Issue. Requests not available');
        }
      );
  }
  viewProfile(member_id, first_name, last_name, picture_path) {
    const dialogRef = this.dialog.open(ViewProfileComponent, {
      width: '80%',
      disableClose: true,
      data: {
        member: member_id,
        first_name: first_name,
        last_name: last_name,
        picture_path: picture_path,
        click_from: 'my_guide_explorer_request',
      },
    });
  }

  addSetup(
    eventId,
    requestor_pic,
    requestor_id,
    requestor_full_name,
    requestor_title,
    collab_content_id,
    collb_content_type,
    rsvp,
    request
  ) {
    const dialogRef = this.dialog.open(SetupDiscussionFromRequestComponent, {
      disableClose: true,
      data: {
        eventId: eventId,
        dp: requestor_pic,
        menteeId: requestor_id,
        mentee: requestor_full_name,
        requestor_title: requestor_title,
        collab_content_id: collab_content_id,
        collb_content_type: collb_content_type,
        rsvp: rsvp,
        clicked_from: 'from_guide_accept_request',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getAllRequestToMe(1);
      this._cdr.detectChanges();
    });
  }
  ngOnDestroy() {
    if (this.handleSubscription) {
      this.handleSubscription.unsubscribe();
    }
    if (this.declineSubscription) {
      this.declineSubscription.unsubscribe();
    }
    if (this.allRequestSubscription) {
      this.allRequestSubscription.unsubscribe();
    }
  }
}
