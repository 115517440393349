import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { MentorService } from '../../../../../service/mentor.service';
import { SendMassageComponent } from '../../../admin-page/send-massage/send-massage.component';
import { ViewProfileComponent } from '../../../admin-page/view-profile/view-profile.component';
import { YourEndorseDiscussionListComponent } from '../../endorse-guide/your-endorse-discussion-list/your-endorse-discussion-list.component';
import { MyGuidesReadRequestsMessageComponent } from '../../my-guides-read-requests-message/my-guides-read-requests-message.component';
import { CommonService } from '../../../../../service/common.service';
import { AccountsService } from './../../../../../service/accounts.service';

@Component({
  selector: 'kt-sent-request',
  templateUrl: './sent-request.component.html',
  styleUrls: ['./sent-request.component.scss'],
})
export class SentRequestComponent implements OnInit, OnDestroy {
  past_guide_count: number;
  requestguideForm: UntypedFormGroup;
  throttle = 300;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;
  subscription: Subscription;
  loggedinUserid: number;
  requestData = [];
  count: number;

  page: number = 2;
  constructor(
    private _cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private mentorService: MentorService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private accountsService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedinUserid = this.accountsService.muser;

    this.requestguideForm = this.formBuilder.group({
      member: new UntypedFormControl(this.loggedinUserid),
      content: new UntypedFormControl('notificationRequest'),
      page: 1,
    });

    this.mentorService.requestrefreshneeded.subscribe((data) => {
      this.searchRequestGuide(1);
    });
    this.searchRequestGuide();
  }
  pushValueRequested(ev?) {
    if (this.count !== this.requestData.length) {
      this.requestguideForm.patchValue({
        page: this.page,
      });
      this.searchRequestGuide();
      this.page++;
    }
  }
  searchRequestGuide(params = 0) {
    if (params === 1) {
      this.requestData = [];
      this.page = 2;
      this.requestguideForm.patchValue({
        page: 1,
      });
    }
    const Formdata = this.requestguideForm.value;
    this.spinner.show();
    this.subscription = this.mentorService
      .sentRequestByPost(Formdata)
      .subscribe((data) => {
        this.requestData = [
          ...new Map(
            this.requestData
              .concat(data.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.count = data.count;
        this._cdr.detectChanges();
        this.spinner.hide();
      });
  }
  viewProfile(member_id, connection_data, member_data) {
    let dialogRef = this.dialog.open(ViewProfileComponent, {
      width: '80%',
      disableClose: true,
      data: {
        member_id: member_id,
        connection_data: connection_data,
        member_data: member_data,
        click_from: 'guide_search',
      },
    });
  }
  sendMassage(collabContentId, mentee_id, full_name, profile_picture) {
    this.dialog.open(SendMassageComponent, {
      disableClose: true,
      width: '40%',
      data: {
        collabContentId: collabContentId,
        mentee_id: mentee_id,
        clicked_from: 1008,
        full_name: full_name,
        profile_picture: profile_picture,
      },
    });
  }
  openYourDiscussions() {
    this.dialog.open(YourEndorseDiscussionListComponent, {
      disableClose: true,
      width: '50%',
      data: {
        mentorId: 0,
        clicked_from: 'my_guides',
        isPastGuide: 0,
      },
    });
  }
  messageDetails(requestId, requestActive, message) {
    this.dialog.open(MyGuidesReadRequestsMessageComponent, {
      disableClose: true,
      width: '30%',
      data: {
        message: message,
        requestId: requestId,
        requestActive: requestActive,
      },
    });
  }
  returnImage(image) {
    if (image) {
      return image;
    } else {
      return 'assets/media/users/default.png';
    }
  }

  viewConnectProfile(member_id, first_name, last_name, picture_path) {
    let dialogRef = this.dialog.open(ViewProfileComponent, {
      width: '80%',
      disableClose: true,
      data: {
        member: member_id,
        first_name: first_name,
        last_name: last_name,
        picture_path: picture_path,
        click_from: 'popup',
      },
    });
  }
  checkStatusMember(status) {
    return CommonService.checkMemberStatus(status);
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
