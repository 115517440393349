<kt-spinner></kt-spinner>
<div class="network_section mat-card-style2">
  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-3 sub-card-left">
      <mat-card class="network_card back_card_background_clr">
        <h2 class="title_name1 prof_list_title">Viewing ...</h2>
        <select (change)="getDiscussionsDocsMentors($event.target.value)" class="general_select general_select_quid">
          <option disabled selected value style="display:none"></option>
          <!-- <option [value]="0_0_0_0_0_0_0_0_0_0_0_0_0_0_0">Select Explorer</option> -->
          <ng-container *ngFor="let mentee of menteeData">
            <option [value]="
                mentee.id +
                  ']||[' +
                  mentee.member +
                  ']||[' +
                  mentee.member_profile[0].full_name +
                  ']||[' +
                  mentee.member_profile[0].picture_path +
                  ']||[' +
                  mentee.active +
                  ']||[' +
                  mentee.start_date +
                  ']||[' +
                  mentee.member_address[0]?.city +
                  ']||[' +
                  mentee.member_address[0]?.country + ']||['+jti_for_current_logged_in_user
              " *ngIf="mentee.member == menteeId" selected>
              {{ mentee.member_profile[0].full_name }}
            </option>
            <option [value]="
                mentee.id +
                ']||[' +
                mentee.member +
                ']||[' +
                mentee.member_profile[0].full_name +
                ']||[' +
                mentee.member_profile[0].picture_path +
                ']||[' +
                mentee.active +
                ']||[' +
                mentee.start_date +
                ']||[' +
                mentee.member_address[0]?.city +
                ']||[' +
                mentee.member_address[0]?.country +
                ']||['+jti_for_current_logged_in_user
              " *ngIf="mentee.member != menteeId">
              {{ mentee.member_profile[0].full_name }}
            </option>
          </ng-container>
        </select>
        <button class="btn begin_quiz push_right guid-swich-btn" (click)="showSharedSpace()">
          Switch
        </button>
        <br />
      </mat-card>
      <mat-card class="network_card back_card_background_clr">
        <div class="d-flex justify-content-arround align-items-center">
          <img [src]="menteeDp" class="w-124-pm" />

          <p style="font-size: 24px; font-weight: 600" class="special-char plus-no-border">
            &amp;
          </p>

          <img [src]="profile_path_for_logged_in_user" class="w-124-pm" />
        </div>
        <div class="endor-guid-det text-center">
          <h1 class="fs-24">
            {{ fullName }}
          </h1>
          <h3 class="text-location fs-20">
            {{ city != "null" ? city : "___" }},
            {{ country != "null" ? country : "___" }}
          </h3>
          <p class="space-para fs-20 text-center">
            Guiding since:
            <span class="">{{ start_date | date: "MMM d, y" }}</span>
          </p>
        </div>
        <div class="border-top">
          <div class="d-flex justify-content-end align-items-center">
            <i class="endorse-comment ml-10 mr-1"><img src="./assets/media/icon/Retire@2x.png"
                style="margin-top: 10px; cursor: pointer" class="icon_size" title="Private Notes"
                (click)="openPrivateNotes()" /></i>

            <i class="endorse-comment ml-10 mr-1" (click)="sendMassage(0, menteeId)"><img
                src="./assets/media/icon/Dialog@2x.png"
                style="margin-top: 10px; cursor: pointer;width: 2.5rem;height:2.5rem;;" class="icon_size"
                title="Send Message" /></i>
          </div>
        </div>
      </mat-card>
      <mat-card class="network_card back_card_background_clr">
        <div class="d-flex justify-content-between" (click)="toggledrpDown1()">
          <h2 class="title_name1 prof_list_title">Balance Summary</h2>
          <div class="arrow" [ngClass]="{ active: dropDown1 }">
            <img src="../../../../../../assets/media/icon/Arrow_Black@2x.png" />
          </div>
        </div>
        <hr />
        <div *ngIf="dropDown1">
          <ng-container *ngIf="isPastGuide == 0">
            <p>Available Balance</p>
            <hr />
            <p class="balance-summary-para">
              <span class="cu_communit fs-12">General:</span>
              <span class="cu_community_date fs-12">
                {{ available_general_discussions_time }} Minutes</span>
            </p>

            <p class="balance-summary-para">
              <span class="cu_communit fs-12">Mirror Reflection:</span>

              <span class="cu_community_date fs-12">
                {{ available_guided_reflection_discussions }} Discussion(s)
              </span>
            </p>

            <p class="balance-summary-para"> 
              <span class="cu_communit fs-12">Meaningful Work Survey:</span>

              <span class="cu_community_date fs-12">
                {{ available_guided_mom_discussion }} Discussion(s)</span>
            </p>
          </ng-container>
          <ng-container *ngIf="isPastGuide == 1">
            <p>Completed discussions:</p>
            <p>
              <span class="cu_communit fs-12">General:</span>

              <span class="cu_community_date fs-12">
                {{ completed_guide_discussions }} Minutes</span>
            </p>
            <p>
              <span class="cu_communit fs-12">Mirror Reflection:</span>

              <span class="cu_community_date fs-12">
                {{ completed_mom_discussions }} Discussion(s)
              </span>
            </p>
            <p>
              <span class="cu_communit fs-12">Meaningful Work Survey:</span>

              <span class="cu_community_date fs-12">
                {{ completed_diy_discussions }} Discussion(s)</span>
            </p>
          </ng-container>
        </div>
      </mat-card>
      <mat-card class="network_card back_card_background_clr">
        <div class="d-flex justify-content-between" (click)="toggledrpDown2()">
          <h2 class="title_name1 prof_list_title">Previous guides</h2>
          <div class="arrow" [ngClass]="{ active: dropDown2 }">
            <img src="../../../../../../assets/media/icon/Arrow_Black@2x.png" />
          </div>
        </div>
        <hr />
        <div *ngIf="dropDown2">
          <cdk-virtual-scroll-viewport itemSize="40" minBufferPx="200" maxBufferPx="400"
            class="scroll_class scroll_class_network border-btm-search previous-guides">
            <div *cdkVirtualFor="let guide of pastGuides; templateCacheSize: 0" class="row previus-guid">
              <div class="col-sm-2">
                <div class="share-left-img cursorp"
                (click)="
                viewProfile(
                  guide.relating_member_profile[0].id,
                  guide.relating_member_profile[0].first_name,
                  guide.relating_member_profile[0].last_name,
                  guide.relating_member_profile[0].picture_path ? guide.relating_member_profile[0].picture_path : './assets/media/users/default.png')">
                  <img [src]="
                      returnImage(
                        guide?.relating_member_profile[0].picture_path
                      )
                    " />
                </div>
              </div>
              <div class="col-sm-10">
                <div class="share-right-content">
                  <h1 class="fs-18 cursorp"
                    (click)="
                  viewProfile(
                    guide.relating_member_profile[0].id,
                    guide.relating_member_profile[0].first_name,
                    guide.relating_member_profile[0].last_name,
                    guide.relating_member_profile[0].picture_path ? guide.relating_member_profile[0].picture_path : './assets/media/users/default.png')">
                    {{ guide.relating_member_profile[0].first_name }}
                    {{ guide.relating_member_profile[0].last_name }}
                  </h1>
                  <p>
                    {{ guide.start_date | date: "MMM d, y" }} to
                    {{ guide.end_date | date: "MMM d, y" }}
                  </p>
                </div>
              </div>
            </div>
            <label *ngIf="previous_guide_count == 0">
              <p>No previous Guides!</p>
            </label>
          </cdk-virtual-scroll-viewport>
        </div>
      </mat-card>
    </div>
    <div class="col-lg-9 col-md-9 col-sm-9 sub-card-right">
      <div class="profile_card mb-3 mc_search back_card_background_clr card2-style-height">
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Discussions">
            <kt-discussions [menteeId]="menteeId" [fullName]="fullName" [menteeDp]="menteeDp"
              [member_relationship_id]="member_relationship_id"></kt-discussions>
          </mat-tab>
          <mat-tab label="Documents">
            <kt-endorse-documents [mentorId]="loggedinUserid" [menteeId]="menteeId" [first_name]="first_name"
              [last_name]="last_name" [fullName]="fullName" [guideDp]="guideDp" [menteeDp]="menteeDp"
              [member_relationship_id]="member_relationship_id"></kt-endorse-documents>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>