
<kt-spinner></kt-spinner>
<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h2 class="cmn_title_top">
          Details of the DIY activity on
          {{ data_value | date: "MMM d, y" }}
        </h2>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />

  <table width="100%" border="0">
    <tr>
      <td class="vat" class="label_text" width="33%">
        <b>I want to STOP doing:</b>
      </td>
      <td class="vat" class="label_text" width="33%">
        <b> I want to START doing: </b>
      </td>
      <td class="vat" class="label_text" width="33%">
        <b>I want to CONTINUE doing:</b>
      </td>
    </tr>
    <tr>
      <td colspan="3">&nbsp;</td>
    </tr>
    <tr>
      <td class="scroll_TD">
        <div
          class="form-control wordWrapText"
          readonly
          [innerHTML]="sanitizer.bypassSecurityTrustHtml(stop_doing)"
        ></div>
      </td>
      <td class="scroll_TD">
        <div
          class="form-control wordWrapText"
          readonly
          [innerHTML]="sanitizer.bypassSecurityTrustHtml(start_doing)"
        ></div>
      </td>
      <td class="scroll_TD">
        <div
          class="form-control wordWrapText"
          readonly
          [innerHTML]="sanitizer.bypassSecurityTrustHtml(continue_doing)"
        ></div>
      </td>
    </tr>
    <tr>
      <td colspan="3">&nbsp;</td>
    </tr>
    <tr>
      <td colspan="3" class="label_text">
        <b>My memorable Survey items:</b>
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <div class="view-scroll">
          <div class="mt-1" *ngFor="let ditem of diyItems">
            <p>
              {{
                ditem.question_category +
                  ditem.question_text +
                  ": " +
                  ditem.answer_text.toUpperCase()
              }}
            </p>
            <p>
              {{ ditem.remarks }}
            </p>
          </div>
        </div>
      </td>
    </tr>
  </table>
  <!-- <br /><br /> -->
</div>
