<div id="vedioBg">
  <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
    <source src="./assets/vedio/layer_2.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
</div>
<kt-home-header [back]="true"></kt-home-header>
<div class="contentEach text-align-center">
  This is the core of MeaningSphere and everything we do. It represents the innate drive inside each of us to define and
  find meaning, to matter, and to make a difference in the world to our work and our lives.
</div>
<div class="row">
  <div class="homeBox">
    <div class="innerBox">

      <h1>My Guide</h1>
      <div class="disc">
        This is the core of MeaningSphere and everything we do. It represents the innate drive inside each of us to
        define and find meaning, to matter, and to make a difference in the world to our work and our lives.
      </div>
      <a class="homeBox-link mt-15 float-right"> Find a Guide</a>
      <div class="triangle"><span class="circle"></span></div>
    </div>
  </div>
</div>
<kt-footer-buttons-home></kt-footer-buttons-home>
