import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { message_properties } from '../../../../../../environments/message_properties';
import { NotificationService } from '../../../../../service/notification.service';

@Component({
  selector: 'kt-all-purchase-history',
  templateUrl: './all-purchase-history.component.html',
  styleUrls: ['./all-purchase-history.component.scss'],
})
export class AllPurchaseHistoryComponent implements OnInit {
  purcahse_item_count: any;
  purchase_item_list: any;
  constructor(
    public dialogRef: MatDialogRef<AllPurchaseHistoryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: NotificationService
  ) {
    this.purcahse_item_count = data.purcahse_item_count;
    this.purchase_item_list = data.purchase_item_list;
  }

  ngOnInit(): void {}
  redirectUrl(link_url) {
    if (link_url) {
      return window.open(
        link_url,
        'popUpWindow',
        'height=this.height*.8,width=this.width*.8, left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes'
      );
    } else {
      this.toastr.showWarning(message_properties.INVOICE_NOT_GENERATE, '');
    }
  }
}
