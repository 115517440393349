import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MessageDetailsComponent } from './../message-details/message-details.component';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MessagesService } from '../../../../../service/messages.service';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../../../service/common.service';
import { AccountsService } from './../../../../../service/accounts.service';

@Component({
  selector: 'kt-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss'],
})
export class MessageListComponent implements OnInit, OnDestroy {
  messageSearchForm: UntypedFormGroup;
  loggedInUser: number;
  allRelatingUserMessages: any[];
  count: number;
  creatingUser: number;
  relatingUserSubscription: Subscription;
  countForBroadSubscription: Subscription;
  messageSearchSubscription: Subscription;
  statusChangeSubscription: Subscription;
  messageBroadcastSubscription: Subscription;
  emittable_data: any;
  broadcast_message_count = 0;
  @Output() public getData = new EventEmitter<string>();

  constructor(
    private spinner: NgxSpinnerService,
    private message: MessagesService,
    private _cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
    this.message.messagessListRefreshed.subscribe((data) => {
      this.getAllRelatingUserMessages();
      this.getCountForBrodCast();
    });
    this.getAllRelatingUserMessages();
    this.messageSearchForm = new UntypedFormGroup({
      search_text: new UntypedFormControl(''),
    });
    this.getCountForBrodCast();
  }

  openMessage(selected_member_id) {
    var emittable_data = {
      logged_in_user_id: this.loggedInUser,
      selected_member_id: selected_member_id,
    };
    this.getData.emit(JSON.stringify(emittable_data));
  }
  getAllRelatingUserMessages() {
    this.spinner.show();
    this.relatingUserSubscription = this.message
      .getAllMessages(this.loggedInUser, '')
      .subscribe((data) => {
        this.allRelatingUserMessages = data.results;
        this.count = data.count ? data.count : 0;
        this.spinner.hide();
        this._cdr.detectChanges();
      });
  }
  getCountForBrodCast() {
    this.countForBroadSubscription = this.message
      .getCountAllBroadcast(this.loggedInUser)
      .subscribe((data) => {
        this.broadcast_message_count = data.broadcast_message_count;
        this.spinner.hide();
        this._cdr.detectChanges();
      });
  }
  searchMessage($event, type) {
    let target_value = type === 'e' ? $event.target.value : $event;
    this.allRelatingUserMessages = [];
    this.spinner.show();
    this.messageSearchSubscription = this.message
      .getAllMessages(this.loggedInUser, target_value)
      .subscribe((data) => {
        this.allRelatingUserMessages = data.results;
        this.count = data.count ? data.count : 0;
        this.spinner.hide();
        this._cdr.detectChanges();
      });
  }
  goToChat() {
    this.dialog.open(MessageDetailsComponent, {
      disableClose: true,
      data: {
        groupFor: 2233,
        active: 2,
      },
    });
  }
  change_status(selected_member_id) {
    let formData = {
      logged_in_user: this.loggedInUser,
      receiving_user: selected_member_id,
    };
    this.message
      .message_ChangeReadStatus(formData)
      .subscribe((data) => {
        this._cdr.detectChanges();
      });
  }
  messageReset() {
    this.messageSearchForm.patchValue({
      search_text: '',
    });
    this.getAllRelatingUserMessages();
  }
  change_status_broadcast_message() {
    const data = {
      all_admin_indicator: this.accountService.admin_indicator,
      all_curator_indicator: this.accountService.curator_indicator,
      all_program_director_indicator: this.accountService
        .program_director_indicator,
      all_guide_indicator: localStorage.getItem('all_guide_indicator'),
      all_explorer_indicator: 'all_explorer',
      loggedInMember: this.loggedInUser,
    };
    this.messageBroadcastSubscription = this.message
      .changeReadStatusForBrodCastMessage(data)
      .subscribe((data_) => {});
  }
  checkStatusMember(status) {
    return CommonService.checkMemberStatus(status);
  }
  ngOnDestroy(): void {
    if (this.relatingUserSubscription) {
      this.relatingUserSubscription.unsubscribe();
    }
    if (this.countForBroadSubscription) {
      this.countForBroadSubscription.unsubscribe();
    }
    if (this.messageSearchSubscription) {
      this.messageSearchSubscription.unsubscribe();
    }
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }
    if (this.messageBroadcastSubscription) {
      this.messageBroadcastSubscription.unsubscribe();
    }
  }
}
