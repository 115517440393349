<div class="col-sm-12 pl-0">
  <div class="col-sm-12">
    <h2 class="section-title1 mt-30 mb-25">Do You Have Life-Work Balance?</h2>
    <p class="section-text">
      While the Meaningful Work Survey focuses primarily on work, there are
      three items that go beyond work to capture your overall sense of
      wellbeing and a feeling of having life-work balance. These survey items
      are a useful check against your PMP and Healthy Tension scores.
    </p>
    <p class="section-text pt-10">
      Your wellbeing responses are displayed below. What's your impression of
      your current life-work balance?
    </p>
</div>
</div>