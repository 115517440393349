<ngx-spinner size="small" class="preload-btn colorw" bdColor="transparent" [fullScreen]="false"
  template="<img src='/assets/media/loader.gif'/>">
</ngx-spinner>

<div class="card register_card absolutueCenter lg loginn-card">
  <h1>Now, let's confirm your email</h1>
  <hr />
  <div class="subtitle">
    <p class="fs-18">
      We've sent a code to {{ email }}.<br />
      Please enter that code below to confirm you have<br />
      access to this mail.
    </p>
  </div>

  <form [formGroup]="notificationForm" (ngSubmit)="onSubmit(notificationForm.value)" novalidate>
    <div class="row">
      <div class="col-sm-12 my-3">
        <mat-form-field class="w-100">
          <input matInput type="text" oninput="this.value = this.value.replace(/[^0-9-]|^ /g,'')"
            placeholder="Enter the code here" formControlName="otpcode" autocomplete="false" /><br />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 my-3">
        <button [disabled]="!notificationForm.valid" class="btn my_btn begin_quiz btn_email_varification">
          Verify
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-align-center">
        <span>Didn't receive the code ?
          <a class="cursorp" (click)="sentAgain()"><u>Send again</u></a></span>
      </div>
    </div>
  </form>
</div>