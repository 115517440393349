<kt-spinner></kt-spinner>
<div>
  <button mat-button mat-dialog-close class="close-dialog">
    X
  </button>

  <!-- <mat-dialog-content> -->
  <div class="">
    <div class="profile_section meaningCentralProfil">
      <div class="row">
        <div class="col-sm-12 col-lg-3">
          <!-- Profile User Data -->
          <div class="central-scroll">
            <div class="profile_card back_card_background_clr profile_card-top">
              <div class="dis-prof i-pad-sect-upload">
                <div class="text-align-center w-100 d-flex flex-direction-column align-items-center">
                  <label for="file" class="i-pad-sect-label w-124-pm pos-re">
                    <img alt="Pic" class="w-124-pm" [src]="picPath" />
                  </label>
                  <h1>
                    {{first_name}}
                  </h1>
                  <h2>{{last_name}}</h2>
                  <hr class="hr-5" />
                  <h3>
                    {{ city ? city + "," : "" }}&nbsp;{{ country }}
                  </h3>

                  <span *ngIf="mentor_indicator" class="loc cursorp" (click)="toogleGuideData()"><u>{{
                      guide_visible ? "Explorer Portrait" : "Guide Portrait"
                      }}</u></span>
                  <p class="avail-sect mt-2 mb-2" *ngIf="mentor_indicator && guide_visible">
                    <img src="./assets/media/icon/curator.png" title="" class="img20" />
                    {{ available ? "Available" : "Not Available" }}
                  </p>
                  <div class="dis-prof i-pad-sect-upload prof-vid-edt" *ngIf="mentor_indicator && guide_visible">
                    <div class="vide-ed-cont" *ngIf="available">
                      <ng-container *ngIf="member_to !== loggedinUserid && relationship_count != 1">
                        <p *ngIf="member_to !== guide_id ">
                          <a (click)="sendIntroSessionRequest()" class="cursorp"><strong style="cursor: pointer;">Get
                              Acquainted</strong></a>
                        </p>

                        <p *ngIf="guide_count == 0" style="cursor: pointer;">
                          <a (click)="sendRequestToBeMyGuide()" class="cursorp"><strong style="cursor: pointer;">Request
                              to Be My Guide</strong></a>
                        </p>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Guide Heightlights -->
            <div class="profile_card back_card_background_clr" *ngIf="mentor_indicator && guide_visible">
              <div class="edit_section">
                <table border="0" width="100%">
                  <tr>
                    <td align="left">
                      <h1 class="fs-20">Guide Highlights</h1>
                    </td>
                  </tr>
                </table>
                <hr />
                <div class="multi-collapse" id="multiCollapseExample7">
                  <div class="boxData">
                    <div class="content" *ngIf="member_expertise_count > 0">
                      <small>Years Of Work Experience:
                        <span>{{ no_of_years }}</span>
                      </small>
                      <br /><br />
                      <small>Why I Love Being A Guide:
                        <span class="word_break" [innerHTML]="
                          sanitizer.bypassSecurityTrustHtml(highlight)
                        "></span></small>
                    </div>
                    <span *ngIf="member_expertise_count == 0">
                      I am still working on this...
                    </span>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <!-- What Matters To Me -->
            <div class="profile_card back_card_background_clr">
              <div class="edit_section">
                <table border="0" width="100%">
                  <tr>
                    <td align="left">
                      <h1 class="fs-20">What Matters To Me</h1>
                    </td>
                  </tr>
                </table>
                <hr />
                <div class="multi-collapse whatMattersToMe" id="multiCollapseExample7">
                  <div class="boxData">
                    <div class="content">
                      <ul *ngIf="member_topic_count > 0">
                        <li *ngFor="let topic of member_topic_results">
                          {{ topic.topic_alternate_name }}
                        </li>
                      </ul>
                      <spna *ngIf="member_topic_count == 0">
                        I am still working on this...
                      </spna>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6">
          <div class="central-scroll">
            <!-- Mentoring Experience -->
            <div class="profile_card back_card_background_clr mb-3" *ngIf="mentor_indicator && guide_visible">
              <div class="row">
                <div class="col">
                  <table border="0" width="100%">
                    <tr>
                      <td align="left">
                        <h1 class="fs-20">Related Experience</h1>
                      </td>
                    </tr>
                  </table>
                  <hr />
                  <div class="multi-collapse multi-collapse" id="multiCollapseExample7">
                    <div class="content" *ngIf="member_expertise_count>0">
                      <span class="word_break" [innerHTML]="sanitizer.bypassSecurityTrustHtml(description)">
                      </span>
                    </div>
                    <div class="content" *ngIf="member_expertise_count==0">
                      <span class="word_break">
                        I am still working on this...
                      </span>
                    </div>
                    <div class="content" *ngIf="member_expertise_count>0 && description==''">
                      <span class="word_break">
                        I am still working on this...
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Guide Endorsements -->
            <div class="profile_card back_card_background_clr mb-3"
              *ngIf="mentor_indicator && member_endorsement_count > 0 && guide_visible">
              <div class="row">
                <div class="col">
                  <table border="0" width="100%">
                    <tr>
                      <td align="left">
                        <h1 class="fs-20">Guide Endorsements</h1>
                      </td>
                    </tr>
                  </table>
                  <hr />
                  <div class="scroll_class scroll_class_profile_network">
                    <ng-container *ngIf="member_endorsement_count > 0">
                      <ng-container *ngFor="let endorse of member_endorsement_results">
                        <div class="meaning-log-card">
                          <img alt="" [src]="
                              returnImage(
                                endorse.endorse_picture_path
                              )
                            " class="profile_img" />
                          &nbsp;
                          <span>
                            {{ endorse.endorse_full_name }}
                          </span>
                          <hr />
                          <p class="word_break" [innerHTML]="
                              sanitizer.bypassSecurityTrustHtml(endorse.remarks)
                            "></p>
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="member_endorsement_count == 0">
                      <span>I am still working on this...</span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <!-- Meaningful Work Story -->
            <div class="profile_card back_card_background_clr">
              <div class="row">
                <div class="col">
                  <table border="0" width="100%">
                    <tr>
                      <td align="left">
                        <h1 class="fs-20">Inspiring Work Story</h1>
                      </td>
                    </tr>
                  </table>
                  <hr />
                  <div class="multi-collapse multi-collapse" id="multiCollapseExample7">
                    <div class="boxData">
                      <div class="content">
                        <span class="word_break" *ngIf="member_work_history_count>0 && property_value!=''" [innerHTML]="
                            sanitizer.bypassSecurityTrustHtml(
                              property_value
                            )
                          ">
                        </span>
                        <span class="word_break" *ngIf="member_work_history_count==0">
                          I am still working on this...
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Meaning Full Work Experience -->
            <div class="profile_card back_card_background_clr">
              <div class="row">
                <div class="col">
                  <table border="0" width="100%">
                    <tr>
                      <td align="left">
                        <h1 class="fs-20">Work Highlights</h1>
                      </td>
                    </tr>
                  </table>
                  <hr />
                  <div class="multi-collapse experiance-scroll-chng" id="multiCollapseExample7">
                    <ng-container *ngIf="member_work_history_count > 0">
                      <div class="boxData" *ngFor="let experience of member_employee_experience_results">
                        <div class="content expanded">
                          <span>{{ experience.experience_title }}</span><br />
                          <span>{{ experience.experience_name }}</span><br />
                          <span>{{ experience.experience_location }}</span>
                          <br /><br />
                          <span class="word_break" [innerHTML]="
                              sanitizer.bypassSecurityTrustHtml(
                                experience.experience_workprofile
                              )
                            "></span>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="member_work_history_count == 0">
                      <span> I am still working on this... </span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <!-- Education -->
            <div class="profile_card back_card_background_clr">
              <div class="row">
                <div class="col">
                  <table border="0" width="100%">
                    <tr>
                      <td align="left">
                        <h1 class="fs-20">Education Highlights</h1>
                      </td>
                    </tr>
                  </table>
                  <hr />
                  <div class="multi-collapse experiance-scroll-chng" id="multiCollapseExample7">
                    <ng-container *ngIf="member_employee_education_count > 0">
                      <div class="boxData" *ngFor="let education of member_employee_education_results">
                        <div class="content expanded">
                          <span>
                            {{ education.education_title }} | {{ education.education_start_date }} -
                            {{ education.education_end_date }}</span><br /><br />
                          <span> {{ education.education_name }}</span><br />
                          <span class="word_break" [innerHTML]="
                              sanitizer.bypassSecurityTrustHtml(
                                education.education_location
                              )
                            "></span><br />
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="member_employee_education_count == 0">
                      <span> I am still working on this... </span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <!-- Volunteer Experiance -->
            <div class="profile_card back_card_background_clr">
              <div class="row">
                <div class="col">
                  <table border="0" width="100%">
                    <tr>
                      <td align="left">
                        <h1 class="fs-20">Volunteer Highlights</h1>
                      </td>
                    </tr>
                  </table>
                  <hr />
                  <div class="multi-collapse experiance-scroll-chng" id="multiCollapseExample7">
                    <ng-container *ngIf="member_employee_volunteer_count > 0">
                      <div class="boxData" *ngFor="let volunteer of member_employee_volunteer_results">
                        <div class="content expanded">
                          <span> {{ volunteer.volunteer_title }}</span><br />
                          <span> {{ volunteer.volunteer_name }}</span><br />
                          <span class="word_break" [innerHTML]="
                              sanitizer.bypassSecurityTrustHtml(
                                volunteer.volunteer_location
                              )
                            "></span>
                          <br /><br />
                          <span class="word_break" [innerHTML]="
                              sanitizer.bypassSecurityTrustHtml(
                                volunteer.volunteer_workprofile
                              )
                            "></span>
                          <br />
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="member_employee_volunteer_count == 0">
                      <span> I am still working on this... </span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-lg-3">
          <!-- My Meaning Log -->
          <div class="profile_card imagePosts back_card_background_clr">
            <div class="row">
              <div class="col">
                <table border="0" width="100%">
                  <tr>
                    <td align="left">
                      <h1 class="fs-20">My Meaning Moments</h1>
                    </td>
                  </tr>
                </table>
                <hr />
                <div class="scroll_class scroll_class_profile_network my_treasure">
                  <ng-container *ngIf="member_meaning_moments_count > 0">
                    <ng-container *ngFor="let log of member_meaning_moments_results">
                      <div class="meaning-log-card">
                        <div class="dis-prof i-pad-sect-upload">
                          <div class="row">
                            <div class="col-sm-12">
                              <span class="time">{{
                                log.action_date | date: "MMM d, y"
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="postedData">
                          <span class="title word_break">{{log.meaning_moments_title}}</span>

                          <ng-container *ngIf="log.meaning_moments_video_indicator == 1">
                            <img [src]="
                                log.meaning_moments_attachment_path
                                  ? log.meaning_moments_attachment_path
                                  : './assets/media/default/circle_default.png'
                              " class="constellation_img all_other_img" alt="" />
                          </ng-container>
                          <ng-container *ngIf="log.meaning_moments_video_indicator == 0">
                            <img src="./assets/media/default/video_default.png" class="profile-posdata-img" alt="" />
                          </ng-container>
                          <div class="postedDataContent word_break" [innerHTML]="
                              sanitizer.bypassSecurityTrustHtml(log.meaning_moments_description)
                            "></div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="member_meaning_moments_count == 0">
                    <span>I am still working on this...</span>
                  </ng-container>
                  <hr />
                </div>
                <a *ngIf="member_meaning_moments_count > 0" routerlinkactive="active" class="vieaAll cursorp"
                  ng-reflect-router-link-active="active" (click)="meaninglogList()">
                  View All
                </a>
              </div>
            </div>
          </div>
          <!-- My Treasures -->
          <div class="profile_card imagePosts imagePostsComments back_card_background_clr">
            <div class="row">
              <div class="col">
                <table border="0" width="100%">
                  <tr>
                    <td align="left">
                      <h1 class="fs-20">My Treasures</h1>
                    </td>
                  </tr>
                </table>
                <hr />
                <div class="scroll_class scroll_class_profile_network my_treasure">
                  <ng-container *ngIf="member_my_treasure_count > 0">
                    <ng-container *ngFor="let gem of member_my_treasure_results">
                      <div class="meaning-log-card">
                        <div class="dis-prof i-pad-sect-upload">
                          <div class="row">
                            <div class="col-sm-2">
                              <label for="file" class="i-pad-sect-label pos-relative">
                                <img alt="Pic" class="profile_img" [src]="
                                    gem.my_treasure_created_by_curator_indicator ? './assets/media/users/sphere.png' : gem.my_treasure_picture_path
                                      ? gem.my_treasure_picture_path : './assets/media/users/default.png' " />
                              </label>
                            </div>
                            <div class="col-sm-9">
                              <span class="name">{{gem.my_treasure_created_by_curator_indicator ? "MeaningSphere Editor"
                                : gem.my_treasure_full_name}}</span>
                              <span class="feedTime m-0 fs-14" *ngIf="checkStatus(gem.my_treasure_member_status)">(This Explorer is no longer active)</span>
                              <span class="time">{{
                                gem.action_date | date: "MMM d, y"
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="postedData">
                          <span class="star-text">{{
                            gem.my_treasure_title
                            }}</span>
                          <ng-container *ngIf="gem.my_treasure_video_indicator == 1">
                            <img [src]="
                                gem.my_treasure_attachment_path
                                  ? gem.my_treasure_attachment_path
                                  : './assets/media/default/circle_default.png'
                              " class="constellation_img all_other_img" alt="" />
                          </ng-container>
                          <ng-container *ngIf="gem.my_treasure_video_indicator == 0">
                            <img src="./assets/media/default/video_default.png" alt=""
                              class="constellation_img mt-2 all_other_img" />
                          </ng-container>
                          <p class="postedDataContent word_break" [innerHTML]="
                              sanitizer.bypassSecurityTrustHtml(
                                gem.my_treasure_description
                              )
                            "></p>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="member_my_treasure_count == 0">
                    <span>I am still working on this...</span>
                  </ng-container>
                  <hr />
                </div>
                <a *ngIf="member_my_treasure_count > 0" routerlinkactive="active" class="vieaAll cursorp"
                  ng-reflect-router-link-active="active" (click)="treasuresList()">
                  View All
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </mat-dialog-content> -->

  <!-- <mat-dialog-actions align="end"> -->
  <table align="right">
    <tr>
      <td *ngIf="requested_to !== requested_from">
        <button (click)="removeConnection(requested_to, requested_from)" class="btn begin_quiz" *ngIf="
            member_connect_count_from_popup == 1 && connection_status == 2295
          ">
          Remove Connection
        </button>
      </td>
      <td *ngIf="requested_to !== requested_from">
        <button (click)="getMessageDetails()" class="btn begin_quiz" *ngIf="
            member_connect_count_from_popup == 1 && connection_status == 2296
          ">
          View Message
        </button>
      </td>
      <td *ngIf="requested_to !== requested_from">
        <button (click)="withdrawConnectionRequest(requested_to, requested_from)" class="btn begin_quiz"
          *ngIf="connection_status == 2296 && requested_to !== loggedinUserid">
          Withdraw
        </button>
      </td>
      <td *ngIf="requested_to !== requested_from && connection_status !== 2295">
        <button (click)="acceptConnection(requested_to, requested_from)" class="btn begin_quiz"
          *ngIf="connection_status == 2296 && requested_to == loggedinUserid">
          Accept
        </button>
      </td>
      <td *ngIf="requested_to !== requested_from && connection_status !== 2295">
        <button (click)="declineConnection(requested_to, requested_from)" class="btn begin_quiz"
          *ngIf="connection_status == 2296 && requested_to == loggedinUserid">
          Decline
        </button>
      </td>
      <td *ngIf="
          requested_to !== requested_from &&
          member_connect_count_from_popup == 0 &&
          requested_to !== loggedinUserid
        ">
        <button (click)="requestConnection()" class="btn begin_quiz">
          Request Connection
        </button>
      </td>
      <!-- <td *ngIf="profile_show">
        <button class="btn begin_quiz" (click)="profileEdit()">
          Edit Portrait
        </button>
      </td> -->
      <td>
        <button mat-dialog-close class="btn begin_quiz canceled_btn">
          Cancel
        </button>
      </td>
      <td>&nbsp;</td>
    </tr>
  </table>
  <!-- </mat-dialog-actions> -->
</div>