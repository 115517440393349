import { MentorService } from './../../../../service/mentor.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionService } from '../../../../service/subscription.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CuratorPageService } from '../../../../service/curator-page.service';
import * as moment from 'moment-timezone';
import { TimezoneService } from '../../../../service/timezone.service';
import { DatePipe } from '@angular/common';
import { AccountsService } from './../../../../service/accounts.service';

@Component({
  selector: 'kt-setup-discussion-view',
  templateUrl: './setup-discussion-view.component.html',
  styleUrls: ['./setup-discussion-view.component.scss'],
})
export class SetupDiscussionViewComponent implements OnInit, OnDestroy {
  @Input() clicked_from;
  dialogForm: UntypedFormGroup;
  showAndHide: boolean = false;
  usersdata: any[];
  selected: number;
  groupid: number = 0;
  collabContentCategory: number = 0;
  creatingUser: number;
  titleHeader: string;
  ufullname: string;
  active: number;
  attachment_path: string = '';
  base64textString: string;
  myDate = new Date();
  myTime;
  minTime;
  toTime: boolean = true;
  valueArr: any;
  public value: string[];
  public current: string;
  show_address: boolean = false;
  saveButton: boolean = false;
  user_profile_path: string;
  user_profile_path_data: boolean;
  fileToUpload: File;
  imageUrl: File;
  profile_path: any;
  title: any;
  menteeDp: any;
  mentee: any;
  menteeId: any;
  loggedinUserid: number;
  eventId: number;
  start_date: string;
  GuideServiceList = [];
  guide_service_count: number;
  timeFromval;
  timeToVal;
  start_time: any;
  end_time: any;
  imageSize: number;
  endTime: string;
  count: number;
  momData = [];
  reflectionData = [];
  diyData = [];

  documentType: number = 1;
  containerView: number;
  header_set_up_discussion: string;
  requestor_title: string;
  collab_content_id: number;
  collb_content_type: number;
  rsvp: string;
  current_timezone: string;
  current_time_zone_date: string;
  member_relationship_id: number;
  created_by_guide: boolean = false;
  event_type: number;
  discussion_duration_plus_minus: boolean = false;
  desc_interval_2: string = 'Minute(s)';
  event_creating_member: number;
  purpus_text_box: boolean = false;
  public from_date: moment.Moment;

  guideServiceSubscription: Subscription;
  durationSubscription: Subscription;
  eventTypeSubscription: Subscription;
  showSubscription: Subscription;
  eventDetailSubscription: Subscription;
  catalogSubscription: Subscription;
  sharedSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<SetupDiscussionViewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _cdr: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private curatorService: CuratorPageService,
    private subscriptionservice: SubscriptionService,
    private mentorService: MentorService,
    private timeZoneService: TimezoneService,
    private date_pipe: DatePipe,
    private accountService: AccountsService
  ) {
    this.mentee = data.mentee;
    this.eventId = data.eventId;
    this.menteeDp =
      data.dp !== null || data.dp !== 'null' || data.dp !== undefined
        ? data.dp
        : './assets/media/users/default.png';
    this.menteeId = data.menteeId;
    this.clicked_from = data.clicked_from;
    this.member_relationship_id = data.member_relationship_id;
    this.created_by_guide = data.created_by_guide;
  }

  ngOnInit() {
    this.loggedinUserid = this.accountService.muser;
    this.ufullname = this.accountService.full_name;
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.current_time_zone_date = moment(this.myDate)
      .tz(this.current_timezone)
      .format('M/D/YYYY');

    this.myTime = moment(this.myDate)
      .tz(this.current_timezone)
      .format('hh:mm A');

    this.user_profile_path = localStorage.getItem('image')
      ? localStorage.getItem('image')
      : './assets/media/users/default.png';
    this.dialogForm = this.formBuilder.group({
      name: new UntypedFormControl('New Set Up'),
      description: new UntypedFormControl(
        { value: '', disabled: this.eventId !== 0 ? true : false },
        [Validators.required]
      ),
      virtual_link_address: new UntypedFormControl(
        { value: '', disabled: this.eventId !== 0 ? true : false },
        [Validators.required]
      ),
      max_participant: new UntypedFormControl('1'),
      visibility_type: new UntypedFormControl('1953'),
      event_type: new UntypedFormControl(
        { value: '', disabled: this.eventId !== 0 ? true : false },
        [Validators.required]
      ),
      catalog_item: new UntypedFormControl({
        value: '',
        disabled: this.eventId !== 0 ? true : false,
      }),
      shared_document_id: new UntypedFormControl({
        value: '',
        disabled: this.eventId !== 0 ? true : false,
      }),
      creating_member: new UntypedFormControl(this.loggedinUserid),
      check_uncheck: new UntypedFormControl(true),
      active: new UntypedFormControl(),
      start_date: new UntypedFormControl(
        { value: '', disabled: this.eventId !== 0 ? true : false },
        [Validators.required]
      ),
      timeFrom: new UntypedFormControl(
        { value: '', disabled: this.eventId !== 0 ? true : false },
        [Validators.required]
      ),
      timeTo: new UntypedFormControl('', [Validators.required]),
      duration: new UntypedFormControl(
        { value: '', disabled: this.eventId !== 0 ? true : false },
        [Validators.required]
      ),
      group: this.groupid,
      imageUrl: [''],
      processing_member: new UntypedFormControl(), // guide
      subscribing_member: new UntypedFormControl(), // explorer
      create_from: new UntypedFormControl(this.clicked_from),
      member_relationship_id: new UntypedFormControl(
        this.member_relationship_id
      ),
    });

    this.guideServiceList();
    if (this.eventId !== 0) {
      this.getEventDetailInDialog(0, this.eventId);
    }

    if (this.clicked_from === 'from_explorer') {
      this.header_set_up_discussion = 'Request for a Discussion';
      this.dialogForm.patchValue({
        active: 0,
        processing_member: this.menteeId, // guide
        subscribing_member: this.loggedinUserid, // explorer
      });
    } else {
      this.header_set_up_discussion = 'Set up a Discussion';
      this.dialogForm.patchValue({
        active: 3,
        processing_member: this.loggedinUserid, // guide
        subscribing_member: this.menteeId, // explorer
      });
    }
  }
  durationFilling(param) {
    this.count = 0;
    if (param !== 2) {
      this.dialogForm.patchValue({
        duration: 60,
      });
      this.onChangeFromTime(param);
    } else {
      this.dialogForm.patchValue({
        duration: 15,
      });
      this.onChangeFromTime(param);
    }
    this.durationSubscription = this.mentorService
      .getCatalogEventDetails(param)
      .subscribe((data) => {
        this.event_type = data.event_type;
        this.dialogForm.patchValue({
          event_type: this.event_type,
        });
        if (this.event_type === 2550) {
          this.discussion_duration_plus_minus = true;
          this.reflectionData = [];
          this.eventTypeSubscription = this.mentorService
            .getSharedDiscussionsDocs(
              this.loggedinUserid,
              'Documents',
              this.menteeId,
              'reflection',
              this.member_relationship_id
            )
            .subscribe((data) => {
              this.documentType = 1;
              this.reflectionData = data['results'];
              this.count = this.reflectionData.length;
              this._cdr.detectChanges();
            });
        }
        if (this.event_type === 2554) {
          this.momData = [];
          this.eventTypeSubscription = this.mentorService
            .getSharedDiscussionsDocs(
              this.loggedinUserid,
              'Documents',
              this.menteeId,
              'mom',
              this.member_relationship_id
            )
            .subscribe((data) => {
              this.momData = data['results'];
              this.documentType = 2;
              this.count = this.momData.length;
              this._cdr.detectChanges();
            });
        }
        if (this.event_type === 2556) {
          this.diyData = [];
          this.eventTypeSubscription = this.mentorService
            .getSharedDiscussionsDocs(
              this.loggedinUserid,
              'Documents',
              this.menteeId,
              'diy',
              this.member_relationship_id
            )
            .subscribe((data) => {
              this.diyData = data['results'];
              this.documentType = 3;
              this.count = this.diyData.length;
              this._cdr.detectChanges();
            });
        }
        this._cdr.detectChanges();
      });
  }
  showContainer(param) {
    this.count = 0;
    if (param === 2550) {
      this.momData = [];
      this.showSubscription = this.mentorService
        .getSharedDiscussionsDocs(
          this.loggedinUserid,
          'Documents',
          this.menteeId,
          'mom',
          this.member_relationship_id
        )
        .subscribe((data) => {
          this.documentType = 1;
          this.momData = data['results'];
          this.count = this.momData.length;
          this._cdr.detectChanges();
        });
    }
    if (param === 2554) {
      this.momData = [];
      this.showSubscription = this.mentorService
        .getSharedDiscussionsDocs(
          this.loggedinUserid,
          'Documents',
          this.menteeId,
          'diy',
          this.member_relationship_id
        )
        .subscribe((data) => {
          this.momData = data['results'];
          this.documentType = 2;
          this.count = this.momData.length;
          this._cdr.detectChanges();
        });
    }
    if (param === 2556) {
      this.momData = [];
      this.showSubscription = this.mentorService
        .getSharedDiscussionsDocs(
          this.loggedinUserid,
          'Documents',
          this.menteeId,
          'reflection',
          this.member_relationship_id
        )
        .subscribe((data) => {
          this.momData = data['results'];
          this.documentType = 3;
          this.count = this.momData.length;
          this._cdr.detectChanges();
        });
    }
    this.containerView = param;
  }

  ngOnChanges(changes) {
    this.onChangeFrom(changes);
  }

  onChangeFromTime(event) {
    var timeFrm = this.dialogForm.get('timeFrom').value;
    var durat = this.dialogForm.get('duration').value;
    var timeToVal;
    timeToVal = timeFrm;
    if (timeFrm) {
      if (durat !== null || durat !== '') {
        timeToVal = moment(timeFrm, 'hh:mm A')
          .add(durat, 'minutes')
          .format('hh:mm A');
      }
      this.endTime = timeToVal;
      this.dialogForm.patchValue({
        timeTo: timeToVal,
      });
    }
    this._cdr.detectChanges();
  }
  onChangeFrom(event) {
    var durat = this.dialogForm.get('duration').value;
    var timeFrm = event;
    var timeToVal;

    if (
      (timeFrm !== null || timeFrm !== '') &&
      (durat !== '' || durat !== null)
    ) {
      timeToVal = moment(timeFrm, 'hh:mm A')
        .add(durat, 'minutes')
        .format('hh:mm A');
    } else {
      timeToVal = timeFrm;
    }
    this.endTime = timeToVal;
    this.dialogForm.patchValue({
      timeTo: timeToVal,
    });
    this._cdr.detectChanges();
  }

  returnImage(image) {
    if (image || image !== null) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }
  getEventDetailInDialog(groupId, sessionId) {
    this.eventDetailSubscription = this.curatorService
      .getEventDetailInDialog(groupId, sessionId)
      .subscribe((data) => {
        this.profile_path = data.image_path;
        this.ufullname = JSON.parse(
          JSON.stringify(data.creating_member_details)
        )[0]['full_name'];
        if (
          JSON.parse(JSON.stringify(data.creating_member_details))[0][
            'picture_path'
          ]
        ) {
          this.user_profile_path_data = true;
          this.user_profile_path = JSON.parse(
            JSON.stringify(data.creating_member_details)
          )[0]['picture_path'];
        }
        this.show_address = data.virtual_indicator ? true : false;
        this.start_date = String(data.end_date);
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        var today_date = yyyy + '-' + mm + '-' + dd;

        if (this.start_date === today_date) {
          this.minTime = data.start_time.replace(/(:\d{2}| [AP]M)$/, '');
        }
        this.title = data.name.toString();

        if (data.catalog_item != null) {
          this.catalogSubscription = this.mentorService
            .getCatalogEventDetails(data.catalog_item)
            .subscribe((data) => {
              this.event_type = data.event_type;
              this.dialogForm.patchValue({
                event_type: this.event_type,
              });
              if (this.event_type === 2556) {
                this.reflectionData = [];
                this.sharedSubscription = this.mentorService
                  .getSharedDiscussionsDocs(
                    this.loggedinUserid,
                    'Documents',
                    this.menteeId,
                    'reflection',
                    this.member_relationship_id
                  )
                  .subscribe((data) => {
                    this.documentType = 1;
                    this.purpus_text_box = false;
                    this.reflectionData = data['results'];
                    this.count = this.reflectionData.length;
                    this._cdr.detectChanges();
                  });
              }
              if (this.event_type === 2554) {
                this.momData = [];
                this.sharedSubscription = this.mentorService
                  .getSharedDiscussionsDocs(
                    this.loggedinUserid,
                    'Documents',
                    this.menteeId,
                    'mom',
                    this.member_relationship_id
                  )
                  .subscribe((data) => {
                    this.momData = data['results'];
                    this.documentType = 2;
                    this.purpus_text_box = false;
                    this.count = this.momData.length;
                    this._cdr.detectChanges();
                  });
              }
              if (this.event_type === 2550) {
                this.diyData = [];
                this.sharedSubscription = this.mentorService
                  .getSharedDiscussionsDocs(
                    this.loggedinUserid,
                    'Documents',
                    this.menteeId,
                    'diy',
                    this.member_relationship_id
                  )
                  .subscribe((data) => {
                    this.diyData = data['results'];
                    this.documentType = 3;
                    this.purpus_text_box = true;
                    this.count = this.diyData.length;
                    this._cdr.detectChanges();
                  });
              }
            });
        }
        this.event_creating_member = data.creating_member;
        this.dialogForm.patchValue({
          start_date: new Date(
            this.date_pipe.transform(
              this.dateInUserTimezome(data.start_date),
              'YYYY,MM,dd'
            )
          ),
          timeFrom: moment
            .tz(data.start_date, this.current_timezone)
            .format('hh:mm A'),
          timeTo: moment
            .tz(data.end_date, this.current_timezone)
            .format('hh:mm A'),
          address: data.address,
          createdOn: data.creation_date,
          event_type: data.event_type,
          name: data.name,
          description: data.description,
          max_participant: data.max_participants,
          check_uncheck: data.virtual_indicator === 'true' ? true : false,
          virtual_link_address: data.virtual_indicator
            ? data.virtual_link
            : data.address,
          duration: data.time_duration,
          catalog_item: data.catalog_item,
          shared_document_id: data.shared_document_id,
        });

        this._cdr.detectChanges();
      });
  }
  guideServiceList() {
    this.guideServiceSubscription = this.subscriptionservice
      .catalogListBasisOnOffering(1249)
      .subscribe(
        (data) => {
          this.guide_service_count = data.count;
          this.GuideServiceList = data.results;
          this._cdr.detectChanges();
        },
        (error) => {}
      );
  }
  plus_item(event_type, interval) {
    if (event_type === 2550) {
      var interval_2 = Number(this.dialogForm.value['duration']);
      if (interval_2 >= 240) {
        return;
      }
      this.dialogForm.patchValue({
        duration: interval_2 + interval,
      });
    }
  }
  minus_item(event_type, interval) {
    if (event_type === 2550) {
      var interval_2 = Number(this.dialogForm.value['duration']);
      if (interval_2 === interval) {
        return;
      }
      this.dialogForm.patchValue({
        duration: interval_2 - interval,
      });
    }
  }
  descInternal(event_type) {
    if (event_type === 2550) {
      return this.desc_interval_2;
    }
  }
  dateInUserTimezome(datetime: string) {
    this.from_date = moment(datetime).utc();
    this.timeZoneService.setTenantTimeZone(this.current_timezone);
    return this.timeZoneService.utcToTenantString(this.from_date, 'LL');
  }
  ngOnDestroy(): void {
    if (this.guideServiceSubscription) {
      this.guideServiceSubscription.unsubscribe();
    }
    if (this.durationSubscription) {
      this.durationSubscription.unsubscribe();
    }
    if (this.eventTypeSubscription) {
      this.eventTypeSubscription.unsubscribe();
    }
    if (this.showSubscription) {
      this.showSubscription.unsubscribe();
    }
    if (this.eventDetailSubscription) {
      this.eventDetailSubscription.unsubscribe();
    }
    if (this.sharedSubscription) {
      this.sharedSubscription.unsubscribe();
    }
    if (this.catalogSubscription) {
      this.catalogSubscription.unsubscribe();
    }
  }
}
