import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  NgForm,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { CityStateCountryService } from '../../../../service/city-state-country.service';
import { ReviewCartItemsComponent } from '../review-cart-items/review-cart-items.component';
import { MeaningNetworkService } from './../../../../service/meaning-network.service';
import { UpdateAddressComponent } from './../../../pages/meaning-network/my-settings/update-address/update-address.component';
import {
  FixedSizeVirtualScrollStrategy,
  VIRTUAL_SCROLL_STRATEGY,
} from '@angular/cdk/scrolling';
import { NotificationService } from './../../../../service/notification.service';
import { message_properties } from './../../../../../environments/message_properties';
import { SubscriptionService } from './../../../../service/subscription.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BraintreeComponent } from '../braintree/braintree.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountsService } from './../../../../service/accounts.service';

export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(0, 100, 400);
  }
}

@Component({
  selector: 'kt-review-information',
  templateUrl: './review-information.component.html',
  styleUrls: ['./review-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy },
  ],
})
export class ReviewInformationComponent implements OnInit {
  nameForm = false;
  confirmBillingAddress: UntypedFormGroup;
  billingForm: UntypedFormGroup;
  nameAForm: UntypedFormGroup;
  full_name: string;
  first_name: string;
  last_name: string;
  secondary_city: string;
  secondary_country: string;
  cartdata: any;
  secondary_state: string;

  secondary_line1: string;
  secondary_line2: string;
  secondary_postalcode: string;
  secondary_country_id: string;
  secondary_state_code: string;
  memberid: any;
  all_catalog_str: any;
  billing_full_name: string;
  country_list = false;
  countryList = [];
  countryCount: number;
  countryBox = false;
  final_amount_details_with_tax = [];
  final_amount_details_with_tax_count: number;
  waiting_popup = false;
  transaction_total_amount: number;
  braintreeToken: string;
  address_verify_indicator = false;
  member_account_transaction_id: string;
  total_amount: number;
  address_count: number;
  constructor(
    public dialogRef: MatDialogRef<ReviewInformationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private meaning_network_service: MeaningNetworkService,
    private cdRef: ChangeDetectorRef,
    private cityStateCountry: CityStateCountryService,
    private toastr: NotificationService,
    private subscriptionService: SubscriptionService,
    private spinner: NgxSpinnerService,
    private accountService: AccountsService
  ) {
    this.all_catalog_str = data.all_catalog_str;
    this.memberid = data.member;
    this.full_name = this.accountService.full_name;
    this.total_amount = Number(data.total_amount);
    this.billing_full_name = data.billing_full_name;
  }

  ngOnInit(): void {
    this.memberid = this.accountService.muser;
    this.getProfileDetail();
    this.getSecondaryAddress();
    this.confirmBillingAddress = this.formBuilder.group({
      check_box: new UntypedFormControl('', [Validators.required]),
      billing_country_name: new UntypedFormControl(this.secondary_country_id),
      country_id: new UntypedFormControl(this.secondary_country_id),
    });
    this.countrylist();
    this.nameAForm = this.formBuilder.group({
      billing_full_name: new UntypedFormControl(this.billing_full_name, [
        Validators.required,
      ]),
      member_id: this.memberid,
    });
  }
  getSecondaryAddress() {
    this.spinner.show();
    this.meaning_network_service
      .getProfileNameWithSecondaryAddress(this.memberid)
      .subscribe((data) => {
        this.address_count = data.count;
        this.secondary_city = data.city;
        this.secondary_state = data.state;
        this.secondary_country = data.country;
        this.secondary_line1 = data.line1;
        this.secondary_line2 = data.line2;
        this.secondary_postalcode = data.postal_code;
        this.secondary_country_id = data.country_id;
        this.address_verify_indicator = data.address_verify_indicator;
        this.secondary_state_code = data.state_code
          ? `(${data.state_code})`
          : '';
        this.confirmBillingAddress.patchValue({
          billing_country_name: this.secondary_country,
          country_id: data.country_id,
        });
        this.cdRef.detectChanges();
        this.spinner.hide();
      });
  }

  editName() {
    this.nameForm = !this.nameForm;
  }
  editAddress() {
    const dialogRef = this.dialog.open(UpdateAddressComponent, {
      width: '50%',
      disableClose: true,
      data: {
        memberId: this.memberid,
        secondary_city: this.secondary_city,
        secondary_state: this.secondary_state,
        secondary_country: this.secondary_country,
        secondary_line1: this.secondary_line1,
        secondary_line2: this.secondary_line2,
        secondary_postalcode: this.secondary_postalcode,
        secondary_country_id: this.secondary_country_id,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getProfileDetail();
      this.getSecondaryAddress();
    });
  }

  continueWithZeroAmount(ngForm: NgForm) {
    if (this.address_count > 0) {
      const data = {
        member: this.memberid,
        all_catalog_str: this.all_catalog_str,
        country_id: this.secondary_country_id,
      };
      this.subscriptionService
        .paymentInCartItemsEaServer(data)
        .subscribe((data) => {
          if (data.status === 201) {
            this.toastr.showSuccess(
              message_properties.PAYMENT_MESSAGE_SUCCESS,
              ''
            );
            this.dialog.closeAll();
          } else {
            this.toastr.showError(
              message_properties.PAYMENT_MESSAGE_ERRROR,
              ''
            );
          }
          (error) => {
            this.toastr.showError(
              message_properties.PAYMENT_MESSAGE_ERRROR,
              ''
            );
          };
        });
    } else {
      const dialogRef = this.dialog.open(UpdateAddressComponent, {
        width: '50%',
        disableClose: true,
        data: {
          memberId: this.memberid,
          secondary_city: this.secondary_city,
          secondary_state: this.secondary_state,
          secondary_country: this.secondary_country,
          secondary_line1: this.secondary_line1,
          secondary_line2: this.secondary_line2,
          secondary_postalcode: this.secondary_postalcode,
          secondary_country_id: this.secondary_country_id,
        },
      });
      dialogRef.afterClosed().subscribe(() => {
        this.getProfileDetail();
        this.getSecondaryAddress();
      });
    }
  }

  continue(ngForm: NgForm) {
    if (this.address_verify_indicator && this.address_count > 0) {
      this.saveInTransactiontableAndCallTaxService(ngForm);
      this.dialog.closeAll();
    } else {
      const dialogRef = this.dialog.open(UpdateAddressComponent, {
        width: '50%',
        disableClose: true,
        data: {
          memberId: this.memberid,
          secondary_city: this.secondary_city,
          secondary_state: this.secondary_state,
          secondary_country: this.secondary_country,
          secondary_line1: this.secondary_line1,
          secondary_line2: this.secondary_line2,
          secondary_postalcode: this.secondary_postalcode,
          secondary_country_id: this.secondary_country_id,
        },
      });
      dialogRef.afterClosed().subscribe(() => {
        this.getProfileDetail();
        this.getSecondaryAddress();
      });
    }
  }

  billingFullNameUpdate(ngForm) {
    this.spinner.show();
    this.meaning_network_service
      .billingFullNameUpdate(ngForm)
      .subscribe((data) => {
        this.billing_full_name = data.billing_full_name;
        this.nameForm = false;
        this.cdRef.detectChanges();
        this.spinner.hide();
      });
  }

  getProfileDetail() {
    this.spinner.show();
    this.meaning_network_service
      .getProfileDetails(this.memberid)
      .subscribe((data) => {
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        // this.billing_full_name = data.billing_full_name;
        this.nameAForm.patchValue({
          billing_full_name: this.billing_full_name,
        });
        this.cdRef.detectChanges();
        this.spinner.hide();
      });
  }

  countrylist() {
    this.cityStateCountry.getAllCountryList().subscribe((data) => {
      this.countryList = data.results;
      this.cdRef.detectChanges();
    });
  }
  checkUncheck(event) {
    if (!event.checked) {
      this.confirmBillingAddress.patchValue({
        check_box: '',
      });
    }
  }

  saveInTransactiontableAndCallTaxService(ngForm) {
    this.spinner.show();
    this.cityStateCountry.countryFlagValidation(ngForm).subscribe((data__) => {
      if (data__.status === 204) {
        this.toastr.showError(
          message_properties.COUNTRY_FLAG_VALIDATION_ERROR,
          ''
        );
        this.spinner.hide();
      } else {
        const data__ = {
          member: this.memberid,
          country_id: this.confirmBillingAddress.value.country_id,
          all_catalog_str: this.all_catalog_str,
        };
        this.subscriptionService
          .paymentInCartItems(data__)
          .subscribe((data) => {
            if (data.status === 201) {
              this.final_amount_details_with_tax =
                data.final_amount_details_with_tax;
              this.final_amount_details_with_tax_count =
                data.final_amount_details_with_tax_count;
              this.transaction_total_amount = data.transaction_total_amount;
              this.member_account_transaction_id =
                data.member_account_transaction_id;
              const dialogRef = this.dialog.open(ReviewCartItemsComponent, {
                width: '40%',
                disableClose: true,
                data: {
                  all_catalog_str: this.all_catalog_str,
                  billing_full_name: this.billing_full_name,
                  memberid: this.memberid,
                  city: this.secondary_city,
                  state: this.secondary_state,
                  country: this.secondary_country,
                  line1: this.secondary_line1,
                  line2: this.secondary_line2,
                  postalcode: this.secondary_postalcode,
                  country_id: this.secondary_country_id,
                  secondary_state_code: this.secondary_state_code,
                  final_amount_details_with_tax: this
                    .final_amount_details_with_tax,
                  final_amount_details_with_tax_count: this
                    .final_amount_details_with_tax_count,
                  transaction_total_amount: this.transaction_total_amount,
                  total_amount_pay_through_payment_gateway: btoa(
                    this.transaction_total_amount.toString()
                  ),
                  member_account_transaction_id: btoa(
                    this.member_account_transaction_id
                  ),
                },
              });
              this.spinner.hide();
            } else if (data.status === 202) {
              this.spinner.hide();
              this.toastr.showError(data.message, '');
            }
          });
      }
    });
  }
}
