import { MeaningConstellationService } from '../../../../../service/meaning-constellation.service';
import {
  Component,
  OnInit,
  Optional,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../../../../service/notification.service';
import { message_properties } from '../../../../../../environments/message_properties';
import { MatCheckboxChange } from '@angular/material/checkbox';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AccountsService } from './../../../../../service/accounts.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-meaning-central-flag',
  templateUrl: './meaning-central-flag.component.html',
  styleUrls: ['./meaning-central-flag.component.scss'],
})
export class MeaningCentralFlagComponent implements OnInit, OnDestroy {
  flagForm: UntypedFormGroup;
  collabContentId: number;
  member: number;
  message: string = '';
  flag_data_id: number;
  inappropriate_content_indicator: boolean = false;
  inappropriate_comments_indicator: boolean = false;
  unrelated_content_indicator: boolean = false;
  other_indicator: boolean = false;
  remarks: string;
  valueArr = [];
  action_date_time: string;
  commentId: number = 0;
  action_for: number;
  flag_from: string;
  title: string = '';
  flag_text: string;
  success_text: string;
  createSubscription: Subscription;
  confirmSubscription: Subscription;
  constructor(
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<MeaningCentralFlagComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: NotificationService,
    private meaningConstellation: MeaningConstellationService,
    private _cdr: ChangeDetectorRef,
    private accountService: AccountsService
  ) {
    this.collabContentId = Number(data.collabContentId);
    this.flag_data_id = Number(data.flag_data_id);
    this.inappropriate_content_indicator = data.inappropriate_content_indicator;
    this.unrelated_content_indicator = data.unrelated_content_indicator;
    this.other_indicator = data.other_indicator;
    this.remarks = data.remarks;
    this.action_date_time = data.action_date_time;
    this.commentId = data.commentId;
    this.action_for = data.action_for;
    this.flag_from = data.flag_from;
  }

  ngOnInit(): void {
    this.member = this.accountService.muser;
    this.flagForm = new UntypedFormGroup({
      action_type: new UntypedFormControl(2568),
      action_for: new UntypedFormControl(this.action_for),
      collab_content: new UntypedFormControl(this.collabContentId),
      collab_content_comment: new UntypedFormControl(this.commentId),
      inappropriate_content_indicator: new UntypedFormControl({
        value: this.inappropriate_content_indicator,
        disabled: this.flag_data_id !== 0 ? true : false,
      }),
      unrelated_content_indicator: new UntypedFormControl({
        value: this.unrelated_content_indicator,
        disabled: this.flag_data_id !== 0 ? true : false,
      }),
      other_indicator: new UntypedFormControl({
        value: this.other_indicator,
        disabled: this.flag_data_id !== 0 ? true : false,
      }),
      remarks: new UntypedFormControl(
        {
          value: this.remarks,
          disabled: this.flag_data_id !== 0 ? true : false,
        },
        [Validators.required]
      ),
      member: new UntypedFormControl(this.member),
      tenant: new UntypedFormControl(1),
      flag_from: new UntypedFormControl(this.flag_from),
      check_box: new UntypedFormControl('', [Validators.required]),
    });

    this.title = this.commentId === 0 ? 'Report Content' : 'Report Comment';

    if (this.flag_data_id !== 0 && this.commentId === 0) {
      this.fetchValue();
    }
    if (this.flag_data_id !== 0 && this.commentId !== 0) {
      this.fetchValue();
    }
  }

  onSubmit(ngForm) {
    this.spinner.show();
    this.createSubscription = this.meaningConstellation
      .createAction(ngForm)
      .subscribe(
        (success) => {
          this.toastr.showSuccess(
            message_properties.MEANING_FEEDS_MEANING_CENTRAL_FLAG_SAVE_SUCCESS,
            ''
          );
          this.flagForm.reset();
          this.spinner.hide();
          this.dialogRef.close();
        },
        (error) => {
          this.toastr.showError(
            message_properties.MEANING_FEEDS_MEANING_CENTRAL_FLAG_SAVE_ERROR,
            ''
          );
          this.spinner.hide();
        }
      );
  }

  fetchValue() {
    this.flagForm.patchValue({
      inappropriate_content_indicator: this.inappropriate_content_indicator,
      unrelated_content_indicator: this.unrelated_content_indicator,
      other_indicator: this.other_indicator,
      remarks: this.remarks,
    });
  }

  flagResign(ob: MatCheckboxChange) {
    if (ob.checked) {
      this.valueArr.push(ob.source.value);
    } else {
      this.removeFromArray(this.valueArr, ob.source.value);
    }
    if (this.valueArr.length > 0) {
      this.flagForm.get('check_box').clearValidators();
      this.flagForm.get('check_box').updateValueAndValidity();
    } else {
      this.flagForm.get('check_box').setValidators([Validators.required]);
      this.flagForm.get('check_box').updateValueAndValidity();
    }
  }

  removeFromArray(arr, checked_value = '') {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  }
  unflagPostComment(flag_id, commment_id) {
    if (commment_id === 0) {
      this.flag_text =
        'You have reported this content. Do you want to undo the reporting?';
      this.success_text = 'You have successfully unflagged this content!';
    } else {
      this.flag_text =
        'You have reported this comment. Do you want to undo the reporting?';
      this.success_text = 'You have successfully unflagged this comment!';
    }
    Swal.fire({
      title: 'Please confirm!',
      text: this.flag_text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.confirmSubscription = this.meaningConstellation
          .deleteFlagstatus(
            flag_id,
            this.member,
            commment_id,
            this.collabContentId
          )
          .subscribe((data) => {
            if (data.status === 200) {
              this.toastr.showSuccess(this.success_text, '');
              this._cdr.detectChanges();
            } else {
              this.toastr.showError(
                'We encountered an error. Please try again!',
                ''
              );
              this._cdr.detectChanges();
            }
          });
        this.dialogRef.close();
      }
    });
  }
  ngOnDestroy(): void {
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
  }
}
