<div class="search-results scroll_class card2-style-height-with-tabs-hr-heading" infinite-scroll
  [infiniteScrollDistance]="modalScrollDistance" [infiniteScrollThrottle]="modalScrollThrottle" [scrollWindow]="false"
  (scrolled)="pushValue($event)">
  <ng-container *ngIf="past_count > 0">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-3 card-explorer" *ngFor="let mentee of pastMenteeData;">
        <div class="card archive_card front_card_background_clr cursorp" (click)="
        viewConnectProfile(
              mentee.member_profile[0].id,
              mentee.member_profile[0].first_name,
              mentee.member_profile[0].last_name,
              mentee.member_profile[0].picture_path ? mentee.member_profile[0].picture_path : './assets/media/users/default.png',
              mentee.member_profile[0].member__status)">
          <div class="img_section_mirror text-center">
            <img [src]="returnImage(mentee.member_profile[0].picture_path)" alt="" class="w-124-pm archive_img new-c-photo new-c-photo-guid archive_img {{
                mentee.member_connect_count == 1 ? 'archive_img_border' : ''
              }} curator" />
          </div>

          <div class="title">
            <div class="text_on_image mt-3  member_card_name ">
              <span class="cursorp" (click)="
            viewConnectProfile(
              mentee.member_profile[0].id,
              mentee.member_profile[0].first_name,
              mentee.member_profile[0].last_name,
              mentee.member_profile[0].picture_path ? mentee.member_profile[0].picture_path : './assets/media/users/default.png',
              mentee.member_profile[0].member__status)">
                <h1 class="">
                  {{ mentee.member_profile[0].first_name }}</h1>
                <h2 class="fs-18">
                  {{ mentee.member_profile[0].last_name }}
                </h2>
              </span>

              <h3>
                <small class="memb-small-dec">
                  Guided From: {{ mentee.start_date | date: "MMM d, y" }}<br />
                  Guided To:
                  {{
                  mentee.end_date == "null"
                  ? "Present"
                  : (mentee.end_date | date: "MMM d, y")
                  }}
                </small>
              </h3>
              <h4><small>
                  <!-- Endorsed on:
                  <span *ngIf="mentee?.member_endorsement?.length > 0"> {{mentee?.member_endorsement[0]["creation_date"] | date:"MMM d, y"}}</span><span *ngIf="mentee?.member_endorsement?.length == 0">---</span> -->
                </small>
              </h4>

            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="past_count == 0">
    <table>
      <tbody>
        <tr>
          <td class="p-2">
            <img src="./assets/media/no_data_found.png" class="br-0" />
          </td>
          <td colspan="2" class="p-2">
            You did not have any Guided Explorers in the past!
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
<!-- </cdk-virtual-scroll-viewport> -->