<aside class="meaningsphereAside" [ngClass]="show ? 'subheader_aside mt-5 expand' : 'subheader_aside mt-5'">
  <ul>
    <li>
      <div class="menu-toggle">
        <mat-icon (click)="toggle()" class="mat_icon">arrow_forward</mat-icon>
      </div>
    </li>
    <li>&nbsp;</li>
    <li>
      <div class="main-menu" (click)="explorerSubmenu()">
        <span class="ico explortn" title="Explore"></span>
        <div class="expanded-menu-text" *ngIf="show">
          <span class="menu-lable">Explore</span>
          <span class="ico-arrow" *ngIf="!submenu3">
            <mat-icon style="color: #07a4cf; font-size: 2.25rem">chevron_right</mat-icon>
          </span>
          <span class="ico-arrow" *ngIf="submenu3">
            <mat-icon style="color: #07a4cf; font-size: 2.25rem">expand_more</mat-icon>
          </span>
        </div>
      </div>
      <ul class="sub-menu" *ngIf="submenu3">
        <li [ngClass]="{ active: hasRouteActive(['my-profile']) }"
          (click)="myPortrait(133, name_por, parameter_value_por)">
          <div class="main-menu nav-link" (click)="loadProfile()">
            <span class="ico" title="My Portrait"><img src="./assets/media/sidebar/profile_white.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Portrait</span>
            </div>
          </div>
        </li>
        <li [ngClass]="{ active: hasRouteActive(['meaningful-work-survey']) }"
          (click)="mapOfMeaning(102, name_map, parameter_value_map)">
          <div class="main-menu nav-link">
            <span class="ico" title="Meaningful Work Survey"><img src="./assets/media/sidebar/mom_white.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Meaningful Work Survey</span>
            </div>
          </div>
        </li>
        <li [ngClass]="{ active: hasRouteActive(['meaning-mirror']) }"
          (click)="mirrorList(101, name_mirror, parameter_value_mirror)">
          <div class="main-menu nav-link">
            <span class="ico" title="My Mirror"><img src="./assets/media/sidebar/mirror_white.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Mirror</span>
            </div>
          </div>
        </li>
        <li [ngClass]="{ active: hasRouteActive(['library']) }" (click)="library(122, name_lib, parameter_value_lib)">
          <div class="main-menu nav-link">
            <span class="ico" title="Library"><img src="./assets/media/sidebar/lib_white.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Library</span>
            </div>
          </div>
        </li>
      </ul>
    </li>
    <li>
      <div class="main-menu" (click)="discoverSubmenu()">
        <span class="ico discvry" title="Discover"></span>
        <div class="expanded-menu-text" *ngIf="show">
          <span class="menu-lable">Discover</span>
          <span class="ico-arrow" *ngIf="!submenu2">
            <mat-icon style="color: #a2d45e; font-size: 2.25rem">chevron_right</mat-icon>
          </span>
          <span class="ico-arrow" *ngIf="submenu2">
            <mat-icon style="color: #a2d45e; font-size: 2.25rem">expand_more</mat-icon>
          </span>
        </div>
      </div>
      <ul class="sub-menu" *ngIf="submenu2">
        <li [ngClass]="{
            active: hasRouteActive([
              'discover/my-guides',
              '/discover/search-for-a-guide',
              '/discover/my-guided-explorer-reports',
              '/discover/my-guide/shared-space/'
            ])
          }" (click)="myGuide(100, name_guide, parameter_value_guide)">
          <div class="main-menu nav-link">
            <span class="ico" title="My Guide">
              <img src="./assets/media/sidebar/my_guide_white.png" />
            </span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">My Guide</span>
            </div>
          </div>
        </li>
        <li *ngIf="mentorIndicator" [ngClass]="{
            active: hasRouteActive([
              '/discover/my-guided-explorers',
              '/discover/guide-reports',
              '/discover/my-guided-explorers/shared-space/'
            ])
          }" (click)="myGuideExplorer(134, name_explo, parameter_value_explo)">
          <div class="main-menu nav-link" [routerLink]="'/discover/my-guided-explorers'">
            <span class="ico" title="My Guided Explorers"><img
                src="./assets/media/sidebar/my_gudded_explorer_white.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">My Guided Explorers</span>
            </div>
          </div>
        </li>
        <ng-container *ngIf="diy_show && latestGivenSurveyID>0">
          <li [ngClass]="{
              active: hasRouteActive([
                '/meaningful-work-survey/diy-page/final-report-diy',
                '/meaningful-work-survey/diy-page/first-page',
                '/meaningful-work-survey/diy-page/second-page/',
                '/meaningful-work-survey/diy-page/third-page/',
                '/meaningful-work-survey/diy-page/fourth-page'
              ])
            }" (click)="myDiy(124, name_diy, parameter_value_diy)">
            <div class="main-menu nav-link" [routerLink]="'/meaningful-work-survey/diy-page/final-report-diy'">
              <span class="ico" title="DIY"><img src="./assets/media/sidebar/diy_white.png" /></span>
              <div class="expanded-menu-text" *ngIf="show">
                <span class="menu-lable">DIY</span>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </li>

    <li>
      <div class="main-menu" (click)="collaborateSubmenu()">
        <span class="ico collabrtn" title="Collaborate"></span>
        <div class="expanded-menu-text" *ngIf="show">
          <span class="menu-lable">Connect</span>
          <div class="expanded-menu-text" *ngIf="show">
            <span class="menu-lable">&nbsp;</span>
            <span class="ico-arrow" *ngIf="!submenu4">
              <mat-icon style="color: #e3761f; font-size: 2.25rem">chevron_right</mat-icon>
            </span>
            <span class="ico-arrow" *ngIf="submenu4">
              <mat-icon style="color: #e3761f; font-size: 2.25rem">expand_more</mat-icon>
            </span>
          </div>
        </div>
      </div>
      <ul class="sub-menu" *ngIf="submenu4">
        <li [ngClass]="{ active: hasRouteActive(['meaning-circle']) }"
          (click)="circles(103, name_cir, parameter_value_cir)">
          <div class="main-menu nav-link">
            <span class="ico" title="Circles"><img src="./assets/media/sidebar/circles_white.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Circles</span>
            </div>
          </div>
        </li>
        <li [ngClass]="{ active: hasRouteActive(['meaning-network']) }"
          (click)="network(104, name_net, parameter_value_net)">
          <div class="main-menu nav-link">
            <span class="ico" title="Network"><img src="./assets/media/sidebar/meaning_central_white.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Network</span>
            </div>
          </div>
        </li>
        <li [ngClass]="{ active: hasRouteActive(['meaning-communities']) }"
          (click)="community(105, name_com, parameter_value_com)">
          <div class="main-menu nav-link">
            <span class="ico" title="Communities"><img src="./assets/media/sidebar/community_white.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Communities</span>
            </div>
          </div>
        </li>
      </ul>
    </li>

    <!-- </ul>
  <ul class="buttomFix"> -->

    <li>&nbsp;</li>
    <li>&nbsp;</li>
    <li *ngIf="adminIndicator">
      <div class="main-menu" (click)="adminSubmenu()">
        <span class="ico admin-space" title="Admin Space"></span>
        <div class="expanded-menu-text" *ngIf="show">
          <span class="menu-lable">Admin Space</span>
          <span class="ico-arrow" *ngIf="!submenu5">
            <mat-icon style="color: #ededf2cc; font-size: 2.25rem">chevron_right</mat-icon>
          </span>
          <span class="ico-arrow" *ngIf="submenu5">
            <mat-icon style="color: #ededf2cc; font-size: 2.25rem">expand_more</mat-icon>
          </span>
        </div>
      </div>
      <ul class="sub-menu" *ngIf="submenu5">
        <li [ngClass]="{
            active: hasRouteActive(['admin-page/member-list-with-search'])
          }">
          <div class="main-menu" (click)="manageAdmin()">
            <span class="ico" title="Manage Explorer"><img src="./assets/menu/explorer.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Manage Explorers</span>
            </div>
          </div>
        </li>
        <li [ngClass]="{active: hasRouteActive(['admin-page/manage-deleted-user'])}">
          <div class="main-menu" [routerLink]="'/admin-page/manage-deleted-user'">
            <span class="ico" title="Manage Deleted User"><img src="./assets/menu/NoAccountsRounded.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Manage Deleted Explorers</span>
            </div>
          </div>
        </li>
        <li [ngClass]="{
            active: hasRouteActive(['/admin-page/manage-templates'])
          }">
          <div class="main-menu" [routerLink]="'/admin-page/manage-templates'">
            <span class="ico" title="Manage Templates"><img src="./assets/menu/template.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Manage Templates</span>
            </div>
          </div>
        </li>
        <li [ngClass]="{active: hasRouteActive(['admin-page/manage-country-list'])}">
          <div class="main-menu" [routerLink]="'/admin-page/manage-country-list'">
            <span class="ico" title="Manage Country"><img src="./assets/menu/VpnLockRounded.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Manage Country</span>
            </div>
          </div>
        </li>
        <li [ngClass]="{active: hasRouteActive(['admin-page/manage-catalog-price-list'])}">
          <div class="main-menu" [routerLink]="'/admin-page/manage-catalog-price-list'">
            <span class="ico" title="Manage Price"><img src="./assets/menu/PriceChangeRounded.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Manage Price</span>
            </div>
          </div>
        </li>
      </ul>
    </li>
    <li *ngIf="curatorIndicator">
      <div class="main-menu" (click)="curatorSubmenu()">
        <span class="ico curator-space" title="Curator Space"></span>
        <div class="expanded-menu-text" *ngIf="show">
          <span class="menu-lable">Curator Space</span>
          <span class="ico-arrow" *ngIf="!submenu6">
            <mat-icon style="color: #ededf2cc; font-size: 2.25rem">chevron_right</mat-icon>
          </span>
          <span class="ico-arrow" *ngIf="submenu6">
            <mat-icon style="color: #ededf2cc; font-size: 2.25rem">expand_more</mat-icon>
          </span>
        </div>
      </div>
      <ul class="sub-menu" *ngIf="submenu6">
        <li [ngClass]="{
            active: hasRouteActive(['curator-page/manage-library'])
          }">
          <div class="main-menu" [routerLink]="'/curator-page/manage-library'">
            <span class="ico" title="Library"><img src="./assets/media/sidebar/lib_white.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Library</span>
            </div>
          </div>
        </li>
        <li [ngClass]="{
            active: hasRouteActive(['curator-page/manage-meaning-central'])
          }">
          <div class="main-menu" [routerLink]="'/curator-page/manage-meaning-central'">
            <span class="ico" title="Meaning central"><img
                src="./assets/media/sidebar/meaning_central_white.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Network</span>
            </div>
          </div>
        </li>
        <li [ngClass]="{
            active: hasRouteActive([
              'curator-page/manage-community',
              '/view-community'
            ])
          }">
          <div class="main-menu" [routerLink]="'/curator-page/manage-community'">
            <span class="ico" title="Community"><img src="./assets/media/sidebar/community_white.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Communities</span>
            </div>
          </div>
        </li>
        <li [ngClass]="{
            active: hasRouteActive([
              'curator-page/manage-circles',
              '/view-circle'
            ])
          }">
          <div class="main-menu" [routerLink]="'/curator-page/manage-circles'">
            <span class="ico" title="Circles"><img src="./assets/media/sidebar/circles_white.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable"> Circles </span>
            </div>
          </div>
        </li>
        <li [ngClass]="{
            active: hasRouteActive(['curator-page/flagged-posts-report'])
          }">
          <div class="main-menu" [routerLink]="'/curator-page/flagged-posts-report'">
            <span class="ico" title="Flagged content"><img src="./assets/menu/flagged_content.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Flagged Posts </span>
            </div>
          </div>
        </li>
        <li [ngClass]="{
            active: hasRouteActive(['curator-page/flagged-comments-report'])
          }">
          <div class="main-menu" [routerLink]="'/curator-page/flagged-comments-report'">
            <span class="ico" title="Flagged content"><img src="./assets/menu/flagged_content.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Flagged Comments </span>
            </div>
          </div>
        </li>
        <li [ngClass]="{ active: hasRouteActive(['curator-page/topic-list']) }">
          <div class="main-menu" [routerLink]="'/curator-page/topic-list'">
            <span class="ico" title="Topics"><img src="./assets/media/sidebar/topic.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Mirror Topics </span>
            </div>
          </div>
        </li>
        <li [ngClass]="{
            active: hasRouteActive(['curator-page/meaning-clasification'])
          }">
          <div class="main-menu" [routerLink]="'/curator-page/meaning-clasification'">
            <span class="ico" title="Classification"><img src="./assets/media/sidebar/classification.png"
                class="classifi" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Topics</span>
            </div>
          </div>
        </li>
      </ul>
    </li>
    <!-- curatorIndicator={{curatorIndicator}} -->
    <li *ngIf="programDirectorIndicator">
      <div class="main-menu" (click)="guideprogrameSubmenu()">
        <span class="ico program-director-space" title="Guide Program"></span>
        <div class="expanded-menu-text" *ngIf="show">
          <span class="menu-lable">Guide Program</span>
          <span class="ico-arrow" *ngIf="!submenu7">
            <mat-icon style="color: #ededf2cc; font-size: 2.25rem">chevron_right</mat-icon>
          </span>
          <span class="ico-arrow" *ngIf="submenu7">
            <mat-icon style="color: #ededf2cc; font-size: 2.25rem">expand_more</mat-icon>
          </span>
        </div>
      </div>
      <ul class="sub-menu" *ngIf="submenu7">
        <li [ngClass]="{
            active: hasRouteActive([
              'program-director-page/mentor-list-with-search'
            ])
          }">
          <div class="main-menu" (click)="manageProgramedirector()">
            <span class="ico" title="Manage Guides"><img
                src="./assets/media/sidebar/my_gudded_explorer_white.png" /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Manage Guides</span>
            </div>
          </div>
        </li>
        <!-- <li [ngClass]="{ active: hasRouteActive(['program-director-page/manage-request']) }">
          <div
            class="main-menu"
            [routerLink]="'/program-director-page/manage-request'"
          >
            <span class="ico" title="Manage Requests"
              ><img src="./assets/media/sidebar/meaning_central_white.png"
            /></span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">Manage Requests</span>
            </div>
          </div>
        </li> -->
        <li [ngClass]="{
            active: hasRouteActive(['program-director-page/guides-reports'])
          }">
          <div class="main-menu" [routerLink]="'/program-director-page/guides-reports'">
            <span class="ico" title="View Guides Reports">
              <img src="./assets/media/icon/report-white.png" />
              <!-- <img src="./assets/media/sidebar/meaning_central_white.png"
            /> -->
            </span>
            <div class="expanded-menu-text" *ngIf="show">
              <span class="menu-lable">View Guide Reports</span>
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>
  <kt-footer class="bottomFooter" *ngIf="show"></kt-footer>
</aside>