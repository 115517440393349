import { SurveyService } from './../../../../service/survey.service';
import { Router } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-create-survey',
  templateUrl: './create-survey.component.html',
  styleUrls: ['./create-survey.component.scss'],
})
export class CreateSurveyComponent implements OnInit, OnDestroy {
  surveyData: [];
  surveySubscription: Subscription;
  constructor(
    private route: Router,
    private _cdr: ChangeDetectorRef,
    private surveyService: SurveyService
  ) {}

  ngOnInit() {
    this.surveySubscription = this.surveyService
      .getAllSurvey()
      .subscribe((data) => {
        this.surveyData = data['results'];
        this._cdr.detectChanges();
      });
  }
  add_new_survey() {
    this.route.navigateByUrl('/meaningful-work-survey/survey-add');
  }
  ngOnDestroy(): void {
    if (this.surveySubscription) {
      this.surveySubscription.unsubscribe();
    }
  }
}
