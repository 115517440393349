// Angular
import { Component, OnInit } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../core/_base/layout';
// Object-Path
import * as objectPath from 'object-path';
import { AccountsService } from './../../../service/accounts.service';

@Component({
  selector: 'kt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  today: number = Date.now();
  fluid: boolean;
  adminIndicator = '';
  version_details =
    'UI: 3.6.9<br/>Microservices: 3.6.9 <br/> Webservices: 3.6.9 <br/> Database: 3.6.9';
  /**
   * Component constructor
   *
   * @param layoutConfigService: LayouConfigService
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig();
    this.adminIndicator = this.accountService.admin_indicator;
    // footer width fluid
    this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
  }
}
