<!-- begin:: Page -->
<ng-container *ngIf="selfLayout">
  <div class="alert-css" [ngClass]="{'alert-exists-main':headeralert}">
    <div class="kt-grid kt-grid--hor kt-grid--root" [ngClass]="{'notloggedin': !isLoggedInUser}">
      <!-- begin::Body -->
      <div class="{{!isLoggedInUser? 'p-top-15' : 'p-top-80'}}">
        <ng-container *ngIf="asideDisplay">
        </ng-container>
        <div>
          <div [ngClass]="{
            expandSidebar: step == 1,
            'expandSidebar-hover': step == 2,
            ' ': step == 3
          }">
            <!-- begin:: Header -->
            <kt-alert-header *ngIf="headeralert" (toggleAlertHeader)="showheaderAlertTransfer($event)">
            </kt-alert-header>
            <kt-header *ngIf="isLoggedInUser" class="hidden-md-down" (sidebarToggleEvent)="showSidebarTransfer($event)">
            </kt-header>
            <!-- end:: Header -->

            <!-- begin:: Content -->
            <div class="container-fluid">
              <div class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                <!-- begin:: Content Head -->
                <div [ngClass]="{
                  'row expandSidebar': step == 1,
                  'row expandSidebar-hover': step == 2,
                  row: step == 3
                }">
                  <div class="kt-sidebar" id="left_side" *ngIf="isLoggedInUser">
                    <kt-sidebar-menu [show]="showSidebar" (sidebarEvent)="addItem($event)" *ngIf="subheaderDisplay">
                    </kt-sidebar-menu>
                  </div>
                  <!-- end:: Content Head -->
                  <!-- begin:: Content Body -->
                  <div class="kt-main" style="margin: auto">
                    <div ktContentAnimate class="" [ngClass]="{ 'kt-container--fluid': fluid }">
                      <router-outlet></router-outlet>
                    </div>
                  </div>
                  <div class="scrollbar scrollbar-black bordered-black square thin">
                    <div class="force-overflow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="cookies_panel_show">
            <div class="cookies" *ngIf="hasRoute('/home')">
              <div class="conten_to_hide">
                <button class="close-dialog-purple color-white" (click)="clicked()">
                  X
                </button>
                <div class="row">
                  <div class="col-sm-12 col-md-12">
                    <table width="100%">
                      <tr>
                        <td align="left" width="90%">
                          <p style="color:#fff !important;">
                            MeaningSphere uses cookies to improve user experience. View
                            our
                            <a style="color:yellow !important" href="./assets/media/MeaningSphere_Privacy_Policy.pdf"
                              target="__blank">Privacy Policy</a>
                            to learn more.
                          </p>
                        </td>
                        <td align="right">
                          <button class="btn begin_quiz" (click)="acceptCookies()">
                            Accept
                          </button>
                        </td>
                        <td align="right">
                          <button class="btn begin_quiz" (click)="rejectCookies()">
                            Decline
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- end:: Content Body -->

          <!-- end:: Content -->
        </div>
      </div>
      <!-- end:: Body -->
    </div>
  </div>
</ng-container>