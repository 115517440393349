<div class="col-sm-12 mt-30">
  <div class="my-4">
    <div class="row">
      <div class="col-sm-12 pl-5">
        <p class="section-text">
          <span style="color:#fff !important;" class="arrow-highlight cursorp"
            (click)="dmitriVideo()">Watch&nbsp;&nbsp;<img src="/../assets/media/icon/play_arrow.png"
              class="img15" /></span>
              Dimitry: “I found a trick that works!”
        </p>
      </div>
      <div class="col-sm-4"></div>
    </div>
  </div>

  <div class="section-title mt-20"><h1>Your Meaning-Seeking Patterns</h1></div>
  <div class="section-text mt-3">
    When you explore your everyday situation, you will find what's fueling and what’s draining your energy and
    motivation.
  </div>
  <p class="section-text mt-3">
    Your PMP and Healthy Tension scores point to the following pattern(s) of meaning-seeking in your current work situation. Based
    on these scores you have...
  </p>
  <ul class="section-text mt-3">
    <li>{{ lastPageStringValue }}</li>
  </ul>
</div>