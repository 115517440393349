import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../../../../app/service/notification.service';
import { SubscriptionService } from '../../../../../app/service/subscription.service';
import { message_properties } from '../../../../../environments/message_properties';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-buy-meaningful-survey',
  templateUrl: './buy-meaningful-survey.component.html',
  styleUrls: ['./buy-meaningful-survey.component.scss'],
})
export class BuyMeaningfulSurveyComponent implements OnInit, OnDestroy {
  buysurveyForm: UntypedFormGroup;
  loggedinUserid: number;
  survey_amount: string = '';
  description: string = '';
  catalog_ids: string = '';
  valueArr = [];
  surveyPurchaseSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<BuyMeaningfulSurveyComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private toastr: NotificationService,
    private subscriptionService: SubscriptionService,
    private accountService: AccountsService
  ) {
    this.survey_amount = data.survey_price;
    this.description = data.description;
    this.catalog_ids = data.catalog_id;
  }

  ngOnInit(): void {
    this.loggedinUserid = this.accountService.muser;
    this.buysurveyForm = this.formBuilder.group({
      member: new UntypedFormControl(this.loggedinUserid),
      selected_value: new UntypedFormControl(this.catalog_ids),
      check_guide_discussion: new UntypedFormControl('', [Validators.required]),
    });
  }

  purchaseSurvey(ngForm: NgForm) {
    let formData = this.buysurveyForm.value;
    const selected_value = formData['selected_value'];
    this.valueArr.push(selected_value);
    this.buysurveyForm.patchValue({
      check_guide_discussion: JSON.stringify(
        Array.from(new Set(this.valueArr)).sort()
      ),
    });
    this.surveyPurchaseSubscription = this.subscriptionService
      .addToCart(this.buysurveyForm.value)
      .subscribe(
        (data) => {
          if (data.status === 201) {
            this.dialogRef.close();
            this.toastr.showSuccess(
              message_properties.CART_ITEM_ADD_SUCCESS,
              ''
            );
          } else if (data.status === 204) {
            this.dialogRef.close();
            this.toastr.showError(data.message, '');
          } else if (data.status === 400) {
            this.toastr.showError(data.message, '');
          } else {
            this.toastr.showError(message_properties.CART_ITEM_ADD_ERROR, '');
          }
        },
        (error) => {
          this.toastr.showError(message_properties.CART_ITEM_ADD_ERROR, '');
        }
      );
  }
  ngOnDestroy(): void {
    if (this.surveyPurchaseSubscription) {
      this.surveyPurchaseSubscription.unsubscribe();
    }
  }
}
