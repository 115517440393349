import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ThirdWelcomePageComponent } from './../third-welcome-page/third-welcome.component';
import { FirstWelcomePageComponent } from './../first-welcome-page/first-welcome.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MeaningNetworkService } from '../../../../service/meaning-network.service';
import { UntypedFormGroup } from '@angular/forms';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-second-welcome',
  templateUrl: './second-welcome.component.html',
  styleUrls: ['./second-welcome.component.scss'],
})
export class SecondWelcomePageComponent implements OnInit, OnDestroy {
  whaticareAboutForm: UntypedFormGroup;
  loggedInUser: number;
  topic_count: number = 0;
  onboarding_complete: boolean;
  topics = [];
  tagList = [];
  topicArr = [];
  interests = [];

  interestsSelected: any = {
    matter1: '',
    matter2: '',
    matter3: '',
    matter4: '',
    matter5: '',
    matter6: '',
    property_id: 2354,
    check_value: 9,
    member: '',
    tenant: 1,
  };
  
  constructor(
    public dialogRef: MatDialogRef<SecondWelcomePageComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private meaning_network_service: MeaningNetworkService,
    public sanitizer: DomSanitizer,
    private accountService: AccountsService
  ) {
    this.tagListForWhatmatter();
  }

  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
    this.interests['member'] = this.loggedInUser;
    this.getWhatICareAbout(this.loggedInUser);
  }

  previousPage(): void {
    this.dialogRef.close();
    this.dialog.open(ThirdWelcomePageComponent, {
      width: '70%',
      disableClose: true,
    });
  }
  nextPage(): void {
    this.whaticareAboutSave();
    this.dialogRef.close();
    this.dialog.open(FirstWelcomePageComponent, {
      width: '70%',
      disableClose: true,
    });
  }

  close(): void {
    this.start();
    this.dialogRef.close();
  }

  getWhatICareAbout(member) {
    this.meaning_network_service
      .getWhatICareAbout(this.loggedInUser)
      .subscribe((data) => {
        this.topic_count = data.count;
        this.topics = data.results;
        this.cdRef.detectChanges();
        this.interests = [];
        for (const obj in this.topics) {
          const tag_obj = this.topics[obj];
          const tag_id = tag_obj['tag_id'];
          this.interests.push(tag_id);
        }
      });
  }

  whaticareAboutSave() {
    const data = {
      interest_id: this.interests,
      member_id: this.loggedInUser,
    };
    this.meaning_network_service
      .postWhatICareAbout(data)
      .subscribe(
        () => {
          this.getWhatICareAbout(this.loggedInUser);
          this.cdRef.detectChanges();
        },
        () => {
          this.cdRef.detectChanges();
        }
      );
  }
  selctInterest(interest) {
    if (!this.interests.includes(interest)) {
      if (this.interests.length < 6) {
        this.interests.push(interest);
      }
    } else {
      this.interests.splice(this.interests.indexOf(interest), 1);
    }
  }
  checkInterest(interest) {
    let flag = false;
    if (this.interests.includes(interest)) {
      flag = true;
    }
    return flag;
  }
  tagListForWhatmatter() {
    this.meaning_network_service
      .getTagListForWhatMatter()
      .subscribe((data) => {
        this.tagList = data.results;
        this.cdRef.detectChanges();
      });
  }
  start(): void {
    this.onboarding_complete = true;
    const data = {
      member: this.loggedInUser,
      onboarding_complete: this.onboarding_complete,
    };
    this.meaning_network_service
      .postwelcomeFinish(data)
      .subscribe((response) => {
        this.dialogRef.close();
      });
  }
  ngOnDestroy(): void {
    
  }
}
