import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SubscriptionService } from './../../../../../service/subscription.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../../../../service/notification.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  NgForm,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { message_properties } from '../../../../../../environments/message_properties';
import { AccountsService } from './../../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-purchase-guide-time',
  templateUrl: './purchase-guide-time.component.html',
  styleUrls: ['./purchase-guide-time.component.scss'],
})
export class PurchaseGuideTimeComponent implements OnInit, OnDestroy {
  guidepurchaseForm: UntypedFormGroup;
  check_items_price: UntypedFormArray;
  creatingUser: number;
  menteeId: any;
  loggedinUserid: number;
  eventId: number;
  start_date: string;
  first_name: string;
  last_name: string;
  image_path: string;
  catalog_id: number;
  GuideServiceList = [];
  guide_service_count: number;
  desc_interval_1: string = 'Discussion(s)';
  desc_interval_2: string = 'Minute(s)';
  desc_interval_3: string = 'Discussion(s)';
  valueArr = [];
  fields: any;
  saveBtn: boolean = true;
  purchaseSubscription: Subscription;
  serviceSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<PurchaseGuideTimeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private toastr: NotificationService,
    private subscriptionService: SubscriptionService,
    private cdRef: ChangeDetectorRef,
    private accountService: AccountsService
  ) {
    this.menteeId = data.menteeId;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.image_path = data.image_path
      ? data.image_path
      : '/assets/media/users/default.png';
    this.catalog_id = data.catalog_id;
  }

  ngOnInit() {
    this.loggedinUserid = this.accountService.muser;
    this.guidepurchaseForm = this.formBuilder.group({
      member: new UntypedFormControl(this.loggedinUserid),
      check_guide_discussion: new UntypedFormControl('', [Validators.required]),
      time_interval_1: new UntypedFormControl('1'),
      time_interval_2: new UntypedFormControl('15'),
      time_interval_3: new UntypedFormControl('1'),
    });
    this.guideServiceList();
  }

  purchaseGuideBalance(ngForm: NgForm) {
    this.purchaseSubscription = this.subscriptionService
      .addToCart(ngForm)
      .subscribe(
        (data) => {
          if (data.status === 201) {
            this.saveBtn = false;
            this.dialogRef.close();
            this.toastr.showSuccess(
              message_properties.CART_ITEM_ADD_SUCCESS,
              ''
            );
          } else if (data.status === 204) {
            this.saveBtn = false;
            this.dialogRef.close();
            this.toastr.showSuccess(data.message, '');
          } else if (data.status === 400) {
            this.toastr.showError(data.message, '');
          } else {
            this.toastr.showError(message_properties.CART_ITEM_ADD_ERROR, '');
          }
        },
        (error) => {
          this.toastr.showError(message_properties.CART_ITEM_ADD_ERROR, '');
        }
      );
  }
  guideServiceList() {
    this.GuideServiceList = [];
    this.serviceSubscription = this.subscriptionService
      .catalogListBasisOnOffering(1249)
      .subscribe(
        (data) => {
          this.guide_service_count = data.count;
          this.GuideServiceList = data.results;
          this.cdRef.detectChanges();
        },
        (error) => console.error('Technical Issue. Guide Service not available')
      );
  }
  descInternal(id) {
    if (id === 1) {
      return this.desc_interval_1;
    } else if (id === 2) {
      return this.desc_interval_2;
    } else {
      return this.desc_interval_3;
    }
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  returnImage(image) {
    if (image || image !== null) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }
  onChecklistChange($event: Event) {
    const checked_value = $event.target['value'];

    if ($event.target['checked']) {
      this.valueArr.push(checked_value);
    } else {
      this.removeFromArray(this.valueArr, checked_value);
    }
    if (this.valueArr.length !== 0) {
      this.saveBtn = false;
    } else {
      this.saveBtn = true;
    }
    this.guidepurchaseForm.patchValue({
      check_guide_discussion: JSON.stringify(
        Array.from(new Set(this.valueArr)).sort()
      ),
    });
    this.cdRef.detectChanges();
  }
  removeFromArray(arr, checked_value = '') {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  }
  plus_item(id, interval) {
    if (id === 1) {
      var interval_1 = Number(
        this.guidepurchaseForm.value['time_interval_1']
      );
      if (interval_1 >= 4) {
        return;
      }
      this.guidepurchaseForm.patchValue({
        time_interval_1: interval_1 + interval,
      });
    } else if (id === 2) {
      var interval_2 = Number(
        this.guidepurchaseForm.value['time_interval_2']
      );
      if (interval_2 >= 240) {
        return;
      }
      this.guidepurchaseForm.patchValue({
        time_interval_2: interval_2 + interval,
      });
    } else if (id === 3) {
      var interval_3 = Number(
        this.guidepurchaseForm.value['time_interval_3']
      );
      if (interval_3 >= 4) {
        return;
      }
      this.guidepurchaseForm.patchValue({
        time_interval_3: interval_3 + interval,
      });
    }
  }
  minus_item(id, interval) {
    if (id === 1) {
      var interval_1 = Number(
        this.guidepurchaseForm.value['time_interval_1']
      );
      if (interval_1 === interval) {
        return;
      }
      this.guidepurchaseForm.patchValue({
        time_interval_1: interval_1 - interval,
      });
    } else if (id === 2) {
      var interval_2 = Number(
        this.guidepurchaseForm.value['time_interval_2']
      );
      if (interval_2 === interval) {
        return;
      }
      this.guidepurchaseForm.patchValue({
        time_interval_2: interval_2 - interval,
      });
    } else if (id === 3) {
      var interval_3 = Number(
        this.guidepurchaseForm.value['time_interval_3']
      );
      if (interval_3 === interval) {
        return;
      }
      this.guidepurchaseForm.patchValue({
        time_interval_3: interval_3 - interval,
      });
    }
  }
  ngOnDestroy() {
    if (this.purchaseSubscription) {
      this.purchaseSubscription.unsubscribe();
    }
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }
}
