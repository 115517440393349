<div class="d-flex jutify-content-start align-items-center pt-15 pb-15">
  <form [formGroup]="requestForm" novalidate autocomplete="off">
    <table>
      <tr>
        <td><span>Show:</span></td>
        <td>
          <select class="select" formControlName="select_value">
            <option disabled selected value style="display:none"></option>
            <option value="1">All</option>
            <option value="2">Accepted</option>
            <option value="3">Declined</option>
            <option value="4">Requested</option>
          </select>
        </td>
        <td>
          <button
            class="btn begin_quiz ml-5"
            (click)="getAllRequestToMe(1)"
            [disabled]="go_btn"
          >
            GO
          </button>
        </td>
      </tr>
    </table>
  </form>
</div>

<!-- <cdk-virtual-scroll-viewport
  class="scroll_class scroll_class_requests card2-style-height-with-tabs-hr-heading-dropdown"
  [itemSize]="0"
  (scroll)="pushValue($event)"
> -->
<div
  class="search-results scroll_class scroll_class_requests card2-style-height-with-tabs-hr-heading-dropdown"
  infinite-scroll
  [infiniteScrollDistance]="modalScrollDistance"
  [infiniteScrollThrottle]="modalScrollThrottle"
  [scrollWindow]="false"
  (scrolled)="pushValue($event)"
>
  <div class="">
    <ng-container *ngIf="request_count > 0">
      <div class="row">
        <div
          *ngFor="let request of requestData"
          class="col-lg-3 col-md-6 col-sm-12"
        >
          <div class="curator_card_main mt-3 front_card_background_clr">
            <div class="title img_section_mirror text-center">
              <img
                alt=""
                class="w-124-pm archive_img new-c-photo new-c-photo-guid archive_img {{
                  request.member_accepted_count == 1 ? 'archive_img_border' : ''
                }} curator"
                [src]="returnImage(request.creatingUser[0].picture_path)"
                style="border-radius: 50%; cursor: pointer"
                (click)="
                  viewProfile(
                    request.creatingUser[0].id,
                    request.creatingUser[0].first_name,
                    request.creatingUser[0].last_name,
                    returnImage(request.creatingUser[0].picture_path)
                  )
                "
              />
              <h1
                class="stat-p-tite"
                style="cursor: pointer"
                (click)="
                  viewProfile(
                    request.creatingUser[0].id,
                    request.member_connect_arr,
                    request.memberProfileData
                  )
                "
              >
                {{ request.creatingUser[0].first_name }}</h1>
                <h2 class="fs-18">{{ request.creatingUser[0].last_name }}
              </h2>

              <p class="stat-p">
                {{
                  request.creatingUserAddress.length > 0
                    ? request.creatingUserAddress[0]["city"]
                    : "--"
                }},
                {{
                  request.creatingUserAddress.length > 0
                    ? request.creatingUserAddress[0]["country"]
                    : "--"
                }}
              </p>
            </div>
            <hr />
            <div class="">
              <div class="">
                <p>
                  {{ request.title }} <br />
                  Status:
                  <label *ngIf="request.active == 6">Requested</label>
                  <label *ngIf="request.active == 7">Accepted</label>
                  <label *ngIf="request.active == 8">Declined</label>
                  <label *ngIf="request.active == 9">Withdrawn</label>
                  <br />
                  Requested on:
                  {{
                    request.creation_date | date: "MMM d, y":current_timezone
                  }}
                </p>
              </div>

              <div class="">
                <textarea
                  class="right-textarea"
                  readonly="true"
                  rows="4"
                  cols="40"
                  >{{ request.description }}</textarea
                >
                <table
                  class="mt-2 request-event-float"
                 
                  *ngIf="request.event_count == 0"
                >
                  <tr>
                    <ng-container
                      *ngIf="
                        request.active !== 6 &&
                        request.active !== 9 &&
                        request.active !== 8 &&
                        request.collab_content_type == 2542
                      "
                    >
                      <td>
                        <button class="guid-btn-list">
                          <img
                            src="../assets/media/icon/scheduled.png"
                            class="action_button"
                            title="Update Discussion"
                            (click)="
                              updateSession(
                                request.requestingToUser[0].full_name,
                                request.requestingToUser[0].picture_path,
                                request.title,
                                request.id,
                                request.requestingToUser[0].id
                              )
                            "
                          />
                        </button>
                      </td>
                    </ng-container>
                    
                    <ng-container *ngIf="request.active == 6">
                      <td>
                        <button class="guid-btn-list">
                          <img
                            src="../assets/media/icon/Approve@2x.png"
                            (click)="
                              handleRequest(
                                request.creatingUser[0].full_name,
                                request.creatingUser[0].picture_path,
                                request.title,
                                request.id,
                                request.creatingUser[0].id,
                                request.collab_content_type,
                                'A',
                                request
                              )
                            "
                            class="action_button"
                            title="Accept"
                          />
                        </button>
                      </td>
                      <td>
                        <button class="guid-btn-list">
                          <img
                            src="../assets/media/icon/Reject@2x.png"
                            (click)="
                              handleRequest(
                                request.creatingUser[0].full_name,
                                request.creatingUser[0].picture_path,
                                request.title,
                                request.id,
                                request.creatingUser[0].id,
                                request.collab_content_type,
                                'D',
                                request
                              )
                            "
                            class="action_button"
                            title="Declined"
                          />
                        </button>
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <label *ngIf="request_count == 0">
          <table>
            <tbody>
              <tr>
                <td style="padding: 6px !important">
                  <img
                    src="./assets/media/no_data_found.png"
                    style="border-radius: 0px"
                  />
                </td>
                <td colspan="2" style="padding: 6px !important">
                  You do not have any Guide requests!
                </td>
              </tr>
            </tbody>
          </table>
        </label>
      </div>
    </ng-container>
    <ng-container *ngIf="request_count == 0">
      <table>
        <tbody>
          <tr>
            <td align="right">
              <img
                src="./assets/media/no_data_found.png"
                style="border-radius: 0px"
              />
            </td>
            <td colspan="2">You do not have any requests!</td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
</div>
<!-- </cdk-virtual-scroll-viewport> -->
