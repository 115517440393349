import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-become-a-contributor',
  templateUrl: './become-a-contributor.component.html',
  styleUrls: ['./become-a-contributor.component.scss'],
})
export class BecomeAContributorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
