<kt-spinner></kt-spinner>
<div class="mat-dialog-popup">
  <h1 class="cmn_title_top">My Address</h1>

  <button mat-button mat-dialog-close class="close-dialog">X</button>

  <hr />
  <div class="fixed_cntent address-update">
    <mat-card-content>
      <form class="addressForm" [formGroup]="addressForm" (ngSubmit)="saveAddress(addressForm.value)"
        enctype="multipart/form-data" novalidate>
        <div class="addressformfill">
          <div class="row">
          <div class="form-group col-sm-12 country-parent-div">
              <kt-search-dropdown (sendbackresponse)="setCountry($event)" [countrySelected]="true" [mode]="'edit'"
                [fieldType]="'country'"></kt-search-dropdown>
          
          </div>
          <div class="form-group col-sm-12 country-parent-div">
            <mat-form-field>
              <mat-label>Address</mat-label>
              <input matInput placeholder="Street address or P.O.Box" type="text" formControlName="line1"
                autocomplete="off" tabindex="2" />
            </mat-form-field>
          </div>

          <div class="form-group col-sm-12 country-parent-div">
            <mat-form-field>
              <input matInput placeholder="Apt, Suite, Unit, Building, Floor etc" type="text" formControlName="line2"
                autocomplete="off" tabindex="3" />
            </mat-form-field>
          </div>
          <div class="form-group col-sm-4 city-parent-div">
            <mat-form-field>
              <mat-label>City</mat-label>
              <input matInput type="text" formControlName="city" autocomplete="off" tabindex="4" #citySearchInput
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')" />
            </mat-form-field>
            <!-- (clickOutside)="cityBox = false" -->
            <div class="city-box-child-div" *ngIf="cityBox" (clickOutside)="cityBox = false">
              <div class="search-results country-viewport" infinite-scroll
                [infiniteScrollDistance]="modalScrollDistance" [infiniteScrollThrottle]="modalScrollThrottle"
                [scrollWindow]="false" (scrolled)="pushValue($event)">
                <div class="country-autocom-box">
                  <li *ngFor="let city of cityList" (click)="setCity(city.name, city.state_name, city.id)">
                    {{ city.city_with_country_code }}
                  </li>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-sm-4 country-parent-div">
            <mat-form-field>
              <mat-label>State/Province/Territory</mat-label>
              <input matInput type="text" formControlName="state" autocomplete="off" tabindex="5"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')" />
            </mat-form-field>
          </div>
          <div class="form-group col-sm-4 country-parent-div">
            <mat-form-field>
              <mat-label>Postal Code</mat-label>
              <input matInput type="text" formControlName="postalcode" autocomplete="off" tabindex="6"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')" />
            </mat-form-field>
          </div>
        </div>
      </div>
        <hr />
        <div class="row">
          <div class="form-group col-sm-12 tr marg-none">
            <button [disabled]="!addressForm.valid" class="btn begin_quiz ml-2 mt-1" type="submit">
              Save
            </button>

            <button mat-dialog-close class="btn begin_quiz mt-1 ml-2 button-change-scroll canceled_btn">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </div>
</div>