import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MentorService {
  apiUrl = environment.apiUrl;
  private _requestrefreshneeded = new Subject<void>();
  private _requestrefreshneededForMarkAsClosed = new Subject<void>();
  private _requestrefreshneededExplorer = new Subject<void>();

  get requestrefreshneededExplorer() {
    return this._requestrefreshneededExplorer;
  }

  get requestrefreshneededForMarkAsClosed() {
    return this._requestrefreshneededForMarkAsClosed;
  }
  get requestrefreshneeded() {
    return this._requestrefreshneeded;
  }

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  getMentorData(loggedinUserid) {
    return this.http.get<ResDataModal[]>(
      this.apiUrl + 'member/mentor-list/' + loggedinUserid
    );
  }
  getMentorMetadata(mentorid) {
    return this.http.get<ResDataModal[]>(
      this.apiUrl + 'member/member-view-from-admin/' + mentorid
    );
  }
  getPastPresentMentors(mentorid) {
    return this.http.get<any>(
      this.apiUrl + 'member/relationship/mentees-of-member/' + mentorid
    );
  }
  createCollabContent(data) {
    return this.http.post<any>(
      this.apiUrl + 'collabcontent/collab-content-request/',
      data
    );
  }

  searchGuidesForm(data) {
    return this.http.post<any>(this.apiUrl + 'member/search-guides', data);
  }
  getCollabContent(member, content) {
    const data = {
      member: member,
      content: content,
      q: 7,
    };

    return this.http.post<any>(
      `${this.apiUrl}collabcontent/collab-content-request-list/`,
      data
    );
  }
  getDiscussionsListForExplorer(logged_in_user, which) {
    return this.http.get<any>(
      this.apiUrl +
        'event/discussion-list-for-explorers/' +
        logged_in_user +
        '?q=' +
        which
    );
  }
  getDiscussionsListForGuide(logged_in_user, which) {
    return this.http.get<any>(
      this.apiUrl +
        'event/discussion-list-for-guide/' +
        logged_in_user +
        '?q=' +
        which
    );
  }
  endorseGuide(data, guide, member) {
    return this.http.post<any>(
      this.apiUrl + 'member/relationship/endorse/' + guide + '/' + member,
      data
    );
  }
  updateMemberRelationship(ngForm, relationshipId) {
    return this.http.put<any>(
      this.apiUrl + 'member/relationship/update/' + relationshipId,
      ngForm
    );
  }
  getMenteeData(loggedinUserid, status) {
    return this.http.get<any>(
      this.apiUrl +
        'member/guided-explorer-list/' +
        loggedinUserid +
        '/' +
        status
    );
  }
  discontinueGuide(ngForm, relationshipId, loggedInUser) {
    return this.http.put<any>(
      this.apiUrl +
        'member/relationship/discontinue-guide/' +
        relationshipId +
        '/' +
        loggedInUser,
      ngForm
    );
  }
  //Update by T.K. Mar 18,2021
  updateCollabContent(ccId, rsvp, collb_content_type, guide) {
    let data = {
      pk: ccId,
      rsvp: rsvp,
      collb_content_type: collb_content_type,
      guide: guide,
    };
    return this.http
      .put<any>(this.apiUrl + 'collabcontent/collab-content-request/', data)
      .pipe(
        tap(() => {
          this._requestrefreshneeded.next();
        })
      );
  }
  //Update by T.K. Mar 22,2021
  getSharedDiscussionsDocs(participant, content, guide, q, relationship_id) {
    return this.http.get<any>(
      `${this.apiUrl}survey/surveyresponse/shared-with-guide?explorer=${participant}&content=${content}&guide=${guide}&q=${q}&relationship_id=${relationship_id}`
    );
  }
  unshareDoc(sharedId) {
    return this.http.delete<any>(
      this.apiUrl + 'member/delete-share/' + sharedId
    );
  }
  docAttachemnt(data) {
    return this.http.post<any>(this.apiUrl + 'event/document-uploaded', data);
  }
  getPrivateNotesData(subscriving_member, processing_member) {
    return this.http.get<privateNotesData>(
      this.apiUrl +
        'event/private-note-list/' +
        subscriving_member +
        '/' +
        processing_member
    );
  }
  getGuideDataByPost(
    loggedInUser,
    indicator,
    ngform
  ): Observable<privateNotesData> {
    return this.http.post<privateNotesData>(
      this.apiUrl + 'event/guide-report/' + loggedInUser + '/' + indicator,
      ngform
    );
  }
  getGuideDataByGet(loggedInUser, indicator): Observable<privateNotesData> {
    return this.http.get<privateNotesData>(
      this.apiUrl + 'event/guide-report/' + loggedInUser + '/' + indicator
    );
  }

  getDiscussionDetailsData(subscriving_member, processing_member) {
    return this.http.get<privateNotesData>(
      this.apiUrl +
        'event/guide_discussion_details/' +
        subscriving_member +
        '/' +
        processing_member
    );
  }
  getGuideDiscussionListByPost(
    logged_in_user,
    registration_type,
    ngform
  ): Observable<privateNotesData> {
    return this.http.post<privateNotesData>(
      this.apiUrl +
        'event/guide-discussion-list/' +
        logged_in_user +
        '/' +
        registration_type,
      ngform
    );
  }
  getGuideDiscussionListByGet(
    logged_in_user,
    registration_type
  ): Observable<privateNotesData> {
    return this.http.get<privateNotesData>(
      this.apiUrl +
        'event/guide-discussion-list/' +
        logged_in_user +
        '/' +
        registration_type
    );
  }
  getMyDiscussionListByPost(
    loginUser,
    indicator,
    ngform
  ): Observable<GuideExplorerData> {
    return this.http.post<GuideExplorerData>(
      this.apiUrl +
        `event/event-list-by-loggedin-user/${loginUser}/${indicator}`,
      ngform
    );
  }
  getMyDiscussionListByGet(
    loginUser,
    indicator
  ): Observable<GuideExplorerData> {
    return this.http.get<GuideExplorerData>(
      this.apiUrl +
        `event/event-list-by-loggedin-user/${loginUser}/${indicator}`
    );
  }
  getDiscussionSummaryData(
    relatonship_id,
    subscriving_member,
    processing_member
  ) {
    return this.http.get<privateNotesData>(
      this.apiUrl +
        `event/guide-discussion-summary/${relatonship_id}/${subscriving_member}/${processing_member}`
    );
  }
  getGuideExplorerDiscussionList(
    member_relationship_id,
    guide,
    explorer
  ): Observable<privateNotesData> {
    return this.http.get<privateNotesData>(
      this.apiUrl +
        `event/guide-all-discussion-list/${member_relationship_id}/${guide}/${explorer}`
    );
  }
  getExplorerGuideDiscussionList(
    member_relationship_id,
    explorer,
    guide
  ): Observable<privateNotesData> {
    return this.http.get<privateNotesData>(
      this.apiUrl +
        `event/explorer-all-discussion-list/${member_relationship_id}/${explorer}/${guide}`
    );
  }
  getLoggedInMemberDiscussionList(
    logged_in_member
  ): Observable<privateNotesData> {
    return this.http.get<privateNotesData>(
      this.apiUrl + 'event/discussions-list/' + logged_in_member
    );
  }
  getGuideExplorerDiscussionListByPost(
    member_relationship_id,
    guide,
    explorer,
    ngform
  ): Observable<privateNotesData> {
    return this.http.post<privateNotesData>(
      this.apiUrl +
        `event/guide-all-discussion-list/${member_relationship_id}/${guide}/${explorer}`,
      ngform
    );
  }
  getExplorerGuideDiscussionListByPost(
    member_relationship_id,
    explorer,
    guide,
    ngform
  ): Observable<privateNotesData> {
    return this.http.post<privateNotesData>(
      this.apiUrl +
        `event/explorer-all-discussion-list/${member_relationship_id}/${explorer}/${guide}`,
      ngform
    );
  }
  getLoggedInMemberDiscussionListByPost(
    logged_in_member,
    ngform
  ): Observable<privateNotesData> {
    return this.http.post<privateNotesData>(
      this.apiUrl + 'event/discussions-list/' + logged_in_member,
      ngform
    );
  }
  getLoggedInMemberDiscussionSummaryData(logged_in_member) {
    return this.http.get<privateNotesData>(
      this.apiUrl +
        'event/logged_in_member-discussion-summary/' +
        logged_in_member
    );
  }

  getPresentMentors(mentorid) {
    return this.http.get<PastPresentGuideData>(
      this.apiUrl + `member/relationship/mentees-of-member/${mentorid}?active=1`
    );
  }
  getPastMentors(mentorid) {
    return this.http.get<PastPresentGuideData>(
      this.apiUrl + `member/relationship/mentees-of-member/${mentorid}?active=0`
    );
  }

  getCatalogEventDetails(catalog_id) {
    return this.http.get<CatalogEventData>(
      this.apiUrl + `subscription/catalog-event/details/${catalog_id}`
    );
  }

  discussionMarkedAsCompleted(ngForm) {
    return this.http
      .put<privateNotesData>(
        this.apiUrl + 'event/event-discussion-marked-as-completed',
        ngForm
      )
      .pipe(
        tap(() => {
          this._requestrefreshneededForMarkAsClosed.next();
        })
      );
  }
  getRequestToMe(formData) {
    return this.http.post<any>(
      this.apiUrl + 'collabcontent/collab-content-request-to-me/',
      formData
    );
  }

  getAllMenteeData(loggedinUserid) {
    return this.http.get<any>(
      this.apiUrl + 'member/guided-explorer-past-present-list/' + loggedinUserid
    );
  }
  getAllExplorer(loggedinUserid) {
    return this.http.get<any>(
      this.apiUrl + 'member/all-explorer-list-report/' + loggedinUserid
    );
  }

  getAllGuide(loggedinUserid) {
    return this.http.get<any>(
      this.apiUrl + 'member/all-guide-list-report/' + loggedinUserid
    );
  }

  getGuideExplorerDiscussionReportList(member): Observable<privateNotesData> {
    return this.http.get<privateNotesData>(
      this.apiUrl + `event/guide-all-discussion-list-report/${member}`
    );
  }
  postGuideExplorerDiscussionReportList(
    member,
    ngform
  ): Observable<privateNotesData> {
    return this.http.post<privateNotesData>(
      this.apiUrl + `event/guide-all-discussion-list-report/${member}`,
      ngform
    );
  }

  getLoggedInMemberDiscussionSummaryDataReport(logged_in_member, indicator) {
    return this.http.get<privateNotesData>(
      this.apiUrl +
        'event/logged-in-member-discussion-summary/' +
        logged_in_member +
        '/' +
        indicator
    );
  }
  sentRequestByPost(ngform) {
    return this.http.post<any>(
      this.apiUrl + 'collabcontent/collab-content-requested-user',
      ngform
    );
  }
  getMenteeDataByPost(ngform) {
    return this.http.post<any>(
      this.apiUrl + 'member/guided-explorer-list-post',
      ngform
    );
  }
  getPrivateNoteData(NgForm) {
    return this.http.post<privateNotesData>(
      this.apiUrl + `event/private-note-list-post`,
      NgForm
    );
  }
  getMentorDataByPost(ngForm) {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/mentor-list-post',
      ngForm
    );
  }
  disconnectGuideExplorerRelation(ngForm): Observable<DataWithStatus> {
    return this.http
      .post<DataWithStatus>(
        this.apiUrl +
          'member/relationship/disconnect-guide-explorer-relationship',
        ngForm
      )
      .pipe(
        tap(() => {
          this._requestrefreshneededExplorer.next();
        })
      );
  }
  allGuideExplorerEventData(Formdata): Observable<privateNotesData> {
    return this.http.post<privateNotesData>(
      this.apiUrl + 'event/guide-explorer-report',
      Formdata
    );
  }
  postPastMentors(data) {
    return this.http.post<PastPresentGuideData>(
      this.apiUrl + `member/relationship/past-mentor-of-member`,
      data
    );
  }
  downloadDiscussionReport(send_data): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'common/download-discussion-csv-format',
      send_data
    );
  }
}

export interface DataWithStatus {
  creating_member: number;
  lock_unlock_status: number;
  member: number;
  property_name: string;
  property_value: string;
  status_type: string;
  results: [];
  count: number;
  code: number;
  message: string;
}

export interface CatalogEventData {
  event_type: number;
  catalog_item: number;
  tenant: number;
  count: number;
  status: number;
  results: [];
}

export interface privateNotesData {
  visibility_type: number;
  event_registration_status: number;
  subscription_date: string;
  additional_information: string;
  event: number;
  subscribing_member: number;
  processing_member: number;
  processing_member_details: {};
  subscriving_member_details: {};
  event_details: {};
  total_sessions: number;
  total_hours: number;
  graph_data: {};
  count: number;
  status: number;
  results: [];
  host_meeting_link: string;
  member_meeting_link: string;
  meeting_recording_video_path: string;
  actual_duration: number;
  message: string;
}

export interface GuideExplorerData {
  visibility_type: number;
  event_registration_status: number;
  subscription_date: string;
  additional_information: string;
  event: number;
  subscribing_member: number;
  processing_member: number;
  processing_member_details: {};
  subscriving_member_details: {};
  event_details: {};
  total_sessions: number;
  total_hours: number;
  graph_data: {};
  count: number;
  status: number;
  results: [];
  am_i_guide_in_this_event: boolean;
  am_i_explorer_in_this_event: boolean;
}

export interface ResDataModal {
  count: number;
  status: number;
  results: [];
  member: {};
  user: {};
  member_mentor: {};
  first_name: string;
  last_name: string;
  email: string;
  download_url: string;
  message: string;
}

export interface PastPresentGuideData {
  visibility_type: number;
  relationship_type: number;
  influence_level: number;
  motive: number;
  influence_type: number;
  degree_of_influence: number;
  active: number;
  external_indicator: number;
  start_date: string;
  end_date: string;
  member: number;
  relating_member: number;
  remarks: string;
  member_endorsement: {};
  member_address: {};
  member_connect_arr: {};
  member_accepted_count: {};
  alredy_have_guide: number;
  member_profile: {};
  relating_member_profile: {};
  count: number;
  status: number;
  results: [];
}
