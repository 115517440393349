
<!-- begin: Header -->
<div ktHeader #ktHeader class="kt-header kt-grid__item d-flex p-10 align-items-center w-100" id="kt_header">
  <div class="kt-container d-flex justify-content-between w-100" [ngClass]="{ 'kt-container--fluid': fluid }">
    <!-- begin:: Brand -->
    <div class="headerBox" style="display: flex;align-items: center;">
      <i class="fas fa-bars" (click)="toggleSidebar()"></i>
      <kt-brand></kt-brand>
    </div>
    <!-- end:: Brand -->
    

    <!-- begin:: Header Topbar -->
    <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
    <div *ngIf="!menuHeaderDisplay"></div>
    <kt-topbar></kt-topbar>

    <!-- end:: Header Topbar -->
  </div>
</div>
<!-- end: Header -->