<div id="vedioBg" *ngIf="!isLoggedInUser">
  <video class="desktop-video" *ngIf="selectedVideo === 0" muted autoplay oncanplay="this.play()"
    onloadedmetadata="this.muted = true" playsinline>
    <!-- loop -->
    <source [src]="src" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <video class="desktop-video" *ngIf="selectedVideo === 1" loop muted autoplay oncanplay="this.play()"
    onloadedmetadata="this.muted = true">
    <source src="./assets/vedio/layer_1.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <video class="desktop-video" *ngIf="selectedVideo === 2" muted autoplay oncanplay="this.play()"
    onloadedmetadata="this.muted = true" loop>
    <source src="./assets/vedio/layer_2.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <video class="desktop-video" *ngIf="selectedVideo === 3" loop muted autoplay oncanplay="this.play()"
    onloadedmetadata="this.muted = true">
    <source src="./assets/vedio/layer_3.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <video class="desktop-video" *ngIf="selectedVideo === 4" loop muted autoplay oncanplay="this.play()"
    onloadedmetadata="this.muted = true">
    <source src="./assets/vedio/layer_4.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <div class="videofooter">
    <span class="colorw cursorp" (click)="toggleText()" *ngIf="!toggleTextName">
      What's this?
    </span>
    <span *ngIf="toggleTextName" class="colorw">
      The Sphere is a depiction of our vision. It represents the connections
      between work, people and purpose. Following these connecting paths can
      help people visualize where they can receive meaning in their work.
      <u (click)="goHome()" class="coloro cursorp">Learn more.</u>
    </span>
  </div>
</div>
<kt-home-header [back]="false"></kt-home-header>
<div class="row">

  <div class="col-sm-12 col-md-12 col-lg-5 welcomMeaninSphere">
    <h1 class="welcome_to_h1">
      <span class="welcome_to"> Welcome to </span><br />
      MeaningSphere
    </h1>
    <ul class="homeDiscover">
      <li class="hm1">
        <div (click)="toggleContent(1)">
          <span>
            Experience the magic of a Meaning Circle
            <!-- <span>Take Map of Meaning quiz</span> -->
          </span>
          <span class="icn" [ngClass]="{ active: selectedVideo1 }"><img src="./assets/images/right1.png" /></span>
        </div>
        <div class="discHome height-join" [ngClass]="{ activeDisc: selectedVideo1 }">
          <table>
            <tr>
              <td>
                A Meaning Circle™ is a unique, small-group experience. A host
                poses an opening question about meaningful work life experiences
                and participants share their reflections with no cross-talk or
                commentary. You’ll listen and be listened to, enabling you to
                discover new perspectives on what could make your work life more
                meaningful to you.
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td align="right">
                <a class="btn begin_quiz" (click)="loginpage('Circle', '/meaning-circle/circles')">Join a Circle</a>
              </td>
            </tr>
          </table>
        </div>
      </li>
      <li class="hm2">
        <div (click)="toggleContent(2)">
          <span>
            Find others who inspire your meaning journey
            <!-- <span>Start making Connections</span> -->
          </span>
          <span class="icn" [ngClass]="{ active: selectedVideo2 }"><img src="./assets/images/right2.png" /></span>
        </div>
        <div class="discHome" [ngClass]="{ activeDisc: selectedVideo2 }">
          <table>
            <tr>
              <td>
                <p>
                  The Network is where you can connect with those in a similar
                  spot to you as well as those who are where you would like to
                  be. You can explore new perspectives, learn about new meaning
                  practices, and inspire others.
                </p>
              </td>
            </tr>
            <tr>
              <td align="right">
                <a class="btn begin_quiz" (click)="loginpage('Network', '/meaning-network/my-feed')">Make
                  Connections</a>
              </td>
            </tr>
          </table>
        </div>
      </li>
      <li class="hm3">
        <div (click)="toggleContent(4)">
          <span>
            Explore a curated collection of meaningful content
            <!-- <span>Become a Contributor</span> -->
          </span>
          <span class="icn" [ngClass]="{ active: selectedVideo4 }"><img src="./assets/images/right4.png" /></span>
        </div>
        <div class="discHome" [ngClass]="{ activeDisc: selectedVideo4 }">
          <table>
            <tr>
              <td>
                <p>
                  In the Library, you'll find a distilled collection of content
                  — movies, articles, books, and more — that focuses on
                  meaningful work experiences, enabling you to cut through the
                  noise and focus on exploring high-quality resources that
                  entertain, inspire, and motivate you.
                </p>
              </td>
            </tr>
            <tr>
              <td align="right">
                <a class="btn begin_quiz" (click)="
                    loginpage('Constellation', '/library/all')
                  ">Visit the Library</a>
              </td>
            </tr>
          </table>
        </div>
      </li>
      <li class="hm3">
        <div (click)="toggleContent(3)">
          <span>
            Get guidance on where to go next
            <!-- <span>Take to a Guide</span> -->
          </span>
          <span class="icn" [ngClass]="{ active: selectedVideo3 }"><img src="./assets/images/right3.png" /></span>
        </div>
        <div class="discHome" [ngClass]="{ activeDisc: selectedVideo3 }">
          <table>
            <tr>
              <td>
                <p>
                  Guides are people from different walks of life who’ve had
                  success in navigating the world of work. They've been trained
                  to help you make the most of your MeaningSphere experience. By
                  listening carefully to you, they’ll help you explore the
                  big question: “What is the meaning of my work to me?”
                </p>
              </td>
            </tr>
            <tr>
              <td align="right">
                <a class="btn begin_quiz" (click)="loginpage('Guide', '/discover/search-for-a-guide')">Talk to a
                  Guide</a>
              </td>
            </tr>
          </table>
        </div>
      </li>
    </ul>
  </div>
  <div>
    <ul class="d-flex foot-buttons p-0 m-0">
      <!-- <li class="wlcme-1">
        <a [routerLink]="'/home/take-map-of-meaningquiz'"></a>
      </li>
      <li class="wlcme-2">
        <a [routerLink]="'/home/start-making-connections'"></a>
      </li>
      <li class="wlcme-3"><a [routerLink]="'/home/take-to-guide'"></a></li>
      <li class="wlcme-4">
        <a [routerLink]="'/home/become-a-contributor'"></a>
      </li>
      <li class="wlcme-5">
        <a [routerLink]="'/home'"
          ><img src="./assets/media/users/default1.png"
        /></a>
      </li> -->
    </ul>
  </div>
</div>