<div class="{{ eventId == 0 ? 'mat-dialog-popup' : '' }}">
  <table width="100%">
    <tr>
      <td>
        <h1>{{header_set_up_discussion}}</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form
    [formGroup]="dialogForm"
    (ngSubmit)="createSetupDiscussionFromRequest(dialogForm.value)"
    novalidate
    enctype="multipart/form-data"
    autocomplete="off"
  >
    <div class="">
      <div class="row">
        <div class="col-sm-12">
          <p>
            Discussion with:
            <img
              class="img-fluid curator-createimg"
              [src]="menteeDp"
            />
            <label style="cursor: pointer">&nbsp;&nbsp;{{ mentee }}</label>
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field style="width: 100%;">
            <mat-label>Purpose </mat-label>
            <textarea
              matInput
              autocomplete="off"
              maxlength="700"
              oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&!@#$%=*-+?() ]|^ /g,'')"
              formControlName="description"
              rows="4"
            >
            </textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">

        <!-- <div class="col-lg-4 col-md-12 col-sm-12">
          <mat-form-field style="width: 100%;">
            <mat-label>Discussion Date </mat-label>
            <input
              matInput
              [matDatepicker]="start_date"
              formControlName="start_date"
              [min]="myDate"
            />
            <mat-datepicker-toggle matSuffix [for]="start_date">
            </mat-datepicker-toggle>
            <mat-datepicker #start_date></mat-datepicker>
          </mat-form-field>
        </div> -->

        <div class="col-lg-4 col-md-12 col-sm-12">
          <mat-form-field style="width: 100%;">
            <mat-label>Discussion Date </mat-label>
            <input
              matInput
              readonly
              [matDatepicker]="start_date"
              formControlName="start_date"
              [min]="current_time_zone_date"
              (dateInput)="onChangedate('input', $event)"
            />
            <mat-datepicker-toggle matSuffix [for]="start_date">
            </mat-datepicker-toggle>
            <mat-datepicker #start_date></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-lg-4 col-md-12 col-sm-12" >
          <mat-form-field style="width: 100%;">
            <mat-label>Discussion Time </mat-label>
            <input
              matInput
              readonly
              [min]="minTime"
              [ngxTimepicker]="toggleTimepicker"
              formControlName="timeFrom"
              [disableClick]="true"
            />
            <ngx-material-timepicker-toggle [for]="toggleTimepicker">
            </ngx-material-timepicker-toggle>
            <ngx-material-timepicker
              #toggleTimepicker
              (timeSet)="onChangeFrom($event)"
            >
            </ngx-material-timepicker>
            <!-- <ngx-material-timepicker
              (timeSet)="onChangeFrom($event)"
              #toggleTimepicker
            >
            </ngx-material-timepicker> -->
          </mat-form-field>
        </div>

        <div class="col-lg-4 col-md-12 col-sm-12">
            <mat-form-field style="width: 100%;" class="disable-focus">
              <mat-label>Duration (In Minutes) </mat-label>
              <input
                matInput
                readonly
                formControlName="duration"
                (change)="onChangeFromTime($event)"
                value="0"
                maxlength="3"
                type="number" 
                min="0"
                max="180"
                oninput="this.value = this.value.replace(/[^0-9-]|-^ /g,'')"
              />
            </mat-form-field>
        </div>
      </div>
      <hr />
    </div>
    <div class="row">
      <div class="col-sm-6"><p>This is a complimentary discussion!</p></div>
      <div class="col-sm-6 tr">
        <button
          [disabled]="!dialogForm.valid || saveButton"
          class="btn begin_quiz ml-2"
          type="submit"
        >
          Save
        </button>

        <button
          mat-dialog-close
          class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
