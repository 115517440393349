<mat-card
  id="infoPopup"
  class="archive_main_card bg-color-transp back_card_background_clr"
>
  <div class="infoPopupheader">
    <table width="100%">
      <tr>
        <td align="left">
          <h1 class="text-align-center color-white">Hi {{ fullname }}!</h1>
          <br />

          <h2 class="text-align-center color:whitesmoke color-white">
            Learn about all the spaces you can explore <br />
            on your journey to meaning
          </h2>
        </td>
        <td align="right" class="vertical-align-top" ></td>
      </tr>
    </table>
  </div>
  <span class="close-dialog" (click)="close()">X</span>

  <div class="infoBody">
    <!---first row with 4 images-->
    <mat-dialog-content class="heightScroll">
      <div class="d-flex justify-content-evenly row">
        <div class="column col-sm-12 col-md-6 col-lg-3">
          <img
            src="./assets/media/sidebar/my_guide.png"
            alt="My Guide"
          />
          <div class="dropdown">
            <span
            (click)="myGuide(128,name_guide,parameter_value_guide)"
              class="btn dropdown-toggle"
              
              id="dropdownMenuButton"
              data-toggle="dropdown"
            >
              My Guide
          </span>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
            ></div>
          </div>
        </div>

        <div class="column col-sm-12 col-md-6 col-lg-3">
          <img
            src="./assets/media/sidebar/mom.png"
            alt="Meaningful Work Survey"
          />
          <div class="dropdown">
            <span
            (click)="mapOfMeaning(126,name_map,parameter_value_map)"
              class="btn dropdown-toggle"
              
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
            Meaningful Work Survey
          </span>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
            ></div>
          </div>
        </div>

        <div class="column col-sm-12 col-md-6 col-lg-3">
          <img src="./assets/media/sidebar/profile.png" alt="Portrait" />
          <div class="dropdown">
            <span
            (click)="myPortrait(125,name_port,parameter_value_port)"
            class="btn dropdown-toggle"
              
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              My Portrait
          </span>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
            ></div>
          </div>
        </div>

        <div class="column col-sm-12 col-md-6 col-lg-3">
          <img
            src="./assets/media/sidebar/mirror.png"
            alt="My Mirror"
          />
          <div class="dropdown">
            <span
            (click)="mirrorList(127,name_mirror, parameter_value_mirror)"
              class="btn dropdown-toggle"
              
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              My Mirror
          </span>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
            ></div>
          </div>
        </div>
      </div>
      <!---second row with 4 images-->
      <div class="d-flex justify-content-evenly row">
        <div class="column col-sm-12 col-md-6 col-lg-3">
          <img
            src="./assets/media/sidebar/meaning_central.png"
            alt="Meaning Central"
          />
          <div class="dropdown">
            <span
            (click)="network(130, name_net, parameter_value_net)"
              class="btn dropdown-toggle"
              
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Meaning Network
          </span>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
            ></div>
          </div>
        </div>

        <div class="column col-sm-12 col-md-6 col-lg-3">
          <img
            src="./assets/media/sidebar/community.png"
            alt="Meaning Communities"
          />
          <div class="dropdown">
            <span
            (click)="community(131, name_com, parameter_value_com)"
              class="btn dropdown-toggle"
              
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Meaning Communities
          </span>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
            ></div>
          </div>
        </div>

        <div class="column col-sm-12 col-md-6 col-lg-3">
          <img
            src="./assets/media/sidebar/circles.png"
            alt="Meaning Circles"
          />
          <div class="dropdown">
            <span
            (click)="circles(132,name_cir, parameter_value_cir)"
              class="btn dropdown-toggle"
              
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Meaning Circles
          </span>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
            ></div>
          </div>
        </div>

        <div class="column col-sm-12 col-md-6 col-lg-3">
          <img
            src="./assets/media/sidebar/lib.png"
            alt="Library"
          />
          <div class="dropdown">
            <span
            (click)="library(129,name_lib, parameter_value_lib)"
              class="btn dropdown-toggle"
              
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Meaning Library
          </span>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
            ></div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</mat-card>