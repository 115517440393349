import { EventCancellationRequestComponent } from './../../../pages/my-mentor/event-cancellation-request/event-cancellation-request.component';
import { SetupDiscussionComponent } from '../../../pages/my-mentor/setup-discussion/setup-discussion.component';
import { NotificationService } from './../../../../service/notification.service';
import { CuratorPageService } from './../../../../service/curator-page.service';
import { MatDialog } from '@angular/material/dialog';
import { MentorService } from './../../../../service/mentor.service';

import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as moment from 'moment-timezone';
import { SetupDiscussionViewComponent } from '../../../pages/my-mentor/setup-discussion-view/setup-discussion-view.component';
import { SetupDiscussionFromGuideComponent } from '../../../pages/my-mentor/setup-discussion-from-guide/setup-discussion-from-guide.component';
import { MarkedAsCompletedComponent } from '../../../pages/my-mentor/marked-as-completed/marked-as-completed.component';
import { message_properties } from '../../../../../environments/message_properties';
import { AccountsService } from './../../../../service/accounts.service';
import { TimezoneService } from './../../../../service/timezone.service';

export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(4, 200, 400);
  }
}
@Component({
  selector: 'kt-guide-discussion-event',
  templateUrl: './guide-discussion-event.component.html',
  styleUrls: ['./guide-discussion-event.component.scss'],
})
export class GuideDiscussionEventComponent implements OnInit {
  myEventSearchForm: UntypedFormGroup;

  minDate;
  disabled = true;
  maxDate = new Date();
  isFromDateNoted = false;
  reportData: any[];
  guidesdata = [];
  count: number;
  subscription: Subscription;
  loggedInUser: number;
  indicator: number;
  mentorId: any;
  loggedinUserid: number;
  eventId: number;
  start_date: string;
  first_name: string;
  last_name: string;
  image_path: string;
  myDiscussionData = [];
  myScheduledDiscussionData = [];
  myScheduledDiscussionCount: number;
  selected_tab: string;
  current_timezone: string;
  public from_date: moment.Moment;
  constructor(
    private _cdref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private elementRef: ElementRef,
    private spinner: NgxSpinnerService,
    private mentor_service: MentorService,
    private dialog: MatDialog,
    private toastr: NotificationService,
    private curatorService: CuratorPageService,
    private accountService: AccountsService,
    private timeZoneService: TimezoneService
  ) {}

  ngOnInit(): void {
    this.loggedinUserid = this.accountService.muser;
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.myEventSearchForm = this.formBuilder.group({
      within_date_range: new UntypedFormControl(false),
      date_from: new UntypedFormControl(''),
      date_to: new UntypedFormControl(''),
      status_checked: new UntypedFormControl(false),
      status_option: new UntypedFormControl(''),
    });
    this.curatorService.all_status_change_refresh.subscribe((data) => {
      this.getMyScheduledDiscussionList();
    });
    this.curatorService.refreshneededEvent.subscribe((data)=>{
      this.getMyScheduledDiscussionList();
    });
    this.mentor_service.requestrefreshneededForMarkAsClosed.subscribe(
      (data) => {
        this.getMyScheduledDiscussionList();
      }
    );
    this.getMyScheduledDiscussionList();
  }

  onChangeFrom(event) {
    this.minDate = event.value;
    this.myEventSearchForm.patchValue({
      endDate: this.minDate,
    });
    this._cdref.detectChanges();
  }
  tabChanged(event) {
    this.selected_tab = event.tab.textLabel;
  }
  onChangeTo(event) {
    if (
      this.myEventSearchForm.value.startDate === '' ||
      this.myEventSearchForm.value.startDate === null
    ) {
      this.myEventSearchForm.patchValue({
        startDate: '2020-01-01',
        // startDate: this.maxDate,
      });

      if (
        this.myEventSearchForm.value.startDate >
        this.myEventSearchForm.value.endDate
      ) {
        this.myEventSearchForm.patchValue({
          // startDate: '2020-01-01',
          startDate: new Date(
            // '2020-01-01'
            new Date(this.myEventSearchForm.value.endDate).setDate(
              new Date(this.myEventSearchForm.value.endDate).getDate() - 1
            )
          ),
        });
      }
    }
    if (
      this.myEventSearchForm.value.startDate >
      this.myEventSearchForm.value.endDate
    ) {
      this.myEventSearchForm.patchValue({
        startDate: new Date(
          new Date(this.myEventSearchForm.value.endDate).setDate(
            new Date(this.myEventSearchForm.value.endDate).getDate() - 1
          )
        ),
      });
    }
  }

  getMyScheduledDiscussionList() {
    this.spinner.show();
    // group_for=2234&&status=&&q=2&&listType=0&&creating_member=0&&limit=0
    this.mentor_service
      .getMyDiscussionListByGet(this.loggedinUserid, 0)
      .subscribe((data) => {
        this.myScheduledDiscussionData = data.results;
        this.myScheduledDiscussionCount = data.count;
        this.spinner.hide();
        this._cdref.detectChanges();
      });
  }
  searchFromMyDiscussionForm(ngForm: NgForm) {
    this.myDiscussionData = [];
    this.spinner.show();
    this.mentor_service
      .getMyDiscussionListByPost(this.loggedinUserid, 1, ngForm)
      .subscribe((data) => {
        this.myDiscussionData = data.results;
        this.count = data.count ? data.count : 0;
        this.spinner.hide();
        this._cdref.detectChanges();
      });
  }
  returnImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }

  event_type_textReturn(event_type) {
    if (event_type === 2548) {
      return 'Get Acquainted';
    } else if (event_type === 2549) {
      return 'Be My Guide Discussion';
    } else if (event_type === 2733) {
      return 'Complimentary Discussion';
    } else if (event_type === 2550) {
      return 'General Discussion';
    } else if (event_type === 2554) {
      return 'Meaningful Work Survey';
    } else if (event_type === 2556) {
      return 'Mirror Reflection';
    } else {
      return 'DIY Discussion';
    }
  }

  event_status_textReturn(status) {
    if (status === 0) {
      return 'Requested';
    } else if (status === 1) {
      return 'Published';
    } else if (status === 2) {
      return 'Canceled';
    } else if (status === 3) {
      return 'Scheduled';
    } else if (status === 4) {
      return 'Canceled'; // Auto canceled
    } else if (status === 5) {
      return 'Completed';
    } else if (status === 6) {
      return 'Closed';
    } else if (status === 7) {
      return 'Completed'; // Auto Completed
    } else if (status === 8) {
      return 'Withdrawn';
    } else if (status === 9) {
      return 'Request Declined';
    } else if (status === 10) {
      return 'Cancelation Requested';
    } else if (status === 11) {
      return 'Canceled due to request';
    } else if (status === 12) {
      return 'Auto Withdrawn';
    }
  }
  requestForCancelledDiscussion(event_id, guide, explorer) {
    this.dialog.open(EventCancellationRequestComponent, {
      disableClose: true,
      data: {
        eventId: event_id,
        guide: guide,
        explorer: explorer,
        clicked_from: 'endorse_guide',
      },
    });
  }
  requestCancelledDiscussion(event_id, guide, explorer) {
    this.dialog.open(EventCancellationRequestComponent, {
      disableClose: true,
      data: {
        eventId: event_id,
        guide: guide,
        explorer: explorer,
        clicked_from: 'shared_guide',
      },
    });
  }
  openPopupForDiscussionEditGuide(
    eventId,
    mentee_pic,
    mentee_id,
    mentee_full_name,
    created_by_guide
  ) {
    this.dialog.open(SetupDiscussionFromGuideComponent, {
      disableClose: true,
      data: {
        eventId: eventId,
        created_by_guide: created_by_guide,
        dp: mentee_pic,
        menteeId: mentee_id,
        mentee: mentee_full_name,
        clicked_from: 'from_guide',
      },
    });
  }
  openPopupForDiscussionEditExplorer(
    eventId,
    mentee_pic,
    mentee_id,
    mentee_full_name,
    created_by_guide,
    member_relationship_id
  ) {
    this.dialog.open(SetupDiscussionComponent, {
      disableClose: true,
      data: {
        eventId: eventId,
        dp: mentee_pic,
        menteeId: mentee_id,
        mentee: mentee_full_name,
        clicked_from: 'from_guide',
        created_by_guide: created_by_guide,
        member_relationship_id: member_relationship_id,
      },
    });
  }
  resheduledDiscussion(
    eventId,
    mentee_pic,
    mentee_id,
    mentee_full_name,
    created_by_guide,
    member_relationship_id,
    active
  ) {
    if (created_by_guide === true) {
      this.dialog.open(SetupDiscussionFromGuideComponent, {
        disableClose: true,
        data: {
          eventId: eventId,
          dp: mentee_pic,
          menteeId: mentee_id,
          mentee: mentee_full_name,
          clicked_from: 'from_guide',
          created_by_guide: created_by_guide,
          member_relationship_id: member_relationship_id,
          active: active,
        },
      });
    } else {
      SetupDiscussionComponent;
      this.dialog.open(SetupDiscussionComponent, {
        disableClose: true,
        data: {
          eventId: eventId,
          dp: mentee_pic,
          menteeId: mentee_id,
          mentee: mentee_full_name,
          clicked_from: 'from_guide',
          created_by_guide: created_by_guide,
          member_relationship_id: member_relationship_id,
          active: active,
        },
      });
    }
  }

  cancelledDiscussion(event_id, active, guide, explorer) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to Cancel this discussion.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.curatorService
          .updateEventDiscussionStatusChangeEventHeader(
            event_id,
            active,
            0,
            guide,
            explorer
          )
          .subscribe((data) => {
            if (data.code === 200) {
              this.toastr.showSuccess(
                message_properties.CANCELLED_DISCUSSION_SUCCESS,
                ''
              );
              this._cdref.detectChanges();
            } else {
              this.toastr.showSuccess(
                'Some Technical Issue Found Here, Please contact the administrator!',
                ''
              );
              this._cdref.detectChanges();
            }
          });
      }
    });
  }

  withdrawnDiscussion(eventId, action, guide, explorer) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to Withdrawn Discussion.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.curatorService
          .updateEventDiscussionStatusChangeEventHeader(
            eventId,
            action,
            0,
            guide,
            explorer
          )
          .subscribe((data) => {
            if (data.code === 200) {
              this.toastr.showSuccess('Withdrawn discussion successfully!', '');
              this._cdref.detectChanges();
            } else {
              this.toastr.showSuccess(
                'Some Technical Issue Found Here, Please contact the administrator!',
                ''
              );
              this._cdref.detectChanges();
            }
          });
      }
    });
  }

  joinDiscussion(
    eventId,
    action,
    member_meeting_link,
    zoom_start_join_check,
    guide,
    explorer
  ) {
    if (zoom_start_join_check) {
      this.toastr.showError(message_properties.ZOOM_MEETING_TIME_ERROR, '');
    } else {
      Swal.fire({
        title: 'Please confirm!',
        text: 'You want to Join Discussion.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.curatorService
            .updateEventDiscussionStatusChangeEventHeader(
              eventId,
              action,
              3,
              guide,
              explorer
            )
            .subscribe((data) => {
              if (data.code === 200) {
                this.toastr.showSuccess('Joined discussion successfully!', '');
                this._cdref.detectChanges();
              } else {
                this.toastr.showSuccess(
                  'Some Technical Issue Found Here, Please contact the administrator!',
                  ''
                );
                this._cdref.detectChanges();
              }
            });
          window.open(member_meeting_link);
        }
      });
    }
  }
  acceptDiscussion(eventId, action, guide, explorer) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to Accept request.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.curatorService
          .updateEventDiscussionStatusChangeEventHeader(
            eventId,
            action,
            0,
            guide,
            explorer
          )
          .subscribe((data) => {
            if (data.code === 200) {
              this.toastr.showSuccess('Accepted discussion successfully!', '');
              this._cdref.detectChanges();
            } else {
              this.toastr.showSuccess(
                'Some Technical Issue Found Here, Please contact the administrator!',
                ''
              );
              this._cdref.detectChanges();
            }
          });
      }
    });
  }
  declinedDiscussion(eventId, action, guide, explorer) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to Declined request.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.curatorService
          .updateEventDiscussionStatusChangeEventHeader(
            eventId,
            action,
            0,
            guide,
            explorer
          )
          .subscribe((data) => {
            if (data.code === 200) {
              this.toastr.showSuccess('Declined discussion successfully!', '');
              this._cdref.detectChanges();
            } else {
              this.toastr.showSuccess(
                'Some Technical Issue Found Here, Please contact the administrator!',
                ''
              );
              this._cdref.detectChanges();
            }
          });
      }
    });
  }

  startDiscussion(
    eventId,
    action,
    host_meeting_link,
    zoom_start_join_check,
    guide,
    explorer
  ) {
    if (zoom_start_join_check) {
      this.toastr.showError(message_properties.ZOOM_MEETING_TIME_ERROR, '');
    } else {
      Swal.fire({
        title: 'Please confirm!',
        text: 'You want to Start discussion.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.curatorService
            .updateEventDiscussionStatusChangeEventHeader(
              eventId,
              action,
              0,
              guide,
              explorer
            )
            .subscribe((data) => {
              if (data.code === 200) {
                this.toastr.showSuccess('Started discussion successfully!', '');
                this._cdref.detectChanges();
              } else {
                this.toastr.showSuccess(
                  'Some Technical Issue Found Here, Please contact the administrator!',
                  ''
                );
                this._cdref.detectChanges();
              }
            });
          window.open(host_meeting_link);
        }
      });
    }
  }
  viewDetails(
    event_id,
    mentee_id,
    mentee_pic,
    mentee_full_name,
    created_by_guide,
    member_relationship_id
  ) {
    this.dialog.open(SetupDiscussionViewComponent, {
      disableClose: true,
      data: {
        eventId: event_id,
        menteeId: mentee_id,
        dp: mentee_pic,
        mentee: mentee_full_name,
        clicked_from: 'view_only',
        created_by_guide: created_by_guide,
        member_relationship_id: member_relationship_id,
      },
    });
  }

  markDiscussionCompleted(
    eventId,
    action,
    mentee_pic,
    mentee_full_name,
    event_type,
    mentee_id
  ) {
    if (event_type === 2550) {
      this.dialog.open(MarkedAsCompletedComponent, {
        disableClose: true,
        width: '30%',
        data: {
          eventId: eventId,
          menteeFullName: mentee_full_name,
          menteeDp: mentee_pic,
          action: action,
          event_type: event_type,
        },
      });
    } else {
      Swal.fire({
        title: 'Please confirm!',
        text: 'This discussion will be marked as closed.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          const formData = {
            actual_duration: 1,
            active: 6,
            action: action,
            event_type: event_type,
            eventId: eventId,
            guideId: this.loggedinUserid,
            exploterId: mentee_id,
          };
          this.mentor_service.discussionMarkedAsCompleted(formData).subscribe(
            (data) => {
              if (data.status === 201) {
                this.toastr.showSuccess(data.message, '');
              } else {
                this.spinner.hide();
                this.toastr.showError('Not Saved', '');
              }
            },
            (error) => {
              this.spinner.hide();
              this.toastr.showError('Not Saved', '');
            }
          );
          this.toastr.showSuccess(
            'Discussion marked as completed successfully!',
            ''
          );
        }
      });
    }
  }

  requestedCancelledDiscussionfromexlorer(
    event_id,
    action,
    guide,
    explorer,
    event_type
  ) {
    this.dialog.open(EventCancellationRequestComponent, {
      disableClose: true,
      width: '40%',
      data: {
        eventId: event_id,
        guide: guide,
        action: action,
        explorer: explorer,
        clicked_from: 'shared_guide',
        eventType: event_type,
      },
    });
  }

  dateInUserTimezome(datetime: string) {
    this.from_date = moment(datetime).utc();
    this.timeZoneService.setTenantTimeZone(this.current_timezone);
    return this.timeZoneService.utcToTenantString(this.from_date, 'LLL');
  }
}
