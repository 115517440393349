import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CuratorPageService } from '../../../../../service/curator-page.service';
import { ViewProfileComponent } from '../../../admin-page/view-profile/view-profile.component';
import { AccountsService } from './../../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-single-community-popup-host-list',
  templateUrl: './single-community-popup-host-list.component.html',
  styleUrls: [
    './single-community-popup-host-list.component.scss',
    '../../user-community/user-community.component.scss',
  ],
})
export class SingleCommunityPopupHostListComponent
  implements OnInit, OnDestroy {
  @Input() communityId;
  loggedInUserId: number;
  hostListForm: UntypedFormGroup;
  circleData = [];
  hostData = [];
  circle_host_count: number;
  groupHostSubscription: Subscription;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private curatorService: CuratorPageService,
    private cdRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedInUserId = this.accountService.muser;

    this.hostListForm = this.formBuilder.group({
      groupId: new UntypedFormControl(this.communityId),
      limit: new UntypedFormControl(0),
      loggedInUser: new UntypedFormControl(this.loggedInUserId),
      page: 1,
    });
    this.listOfHostsInGroup();
  }
  listOfHostsInGroup() {
    this.spinner.show();
    const FormData = this.hostListForm.value;
    this.groupHostSubscription = this.curatorService
      .singleGroupAllHostsList(FormData)
      .subscribe((data) => {
        this.circleData = data.results;
        this.circle_host_count = data.count;
        this.cdRef.detectChanges();
        this.spinner.hide();
      });
  }
  viewProfile(member_id, first_name, last_name, picture_path) {
    let dialogRef = this.dialog.open(ViewProfileComponent, {
      width: '80%',
      disableClose: true,
      data: {
        member: member_id,
        first_name: first_name,
        last_name: last_name,
        picture_path: picture_path,
        click_from: 'popup',
      },
    });
  }
  ngOnDestroy(): void {
    if (this.groupHostSubscription) {
      this.groupHostSubscription.unsubscribe();
    }
  }
}
