import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { AuthService } from '../service/auth.service';
import { NgxPendoService } from 'ngx-pendo';
import { finalize, tap } from 'rxjs/operators';
import { AccountsService } from './../service/accounts.service';
const started = Date.now();
let ok: string;

@Injectable()
export class PendoInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    protected ngxPendoService: NgxPendoService
  ) {}

  intercept(req, next) {
    let authService = this.injector.get(AccountsService);
    const loggedIn = authService.loggedIn;

    return next.handle(req).pipe(
      tap(
        // (event: HttpEvent<any>) => ok = event instanceof HttpResponse ? 'successed' : '',
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && event.status === 201) {
          }
        },
        (error: HttpErrorResponse) => (ok = 'failed')
      ),
      finalize(() => {
        const elasped = Date.now() - started;
        const msg = `${req.method} "${req.urlWithParams}" ${ok} in ${elasped} ms.`;
        const check = true;
        if (check) {
          if (localStorage.getItem('var1')) {
            const admin_indicator = authService.admin_indicator;
            const curator_indicator = authService.curator_indicator;
            const mentor_indicator = authService.mentor_indicator;
            const employee_indicator = authService.employee_indicator;
            const program_director_indicator =
              authService.program_director_indicator;
            const admin = admin_indicator ? 'YES' : 'NO';
            const curator = curator_indicator ? 'YES' : 'NO';
            const program_director = program_director_indicator ? 'YES' : 'NO';
            const guide = mentor_indicator ? 'YES' : 'NO';
            const employee = employee_indicator ? 'YES' : 'NO';
            const mfid = localStorage.getItem('mfid')
              ? atob(localStorage.getItem('mfid'))
              : String(Date.now());
            const pendo_data = {
              id: mfid,
              name: '',
              email: '',
              admin: admin,
              curator: curator,
              program_director: program_director,
              guide: guide,
              employee: employee,
            };
            this.ngxPendoService.initialize(pendo_data);
          }
        }
      })
    );
  }
}
