import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-take-map-of-meaning-quiz',
  templateUrl: './take-map-of-meaning-quiz.component.html',
  styleUrls: ['./take-map-of-meaning-quiz.component.scss']
})
export class TakeMapOfMeaningQuizComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
