<div class="mat-dialog-popup">
  <h1 mat-dialog-title>
    <div class="head_top_section">
      <h1 class="card_title">Compare my Responses</h1>
    </div>
    <button mat-button mat-dialog-close class="modal_close_button_top">
      X
    </button>
    <hr />
  </h1>
  <div class="fixed_cntent fixed_cntent-pop">
    <form
      [formGroup]="compareForm"
      (ngSubmit)="compareSurvey(compareForm.value)"
      novalidate
      autocomplete="off"
    >
      <div class="card-body">
        <div class="row">
          <label class="cmp-label">
            First Survey selected for comparison:
            {{ dateInUserTimezome(_datetime) | date: "MMM d, y h:mm a" }}
          </label>
        </div>
        <br />
        <div class="row compareSurvey">
          <label>
            Select the second survey to compare with
          </label>
          <ng-select
            class="custom w-50" 
            bindLabel="name"
            formControlName="selected_survey"
            multiple="true"
            maxSelectedItems="1"
          >
            <ng-container *ngFor="let survey of momData">
              <ng-option
                [value]="survey['id'] + '_' + survey['submitted_at']"
                *ngIf="
                  currentSelectedSurvey != survey['id'] &&
                  survey['survey_status'] == '2252'
                "
              >
                {{ survey["submitted_at"] | date: "medium" }}
              </ng-option>
            </ng-container>
          </ng-select>
        </div>
        <br />
      </div>
      <hr />
      <div class="row">
        <div class="col-sm-12 mt-5">
          <table width="100%" border="0" class="">
            <tr>
              <td align="right">
                <button
                  [disabled]="!compareForm.valid"
                  class="btn begin_quiz"
                  type="submit"
                >
                  GO
                </button>

                <button
                  mat-dialog-close
                  class="btn begin_quiz ml-2 canceled_btn"
                >
                  Cancel
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </form>
  </div>
</div>
