<kt-spinner></kt-spinner>
<div class="network_section mat-card-style2">
  <div class="row">
    <div class="col-sm-3 sub-card-left">
      <mat-card class="network_card back_card_background_clr icon-gap">
        <div class="d-flex justify-content-arround align-items-center">
          <img class="w-124-pm" [src]="profile_path_for_logged_in_user" />

          <p class="plus-no-border special-char" style="font-size: 24px; font-weight: 600">&amp;</p>

          <img class="w-124-pm" [src]="image_path" />
        </div>
        <div class="endor-guid-det">
          <h1 title="{{ first_name }}" class="text-align-center">
            {{ first_name }}
          </h1>
          <h2 title="{{ last_name }}" class="text-align-center">
            {{ last_name }}
          </h2>
          <h3 class="text-location">{{ city }}, {{ country }}</h3>
          <p class="space-para" *ngIf="isPastGuide==0">
            Guiding since:
            <span class="">{{ start_date | date: "MMM d, y" }}</span>
          </p>
          <p class="space-para" *ngIf="isPastGuide==1">
            {{ start_date | date: "MMM d, y" }} - {{ end_date | date: "MMM d, y" }}
          </p>
          <p class="space-para" *ngIf="isPastGuide==1 && checkStatus(member_status)">
            <span style="color:#ec7422 !important">(This Guide is no longer active)</span>
          </p>
        </div>
        <div class="border-top">
          <div class="d-flex justify-content-end align-items-center pt-5 pb-5" *ngIf="isPastGuide != 1">
            <i
              class="endorse-comment mr-15"
              aria-hidden="true"
              (click)="endorseGuide(mentorId)"
            >
              <img
                src="./assets/media/icon/endorseguide.png"
                style="cursor: pointer; width: 1.75rem; height: 1.75rem"
                class="guide_icon_size"
                title="Endorse Guide"
              />
            </i>
            <i
              class="endorse-comment mr-10"
              (click)="sendMassage(0, mentorId)"
              ><img
                src="./assets/media/icon/Dialog@2x.png"
                class="guide_icon_size"
                title="Send Message"
                style="cursor: pointer; width: 2.5rem; height: 2.5rem"
            /></i>
            <span *ngIf="isPastGuide == 0">
              <i
              class="endorse-comment ml-10 mr-10"
              (click)="discontinueGuide(mentorId)"
              >
              <img
                src="./assets/media/icon/Discontinue.png"
                class="guide_icon_size" 
                
                title="Discontinue Guide"
                style="cursor: pointer; width:1.95rem; height: 1.95rem"
            /></i>
            </span>
          </div>
        </div>
      </mat-card>

      <mat-card class="network_card back_card_background_clr mat-card-padd" *ngIf="isPastGuide != 1">
        <div class="d-flex justify-content-between" (click)="toggledrpDown1()">
          <h2 class="title_name1 prof_list_title">Balance Summary</h2>
          <div class="arrow" [ngClass]="{ active: dropDown1 }">
            <img src="./assets/media/icon/Arrow_Black@2x.png" />
          </div>
        </div>
        <hr />
        <div *ngIf="dropDown1">
          <ng-container *ngIf="isPastGuide == 0">
            <p>Available Balance</p>
            <hr />
            <p class="balance-summary-para">
              <span class="cu_communit fs-12">General:</span>
              <span class="cu_community_date fs-12">
                {{ available_general_discussions_time }} Minutes
              </span>
            </p>
            <p class="balance-summary-para">
              <span class="cu_communit fs-12">Mirror Reflection:</span>
              <span class="cu_community_date fs-12">
                {{ available_guided_reflection_discussions }} Discussion(s)
              </span>
            </p>

            <p class="balance-summary-para">
              <span class="cu_communit fs-12">Meaningful Work Survey:</span>

              <span class="cu_community_date fs-12">
                {{ available_guided_mom_discussion }} Discussion(s)</span
              >
            </p>
          </ng-container>
          <ng-container *ngIf="isPastGuide == 1">
            <p>Completed discussions:</p>
            <hr />
            <p>
              <span class="cu_communit fs-12">General:</span>
              <span class="cu_community_date fs-12">
                 {{ completed_guide_discussions }} Minutes
              </span>
            </p>
            <p>
              <span class="cu_communit fs-12">Mirror Reflection:</span>
              <span class="cu_community_date fs-12">
                {{ completed_diy_discussions }} Discussion(s)
              </span>
            </p>
            <p>
              <span class="cu_communit fs-12">Meaningful Work Survey:</span>

              <span class="cu_community_date fs-12">
                {{ completed_mom_discussions }} Discussion(s)</span
              >
            </p>
          </ng-container>
          <br />
          <hr />
          <table border="0" width="100%">
            <tr>
              <td align="right" *ngIf="isPastGuide != 1">
                <button
                  class="btn begin_quiz begin_quiz-font ml-2 mt-1"
                  (click)="OpenPerchasePopoup()"
                >
                  Buy Guide Time
                </button>
              </td>
            </tr>
          </table>
        </div>
      </mat-card>
    </div>
    <div class="col-sm-9 sub-card-right">
      <div
        class="profile_card mb-3 mc_search back_card_background_clr card2-style-height"
      >
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Discussions">
            <kt-endorse-discussion
              [member_relationship_id]="relationshipId"
              [isPastGuide]="isPastGuide"
              [mentorId]="mentorId"
              [first_name]="first_name"
              [last_name]="last_name"
              [image_path]="image_path"
            >
            </kt-endorse-discussion>
          </mat-tab>
          <mat-tab label="Documents">
            <kt-endorse-documents
              [member_relationship_id]="relationshipId"
              [menteeId]="loggedinUserid"
              [mentorId]="mentorId"
            ></kt-endorse-documents>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
