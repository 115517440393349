import { Inject, OnDestroy } from '@angular/core';
import { Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SurveyService } from './../../../../service/survey.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-compare-survey-report',
  templateUrl: './compare-survey-report.component.html',
  styleUrls: ['./compare-survey-report.component.scss'],
})
export class CompareSurveyReportComponent implements OnInit, OnDestroy {
  selectedSurveyData = [];
  currentSelectedSurveyData = [];
  currentSelectedSurvey: any;
  currentDateTime: any;
  selectedSurvey: any;
  currentSelectedSurveyArr = [];
  selectedSurveyArr = [];
  selectedSurveyId: any;
  selectedSurveyDateTime: any;
  currentSurveyFinalArr = [];
  selectSurveyFinalArr = [];
  mirrorresponse = [
    'Never/hardly ever',
    'Seldom',
    'Sometimes',
    'Often',
    'Always/almost always',
  ];
  current_timezone: string;
  surveyCurrentSelectedSubscription: Subscription;
  surveySelectedSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<CompareSurveyReportComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private reports: SurveyService,
    private cdRef: ChangeDetectorRef
  ) {
    this.currentSelectedSurvey = atob(data.currentSelectedSurvey);
    this.currentDateTime = atob(data.currentDateTime);
    this.selectedSurvey = atob(data.selectedSurvey).split('_');
  }

  ngOnInit() {
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.selectedSurveyId = this.selectedSurvey[0];
    this.selectedSurveyDateTime = this.selectedSurvey[1];
    this.getCurrentSelectedSurvey(this.currentSelectedSurvey);
    this.getSelectedSurvey(this.selectedSurveyId);
  }

  getSelectedSurvey(selctedParticipantId) {
    const getSelectedSurvey = this.reports.getreports(selctedParticipantId);
    this.surveySelectedSubscription = getSelectedSurvey.subscribe((data) => {
      this.selectedSurveyData = JSON.parse(JSON.stringify(data.results));

      for (let selectedData of this.selectedSurveyData) {
        const finalstr = selectedData.answer_text[0]['text'];
        this.selectSurveyFinalArr.push(finalstr);
      }
      this.cdRef.detectChanges();
    });
  }

  getCurrentSelectedSurvey(selctedParticipantId) {
    const getCurrentSelectedSurvey = this.reports.getreports(
      selctedParticipantId
    );
    this.surveyCurrentSelectedSubscription = getCurrentSelectedSurvey.subscribe(
      (data) => {
        this.currentSelectedSurveyData = JSON.parse(
          JSON.stringify(data.results)
        );

        for (let currentSelectedData of this.currentSelectedSurveyData) {
          const finalcurrentstr = {
            question_current_category:
              currentSelectedData.question_response_text[0][
                'question_category'
              ],
            question_current_text:
              currentSelectedData.question_response_text[0]['question_text'],
            answer_current_text: currentSelectedData.answer_text[0]['text'],
            current_survey_datetime: this.currentDateTime,
          };
          this.currentSurveyFinalArr.push(finalcurrentstr);
        }
        this.cdRef.detectChanges();
      }
    );
  }
  ngOnDestroy(): void {
    if (this.surveyCurrentSelectedSubscription) {
      this.surveyCurrentSelectedSubscription.unsubscribe();
    }
    if (this.surveySelectedSubscription) {
      this.surveySelectedSubscription.unsubscribe();
    }
  }
}
