import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  apiUrl = environment.apiUrl;
  private _refreshneeded = new Subject<void>();
  private _MemberNotificationsList = new Subject<void>();
  get refreshneeded() {
    return this._refreshneeded;
  }
  get MemberNotificationsList() {
    return this._MemberNotificationsList;
  }
  constructor(private http: HttpClient) {}

  getAllMembersNotification(data): Observable<NotificationData> {
    return this.http.post<NotificationData>(
      this.apiUrl + 'chat/notifications/user-all-notification/', data
    );
  }
  getAllSystemUserNotification(data): Observable<NotificationData> {
    return this.http.post<NotificationData>(
      this.apiUrl + 'chat/notifications/system-user/', data
    );
  }
  updateStatusInCollabContent(ngForm) {
    return this.http
      .put<NotificationData>(
        this.apiUrl + 'chat/notifications/notification-soft-remove',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneeded.next();
        })
      );
  }
  updateReadStatusInCollabContent(ngForm) {
    return this.http
      .put<NotificationData>(
        this.apiUrl + 'chat/notifications/notification-read-status-change',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneeded.next();
        })
      );
  }

  getAllNotificationCount(logged_in_user_id): Observable<NotificationData> {
    return this.http.get<NotificationData>(
      this.apiUrl + 'chat/notifications/count-total-notification/' + logged_in_user_id
    );
  }
}

export interface NotificationData {
  collabcontentcategory: number;
  collabcontenttype: number;
  collabcontentstatus: number;
  creation_date: any;
  title: string;
  active: number;
  description: string;
  attachment_path: string;
  creating_user: string;
  suggesting_user: string;
  message: string;
  status: number;
  results: [];
  count: number;
  flaggedCount: number;
  creatingUser: {};
  suggistingUser: {};
  collab_content_category: number;
  collab_content_type: number;
  user_notifications: {};
  system_notifications: {};
  user_notification_count: number;
  system_notification_count: number;
  description_with_timezone_converted_string: string;
}
