import { RegisterService } from '../../../service/register.service';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
// Core Module
import { LoginComponent } from './login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteGuard } from '../../../guard/route.guard';
import { AuthService } from '../../../service/auth.service';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { RegisterComponent } from './register/register.component';
import { RegisterverificationComponent } from './registerverification/registerverification.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AcceptTermsComponent } from './accept-terms/accept-terms.component';
import { NotEligibleCountryFormComponent } from './not-eligible-country-form/not-eligible-country-form.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSelectModule } from 'ngx-select-ex';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    NgxSpinnerModule,
    NgSelectModule,
    ScrollingModule,
    MatAutocompleteModule,
    InfiniteScrollModule,
    NgxSelectModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'privacy-policy',
        component: AcceptTermsComponent,
      },
      {
        path: 'not-eligible-country',
        component: NotEligibleCountryFormComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'registerverification',
        component: RegisterverificationComponent,
      },
      {
        path: 'forgotpassword',
        component: ForgotpasswordComponent,
      },

      {
        path: '',
        component: LoginComponent,
      },
    ]),
  ],
  providers: [RouteGuard, AuthService, RegisterService],
  declarations: [
    LoginComponent,
    RegisterComponent,
    RegisterverificationComponent,
    ForgotpasswordComponent,
    AcceptTermsComponent,
    NotEligibleCountryFormComponent
  ],
})
export class LoginModule { }
