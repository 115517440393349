<kt-spinner></kt-spinner>
<div class="">
  <button mat-button mat-dialog-close class="modal_close_button_top">X</button>
  <h1 class="popup_card_title">Leave Community</h1>
  <hr />
  <form
    [formGroup]="withdrawForm"
    (ngSubmit)="memberLeaveCommunity(withdrawForm.value)"
    novalidate
    autocomplete="off"
  >
    <div class="row mx-1 mb-2">
      <div class="col-lg-4 col-md-4">
        <mat-checkbox
          class="matCheckBoxLabelClass"
          formControlName="is_not_active"
        >
          Community is not active
        </mat-checkbox>
      </div>
    </div>
    <div class="row mx-1 mb-2">
      <div class="col-lg-4 col-md-4">
        <mat-checkbox
          class="matCheckBoxLabelClass"
          formControlName="not_inspired_by_the_content"
        >
          I am not inspired by the content
        </mat-checkbox>
      </div>
    </div>
    <div class="row mx-1 mb-2">
      <div class="col-lg-4 col-md-4">
        <mat-checkbox
          class="matCheckBoxLabelClass"
          formControlName="is_not_aligned_to_my_interests"
        >
          It is not aligned to my interests
        </mat-checkbox>
      </div>
    </div>
    <div class="row mx-1 mb-2">
      <div class="col-lg-4 col-md-4">
        <mat-checkbox
          class="matCheckBoxLabelClass"
          formControlName="other_indicator"
        >
          Other
        </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <mat-form-field
          class="mat-form-field mat-primary mat-form-field-type-mat-input mat-form-field-appearance-legacy mat-form-field-can-float mat-form-field-has-label mat-form-field-invalid mat-form-field-hide-placeholder"
          style="width: 100%; color: #fff"
        >
          <mat-label>Remarks</mat-label>
          <textarea
            matInput
            formControlName="remarks"
            rows="8"
            autocomplete="off"
            maxlength="240"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&!@#$%=*-+?() ]|^ /g,'')"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0 mt-3 mb-1">
      <div class="col-lg-12 col-md-12 pr-0 text-right">
        <button
          class="btn begin_quiz mr-3"
          type="submit"
          [disabled]="!withdrawForm.valid"
        >
          LEAVE COMMUNITY
        </button>
        <button class="btn begin_quiz canceled_btn" mat-dialog-close>
          CANCEL
        </button>
      </div>
    </div>
  </form>
</div>
