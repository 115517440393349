<kt-spinner></kt-spinner>
<mat-card class="archive_main_card bg-color-transp back_card_background_clr">
  <form class="surveyForm"
    [formGroup]="surveyForm"
    (ngSubmit)="getNextPreviousQuestion(surveyForm)"
    novalidate
  >
    <section class="QuizContainer mt-4">
      <div class="quesarea pb-20">
        <div class="col-sm-12 text-center">
          <div class="progress" *ngIf="submitButtonShowAndHide">
            <div
              class="progress-bar"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="0"
              [style.width.%]="progress_bar"
              style="width: 33%;"
            >
              {{ progress_bar }} %
            </div>
          </div>
          <mat-card-content>
            <input type="hidden" formControlName="sequence_type" />
            <h1 class="QuestionText mt-2 fs-20" *ngIf="submitButtonShowAndHide">
              {{ survey_sequence }}. {{ question_text }}
            </h1>
            <div class="help_text my-2">{{ help_text }}</div>
            <div class="row" *ngIf="questiontype == 2250">
              <div class="RadioContainer survey-radio col-sm-12 radio-table">
                <mat-radio-group formControlName="question_answer">
                  <span *ngFor="let item of survey_answers">
                    <table width="100%">
                      <tr>
                        <td width="40%">&nbsp;</td>
                        <td align="left">
                          <mat-radio-button
                            tabindex="1"
                            value="{{ item['id'] }}"
                            [checked]="
                              selected_answer == item['id'] ? true : false
                            "
                            >{{ item["text"] }}</mat-radio-button
                          >
                        </td>
                        <td width="33%">&nbsp;</td>
                      </tr>
                    </table>
                  </span>
                </mat-radio-group>
              </div>
            </div>
          </mat-card-content>
        </div>
      </div>
      <mat-card-footer>
        <div class="quizfooter">
          <div class="row">
            <div class="col-sm-4" *ngIf="survey_sequence == 1"></div>
            <div class="col-sm-4 text-align-left" *ngIf="survey_sequence > 1"><a style="cursor:pointer;" (click)="sequenceType('L');getNextPreviousQuestion(surveyForm)">Save for Later</a></div>
            <div class="col-sm-2 text-center"></div>
            <div class="col-sm-6 ta-r">
              <a
                class="btn begin_quiz"
                placeholder="please select an option"
                *ngIf="survey_sequence > 1"
                (click)="sequenceType('P');getNextPreviousQuestion(surveyForm)"
              >
                Previous
            </a>

              <span
                class="quesnumber text-center"
                *ngIf="submitButtonShowAndHide"
              >
                {{ survey_sequence }} / {{ max_sequence_number }}
              </span>
              <button
                class="btn begin_quiz"
                placeholder="please select an option"
                *ngIf="survey_sequence < max_sequence_number"
                (click)="sequenceType('N')"
                (keypress)="sequenceType('N')"
                tabindex="2"
              >
                Next
              </button>
              <button
                class="btn begin_quiz"
                placeholder="please select an option"
                *ngIf="
                  survey_sequence == max_sequence_number &&
                  submitButtonShowAndHide
                "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </mat-card-footer>
    </section>
  </form>
</mat-card>
