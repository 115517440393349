import { MatDialog } from '@angular/material/dialog';
import { ViewProfileComponent } from './../../../pages/admin-page/view-profile/view-profile.component';
import { NotificationService } from '../../../../service/notification.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { BehaviorSubject, Subscription } from 'rxjs';
import { NotificationsService } from './notifications.service';

import { message_properties } from '../../../../../environments/message_properties';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { AccountsService } from './../../../../service/accounts.service';

@Component({
  selector: 'kt-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit, OnDestroy {
  uerNotificationForm: UntypedFormGroup;
  systemNotificationForm: UntypedFormGroup;
  loggedInUserId: number;
  collabcontentId: number;
  active: number;
  allUserNotifications = [];
  allSystemUserNotifications = [];
  user_notification_count: number;
  selected_tab: string;
  system_count: number;
  curator_indicator: string;
  current_timezone: string;
  admin_indicator: string;
  program_director_indicator: string;
  page_user_notofication = 2;
  usernotificationArr = [];
  allUserNotificationDataArr = new BehaviorSubject<any[]>([]);
  totalPageUserNotification: number;
  page_system_notofication = 2;
  systemNotificationArr = [];
  allSystemNotificationDataArr = new BehaviorSubject<any[]>([]);
  totalPageSystemNotification: number;

  user_throttle = 300;
  user_modalScrollDistance = 1;
  user_modalScrollThrottle = 50;

  system_throttle = 300;
  system_modalScrollDistance = 1;
  system_modalScrollThrottle = 50;
  notificationListSubscription: Subscription;
  systemNotificationSubscription: Subscription;
  activeNotificationSubscription: Subscription;
  removeNotificationSubscription: Subscription;
  expandSubscription: Subscription;
  constructor(
    private spinner: NgxSpinnerService,
    private notifications: NotificationsService,
    private toster: NotificationService,
    private _cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.admin_indicator = this.accountService.admin_indicator;
    this.curator_indicator = this.accountService.curator_indicator;
    this.program_director_indicator = this.accountService.program_director_indicator;
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.loggedInUserId = this.accountService.muser;

    this.uerNotificationForm = this.formBuilder.group({
      creating_user: new UntypedFormControl(this.loggedInUserId),
      page: new UntypedFormControl(1),
    });
    this.systemNotificationForm = this.formBuilder.group({
      creating_user: new UntypedFormControl(this.loggedInUserId),
      page: new UntypedFormControl(1),
    });
    this.getAllNotifications();
    this.getAllSystemNotifications();
  }

  pushValue(ev?) {
    if (this.user_notification_count !== this.allUserNotifications.length) {
      this.uerNotificationForm.patchValue({
        page: this.page_user_notofication,
      });
      this.getAllNotifications();
      this.page_user_notofication++;
    }
  }

  getAllNotifications(params = 0) {
    this.spinner.show();
    if (params === 1) {
      this.page_user_notofication = 2;
      this.uerNotificationForm.patchValue({
        page: 1,
      });
      this.allUserNotifications = [];
    }
    this.notificationListSubscription = this.notifications
      .getAllMembersNotification(this.uerNotificationForm.value)
      .subscribe((data) => {
        this.user_notification_count = data.count;
        // this.allUserNotifications = [
        //   ...new Map(
        //     this.allUserNotifications
        //       .concat(data.results)
        //       .map((item) => [item['id'], item])
        //   ).values(),
        // ];
        this.allUserNotifications = [
          ...new Set([...this.allUserNotifications, ...data.results]),
        ];
        this._cdr.detectChanges();
        this.spinner.hide();
      });
  }
  systemPushValue(ev?) {
    if (this.system_count !== this.allSystemUserNotifications.length) {
      this.systemNotificationForm.patchValue({
        page: this.page_system_notofication,
      });
      this.getAllSystemNotifications();
      this.page_system_notofication++;
    }
  }

  getAllSystemNotifications(params = 0) {
    this.spinner.show();
    if (params === 1) {
      this.page_system_notofication = 2;
      this.systemNotificationForm.patchValue({
        page: 1,
      });
      this.allSystemUserNotifications = [];
    }
    this.systemNotificationSubscription = this.notifications
      .getAllSystemUserNotification(this.systemNotificationForm.value)
      .subscribe((data) => {
        // this.allSystemUserNotifications = [
        //   ...new Map(
        //     this.allSystemUserNotifications
        //       .concat(data.results)
        //       .map((item) => [item['id'], item])
        //   ).values(),
        // ];
        this.allSystemUserNotifications = [
          ...new Set([...this.allSystemUserNotifications, ...data.results]),
        ];
        this.system_count = data.count;
        this._cdr.detectChanges();
        this.spinner.hide();
      });
  }
  inactiveNotification(collabcontentId) {
    const ngForm = {
      collabcontentId: collabcontentId,
      status: 1,
    };
    this.notifications
      .updateStatusInCollabContent(ngForm)
      .subscribe((data) => {
        this._cdr.detectChanges();
      });
  }
  tabChanged(event) {
    this.selected_tab = event.tab.textLabel;
  }
  removeNotification(collabcontentId) {
    const ngForm = {
      collabcontentId: collabcontentId,
      status: 4,
    };
    this.notifications
      .updateStatusInCollabContent(ngForm)
      .subscribe((data) => {
        this.toster.showSuccess(message_properties.NOTIFICATIONS_SUCCESS, '');
        this.getAllNotifications(1);
        this.getAllSystemNotifications(1);
        this._cdr.detectChanges();
      });
  }
  // read notification
  onExpand(id, notification) {
    const ngForm = {
      collabcontentId: id,
      read_status: 1,
    };
    this.notifications
      .updateReadStatusInCollabContent(ngForm)
      .subscribe((data) => {
        notification.read_status = true;
        this._cdr.detectChanges();
      });
  }
  viewProfile(member_id, first_name, last_name, picture_path, member_status) {
    if (member_status === 0 || member_status === 2) {
      this.toster.showInfo(message_properties.REQUEST_USER_INFO, '');
    } else {
      let dialogRef = this.dialog.open(ViewProfileComponent, {
        width: '80%',
        disableClose: true,
        data: {
          member: member_id,
          first_name: first_name,
          last_name: last_name,
          picture_path: picture_path,
          click_from: 'notification',
        },
      });
      dialogRef.afterClosed().subscribe(() => {
        this.getAllNotifications(1);
      });
    }
  }

  returnProfileImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }
  ngOnDestroy(): void {
    if (this.notificationListSubscription) {
      this.notificationListSubscription.unsubscribe();
    }
    if (this.systemNotificationSubscription) {
      this.systemNotificationSubscription.unsubscribe();
    }
    if (this.activeNotificationSubscription) {
      this.activeNotificationSubscription.unsubscribe();
    }
    if (this.removeNotificationSubscription) {
      this.removeNotificationSubscription.unsubscribe();
    }
    if (this.expandSubscription) {
      this.expandSubscription.unsubscribe();
    }
  }
}
