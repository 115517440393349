import {
  AfterViewInit,
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import * as jQuery from 'jquery';
import { Router } from '@angular/router';
import { CityStateCountryService } from '../../../service/city-state-country.service';
import { Subscription } from 'rxjs';
import { AccountsService } from './../../../service/accounts.service';

@Component({
  selector: 'kt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  selectedVideo: number = 0;
  public show = false;
  meaningsphere: boolean = false;
  isLoggedInUser: boolean = false;
  selectedVideo1: boolean = false;
  selectedVideo2: boolean = false;
  selectedVideo3: boolean = false;
  selectedVideo4: boolean = false;
  toggleTextName: boolean = false;
  public show1 = false;
  public buttonName: any = '+';
  cookieValue: string = '0';
  myDate = new Date();
  src: string;
  currentDate: string;
  countryListSubscription: Subscription;
  flagCountryDetailSubscription: Subscription;
  constructor(
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private country_service: CityStateCountryService,
    private accountService: AccountsService
  ) {}
  ngAfterViewInit() {
    let width = jQuery(window).width();
    if (width > 1024) {
      this.src = './assets/vedio/home.mp4';
    } else {
      this.src = './assets/vedio/home-centerd.mp4';
    }
    this._cdr.detectChanges();
  }

  ngOnInit() {
    this.pendoAndUserSnapDetails();
    this.flagCountryValidation();
    if (this.accountService.loggedIn) {
      this.isLoggedInUser = true;
      this._router.navigate(['meaning-network', 'my-feed']);
    } else {
      this.isLoggedInUser = false;
    }

    this.selectedVideo = 0;
  }
  toggleContent(t: number) {
    switch (t) {
      case 1:
        this.selectedVideo1 = !this.selectedVideo1;
        this.selectedVideo2 = false;
        this.selectedVideo3 = false;
        this.selectedVideo4 = false;
        break;
      case 2:
        this.selectedVideo1 = false;
        this.selectedVideo2 = !this.selectedVideo2;
        this.selectedVideo3 = false;
        this.selectedVideo4 = false;
        break;
      case 3:
        this.selectedVideo1 = false;
        this.selectedVideo2 = false;
        this.selectedVideo3 = !this.selectedVideo3;
        this.selectedVideo4 = false;
        break;
      case 4:
        this.selectedVideo1 = false;
        this.selectedVideo2 = false;
        this.selectedVideo3 = false;
        this.selectedVideo4 = !this.selectedVideo4;
        break;
      default:
        this.selectedVideo1 = false;
        this.selectedVideo2 = false;
        this.selectedVideo3 = false;
        this.selectedVideo4 = false;
        break;
    }
    if (
      this.selectedVideo1 === false &&
      this.selectedVideo2 === false &&
      this.selectedVideo3 === false &&
      this.selectedVideo4 === false
    ) {
      this.meaningsphere = false;
    } else {
      this.meaningsphere = true;
    }
  }
  selectVideo(t: number) {
    this.selectedVideo = t;
  }
  toggleText() {
    this.toggleTextName = !this.toggleTextName;
  }
  goHome() {
    this._router.navigate(['/about-meaningsphere']);
  }
  loginpage(data, link) {
    this._router.navigate(['/login/register'], {
      state: { text: data },
      queryParams: { returnUrl: link },
    });
  }

  flagCountryValidation() {
    if (localStorage.getItem('flag_country')) {
      return;
    }
    this.flagCountryDetailSubscription = this.country_service
      .getCountryFlagDetails()
      .subscribe((data) => {
        if (data.status === 200) {
          if (data.flag === true) {
            localStorage.setItem('flag_country', btoa('1'));
          } else {
            localStorage.setItem('flag_country', btoa('0'));
          }
        }
      });
  }
  pendoAndUserSnapDetails() {
    if (localStorage.getItem('var1')) {
      return;
    }
    this.countryListSubscription = this.country_service
      .getAllCountryList()
      .subscribe((response) => {
        localStorage.setItem('var1', response.pando_api);
        localStorage.setItem('var2', btoa(response.user_snap));
      });
  }

  ngOnDestroy(): void {
    if (this.countryListSubscription) {
      this.countryListSubscription.unsubscribe();
    }
    if (this.flagCountryDetailSubscription) {
      this.flagCountryDetailSubscription.unsubscribe();
    }
  }
}
