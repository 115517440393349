<ng-container *ngIf="upcoming_circle_count > 0">
  <div
    class="row py-2 px-4"
    *ngFor="let dialogObject of upcomingDialogListUnderCircleDatas"
  >
    <div class="col-lg-2 col-md-2 labelValueClass pl-0">
      {{ dialogObject?.start_date | date: "MMM d" }}
    </div>
    <div class="col-lg-10 col-md-10 labelValueClass pl-0">
      <p
        class="labelValueClass only_one_line_show mb-0"
        title="{{ dialogObject.name | titlecase }}"
      >
        {{ dialogObject?.name | titlecase }}
      </p>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="upcoming_circle_count == 0">
  <div class="row py-2 px-4">
    <div class="col-lg-10 col-md-10 labelValueClass pl-2">
      No Upcoming Dialogs Found!
    </div>
  </div>
</ng-container>
