import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';

@Component({
  selector: 'kt-view-guide-video',
  templateUrl: './view-guide-video.component.html',
  styleUrls: ['./view-guide-video.component.scss'],
})
export class ViewGuideVideoComponent implements OnInit {
  source: any;
  video_url: string;
  constructor(
    public dialogRef: MatDialogRef<ViewGuideVideoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.video_url = data.source || '0';
    this.source = data.source;
  }

  ngOnInit(): void {}
}
