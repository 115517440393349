import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { MentorService } from '../../../../service/mentor.service';
import { NotificationService } from '../../../../service/notification.service';
import { message_properties } from '../../../../../environments/message_properties';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-send-request-to-be-my-guide',
  templateUrl: './send-request-to-be-my-guide.component.html',
  styleUrls: ['./send-request-to-be-my-guide.component.scss'],
})
export class SendRequestToBeMyGuideComponent implements OnInit, OnDestroy {
  sendRequestForm: UntypedFormGroup;
  mentorId: number;
  fullName: string;
  picPath: string;
  sendRequestSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<SendRequestToBeMyGuideComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private mentorService: MentorService,
    private toastr: NotificationService,
    private accountService: AccountsService
  ) {
    this.mentorId = data.mentorId;
    this.fullName = data.fullName;
    this.picPath = data.picPath;
  }

  ngOnInit(): void {
    this.sendRequestForm = this.formBuilder.group({
      description: new UntypedFormControl('', [Validators.required]),
      creating_user: this.accountService.muser,
      tenant: 1,
      context: '',
      company: '',
      collab_content_category: 2461,
      wiki_type: '',
      collab_content_type: 2685,
      collab_content_status: 1324,
      faq_for: '',
      forum_item_type: '',
      assessment_id: '',
      no_of_views: 0,
      no_of_likes: 0,
      visible_to_all: 1,
      title: 'Be My Guide Request',
      additional_info: '',
      references: '',
      attachment_path: '',
      parent_id: '',
      creation_date: '',
      read_status: 1,
      suggesting_user: this.mentorId,
      active: 6,
      content: 'beMyGuideRequest',
    });
  }
  sendRequest(ngForm) {
    this.sendRequestSubscription = this.mentorService
      .createCollabContent(ngForm.value)
      .subscribe(
        (success) => {
          if (success['status'] === 201) {
            this.dialogRef.close();
            this.toastr.showSuccess(
              message_properties.SEND_REQUEST_TOBE_MY_GUIDE_SUCCESS,
              ''
            );
          } else if (success['status'] === 204) {
            this.dialogRef.close();
            this.toastr.showError(success['message'], '');
          } else {
            this.dialogRef.close();
            this.toastr.showError(
              message_properties.SEND_REQUEST_TOBE_MY_GUIDE_ERROR,
              ''
            );
          }
        },
        (error) => {
          this.toastr.showError(
            message_properties.SEND_REQUEST_TOBE_MY_GUIDE_ERROR1,
            ''
          );
        }
      );
  }
  ngOnDestroy(): void {
    if (this.sendRequestSubscription) {
      this.sendRequestSubscription.unsubscribe();
    }
  }
}
