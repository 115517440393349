<div class="mat-dialog-popup">
  <div mat-dialog-title class="tr">
    <button
      mat-button
      mat-dialog-close
      class="modal_close_button_top"
    >
      X
    </button>
  </div>
  <div >
    <div class="row">
      <div class="col-sm-12">
          <table width="100%" align="center" border="0">
            <tr>
              <td>
                <swiper [config]="config">
                  <div class="swiper-wrapper">

                    <div class="bb swiper-slide video-pop-box" *ngFor="let video of videoLists"  (click)="onCreate(video.videoLink)">
                      <div class="video-box-div"><img  alt="" class="video-box_img" [src]="video.videoPic"></div>
                      <h3 class="vide-box-title">{{video.videoTitle}}</h3>
                      <p class="vide-box-dec">{{video.videoDescription}}</p>

                    </div>

                  </div>
                  <div class="swiper-button-next"></div>
                  <div class="swiper-button-prev"></div>
                </swiper>
              </td>
            </tr>
          </table>
      </div>
    </div>
  </div>
</div>
