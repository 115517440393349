import { SubscriptionService } from './../../../../service/subscription.service';
import { YourEndorseDiscussionListComponent } from './your-endorse-discussion-list/your-endorse-discussion-list.component';
import { PurchaseGuideTimeComponent } from './purchase-guide-time/purchase-guide-time.component';
import { MentorService } from './../../../../service/mentor.service';
import { ViewProfileComponent } from './../../admin-page/view-profile/view-profile.component';
import { MeaningNetworkService } from '../../../../service/meaning-network.service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EndorsementDialogComponent } from '../endorsement-dialog/endorsement-dialog.component';
import { DiscontinueGuideComponent } from '../discontinue-guide/discontinue-guide.component';
import { ActivatedRoute } from '@angular/router';
import { SendMassageComponent } from '../../admin-page/send-massage/send-massage.component';
import { CuratorPageService } from '../../../../service/curator-page.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../../../../service/auth.service';
import { CommonService } from '../../../../service/common.service';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-endorse-guide',
  templateUrl: './endorse-guide.component.html',
  styleUrls: ['./endorse-guide.component.scss'],
})
export class EndorseGuideComponent implements OnInit, OnDestroy {
  discussions = [];
  mentorId: any;
  full_name: string;
  first_name: string;
  last_name: string;
  relationshipId: number;
  loggedinUserid: number;
  isPastGuide: number;
  profileData = [];
  mentor_indicator: boolean;
  profile_path: string;
  image_path: string;
  profile_path_for_logged_in_user: string;
  city: string;
  country: string;
  start_date: string;
  end_date: string;
  discussion_details: any;
  completed_discussion: any;
  scheduled_discussion: any;
  requested_discussion: any;
  remaining_balance: any;
  completed_mom_discussions: any;
  completed_guide_discussions: any;
  completed_diy_discussions: any;
  available_general_discussions_time: any;
  available_guided_mom_discussion: any;
  available_guided_reflection_discussions: any;
  requested_from: number;
  connection_status: number;
  requested_to: number;
  connection_count: number;
  params: string;
  dropDown1: boolean = false;
  jit_come_from_url: string;
  member_status: number;
  authSubscription: Subscription;
  profileSubscription: Subscription;
  addressSubscription: Subscription;
  discussionSubscription: Subscription;
  summarySubscription: Subscription;
  constructor(
    private dialog: MatDialog,
    private active_route: ActivatedRoute,
    private meaning_network_service: MeaningNetworkService,
    private cdRef: ChangeDetectorRef,
    private mentorService: MentorService,
    private subscriptionService: SubscriptionService,
    private curatorSpace: CuratorPageService,
    private accountService: AccountsService
  ) {
    this.params = this.active_route.snapshot.paramMap.get('params');
    const paramsArr = atob(this.params).split('|');
    this.mentorId = Number(paramsArr[0]);
    this.first_name = paramsArr[1];
    this.last_name = paramsArr[2];
    this.image_path = paramsArr[3];
    this.city = paramsArr[4];
    this.country = paramsArr[5];
    this.start_date = paramsArr[6];
    this.relationshipId = Number(paramsArr[7]);
    this.isPastGuide = Number(paramsArr[8]);
    this.requested_from = Number(paramsArr[9]);
    this.connection_status = Number(paramsArr[10]);
    this.requested_to = Number(paramsArr[11]);
    this.connection_count = Number(paramsArr[12]);
    this.jit_come_from_url = paramsArr[13];
    this.member_status = Number(paramsArr[14]);
    this.end_date = paramsArr[15];
  }

  ngOnInit() {
    this.loggedinUserid = this.accountService.muser;
    const helper = new JwtHelperService();
    const jti_for_current_logged_in_user = helper.decodeToken(
      this.accountService.token
    ).jti;
    const postData = {
      refresh: this.accountService.refresh_token,
      token: this.accountService.token,
    };
    if (
      String(this.jit_come_from_url) !== String(jti_for_current_logged_in_user)
    ) {
      this.authSubscription = this.accountService
        .logoutuser(postData)
        .subscribe((data) => {
          localStorage.removeItem('token_refresh_data');
          localStorage.clear();
          this.cdRef.detectChanges();
          window.location.href = '/';
        });
    }
    this.getDiscussionDetails();
    this.getProfileWithAddress();
    this.getProfileWithAddressOfLoggedInUser();
    this.getDiscussionSummaryDetails();
    this.subscriptionService.refreshPaymentList.subscribe((data) => {
      this.getDiscussionDetails();
      this.getProfileWithAddress();
      this.getProfileWithAddressOfLoggedInUser();
      this.getDiscussionSummaryDetails();
    });
    this.curatorSpace.refreshneededEvent.subscribe((data) => {
      this.getDiscussionDetails();
      this.getProfileWithAddress();
      this.getProfileWithAddressOfLoggedInUser();
      this.getDiscussionSummaryDetails();
    });
    this.curatorSpace.all_status_change_refresh.subscribe((data) => {
      this.getDiscussionDetails();
      this.getProfileWithAddress();
      this.getProfileWithAddressOfLoggedInUser();
      this.getDiscussionSummaryDetails();
    });
  }
  getProfileWithAddressOfLoggedInUser() {
    this.profileSubscription = this.meaning_network_service
      .getProfileDetails(this.loggedinUserid)
      .subscribe((data) => {
        this.mentor_indicator = Boolean(data.mentor_indicator);
        this.full_name = data.full_name;
        this.profile_path_for_logged_in_user = data.profile_path
          ? data.profile_path
          : './assets/media/users/default.png';
        localStorage.setItem('ufullname', btoa(this.full_name));
        localStorage.setItem(
          'image',
          data.profile_path !== null ? data.profile_path : '0'
        );

        this.cdRef.detectChanges();
      });
  }
  getProfileWithAddress() {
    this.addressSubscription = this.meaning_network_service
      .getProfileDetails(this.mentorId)
      .subscribe((data) => {
        this.cdRef.detectChanges();
      });
  }

  returnImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }
  endorseGuide(mentorId) {
    this.dialog.open(EndorsementDialogComponent, {
      disableClose: true,
      width: '50%',
      data: {
        mentorId: mentorId,
        first_name: this.first_name,
        last_name: this.last_name,
        memberId: this.accountService.muser,
      },
    });
  }
  discontinueGuide(mentorId) {
    this.dialog.open(DiscontinueGuideComponent, {
      disableClose: true,
      width: '50%',
      data: {
        mentorId: mentorId,
        fullName: this.first_name + ' ' + this.last_name,
        relationshipId: this.relationshipId,
      },
    });
  }
  sendMassage(collabContentId, mentorId) {
    this.dialog.open(SendMassageComponent, {
      disableClose: true,
      width: '40%',
      data: {
        collabContentId: collabContentId,
        mentee_id: mentorId,
        clicked_from: 1008,
        full_name: this.first_name + ' ' + this.last_name,
        profile_picture: this.image_path,
      },
    });
  }

  viewGuideProfile(
    requested_from,
    fullname,
    picPath,
    connection_status,
    requested_to,
    connection_count
  ) {
    let dialogRef = this.dialog.open(ViewProfileComponent, {
      width: '80%',
      disableClose: true,
      data: {
        requested_from: requested_from,
        fullName: fullname,
        picPath: picPath,
        connection_status: connection_status,
        requested_to: requested_to,
        member_connect_count: connection_count,
        click_from: 'guide_search',
      },
    });
  }
  getDiscussionDetails() {
    this.discussionSubscription = this.subscriptionService
      .getDiscussionDetailsData(this.mentorId, this.loggedinUserid)
      .subscribe((data) => {
        this.completed_discussion = data.completed_discussions_time;
        this.scheduled_discussion = data.scheduled_discussions_time;
        this.requested_discussion = data.requested_discussions_time;
        this.remaining_balance = data.available_balance_time;
        this.cdRef.detectChanges();
      });
  }

  OpenPerchasePopoup() {
    this.dialog.open(PurchaseGuideTimeComponent, {
      disableClose: true,
      width: '40%',
      data: {
        mentorId: this.mentorId,
        first_name: this.first_name,
        last_name: this.last_name,
        image_path: this.image_path,
        catalog_id: 1,
      },
    });
  }

  yourGuideDiscussionPopoup() {
    this.dialog.open(YourEndorseDiscussionListComponent, {
      disableClose: true,
      width: '80%',
      data: {
        mentorId: this.mentorId,
        first_name: this.first_name,
        last_name: this.last_name,
        image_path: this.image_path,
        registration_type: 2682,
        isPastGuide: this.isPastGuide,
        member_relationship_id: this.relationshipId,
        clicked_from: 'endorse_guide',
      },
    });
  }
  getDiscussionSummaryDetails() {
    this.summarySubscription = this.mentorService
      .getDiscussionSummaryData(
        this.relationshipId,
        this.mentorId,
        this.loggedinUserid
      )
      .subscribe((data) => {
        this.completed_mom_discussions =
          data.results['completed_guided_mom_discussion'];
        this.completed_guide_discussions =
          data.results['completed_general_discussions_time'];
        this.completed_diy_discussions =
          data.results['completed_guided_reflection_discussions'];

        this.available_general_discussions_time =
          data.results['available_general_discussions_time'];
        this.available_guided_mom_discussion =
          data.results['available_guided_mom_discussion'];
        this.available_guided_reflection_discussions =
          data.results['available_guided_reflection_discussions'];

        this.cdRef.detectChanges();
      });
  }
  toggledrpDown1() {
    this.dropDown1 = !this.dropDown1;
  }
  checkStatus(status) {
    return CommonService.checkMemberStatus(status);
  }
  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    if (this.addressSubscription) {
      this.addressSubscription.unsubscribe();
    }
    if (this.discussionSubscription) {
      this.discussionSubscription.unsubscribe();
    }
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
  }
}
