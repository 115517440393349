import { CuratorPageService } from '../../../../service/curator-page.service';
import { MeaningConstellationService } from '../../../../service/meaning-constellation.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../../../service/notification.service';
import { message_properties } from '../../../../../environments/message_properties';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { AccountsService } from './../../../../service/accounts.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-shared-as-post-popup',
  templateUrl: './shared-as-post-popup.component.html',
  styleUrls: ['./shared-as-post-popup.component.scss'],
})
export class SharedAsPostPopupComponent implements OnInit, OnDestroy {
  sharedpostForm: UntypedFormGroup;
  loggedInUser: number;
  collabContentId: number;
  bucket_type: number;
  visibility: number;
  mode: string;
  additional_info: string;
  config = {
    placeholder: 'Enter a brief description',
    tabsize: 2,
    height: 150,

    toolbar: [],
    disableDragAndDrop: true,
    disableResizeEditor: true,
    callbacks: {
      onPaste: function (e) {
        const bufferText = (e.originalEvent || e).clipboardData.getData('Text');
        e.preventDefault();
        document.execCommand('insertText', false, bufferText);
      },
    },
  };
  saveButton: boolean = false;
  collabSaveSubscription: Subscription;
  viewCollabContentSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<SharedAsPostPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private meaningconsellationservice: MeaningConstellationService,
    private formBuilder: UntypedFormBuilder,
    private _cdr: ChangeDetectorRef,
    private toastr: NotificationService,
    private curatorService: CuratorPageService,
    private accountService: AccountsService
  ) {
    this.collabContentId = data.collabContentId;
    this.bucket_type = data.bucket_type;
    this.visibility = data.visibility;
    this.mode = data.mode || '0';
  }

  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
    this.sharedpostForm = this.formBuilder.group({
      additional_info: new UntypedFormControl('', [Validators.required]),
      collab_content: new UntypedFormControl(this.collabContentId),
      collab_content_category: new UntypedFormControl(2460),
      bucket_type: new UntypedFormControl(this.bucket_type),
      visibility: new UntypedFormControl(this.visibility),
      member: new UntypedFormControl(this.loggedInUser),
    });
    if (this.mode === 'edit') {
      this.viewWallOfFameComponent(this.collabContentId);
    }
  }

  saveCollabContent(ngForm: NgForm) {
    this.saveButton = true;
    if (this.mode !== 'edit') {
      this.collabSaveSubscription = this.meaningconsellationservice
        .createShare(ngForm)
        .subscribe(
          (data) => {
            if (data.status === 201) {
              this.emptyForm();
              this.saveButton = false;
              this.dialogRef.close();
              this.toastr.showSuccess(
                message_properties.SHARED_AS_POST_POPUP_SAVE_SUCCESS,
                ''
              );
              this._cdr.detectChanges();
            } else if (data.status === 204) {
              this.toastr.showError(data.message, '');
              this._cdr.detectChanges();
            } else {
              this.toastr.showError(data.message, '');
            }
          },
          (error) => {
            this.toastr.showError(
              message_properties.SHARED_AS_POST_POPUP_SAVE_ERROR,
              ''
            );
          }
        );
    } else {
      this.collabSaveSubscription = this.meaningconsellationservice
        .editSharedPostContent(this.collabContentId, ngForm)
        .subscribe(
          (data) => {
            if (data.status === 201) {
              this.emptyForm();
              this.saveButton = false;
              this.dialogRef.close();
              this.toastr.showSuccess(
                message_properties.SHARED_AS_POST_POPUP_UPDATE_SUCCESS,
                ''
              );
              this.dialogRef.close();
              this._cdr.detectChanges();
            } else if (data.status === 204) {
              this.toastr.showError(data.message, '');
              this._cdr.detectChanges();
            }
          },
          (error) => {
            this.toastr.showError(
              message_properties.SHARED_AS_POST_POPUP_UPDATE_ERROR,
              ''
            );
          }
        );
    }
  }

  emptyForm() {
    this.sharedpostForm.patchValue({
      additional_info: '',
    });
  }

  viewWallOfFameComponent(id) {
    let data = [];
    this.viewCollabContentSubscription = this.curatorService
      .getWallOfFameById(id, this.loggedInUser)
      .subscribe((result) => {
        if (result.code === 200) {
          data = JSON.parse(JSON.stringify(result.results));
          this.additional_info = data['additional_info'];

          this.sharedpostForm.patchValue({
            additional_info: this.additional_info,
            collab_content: new UntypedFormControl(this.collabContentId),
            collab_content_category: new UntypedFormControl(2460),
            bucket_type: new UntypedFormControl(this.bucket_type),
            visibility: new UntypedFormControl(this.visibility),
            member: new UntypedFormControl(this.loggedInUser),
          });

          this._cdr.detectChanges();
        } else {
          this.toastr.showError(
            message_properties.SHARES_AS_POST_POPUP_VIEW_WALLOFFAME_ERROR,
            ''
          );
        }
      });
  }
  ngOnDestroy(): void {
    if (this.collabSaveSubscription) {
      this.collabSaveSubscription.unsubscribe();
    }
    if (this.viewCollabContentSubscription) {
      this.viewCollabContentSubscription.unsubscribe();
    }
  }
}
