<div class="postPopup collab-content-details-popup" *ngIf="found">
  <h1 class="word-break-all m-0 only_two_line_show fs-28" title="{{ title }}">
    {{ title }}
  </h1>
  <button mat-button mat-dialog-close class="modal_close_button_top">X</button>
  <hr />
  <mat-dialog-content>
    <div class="postPopup-content">
      <!-- <div *ngIf="referenceUrl !== '0'">
        <p class="fs-18" [innerHTML]="sanitizer.bypassSecurityTrustHtml(additional_info)"></p>
      </div> -->
      <div class="postPopup-media">
        <ng-container *ngIf="video_indicator == 1 && attachment_path">
          <img [src]="sanitizer.bypassSecurityTrustResourceUrl(attachment_path)" alt="" />
        </ng-container>
      </div>
      <table width="100%">
        <tr class="postPopup-media-options justify-content-between mt-5 mb-5">
          <td width="50%" align="left" class="view_source" *ngIf="referenceUrl">
            <a (click)="basicPopup(referenceUrl)" class="img20 cursorp"><u>View Source</u>&nbsp;&nbsp;<i
                class="fas fa-external-link-alt fa-color"></i></a>
          </td>
          <td  width="50%"  align="left" *ngIf="!referenceUrl">&nbsp;</td>
          <td width="50%" align="right" class="star-rating star-rating-collabs">
            <ul ng-model="ratinglist" class="ratinglist finalRatings">
              <li>
                <a class="no-underline" (click)="star = 1; $event.stopPropagation()">
                  <img src="./assets/images/{{ averageStars[0].color }}.png" />
                </a>
              </li>
              <li>
                <a class="no-underline" (click)="star = 2; $event.stopPropagation()">
                  <img src="./assets/images/{{ averageStars[1].color }}.png" />
                </a>
              </li>
              <li>
                <a class="no-underline" (click)="star = 3; $event.stopPropagation()">
                  <img src="./assets/images/{{ averageStars[2].color }}.png" />
                </a>
              </li>
              <li>
                <a class="no-underline" (click)="star = 4; $event.stopPropagation()">
                  <img src="./assets/images/{{ averageStars[3].color }}.png" />
                </a>
              </li>
              <li>
                <a class="no-underline" (click)="star = 5; $event.stopPropagation()">
                  <img src="./assets/images/{{ averageStars[4].color }}.png" />
                </a>
              </li>
              <li>
                <p class="mt-0">
                  ({{ how_many_person_give_rating_count }})
                </p>
              </li>
            </ul>
          </td>
        </tr>
      </table>
      <div class="postPopup-description">
        <div class="word_break">
          <p class="fs-18" [innerHTML]="sanitizer.bypassSecurityTrustHtml(description)"></p>
        </div>
      </div>
      <hr class="m-5" />
      <div class="postPopup-options mt-10">
        <div class="postPopup-feedStats">
          <ul class="list-style-none postPopup-stats-count">
            <li>
              <span class="icon">
                <img src="./assets/my-feeds/Cheer_Icon_Hover.png" alt="" title="I Cheer" />
              </span>

              <span class="count">{{ cheer_count }}</span>
            </li>

            <li>
              <span class="icon">
                <img src="./assets/my-feeds/Resonate_Icon_Hover.png" alt="" title="I Resonate" />
              </span><span class="count">{{ iresonate_count }}</span>
            </li>

            <li>
              <span class="icon">
                <img src="./assets/my-feeds/Learned_Icon_Hover.png" alt="" title="I Learned" />
              </span><span class="count">{{ ilearned_count }}</span>
            </li>

            <li>
              <span class="icon"><img src="./assets/my-feeds/Inspired_Icon_Hover.png" alt="" title="I Am Inspired" />
              </span><span class="count">{{ iaminspired_count }}</span>
            </li>

            <li>
              <span class="icon"><img src="./assets/media/icon/Comment_Black@2x.png" alt=""
                  title="Comments" /></span><span class="count">{{ comments_count }}</span>
            </li>
          </ul>
        </div>

        <div class="postPopup-settings" style="justify-content: end">
          <ul class="list-style-none postPopup-settings-option">
            <li class="rating-tab-star">
              <a style="cursor: pointer" class="pos-relative top-3px" (click)="startRatingToggle()">
                <span class="icon"><img src="./assets/my-feeds/feed-star.png" alt="" /></span>Rate
              </a>
              <ul ng-model="ratinglist" class="ratinglist center-feed" *ngIf="startRatingOpen">
                <li>
                  <a class="no-underline" (click)="star = 1; $event.stopPropagation(); starRating(1)"
                    (mouseout)="leave = 1; lightOffStars()" (mouseover)="over = 1; lightUpStars(); rate = 1">
                    <img src="./assets/images/{{ stars[0].color }}.png" class="img20" />
                  </a>
                </li>
                <li>
                  <a class="no-underline" (click)="star = 2; $event.stopPropagation(); starRating(2)"
                    (mouseout)="leave = 2; lightOffStars()" (mouseover)="over = 2; lightUpStars(); rate = 2">
                    <img src="./assets/images/{{ stars[1].color }}.png" class="img20" />
                  </a>
                </li>
                <li>
                  <a class="no-underline" (click)="star = 3; $event.stopPropagation(); starRating(3)"
                    (mouseout)="leave = 3; lightOffStars()" (mouseover)="over = 3; lightUpStars(); rate = 3">
                    <img src="./assets/images/{{ stars[2].color }}.png" class="img20" />
                  </a>
                </li>
                <li>
                  <a class="no-underline" (click)="star = 4; $event.stopPropagation(); starRating(4)"
                    (mouseout)="leave = 4; lightOffStars()" (mouseover)="over = 4; lightUpStars(); rate = 4">
                    <img src="./assets/images/{{ stars[3].color }}.png" class="img20" />
                  </a>
                </li>
                <li>
                  <a class="no-underline" (click)="star = 5; $event.stopPropagation(); starRating(5)"
                    (mouseout)="leave = 5; lightOffStars()" (mouseover)="over = 5; lightUpStars(); rate = 5">
                    <img src="./assets/images/{{ stars[4].color }}.png" class="img20" />
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a style="cursor: pointer" (click)="cheerToggle()">    <span class="icon cheer-icon"><img
                    src="./assets/my-feeds/feed-react-emogies.png" alt="" /></span>
                <span class="cheer">Cheer</span>
              </a>
              <ul class="cheersIcons" *ngIf="cheerOpen">
                <li>
                  <span class="icon">
                    <img [src]="cheerUrl" alt="" title="I Cheer" style="cursor: pointer"
                      (click)="cheer(collabContentId)" />
                  </span>
                </li>
                <li>
                  <span class="icon">
                    <img [src]="iresonateUrl" alt="" title="I Resonate" style="cursor: pointer"
                      (click)="iresonate(collabContentId)" />
                  </span>
                </li>
                <li>
                  <span class="icon">
                    <img [src]="ilearnedUrl" alt="" title="I Learned" style="cursor: pointer"
                      (click)="ilearned(collabContentId)" />
                  </span>
                </li>
                <li>
                  <span class="icon">
                    <img [src]="iaminspiredUrl" alt="" title="I am Inspired" style="cursor: pointer"
                      (click)="iaminspired(collabContentId)" />
                  </span>
                </li>
              </ul>
            </li>
            <li>
              <a style="cursor: pointer" (click)="shareToggle()"><span class="icon"><img
                    src="./assets/my-feeds/feed-share.png" alt="" /></span>
                Share</a>
              <ul class="center-share" *ngIf="shareOpen">
                <li>
                  <span class="icon"><img src="./assets/media/icon/Invite@2x.png" alt="" title="Share as Message"
                      style="cursor: pointer" class="img20" (click)="sendMassage(collabContentId)" /></span>
                </li>
                <li>
                  <span class="icon"><img src="./assets/media/icon/Add Content@2x.png" alt="" title="Share as Post"
                      class="img20 cursorp" (click)="shareAsPost(collabContentId)" /></span>
                </li>
              </ul>
            </li>
            <li>
              <a (click)="addToMyTreasures(collabContentId)" style="cursor: pointer">
                <span class="icon">
                  <img *ngIf="!post_treasured_user_wise" src="./assets/media/sidebar/treasures_1.png" alt="" />
                  <img *ngIf="post_treasured_user_wise" src="./assets/media/icon/treasure_taken.png" alt="" />
                </span>Add to My Treasures</a>
            </li>
            <li>
              <a (click)="addToMyBookmarks(collabContentId)" style="cursor: pointer"><span class="icon">
                  <img *ngIf="!post_bookmarked_user_wise" src="./assets/media/sidebar/bookmark_1.png" alt="" />
                  <img *ngIf="post_bookmarked_user_wise" src="./assets/media/icon/bookmark_taken.png" alt="" />
                </span>
                Add to My Bookmarks</a>
            </li>
          </ul>
        </div>
      </div>
      <hr class="m-5" />
      <div class="postPopup-options-content-area" *ngIf="recommended_indicator && collab_content_category == 2474">
        <div class="video-pop-logo-sect">
          <img src="./assets/media/users/default1.png" class="collab_content_sphere" alt="" />
          <div class="row">
          <div class="col-sm-12 pl-0"><p class="pt-0">MeaningSphere Recommended</p>
          <span class="msrec-img"><img src="./assets/media/icon/Recommend@2x.png" class="contarea-img" alt="" /></span></div>
          <div class="col-sm-12 pl-0">
          <p class="word_break" [innerHTML]="ms_recommends"></p></div></div>
        </div>
        <hr class="m-5" />
      </div>
      <ng-container *ngIf="comments_count > 0">
        <ng-container *ngFor="let comment of commentsArr; let l = last">
          <!--pinned start Figma -->

        </ng-container>
      </ng-container>

      <div class="postPopup-comments mr-3 mt-10 mb-10" *ngIf="comments_count > 0">
        <ng-container *ngFor="let comment of commentsArr; let l = last">
          <ng-container *ngIf="comment.lib_request_indicator">
            <div class="popup-default-design">
              <div class="comment mt-3 d-flex justify-content-start">
                <div class="user cursorp"
                [ngClass]="{'cursorp':!checkStatus(comment.user__status)}"
                  (click)="viewProfile(
                  comment.user__member_table__member,
                  comment.user__member_table__first_name,
                  comment.user__member_table__last_name,
                  comment.user__member_table__picture_path ? comment.user__member_table__picture_path : './assets/media/users/default.png',
                  comment.user__status)">
                  <img [src]="
                  comment.user__member_table__picture_path
                    ? comment.user__member_table__picture_path
                    : './assets/media/users/default.png'
                " alt="" />
                </div>
                <div class="commetnData">
                  <div class="innerBg">
                    <p class="name mb-5">
                      <span class="pos-relative ">
                        Submitter’s Review
                        <span class="pinned-text" style="margin-right: 6px">Pinned</span>
                        <span style="margin-right: 4px">
                          <img class="pinned-icon" src="./assets/media/icon/pinned.png" alt="" /></span></span>
                    </p>
                    <p class="datan">
                      <span class="cursorp"
                      [ngClass]="{'cursorp':!checkStatus(comment.user__status)}"
                        (click)="viewProfile(
                        comment.user__member_table__member,
                        comment.user__member_table__first_name,
                        comment.user__member_table__last_name,
                        comment.user__member_table__picture_path ? comment.user__member_table__picture_path : './assets/media/users/default.png',
                        comment.user__status)">{{
                        comment.user__member_table__full_name }}</span> ({{ dateInUserTimezome(comment.creation_date) | date: "MMM d, y h:mm a" }})</p>
                      <span class="feedTime m-0 fs-14" *ngIf="checkStatus(comment.user__status)">(This Explorer is no longer active)</span>

                    <div class="row">
                      <div class="col-sm-12 comentValue word_break" style="text-align: justify">
                        <p>{{ comment.remarks }}</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!comment.lib_request_indicator">
            <div class="comment mt-3 d-flex justify-content-start">
              <div class="user cursorp"
              [ngClass]="{'cursorp':!checkStatus(comment.user__status)}"
                (click)="viewProfile(
                comment.user__member_table__member,
                comment.user__member_table__first_name,
                comment.user__member_table__last_name,
                comment.user__member_table__picture_path ? comment.user__member_table__picture_path : './assets/media/users/default.png',
                comment.user__status)">
                <img [src]="
                  comment.user__member_table__picture_path
                    ? comment.user__member_table__picture_path
                    : './assets/media/users/default.png'
                " alt="" />
              </div>
              <div class="commetnData">
                <div class="innerBg">
                  <span class="name">
                    <span class="pos-relative t6n">
                      <span class="cursorp pr-5"
                      [ngClass]="{'cursorp':!checkStatus(comment.user__status)}"
                        (click)="viewProfile(
                        comment.user__member_table__member,
                        comment.user__member_table__first_name,
                        comment.user__member_table__last_name,
                        comment.user__member_table__picture_path ? comment.user__member_table__picture_path : './assets/media/users/default.png',
                        comment.user__status)">{{
                        comment.user__member_table__full_name }}</span>
                      <span style="margin-right: 6px">({{ dateInUserTimezome(comment.creation_date) | date: "MMM d, y h:mm a" }})</span></span>
                    <span>
                      <mat-icon class="delete-mat-icon" *ngIf="
                        comment.user__member_table__member == loggedInUserId
                      " title="Delete this comment." (click)="deleteComments(comment.id)">delete</mat-icon>

                      <ng-container *ngIf="
                        comment.comment_flagged_user_wise == 0 &&
                        comment.user__member_table__member !== loggedInUserId
                      ">
                      <a class="pos-relative t3n cursorp" (click)="
                      flagCommentsOpen(
                            collabContentId,
                            comment.id,
                            comment.comment_flagged_user_wise_data
                          )
                        "><span class="icon"><img src="./assets/media/flagged_1.png" alt=""
                              title="Flag this comment" class="img20"/></span></a>
                      </ng-container>

                      <ng-container *ngIf="
                        comment.comment_flagged_user_wise > 0 &&
                        comment.user__member_table__member !== loggedInUserId
                      ">
                      <a class="pos-relative t3n cursorp" (click)="
                      flagCommentsOpen(
                            collabContentId,
                            comment.id,
                            comment.comment_flagged_user_wise_data
                          )
                        "><span class="icon"><img src="./assets/media/flagged_checked.png"  class="img20" alt=""
                              title="Unflag this comment" /></span></a>
                      </ng-container>
                    </span>
                  </span>
                  <br /><span class="feedTime m-0 fs-14" *ngIf="checkStatus(comment.user__status)">(This Explorer is no longer active)</span>
                  <div class="row">
                    <div class="col-sm-12 comentValue word_break" style="text-align: justify">
                      <p>{{ comment.remarks }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </mat-dialog-content>

  <form class="commentsForm" [formGroup]="commentsForm" (ngSubmit)="commentsSave(commentsForm.value)"
    enctype="multipart/form-data" novalidate>
    <mat-dialog-actions>
      <div class="postPopup-add-comment">
        <div class="user">
          <img [src]="profile_path" alt="" class="profile_image" />
        </div>

        <mat-form-field style="width: 100%">
          <textarea matInput autocomplete="off" placeholder="Add your comments" formControlName="remarks" rows="2"
            cols="200"></textarea>
        </mat-form-field>

        <button type="submit" [disabled]="!commentsForm.valid || saveButton " class="comment-submit-btn">
          <img src="./assets/media/icon/right-arrow.png" alt="" />
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
<div class="postPopup" *ngIf="!found">
  <h1 class="m-0 only_two_line_show fs-28" title="{{ title }}">
    {{ title }}
  </h1>
  <button mat-button mat-dialog-close class="modal_close_button_top">X</button>
  <hr />
  <mat-dialog-content>
    <div class="row">
      <div class="col-sm-12 text-align-center">
        <span><img src="{{ attachment_path }}" style="object-fit: contain" /></span>
      </div>
    </div>
  </mat-dialog-content>
</div>