<kt-spinner></kt-spinner>

<div class="card register_card absolutueCenter lg loginn-card">
  <div class="title_section login_title mb-2">
    <h3 mat-dialog-title class="d-flex justify-content-between">
    
      <div>
        <span class="login_header">Thank you for your interest in MeaningSphere!</span>
        <br />
        <span class="loginText">
           Unfortunately we are not
          set up to support your country at this time. We are continuing to
          expand access to more countries as MeaningSphere grows. <br/>To stay
          updated, please share your details.
        </span>
      </div>
      <img class="sphere" src="./assets/media/users/default1.png" />
    </h3>
  </div>
  <button class="close-dialog" routerLink="/home">X</button>
 
  <form
    [formGroup]="visitorForm"
    (ngSubmit)="onSubmit(visitorForm.value)"
    novalidate
  >
    <div class="form-group">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input
          matInput
          type="text"
          formControlName="first_name"
          oninput="this.value = this.value.replace(/[^A-Za-z ]|^ /g,'')"
          autocomplete="off"
          tabindex="1"
        />
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input
          matInput
          type="text"
          formControlName="last_name"
          oninput="this.value = this.value.replace(/[^A-Za-z ]|^ /g,'')"
          autocomplete="off"
          tabindex="2"
        />
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input
          matInput
          formControlName="email"
          pattern="^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          autocomplete="true"
          tabindex="3"
        />
      </mat-form-field>
    </div>
  <hr>
    <div class="form-group text-right">
      <button
        [disabled]="!visitorForm.valid || saveButton"
        type="submit"
        class="btn begin_quiz"
      >
        Submit
      </button>
    </div>
  </form>
</div>
