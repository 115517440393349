import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { 

  }

  static checkMemberStatus(status){
    if(status === 0){
      return true;
    }else if(status === 2){
      return true;
    }else if(status === 6){
      return true;
    }else if(status === 7){
      return true;
    }else if(status === 8){
      return true;
    }else{
      return false;
    }
  }
}
