<ngx-spinner
  size="small"
  class="preload-btn"
  color="#ffffff"
  bdColor="transparent"
  [fullScreen]="false"
  template="<img src='/assets/media/loader.gif'/>"
>
</ngx-spinner>
<mat-card class="archive_main_card back_card_background_clr_circle">
  <h1 class="card_title">{{ overview_title }}</h1>
  <hr />
  <mat-card-content>
    <span [innerHtml]="overview_body"></span>
  </mat-card-content>
  <mat-card-actions>
    <button
      type="button"
      class="btn begin_quiz"
      routerLink="/meaning-circle/circles"
    >
      Find your Circles
    </button>
  </mat-card-actions>
</mat-card>
