<div>
  <table width="100%">
    <tr>
      <td>
        <h1>{{ header_set_up_discussion }}</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form
    class="dialogF"
    [formGroup]="dialogForm"
    (ngSubmit)="createDialog(dialogForm.value)"
    novalidate
    enctype="multipart/form-data"
    autocomplete="off"
  >
    <div class="dialogForm">
      <div class="row">
        <div class="col-sm-12" *ngIf="clicked_from == 'from_explorer'">
          <p>
            Your Guide:
            <img class="img-fluid curator-createimg" [src]="menteeDp" />
            <label class="cursorp">&nbsp;&nbsp;{{ mentee }}</label>
          </p>
        </div>

        <div class="col-sm-12" *ngIf="clicked_from == 'from_guide'">
          <p>
            Your Explorer:
            <img class="img-fluid curator-createimg" [src]="menteeDp" />
            <label class="cursorp">&nbsp;&nbsp;{{ mentee }}</label>
          </p>
        </div>
      </div>
      <ng-container *ngIf="clicked_from != 'view_only' && eventId == 0">
        <div class="row">
          <div class="col-md-12">
            <label>Type of Discussion *</label>
            <select
              matNativeControl
              (change)="durationFilling($event.target.value)"
              formControlName="catalog_item"
              class="general_select guid-select"
            >
              <option disabled selected value style="display:none"></option>
              <option
                *ngFor="let guideData of GuideServiceList"
                value="{{ guideData.id }}"
              >
                {{ guideData.offering_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="purpus_text_box">
          <div class="col-sm-12">
            <mat-form-field class="w-100">
              <mat-label>Purpose </mat-label>
              <textarea
                matInput
                autocomplete="off"
                maxlength="1024"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&!@#$%=*-+?() ]|^ /g,'')"
                formControlName="description"
                rows="4"
              >
              </textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Relevant Shared Space Document </label>
            <select
              matNativeControl
              formControlName="shared_document_id"
              class="general_select guid-select"
            >
              <ng-container *ngIf="count == 0">
                <option>No documents found!</option>
              </ng-container>
              <ng-container *ngIf="event_type == 2556 && count > 0">
                <option value="">Select document</option>
                <option
                  *ngFor="let data of reflectionData"
                  value="{{ data.report_id }}"
                >
                  {{ data.mirror_title }}&nbsp;&nbsp;{{
                    data.shared_on | date: "MMM d, y"
                  }}
                </option>
              </ng-container>
              <ng-container *ngIf="event_type == 2554 && count > 0">
                <option value="">Select document</option>
                <option
                  *ngFor="let data of momData"
                  value="{{ data.report_id }}"
                >
                  Meaningful Work Survey
                  {{ data.shared_on | date: "MMM d, y" }}
                </option>
              </ng-container>
              <ng-container *ngIf="event_type == 2550 && count > 0">
                <option value="">Select document</option>
                <option *ngFor="let data of diyData" value="{{ data.diy_id }}">
                  DIY Activity {{ data.shared_on | date: "MMM d, y" }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <!-- <br/> -->
        <div class="row">
          <div class="col-sm-12">
            Request a Date & Time
            <span
              tooltipClass="tooltip_design"
              theme="light"
              tooltip="If your Guide cannot make the requested time, they will message you to work out a different time for your discussion."
              placement="right"
              showDelay="500"
            >
              <span class="icon-info">
                <mat-icon class="info_material_icon">info</mat-icon>
              </span>
            </span>
          </div>

          <div class="col-md-12 col-lg-4 mt3n">
            <mat-form-field class="w-100 top01">
              <mat-label>Requested Date</mat-label>
              <input
                matInput
                readonly
                [matDatepicker]="start_date"
                formControlName="start_date"
                [min]="current_time_zone_date"
                (dateInput)="onChangedate('input', $event)"
              />
              <mat-datepicker-toggle matSuffix [for]="start_date">
              </mat-datepicker-toggle>
              <mat-datepicker #start_date></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-md-12 col-lg-4 mt3n" *ngIf="!event_bool && !both_date">
            <mat-form-field class="w-100 top01">
              <mat-label>Requested Time</mat-label>
              <input class="requestedtime w-100"
                matInput
                [min]="minTime"
                readonly
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
                [disableClick]="true"
              />
              <ngx-material-timepicker-toggle [for]="toggleTimepicker">
              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker
                #toggleTimepicker
                (timeSet)="onChangeFrom($event)"
              >
              </ngx-material-timepicker>
              <!-- <ngx-material-timepicker
                (timeSet)="onChangeFrom($event)"
                #timeFrom
              >
              </ngx-material-timepicker> -->
            </mat-form-field>
          </div>

          <!-- <div class="col-md-12 col-lg-4 mt34n" *ngIf="event_bool && !both_date">
            <mat-form-field class="w-100">
              <mat-label>Requested Time mahi </mat-label>
              <input 
                matInput
                readonly
                
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
              />
             
              <ngx-material-timepicker
                (timeSet)="onChangeFrom($event)"
                #toggleTimepicker
              >
              </ngx-material-timepicker>
            </mat-form-field>
          </div> -->

          <div class="col-md-12 col-lg-4 mt34n" *ngIf="event_bool">
            <mat-form-field class="w-100">
              <mat-label>Requested Time</mat-label>
              <input
                matInput
                readonly
                [min]="minTime"
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
                [disableClick]="true"
              />
              <ngx-material-timepicker-toggle [for]="toggleTimepicker">
              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker
                #toggleTimepicker
                (timeSet)="onChangeFrom($event)"
              >
              </ngx-material-timepicker>

              <!-- <ngx-material-timepicker
                (timeSet)="onChangeFrom($event)"
                #timeFrom
              >
              </ngx-material-timepicker> -->
            </mat-form-field>
          </div>

          <div class="col-md-12 col-lg-4 mt34n" *ngIf="both_date">
            <mat-form-field class="w-100">
              <mat-label>Requested Time</mat-label>
              <input
                matInput
                readonly
                [min]="minTime"
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
                [disableClick]="true"
              />
              <ngx-material-timepicker-toggle [for]="toggleTimepicker">
              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker
                #toggleTimepicker
                (timeSet)="onChangeFrom($event)"
              >
              </ngx-material-timepicker>
              <!-- <ngx-material-timepicker
                (timeSet)="onChangeFrom($event)"
                #timeFrom
              >
              </ngx-material-timepicker> -->
            </mat-form-field>
          </div>

          <div class="col-md-12 col-lg-4 mt7n">
            <ng-container
              *ngIf="this.dialogForm.get('catalog_item').value == 2"
            >
              <mat-form-field class="w-100">
                <mat-label>Duration (In Minutes) *</mat-label>
                <table>
                  <tr>
                    <td>
                      <a
                        (click)="
                          minus_item(event_type, 15); onChangeFromTime($event)
                        "
                        class="btn begin_quiz text-center"
                        id="minus-btn"
                      >
                        <mat-icon>remove</mat-icon>
                      </a>
                    </td>
                    <td>
                      <input
                        matInput
                        readonly
                        formControlName="duration"
                        type="text"
                        min="1"
                        class="duration-input"
                        (change)="onChangeFromTime($event)"
                      />
                    </td>
                    <td class="desc_flex">
                      <a
                        (click)="
                          plus_item(event_type, 15); onChangeFromTime($event)
                        "
                        class="btn begin_quiz text-center"
                        id="plus-btn"
                      >
                        <mat-icon>add</mat-icon>
                      </a>
                    </td>
                  </tr>
                </table>
              </mat-form-field>
            </ng-container>
            <ng-container
              *ngIf="this.dialogForm.get('catalog_item').value != 2"
            >
              <mat-form-field class="w-100 disable-focus">
                <mat-label>Duration (In Minutes)</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="duration"
                  (change)="onChangeFromTime($event)"
                  value="0"
                  maxlength="3"
                  type="number"
                  min="0"
                  max="180"
                  readonly
                  oninput="this.value = this.value.replace(/[^0-9-]|^ /g,'')"
                />
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="created_by_guide == false && eventId != 0">
        <div class="row">
          <div class="col-md-12">
            <label>Type of Discussion </label>
            <select
              disabled="true"
              (change)="durationFilling($event.target.value)"
              formControlName="catalog_item"
              class="general_select guid-select"
            >
              <option disabled selected value style="display:none"></option>
              <option
                *ngFor="let guideData of GuideServiceList"
                value="{{ guideData.id }}"
              >
                {{ guideData.offering_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Relevant Shared Space Document </label>
            <select
              matInput
              disabled="true"
              formControlName="shared_document_id"
              class="general_select guid-select"
            >
              <ng-container *ngIf="count == 0">
                <option>No documents found!</option>
              </ng-container>
              <ng-container *ngIf="event_type == 2556 && count > 0">
                <option value="">Select document</option>
                <option
                  *ngFor="let data of reflectionData"
                  value="{{ data.report_id }}"
                >
                  {{ data.mirror_title }}&nbsp;&nbsp;{{
                    data.shared_on | date: "MMM d, y"
                  }}
                </option>
              </ng-container>
              <ng-container *ngIf="event_type == 2554 && count > 0">
                <option value="">Select document</option>
                <option
                  *ngFor="let data of momData"
                  value="{{ data.report_id }}"
                >
                  Meaningful Work Survey
                  {{ data.shared_on | date: "MMM d, y" }}
                </option>
              </ng-container>
              <ng-container *ngIf="event_type == 2550 && count > 0">
                <option value="">Select document</option>
                <option *ngFor="let data of diyData" value="{{ data.diy_id }}">
                  DIY Activity {{ data.shared_on | date: "MMM d, y" }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="purpus_text_box">
          <div class="col-sm-12">
            <mat-form-field class="w-100">
              <mat-label>Purpose </mat-label>
              <textarea
                matInput
                readonly
                disabled="true"
                autocomplete="off"
                maxlength="1024"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&!@#$%=*-+?() ]|^ /g,'')"
                formControlName="description"
                rows="4"
              >
              </textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 col-lg-4">
            <mat-form-field class="w-100">
              <mat-label>Requested Date</mat-label>
              <input
                matInput
                readonly
                [matDatepicker]="start_date"
                formControlName="start_date"
                [min]="current_time_zone_date"
                (dateInput)="onChangedate('input', $event)"
              />
              <mat-datepicker-toggle matSuffix [for]="start_date">
              </mat-datepicker-toggle>
              <mat-datepicker #start_date></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-md-12 col-lg-4 mt34n" *ngIf="!event_bool && !both_date">
            <mat-form-field class="w-100">
              <mat-label>Requested Time</mat-label>
              <input
                matInput
                [min]="minTime"
                readonly
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
                [disableClick]="true"
              />
              <ngx-material-timepicker-toggle [for]="toggleTimepicker">
              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker
                #toggleTimepicker
                (timeSet)="onChangeFrom($event)"
              >
              </ngx-material-timepicker>
              <!-- <ngx-material-timepicker
                (timeSet)="onChangeFrom($event)"
                #timeFrom
              >
              </ngx-material-timepicker> -->
            </mat-form-field>
          </div>

          <div
            class="col-md-12 col-lg-4 mt34n"
            *ngIf="event_bool && !both_date && !ifToday"
          >
            <mat-form-field class="w-100">
              <mat-label>Requested Time</mat-label>
              <input
                matInput
                readonly
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
                [disableClick]="true"
              />
              <ngx-material-timepicker-toggle [for]="toggleTimepicker">
              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker
                #toggleTimepicker
                (timeSet)="onChangeFrom($event)"
              >
              </ngx-material-timepicker>
              <!-- <ngx-material-timepicker
                (timeSet)="onChangeFrom($event)"
                #toggleTimepicker
              >
              </ngx-material-timepicker> -->
            </mat-form-field>
          </div>

          <!-- <div class="col-md-12 col-lg-4 mt34n" *ngIf="event_bool && !both_date && !ifToday">
            <mat-form-field class="w-100">
              <mat-label>Requested Time 2 </mat-label>
              <input 
                matInput
                readonly
               
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
              />
              <ngx-material-timepicker
                (timeSet)="onChangeFrom($event)"
                #toggleTimepicker
              >
              </ngx-material-timepicker>
            </mat-form-field>
          </div> -->

          <!-- <div class="col-md-12 col-lg-4 mt34n" *ngIf="event_bool && !both_date && ifToday">
            <mat-form-field class="w-100">
              <mat-label>Requested Time 2 today {{minTime}}</mat-label>
              <input class="time_set" *ngIf="rescheduletimeselected"
                matInput
                readonly
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
              />
              <input 
                matInput
                readonly
                [min]="minTime"
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
              />
              
              <ngx-material-timepicker
                (opened)="toggleMinTime($event)"
                (timeSet)="onChangeFrom($event)"
                #toggleTimepicker
              >
              </ngx-material-timepicker>
            </mat-form-field>
          </div> -->
          <div
            class="col-md-12 col-lg-4 mt34n"
            *ngIf="event_bool && !both_date && ifToday"
          >
            <mat-form-field class="w-100">
              <mat-label>Requested Time</mat-label>
              <input
                class="time_set"
                *ngIf="rescheduletimeselected"
                matInput
                readonly
                formControlName="timeFrom"
                [disableClick]="true"
              />
              <input
                matInput
                readonly
                [min]="minTime"
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
                [disableClick]="true"
              />
              <ngx-material-timepicker-toggle [for]="toggleTimepicker">
              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker
                #toggleTimepicker
                (timeSet)="onChangeFrom($event)"
              >
              </ngx-material-timepicker>

              <!-- <ngx-material-timepicker
                (opened)="toggleMinTime($event)"
                (timeSet)="onChangeFrom($event)"
                #toggleTimepicker
              >
              </ngx-material-timepicker> -->
            </mat-form-field>
          </div>

          <div class="col-md-12 col-lg-4 mt34n" *ngIf="both_date">
            <mat-form-field class="w-100">
              <mat-label>Requested Time</mat-label>
              <input
                matInput
                [min]="minTime"
                readonly
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
                [disableClick]="true"
              />
              <ngx-material-timepicker-toggle [for]="toggleTimepicker">
              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker
                #toggleTimepicker
                (timeSet)="onChangeFrom($event)"
              >
              </ngx-material-timepicker>
              <!-- <ngx-material-timepicker
                (timeSet)="onChangeFrom($event)"
                #timeFrom
              >
              </ngx-material-timepicker> -->
            </mat-form-field>
          </div>

          <div class="col-md-12 col-lg-4">
            <mat-form-field class="w-100 disable-focus">
              <mat-label>Duration (In Minutes)</mat-label>
              <input
                matInput
                disabled="true"
                type="number"
                formControlName="duration"
                (change)="onChangeFromTime($event)"
                value="0"
                maxlength="3"
                type="number"
                min="0"
                max="240"
                readonly
                oninput="this.value = this.value.replace(/[^0-9-]|^ /g,'')"
              />
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="created_by_guide == true && eventId != 0">
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field class="w-100">
              <mat-label>Purpose </mat-label>
              <textarea
                matInput
                
                autocomplete="off"
                maxlength="1024"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&!@#$%=*-+?() ]|^ /g,'')"
                formControlName="description"
                rows="4"
              >
              </textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-4">
            <mat-form-field class="w-100">
              <mat-label>Discussion Date </mat-label>
              <input
                matInput
                readonly
                [matDatepicker]="start_date"
                formControlName="start_date"
                [min]="current_time_zone_date"
                (dateInput)="onChangedate('input', $event)"
              />
              <mat-datepicker-toggle matSuffix [for]="start_date">
              </mat-datepicker-toggle>
              <mat-datepicker #start_date></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-md-12 col-lg-4" *ngIf="!event_bool && !both_date">
            <mat-form-field class="w-100">
              <mat-label>Discussion Time</mat-label>
              <input
                matInput class="requestedtime"
                readonly
                [min]="minTime"
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
                [disableClick]="true"
              />
              <ngx-material-timepicker-toggle class="img-resizer-icon" [for]="toggleTimepicker">

              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker
                #toggleTimepicker
                (timeSet)="onChangeFrom($event)"
              >
              </ngx-material-timepicker>
              <!-- <ngx-material-timepicker
                (timeSet)="onChangeFrom($event)"
                #timeFrom
              >
              </ngx-material-timepicker> -->
            </mat-form-field>
          </div>

          <div class="col-md-12 col-lg-4" *ngIf="event_bool && !both_date && !ifToday">
            <mat-form-field class="w-100">
              <mat-label>Discussion Time</mat-label>
              <input
                matInput
                readonly
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
                [disableClick]="true"
              />
              <ngx-material-timepicker-toggle class="img-resizer-icon"  [for]="toggleTimepicker">
              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker
                #toggleTimepicker
                (timeSet)="onChangeFrom($event)"
              >
              </ngx-material-timepicker>
              <!-- <ngx-material-timepicker
                (timeSet)="onChangeFrom($event)"
                #timeFrom
              >
              </ngx-material-timepicker> -->
            </mat-form-field>
          </div>

          <div class="col-md-12 col-lg-4" *ngIf="event_bool && !both_date && ifToday">
            <mat-form-field class="w-100">
              <mat-label>Discussion Time</mat-label>

              <input
                class="time_set"
                *ngIf="rescheduletimeselected"
                matInput
                readonly
                formControlName="timeFrom"
                [disableClick]="true"
              />
              <input
                matInput
                readonly
                [min]="minTime"
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
                [disableClick]="true"
              /><ngx-material-timepicker-toggle class="img-resizer-icon" [for]="toggleTimepicker">
              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker
                #toggleTimepicker
                (timeSet)="onChangeFrom($event)"
              >
              </ngx-material-timepicker>
              <!-- <ngx-material-timepicker
              (opened)="toggleMinTime($event)"
                (timeSet)="onChangeFrom($event)"
                #toggleTimepicker
              >
              </ngx-material-timepicker> -->
            </mat-form-field>
          </div>

          <div class="col-md-12 col-lg-4" *ngIf="both_date">
            <mat-form-field class="w-100">
              <mat-label>Discussion Time</mat-label>
              <input
                matInput
                readonly
                [min]="minTime"
                [ngxTimepicker]="toggleTimepicker"
                formControlName="timeFrom"
              />
              <ngx-material-timepicker-toggle class="img-resizer-icon"  [for]="toggleTimepicker">              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker
                #toggleTimepicker
                (timeSet)="onChangeFrom($event)"
              >
              </ngx-material-timepicker>
              <!-- <ngx-material-timepicker
                (timeSet)="onChangeFrom($event)"
                #timeFrom
              >
              </ngx-material-timepicker> -->
            </mat-form-field>
          </div>

          <div class="col-md-12 col-lg-4 mt7n">
            <ng-container
              *ngIf="this.dialogForm.get('catalog_item').value == 2"
            >
              <mat-form-field class="w-100">
                <mat-label>Duration (In Minutes)</mat-label>
                <input
                  matInput
                  formControlName="duration"
                  (change)="onChangeFromTime($event)"
                  value="0"
                  maxlength="3"
                  type="number"
                  min="0"
                  max="15"
                  readonly
                  oninput="this.value = this.value.replace(/[^0-9-]|-^ /g,'')"
                />
              </mat-form-field>
            </ng-container>
            <ng-container
              *ngIf="this.dialogForm.get('catalog_item').value != 2"
            >
              <mat-form-field class="w-100">
                <mat-label>Duration (In Minutes)</mat-label>
                <ng-container
                  *ngIf="
                    clicked_from != 'view_only' &&
                    created_by_guide == true &&
                    eventId != 0 &&
                    event_creating_member == loggedinUserid
                  "
                >
                  <input
                    matInput
                    type="number"
                    formControlName="duration"
                    (change)="onChangeFromTime($event)"
                    value="0"
                    maxlength="3"
                    type="number"
                    min="0"
                    max="15"
                    readonly
                    oninput="this.value = this.value.replace(/[^0-9-]|^ /g,'')"
                  />
                </ng-container>
                <ng-container
                  *ngIf="
                    clicked_from != 'view_only' &&
                    created_by_guide == true &&
                    eventId != 0 &&
                    event_creating_member != loggedinUserid
                  "
                >
                  <input
                    matInput
                    type="number"
                    formControlName="duration"
                    (change)="onChangeFromTime($event)"
                    value="0"
                    maxlength="3"
                    type="number"
                    min="0"
                    max="15"
                    readonly
                    oninput="this.value = this.value.replace(/[^0-9-]|^ /g,'')"
                  />
                </ng-container>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </ng-container>
     
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-12 tr">
        <button
          *ngIf="clicked_from != 'view_only'"
          [disabled]="!dialogForm.valid || saveButton"
          class="btn begin_quiz ml-2"
          type="submit"
        >
          Save
        </button>

        <button
          mat-dialog-close
          class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
