<div class="mat-dialog-popup">
    <table width="100%">
      <tr>
        <td>
          <h1 class="popup_card_title">Your Survey Report</h1>
        </td>
        <td>
          <h1 mat-dialog-title>
            <button mat-button mat-dialog-close class="modal_close_button_top">
              X
            </button>
          </h1>
        </td>
      </tr>
    </table>
    <hr />
  
    <div class="text_area text_area-scrool my-4" style="color:white">
      <div class="row">
        <div class="col-sm-12 col-lg-7">
          <p>
            Thank you for completing the Meaningful Work Survey. 
          </p>
          <p>
            The Report contains your results and guidance for understanding your scores. You
            can watch videos based on other actual survey user's thoughts and ideas.
          </p>
          <p>
            The results do not describe your personality, but the information does enable you
            to understand where and how your current work situation feels more or less meaningful to you.
          </p>
          <p>
           After reading the Report, you'll have several options for next steps that support you 
           in finding ways to make work more meaningful.
          </p>
          <p>
           Before reviewing your Report, please watch the video to familiarize yourself with 
           the Map of Meaning and its seven dimensions. Then go on to view your results.
          </p>
           <div class="row">
            <div class="col-sm-5 pr-0 pl-0">
                <img class="img-fluid img-responsive" src="../../../../../assets/images/mapofme.png" alt="mapofmeaning"/>
              </div>
              <div class="col-sm-7 pl-0 pr-0 pt-10">
                <p class="text-justify" >The Map of Meaning © Dr Marjolein Lips-Wiersma Licensed through Map of Meaning International </p>
              </div>
            </div>
        </div>

        <div class="col-sm-12 col-lg-5">
          <div class="col-sm-12 text-center mt-2">
            <img
              src="./assets/media/images/first_page_pdf.png"
              class="repor-img img-fluid img-responsive"
            />
          </div>
          <div class="button_area mt-3">
            <div class="">
              <div class="">
                <table align="center" border="0">
                  <tr>
                    <td>
                      <button
                        color="primary"
                        class="btn begin_quiz"
                        (click)="onCreate()"
                      >
                        VIEW VIDEO
                      </button>
                    </td>
                    <td>
                      <button
                        color="primary"
                        class="btn begin_quiz"
                        (click)="goToSelectedSurveyReport()"
                      >
                        VIEW SURVEY REPORT
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
