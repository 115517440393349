<div class="d-flex pt-15">
  <div class="d-flex align-items-center">
    <select class="select" #eventStatus>
      <option [value]="1">All</option>
      <option [value]="2">Requested</option>
      <!-- <option [value]="3">Declined</option> -->
      <option [value]="4">Scheduled</option>
      <!-- <option [value]="6">Completed & Marked</option> -->
      <option [value]="5">Completed</option>
      <!-- <option [value]="7">Cancelled</option>
      <option [value]="8">Withdrawn</option> -->
    </select>
    <button class="btn begin_quiz" (click)="onChange(eventStatus.value)">
      GO
    </button>
  </div>
  <a class="mr-5 float-right" (click)="addSetup(0)" style="float: right">
    <mat-icon class="add_icon float-right" title="Add to New Discussion">add_circle_outline</mat-icon>
  </a>
</div>
<hr />
<div class="scroll_class card2-style-height-with-tabs-hr-dropdown2">
  <div class="">
    <ng-container *ngIf="count > 0">
      <div class="row">
        <ng-container *ngFor="let doc of discussions">
                  
          <div class="col-md-12 col-lg-6" *ngIf="doc.active != 8">
                        <div class="card archive_card front_card_background_clr">
              <div class="img_section_mirror text-center">
                <img *ngIf="doc.event_type == 2550" [src]="'./assets/media/icon/guide_discussion.png'" alt=""
                  style="width: 60px; height: 60px" />
                <img *ngIf="doc.event_type == 2554" [src]="'./assets/media/map_of_meaning.png'"
                  style="width: 60px; height: 60px" />
                <img *ngIf="doc.event_type == 2555" [src]="'./assets/media/my_mirror.png'" alt=""
                  style="width: 60px; height: 60px" />
                <img *ngIf="doc.event_type == 2556 || doc.event_type == 2548" [src]="'./assets/media/diy.png'" alt=""
                  style="width: 60px; height: 60px" />
              </div>

              <div class="title">
                <small>
                  Date: {{ dateInUserTimezome(doc.start_date) | date: "MMM d, y" }}
                  <br />
                  Time:
                  {{ dateInUserTimezome(doc.start_date) | date:"h:mm a" }}
                  -
                  {{ dateInUserTimezome(doc.end_date) | date:"h:mm a" }}
                  <br />
                  Type: {{ eventType(doc.event_type) }} <br />
                  Status:
                  <span *ngIf="doc.active !== 10">{{
                    StatusChange(doc.active)
                    }}</span>
                  <span style="color: #ec7422 !important" *ngIf="doc.active == 10">{{ StatusChange(doc.active) }}</span>
                </small>
              </div>
              <div class="archive_title tooltip-member" data-toggle="tooltip" data-placement="top">
                <button *ngIf="doc.active !== 11" mat-icon-button [matMenuTriggerFor]="beforeMenu"
                  aria-label="Example icon-button with a menu" class="dot-button">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                  <button (click)="
                      viewDetails(
                        doc.id,
                        doc.discussion_created_by_guide,
                        doc.member_relationship
                      )
                    " mat-menu-item>
                    <span>View Details</span>
                  </button>
                  <ng-container *ngIf="doc.discussion_created_by_guide">
                    <button (click)="
                        openPopupForDiscussionEditGuide(doc.id, doc.active)
                      " mat-menu-item *ngIf="
                        doc.active == 0 && doc.creating_member == loggedinUserid
                      ">
                      <span>Update</span>
                    </button>
                  </ng-container>
                  <ng-container *ngIf="!doc.discussion_created_by_guide">
                    <button (click)="
                        openPopupForDiscussionEditExplorer(
                          doc.id,
                          doc.discussion_created_by_guide,
                          doc.active
                        )
                      " mat-menu-item *ngIf="doc.active == 0">
                      <span>Update</span>
                    </button>
                  </ng-container>

                  <button mat-menu-item *ngIf="doc.active == 10"
                    (click)="requestCancelledDiscussion(doc.id, doc.event_type)">
                    <span>Cancel</span>
                  </button>
                  <!-- 5= Complete, 6 = Marked Complete -->
                  <button mat-menu-item *ngIf="doc.active == 0" (click)="acceptDiscussion(doc.id, 3)">
                    <span>Accept Request</span>
                  </button>
                  <button mat-menu-item *ngIf="doc.active == 0" (click)="declinedDiscussion(doc.id, 9)">
                    <span>Decline Request</span>
                  </button>
                  <button mat-menu-item *ngIf="doc.active == 3" (click)="cancelledDiscussion(doc.id, 2)">
                    <span>Cancel</span>
                  </button>
                  <button mat-menu-item *ngIf="
                      doc.active == 3 && doc.discussion_created_by_guide == true
                    " (click)="
                      openPopupForDiscussionEditGuide(
                        doc.id,
                        doc.discussion_created_by_guide,
                        doc.active
                      )
                    ">
                    <span>Reschedule</span>
                  </button>
                  <button mat-menu-item *ngIf="
                      doc.active == 3 &&
                      doc.discussion_created_by_guide == false
                    " (click)="
                      openPopupForDiscussionEditExplorer(
                        doc.id,
                        doc.discussion_created_by_guide,
                        doc.active
                      )
                    ">
                    <span>Reschedule</span>
                  </button>

                  <button mat-menu-item *ngIf="doc.active == 3" (click)="
                      startDiscussion(
                        doc.id,
                        100,
                        doc.host_meeting_link,
                        doc.zoom_start_join_check
                      )
                    ">
                    <span>Start</span>
                  </button>
                  <button mat-menu-item *ngIf="doc.active == 3 || doc.active == 5"
                    (click)="markDiscussionCompleted(doc.id, 6, doc.event_type)">
                    <span>Mark as Closed</span>
                  </button>

                  <button (click)="insightsActions(doc.id)" mat-menu-item *ngIf="
                      doc.active == 3 || doc.active == 5 || doc.active == 6
                    ">
                    <span>View Insights and Action</span>
                  </button>
                  <button *ngIf="
                      doc.active == 3 || doc.active == 5 || doc.active == 6
                    " (click)="
                      addNewPrivateNote(
                        doc.id,
                        eventType(doc.event_type),
                        doc.start_date,
                        doc.end_date,
                        doc.private_notes_one
                      )
                    " mat-menu-item>
                    <span>View Private Notes</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="count == 0">
      <table>
        <tbody>
          <tr>
            <td style="padding: 6px !important">
              <img src="./assets/media/no_data_found.png" style="border-radius: 0px" />
            </td>

            <td colspan="2" style="padding: 6px !important">
              No discussions found!
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
</div>