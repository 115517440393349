import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminPageService } from '../../../service/admin-page.service';
import { AccountsService } from './../../../service/accounts.service';

@Component({
  selector: 'kt-alert-header',
  templateUrl: './alert-header.component.html',
  styleUrls: ['./alert-header.component.scss'],
})
export class AlertHeaderComponent implements OnInit, DoCheck {
  @Output() toggleAlertHeader = new EventEmitter<boolean>();
  banner_message_details: string;
  loggedInUser: number;
  constructor(
    private cdRef: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    private adminService: AdminPageService,
    private accountService: AccountsService
  ) {}
  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
  }
  ngDoCheck() {
    this.getBannerMessageDetails();
  }
  getBannerMessageDetails() {
    this.banner_message_details = localStorage.getItem('banner_message');
  }
  toggleAlert() {
    const data = {
      logged_in_member_id: this.loggedInUser,
      banner_id: localStorage.getItem('banner_id')
    };
    this.adminService.bannerInserMemberWise(data).subscribe((data) => {
      if (data.code === 201) {
        this.toggleAlertHeader.emit(false);
      }
    });
  }
}
