import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private route: Router
  ) {
    this.apiUrl = environment.apiUrl;
  }

  getSurveyData(
    survey,
    sequence,
    survey_type,
    datetime,
    qresponce_id,
    participantSurveyId
  ): Observable<Survey> {
    let data = {
      survey: survey,
      sequence: sequence,
      survey_type: survey_type,
      datetime: datetime,
      qresponce_id: qresponce_id,
      participantSurveyId: participantSurveyId,
    };
    return this.http.post<Survey>(
      this.apiUrl + 'survey/surveyresponse/get-one-question-detail-with-answer',
      data
    );
  }

  getNextQuestionData(json_data): Observable<Survey> {
    return this.http.post<Survey>(
      this.apiUrl +
        'survey/surveyresponse/submit-answer-with-response-next-question',
      json_data
    );
  }
  getQuestionSubmittedData(user_id, survey_id): Observable<Survey> {
    let data = {
      user_id: user_id,
      survey_id: survey_id,
    };
    return this.http.post<Survey>(
      this.apiUrl + 'survey/surveyresponse/get-question-submitted-data',
      data
    );
  }

  getreports(param): Observable<Survey> {
    return this.http.get<Survey>(
      this.apiUrl +
        'survey/surveyresponse/list-quetion-with-response-answer/' +
        param
    );
  }

  postSurvey(ngForm): Observable<SurveyData> {
    return this.http.post<SurveyData>(
      this.apiUrl + 'survey/survey-create',
      ngForm
    );
  }
  getAllSurvey() {
    return this.http.get<SurveyData[]>(this.apiUrl + 'survey/survey-list');
  }
  getAllMapOfMeaningList(participant, survey) {
    return this.http.get<SurveyData[]>(
      this.apiUrl +
        'survey/surveyresponse/all-survey-list?participant=' +
        participant +
        '&survey=' +
        survey
    );
  }
  getViewSurveyByID(id) {
    return this.http.get<SurveyData[]>(
      this.apiUrl + 'survey/get-survey-detail/' + id
    );
  }
  getLatestSurveyID(paticipant) {
    return this.http.get<Survey>(
      this.apiUrl + 'survey/surveyresponse/get-latest-survey-id/' + paticipant
    );
  }
  diyCreate(ngForm) {
    return this.http.post<Diy>(
      this.apiUrl + 'survey/surveyresponse/diy-create',
      ngForm
    );
  }

  diyUpdate(ngForm) {
    return this.http.put<Diy>(
      this.apiUrl + 'survey/surveyresponse/diy-update',
      ngForm
    );
  }

  diyItemList(participantDiyID) {
    return this.http.get<Diy>(
      this.apiUrl + 'survey/surveyresponse/diy-item-list/' + participantDiyID
    );
  }
  diyActionItem(participantDiyID) {
    return this.http.get<Diy>(
      this.apiUrl + 'survey/surveyresponse/diy-action-item/' + participantDiyID
    );
  }
  diyItemUpdate(ngForm) {
    return this.http.put<Diy>(
      this.apiUrl +
        'survey/surveyresponse/diy-items-remarks-update/',
      ngForm
    );
  }
  diyActionCreate(ngForm) {
    return this.http.post<Diy>(
      this.apiUrl + 'survey/surveyresponse/diy-actions-create',
      ngForm
    );
  }
  getAllDiy(participantId) {
    return this.http.get<Diy>(
      this.apiUrl + 'survey/surveyresponse/diy-list/' + participantId
    );
  }
  checkAndUncheckedAllResponse(
    latestGivenSurveyID,
    participantDiyID,
    participant
  ) {
    return this.http.get<Diy>(
      this.apiUrl +
        'survey/surveyresponse/check-and-uncheck-list/' +
        latestGivenSurveyID +
        '/' +
        participantDiyID +
        '/' +
        participant
    );
  }

  sharedWithGuide(ngForm) {
    return this.http.post<SharedWithGuide>(
      this.apiUrl + 'survey/surveyresponse/shared-with-guide',
      ngForm
    );
  }

  getSurveyDetaislsWithPersonalDetails(selected_survey_id, memberId) {
    return this.http.get<SurveyDataWithPersonalDetails>(
      this.apiUrl + 'survey/surveyresponse/survey-data-with-personal-details/'+selected_survey_id+'/'+memberId
    );
  }
  allAllMapOfMeaningListbyPost(ngForm) {
    return this.http.post<SurveyData[]>(
      this.apiUrl +'survey/surveyresponse/all-survey-list', ngForm);
  }
  compairSurveyList(ngForm) {
    return this.http.post<SurveyData[]>(
      this.apiUrl +'survey/surveyresponse/compair-survey-list', ngForm);
  }
  getAllDiybyPost(ngform) {
    return this.http.post<Diy>(
      this.apiUrl + 'survey/surveyresponse/diy-list-post',ngform);
  }
  sendPersonalData(ngform) {
    return this.http.post<SharedPersonalData>(
      this.apiUrl + 'survey/shared-some-personal-data',
      ngform
    );
  }
  checkReportSubmissionDate(member){
    const data ={
      'member':member
    }
    return this.http.post<SharedPersonalData>(
      this.apiUrl + 'survey/personal-data-shared-date',data
    )
  }
}
export interface SharedPersonalData {
  status: number;
  message: string;
  gender: string;
  age_group: string;
  highest_education_level_attained: string;
  region_you_live: string;
  region_of_birth: string;
  industry: string;
  occupation: string;
  tenant: number;
  loggedInUser: number;
  survey_id: number;
}

export interface SharedWithGuide {
  relationship_type: number;
  object_type: number;
  object_id: number;
  shared_date: string;
  shared_with_member: number;
  member: number;
  status: number;
  message: string;
}
export interface Diy {
  selectValue: any[];
  count: number;
  latestGivenSurvey: number;
  participant: number;
  participantDiyID: number;
  results: any[];
  question_response_text: any[];
  text: string;
  answer_text: any[];
  remarks: string;
  stop_doing: string;
  start_doing: string;
  continue_doing: string;
  check_uncheck: boolean;
  status: number;
  message: string;
  checked_count:number;
  diy_status: number;
}
export interface Survey {
  Id: number;
  survey_id: number;
  survey: number;
  sequence: number;
  question_text: string;
  help_text: string;
  answers: any[];
  selected_answer: number;
  survey_response_view: any[];
  max_sequence_number: number;
  number_of_save: string;
  survey_status: number;
  rating: number;
  time_to_complete: any;
  no_of_saves: number;
  start_date: any;
  submission_date: any;
  submitted_at: any;
  feedback: any;
  results: {};
  count: number;
  question: number;
  survey_response: {};
  question_response_text: any[];
  text: string;
  answer_text: any[];
  submitted_answer_free_format: string;
  submitted_answer_option: string;
  qresponce_id: number;
  participant_survey_id: number;
  latestGivenSurveyID: number;
  save_for_later:number;
}

export interface SurveyData {
  id: number;
  results: {};
  count: number;
  name: string;
  status: number;
}

export interface SurveyDataWithPersonalDetails{
  id: number;
  results: {};
  count: number;
  survey_status:string;
  rating:string;
  time_to_complete:string;
  no_of_saves:string;
  start_date:string;
  submission_date:string;
  submitted_at:string;
  feedback:string;
}
