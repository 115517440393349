<div class="mat-dialog-popup">
  <table class="w-100">
    <tr>
      <td>
        <h1 class="cmn_title_top">Pay with</h1>
      </td>
    </tr>
  </table>
  <button mat-button mat-dialog-close class="modal_close_button_top">X</button>
  <hr />

  <div class="fixed_cntent">
    <ngx-braintree [getClientToken]="getClientToken" [createPurchase]="getCreatePurchase"
      (paymentStatus)="onPaymentStatus($event)" [chargeAmount]="total_amount_pay" [buttonText]="'Pay Now'"
      [enablePaypalVault]="false" [currency]="'USD'" [showCardholderName]="true">
    </ngx-braintree>
  </div>
</div>