import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-survey-info',
  templateUrl: './survey-info.component.html',
  styleUrls: ['./survey-info.component.scss'],
})
export class SurveyInfoComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  giveSurvet() {
    localStorage.removeItem('participantSurveyId');
    localStorage.removeItem('number_of_save');
    this.router.navigateByUrl('/meaningful-work-survey/take-the-survey');
  }
}
