import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CuratorPageService {
  apiUrl: string;
  public libAllData = new BehaviorSubject<any[]>([]);
  private __g_edit_member_id: any;
  private _refreshneeded = new Subject<void>();
  private _refreshneededGroup = new Subject<void>();
  private _refreshneededEvent = new Subject<void>();
  private _refreshneededGroupMemberList = new Subject<void>();
  private _refreshneededEventSummaryList = new Subject<void>();
  private _refreshneededEventActionList = new Subject<void>();
  private _refreshneededMirrorTopicList = new Subject<void>();
  private _update_dialog_refresh = new Subject<void>();
  private _all_status_change_refresh = new Subject<void>();
  private _refreshneededCollabContentComment = new Subject<void>();
  private _refreshneededRejected = new Subject<void>();
  private _refreshnetworkPostFromCurator = new Subject<void>();
  private _refreshneededGroupacceptreject = new Subject<void>();
  private _refreshDiscussionFromGuide = new Subject<void>();
  private _refreshNeededForApprovedRequest = new Subject<void>();
  private _refreshNeededForApprovedRequestCircle = new Subject<void>();
  private _refreshCreateUpdateLibraryCuratorSpace = new Subject<void>();

  get refreshCreateUpdateLibraryCuratorSpace(){
      return this._refreshCreateUpdateLibraryCuratorSpace;
    }

  get refreshNeededForApprovedRequestCircle() {
    return this._refreshNeededForApprovedRequestCircle;
  }
  
  get refreshNeededForApprovedRequest() {
    return this._refreshNeededForApprovedRequest;
  }
  get refreshDiscussionFromGuide() {
    return this._refreshDiscussionFromGuide;
  }

  get refreshneededGroupacceptreject() {
    return this._refreshneededGroupacceptreject;
  }

  get refreshnetworkPostFromCurator() {
    return this._refreshnetworkPostFromCurator;
  }

  get refreshneededRejected() {
    return this._refreshneededRejected;
  }

  get refreshneededCollabContentComment() {
    return this._refreshneededCollabContentComment;
  }
  get all_status_change_refresh() {
    return this._all_status_change_refresh;
  }
  get update_dialog_refresh() {
    return this._update_dialog_refresh;
  }
  get refreshneeded() {
    return this._refreshneeded;
  }
  get refreshneededGroup() {
    return this._refreshneededGroup;
  }
  get refreshneededEvent() {
    return this._refreshneededEvent;
  }
  get refreshneededGroupMemberList() {
    return this._refreshneededGroupMemberList;
  }
  get refreshneededEventSummaryList() {
    return this._refreshneededEventSummaryList;
  }
  get refreshneededEventActionList() {
    return this._refreshneededEventActionList;
  }
  get refreshneededMirrorTopicList() {
    return this._refreshneededMirrorTopicList;
  }
  _constellation = new BehaviorSubject<WallOfFameData[]>([]);
  readonly constellation$ = this._constellation.asObservable();

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  createWallOfFame(ngForm): Observable<WallOfFameData> {
    return this.http
      .post<WallOfFameData>(
        this.apiUrl + 'collabcontent/create-wall-of-fame',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneeded.next();
        })
      );
  }
  createLibrary(ngForm): Observable<WallOfFameData> {
    return this.http
      .post<WallOfFameData>(
        this.apiUrl + 'collabcontent/create-library',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshCreateUpdateLibraryCuratorSpace.next();
        })
      );
  }
  networkPostFromCurator(ngForm): Observable<WallOfFameData> {
    return this.http
      .post<WallOfFameData>(
        this.apiUrl + 'collabcontent/network-post-from-curator',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshnetworkPostFromCurator.next();
        })
      );
  }
  updateWallOfFame(ngForm): Observable<WallOfFameData> {
    return this.http
      .put<WallOfFameData>(
        this.apiUrl + 'collabcontent/update-wall-of-fame/',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneeded.next();
        })
      );
  }

  updateLibrary(ngForm, collabcontentID): Observable<WallOfFameData> {
    return this.http
      .put<WallOfFameData>(
        this.apiUrl + 'collabcontent/update-library/' + collabcontentID,
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshCreateUpdateLibraryCuratorSpace.next();
        })
      );
  }
  networkPostFromCuratorUpdate(
    ngForm,
    collabcontentID
  ): Observable<WallOfFameData> {
    return this.http
      .put<WallOfFameData>(
        this.apiUrl +
          'collabcontent/network-post-from-curator-update/' +
          collabcontentID,
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshnetworkPostFromCurator.next();
        })
      );
  }

  callingWallOfFameGetList(
    collabcontentCategory,
    params,
    active,
    page,
    list_type = 'all'
  ) {
    return this.http.get<WallOfFameData>(
      this.apiUrl +
        'collabcontent/collab-content-type-list-with-search?collab_content_category=' +
        collabcontentCategory +
        '&&q=' +
        params +
        '&&collab_active=' +
        active +
        '&&list_type=' +
        list_type +
        '&&page=' +
        page
    );
  }

  allLibrary(collabcontentCategory, params, active, page, list_type = 'all') {
    return this.http
      .get<WallOfFameData>(
        this.apiUrl +
          'collabcontent/collab-content-type-list-with-search?collab_content_category=' +
          collabcontentCategory +
          '&&q=' +
          params +
          '&&collab_active=' +
          active +
          '&&list_type=' +
          list_type +
          '&&page=' +
          page
      )
      .pipe(
        map((data) => {
          this.libAllData.next({ ...data['results'] });
          return { ...data['results'], count: data['count'] };
        })
      );
  }
  getWallOfFameById(id, loggedInUserId) {
    return this.http.get<WallOfFameData>(
      this.apiUrl +
        'collabcontent/details/' +
        id +
        '?loggedInMember=' +
        loggedInUserId
    );
  }

  getSuggestedLibraryById(id, loggedInUserId) {
    return this.http.get<WallOfFameData>(
      this.apiUrl +
        'collabcontent/lib-details/' +
        id +
        '?loggedInMember=' +
        loggedInUserId
    );
  }
  removeLibrary(collabcontentId) {
    return this.http
      .delete<WallOfFameData>(
        this.apiUrl +
          'collabcontent/collab-content-delete-with-all-relative/' +
          collabcontentId
      )
      .pipe(
        tap(() => {
          this._refreshneeded.next();
        })
      );
  }
  removeNetworkPost(collabcontentId) {
    return this.http
      .delete<WallOfFameData>(
        this.apiUrl +
          'collabcontent/collab-content-delete-with-all-relative/' +
          collabcontentId
      )
      .pipe(
        tap(() => {
          this._refreshnetworkPostFromCurator.next();
        })
      );
  }
  createCommunity(ngForm): Observable<GroupData> {
    return this.http
      .post<GroupData>(this.apiUrl + 'group/group-create', ngForm)
      .pipe(
        tap(() => {
          this._refreshneededGroup.next();
        })
      );
  }

  updateCommunityAndCircle(ngForm, communityId): Observable<GroupData> {
    return this.http
      .put<GroupData>(
        this.apiUrl + 'group/group-update-community-circle/' + communityId,
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededGroup.next();
        })
      );
  }

  updateCommunity(ngForm, communityId): Observable<GroupData> {
    return this.http
      .put<GroupData>(this.apiUrl + 'group/group-update/' + communityId, ngForm)
      .pipe(
        tap(() => {
          this._refreshneededGroupacceptreject.next();
        })
      );
  }

  updateGroupImage(ngForm): Observable<GroupData> {
    return this.http
      .put<GroupData>(this.apiUrl + 'group/group-image-update', ngForm)
      .pipe(
        tap(() => {
          this._refreshneededGroup.next();
        })
      );
  }
  // group_for=2234&&status=&&q=2&&listType=0&&creating_member=0&&limit=0
  getAllCommunity(groupFor, status, params, listType, logged_in_user, limit) {
    return this.http.get<GroupData>(
      this.apiUrl +
        'group/group-list?group_for=' +
        groupFor +
        '&&status=' +
        status +
        '&&q=' +
        params +
        '&&listType=' +
        listType +
        '&&creating_member=' +
        logged_in_user +
        '&&limit=' +
        limit
    );
  }
  getAllRequestInCommunityAndCircle(
    groupFor,
    params,
    status,
    listType,
    logged_in_user,
    limit
  ) {
    return this.http.get<GroupData>(
      this.apiUrl +
        'group/user-requested-group-list?group_for=' +
        groupFor +
        '&&q=' +
        params +
        '&&status=' +
        status +
        '&&listType=' +
        listType +
        '&&creating_member=' +
        logged_in_user +
        '&&limit=' +
        limit
    );
  }
  getAllCommunitySearchPostRequest(ngForm) {
    return this.http.post<GroupData>(this.apiUrl + 'group/group-list', ngForm);
  }
  updateStatusCommunity(groupid, params): Observable<GroupData> {
    let data = {
      active: params,
    };
    return this.http.put<GroupData>(
      this.apiUrl + 'group/group-update-status/' + groupid,
      data
    );
  }

  createMember(ngForm): Observable<GroupMemberData> {
    return this.http
      .post<GroupMemberData>(this.apiUrl + 'group/group-members-create', ngForm)
      .pipe(
        tap(() => {
          this._refreshneededGroupMemberList.next();
        })
      );
  }
  getAllMemberInGroup(groupId, logged_in_user_id): Observable<GroupMemberData> {
    return this.http.get<GroupMemberData>(
      this.apiUrl +
        'group/group-members-list/' +
        groupId +
        '/' +
        logged_in_user_id
    );
  }
  removeMemberFromGroup(
    memberProfileId,
    logged_in_member
  ): Observable<GroupMemberData> {
    return this.http
      .delete<GroupMemberData>(
        this.apiUrl +
          `group/group-members-delete/${memberProfileId}?login_member=${logged_in_member}`
      )
      .pipe(
        tap(() => {
          this._refreshneededGroupMemberList.next();
        })
      );
  }

  getMemberAndTagInAGroup(groupId) {
    return this.http.get<GroupMemberData>(
      this.apiUrl + 'group/group-details-with-member-list-with-tab/' + groupId
    );
  }
  getGroupCount(groupFor, groupId, mapFor) {
    return this.http.get<GroupMemberData>(
      this.apiUrl +
        'group/all-count-in-group/' +
        groupFor +
        '?groupId=' +
        groupId +
        '&&mapFor=' +
        mapFor
    );
  }
  //Event details all api
  createEvent(ngForm): Observable<EventData> {
    return this.http
      .post<EventData>(this.apiUrl + 'event/event-create', ngForm)
      .pipe(
        tap(() => {
          this._refreshneededEvent.next();
        })
      );
  }
  createSetUpDiscussionFromExplorer(ngForm): Observable<EventData> {
    return this.http
      .post<EventData>(
        this.apiUrl + 'event/set-up-discussion-create-from-explorer',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededEvent.next();
        })
      );
  }
  updateSetUpDiscussionFromExplorer(ngForm, eventId): Observable<EventData> {
    return this.http
      .put<EventData>(
        this.apiUrl + 'event/set-up-discussion-update-from-explorer/' + eventId,
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededEvent.next();
        })
      );
  }
  createSetUpDiscussionFromGuide(ngForm): Observable<EventData> {
    return this.http
      .post<EventData>(
        this.apiUrl + 'event/set-up-discussion-create-from-guide',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshDiscussionFromGuide.next();
        })
      );
  }
  updateSetUpDiscussionFromGuide(ngForm, eventId): Observable<EventData> {
    return this.http
      .put<EventData>(
        this.apiUrl + 'event/set-up-discussion-update-from-guide/' + eventId,
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededEvent.next();
        })
      );
  }
  updateEvent(ngForm, eventId): Observable<EventData> {
    return this.http
      .put<EventData>(this.apiUrl + 'event/event-update/' + eventId, ngForm)
      .pipe(
        tap(() => {
          this._refreshneededEvent.next();
        })
      );
  }
  createPrivateNote(ngForm): Observable<EventData> {
    return this.http
      .post<EventData>(this.apiUrl + 'event/create-private-note', ngForm)
      .pipe(
        tap(() => {
          this._refreshneededEvent.next();
        })
      );
  }
  allEventInAGroup(
    groupId,
    eventType,
    listType,
    statusType
  ): Observable<EventData> {
    return this.http.get<EventData>(
      this.apiUrl +
        'event/group-event-list/' +
        groupId +
        '?eventtype=' +
        eventType +
        '&&listType=' +
        listType +
        '&&statusType=' +
        statusType
    );
  }

  getAllEventInAGroup(
    groupId,
    eventType,
    past_or_upcoming,
    limit,
    loggedInUser
  ): Observable<EventData> {
    const data = {
      groupId: groupId,
      eventType: eventType,
      past_or_upcoming: past_or_upcoming,
      limit: limit,
      loggedInUser: loggedInUser,
    };
    return this.http.post<EventData>(
      this.apiUrl +
        'group/circle-and-community/up-coming-event-list-in-a-group',
      data
    );
  }
  createComment(ngForm): Observable<EventSummaryData> {
    return this.http
      .post<EventSummaryData>(
        this.apiUrl + 'event/event-summary-create',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededEventSummaryList.next();
        })
      );
  }

  // Insights api call
  createInsights(ngForm): Observable<EventSummaryData> {
    return this.http
      .post<EventSummaryData>(
        this.apiUrl + 'event/event-summary-create',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededEventSummaryList.next();
        })
      );
  }
  updateInsights(ngForm): Observable<EventSummaryData> {
    return this.http
      .put<EventSummaryData>(
        this.apiUrl + 'event/event-summary-update/',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededEventSummaryList.next();
        })
      );
  }
  createActions(ngForm): Observable<EventSummaryData> {
    return this.http
      .post<EventSummaryData>(
        this.apiUrl + 'event/event-summary-create',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededEventActionList.next();
        })
      );
  }
  updateActions(ngForm): Observable<EventSummaryData> {
    return this.http
      .put<EventSummaryData>(
        this.apiUrl + 'event/event-summary-update/',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededEventActionList.next();
        })
      );
  }
  getAllEventSummary(eventId, summary_type): Observable<EventSummaryData> {
    return this.http.get<EventSummaryData>(
      this.apiUrl +
        'event/event-summary-list/' +
        eventId +
        '?summary_type=' +
        summary_type
    );
  }
  getSummaryDetails(event, summaryType) {
    return this.http.get<EventSummaryData>(
      this.apiUrl +
        'event/event-summary-details-for-one/' +
        event +
        '/' +
        summaryType
    );
  }
  getEventSummaryDetail(eventSummaryId) {
    return this.http.get<EventSummaryData>(
      this.apiUrl + 'event/event-summary-details/' + eventSummaryId
    );
  }
  // Get Group member detail
  getMemberDetailInGroup(mapped_object_id, mapped_object_type) {
    const postData = {
      mapped_object_id: mapped_object_id,
      mapped_object_type: mapped_object_type,
    };
    return this.http.post<IndividualCircleCommunityData>(
      this.apiUrl + 'group/group-details-with-member-list-with-tab',
      postData
    );
  }

  // Get Group member event detail under dialog
  getEventDetailInDialog(dialogProfileId, dialogEventId) {
    return this.http.get<DialogViewMemberData>(
      this.apiUrl + 'event/event-details/' + dialogEventId
    );
  }
  getEventDetailInWithTOpicSessionAndDialog(dialogEventId, mapped_object_type) {
    return this.http.get<DialogViewMemberData>(
      this.apiUrl +
        'event/event-details-with-topic/' +
        dialogEventId +
        '/' +
        mapped_object_type
    );
  }
  // Get Group member event detail under dialog
  getAllLookupValueInDropDown(lookupIdentity) {
    return this.http.get<LookupIdentifier>(
      this.apiUrl + 'common/lookup-identifier-list/' + lookupIdentity
    );
  }

  getCommunityAndCircleListForDropDown(groupFor) {
    return this.http.get<GroupData>(
      this.apiUrl + 'group/group-for-list-for-community-and-circle/' + groupFor
    );
  }

  getCollabContentFlagged(active, searchText) {
    return this.http.get<WallOfFameData>(
      this.apiUrl +
        'collabcontent/manage-flagged?active=' +
        active +
        '&q=' +
        searchText
    );
  }

  postCollabContentCommentsFlagged(postData) {
    return this.http.post<CommentsData>(
      this.apiUrl + 'collabcontent/comments-flagged',
      postData
    );
  }
  removeCollabContentCommentFlag(comment_id): Observable<CommentsData> {
    const data = { comment_id: comment_id };
    return this.http
      .put<CommentsData>(
        this.apiUrl + `collabcontent/remove-collab-content-comments-flag`,
        data
      )
      .pipe(
        tap(() => {
          this._refreshneededCollabContentComment.next();
        })
      );
  }
  keepCollabContentCommentFlag(comment_id): Observable<CommentsData> {
    const data = { comment_id: comment_id };
    return this.http
      .put<CommentsData>(
        this.apiUrl + `collabcontent/flagged-and-retained`,
        data
      )
      .pipe(
        tap(() => {
          this._refreshneededCollabContentComment.next();
        })
      );
  }
  flaggedAndRetained(comment_id): Observable<CommentsData> {
    const data = { comment_id: comment_id };
    return this.http
      .put<CommentsData>(
        this.apiUrl + `collabcontent/flagged-and-retained`,
        data
      )
      .pipe(
        tap(() => {
          this._refreshneededCollabContentComment.next();
        })
      );
  }
  getMemberDetails(formData) {
    const data = {
      member: formData, //formData = member_id
    };
    return this.http.post(
      this.apiUrl + 'member/member-profile-and-address/',
      data
    );
  }

  // Get Group member detail
  getAllReport(groupFor, mapFor, eventType) {
    return this.http.get<IndividualCircleCommunityData>(
      this.apiUrl +
        'group/all-count-in-report/' +
        groupFor +
        '/' +
        mapFor +
        '/' +
        eventType
    );
  }
  // Get Group member detail
  postAllReport(ngForm, groupFor, mapFor, eventType) {
    return this.http.post<IndividualCircleCommunityData>(
      this.apiUrl +
        'group/all-count-in-report/' +
        groupFor +
        '/' +
        mapFor +
        '/' +
        eventType,
      ngForm
    );
  }

  getAllReportForFlagged() {
    return this.http.get<IndividualCircleCommunityData>(
      this.apiUrl + 'group/all-count-in-report-for-flagged'
    );
  }
  postAllReportForFlagged(ngForm) {
    return this.http.post<IndividualCircleCommunityData>(
      this.apiUrl + 'group/all-count-in-report-for-flagged',
      ngForm
    );
  }

  getAllTopic(questionId, surveyType) {
    return this.http.get<AnswerText>(
      this.apiUrl +
        'survey/survey-question/get-mirror-choices/' +
        questionId +
        '/' +
        surveyType
    );
  }
  getOneTopic(questionId, answerId) {
    return this.http.get<AnswerText>(
      this.apiUrl +
        'survey/survey-question/get-mirror-choices-single-answer/' +
        questionId +
        '/' +
        answerId
    );
  }
  createMirrorTopic(ngForm) {
    return this.http
      .post<AnswerText>(
        this.apiUrl + 'survey/survey-question/get-mirror-choices-create',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededMirrorTopicList.next();
        })
      );
  }
  updateMirrorTopic(ngForm, qnswer) {
    return this.http
      .put<AnswerText>(
        this.apiUrl +
          'survey/survey-question/get-mirror-choices-update/' +
          qnswer,
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededMirrorTopicList.next();
        })
      );
  }
  updateEventDraftOrPublish(dialogEventId, actionType) {
    let data = {
      eventId: dialogEventId,
      action: actionType,
    };
    return this.http
      .put<EventData>(this.apiUrl + 'event/event-publish-or-draft', data)
      .pipe(
        tap(() => {
          this._update_dialog_refresh.next();
        })
      );
  }

  updateCollabContentStatus(
    ngForm,
    collabcontentID
  ): Observable<WallOfFameData> {
    const rejected_data = ngForm['check_status'];
    if (rejected_data != 8) {
      return this.http
        .put<WallOfFameData>(
          this.apiUrl +
            'collabcontent/collab-content-status-change-with-remarks/' +
            collabcontentID,
          ngForm
        )
        .pipe(
          tap(() => {
            this._refreshNeededForApprovedRequest.next();
          })
        );
    }
    if (rejected_data === 8) {
      return this.http
        .put<WallOfFameData>(
          this.apiUrl +
            'collabcontent/collab-content-status-change-with-remarks/' +
            collabcontentID,
          ngForm
        )
        .pipe(
          tap(() => {
            this._refreshneededRejected.next();
          })
        );
    }
  }
  getReportListByContentId(contentid) {
    return this.http.get<ReportData>(
      this.apiUrl + 'collabcontent/collabcontentaction/report-list/' + contentid
    );
  }
  getReportListByCommentId(commentId) {
    return this.http.get<ReportData>(
      this.apiUrl +
        'collabcontent/collabcontentaction/report-list-comments/' +
        commentId
    );
  }
  getCollabContentActionDetails(action_id): Observable<CollabContentAction> {
    return this.http.get<CollabContentAction>(
      this.apiUrl +
        'collabcontent/collabcontentaction/report-details/' +
        action_id
    );
  }
  groupRating(ngForm): Observable<GroupRatingData> {
    return this.http.post<GroupRatingData>(
      this.apiUrl + 'group/rating-details/group-rating',
      ngForm
    );
  }

  deleteActionOrInsight(summaryId): Observable<EventSummaryData> {
    return this.http
      .delete<EventSummaryData>(
        this.apiUrl + 'event/event-summary-delete/' + summaryId
      )
      .pipe(
        tap(() => {
          this._refreshneededEventSummaryList.next();
        })
      );
  }

  getCircleOrCommunityCountByLogginUser(
    login_user,
    group_for
  ): Observable<any> {
    return this.http.get<any>(
      this.apiUrl +
        'group/circle-and-community/my-community-circle-count-indicator/' +
        login_user +
        '/' +
        group_for
    );
  }
  publishInsightsOrActions(ngForm, summaryId): Observable<EventSummaryData> {
    return this.http
      .put<EventSummaryData>(
        this.apiUrl + 'event/event-summary-publish/' + summaryId,
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededEventSummaryList.next();
        })
      );
  }
  updateEventDiscussionStatusChange(
    eventId,
    actionType,
    start_or_join,
    menteeId,
    loggedinUserid
  ) {
    let data = {
      eventId: eventId,
      action: actionType,
      start_or_join: start_or_join,
      explorer: menteeId,
      guide: loggedinUserid,
    };
    return this.http
      .put<EventData>(
        this.apiUrl + 'event/event-discussion-status-change',
        data
      )
      .pipe(
        tap(() => {
          this._all_status_change_refresh.next();
        })
      );
  }
  tagLinkWithGroup(group, mapped_object_type) {
    return this.http.get<TagLinkWIthGroup[]>(
      `${this.apiUrl}common/tag/tag-link-with-group/${group}/${mapped_object_type}`
    );
  }

  updateAttachmentStatus(ngForm): Observable<WallOfFameData> {
    return this.http
      .put<WallOfFameData>(
        this.apiUrl + 'collabcontent/attachment-delete',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneeded.next();
        })
      );
  }

  getWallOfFameByIdForEdit(id, mapped_object_type, loggedInUserId) {
    return this.http.get<WallOfFameData>(
      this.apiUrl +
        'collabcontent/content-details/' +
        id +
        '/' +
        mapped_object_type +
        '?loggedInMember=' +
        loggedInUserId
    );
  }

  createGroupRequest(ngForm): Observable<GroupData> {
    return this.http
      .post<GroupData>(this.apiUrl + 'group/group-request', ngForm)
      .pipe(
        tap(() => {
          this._refreshneededGroup.next();
        })
      );
  }
  updateEventDraftOrPublishSessionAndDialog(
    dialogEventId,
    actionType,
    login_member
  ) {
    let data = {
      eventId: dialogEventId,
      action: actionType,
      login_member: login_member,
    };
    return this.http
      .put<EventData>(this.apiUrl + 'event/event-publish-or-draft', data)
      .pipe(
        tap(() => {
          this._update_dialog_refresh.next();
        })
      );
  }
  eventCancellationRequest(ngForm): Observable<EventAction> {
    return this.http
      .post<EventAction>(this.apiUrl + 'event/event-action-create/', ngForm)
      .pipe(
        tap(() => {
          this._all_status_change_refresh.next();
        })
      );
  }
  eventCancellationRequestUpdate(event_id, ngForm): Observable<EventAction> {
    return this.http
      .put<EventAction>(
        this.apiUrl + 'event/event-action-update/' + event_id,
        ngForm
      )
      .pipe(
        tap(() => {
          this._all_status_change_refresh.next();
        })
      );
  }
  eventCancellationRequestDetails(event) {
    return this.http.get<EventAction>(
      this.apiUrl + `event/event-action-details/${event}`
    );
  }
  callingWallOfFameGetUserList(
    collabcontentCategory,
    params,
    active,
    page,
    list_type = 'new_request'
  ) {
    return this.http.get<WallOfFameData>(
      this.apiUrl +
        'collabcontent/collab-content-type-list-with-search?collab_content_category=' +
        collabcontentCategory +
        '&&q=' +
        params +
        '&&collab_active=' +
        active +
        '&&list_type=' +
        list_type +
        '&&page=' +
        page
    );
  }
  userSuggestedListServiceForLib(ngForm) {
    return this.http.post<WallOfFameData>(
      this.apiUrl + 'collabcontent/collab-content-type-list-with-search',
      ngForm
    );
  }
  postCollabContentFlagged(ngForm) {
    return this.http.post<GroupData>(
      this.apiUrl + 'collabcontent/manage-flagged',
      ngForm
    );
  }
  getAllRequestCommunityAndCirclePost(listType, ngform) {
    return this.http.post<GroupData>(
      this.apiUrl + 'group/user-requested-group-list?listType=' + listType,
      ngform
    );
  }
  updateEventDiscussionStatusChangeEventHeader(
    eventId,
    actionType,
    start_or_join,
    guide,
    explorer
  ) {
    let data = {
      eventId: eventId,
      action: actionType,
      start_or_join: start_or_join,
      guide: guide,
      explorer: explorer,
    };
    return this.http
      .put<EventData>(
        this.apiUrl + 'event/event-discussion-status-change',
        data
      )
      .pipe(
        tap(() => {
          this._all_status_change_refresh.next();
        })
      );
  }
  singleGroupAllEvent(data): Observable<EventData> {
    return this.http.post<EventData>(
      this.apiUrl +
        'group/circle-and-community/up-coming-event-list-in-a-group',
      data
    );
  }
  listOfAllMemberInGroup(data): Observable<GroupMemberData> {
    return this.http.post<GroupMemberData>(
      this.apiUrl + 'group/all-members-in-group-curator-space',
      data
    );
  }
  allEventInGroupPost(data): Observable<EventData> {
    return this.http.post<EventData>(
      this.apiUrl + 'event/group-event-list-curator-space',
      data
    );
  }
  curatorSpaceExplorerSuggestedLibrary(ngForm) {
    return this.http.post<WallOfFameData>(
      this.apiUrl +
        'collabcontent/curator-space-explorer-requested-library-list',
      ngForm
    );
  }

  getCuratorNetworkPostDetail(id, loggedInUserId) {
    return this.http.get<WallOfFameData>(
      this.apiUrl +
        'collabcontent/curator-network-post-details/' +
        id +
        '?loggedInMember=' +
        loggedInUserId
    );
  }
  getCuratorFlaggedPostComments(id, loggedInUserId) {
    return this.http.get<WallOfFameData>(
      this.apiUrl +
        'collabcontent/flagged-post-comment-list/' +
        id +
        '?loggedInMember=' +
        loggedInUserId
    );
  }
  allMirrorTopicList(formData): Observable<AnswerText> {
    return this.http.post<AnswerText>(
      this.apiUrl + 'survey/survey-question/all-mirror-topics-list-curator',
      formData
    );
  }
  singleGroupAllUpcomingEvent(data): Observable<EventData> {
    return this.http.post<EventData>(
      this.apiUrl + 'group/circle-and-community/single-group-upcoming-events',
      data
    );
  }

  singleGroupAllHostsList(data): Observable<EventData> {
    return this.http.post<EventData>(
      this.apiUrl + 'group/circle-and-community/single-group-host-list',
      data
    );
  }
  singleSessionAndDialogDetailWithTopic(event, mapped_object_type) {
    return this.http.get<DialogViewMemberData>(
      this.apiUrl + 'event/session-dialog-details-with-topic/'  + event + '/' + mapped_object_type
    );
  }
  groupEventSummaryActionInsight(sendData): Observable<EventSummaryData> {
    return this.http.post<EventSummaryData>(
      this.apiUrl + 'event/group-event-summary-action-insight', sendData
    );
  }
  updateCircle(ngForm, communityId): Observable<GroupData> {
    return this.http
      .put<GroupData>(this.apiUrl + 'group/group-update/' + communityId, ngForm)
      .pipe(
        tap(() => {
          this._refreshNeededForApprovedRequestCircle.next();
        })
      );
  }
}
export interface EventAction {
  action: number;
  reason: string;
  penalty_duration: number;
  creation_datetime: string;
  remarks: string;
  event: number;
  explorer: number;
  guide: number;
  tenant: number;
  event_details: {};
  request_time_difference: number;
  results: [];
  count: number;
  status: number;
  message: string;
  id: number;
}
export interface CommentsData {
  rating: string;
  remarks: string;
  active: string;
  user: string;
  tenant: string;
  collab_content: string;
  results: [];
  count: number;
  is_post_also_flagged: boolean;
}
export interface TagLinkWIthGroup {
  topic_str: string;
  topic_count: number;
}
export interface GroupRatingData {
  rating: number;
  viewrating: number;
  remarks: string;
  member: number;
  group: number;
  tenant: number;
  results: [];
  count: number;
  status: number;
}
export interface CollabContentAction {
  action_type: number;
  action_for: number;
  remarks: string;
  collab_content: number;
  tenant: number;
  member: number;
  inappropriate_content_indicator: boolean;
  inappropriate_comments_indicator: boolean;
  unrelated_content_indicator: boolean;
  other_indicator: boolean;
  results: [];
  count: number;
  status: number;
}
export interface ReportData {
  action_type: number;
  action_for: number;
  action_date: string;
  remarks: string;
  collab_content: {};
  member: number;
  member_table: {};
  tenant: number;
  results: [];
  count: number;
  status: number;
}
export interface AnswerText {
  text: number;
  sequence: string;
  score: string;
  results: [];
  count: number;
  status: number;
  message: string;
}
export interface LookupIdentifier {
  lookup_identifier: number;
  name: string;
  lookup_value: string;
  parent_lookup_id: number;
  results: [];
  count: number;
  status: number;
}
export interface DialogViewMemberData {
  active: number;
  additional_information: string;
  address: string;
  campaign_id: number;
  city: number;
  company_id: number;
  context: number;
  country: number;
  creating_member: number;
  creation_date: string;
  criticality: number;
  description: string;
  end_date: string;
  end_time: string;
  event_type: number;
  difference: string;
  id: number;
  image_path: any;
  name: string;
  no_of_likes: number;
  no_of_views: number;
  start_date: string;
  start_time: string;
  virtual_link: number;
  visibility_type: number;
  virtual_indicator: any;
  max_participants: number;
  creating_member_details: [];
  tag_with_comma: string;
  tag_with_comma_all_ids: string;
  time_duration: number;
  catalog_item: number;
  shared_document_id: number;
  actual_duration: number;
  reason: string;
}

export interface IndividualCircleCommunityData {
  id: number;
  visibility_type: number;
  group_for: number;
  group_object_id: number;
  auto_created_indicator: number;
  total_participants: number;
  company_id: number;
  active: number;
  creation_date: string;
  name: string;
  description: string;
  charter: string;
  context: number;
  image_path: string;
  creating_member: number;
  mresults: [];
  mcount: number;
  tresults: [];
  count: number;
  status: number;
  remarks: string;
  tag_with_comma: string;
  tag_with_comma_all_ids: string;
  host_results: [];
  mhost_results: [];
}

export interface GroupMemberData {
  start_date: string;
  end_date: string;
  group_role: string;
  visibility_type: string;
  creating_member: string;
  member: number;
  group: number;
  results: [];
  count: number;
  status: number;
  dateDifference: number;
  totalEvent: number;
  calculateTotalMember: number;
  totalPostCount: number;
}
export interface WallOfFameData {
  collabcontentcategory: number;
  collabcontenttype: number;
  collabcontentstatus: number;
  creation_date: any;
  title: string;
  description: string;
  attachment_path: string;
  creating_user: string;
  suggesting_user: string;
  message: string;
  status: number;
  results: [];
  count: number;
  flaggedCount: number;
  creatingUser: {};
  iaminspired_count: number;
  ilearned_count: number;
  iresonate_count: number;
  cheer_count: number;
  code: number;
  tag_with_comma: string;
  tag_with_comma_all_ids: string;
  content_topics: [];
}
export interface GroupData {
  collabcontentcategory: number;
  collabcontenttype: number;
  collabcontentstatus: number;
  creation_date: any;
  title: string;
  description: string;
  attachment_path: string;
  image_path: string;
  creating_user: string;
  suggesting_user: string;
  message: string;
  status: number;
  total_participants: number;
  results: [];
  count: number;
  checkStatus: number;
  code: number;
  statusType: number;
  creating_member: number;
}
export interface EventData {
  event_type: number;
  criticality: number;
  visibility_type: number;
  creation_date: any;
  name: string;
  description: string;
  additional_information: string;
  creating_user: string;
  creating_member: string;
  max_participants: number;
  difference: string;
  results: [];
  count: number;
  status: number;
  code: number;
  active: number;
  message: string;
  registred_participant_in_this_event: number;
  host_meeting_link: string;
  member_meeting_link: string;
  is_loggedin_member_registered_in_this_event: boolean;
}
export interface EventSummaryData {
  summary_type: number;
  visibility_type: number;
  creation_date: any;
  remarks: string;
  creating_member: string;
  results: [];
  count: number;
  status: number;
}
