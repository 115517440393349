<kt-spinner></kt-spinner>
<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1>Create a Meaning Moment</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form
    class="ccForm"
    [formGroup]="ccForm"
    (ngSubmit)="createLog(ccForm.value)"
    enctype="multipart/form-data"
  >
    <mat-dialog-content>
      <div class="row">
        <div class="col-md-12 col-lg-9">
          <table width="100%">
            <tr>
              <td colspan="2">
                <mat-form-field style="width: 100%">
                  <mat-label>Title</mat-label>
                  <input
                    maxlength="450"
                    matInput
                    formControlName="title"
                    placeholder=""
                    autocomplete="off"
                  />
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <label>Add a Meaningful Moments *</label>
                <textarea
                  [ngxSummernote]="config"
                  formControlName="postDetail"
                  rows="3"
                  placeholder=""
                  (summernoteModelChange)="changeDesc($event)"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="url">
                  <mat-form-field style="width: 100%">
                    <input
                      matInput
                      formControlName="textUrl"
                      type="url"
                      placeholder="Link"
                      autocomplete="off"
                      (change)="linkPreview($event)"
                     />
                  </mat-form-field>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="hide-icons-area">
            <div class="previewWindow">
              <!-- <div class="url">
                <div class="preview linkPreviewDesign" *ngIf="link_preview">
                  <img [src]="image_path" height="100" />
                  <span class="removeUrl">
                    <img
                      src="./assets/my-feeds/feed-delete.png"
                      (click)="linkRemove()"
                      alt=""
                    />
                  </span>
                </div>
              </div> -->
              <div class="preview" *ngIf="image_preview">
                <img [src]="image_path" class="h-100" />
                
                <span class="removeUrl bottom_preview">
                  <img
                    class="delete_icon_img"
                    src="./assets/my-feeds/feed-delete.png"
                    (click)="imageRemove()"
                    alt=""
                  />
                </span>
              </div>
            </div>
            <ul class="button-home">
              <li>
                <div class="image-upload" *ngIf="!image_link_uploaded">
                  <label for="image-input">
                    <img
                      src="./assets/media/icon/Photo-Video2x-white.png"
                      alt=""
                      (click)="imageUpload()"
                    />
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr />
    <div class="text-align-right">
      <button class="btn begin_quiz" [disabled]="!ccForm.valid || saveButton">Publish</button>
    </div>
  </form>
</div>
