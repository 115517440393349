<div class="">
  <table width="100%">
    <tr>
      <td>
        <h1 class="popup_card_title">Request Connection</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form
    [formGroup]="requestForm"
    (ngSubmit)="onSubmit(requestForm.value)"
    novalidate
  >
    <div class="multi-collapse" id="multiCollapseExample5">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 col-lg-4">
            <div class="memg-btn-go">
              <mat-checkbox tabindex="1" formControlName="resonate" [disabled]="message == '' ? 'false':'true'" [value]="1" (change)="requestResign($event)">
                I resonate with your journey and what you say about yourself
              </mat-checkbox>
            </div>
            <div class="memg-btn-go">
              <mat-checkbox tabindex="2" formControlName="inspired" [disabled]="message == '' ? 'false':'true'" [value]="2" (change)="requestResign($event)">
                I feel inspired by what your meaningful work story and/or your
                posts
              </mat-checkbox>
            </div>
            <div class="memg-btn-go">
              <mat-checkbox tabindex="3" formControlName="care" [disabled]="message == '' ? 'false':'true'" [value]="3" (change)="requestResign($event)">
                We care about some of the same topics and interests relating to
                meaningful work
              </mat-checkbox>
            </div>
            <div class="memg-btn-go">
              <mat-checkbox tabindex="4" formControlName="career" [disabled]="message == '' ? 'false':'true'" [value]="4" (change)="requestResign($event)">
                I am interested in following the same career path as you
              </mat-checkbox>
            </div>
            <div class="memg-btn-go">
              <mat-checkbox tabindex="5" formControlName="other"  [disabled]="message == '' ? 'false':'true'" [value]="5" (change)="requestResign($event)"> Other </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field style="width: 100%;">
              <mat-label>Request message</mat-label>
              <textarea
              tabindex="6"
                matInput
                formControlName="request_message"
                rows="8"
                autocomplete="off"
                maxlength="240"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;:*'&/.\-_+()@!?#$% ]|^ /g,'')"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <hr/>
      <table width="100%" border="0" class="mt-3">
        <tr>
          <td>&nbsp;</td>
          <td  style="float: right;">
            <button
              mat-dialog-close
              class="btn begin_quiz ml-2 canceled_btn"
              tabindex="8"
            >
              Cancel
          </button>
          </td>
          <td *ngIf="message!=='received'" style="float: right;">
            <button tabindex="7" class="btn begin_quiz my_btn" [disabled]="!requestForm.valid">Send</button>
          </td>
        </tr>
      </table>
    </div>
  </form>
</div>
