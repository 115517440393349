<mat-card
  class="welcomPopup archive_main_card bg-color-transp back_card_background_clr"
>
  <div class="welcomPopupheader">
    <table width="100%">
      <tr>
        <td align="left">
          <h3>At MeaningSphere,</h3>
          <h1>We are all Explorers on this journey together.</h1>
        </td>
      </tr>
    </table>
  </div>

  <mat-card-content>
    <div class="row welcom-content">
      <div class="col-lg-6 col-md-6 col-sm-12 welcom-content-desc">
        <div
          class="d-flex flex-direction-column justify-content-between h-100P"
        >
          <div>
            <p>
              <strong>
                Choose 6 interests that are most meaningful to you on this
                journey and that you’d like other Explorers to know.
              </strong>
            </p>

            <p>
              Your choices will be visible on your profile in the order you
              select them, and may be used to recommend content and
              conversations that are meaningful to you.
            </p>
            <p>And don't worry, you can change them at any time.</p>
          </div>
          
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 welcom-content-bg">
        <p>
          <strong> Select up to 6 interests. </strong>
        </p>
        <div class="">
          <spna *ngFor="let item of tagList">
            <button
              class="welcom_butto"
              [ngClass]="{ active: checkInterest(item.id) }"
              (click)="selctInterest(item.id)"
            >
            {{ item.alternate_name }}
            </button>
          </spna>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12">
        <br /><br />
        <div class="welcome-buttons">
          <button (click)="nextPage()" class="btn canceled_btn">
            Next: Tour the MeaningSphere
          </button>
          <button
            (click)="previousPage()"
            type="button"
            type="button"
            class="btn ml-10"
          >
            Back
          </button>
          <button (click)="close()" type="button" class="btn ml-10">
            Close
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
