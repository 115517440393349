<kt-spinner></kt-spinner>
<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1 class="popup_card_title">Volunteer Highlights</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form
    class="volunteerForm"
    [formGroup]="volunteerForm"
    (ngSubmit)="volunteerSave(volunteerForm.value)"
    novalidate
  >
    <div class="multi-collapse" id="multiCollapseExample5">
      <div class="card-body row profile_section_button">
        <mat-form-field>
          <mat-label>Role </mat-label>

          <input
            matInput
            formControlName="title"
            autocomplete="off"
            tabindex="1"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Organization </mat-label>

          <input
            matInput
            formControlName="company"
            autocomplete="off"
            tabindex="2"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Location </mat-label>
          <input
            matInput
            type="text"
            formControlName="location"
            autocomplete="off"
            tabindex="3"
            #countrySearchInput
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"
          />
          <div class="country-box-child-div" *ngIf="countryBox1">
            <cdk-virtual-scroll-viewport class="country-viewport">
              <div class="country-autocom-box">
                <li
                  *cdkVirtualFor="let country of countryList"
                  (click)="setCountry(country.name, country.id)"
                >
                  {{ country.name }}
                </li>
              </div>
            </cdk-virtual-scroll-viewport>
          </div>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Start Date</mat-label>
          <input
            matInput
            readonly
            [max]="maxDate"
            [matDatepicker]="start_date"
            formControlName="start_date"
            (dateChange)="onChangeFrom($event)"
            (click)="$event.stopPropagation()"
            tabindex="4"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="start_date"
          ></mat-datepicker-toggle>
          <mat-datepicker #start_date></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>End Date</mat-label>
          <input
            matInput
            readonly
            [min]="minDate"
            [max]="maxDate"
            [matDatepicker]="end_date"
            formControlName="end_date"
            (click)="$event.stopPropagation()"
            tabindex="5"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="end_date"
          ></mat-datepicker-toggle>
          <mat-datepicker #end_date></mat-datepicker>
        </mat-form-field>

        <div class="mt-5" style="width: 100%; color: white">
          <p>What I found meaningful was...*</p>

          <textarea
            tabindex="6"
            formControlName="workprofile"
            [ngxSummernote]="config"
          ></textarea>
        </div>
      </div>
    </div>
    <hr />
    <table width="100%" class="mt-5" align="right" border="0">
      <tr>
        <td width="80%">&nbsp;</td>
        <td>
          <button
            [disabled]="!volunteerForm.valid || saveButton"
            class="btn begin_quiz my_btn"
          >
            Save
          </button>
        </td>
        <td>
          <button
            mat-dialog-close
            class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
          >
            Cancel
          </button>
        </td>
      </tr>
    </table>
  </form>
</div>
