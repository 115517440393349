<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1 class="popup_card_title">Comparison of my Responses</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <div class="content_part mt-4 mob-display-none">
    <table width="100%">
      <thead>
        <tr>
          <td width="45%">&nbsp;</td>
          <td
            *ngFor="let option of mirrorresponse"
            width="11%"
            align="center" class="text-uppercase" 
          >
            <p class="p_edit_table_response_header">
              {{ option }}
            </p>
          </td>
        </tr>
      </thead>
      <tbody class="view-scrool">
        <ng-container
          *ngFor="let mirror of currentSurveyFinalArr; let i = index"
        >
          <tr>
            <td width="25%" rowspan="2">
              <p class="p_edit mt-2">
                {{ mirror.question_current_category }} {{
                mirror.question_current_text }}
              </p>
            </td>
            <td width="20%">
              <p class="p_edit mt-2">
                {{ currentDateTime | date: "medium": current_timezone }}
              </p>
            </td>
            <td
              *ngFor="let option of mirrorresponse"
              align="center"
              width="11%"
            >
              <mat-radio-group aria-label="Select an option">
                <mat-radio-button
                  [disabled]="true"
                  value="1"
                  [checked]="
                    option == mirror.answer_current_text ? true : false
                  "
                ></mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <tr>
            <td width="25%">
              <p class="p_edit mt-2">&nbsp;</p>
            </td>
            <td width="20%">
              <p class="p_edit mt-2">
                {{ selectedSurveyDateTime | date: "medium":current_timezone }}
              </p>
            </td>
            <td
              *ngFor="let option of mirrorresponse"
              align="center"
              width="11%"
            >
              <mat-radio-group aria-label="Select an option">
                <mat-radio-button
                  [disabled]="true"
                  value="1"
                  [checked]="option == selectSurveyFinalArr[i] ? true : false"
                ></mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <hr />
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
