<div class="d-flex p-5 pt-15 justify-content-between align-items-center">
  <table width="100%">
    <tr>
      <td align="left">
       <table>
        <tr>
          <td>
            <select class="select" #selectEventType>
              <option [value]="1">All</option>
              <option [value]="2">Requested</option>
              <!-- <option [value]="3">Declined</option> -->
              <option [value]="4">Scheduled</option>
              <!-- <option [value]="6">Completed & Marked</option> -->
              <option [value]="5">Completed</option>
              <!-- <option [value]="7">Cancelled</option>
              <option [value]="8">Withdrawn</option> -->
            </select>
          </td>
          <td>
            <button class="btn begin_quiz" (click)="onChange(selectEventType.value)">
              GO
            </button>
          </td>
        </tr>
       </table>
      </td>
      <td align="right">
        <a
        class="mr-5 mt-3 btn-addnew begin_quiz_qid"
        (click)="setUpDiscission(0)"
        style="float: right"
        *ngIf="isPastGuide != 1"
      >
        <mat-icon class="add_icon">add_circle_outline</mat-icon>
      </a>
      </td>
    </tr>
  </table>
  

  
</div>
<hr />
<div class="">
  <cdk-virtual-scroll-viewport
    itemSize="40"
    minBufferPx="200"
    maxBufferPx="400"
    class="scroll_class scroll_class_network card2-style-height-with-tabs-hr-heading"
  >
    <ng-container *ngIf="count > 0">
      <div class="row">
        <div class="col-sm-12 col-lg-6" *ngFor="let doc of discussions">
          <div class="card archive_card front_card_background_clr document">
            <div class="img_section_mirror text-center">
              <img
                *ngIf="doc.event_type == 2550"
                [src]="'./assets/media/icon/guide_discussion.png'"
                alt=""
                style="width: 60px; height: 60px"
              />
              <img
                *ngIf="doc.event_type == 2554"
                [src]="'./assets/media/map_of_meaning.png'"
                style="width: 60px; height: 60px"
              />
              <img
                *ngIf="doc.event_type == 2555"
                [src]="'./assets/media/my_mirror.png'"
                alt=""
                style="width: 60px; height: 60px"
              />
              <img
                *ngIf="doc.event_type == 2556 || doc.event_type == 2548"
                [src]="'./assets/media/diy.png'"
                alt=""
                style="width: 60px; height: 60px"
              />
            </div>

            <div class="title">
              <small>
                Date: {{ dateInUserTimezome(doc.start_date) | date: "MMM d, y" }}
                <br />
                Time:
                {{ dateInUserTimezome(doc.start_date) | date:"h:mm a" }}
                - {{ dateInUserTimezome(doc.end_date) | date:"h:mm a" }}
                <br />
                Type: {{ eventType(doc.event_type) }} <br />
                Status: <span *ngIf="doc.active!==10">{{StatusChange(doc.active)}}</span>
                <span style="color: #ec7422 !important;"  *ngIf="doc.active==10">{{StatusChange(doc.active)}}</span>
              </small>
            </div>
            <div
              class="archive_title tooltip-member"
              data-toggle="tooltip"
              data-placement="top"
            >
              <button
                mat-icon-button
                [matMenuTriggerFor]="beforeMenu"
                aria-label="Example icon-button with a menu"
                class="dot-button"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button
                  (click)="joinDiscussion(doc.id, 3, doc.member_meeting_link,doc.zoom_start_join_check)"
                  mat-menu-item
                  *ngIf="doc.active == 3"
                >
                  <span>Join</span>
                </button>
                <button
                  (click)="
                    viewDetails(
                      doc.id,
                      doc.discussion_created_by_guide,
                      doc.member_relationship
                    )
                  "
                  mat-menu-item
                >
                  <span>View Details</span>
                </button>
                <button
                  (click)="withdrawnDiscussion(doc.id, 8)"
                  mat-menu-item
                  *ngIf="doc.active == 0"
                >
                  <span>Withdraw Request</span>
                </button>
                <button
                  (click)="requestCancelledDiscussion(doc.id, 2, doc.difference)"
                  mat-menu-item
                  *ngIf="doc.active == 3 && doc.discussion_created_by_guide==false"
                >
                  <span>Request Cancelation</span>
                </button>
                
                <!-- 5= Complete, 6 = Marked Complete -->
                <button
                  (click)="insightsActions(doc.id)"
                  mat-menu-item
                  *ngIf="doc.active == 5 || doc.active == 6"
                >
                  <span>View Insights and Action</span>
                </button>
                <button
                (click)="requestCancelcomplimentaryDis(doc.id, 2,doc.difference)"
                  mat-menu-item
                  *ngIf="doc.active == 3 && doc.event_type == 2733"
                >
                  <span>Request Cancelation</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="count == 0">
      <table>
        <tbody>
          <tr>
            <td style="padding: 6px !important">
              <img
                src="./assets/media/no_data_found.png"
                style="border-radius: 0px"
              />
            </td>
            <td colspan="2" style="padding: 6px !important">No discussion found!</td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</div>
