import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CuratorPageService } from '../../../../../service/curator-page.service';
import { AccountsService } from './../../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-single-community-popup-upcoming-dialog-list',
  templateUrl: './single-community-popup-upcoming-dialog-list.component.html',
  styleUrls: ['./single-community-popup-upcoming-dialog-list.component.scss'],
})
export class SingleCommunityPopupUpcomingDialogListComponent
  implements OnInit, OnDestroy {
  @Input() communityId;
  loggedInUserId: number;
  groupEventForm: UntypedFormGroup;
  upcomingDialogListUnderCircleDatas = [];
  upcoming_circle_count: number;
  eventListSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private curatorService: CuratorPageService,
    private cdRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedInUserId = this.accountService.muser;

    this.groupEventForm = this.formBuilder.group({
      groupId: new UntypedFormControl(this.communityId),
      eventType: new UntypedFormControl(2480),
      limit: new UntypedFormControl(0),
      loggedInUser: new UntypedFormControl(this.loggedInUserId),
      page: 1,
    });
    this.upcomingEventInSingleGroup();
  }
  upcomingEventInSingleGroup() {
    this.spinner.show();
    const FormData = this.groupEventForm.value;
    this.eventListSubscription = this.curatorService
      .singleGroupAllUpcomingEvent(FormData)
      .subscribe((data) => {
        this.upcomingDialogListUnderCircleDatas = data.results;
        this.upcoming_circle_count = data.count;
        this.cdRef.detectChanges();
        this.spinner.hide();
      });
  }
  ngOnDestroy(): void {
    if (this.eventListSubscription) {
      this.eventListSubscription.unsubscribe();
    }
  }
}
