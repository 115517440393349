<div class="mat-dialog-popup">
  <h1 class="cmn_title_top">Review your cart items</h1>
  <button mat-button mat-dialog-close class="close-dialog">X</button>
  <hr />
  <div class="fixed_cntent">
    <mat-card-content>
      <div class="d-flex justify-content-between">
        <p class="m-0"><b>Name</b></p>
      </div>
      <hr class="hr-5" />
      <div>
        {{ billing_full_name }}
      </div>
      <div class="d-flex justify-content-between mt-10">
        <p class="m-0">
          <b>Address</b>
        </p>
      </div>
      <hr class="hr-5" />
      <p>
        {{ line1 ? line1+', ' : "" }}{{ line2 ? line2+', ' : "" }}<br />
        {{ city ? city+', ':"" }} {{ state?state+secondary_state_code:"" }} <br />
        {{ country ? country+" ": "" }} {{ postalcode ? postalcode :"" }}
      </p>
      <div class="your-cart">
        <h2 class="mb-10">Your Cart</h2>
        <div class="your-cart-inner p-25">
          <ng-container *ngFor="let mydata of myCartItemsData; let i = index">
            <div class="cart-item mb-15 d-flex justify-content-between">
              <table width="100%">
                <tr>
                  <td align="left">
                    <b>{{mydata.catalog_item !== 4 ? "Guide Discussions:&nbsp;" : ''}}{{ mydata.item_name }}</b>
                    <span>
                      ({{ mydata.item_quantity }}
                      {{
                      mydata.catalog_item == 2 ? "Minutes" : "Discussion(s)"
                      }})
                    </span>
                  </td>
                  <td align="right">
                    US $ &nbsp;{{ mydata.item_total_amount | number: "1.2-2" }}
                  </td>
                </tr>
                <tr>
                  <td align="left">
                    Tax Amount:
                  </td>
                  <td align="right">
                    US $ &nbsp;{{ final_amount_details_with_tax[i]['tax_amount'] | number: "1.2-2" }}
                  </td>
                </tr>
              </table>
            </div>
          </ng-container>
          <hr class="hr-10" />
          <div class="total d-flex justify-content-between">
            <div class="total-lable">Total</div>
            <div class="total-value">
              US $ &nbsp;<span>{{ transaction_total_amount | number: "1.2-2" }}</span>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </div>
</div>
<hr />
<div class="row">
  <div class="form-group col-sm-12 tr marg-none">
    <!-- <button class="btn begin_quiz ml-2 mt-1" type="submit" (click)="paymentCompleteFrom()">
      Continue to Payment
    </button> -->
    <button
      class="btn begin_quiz ml-2 mt-1"
      type="submit"
      (click)="payNow()"
    >
      Continue to Payment
    </button>
<!-- 
    <button mat-dialog-close class="btn begin_quiz back mt-1 ml-2 button-change-scroll canceled_btn">
      Back
    </button> -->
    <button mat-dialog-close class="btn begin_quiz mt-1 ml-2 button-change-scroll canceled_btn">
      Cancel
    </button>
  </div>
</div>