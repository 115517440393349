import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { CuratorPageService } from '../../../../../../../../service/curator-page.service';
import { NotificationService } from '../../../../../../../../service/notification.service';
import { message_properties } from '../../../../../../../../../environments/message_properties';
import { AccountsService } from './../../../../../../../../service/accounts.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-insight-form',
  templateUrl: './insight-form.component.html',
  styleUrls: ['./insight-form.component.scss'],
})
export class InsightFormComponent implements OnInit, OnDestroy {
  insiteForm: UntypedFormGroup;
  creatingUser: number;
  symmary_type: number = 0;
  event: number = 0;
  summaryId: number = 0;
  title: string;
  saveButton: boolean = false;
  insightSubscription: Subscription;
  summaryDetailSubscription: Subscription;
  actionSubscription: Subscription;
  commentSubscription: Subscription;
  actionUpdateSubscription: Subscription;
  insightUpdateSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<InsightFormComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private toastr: NotificationService,
    private curatorService: CuratorPageService,
    private accountService: AccountsService
  ) {
    this.summaryId = data.summaryId;
    this.event = data.event;
    this.symmary_type = data.symmary_type;
  }

  ngOnInit(): void {
    if (this.symmary_type === 2406) {
      this.title = 'Add Action';
    } else if (this.symmary_type === 2586) {
      this.title = 'Add Comment';
    } else {
      this.title = 'Add Insight';
    }
    if (this.summaryId !== 0) {
      this.getEventSummaryDetail(this.summaryId);
    }

    this.creatingUser = this.accountService.muser;
    this.insiteForm = this.formBuilder.group({
      visibility_type: new UntypedFormControl(1953),
      summary_type: new UntypedFormControl(this.symmary_type),
      summary_id: new UntypedFormControl(this.summaryId),
      remarks: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(2048),
      ]),
      creating_member: new UntypedFormControl(this.creatingUser),
      event: new UntypedFormControl(this.event),
    });
  }
  createInsights(ngForm: NgForm, symmary_type) {
    this.saveButton = true;
    if (this.summaryId === 0) {
      if (symmary_type === 2406) {
        // 'Add Action'
        this.actionSubscription = this.curatorService
          .createActions(ngForm)
          .subscribe((data) => {
            if (data.status === 201) {
              this.dialogRef.close();
              this.toastr.showSuccess(
                message_properties.VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__ACTION_FORM__SUCCESS,
                ''
              );
            } else {
              this.saveButton = false;
              this.toastr.showError(
                message_properties.VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__ACTION_FORM__ERROR,
                ''
              );
            }
          });
      } else if (symmary_type === 2586) {
        // 'Add Comment'
        this.commentSubscription = this.curatorService
          .createComment(ngForm)
          .subscribe((data) => {
            // 'createComment'
            if (data.status === 201) {
              this.dialogRef.close();
              this.toastr.showSuccess(
                message_properties.VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__COMMENT_FORM__SUCCESS,
                ''
              );
            } else {
              this.saveButton = false;
              this.toastr.showError(
                message_properties.VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__COMMENT_FORM__ERROR,
                ''
              );
            }
          });
      } else {
        this.insightSubscription = this.curatorService
          .createInsights(ngForm)
          .subscribe((data) => {
            // 'createInsights'
            if (data.status === 201) {
              this.dialogRef.close();
              this.toastr.showSuccess(
                message_properties.VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__INSIGHT_FORM__SUCCESS,
                ''
              );
            } else {
              this.saveButton = false;
              this.toastr.showError(
                message_properties.VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__INSIGHT_FORM__ERROR,
                ''
              );
            }
          });
      }
    } else {
      if (symmary_type === 2406) {
        this.actionUpdateSubscription = this.curatorService
          .updateActions(ngForm)
          .subscribe((data) => {
            if (data.status === 201) {
              this.dialogRef.close();
              this.toastr.showSuccess(
                message_properties.VIEW_COMMUNITY_UPDATE_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__ACTION_FORM__SUCCESS,
                ''
              );
            } else {
              this.toastr.showError(
                message_properties.VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__ACTION_FORM__ERROR,
                ''
              );
            }
          });
      } else {
        this.insightUpdateSubscription = this.curatorService
          .updateInsights(ngForm)
          .subscribe((data) => {
            if (data.status === 201) {
              this.dialogRef.close();
              this.toastr.showSuccess(
                message_properties.VIEW_COMMUNITY_UPDATE_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__INSIGHT_FORM__SUCCESS,
                ''
              );
            } else {
              this.toastr.showError(
                message_properties.VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__INSIGHT_FORM__ERROR,
                ''
              );
            }
          });
      }
    }
  }
  getEventSummaryDetail(eventSummaryId) {
    this.summaryDetailSubscription = this.curatorService
      .getEventSummaryDetail(eventSummaryId)
      .subscribe((data) => {
        this.insiteForm.patchValue({
          visibility_type: 1953,
          summary_type: this.symmary_type,
          remarks: data.remarks,
          creating_member: data.creating_member,
          event: this.event,
        });
      });
  }
  ngOnDestroy() {
    if (this.insightSubscription) {
      this.insightSubscription.unsubscribe();
    }
    if (this.summaryDetailSubscription) {
      this.summaryDetailSubscription.unsubscribe();
    }
    if (this.actionSubscription) {
      this.actionSubscription.unsubscribe();
    }
    if (this.commentSubscription) {
      this.commentSubscription.unsubscribe();
    }
    if (this.actionUpdateSubscription) {
      this.actionUpdateSubscription.unsubscribe();
    }
    if (this.insightUpdateSubscription) {
      this.insightUpdateSubscription.unsubscribe();
    }
  }
}
