import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from './../../../../../environments/environment.prod';
import { AdminPageService } from '../../../../service/admin-page.service';
import { MentorService } from '../../../../service/mentor.service';
import { UpdateRequestedSessionComponent } from './../../my-mentor/update-requested-session/update-requested-session.component';
import { ViewGuideVideoComponent } from './../../admin-page/view-guide-video/view-guide-video.component';
import { UploadService } from '../../../../service/upload.service';
import { NotificationService } from '../../../../service/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MeaningNetworkService } from '../../../../service/meaning-network.service';
import { MatDialog } from '@angular/material/dialog';
import { MeaningFullWorkExperienceFormProfileComponent } from './../meaning-full-work-experience-form-profile/meaning-full-work-experience-form-profile.component';
import { EducationFormProfileComponent } from './../education-form-profile/education-form-profile.component';
import { VolunteerExperianceFormProfileComponent } from './../volunteer-experiance-form-profile/volunteer-experiance-form-profile.component';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  AfterViewInit,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  NgForm,
  UntypedFormBuilder,
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxImageCompressService } from 'ngx-image-compress';
import { message_properties } from '../../../../../environments/message_properties';
import { CityStateCountryService } from '../../../../service/city-state-country.service';
import { ViewProfileComponent } from '../../admin-page/view-profile/view-profile.component';
import { folder_path } from '../../../../../environments/folder_path';
import { AccountsService } from './../../../../service/accounts.service';

@Component({
  selector: 'kt-meaning-central-profile',
  templateUrl: './meaning-central-profile.component.html',
  styleUrls: ['./meaning-central-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeaningCentralProfileComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('countrySearchInput', { static: false })
  countrySearchInput: ElementRef;
  @ViewChild('citySearchInput', { static: false }) citySearchInput: ElementRef;
  config = {
    placeholder: 'Enter a brief description',
    tabsize: 2,
    height: 150,
    toolbar: [],
    disableDragAndDrop: true,
    disableResizeEditor: true,
    linkTargetBlank: false,
    callbacks: {
      onPaste: function (e) {
        var bufferText = (e.originalEvent || e).clipboardData.getData('Text');
        e.preventDefault();
        document.execCommand('insertText', false, bufferText);
      },
    },
  };

  model: NgbDateStruct;
  @Input() accept = 'image/*';
  memberadditionalForm: UntypedFormGroup;
  experienceForm: UntypedFormGroup;
  mentoringExperienceForm: UntypedFormGroup;
  shwGuidEndorsementForm: UntypedFormGroup;
  educationForm: UntypedFormGroup;
  volunteerForm: UntypedFormGroup;
  profileForm: UntypedFormGroup;
  whaticareAboutForm: UntypedFormGroup;
  GuidHightltsForm: UntypedFormGroup;
  endorsementForm: UntypedFormGroup;

  memberadditionalFormData: string = '';
  experienceFormData: string = '';
  mentoringExperienceFormData: string = '';
  shwGuidEndorsementFormData: string = '';
  educationFormData: string = '';
  volunteerFormData: string = '';
  profileFormData: string = '';
  whaticareAboutFormData: string = '';
  GuidHightltsFormData: string = '';
  additionalData: Array<any>;
  experienceData: Array<any>;
  educationData: Array<any>;
  volunteeringData: Array<any>;
  profileData: {};
  ecount: number;
  emcount: number;
  vcount: number;
  adcount: number;
  full_name: any;
  memberid: any;
  countryBox1: boolean = false;

  shwUpload: boolean;
  role: boolean = false;
  shwEdtProfile = false;
  shwChgPwd = false;
  shwGuidHightlts = false;
  shwMentoringExperiance = false;
  shwGuidEndorsement = false;
  shwCloseAcc = false;
  shwSchool = false;
  shwTreasure = false;
  shwExperience = false;
  shwStory = false;
  shwCare = false;
  shwVolunteer = false;
  shwAccountProfileAcc = false;

  message: any = '';
  changePasswordForm: UntypedFormGroup;
  memberId: string = '';
  first_name: string = '';
  last_name: string = '';
  city: string = '';
  state: string = '';
  country: string = '';
  adminIndicator: boolean = false;
  topics = [];
  meaningLog = [];
  myTreasure = [];
  countryList = [];
  countryCount: number;
  cityList = [];
  cityCount: number;
  cityBox: boolean = false;

  stateList = [];
  stateCount: number;
  stateBox: boolean = false;

  fileToUpload: File;
  imageUrl: File;
  profile_path:string = '/assets/media/users/default.png';
  profile_photo_present:boolean = false;
  guideProfilePage: boolean = false;
  topic_count: number = 0;
  expertise_level: number;
  description: string = '';
  highlight: string = '';
  no_of_years: number;
  loggedInUser: number;
  available: number;

  videoUrl: File;
  vedio_path: any;
  experties_id: number;
  video_path: string = '';
  requestData = [];
  myEndorseData = [];
  mentor_indicator: boolean;
  guide_experience_description: string;
  endorse_count: number;
  experience_count: number;
  education_count: number;
  volunteering_count: number;
  videoSize: number;
  imageSize: number;
  address_details: [];
  member_details_table_arr: [];
  filteredOptions1: Observable<string[]>;
  filteredOptions2: Observable<string[]>;
  filteredOptions3: Observable<string[]>;
  filteredOptions4: Observable<string[]>;
  filteredOptions5: Observable<string[]>;
  filteredOptions6: Observable<string[]>;
  myControl = new UntypedFormControl();
  tagList = [];
  tagListFilter = [];
  hideShow1: string = 'hide';
  hideShow2: string = 'hide';
  hideShow3: string = 'hide';
  hideShow4: string = 'hide';
  hideShow5: string = 'hide';
  hideShow6: string = 'hide';
  profileImageSize: number;
  interests = [];
  country_id: string = '';
  countryId: string;
  signedUrl: string;
  meaning_moments_count: number;
  tresure_count: number;

  whatMattersToMeToolTip =
    'Select up to six areas of interest relating to meaningful work. The order you select them in will be the order they appear.';
  mentoringExperienceToolTiop =
    'Add a brief description of your experiences guiding others, e.g., coaching, counseling, crisis or career counseling, tutoring.';
  guideEndorsementsToolTip =
    'Here you can see your accepted and pending endorsements from Explorers. Only accepted endorsements are visible to other Explorers.';
  meaningfulWorkStoryToolTip =
    'Here you can write about an experience or situation at work that made a difference to you and how it impacted your journey to meaningful work.';
  meaningfulWorkExperienceToolTip =
    'Here you can add some of your work highlights. This is not meant to be a resume.';
  educationToolTip =
    'Here you can share your key educational experiences. This is not meant to be a resume.';
  volunteerExperienceToolTip =
    'Here you can share meaningful volunteering experiences and acts of service.';
  myMeaningLogToolTip =
    'The Meaning Moment tag is for posts in which you share a specific moment or experience from your life that has impacted your perspective on meaningful work.';
  myTreasuresToolTip =
    'Treasures enable you to save content that you have already read, watched, or listened to and have found particularly useful to your journey.';
  myBookmarkToolTip =
    'You can bookmark MeaningSphere content that you would like to save for later because you think it may be interesting or useful to your journey. ';
  guide_highlights =
    'Give a brief overview of other experiences where you \'ve acted as a guide to others in some way.';

  searchForm: UntypedFormGroup;
  page: number = 1;
  throttle = 300;
  modalScrollDistance = 1;
  modalScrollThrottle = 30;

  subbscriptionCity: Subscription;
  tagListSubscription: Subscription;
  whatMatterSubscription: Subscription;
  removePictureSubscription: Subscription;
  volunteerExperienceSubscription: Subscription;
  educationFormSubscription: Subscription;
  workExperienceSubscription: Subscription;
  endorseStatusSubscription: Subscription;
  endorseRequestSubscription: Subscription;
  endorseSubscription: Subscription;
  requestHandleSubscription: Subscription;
  sentRequestSubscription: Subscription;
  saveVideoSubscription: Subscription;
  treasureSubscription: Subscription;
  momentSubscription: Subscription;
  careWhatSubscription: Subscription;
  guideDescriptionSubscription: Subscription;
  expertGuideSubscription: Subscription;
  countryEditSubscription: Subscription;
  profileEditSubscription: Subscription;
  saveProfileSubscription: Subscription;
  workStorySubscription: Subscription;
  guideHighlightSubscription: Subscription;
  mentorExperienceSubscription: Subscription;
  whatCareSubscription: Subscription;
  additionalSubscription: Subscription;
  volunteerSubscription: Subscription;
  educationSubscription: Subscription;
  experienceSubscription: Subscription;
  profileSubscription: Subscription;
  uploadPhotoSubscription: Subscription;
  constructor(
    private meaning_network_service: MeaningNetworkService,
    private spinner: NgxSpinnerService,
    private toastr: NotificationService,
    private cdRef: ChangeDetectorRef,
    private upload: UploadService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private mentorService: MentorService,
    private adminService: AdminPageService,
    public sanitizer: DomSanitizer,
    private imageCompress: NgxImageCompressService,
    private cityStateCountry: CityStateCountryService,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountsService
  ) {
    this.videoSize = environment.videoSize;
    this.imageSize = environment.imageSize;
    this.tagListForWhatmatter();
    this.searchForm = this.formBuilder.group({
      name: new UntypedFormControl(''),
      countryId: new UntypedFormControl(''),
      page: 1,
    });
  }

  async findMemberId() {
    await this.route.queryParams.subscribe((params) => {
      return (this.memberId = params['member']);
    });
  }

  ngOnInit(): void {
    this.profileImageSize = environment.profileImageSize;
    this.experties_id = 0;
    this.loggedInUser = this.accountService.muser;
    this.role = this.accountService.role === '2223' ? true : false;
    this.adminIndicator =
      this.accountService.admin_indicator === 'true' ? true : false;

    this.findMemberId();
    if (this.memberId) {
      this.memberid = this.memberId;
    } else {
      this.memberid = this.accountService.muser;
    }
    this.memberadditionalForm = new UntypedFormGroup({
      property_value: new UntypedFormControl(''),
      check_value: new UntypedFormControl(0),
      property_id: new UntypedFormControl(2353),
      member: new UntypedFormControl(this.memberid),
      association_type: new UntypedFormControl(1987),
    });

    this.educationForm = new UntypedFormGroup({
      member: new UntypedFormControl(this.memberid),
      association_type: new UntypedFormControl(2297),
    });

    this.whaticareAboutForm = new UntypedFormGroup({
      matter1: new UntypedFormControl(''),
      matter2: new UntypedFormControl(''),
      matter3: new UntypedFormControl(''),
      matter4: new UntypedFormControl(''),
      matter5: new UntypedFormControl(''),
      matter6: new UntypedFormControl(''),
      property_id: new UntypedFormControl(2354),
      check_value: new UntypedFormControl(9),
      member: new UntypedFormControl(this.memberid),
      tenant: new UntypedFormControl(1),
    });

    this.mentoringExperienceForm = new UntypedFormGroup({
      visibility_type: new UntypedFormControl(1953),
      subject_classification: new UntypedFormControl(1),
      expertise_level: new UntypedFormControl(2140),
      certifying_institution: new UntypedFormControl('123'),
      company_id: new UntypedFormControl(1),
      description: new UntypedFormControl(''),
      member: new UntypedFormControl(this.memberid),
    });

    this.GuidHightltsForm = new UntypedFormGroup({
      visibility_type: new UntypedFormControl(1953),
      subject_classification: new UntypedFormControl(1),
      expertise_level: new UntypedFormControl(2140),
      certifying_institution: new UntypedFormControl('123'),
      company_id: new UntypedFormControl(1),
      highlight: new UntypedFormControl(''),
      member: new UntypedFormControl(this.memberid),
      available: new UntypedFormControl(false),
      video_path: new UntypedFormControl(''),
      no_of_years: new UntypedFormControl('', [Validators.required]),
    });
    this.profileForm = new UntypedFormGroup({
      first_name: new UntypedFormControl('', [Validators.required]),
      last_name: new UntypedFormControl('', [Validators.required]),
      imageUrl: new UntypedFormControl(''),
      city: new UntypedFormControl('', [Validators.required]),
      state: new UntypedFormControl(''),
      country: new UntypedFormControl('', [Validators.required]),
      countryId: new UntypedFormControl(localStorage.getItem('countryId')),
      cityId: new UntypedFormControl(localStorage.getItem('cityId')),
      check_value: new UntypedFormControl(4),
      member: new UntypedFormControl(this.memberid),
      user_id: new UntypedFormControl(this.accountService.user_id),
    });
    this.endorsementForm = new UntypedFormGroup({
      endorsing_member: new UntypedFormControl(this.loggedInUser),
    });
    this.volunteerForm = new UntypedFormGroup({
      member: new UntypedFormControl(this.memberid),
      association_type: new UntypedFormControl(2352),
    });

    this.getAllExperience();
    this.meaning_network_service.refreshneededExperienced.subscribe((data) => {
      this.getAllExperience();
    });
    this.getAllEducation();
    this.meaning_network_service.refreshneededEducation.subscribe((data) => {
      this.getAllEducation();
    });
    this.getAllVolunteering();
    this.meaning_network_service.refreshneededVolunteer.subscribe((data) => {
      this.getAllVolunteering();
    });

    this.getAdditionalData();
    this.getProfileWithAddress();
    this.getWhatICareAbout(this.loggedInUser);
    this.getMeaningMoments(3);
    this.getTreasure(3);
    this.setGuideExpertiseEdit();
    this.setGuideExpertiseDescriptionEdit();
    this.setCountryIdForEdit();
    this.getRequestsSentToMe();
    this.getMyEndorseData();
  }
  ngAfterViewInit(): void {}

  tagListForAutoselect() {
    this.tagListSubscription = this.meaning_network_service
      .getTagListForAutoSelect()
      .subscribe((data) => {
        this.tagList = data.results;
        this.cdRef.detectChanges();
      });
  }

  viewMeaningFullWorkExperienceFormProfile(callType, id, cdk = 1) {
    this.dialog.open(MeaningFullWorkExperienceFormProfileComponent, {
      width: '30%',
      disableClose: true,
      data: { addOrEdit: callType, experienceId: id },
    });
  }
  viewEducationFormProfile(callType1, id, cdk = 1) {
    this.dialog.open(EducationFormProfileComponent, {
      width: '30%',
      disableClose: true,
      data: { addOrEdit: callType1, educationId: id },
    });
  }
  viewVolunteerExperianceFormProfile(callType2, id, cdk = 1) {
    this.dialog.open(VolunteerExperianceFormProfileComponent, {
      disableClose: true,
      width: '30%',
      data: { addOrEdit: callType2, volunteerId: id },
    });
  }

  profileImageUpload() {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      let mimeType = image.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
      let letmineTypeArr = mimeType.split('/');
      if (
        mimeType !== 'image/jpeg' &&
        mimeType !== 'image/jpg' &&
        mimeType !== 'image/png'
      ) {
        this.toastr.showError(
          message_properties.MEANING_CENTRAL_PROFILE_HANDLE_FILE_INPUT_ERROR,
          ''
        );
        return false;
      }
      if (this.imageCompress.byteCount(image) > this.profileImageSize) {
        this.toastr.showError(message_properties.PROFILE_IMAGE_SIZE_ERROR, '');
        return false;
      }
      this.imageCompress
        .compressFile(image, orientation, 80, 60) // 50% ratio, 50% quality
        .then((compressedImage) => {
          this.profile_path = compressedImage;
          this.cdRef.detectChanges();
          let image_pro = this.upload.base64toFileInformation(
            compressedImage,
            Date.now().toString() + '.' + letmineTypeArr[1]
          );
          this.profilePhotoUpload(image_pro, this.profile_path);
        });
    });
  }
  hideoverlay(event, hide) {
    let eve = event.target.value.toLowerCase();
    const val = this.tagList.filter((s) => {
      const s1 = s.toLowerCase();
      return s1.includes(eve);
    });

    this.tagListFilter = val.slice(0, 5);
    if (this.tagListFilter.length > 0) {
      if (hide === '1') {
        this.hideShow1 = 'active';
      } else if (hide === '2') {
        this.hideShow2 = 'active';
      } else if (hide === '3') {
        this.hideShow3 = 'active';
      } else if (hide === '4') {
        this.hideShow4 = 'active';
      } else if (hide === '5') {
        this.hideShow5 = 'active';
      } else if (hide === '6') {
        this.hideShow6 = 'active';
      }
    }

    if (eve.length === 0) {
      this.tagListFilter = [];
    }
  }

  hideoverlaykey(matter, event) {
    if (matter === 'matter1') {
      this.whaticareAboutForm.patchValue({
        matter1: event,
      });
      this.hideShow1 = 'hide';
    } else if (matter === 'matter2') {
      this.whaticareAboutForm.patchValue({
        matter2: event,
      });
      this.hideShow2 = 'hide';
    } else if (matter === 'matter3') {
      this.whaticareAboutForm.patchValue({
        matter3: event,
      });
      this.hideShow3 = 'hide';
    } else if (matter === 'matter4') {
      this.whaticareAboutForm.patchValue({
        matter4: event,
      });
      this.hideShow4 = 'hide';
    } else if (matter === 'matter5') {
      this.whaticareAboutForm.patchValue({
        matter5: event,
      });
      this.hideShow5 = 'hide';
    } else if (matter === 'matter6') {
      this.whaticareAboutForm.patchValue({
        matter6: event,
      });
      this.hideShow6 = 'hide';
    }

    this.cdRef.detectChanges();
  }

  async profilePhotoUpload(image, profile_path) {
    var data_ = {};
    this.spinner.show();
    const image_file_size = image.size / 1024;
    if (image_file_size < this.profileImageSize) {
      if (image) {
        var image_spit_arr = image.name.split('.');
        const file_name =
          image_spit_arr[0].toString() +
          Date.now().toString() +
          '.' +
          image_spit_arr[1].toString();
        this.upload.fileUpload(image, file_name, 6);
        await this.delay(3000);
        data_ = {
          profile_photo: image
            ? `${this.accountService.AWS_BUCKET_888}${folder_path.folder_profile}${file_name}`
            : '',
          user_id: this.loggedInUser,
        };
      }
    }
    this.uploadPhotoSubscription = this.meaning_network_service
      .postProfilePhotoData(data_)
      .subscribe(
        (data) => {
          localStorage.setItem(
            'image',
            data.picture_path !== null ? data.picture_path : '0'
          );
          this.profile_path = data.picture_path;
          this.profile_photo_present = true;
          this.toastr.showSuccess(
            message_properties.MEANING_CENTRAL_PROFILE_POST_PROFILE_PHOTO_DATA_SUCCESS,
            ''
          );
          this.spinner.hide();
          this.cdRef.detectChanges();
        },
        (error) => {
          this.toastr.showError(
            message_properties.MEANING_CENTRAL_PROFILE_POST_PROFILE_PHOTO_DATA_ERROR,
            ''
          );
          this.spinner.hide();
          this.cdRef.detectChanges();
        }
      );
  }

  getProfileWithAddress() {
    this.profileSubscription = this.meaning_network_service
      .getProfileDetails(this.memberid)
      .subscribe((data) => {
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.city = data.city;
        this.state = data.state;
        this.country = data.country;
        this.profile_path = data.profile_path;
        this.profile_photo_present = data.profile_path ? true : false;
        this.mentor_indicator = Boolean(data.mentor_indicator);

        this.full_name = this.first_name + ' ' + this.last_name;
        localStorage.setItem('ufullname', btoa(this.full_name));
        localStorage.setItem(
          'image',
          this.profile_path !== null ? this.profile_path : '0'
        );
        this.country_id = data.country_id;
        localStorage.setItem('countryId', this.country_id);
        this.cdRef.detectChanges();
      });
  }
  getAllExperience() {
    const FormData = this.memberadditionalForm.value;
    this.experienceSubscription = this.meaning_network_service
      .getAllExperienceData(FormData)
      .subscribe((data) => {
        this.experience_count = data.count;
        this.experienceData = data.results;

        this.cdRef.detectChanges();
      });
  }
  getAllEducation() {
    const FormData = this.educationForm.value;
    this.educationSubscription = this.meaning_network_service
      .getAllEducationData(FormData)
      .subscribe((data) => {
        this.education_count = data.count;
        this.educationData = data.results;
        this.cdRef.detectChanges();
      });
  }

  getAllVolunteering() {
    const FormData = this.volunteerForm.value;
    this.volunteerSubscription = this.meaning_network_service
      .getAllVolunteeringData(FormData)
      .subscribe((data) => {
        this.volunteering_count = data.count;
        this.volunteeringData = data.results;
        this.cdRef.detectChanges();
      });
  }

  getAdditionalData() {
    const FormData = this.memberadditionalForm.value;
    this.additionalSubscription = this.meaning_network_service
      .getAdditionalData(FormData)
      .subscribe((data) => {
        if (data.code !== 200) {
          this.memberadditionalFormData = data.property_value;
          if (this.memberadditionalFormData==undefined || this.memberadditionalFormData=='') {
            this.memberadditionalFormData = 'You have not added your work story!';
          }
          this.memberadditionalForm.patchValue({
            property_value: data.property_value,
            check_value: 0,
            mode: 0, // mode update
            member: this.memberid,
            user_id: this.accountService.user_id,
          });
        } else {
          this.memberadditionalFormData = 'You have not added your work story!';
        }
        this.cdRef.detectChanges();
      });
  }

  whaticareAboutSave() {
    const data = {
      interest_id: this.interests,
      member_id: this.loggedInUser,
    };
    this.whatCareSubscription = this.meaning_network_service
      .postWhatICareAbout(data)
      .subscribe(
        () => {
          this.toastr.showSuccess(
            message_properties.WHAT_I_CARE_ABOUT_SUCCESS,
            ''
          );
          this.getWhatICareAbout(this.loggedInUser);
          this.cdRef.detectChanges();
        },
        () => {
          this.toastr.showSuccess(
            message_properties.WHAT_I_CARE_ABOUT_ERROR,
            ''
          );
          this.cdRef.detectChanges();
        }
      );
  }
  submit() {
    this.whaticareAboutSave();
    this.shwCare = false;
    this.cdRef.detectChanges();
  }
  mentoringExperienceSave(ngForm) {
    this.spinner.show();
    this.mentorExperienceSubscription = this.meaning_network_service
      .mentoringExperienceCreate(ngForm)
      .subscribe(
        (data) => {
          if (data.status === 201) {
            this.toastr.showSuccess(
              message_properties.MEANING_CENTRAL_PROFILE_GUIDE_DESCRIPTION__SUCCESS,
              ''
            );
            this.setGuideExpertiseEdit();
            this.shwMentoringExperiance = false;
            this.cdRef.detectChanges();
            this.spinner.hide();
          } else {
            this.toastr.showError(data.message, '');
            this.shwMentoringExperiance = true;
            this.cdRef.detectChanges();
            this.spinner.hide();
          }
        },
        (error) => {
          this.toastr.showError(
            message_properties.MEANING_CENTRAL_PROFILE_GUIDE_DESCRIPTION__ERROR,
            ''
          );
          this.cdRef.detectChanges();
          this.spinner.hide();
        }
      );
  }
  GuidHightltsSave(ngForm) {
    this.spinner.show();
    this.guideHighlightSubscription = this.meaning_network_service
      .guideHighlightCreate(ngForm)
      .subscribe(
        (data) => {
          if (data.status === 201) {
            this.toastr.showSuccess(
              message_properties.MEANING_CENTRAL_PROFILE_GUIDE_HIGHLIGHT__SUCCESS,
              ''
            );
            this.setGuideExpertiseEdit();
            this.shwGuidHightlts = false;

            this.cdRef.detectChanges();
            this.spinner.hide();
          } else if (data.status === 204) {
            this.toastr.showError(data.message, '');
            this.shwGuidHightlts = true;
            this.cdRef.detectChanges();
            this.spinner.hide();
          }
        },
        (error) => {
          this.toastr.showError(
            message_properties.MEANING_CENTRAL_PROFILE_GUIDE_HIGHLIGHT__ERROR,
            ''
          );
          this.cdRef.detectChanges();
          this.spinner.hide();
        }
      );
  }
  inspiringWorkStorySave(ngForm: NgForm) {
    this.spinner.show();
    this.workStorySubscription = this.meaning_network_service
      .inspiringWorkStory(ngForm)
      .subscribe(
        (data) => {
          if (data.status === 201) {
            this.getAdditionalData();
            this.shwStory = false;
            this.toastr.showSuccess(
              message_properties.MEANING_CENTRAL_PROFILE_MEMBER_ADDITIONAL__SUCCESS,
              ''
            );
            this.cdRef.detectChanges();
            this.spinner.hide();
          } else {
            this.shwStory = true;
            this.toastr.showError(data.message, '');
            this.spinner.hide();
          }
        },
        (error) => {
          this.toastr.showError(
            message_properties.MEANING_CENTRAL_PROFILE_MEMBER_ADDITIONAL__ERROR,
            ''
          );
          this.cdRef.detectChanges();
          this.spinner.hide();
        }
      );
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  async profileSave(ngForm: NgForm) {
    this.spinner.show();
    let data_ = {
      check_value: 4,
      cityId: ngForm['cityId'],
      countryId: ngForm['countryId'],
      city: ngForm['city'],
      country: ngForm['country'],
      first_name: ngForm['first_name'],
      last_name: ngForm['last_name'],
      member: this.memberid,
      state: ngForm['state'],
      user_id: this.accountService.user_id,
    };
    this.saveProfileSubscription = this.meaning_network_service
      .postProfileData(data_)
      .subscribe(
        (data) => {
          if (data.code === 204) {
            this.toastr.showError(data.message, '');
            this.cdRef.detectChanges();
            this.spinner.hide();
          } else {
            localStorage.setItem(
              'countryIdForEdit',
              data.address_response['countryId']
            );
            localStorage.setItem('cityId', data.address_response['cityId']);
            localStorage.setItem(
              'countryId',
              data.address_response['countryId']
            );
            localStorage.setItem(
              'current_time_zone',
              data.address_response['timezone_str']
            );
            localStorage.setItem(
              'ufullname',
              window.btoa(data.full_name['full_name'])
            );
            this.getProfileWithAddress();
            this.shwEdtProfile = false;
            this.toastr.showSuccess(
              message_properties.MEANING_CENTRAL_PROFILE_POST_PROFILE_DATA_SUCCESS,
              ''
            );
            this.cdRef.detectChanges();
            this.spinner.hide();
          }
        },
        (error) => {
          this.toastr.showError(
            message_properties.MEANING_CENTRAL_PROFILE_POST_PROFILE_DATA_ERROR,
            ''
          );
          this.cdRef.detectChanges();
          this.spinner.hide();
        }
      );
  }

  setProfileEdit() {
    this.loadEditProfile();
    this.profileEditSubscription = this.meaning_network_service
      .getProfileDetails(this.memberid)
      .subscribe((data) => {
        this.profileForm.patchValue({
          first_name: data.first_name,
          last_name: data.last_name,
          city: data.city,
          state: data.state,
          country: data.country,
          check_value: 4,
          mode: 0, // mode update
          member: this.memberid,
          user_id: this.accountService.user_id,
        });
        this.citylistSearch();
        this.cdRef.detectChanges();
      });
  }
  setCountryIdForEdit() {
    this.countryEditSubscription = this.meaning_network_service
      .getProfileDetails(this.memberid)
      .subscribe((data) => {
        localStorage.setItem('countryIdForEdit', data?.country_id);
        this.cdRef.detectChanges();
      });
  }

  setGuideExpertiseEdit() {
    this.expertGuideSubscription = this.meaning_network_service
      .guideExpertiseDetails(this.memberid)
      .subscribe((data) => {
        if (data.status !== 204) {
          this.experties_id = Number(data.id);
          this.expertise_level = data.expertise_level;
          this.video_path = data.video_path;
          this.guide_experience_description =
            data.description ?? 'You have not added any related experience!';
          this.highlight = data.highlight;
          this.no_of_years = data.no_of_years;
          this.available = data.available;
          this.GuidHightltsForm.patchValue({
            expertise_level: data.expertise_level,
            available: data.available,
            description: data.description,
            highlight: data.highlight,
            no_of_years: data.no_of_years,
          });
        } else {
          this.guide_experience_description =
            'You have not added any related experience!';
        }

        this.cdRef.detectChanges();
      });
  }
  setGuideExpertiseDescriptionEdit() {
    this.guideDescriptionSubscription = this.meaning_network_service
      .guideExpertiseDetails(this.memberid)
      .subscribe((data) => {
        if (data.status !== 204) {
          this.experties_id = Number(data.id);
          this.video_path = data.video_path;
          this.expertise_level = data.expertise_level;
          this.guide_experience_description =
            data.description ?? 'You have not added any related experience!';
          this.highlight = data.highlight;
          this.no_of_years = data.no_of_years;
          this.mentoringExperienceForm.patchValue({
            expertise_level: data.expertise_level,
            description: data.description,
            highlight: data.highlight,
            no_of_years: data.no_of_years,
          });
          this.cdRef.detectChanges();
        } else {
          this.guide_experience_description =
            'You have not added any related experience!';
        }
      });
  }

  loadEditProfile() {
    this.shwEdtProfile = !this.shwEdtProfile;
    this.shwChgPwd = false;
    this.shwCloseAcc = false;
    this.shwSchool = false;
    this.shwExperience = false;
    this.shwStory = false;
    this.shwCare = false;
    this.shwVolunteer = false;
    this.shwAccountProfileAcc = false;
    this.shwTreasure = false;
    this.shwGuidHightlts = false;
    this.shwGuidEndorsement = false;
    this.shwMentoringExperiance = false;
  }

  loadCare(cdk = 1) {
    this.shwEdtProfile = false;
    this.shwChgPwd = false;
    this.shwCloseAcc = false;
    this.shwSchool = false;
    this.shwExperience = false;
    this.shwStory = false;
    this.shwCare = !this.shwCare;
    this.shwVolunteer = false;
    this.shwAccountProfileAcc = false;
    this.shwTreasure = false;
    this.shwGuidHightlts = false;
    this.shwGuidEndorsement = false;
    this.shwMentoringExperiance = false;
  }

  loadEducation() {
    this.shwEdtProfile = false;
    this.shwChgPwd = false;
    this.shwCloseAcc = false;
    this.shwSchool = !this.shwSchool;
    this.shwExperience = false;
    this.shwStory = false;
    this.shwCare = false;
    this.shwVolunteer = false;
    this.shwAccountProfileAcc = false;
    this.shwTreasure = false;
    this.shwGuidHightlts = false;
    this.shwGuidEndorsement = false;
    this.shwMentoringExperiance = false;
  }

  loadStory() {
    this.shwEdtProfile = false;
    this.shwChgPwd = false;
    this.shwCloseAcc = false;
    this.shwSchool = false;
    this.shwExperience = false;
    this.shwStory = !this.shwStory;
    this.shwCare = false;
    this.shwVolunteer = false;
    this.shwAccountProfileAcc = false;
    this.shwTreasure = false;
    this.shwGuidHightlts = false;
    this.shwGuidEndorsement = false;
    this.shwMentoringExperiance = false;
  }

  loadAccountProfileAcc() {
    this.shwEdtProfile = false;
    this.shwChgPwd = false;
    this.shwCloseAcc = false;
    this.shwSchool = false;
    this.shwExperience = false;
    this.shwStory = false;
    this.shwAccountProfileAcc = !this.shwAccountProfileAcc;
    this.shwCare = false;
    this.shwVolunteer = false;
    this.shwTreasure = false;
    this.shwGuidHightlts = false;
    this.shwGuidEndorsement = false;
    this.shwMentoringExperiance = false;
  }

  loadEditguideHeightlts() {
    this.shwChgPwd = false;
    this.shwEdtProfile = false;
    this.shwCloseAcc = false;
    this.shwSchool = false;
    this.shwExperience = false;
    this.shwStory = false;
    this.shwCare = false;
    this.shwVolunteer = false;
    this.shwAccountProfileAcc = false;
    this.shwTreasure = false;
    this.shwGuidEndorsement = false;
    this.shwMentoringExperiance = false;
    this.shwGuidHightlts = !this.shwGuidHightlts;
  }

  loadMentoringExperiance() {
    this.shwGuidHightlts = false;
    this.shwChgPwd = false;
    this.shwEdtProfile = false;
    this.shwCloseAcc = false;
    this.shwSchool = false;
    this.shwExperience = false;
    this.shwStory = false;
    this.shwCare = false;
    this.shwVolunteer = false;
    this.shwAccountProfileAcc = false;
    this.shwTreasure = false;
    this.shwGuidEndorsement = false;
    this.shwMentoringExperiance = !this.shwMentoringExperiance;
  }
  loadGuidEndorsement() {
    this.shwGuidEndorsement = !this.shwGuidEndorsement;
    this.shwGuidHightlts = false;
    this.shwChgPwd = false;
    this.shwEdtProfile = false;
    this.shwCloseAcc = false;
    this.shwSchool = false;
    this.shwExperience = false;
    this.shwStory = false;
    this.shwCare = false;
    this.shwVolunteer = false;
    this.shwAccountProfileAcc = false;
    this.shwTreasure = false;
    this.shwMentoringExperiance = false;
  }

  getWhatICareAbout(member) {
    this.careWhatSubscription = this.meaning_network_service
      .getWhatICareAbout(this.loggedInUser)
      .subscribe((data) => {
        this.topic_count = data.count;
        this.topics = data.results;
        this.cdRef.detectChanges();
        this.interests = [];
        for (let obj in this.topics) {
          let tag_obj = this.topics[obj];
          let tag_id = tag_obj['tag_id'];
          this.interests.push(tag_id);
        }
      });
  }
  getMeaningMoments(limit) {
    this.spinner.show();
    const data = {
      loggedInUser: this.loggedInUser,
      bucket_type: 2564,
      limit: limit,
      page: 1,
    };
    this.momentSubscription = this.meaning_network_service
      .postAllTreasureLogsOfUser(data)
      .subscribe((response) => {
        this.meaning_moments_count = response.count;
        this.meaningLog = response.results;
        this.spinner.hide();
        this.cdRef.detectChanges();
      });
  }
  getTreasure(limit) {
    this.spinner.show();
    const data = {
      loggedInUser: this.loggedInUser,
      bucket_type: 2552,
      limit: limit,
      page: 1,
    };
    this.treasureSubscription = this.meaning_network_service
      .postAllTreasureLogsOfUser(data)
      .subscribe((response) => {
        this.tresure_count = response.count;
        this.myTreasure = response.results;
        this.spinner.hide();
        this.cdRef.detectChanges();
      });
  }

  handleVideoFileInput(event: any) {
    this.videoUrl = event.target.files[0];
    var videoType = this.videoUrl.type.split('/').pop().toLowerCase();
    // mp4, m4a, m4v, f4v, f4a, m4b, m4r, f4b, mov
    if (videoType !== 'mp4' && videoType !== 'mov' && videoType !== 'wmv') {
      this.toastr.showError(
        message_properties.MEANING_CENTRAL_PROFILE_handleVideoFileInput_ERROR,
        ''
      );
      return false;
    }
    var file_size = this.videoUrl.size / 1024;
    if (file_size > this.videoSize) {
      this.toastr.showError(
        message_properties.MEANING_CENTRAL_PROFILE_handleVideoFileInput_SIZE_ERROR,
        ''
      );
      this.cdRef.detectChanges();
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(this.videoUrl);
      reader.onload = (_event) => {
        this.videoSave(this.videoUrl);
      };
    }
  }

  async videoSave(fileToUpload) {
    this.spinner.show();
    this.upload.fileUpload(fileToUpload, this.memberid, 6);
    await this.delay(3000);
    let data_ = {
      member: this.memberid,
      video_path: fileToUpload
        ? `${this.accountService.AWS_BUCKET_888}${folder_path.folder_profile}${fileToUpload.name}`
        : '',
    };
    this.saveVideoSubscription = this.meaning_network_service
      .guideVideoUpdate(data_, this.memberid)
      .subscribe(
        (data) => {
          this.toastr.showSuccess(
            message_properties.MEANING_CENTRAL_PROFILE_GUIDE_VIDEO_UPLOAD_SUCCESS,
            ''
          );
          this.video_path = data.video_path;
          this.cdRef.detectChanges();
          this.spinner.hide();
        },
        (error) => {
          this.toastr.showError(
            message_properties.MEANING_CENTRAL_PROFILE_GUIDE_VIDEO_UPLOAD_ERROR,
            ''
          );
          this.cdRef.detectChanges();
          this.spinner.hide();
        }
      );
  }
  openGuideVideo() {
    this.dialog.open(ViewGuideVideoComponent, {
      disableClose: true,
      width: '50%',
      data: { source: this.video_path },
    });
  }

  getRequestsSentToMe() {
    this.sentRequestSubscription = this.mentorService
      .getCollabContent(this.loggedInUser, 'requests2me')
      .subscribe(
        (data) => {
          this.requestData = data.results;
          this.cdRef.detectChanges();
        },
        (error) =>
          this.toastr.showError(
            message_properties.MEANING_CENTRAL_PROFILE_GET_REQUESTS_SENT_TO_ME_ERROR,
            ''
          )
      );
  }
  handleRequest(ccId, rsvp) {
    this.requestHandleSubscription = this.mentorService
      .updateCollabContent(ccId, rsvp, '', this.loggedInUser)
      .subscribe(
        (response) => {
          if (response.status === 200) {
            this.getRequestsSentToMe();
            if (rsvp === 'A') {
              this.toastr.showSuccess(
                message_properties.MEANING_CENTRAL_PROFILE_HANDLE_REQUEST_ACCEPT_SUCCESS,
                ''
              );
            } else {
              this.toastr.showSuccess(
                message_properties.MEANING_CENTRAL_PROFILE_HANDLE_REQUEST_DECLINED_SUCCESS,
                ''
              );
            }
          } else {
            this.toastr.showError(
              message_properties.MEANING_CENTRAL_PROFILE_HANDLE_REQUEST_ERROR,
              ''
            );
          }
        },
        () =>
          this.toastr.showError(
            message_properties.MEANING_CENTRAL_PROFILE_HANDLE_REQUEST_ERROR,
            ''
          )
      );
  }
  updateSession(requestor, requestor_dp, title, request_id, requestor_id) {
    this.dialog.open(UpdateRequestedSessionComponent, {
      disableClose: true,
      width: '60%',
      data: {
        requestor: requestor,
        requestor_dp: requestor_dp,
        title: title,
        ccId: request_id,
        requestor_id: requestor_id,
      },
    });
  }
  returnImage(image) {
    if (image) {
      return image;
    } else {
      return 'assets/media/users/default.png';
    }
  }

  getMyEndorseData() {
    let Formdata = this.endorsementForm.value;
    this.endorseSubscription = this.adminService
      .myEndorseList(Formdata)
      .subscribe(
        (data) => {
          this.endorse_count = data.count;
          this.myEndorseData = data.results;
          this.cdRef.detectChanges();
        },
        (error) => {
          this.toastr.showError('Technical Issue. Requests not available', '');
        }
      );
  }

  acceptMyEndorsementRequest(id) {
    let ngForm = {
      endorsement_status: 2402,
    };
    this.endorseRequestSubscription = this.adminService
      .endorse_ChangeStatus(ngForm, id)
      .subscribe((data) => {
        this.toastr.showSuccess(
          message_properties.MEANING_CENTRAL_PROFILE_ENDORSEMENT_ACCEPT_SUCCESS,
          ''
        );
        this.getMyEndorseData();
        this.cdRef.detectChanges();
      });
  }
  declineMyEndorsementRequest(id) {
    let ngForm = {
      endorsement_status: 2403,
    };
    this.endorseStatusSubscription = this.adminService
      .endorse_ChangeStatus(ngForm, id)
      .subscribe((data) => {
        this.toastr.showSuccess(
          message_properties.MEANING_CENTRAL_PROFILE_ENDORSEMENT_DECLINED_SUCCESS,
          ''
        );
        this.getMyEndorseData();
        this.cdRef.detectChanges();
      });
  }
  viewMeaningFullWorkExperienceFormProfileDelete(experienceId) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to remove your work experience.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.workExperienceSubscription = this.meaning_network_service
          .companyHardDelete(this.loggedInUser, experienceId)
          .subscribe((data) => {
            this.toastr.showSuccess(
              'Work Experience deleted successfully!',
              ''
            );
            this.getAllExperience();
            this.cdRef.detectChanges();
          });
      }
    });
  }
  viewEducationFormProfileDelete(educationId) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to remove your education.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.educationFormSubscription = this.meaning_network_service
          .companyHardDelete(this.loggedInUser, educationId)
          .subscribe((data) => {
            this.getAllEducation();
            this.toastr.showSuccess('Education deleted successfully!', '');
            this.cdRef.detectChanges();
          });
      }
    });
  }
  viewVolunteerExperianceFormProfileDelete(volunteerId) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to remove your volunteer experience.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.volunteerExperienceSubscription = this.meaning_network_service
          .companyHardDelete(this.loggedInUser, volunteerId)
          .subscribe((data) => {
            this.toastr.showSuccess(
              'Volunteer Experience deleted successfully!',
              ''
            );
            this.getAllVolunteering();
            this.cdRef.detectChanges();
          });
      }
    });
  }

  removePic() {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to remove your profile photo.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      const data = {
        memberId: this.loggedInUser,
      };
      if (result.value) {
        this.removePictureSubscription = this.meaning_network_service
          .removeProfilePic(data)
          .subscribe((response) => {
            this.profile_path = null;
            this.profile_photo_present = false;
            localStorage.setItem('image', '0');
            this.toastr.showSuccess(
              message_properties.MEANING_CENTRAL_PROFILE_REMOVE_PIC,
              ''
            );
            this.cdRef.detectChanges();
          });
      }
    });
  }
  tagListForWhatmatter() {
    this.whatMatterSubscription = this.meaning_network_service
      .getTagListForWhatMatter()
      .subscribe((data) => {
        this.tagList = data.results;
        this.cdRef.detectChanges();
      });
  }
  selctInterest(interest) {
    if (!this.interests.includes(interest)) {
      if (this.interests.length < 6) {
        this.interests.push(interest);
      }
    } else {
      this.interests.splice(this.interests.indexOf(interest), 1);
    }
  }
  checkInterest(interest) {
    let flag = false;
    if (this.interests.includes(interest)) {
      flag = true;
    }
    return flag;
  }

  setCountry(ev) {
    this.countryBox1 = false;
    this.cityBox = false;
    localStorage.setItem('countryId', ev[0].value);
    this.profileForm.patchValue({
      country: ev[0].text,
      countryId: ev[0].value,
    });
    if (this.country_id !== ev[0].value) {
      this.country_id = ev[0].value;
      this.profileForm.patchValue({
        state: '',
        city: '',
      });
    }
  }
  citylistSearch() {
    fromEvent(this.citySearchInput.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // if character length greater then 2
        filter((res) => res.length > 2),
        // Time in milliseconds between key events
        debounceTime(500),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((sendData: string) => {
        this.searchForm.patchValue({
          name: sendData,
          countryId: localStorage.getItem('countryId'),
        });
        this.callSearchCityFunction(1);
      });
  }
  setCity(name, stateId, id) {
    this.cityBox = false;
    localStorage.setItem('cityId', id);
    this.profileForm.patchValue({
      city: name,
      state: stateId,
      cityId: id,
    });
  }

  viewEducationFormProfileEdit(callType1, id, start_date, cdk = 1) {
    this.dialog.open(EducationFormProfileComponent, {
      width: '30%',
      disableClose: true,
      data: { addOrEdit: callType1, educationId: id, start_date: start_date },
    });
  }

  viewVolunteerExperianceFormProfileEdit(callType2, id, start_date, cdk = 1) {
    this.dialog.open(VolunteerExperianceFormProfileComponent, {
      disableClose: true,
      width: '30%',
      data: { addOrEdit: callType2, volunteerId: id, start_date: start_date },
    });
  }
  viewProfile(member_id, first_name, last_name, picture_path) {
    let dialogRef = this.dialog.open(ViewProfileComponent, {
      width: '80%',
      disableClose: true,
      data: {
        member: member_id,
        first_name: first_name,
        last_name: last_name,
        picture_path: picture_path,
        click_from: 'popup',
      },
    });
  }

  callSearchCityFunction(params = 0) {
    if (params === 1) {
      this.page = 1;
      this.searchForm.patchValue({
        page: 1,
      });
      this.cityList = [];
      this.cityBox = false;
    }
    this.subbscriptionCity = this.cityStateCountry
      .getCity(this.searchForm.value)
      .subscribe((response) => {
        this.cityList = [
          ...new Map(
            this.cityList
              .concat(response.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.cityCount = response.count;
        if (this.cityCount > 0) {
          this.cityBox = true;
        } else {
          this.cityBox = false;
          this.profileForm.patchValue({
            state: '',
            countryId: this.country_id,
            cityId: '',
          });
        }
        this.countryBox1 = false;
        this.cdRef.detectChanges();
      });
  }

  pushValue(ev?) {
    if (this.cityCount !== this.cityList.length) {
      this.page = this.page + 1;
      this.searchForm.patchValue({
        page: this.page,
      });
      this.callSearchCityFunction();
    }
  }
  ngOnDestroy(): void {
    if (this.subbscriptionCity) {
      this.subbscriptionCity.unsubscribe();
    }
    if (this.tagListSubscription) {
      this.tagListSubscription.unsubscribe();
    }
    if (this.whatMatterSubscription) {
      this.whatMatterSubscription.unsubscribe();
    }
    if (this.removePictureSubscription) {
      this.removePictureSubscription.unsubscribe();
    }
    if (this.volunteerExperienceSubscription) {
      this.volunteerExperienceSubscription.unsubscribe();
    }
    if (this.educationFormSubscription) {
      this.educationFormSubscription.unsubscribe();
    }
    if (this.workExperienceSubscription) {
      this.workExperienceSubscription.unsubscribe();
    }
    if (this.endorseStatusSubscription) {
      this.endorseStatusSubscription.unsubscribe();
    }
    if (this.endorseRequestSubscription) {
      this.endorseRequestSubscription.unsubscribe();
    }
    if (this.endorseSubscription) {
      this.endorseSubscription.unsubscribe();
    }
    if (this.requestHandleSubscription) {
      this.requestHandleSubscription.unsubscribe();
    }
    if (this.sentRequestSubscription) {
      this.sentRequestSubscription.unsubscribe();
    }
    if (this.saveVideoSubscription) {
      this.saveVideoSubscription.unsubscribe();
    }
    if (this.treasureSubscription) {
      this.treasureSubscription.unsubscribe();
    }
    if (this.momentSubscription) {
      this.momentSubscription.unsubscribe();
    }
    if (this.careWhatSubscription) {
      this.careWhatSubscription.unsubscribe();
    }
    if (this.guideDescriptionSubscription) {
      this.guideDescriptionSubscription.unsubscribe();
    }
    if (this.expertGuideSubscription) {
      this.expertGuideSubscription.unsubscribe();
    }
    if (this.countryEditSubscription) {
      this.countryEditSubscription.unsubscribe();
    }
    if (this.profileEditSubscription) {
      this.profileEditSubscription.unsubscribe();
    }
    if (this.saveProfileSubscription) {
      this.saveProfileSubscription.unsubscribe();
    }
    if (this.workStorySubscription) {
      this.workStorySubscription.unsubscribe();
    }
    if (this.guideHighlightSubscription) {
      this.guideHighlightSubscription.unsubscribe();
    }
    if (this.mentorExperienceSubscription) {
      this.mentorExperienceSubscription.unsubscribe();
    }
    if (this.whatCareSubscription) {
      this.whatCareSubscription.unsubscribe();
    }
    if (this.additionalSubscription) {
      this.additionalSubscription.unsubscribe();
    }

    if (this.volunteerSubscription) {
      this.volunteerSubscription.unsubscribe();
    }
    if (this.educationSubscription) {
      this.educationSubscription.unsubscribe();
    }
    if (this.experienceSubscription) {
      this.experienceSubscription.unsubscribe();
    }
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    if (this.uploadPhotoSubscription) {
      this.uploadPhotoSubscription.unsubscribe();
    }
  }
}
