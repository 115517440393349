import { tap, map } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MeaningFeedsService {
  apiUrl: string;
  public meaningFeedAllData = new BehaviorSubject<any[]>([]);
  private _refreshedMeaningFeedList = new Subject<MeaningFeedData>();
  get refreshedMeaningFeedList() {
    return this._refreshedMeaningFeedList;
  }
  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  createMeaningFeed(ngForm): Observable<MeaningFeedData> {
    return this.http
      .post<MeaningFeedData>(
        this.apiUrl + `collabcontent/collabcontentbucket/create-meaning-feed`,
        ngForm
      )
      .pipe(
        tap((data) => {
          this._refreshedMeaningFeedList.next(data);
        })
      );
  }
  meaningFeedList(loggedInUser, page): Observable<MeaningFeedData> {
    return this.http.get<MeaningFeedData>(
      this.apiUrl +
        `collabcontent/collabcontentbucket/meaning-feed-list/${loggedInUser}?page=${page}`
    );
  }

  meaningFeedListFilter(loggedInUser, searchForm): Observable<MeaningFeedData> {
    return this.http.post<MeaningFeedData>(
      this.apiUrl +
        `collabcontent/collabcontentbucket/meaning-feed-list/${loggedInUser}`,
      searchForm
    );
  }

  linkPreview(link): Observable<MeaningFeedData> {
    return this.http.get<MeaningFeedData>(
      this.apiUrl +
        `collabcontent/collabcontentbucket/link-preview?link=${link}`
    );
  }
}

export interface MeaningFeedData {
  collabcontentcategory: number;
  collabcontenttype: number;
  collabcontentstatus: number;
  creation_date: any;
  title: string;
  description: string;
  attachment_path: string;
  creating_user: string;
  suggesting_user: string;
  message: string;
  status: number;
  results: [];
  count: number;
  flaggedCount: number;
  creatingUser: {};
  iaminspired_count: number;
  ilearned_count: number;
  iresonate_count: number;
  cheer_count: number;
  link_json: {};
  image: string;
  post_flagged_user_wise:string;
}
