<kt-spinner></kt-spinner>
<mat-card class="full_screen_background_card_height">
  <div class="d-flex justify-content-between alig-items-center">
    <table width="100%">
      <tr>
        <td align="left">
          <h1 class="m-0">GUIDE REPORTS</h1>
        </td>
        <td align="right">
          <a
            class="btn begin_quiz cu_community_date"
            [routerLink]="'/discover/my-guided-explorers'"
          >
            My Guided Explorers
          </a>
        </td>
      </tr>
    </table>
  </div>
  <hr />
  <mat-card-content class="card2-style-height-with-hr-heading">
    <!-- <ng-container *ngIf="count > 0"> -->
    <div class="community-box">
      <div class="d-flex justify-content-between">
        <div
          class="w-100 d-flex justify-content-between align-items-center h40vh"
        >
          <canvas id="barchart" height="50"></canvas>
        </div>
        <hr />
      </div>
      <p class="text-align-right">
        *This chart represents both Completed and Closed discussion
      </p>
      <hr class="hr-5" />
      <div class="d-flex justify-content-between mt-20">
        <h2>Discussion Details</h2>
        <div>
          <a class="btn begin_quiz " (click)="downloadReportCSV()">Download</a>&nbsp;
          <i
            class="fa fa-filter posi-dots fa-one"
            aria-hidden="true"
            title="Filter"
            [matMenuTriggerFor]="menu_search_form"
          ></i>
          <mat-menu
            #menu_search_form="matMenu"
            class="search-menunw guide-reports"
          >
            <form
              [formGroup]="guidesearchForm"
              (keydown.tab)="$event.stopPropagation()"
              (ngSubmit)="GuideSearchFilterForm(1)"
            >
              <div class="mat-menu-search" (click)="$event.stopPropagation()">
                <!-- Explorer -->
                <div class="row">
                  <div class="col-sm-6 pt-5">
                    <div class="memg-btn-go">
                      <mat-checkbox
                        class="mem-radio-btn"
                        (click)="$event.stopPropagation()"
                        formControlName="include_checked"
                        >Explorer
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <select
                      class="memb-select"
                      (click)="$event.stopPropagation()"
                      formControlName="include_option"
                      [attr.disabled]="
                        !guidesearchForm.get('include_checked').value
                          ? disabled
                          : null
                      "
                    >
                      <option
                        disabled
                        selected
                        value
                        style="display: none"
                      ></option>
                      <option
                        [value]="explorer?.id"
                        *ngFor="let explorer of allExplorers"
                      >
                        {{ explorer?.full_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- Status -->
                <div class="row">
                  <div class="col-sm-6">
                    <div class="memg-btn-go">
                      <mat-checkbox
                        class="mem-radio-btn"
                        (click)="$event.stopPropagation()"
                        formControlName="status_checked"
                        >Discussion Status
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <select
                      class="memb-select"
                      (click)="$event.stopPropagation()"
                      formControlName="status_option"
                      [attr.disabled]="
                        !guidesearchForm.get('status_checked').value
                          ? disabled
                          : null
                      "
                    >
                      <option
                        disabled
                        selected
                        value
                        style="display: none"
                      ></option>
                      <option value="0">Requested</option>
                      <!-- <option value="1">Published</option> -->
                      <option value="2">Canceled</option>
                      <option value="3">Scheduled</option>
                      <option value="4">Auto Canceled</option>
                      <option value="5">Completed</option>
                      <option value="6">Closed</option>
                      <option value="7">Auto Completed</option>
                      <option value="8">Withdrawn</option>
                      <option value="9">Declined</option>
                      <option value="10">Requested for cancelation</option>
                      <option value="11">Canceled due to request</option>
                      <option value="12">Auto Withdraw</option>
                    </select>
                  </div>
                </div>
                <!-- Discussion Type -->
                <div class="row">
                  <div class="col-sm-6">
                    <div class="memg-btn-go">
                      <mat-checkbox
                        class="mem-radio-btn"
                        (click)="$event.stopPropagation()"
                        formControlName="type_checked"
                        >Discussion Type
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <select
                      class="memb-select"
                      (click)="$event.stopPropagation()"
                      formControlName="discussion_type"
                      [attr.disabled]="
                        !guidesearchForm.get('type_checked').value
                          ? disabled
                          : null
                      "
                    >
                      <option
                        disabled
                        selected
                        value
                        style="display: none"
                      ></option>
                      <option value="2733">Complimentary</option>
                      <option value="2548">Get Acquainted</option>
                      <!-- <option value="2555">
                          Mirror Reflection Discussion
                        </option> -->
                      <option value="2550">General</option>
                      <option value="2554">Meaningful Work Survey</option>
                      <option value="2556">Mirror Reflection</option>
                    </select>
                  </div>
                </div>
                <!-- From to -->
                <div class="memg-btn-go mbn075">
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    formControlName="within_date_range"
                  >
                    Discussion held between
                  </mat-checkbox>
                </div>

                <div>
                  <mat-form-field
                    style="width: 50%; color: white"
                    class="new-div-inpt"
                  >
                    <input
                      matInput
                      [matDatepicker]="start_date"
                      formControlName="date_from"
                      placeholder="From"
                      (dateChange)="onChangeFrom($event)"
                      (click)="$event.stopPropagation()"
                      readonly
                      [disabled]="
                        !guidesearchForm.get('within_date_range').value
                      "
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="start_date"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #start_date></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field
                    style="width: 50%; color: white"
                    class="new-div-inpt-rht"
                  >
                    <input
                      matInput
                      [min]="minDate"
                      [matDatepicker]="end_date"
                      formControlName="date_to"
                      placeholder="To"
                      (dateChange)="onChangeTo($event)"
                      (click)="$event.stopPropagation()"
                      readonly
                      [disabled]="
                        !guidesearchForm.get('within_date_range').value
                      "
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="end_date"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #end_date></mat-datepicker>
                  </mat-form-field>
                </div>

                <div align="right" class="memg-btn-go">
                  <table>
                    <tr style="margin-top: 5px; display: block">
                      <td>
                        <img
                          src="/assets/media/icon/Refresh@2xWhite.png"
                          width="25px"
                          height="25px"
                          style="cursor: pointer"
                          (click)="$event.stopPropagation(); refreshFullList()"
                        />
                      </td>
                      <td>
                        <button class="btn begin_quiz">GO</button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </form>
          </mat-menu>
        </div>
      </div>

      <!-- This represents the discussion details -->
      <div class="d-flex justify-content-between mt-5">
        <div *ngIf="!include_checked"><strong>With Explorer:</strong> All</div>
        <div *ngIf="include_checked">
          <strong>With Explorer:&nbsp;&nbsp;</strong
          ><span class="innerData"
            >{{ filter_name }}
          </span>
        </div>
        <div *ngIf="!status_checked"><strong>Status: </strong> All</div>
        <div *ngIf="status_checked">
          <strong>Status: </strong>{{ filter_status }}
        </div>

        <div *ngIf="!type_checked"><strong>Discussion Type: </strong>All</div>
        <div *ngIf="type_checked">
          <strong>Discussion Type: </strong>{{ filter_type }}
        </div>

        <div *ngIf="!within_date_range">
          <strong>Discussion Held Between: </strong> All
        </div>
        <div *ngIf="within_date_range">
          <strong>Discussion Held Between: </strong
          >{{ filter_from | date: "MMM d, y" }} -
          {{ filter_to | date: "MMM d, y" }}
        </div>
      </div>

      <!-- <cdk-virtual-scroll-viewport class="scroll_class_list scroll-gap"> -->
      <table class="table headfixed">
        <thead>
          <tr>
            <td>Date</td>
            <td class="w-20">Explorer</td>
            <td class="w-20">Discussion Type</td>
            <td>Scheduled Duration</td>
            <td>Actual Duration</td>
            <td>Status</td>
          </tr>
        </thead>
      </table>
      <!-- <cdk-virtual-scroll-viewport class="scroll_class_list scroll-gap"> -->
        <div
    class="search-results scroll_class_list scroll-gap"
    infinite-scroll
    [infiniteScrollDistance]="modalScrollDistance"
    [infiniteScrollThrottle]="modalScrollThrottle"
    [scrollWindow]="false"
    (scrolled)="pushValue($event)"
  >
        <table class="table">
          <thead></thead>
          <tbody>
            <ng-container *ngIf="count > 0">
              <tr
                width="100%"
                *ngFor="
                  let explorer of guidesExpdata;"
              >
                <td>{{ explorer?.start_date | date: "MMM d, y" }}</td>
                <td class="w-20-1">
                  <span class="innerData">
                    {{ explorer?.explorer_name }}
                  </span>
                </td>
                <td class="w-20-1">{{ eventType(explorer?.event_type) }}</td>
                <td>{{ explorer?.time_duration }} min</td>
                <td>{{ explorer?.actual_duration }} min</td>
                <td>
                  <!-- 5= Complete, 6 = Marked Complete, 7= Auto complete, -->
                  <!-- {{guidedata.event_details[0].active}} -->
                  <span *ngIf="explorer.active == 0">Requested</span>
                  <!-- <span *ngIf="explorer.active == 1">Published</span> -->
                  <span *ngIf="explorer.active == 2">Canceled</span>
                  <span *ngIf="explorer.active == 3">Scheduled</span>
                  <span *ngIf="explorer.active == 4">Auto Canceled</span>
                  <span *ngIf="explorer.active == 5">Complete</span>
                  <span *ngIf="explorer.active == 6">Closed</span>
                  <span *ngIf="explorer.active == 7">Auto completed</span>
                  <span *ngIf="explorer.active == 8">Withdrawn</span>
                  <span *ngIf="explorer.active == 9">Declined </span>
                  <span *ngIf="explorer.active == 10"
                    >Requested for cancelation</span
                  >
                  <span *ngIf="explorer.active == 11"
                    >Canceled due to request</span
                  >
                  <span *ngIf="explorer.active == 12">Auto Withdraw</span>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="count == 0">
              <td colspan="7">
                <p>No Data Found!</p>
              </td>
            </tr>
          </tbody>
        </table>
      <!-- </cdk-virtual-scroll-viewport> -->
    </div>
    </div>
    <p class="text-align-right">
      Report Generated on: {{ maxDate | date: "MMM d, y, h:mm a" }}
    </p>
  </mat-card-content>
</mat-card>
