import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'kt-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.scss'],
})
export class AcceptTermsComponent implements OnInit {
  acceptTermsForm: UntypedFormGroup;
  curScrollPos = 0;
  endReached = false;
  checkBoxChecked = false;
  returnUrl: any;
  loginText: string = '';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private _router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    if (
      this._router.getCurrentNavigation() != null &&
      this._router.getCurrentNavigation().extras.state
    ) {
      this.loginText = this._router.getCurrentNavigation().extras.state.text;
    }
    this.acceptTermsForm = this.formBuilder.group({
      acceptOrReject: new UntypedFormControl('', [Validators.required]),
    });
    if (atob(localStorage.getItem('flag_country')) === '0') {
      this._router.navigate(['/not-eligible-country']);
    }
  }
  changeCheckBox(ev) {
    if (ev.checked) {
      this.checkBoxChecked = true;
    } else {
      this.checkBoxChecked = false;
    }
  }
  updateScrollDown(e) {
    this.endReached = true;
  }
  updateScrollUp(e) {
    this.endReached = false;
  }
  goTo(ngForm: NgForm) {
    if (ngForm['acceptOrReject'] === 1) {
      localStorage.setItem('acceptTerms', '1');
      if (this.returnUrl) {
        this._router.navigate(['/login/register'], {
          state: { text: this.loginText },
          queryParams: { returnUrl: this.returnUrl },
        });
      } else {
        this._router.navigate(['/login/register']);
      }
    }
  }
  goBack() {
    this._router.navigate(['/home']);
  }
  checkUncheck(event) {
    if (!event.checked) {
      this.acceptTermsForm.patchValue({
        acceptOrReject: ''
      });
    }
  }
}
