import { MatDialog } from '@angular/material/dialog';
import { ViewProfileComponent } from './../../../admin-page/view-profile/view-profile.component';
import { MentorService } from '../../../../../service/mentor.service';
import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../../../../../service/common.service';
import { NotificationService } from '../../../../../service/notification.service';
import { AccountsService } from './../../../../../service/accounts.service';

@Component({
  selector: 'kt-past-explorer',
  templateUrl: './past-explorer.component.html',
  styleUrls: ['./past-explorer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PastExplorerComponent implements OnInit, OnDestroy {
  loggedinUserid: number;
  pastMenteeData = [];
  past_count: number;
  pastExplorerForm: UntypedFormGroup;
  throttle = 300;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;
  pastMenteeSubscription: Subscription;
  page: number = 2;

  constructor(
    private route: Router,
    private _cdr: ChangeDetectorRef,
    private mentorService: MentorService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: NotificationService,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedinUserid = this.accountService.muser;
    this.pastExplorerForm = this.formBuilder.group({
      member: new UntypedFormControl(this.loggedinUserid),
      status: new UntypedFormControl(0),
      page: 1,
    });
    this.getAllPastMentee();
  }

  returnImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default_rectangle.png';
    }
  }

  showSharedSpace(
    menteeId,
    full_name,
    dp,
    status,
    start_date,
    city,
    country,
    requested_from,
    connection_status,
    requested_to,
    connection_count
  ) {
    this.route.navigate(['/my-mentor/my-guided-explorers/shared-space'], {
      queryParams: {
        menteeId: window.btoa(menteeId),
        fullName: window.btoa(full_name),
        menteeDp: window.btoa(dp),
        status: window.btoa(status),
        start_date: window.btoa(start_date),
        city: window.btoa(city),
        country: window.btoa(country),
        requested_from: requested_from,
        connection_status: connection_status,
        requested_to: requested_to,
        connection_count: connection_count,
        logged_in_token: this.accountService.token,
      },
    });
  }
  viewProfile(member_id, connection_data, member_data) {
    let dialogRef = this.dialog.open(ViewProfileComponent, {
      width: '80%',
      disableClose: true,
      data: {
        member_id: member_id,
        connection_data: connection_data,
        member_data: member_data,
        click_from: 'guide_search',
      },
    });
  }
  pushValue(ev?) {
    if (this.past_count !== this.pastMenteeData.length) {
      this.pastExplorerForm.patchValue({
        page: this.page,
      });
      this.getAllPastMentee();
      this.page++;
    }
  }
  getAllPastMentee(params = 0) {
    this.spinner.show();
    if (params === 1) {
      this.pastMenteeData = [];
      this.page = 2;
      this.pastExplorerForm.patchValue({
        page: 1,
      });
    }
    const Formdata = this.pastExplorerForm.value;
    this.pastMenteeSubscription = this.mentorService
      .getMenteeDataByPost(Formdata)
      .subscribe((data) => {
        this.pastMenteeData = [
          ...new Map(
            this.pastMenteeData
              .concat(data.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.past_count = data.count;
        this.spinner.hide();
        this._cdr.detectChanges();
      });
  }

  viewConnectProfile(
    member_id,
    first_name,
    last_name,
    picture_path,
    member_status
  ) {
    if (CommonService.checkMemberStatus(member_status)) {
      this.toastr.showInfo('This Guided Explorer is no longer active.', '');
    } else {
      let dialogRef = this.dialog.open(ViewProfileComponent, {
        width: '80%',
        disableClose: true,
        data: {
          member: member_id,
          first_name: first_name,
          last_name: last_name,
          picture_path: picture_path,
          click_from: 'popup',
        },
      });
    }
  }
  ngOnDestroy() {
    if (this.pastMenteeSubscription) {
      this.pastMenteeSubscription.unsubscribe();
    }
  }
}
