import { CollabContentDetailsPopupComponent } from './../../meaning-constellation/collab-content-details-popup/collab-content-details-popup.component';
import { MeaningCentralEdit } from '../meaning-feeds/meaning-central-edit/meaning-central-edit';
import { NotificationService } from '../../../../service/notification.service';
import { MeaningConstellationService } from '../../../../service/meaning-constellation.service';
import { CuratorPageService } from '../../../../service/curator-page.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import {
  NgForm,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { message_properties } from '../../../../../environments/message_properties';
import { AccountsService } from './../../../../service/accounts.service';
import {
  Component,
  OnInit,
  Optional,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-meaning-log-and-treasure-popup',
  templateUrl: './meaning-log-and-treasure-popup.component.html',
  styleUrls: ['./meaning-log-and-treasure-popup.component.scss'],
})
export class MeaningLogAndTreasurePopupComponent implements OnInit, OnDestroy {
  commentsForm: UntypedFormGroup;
  collabContentId: number;
  bucket_type: number;
  attachment_path: string = '';
  ufullname: string;
  user_profile_path: string;
  description: string;
  link_url: any;
  commentsArr: [];
  comments_count: number = 0;
  loggedInUserId: number;
  iaminspired_count: number;
  ilearned_count: number;
  iresonate_count: number;
  cheer_count: number;
  title: string;
  recommended_indicator: boolean = false;
  ms_recommends: string;
  video_path: string;
  referenceUrl: string = '';
  averageStars: any[] = [];

  rate: number;
  over: number;
  leave: number;
  video_indicator: number;
  collab_content_category: number;
  avg_rating: number;
  my_rating: number;
  my_cheer: number;
  my_iaminspired: number;
  my_ilearned: number;
  my_iresonate: number;
  clickFrom: number;
  creatingUserFromDatabase: number;
  link_preview_data: [];
  link_title: string;
  link_description: string;
  link_absolute_image: string;
  link_image: string;
  link_force_title: string;
  popup_call_from: string;
  member_in_bucket: number;
  additional_info: string = '';
  references_id: number = 0;
  wallFameSubscription: Subscription;
  treasureSubscription: Subscription;
  saveCommentSubscription: Subscription;
  deleteSubscription: Subscription;
  bucketDeleteSubscription: Subscription;
  bookmarkSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<MeaningLogAndTreasurePopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private curatorService: CuratorPageService,
    private _cdr: ChangeDetectorRef,
    private meaningConstalletion: MeaningConstellationService,
    private toastr: NotificationService,
    private dialog: MatDialog,
    public sanitizer: DomSanitizer,
    private accountService: AccountsService
  ) {
    this.title = data.title;
    this.description = data.description;
    this.attachment_path = data.attachment_path
      ? data.attachment_path
      : './assets/media/default/post_default.png';
    this.video_indicator = data.video_indicator;
    this.collabContentId = data.collabContentId;
    this.bucket_type = data.bucket_type;
    this.clickFrom = data.clickFrom;
    this.referenceUrl = data.references ? data.references : '';
    this.collab_content_category = data.collab_content_category;
    this.popup_call_from = data.popup_call_from || '0';
    this.member_in_bucket = Number(data.member_in_bucket) || 0;
    this.loggedInUserId = this.accountService.muser;
    this.commentsForm = new UntypedFormGroup({
      remarks: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(1024),
      ]),
      rating: new UntypedFormControl(''),
      collab_content: new UntypedFormControl(this.collabContentId),
      user: new UntypedFormControl(this.loggedInUserId),
    });
  }

  ngOnInit(): void {
    this.meaningConstalletion.refreshneededMeaningCentralList.subscribe(
      (data) => {
        this.viewWallOfFameComponent(this.collabContentId);
      }
    );
    this.curatorService.refreshneeded.subscribe((data) => {
      this.viewWallOfFameComponent(this.collabContentId);
    });
    this.viewWallOfFameComponent(this.collabContentId);
  }

  viewWallOfFameComponent(id) {
    let data = [];
    this.wallFameSubscription = this.curatorService
      .getWallOfFameById(id, this.loggedInUserId)
      .subscribe((result) => {
        data = JSON.parse(JSON.stringify(result.results));
        this.video_indicator = data['video_indicator'];
        this.attachment_path = data['attachment_path']
          ? data['attachment_path']
          : './assets/media/default/post_default.png';
        this.description = data['description'];
        this.title = data['title'];
        this.avg_rating = data['avg_rating'];
        this.my_rating =
          data['my_rating'].length > 0 ? data['my_rating'][0].rating : 0;
        this.cheer_count = data['cheer_count'];
        this.iresonate_count = data['iresonate_count'];
        this.ilearned_count = data['ilearned_count'];
        this.iaminspired_count = data['iaminspired_count'];

        this.my_cheer = data['my_cheer'];
        this.my_iaminspired = data['my_iaminspired'];
        this.my_ilearned = data['my_ilearned'];
        this.my_iresonate = data['my_iresonate'];
        this.recommended_indicator = data['recommended_indicator'];
        this.ms_recommends = data['ms_recommends'];
        this.collab_content_category = data['collab_content_category'];
        this.commentsArr = data['comments'];
        this.comments_count = data['comments_count'];
        this.referenceUrl = data['references'] ? data['references'] : '';
        this.references_id = data['references_id']
          ? Number(data['references_id'])
          : 0;
        this.additional_info = data['additional_info']
          ? data['additional_info']
          : null;
        this._cdr.detectChanges();
      });
  }

  redirectUrl() {
    return window.open(
      this.link_url,
      'popUpWindow',
      'height=900,width=1600, left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
    );
  }

  commentsSave(ngForm: NgForm) {
    this.saveCommentSubscription = this.meaningConstalletion
      .createComments(ngForm)
      .subscribe((data) => {
        this.commentsForm.patchValue({
          remarks: '',
        });
        this._cdr.detectChanges();
      });
  }

  updateCollabContent(
    title,
    description,
    attachment_path,
    referenceUrl,
    collab_content_category
  ) {
    this.dialog.open(MeaningCentralEdit, {
      width: '60%',
      disableClose: true,
      data: {
        collabContantId: this.collabContentId,
        title: title,
        description: description,
        attachment_path: attachment_path,
        referenceUrl: referenceUrl,
        collab_content_category: collab_content_category,
      },
    });
  }

  deleteCollabContent(id) {
    alert(id);
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to DELETE this content.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        if (this.clickFrom !== 2552 || 2734) {
          this.deleteSubscription = this.meaningConstalletion
            .deleteMeaningMomentsDetailsWithRelative(id)
            .subscribe((data) => {
              this.toastr.showSuccess(
                message_properties.MEANING_LOG_AND_TRESSURER_POPUP_COLLAB_CONTENT_DELETE_SUCCESS,
                ''
              );
              this.dialog.closeAll();
            });
          this._cdr.detectChanges();
        }
      }
    });
  }

  basicPopup(url) {
    return window.open(
      url,
      'popUpWindow',
      'height=900,width=1600,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
    );
  }

  deleteCollabContentBucket(collabContentId, bucket_type) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to DELETE this  item.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.bucketDeleteSubscription = this.meaningConstalletion
          .removeFromBucketTableOnly(
            collabContentId,
            bucket_type,
            this.loggedInUserId
          )
          .subscribe((data) => {
            if (bucket_type === 2734) {
              this.toastr.showSuccess(message_properties.BOOKMARKS_SUCCESS, '');
            } else if (bucket_type === 2552) {
              this.toastr.showSuccess(message_properties.TREASURER_SUCCESS, '');
            } else if (bucket_type === 2564) {
              this.toastr.showSuccess(
                message_properties.MEANING_MOMENT_SUCCESS,
                ''
              );
            }
            this.dialog.closeAll();
          });
        this._cdr.detectChanges();
      }
    });
  }
  deleteBookmark(collabContentId, bucket_type) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to DELETE this item.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.bookmarkSubscription = this.meaningConstalletion
          .removeFromBookmark(collabContentId, bucket_type, this.loggedInUserId)
          .subscribe((data) => {
            this.toastr.showSuccess(message_properties.BOOKMARKS_SUCCESS, '');
            this.dialog.closeAll();
          });
        this._cdr.detectChanges();
      }
    });
  }

  deleteTreasure(collabContentId, bucket_type) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to DELETE this  item.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.treasureSubscription = this.meaningConstalletion
          .removeFromTreasure(collabContentId, bucket_type, this.loggedInUserId)
          .subscribe((data) => {
            if (bucket_type === 2734) {
              this.toastr.showSuccess(message_properties.BOOKMARKS_SUCCESS, '');
            } else if (bucket_type === 2552) {
              this.toastr.showSuccess(message_properties.TREASURER_SUCCESS, '');
            } else if (bucket_type === 2564) {
              this.toastr.showSuccess(
                message_properties.MEANING_MOMENT_SUCCESS,
                ''
              );
            }
            this.dialog.closeAll();
          });
        this._cdr.detectChanges();
      }
    });
  }
  ngOnDestroy(): void {
    if (this.treasureSubscription) {
      this.treasureSubscription.unsubscribe();
    }
    if (this.wallFameSubscription) {
      this.wallFameSubscription.unsubscribe();
    }
    if (this.saveCommentSubscription) {
      this.saveCommentSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
    if (this.bucketDeleteSubscription) {
      this.bucketDeleteSubscription.unsubscribe();
    }
    if (this.bookmarkSubscription) {
      this.bookmarkSubscription.unsubscribe();
    }
  }
}
