<ngx-spinner
  size="small"
  class="preload-btn"
  color="#ffffff"
  bdColor="transparent"
  [fullScreen]="false"
  template="<img src='/assets/media/loader.gif'/>"
>
</ngx-spinner>

<div class="mat-dialog-popup">
  <table class="w-100">
    <tr>
      <td>
        <h1 class="popup_card_title">{{ title }}</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form
    [formGroup]="insiteForm"
    (ngSubmit)="createInsights(insiteForm.value, symmary_type)"
    novalidate
    enctype="multipart/form-data"
    autocomplete="off"
  >
    <div class="">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field class="w-100">
                <mat-label>Description</mat-label>
                <textarea
                  maxlength="2048"
                  matInput
                  autocomplete="off"
                  formControlName="remarks"
                  oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&!@#$%=*-+?() ]|^ /g,'')"
                  rows="7"
                >
                </textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
    <div class="row">
      <div class="col-sm-12 tr">
        <button
          [disabled]="!insiteForm.valid || saveButton"
          class="btn begin_quiz ml-2"
          type="submit"
        >
          Save
        </button>

        <button
          mat-dialog-close
          class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
