<table width="100%">
  <tr>
    <td>
      <h1>View Discussion Details</h1>
    </td>
    <td>
      <h1 mat-dialog-title>
        <button mat-button mat-dialog-close class="modal_close_button_top">
          X
        </button>
      </h1>
    </td>
  </tr>
</table>
<hr />
<form  class="dialogF viewP"
  [formGroup]="dialogForm"
  novalidate
  enctype="multipart/form-data"
  autocomplete="off"
>
  <div class="dialogForm">
    <div class="row">
      <div class="col-sm-12">
        <p>
          Discussion with:
          <img class="img-fluid curator-createimg" [src]="menteeDp" />
          <label style="cursor: pointer">&nbsp;&nbsp;{{ mentee }}</label>
        </p>
      </div>
    </div>
<div class="dialogFormScroll">
    <ng-container
      *ngIf="clicked_from == 'view_only' && created_by_guide == false"
    >
      <div class="row">
        <div class="col-md-12">
          <label>Type of Discussion *</label>
          <select
            matInput
            (change)="durationFilling($event.target.value)"
            formControlName="catalog_item"
            class="general_select guid-select"
          >
            <option disabled selected value style="display:none"></option>
            <option
              *ngFor="let guideData of GuideServiceList"
              value="{{ guideData.id }}"
            >
              {{ guideData.offering_name }}
            </option>
          </select>
        </div>
      </div>
      <!-- <br/> -->
      <div class="row" *ngIf="purpus_text_box">
        <div class="col-sm-12">
          <mat-form-field style="width: 100%">
            <mat-label>Purpose </mat-label>
            <textarea
              readonly="true"
              matInput
              autocomplete="off"
              maxlength="1024"
              oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&!@#$%=*-+?() ]|^ /g,'')"
              formControlName="description"
              rows="4"
            >
            </textarea>
          </mat-form-field>
        </div>
      </div>
      <!-- <br/> -->
      <div class="row">
        <div class="col-md-12">
          <label>Relevant Shared Space Document </label>
          <select
            matInput
            formControlName="shared_document_id"
            class="general_select guid-select"
          >
            <ng-container *ngIf="count == 0">
              <option>No Documents found!</option>
            </ng-container>
            <ng-container *ngIf="event_type == 2556 && count > 0">
              <option value="">Select Document</option>
              <option
                *ngFor="let data of reflectionData"
                value="{{ data.report_id }}"
              >
                {{ data.mirror_title }}&nbsp;&nbsp;{{
                  data.survey_taken_on | date: "MMM d, y"
                }}
              </option>
            </ng-container>
            <ng-container *ngIf="event_type == 2554 && count > 0">
              <option value="">Select Document</option>
              <option *ngFor="let data of momData" value="{{ data.report_id }}">
                Meaningful Work Survey
                {{ data.survey_taken_on | date: "MMM d, y" }}
              </option>
            </ng-container>
            <ng-container *ngIf="event_type == 2550 && count > 0">
              <option value="">Select Document</option>
              <option *ngFor="let data of diyData" value="{{ data.diy_id }}">
                DIY Activity {{ data.shared_on | date: "MMM d, y" }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
      <!-- <br /> -->
      <div class="row">
        <div class="col-sm-12">
          Request a Date & Time
          <span
              tooltipClass="tooltip_design"
              theme="light"
              tooltip="If your Guide cannot make the requested time, they will message you to work out a different time for your discussion."
              placement="right"
              showDelay="500"
            >
              <span class="icon-info">
                <mat-icon class="info_material_icon">info</mat-icon>
              </span>
            </span>
        </div>
      
      <!-- <br /> -->
        <div class="col-md-12 col-lg-4">
          <mat-form-field style="width: 100%">
            <mat-label>Requested Date </mat-label>
            <input
              matInput
              [matDatepicker]="start_date"
              formControlName="start_date"
              [min]="myDate"
            />
            <mat-datepicker-toggle matSuffix [for]="start_date">
            </mat-datepicker-toggle>
            <mat-datepicker #start_date></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-12 col-lg-4">
          <mat-form-field style="width: 100%">
            <mat-label>Requested Time </mat-label>
            <input
              matInput
              readonly
              [ngxTimepicker]="toggleTimepicker"
              formControlName="timeFrom"
            />
            <ngx-material-timepicker
              (timeSet)="onChangeFrom($event)"
              #toggleTimepicker
            >
            </ngx-material-timepicker>
          </mat-form-field>
        </div>

        <div class="col-md-12 col-lg-4">
          <mat-form-field style="width: 100%">
            <mat-label>Duration (In Minutes)</mat-label>
            <input
              matInput
              formControlName="duration"
              (change)="onChangeFromTime($event)"
              value="0"
              maxlength="3"
              type="text"
              min="0"
              max="240"
              oninput="this.value = this.value.replace(/[^0-9-]|-^ /g,'')"
            />
          </mat-form-field>
        </div>
      </div>
      <!-- <br /> -->
    </ng-container>

    <ng-container
      *ngIf="clicked_from == 'view_only' && created_by_guide == true"
    >
      <div class="row">
        <div class="col-sm-12">
          <mat-form-field style="width: 100%">
            <mat-label>Purpose </mat-label>
            <textarea
              matInput
              autocomplete="off"
              maxlength="1024"
              oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&!@#$%=*-+?() ]|^ /g,'')"
              formControlName="description"
              rows="4"
            >
            </textarea>
          </mat-form-field>
        </div>
      </div>
      <!-- <br /> -->

      <div class="row">
        <div class="col-md-12 col-lg-4">
          <mat-form-field style="width: 100%">
            <mat-label>Discussion Date </mat-label>
            <input
              matInput
              readonly="true"
              [matDatepicker]="start_date"
              formControlName="start_date"
              [min]="myDate"
            />
            <mat-datepicker-toggle  class="dis-date" matSuffix [for]="start_date">
            </mat-datepicker-toggle>
            <mat-datepicker #start_date></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-12 col-lg-4">
          <mat-form-field style="width: 100%">
            <mat-label>Discussion Time </mat-label>
            <input
              matInput
              readonly="true"
              [ngxTimepicker]="toggleTimepicker"
              formControlName="timeFrom"
            />
            <ngx-material-timepicker
              (timeSet)="onChangeFrom($event)"
              #toggleTimepicker
            >
            </ngx-material-timepicker>
          </mat-form-field>
        </div>

        <div class="col-md-12 col-lg-4">
          <mat-form-field style="width: 100%">
            <mat-label>Duration (In Minutes)</mat-label>
            <input
              matInput
              formControlName="duration"
              (change)="onChangeFromTime($event)"
              value="0"
              maxlength="3"
              type="text"
              min="0"
              max="240"
              oninput="this.value = this.value.replace(/[^0-9-]|-^ /g,'')"
            />
          </mat-form-field>
        </div>
      </div>
      <!-- <br /> -->
    </ng-container></div>
    <hr />
  </div>
  <div class="row">
    <div class="col-sm-12 tr">
      <button
        mat-dialog-close
        class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
      >
        Cancel
      </button>
    </div>
  </div>
</form>

