<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1>{{ header_set_up_discussion }}</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form
    [formGroup]="dialogForm"
    (ngSubmit)="createDialog(dialogForm.value)"
    novalidate
    enctype="multipart/form-data"
    autocomplete="off"
  >
    <div class="">
      <div class="row">
        <div class="col-sm-12">
          <p>
            Your Explorer:
            <img class="img-fluid curator-createimg" [src]="menteeDp" />
            <label style="cursor: pointer">&nbsp;&nbsp;{{ mentee }}</label>
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <mat-form-field style="width: 100%">
          <mat-label>Purpose </mat-label>
          <textarea
            matInput
            
            autocomplete="off"
            maxlength="1024"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&!@#$%=*-+?() ]|^ /g,'')"
            formControlName="description"
            rows="4"
          >
          </textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4">
        <mat-form-field style="width: 100%">
          <mat-label>Discussion Date </mat-label>
          <input
            matInput
            readonly
            [matDatepicker]="start_date"
            formControlName="start_date"
            [min]="current_time_zone_date"
            (dateInput)="onChangedate('input', $event)"
          />
          <mat-datepicker-toggle matSuffix [for]="start_date">
          </mat-datepicker-toggle>
          <mat-datepicker #start_date></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4" *ngIf="!event_bool && !both_date">
        <mat-form-field style="width: 100%">
          <mat-label>Discussion Time</mat-label>
          <input
            matInput
            readonly
            [min]="minTime"
            [ngxTimepicker]="toggleTimepicker"
            formControlName="timeFrom"
            [disableClick]="true"
          />
          <ngx-material-timepicker-toggle class="img-resizer-icon" [for]="toggleTimepicker">
          </ngx-material-timepicker-toggle>
          <ngx-material-timepicker
            #toggleTimepicker
            (timeSet)="onChangeFrom($event)"
          >
          </ngx-material-timepicker>
          <!-- <ngx-material-timepicker
            (timeSet)="onChangeFrom($event)"
            #toggleTimepicker
          >
          </ngx-material-timepicker> -->
        </mat-form-field>
      </div>

      <!-- <div class="col-sm-12 col-md-6 col-lg-4" *ngIf="event_bool && !both_date">
        <mat-form-field style="width: 100%">
          <mat-label>Discussion Time</mat-label>
          <input
            matInput
            readonly
            [ngxTimepicker]="toggleTimepicker"
            formControlName="timeFrom"
          />
          <ngx-material-timepicker
            (timeSet)="onChangeFrom($event)"
            #toggleTimepicker
          >
          </ngx-material-timepicker>
        </mat-form-field>
      </div> -->

      <div class="col-sm-12 col-md-6 col-lg-4 mt34n" *ngIf="event_bool && !both_date && !ifToday">
        <mat-form-field class="w-100">
          <mat-label>Discussion Time </mat-label>
          <input
            matInput
            readonly
            [ngxTimepicker]="toggleTimepicker"
            formControlName="timeFrom"
            [disableClick]="true"
          />
          <ngx-material-timepicker-toggle [for]="toggleTimepicker">
          </ngx-material-timepicker-toggle>
          <ngx-material-timepicker
            #toggleTimepicker
            (timeSet)="onChangeFrom($event)"
          >
          </ngx-material-timepicker>
          <!-- <ngx-material-timepicker
            (timeSet)="onChangeFrom($event)"
            #toggleTimepicker
          >
          </ngx-material-timepicker> -->
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4 mt34n" *ngIf="event_bool && !both_date && ifToday">
        <mat-form-field class="w-100">
          <mat-label>Discussion Time </mat-label>
          <input
            class="time_set"
            *ngIf="rescheduletimeselected"
            matInput
            readonly
            [disableClick]="true"
            formControlName="timeFrom"
          />
          <input
            matInput
            readonly
            [min]="minTime"
            [ngxTimepicker]="toggleTimepicker"
            formControlName="timeFrom"
            [disableClick]="true"
          />
          <ngx-material-timepicker-toggle [for]="toggleTimepicker">
          </ngx-material-timepicker-toggle>
          <ngx-material-timepicker
            #toggleTimepicker
            (timeSet)="onChangeFrom($event)"
          >
          </ngx-material-timepicker>

          <!-- <ngx-material-timepicker
          (opened)="toggleMinTime($event)"
            (timeSet)="onChangeFrom($event)"
            #toggleTimepicker
          >
          </ngx-material-timepicker> -->
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4" *ngIf="both_date">
        <mat-form-field style="width: 100%">
          <mat-label>Discussion Time</mat-label>
          <input
            matInput
            readonly
            [min]="minTime"
            [ngxTimepicker]="toggleTimepicker"
            formControlName="timeFrom"
            [disableClick]="true"
          />
          <ngx-material-timepicker-toggle [for]="toggleTimepicker">
          </ngx-material-timepicker-toggle>
          <ngx-material-timepicker
            #toggleTimepicker
            (timeSet)="onChangeFrom($event)"
          >
          </ngx-material-timepicker>
          <!-- <ngx-material-timepicker
            (timeSet)="onChangeFrom($event)"
            #toggleTimepicker
          >
          </ngx-material-timepicker> -->
        </mat-form-field>
      </div>

      <!---MAHESH H R 01/06/2022-->
      <div class="col-sm-12 col-md-7 col-lg-4">
        <mat-form-field class="w-100">
          <mat-label>Duration (In Minutes)</mat-label>
          <table>
            <tr>
              <td>
                <a
                  (click)="minus_item(15); onChangeFromTime($event)"
                  class="btn begin_quiz text-center"
                  id="minus-btn"
                >
                  <mat-icon>remove</mat-icon>
                </a>
              </td>
              <td>
                <input
                  matInput
                  readonly
                  formControlName="duration"
                  type="text"
                  min="1"
                  class="duration-input"
                  (change)="onChangeFromTime($event)"
                />
              </td>
              <td class="desc_flex">
                <a
                  (click)="plus_item(15); onChangeFromTime($event)"
                  class="btn begin_quiz text-center"
                  id="plus-btn"
                >
                  <mat-icon>add</mat-icon>
                </a>
              </td>
            </tr>
          </table>
        </mat-form-field>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-6"><p>This is a complimentary discussion!</p></div>
      <div class="col-sm-6 tr">
        <button
          *ngIf="clicked_from != 'view_only'"
          [disabled]="!dialogForm.valid || saveButton"
          class="btn begin_quiz ml-2"
          type="submit"
        >
          Save
        </button>

        <button
          mat-dialog-close
          class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
