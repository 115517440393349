import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { tap, map } from 'rxjs/operators';

// import {Overview} from './Overview';
@Injectable({
  providedIn: 'root',
})
export class OverviewService {
  apiUrl = environment.apiUrl;
  login_val: string;
  private _refreshneededSidebar = new Subject<Overview>();

  get refreshneededSidebar() {
    return this._refreshneededSidebar;
  }

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  getoverviewcontent(param): Observable<Overview> {
    return this.http.get<Overview>(
      this.apiUrl + 'common/config-details/' + param
    );
  }

  allMemberListInWhoList(): Observable<MemberDataModal> {
    return this.http.get<MemberDataModal>(
      this.apiUrl + 'member/member-list-by-membertype/'
    );
  }

  getBannerMessage(): Observable<Overview> {
    return this.http.get<Overview>(this.apiUrl + 'common/banner-message/');
  }

  //survey/surveyresponse/check-survey-taken-or-not-taken

  checksurveyTakenOrNotTaken(data): Observable<Overview> {
    return this.http.post<Overview>(
      this.apiUrl + 'survey/surveyresponse/check-survey-taken-or-not-taken',
      data
    );
  }

  uploadFile(ngForm) {
    this.http.post(
      this.apiUrl + 'survey/surveyresponse/check-survey-taken-or-not-taken',
      ngForm
    );
  }

  lastActivityDate(ngForm): Observable<Overview> {
    return this.http
      .put<Overview>(this.apiUrl + 'member/update-last-activity-date', ngForm)
      .pipe(
        tap((data) => {
          this._refreshneededSidebar.next();
        })
      );
  }

  setLoginValue(val) {
    this.login_val = val;
  }

  getLoginVal() {
    return this.login_val;
  }
}

export interface Overview {
  count: number;
  name: string;
  parameter_value: any;
  report_data: any;
  ps_id: number;
  last_activity_date: string;
  locked_count: number;
  mentor_count: number;
  admin_count: number;
  curator_count: number;
  program_director_count: number;
  admin_indicator: string;
  curator_indicator: string;
  program_director_indicator: string;
  mentor_indicator: string;
  banner_message_details: {};
  banner_message_count: number;
  banner_message: string;
  banner_id: string;
}

export interface MemberDataModal {
  count: number;
  status: number;
  results: [];
  visibility_type: number;
  association_type: number;
  association_status: number;
  name: string;
  title: any;
  location: any;
  workprofile: any;
  start_date: any;
  end_date: any;
  company: number;
  first_name: any;
  last_name: any;
  property_value: any;
  user: {};
  city: string;
  state: string;
  country: string;
  fname: string;
  lname: string;
  picture_path: string;
  whatcareabout: string;
  message: string;
  address_response: {};
  relationshion_type: number;
  relating_member_id: number;
  email: string;
  mentor_indicator: string;
  curator_indicator: string;
  admin_indicator: string;
  member_table: any;
  address_details: any;
  joined_date: any;
  member_additional: [];
}
