<mat-card class="archive_main_card bg-color-transp back_card_background_clr">
  <h1>Let’s get started</h1>
  <hr />

  <div class="report_container">
    <div class="mt-1">
      <p>
        The Survey contains 31 multiple-choice statements about where and how
        you find meaning at work.
      </p>
    </div>
    <div class="mt-4">
      <p class="mb-3">
        Choose either your current (or most recent) job or volunteer experience
        as the basis for your Survey responses. If you change your mind about a
        particular response after you've entered it, you can go back and change
        it.
      </p>
      <p class="mb-3">
        After you've completed the Survey (takes less than 10 minutes), you will
        be automatically directed to your Survey Report.
      </p>
      <div class="text-align-right">
        <button
          (click)="giveSurvet()"
          type="button"
          class="btn begin_quiz mb-1"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</mat-card>
