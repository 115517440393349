import { CuratorPageService } from '../../../../service/curator-page.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../../../service/notification.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { message_properties } from '../../../../../environments/message_properties';
import * as moment from 'moment-timezone';
import { AccountsService } from './../../../../service/accounts.service';

@Component({
  selector: 'kt-private-note',
  templateUrl: './private-note.component.html',
  styleUrls: ['./private-note.component.scss'],
})
export class PrivateNoteComponent implements OnInit, OnDestroy {
  privatenoteForm: UntypedFormGroup;
  showAndHide: boolean = false;
  creatingUser: number;
  eventDetailSubscription: Subscription;
  createSubscription: Subscription;
  saveButton: boolean = false;
  myTime;
  minTime;
  toTime: boolean = true;
  sessionType: string;
  menteeId: number;
  mentee_full_name: string;
  explorerDp: string;
  guideFirstName: string;
  guideLastName: string;
  guideDp: string;
  eventId: number;
  start_date: string;
  end_date: string;
  current_timezone: string;
  private_note: string;

  config = {
    placeholder: 'Enter a brief description',
    tabsize: 2,
    height: 150,
    toolbar: [],
    disableDragAndDrop: true,
    disableResizeEditor: true,
    linkTargetBlank: false,
    callbacks: {
      onPaste: function (e) {
        var bufferText = (e.originalEvent || e).clipboardData.getData('Text');
        e.preventDefault();
        document.execCommand('insertText', false, bufferText);
      },
    },
  };

  constructor(
    public dialogRef: MatDialogRef<PrivateNoteComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private toastr: NotificationService,
    private curatorService: CuratorPageService,
    private accountsService: AccountsService
  ) {
    this.eventId = data.eventId;
    this.minTime = this.myTime;
    this.sessionType = data.sessionType;
    this.menteeId = data.menteeId;
    this.mentee_full_name = data.mentee_full_name;
    this.explorerDp =
      data.explorerDp !== 'null'
        ? data.explorerDp
        : './assets/media/users/default.png';
    this.guideFirstName = data.guideFirstName;
    this.guideLastName = data.guideLastName;
    this.guideDp = data.guideDp ?? './assets/media/users/default.png';
    this.start_date = data.start_date;
    this.end_date = data.end_date;
    this.private_note = data.private_note;
  }

  ngOnInit() {
    this.creatingUser = this.accountsService.muser;
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.privatenoteForm = this.formBuilder.group({
      additional_information: new UntypedFormControl(this.private_note, [
        Validators.required,
      ]),
      creating_member: new UntypedFormControl(this.creatingUser),
      explorerId: new UntypedFormControl(this.menteeId),
      guideId: new UntypedFormControl(this.creatingUser),
      eventId: this.eventId,
      summary_type: 2683,
    });
    this.getEventDetails();
  }

  getEventDetails() {
    this.eventDetailSubscription = this.curatorService
      .getEventDetailInDialog(0, this.eventId)
      .subscribe((data) => {
        this.start_date = data.start_date;
        this.end_date = data.end_date;
        this._cdr.detectChanges();
      });
  }

  async createPrivateNote(ngForm: NgForm) {
    this.saveButton = true;
    this.spinner.show();
    const object1 = ngForm;
    const object2 = { type: 'private' };
    const object3 = { ...object1, ...object2 };
    this.createSubscription = this.curatorService
      .createPrivateNote(object3)
      .subscribe(
        (data) => {
          if (data.status === 201) {
            this.saveButton = false;
            this.dialogRef.close();
            this.spinner.hide();
            this.toastr.showSuccess(
              message_properties.PRIVATE_NOTE_SUCCESS,
              ''
            );
          } else if (data.status === 204) {
            this.spinner.hide();
            this.saveButton = false;
            this.toastr.showError(data.message, '');
          } else {
            this.spinner.hide();
            this.saveButton = false;
            this.toastr.showError(message_properties.PRIVATE_NOTE_ERROR, '');
          }
        },
        (error) => {
          this.spinner.hide();
          this.saveButton = false;
          this.toastr.showError(message_properties.PRIVATE_NOTE_ERROR, '');
        }
      );
  }
  changeDesc(event) {
    if (event !== undefined) {
      this.privatenoteForm.patchValue({
        postDetail: event,
      });
    }
  }
  onlyTimeTimeZone(startDateTime) {
    return moment(startDateTime).tz(this.current_timezone).format('h:mm a');
  }
  ngOnDestroy(): void {
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
    if (this.eventDetailSubscription) {
      this.eventDetailSubscription.unsubscribe();
    }
  }
}
