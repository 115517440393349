import { message_properties } from './../../../../../environments/message_properties';
import { MeaningNetworkService } from './../../../../service/meaning-network.service';
import { NotificationService } from './../../../../service/notification.service';
import { SubscriptionService } from './../../../../service/subscription.service';

import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ReviewInformationComponent } from '../review-information/review-information.component';
import { AccountsService } from './../../../../service/accounts.service';

@Component({
  selector: 'kt-guide-discussion-cart',
  templateUrl: './guide-discussion-cart.component.html',
  styleUrls: ['./guide-discussion-cart.component.scss'],
})
export class GuideDiscussionCartComponent implements OnInit {
  @Input() myCartPopUp;
  @Output() closeCartpopUp = new EventEmitter<boolean>();
  count: number;
  subscription: Subscription;
  loggedInUser: number;
  myCartItemsData = [];
  total_amount: number;
  city: string;
  state: string;
  country: string;
  post_code: string;
  all_catalog_str: string;
  survey_str = 'MWS Survey Experience';
  billing_full_name: string;
  constructor(
    private subscriptionService: SubscriptionService,
    private _cdref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private elementRef: ElementRef,
    private spinner: NgxSpinnerService,
    private toastr: NotificationService,
    private meaning_network_service: MeaningNetworkService,
    private dialog: MatDialog,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
    this.getMyCartItemsList(this.loggedInUser);
    this.getProfileDetail();
  }
  getProfileDetail() {
    this.meaning_network_service
      .getProfileDetails(this.loggedInUser)
      .subscribe((data) => {
        this.billing_full_name = data.billing_full_name;
      });
  }
  getMyCartItemsList(logged_in_user_id) {
    this.subscriptionService
      .myCartItemsList(logged_in_user_id)
      .subscribe((data) => {
        this.myCartItemsData = data.results;
        this.count = data.count;
        if (this.count > 0) {
          this.total_amount = this.myCartItemsData[0]['total_amount_in_cart'][
            'item_total_amount__sum'
          ];
          this.all_catalog_str = this.myCartItemsData[0]['all_catalog_id_str'];
        } else {
          this.total_amount = 0;
          this.all_catalog_str = '';
        }
        this._cdref.detectChanges();
      });
  }
  removeItemFromCart(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to remove this item!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        const data = {
          cart_item_status: 2633,
        };
        this.subscriptionService
          .removeItemToCart(data, id)
          .subscribe((data) => {
            if (data.status === 201) {
              this.getMyCartItemsList(this.loggedInUser);
              this.spinner.hide();
              this.toastr.showSuccess(
                message_properties.ITEM_REMOVED_FROM_CART_MESSAGE_SUCCESS,
                ''
              );
            } else {
              this.toastr.showError(
                message_properties.ITEM_REMOVED_FROM_CART_MESSAGE_ERROR,
                ''
              );
            }
            (error) => {
              this.toastr.showError(
                message_properties.ITEM_REMOVED_FROM_CART_MESSAGE_ERROR,
                ''
              );
            };
          });
      }
    });
  }
  paymentIncartItems() {
    const data = {
      member: this.loggedInUser,
      all_catalog_str: this.all_catalog_str,
    };
    this.subscriptionService
      .paymentInCartItemsEaServer(data)
      .subscribe((data) => {
        if (data.status === 201) {
          this.getMyCartItemsList(this.loggedInUser);
          this.toastr.showSuccess(
            message_properties.PAYMENT_MESSAGE_SUCCESS,
            ''
          );
          this.dialog.closeAll();
        } else {
          this.toastr.showError(message_properties.PAYMENT_MESSAGE_ERRROR, '');
        }
        (error) => {
          this.toastr.showError(message_properties.PAYMENT_MESSAGE_ERRROR, '');
        };
      });
  }
  proceedToCheckout() {
    this.closeCartpopUp.emit(false);
    this.myCartPopUp = false;
    this.dialog.open(ReviewInformationComponent, {
      disableClose: true,
      width: '50%',
      data: {
        member: this.loggedInUser,
        all_catalog_str: this.all_catalog_str,
        total_amount: this.total_amount,
        billing_full_name: this.billing_full_name
      },
    });
  }
}
