// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import { HeaderDirective } from './_base/layout';
import { ClickStopPropagationDirective } from './_base/layout/directives/click-stop-propagation.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [HeaderDirective, ClickStopPropagationDirective],
  exports: [HeaderDirective],
  providers: [],
})
export class CoreModule {}
