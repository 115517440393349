<kt-spinner></kt-spinner>
<div class="profile_section meaningCentralProfil">
  <div class="row">
    <div class="col-sm-12 col-lg-3">
      <!-- Profile User Data -->
      <div
        class="profile_card back_card_background_clr profile_card-top pos-relative"
      >
        <mat-icon
          (click)="setProfileEdit()"
          title="Edit"
          class="pos-absolute-top-right-corner"
          style="right: 0rem !important; top: 0rem !important; cursor: pointer"
          >edit</mat-icon
        >
        <div
          class="dis-prof i-pad-sect-upload d-flex flex-direction-column align-items-center"
          style="position: relative"
        >
          <label class="i-pad-sect-label w-250-pp" style="position: relative">
            <!-- <img [src]="signedUrl" /> -->
            <img
              alt="Pic"
              class="w-250-pp"
              [src]="
                profile_path ? profile_path : './assets/media/users/default.png'
              "
            />
            <span
              class="profile-edit-icn profile-edit-icn-pop profile_upload_icon profile-edit-area pos-absolute-bottom-right-corner-20"
            >
              <img
                src="./assets/media/icon/Edit@2x.png"
                [matMenuTriggerFor]="menu_search_form"
              />
            </span>
            <!--Menu start-->

            <mat-menu
              #menu_search_form="matMenu"
              class="search-menunw mat-menu-connections"
            >
              <div class="mat-menu-search">
                <div class="memg-btn-go mb-0">
                  <form>
                    <table width="100%">
                      <tr class="mt-2" *ngIf="!profile_photo_present">
                        <td>
                          <label
                            class="cursorp mb-0"
                            (click)="profileImageUpload()"
                            >Upload Photo</label
                          >
                        </td>
                      </tr>

                      <tr class="mb-2" *ngIf="profile_photo_present">
                        <td>
                          <label class="mb-0 cursorp" (click)="removePic()"
                            >Remove Photo</label
                          >
                        </td>
                      </tr>
                    </table>
                  </form>
                </div>
              </div>
            </mat-menu>
            <!--Menu start-->
          </label>
          <div class="name-name-admin text-align-center w-100">
            <h1>{{ first_name }}</h1>
            <h2>{{ last_name }}</h2>
            <hr class="hr-5" />
            <h3 class="loc">
              {{ city ? city + "," : "" }}
              {{ country }}
            </h3>
          </div>
        </div>
        <div
          class="i-pad-sect-upload prof-vid-edt d-flex justify-content-between align-items-center"
          *ngIf="mentor_indicator"
        >
          <table border="0" width="100%">
            <tr>
              <td align="right" width="5%">
                <img
                  src="./assets/media/icon/curator.png"
                  title=""
                  style="width: 1.25rem; height: 1.25rem; margin-right: 0.3rem"
                />
              </td>
              <td width="35%">
                {{ available ? "Available" : "Not Available" }}
              </td>
            </tr>
          </table>
        </div>
        <div class="edit_section">
          <form
            [formGroup]="profileForm"
            (ngSubmit)="profileSave(profileForm.value)"
            enctype="multipart/form-data"
            novalidate
          >
            <div class="multi-collapse" id="" *ngIf="shwEdtProfile">
              <div class="card-body">
                <mat-form-field class="required">
                  <mat-label>First Name</mat-label>
                  <input
                    matInput
                    formControlName="first_name"
                    autocomplete="off"
                    tabindex="1"
                    oninput="this.value = this.value.replace(/[^A-Za-z-'\- ]|^ /g,'')"
                  />
                </mat-form-field>
                <mat-form-field class="required">
                  <mat-label>Last Name</mat-label>
                  <input
                    matInput
                    formControlName="last_name"
                    autocomplete="off"
                    tabindex="2"
                    oninput="this.value = this.value.replace(/[^A-Za-z-'\- ]|^ /g,'')"
                  />
                </mat-form-field>
                <div>
                  <kt-search-dropdown
                    (sendbackresponse)="setCountry($event)"
                    [countrySelected]="true"
                    [mode]="'edit'"
                    [fieldType]="'country'"
                  ></kt-search-dropdown>
                </div>
                <mat-form-field class="required">
                  <mat-label>State </mat-label>
                  <input
                    class="mt-0"
                    matInput
                    type="text"
                    formControlName="state"
                    autocomplete="off"
                    tabindex="4"
                    oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"
                  />
                </mat-form-field>

                <mat-form-field class="required">
                  <mat-label>City</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="city"
                    autocomplete="off"
                    tabindex="5"
                    #citySearchInput
                    oninput="this.value = this.value.replace(/[^A-Za-z\. ]|^ /g,'')"
                  />
                </mat-form-field>
                <div class="country-box-child-div" *ngIf="cityBox">
                  <div
                    class="search-results country-viewport"
                    infinite-scroll
                    [infiniteScrollDistance]="modalScrollDistance"
                    [infiniteScrollThrottle]="modalScrollThrottle"
                    [scrollWindow]="false"
                    (scrolled)="pushValue($event)"
                  >
                    <div class="country-autocom-box">
                      <li
                        *ngFor="let city of cityList"
                        (click)="setCity(city.name, city.state_name, city.id)"
                      >
                        {{ city.city_with_country_code }}
                      </li>
                    </div>
                  </div>
                </div>
                <button class="btn begin_quiz" [disabled]="!profileForm.valid">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- Guide Heightlights -->
      <div
        class="profile_card back_card_background_clr"
        *ngIf="mentor_indicator"
      >
        <div class="edit_section">
          <!-- <a class="mb-4"> -->
          <table border="0" width="100%">
            <tr>
              <td align="left">
                <table>
                  <tr>
                    <td>
                      <h1 class="fs-20">Guide Highlights</h1>
                    </td>
                    <td>
                      <!-- <p><button tooltip="On click" class="btn btn-small btn-outline btn-rounded">On click</button></p> -->
                      <span
                        tooltipClass="tooltip_design"
                        theme="light"
                        tooltip="{{ guide_highlights }}"
                        placement="right"
                        showDelay="500"
                        animation="fade"
                        noArrow="false"
                      >
                        <span class="icon-info">
                          <mat-icon class="info_material_icon">info</mat-icon>
                        </span>
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
              <td align="right">
                <!-- <img (click)="loadEditguideHeightlts()" src="./assets/media/edit.png" class="ml-10 img_radius_none"
                  style="width: 15px; height: 15px" /> -->
                <mat-icon
                  title="Edit"
                  (click)="loadEditguideHeightlts()"
                  style="
                    right: 4px !important;
                    top: 4px !important;
                    cursor: pointer;
                  "
                  >edit</mat-icon
                >
              </td>
            </tr>
          </table>
          <!-- </a> -->
          <hr />
          <div
            class="multi-collapse"
            id="multiCollapseExample7"
            *ngIf="!shwGuidHightlts"
          >
            <div class="boxData">
              <div class="content content-scroll">
                <span
                  >Years of Work Experience:&nbsp;{{
                    no_of_years == null ? 0 : no_of_years
                  }}</span
                >
                <br /><br />
                <!-- <span>Price per Hour : $50.00</span>
                <br /><br /> -->
                <span>Why I Love Being A Guide: <br /> </span>
                <div
                  [innerHtml]="sanitizer.bypassSecurityTrustHtml(highlight)"
                ></div>
              </div>
            </div>
          </div>

          <div
            class="multi-collapse"
            id="multiCollapseExample7"
            *ngIf="shwGuidHightlts"
          >
            <div class="row">
              <div class="col-sm-12">
                <form
                  [formGroup]="GuidHightltsForm"
                  (ngSubmit)="GuidHightltsSave(GuidHightltsForm.value)"
                  novalidate
                >
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    formControlName="available"
                  >
                    <mat-label>Available</mat-label>
                  </mat-checkbox>
                  <mat-form-field class="mt-2">
                    <mat-label>No. of Years</mat-label>
                    <input
                      matInput
                      formControlName="no_of_years"
                      type="number"
                      min="1"
                      oninput="this.value = this.value.replace(/[^0-9-]|^ /g,'')"
                    />
                  </mat-form-field>
                  <div class="mt-2">
                    <label>Highlights *</label>
                    <textarea
                      [ngxSummernote]="config"
                      formControlName="highlight"
                    ></textarea>
                  </div>
                  <mat-card-actions>
                    <button
                      [disabled]="!GuidHightltsForm.valid"
                      class="btn begin_quiz my_btn"
                    >
                      Save
                    </button>
                  </mat-card-actions>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- What Matters To Me -->
      <div class="profile_card back_card_background_clr">
        <div class="edit_section">
          <table border="0" width="100%">
            <tr>
              <td align="left">
                <table>
                  <tr>
                    <td>
                      <h1 class="fs-20">What Matters To Me</h1>
                    </td>
                    <td>
                      <span
                        theme="light"
                        tooltipClass="tooltip_design"
                        [tooltip]="whatMattersToMeToolTip"
                        placement="right"
                        showDelay="500"
                      >
                        <span class="icon-info">
                          <mat-icon class="info_material_icon">info</mat-icon>
                        </span>
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
              <td align="right">
                <mat-icon (click)="loadCare(0)" style="cursor: pointer"
                  >add_circle</mat-icon
                >
              </td>
            </tr>
          </table>
          <hr />
          <div
            class="multi-collapse"
            id="multiCollapseExample7"
            *ngIf="!shwCare"
          >
            <div class="boxData">
              <div class="content">
                <ul *ngIf="topic_count > 0">
                  <li *ngFor="let item of topics">
                    {{ item.alternate_name }}
                  </li>
                </ul>
                <spna *ngIf="topic_count == 0">
                  You have not added any topics!
                </spna>
              </div>
            </div>
          </div>

          <div
            class="multi-collapse"
            id="multiCollapseExample7"
            *ngIf="shwCare"
          >
            <div class="row">
              <div class="col-sm-12">
                <!-- <form [formGroup]="whaticareAboutForm" (ngSubmit)="whaticareAboutSave(whaticareAboutForm.value)"
                  novalidate> -->
                <div class="multi-collapse" id="multiCollapseExample1">
                  <spna *ngFor="let item of tagList">
                    <button
                      class="welcom_butto"
                      [ngClass]="{ active: checkInterest(item.id) }"
                      (click)="selctInterest(item.id)"
                    >
                      {{ item.alternate_name }}
                    </button>
                  </spna>

                  <button (click)="submit()" class="btn begin_quiz my_btn">
                    Save
                  </button>

                  <!-- <mat-card-actions class="mat-marg-top">
                        <button class="btn begin_quiz my_btn">Save</button>
                      </mat-card-actions> -->
                </div>
                <!-- </form> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-6">
      <div class="">
        <!-- Mentoring Experience -->
        <div
          class="profile_card back_card_background_clr"
          *ngIf="mentor_indicator"
        >
          <form
            [formGroup]="mentoringExperienceForm"
            (ngSubmit)="mentoringExperienceSave(mentoringExperienceForm.value)"
            novalidate
          >
            <div class="row">
              <div class="col">
                <table border="0" width="100%">
                  <tr>
                    <td align="left">
                      <table>
                        <tr>
                          <td>
                            <h1 class="fs-20">Related Experience</h1>
                          </td>
                          <td>
                            <span
                              theme="light"
                              tooltipClass="tooltip_design"
                              [tooltip]="mentoringExperienceToolTiop"
                              placement="right"
                              showDelay="500"
                            >
                              <span class="icon-info">
                                <mat-icon class="info_material_icon"
                                  >info</mat-icon
                                >
                              </span>
                            </span>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td align="right">
                      <mat-icon
                        title="Edit"
                        (click)="loadMentoringExperiance()"
                        style="
                          right: 4px !important;
                          top: 4px !important;
                          cursor: pointer;
                        "
                        >edit</mat-icon
                      >
                      <!-- <img (click)="loadMentoringExperiance()" src="./assets/media/edit.png"
                          class="ml-10 img_radius_none" style="width: 15px; height: 15px" /> -->
                    </td>
                  </tr>
                </table>

                <hr />
                <div
                  class="multi-collapse multi-collapse"
                  id="multiCollapseExample7"
                  *ngIf="!shwMentoringExperiance"
                >
                  <ng-container *ngIf="guide_experience_description">
                    <div
                      class="content experiance-scroll experiance-scroll-exp"
                      [innerHtml]="
                        sanitizer.bypassSecurityTrustHtml(
                          guide_experience_description
                        )
                      "
                    ></div>
                  </ng-container>
                  <ng-container *ngIf="guide_experience_description == ''">
                    <p>You have not added any related experience!</p>
                  </ng-container>
                </div>
                <div
                  class="multi-collapse multi-collapse"
                  id="multiCollapseExample7"
                  *ngIf="shwMentoringExperiance"
                >
                  <div class="card-body pt-0">
                    <div>
                      <label>Description</label>
                      <textarea
                        [ngxSummernote]="config"
                        formControlName="description"
                      ></textarea>
                    </div>
                    <mat-card-actions>
                      <button
                        [disabled]="!mentoringExperienceForm.valid"
                        class="btn begin_quiz my_btn mt-10"
                      >
                        Save
                      </button>
                    </mat-card-actions>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- Guide Endorsements -->
        <div
          class="profile_card back_card_background_clr"
          *ngIf="mentor_indicator"
        >
          <div class="row">
            <div class="col">
              <table border="0" width="100%">
                <tr>
                  <td align="left">
                    <table>
                      <tr>
                        <td>
                          <h1 class="fs-20">Guide Endorsements</h1>
                        </td>
                        <td>
                          <span
                            theme="light"
                            tooltipClass="tooltip_design"
                            [tooltip]="guideEndorsementsToolTip"
                            placement="right"
                            showDelay="500"
                          >
                            <span class="icon-info">
                              <mat-icon class="info_material_icon"
                                >info</mat-icon
                              >
                            </span>
                          </span>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td align="right"></td>
                </tr>
              </table>

              <hr />
              <div
                class="multi-collapse experiance-scroll"
                id="multiCollapseExample7"
              >
                <ng-container *ngIf="endorse_count > 0">
                  <div class="boxData" *ngFor="let endorse of myEndorseData">
                    <div class="content expanded">
                      <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1">
                          <img
                            alt=""
                            [src]="
                              returnImage(
                                endorse.member_profile_details[0].picture_path
                              )
                            "
                            class="profile_img ml-0 cursorp"
                            (click)="
                              viewProfile(
                                endorse.member_profile_details[0].id,
                                endorse.member_profile_details[0].first_name,
                                endorse.member_profile_details[0].last_name,
                                endorse.member_profile_details[0].picture_path
                                  ? endorse.member_profile_details[0]
                                      .picture_path
                                  : './assets/media/users/default.png'
                              )
                            "
                          />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-11">
                          <p
                            class="cursorp"
                            (click)="
                              viewProfile(
                                endorse.member_profile_details[0].id,
                                endorse.member_profile_details[0].first_name,
                                endorse.member_profile_details[0].last_name,
                                endorse.member_profile_details[0].picture_path
                                  ? endorse.member_profile_details[0]
                                      .picture_path
                                  : './assets/media/users/default.png'
                              )
                            "
                          >
                            {{ endorse.member_profile_details[0].full_name }}
                          </p>
                          <p class="fs-12 fs-12-endore">
                            Endorsed on:&nbsp;{{
                              endorse.creation_date | date: "MMM d, y"
                            }}
                          </p>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 pl-4">
                          <br />

                          {{ endorse.remarks }}

                          <table class="mt-2" style="float: right">
                            <tr>
                              <ng-container
                                *ngIf="endorse.endorsement_status == 2404"
                              >
                                <td>
                                  <mat-icon
                                    (click)="
                                      acceptMyEndorsementRequest(endorse.id)
                                    "
                                    >check_circle
                                  </mat-icon>
                                </td>
                                <td>
                                  <mat-icon
                                    (click)="
                                      declineMyEndorsementRequest(endorse.id)
                                    "
                                    >cancel</mat-icon
                                  >
                                </td>
                              </ng-container>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <!-- row-->
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="endorse_count == 0">
                  <p>You do not have any endorsements!</p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <!-- Meaningful Work Story -->
        <div class="profile_card back_card_background_clr">
          <form
            [formGroup]="memberadditionalForm"
            (ngSubmit)="inspiringWorkStorySave(memberadditionalForm.value)"
            novalidate
          >
            <div class="row">
              <div class="col">
                <a>
                  <table border="0" width="100%">
                    <tr>
                      <td align="left">
                        <table>
                          <tr>
                            <td>
                              <h1 class="fs-20">Inspiring Work Story</h1>
                            </td>
                            <td>
                              <span
                                theme="light"
                                tooltipClass="tooltip_design"
                                [tooltip]="meaningfulWorkStoryToolTip"
                                placement="right"
                                showDelay="500"
                              >
                                <span class="icon-info">
                                  <mat-icon class="info_material_icon"
                                    >info</mat-icon
                                  >
                                </span>
                              </span>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td align="right">
                        <mat-icon
                          title="Edit"
                          (click)="loadStory()"
                          style="
                            right: 4px !important;
                            top: 4px !important;
                            cursor: pointer;
                          "
                          >edit</mat-icon
                        >
                        <!-- <img src="./assets/media/edit.png" class="ml-10 img_radius_none"
                          style="width: 15px; height: 15px" /> -->
                      </td>
                    </tr>
                  </table>
                </a>
                <hr />
                <div
                  class="multi-collapse multi-collapse"
                  id="multiCollapseExample7"
                  *ngIf="!shwStory"
                >
                  <div class="boxData">
                    <ng-container>
                      <p
                        class="content experiance-scroll experiance-scroll-exp"
                        [innerHtml]="
                          sanitizer.bypassSecurityTrustHtml(
                            memberadditionalFormData
                          )
                        "
                      ></p>
                    </ng-container>
                  </div>
                </div>
                <div
                  class="multi-collapse"
                  id="multiCollapseExample1"
                  *ngIf="shwStory"
                >
                  <div class="card-body">
                    <div class="example-full-width">
                      <textarea
                        [ngxSummernote]="config"
                        formControlName="property_value"
                      ></textarea>
                    </div>

                    <mat-card-actions>
                      <button
                        [disabled]="!memberadditionalForm.valid"
                        class="btn begin_quiz my_btn mt-10"
                      >
                        Save
                      </button>
                    </mat-card-actions>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- Meaning Full Work Experience -->
        <div class="profile_card back_card_background_clr">
          <div class="row">
            <div class="col">
              <a>
                <table border="0" width="100%">
                  <tr>
                    <td align="left">
                      <table>
                        <tr>
                          <td>
                            <h1 class="fs-20">Work Highlights</h1>
                          </td>
                          <td>
                            <span
                              theme="light"
                              tooltipClass="tooltip_design"
                              [tooltip]="meaningfulWorkExperienceToolTip"
                              placement="right"
                              showDelay="500"
                            >
                              <span class="icon-info">
                                <mat-icon class="info_material_icon"
                                  >info</mat-icon
                                >
                              </span>
                            </span>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td align="right">
                      <mat-icon
                        (click)="
                          viewMeaningFullWorkExperienceFormProfile('add', 0)
                        "
                        style="cursor: pointer"
                      >
                        add_circle</mat-icon
                      >
                    </td>
                  </tr>
                </table>
              </a>
              <hr />
              <div
                class="multi-collapse experiance-scroll"
                id="multiCollapseExample7"
              >
                <ng-container *ngIf="experience_count > 0">
                  <div
                    class="boxData"
                    *ngFor="let experience of experienceData"
                  >
                    <div class="content expanded bgCommon">
                      <span
                        [innerHTML]="
                          sanitizer.bypassSecurityTrustHtml(experience.title)
                        "
                      ></span
                      ><br />
                      <span
                        [innerHTML]="
                          sanitizer.bypassSecurityTrustHtml(experience.name)
                        "
                      ></span
                      ><br />
                      <span
                        [innerHTML]="
                          sanitizer.bypassSecurityTrustHtml(experience.location)
                        "
                      ></span>
                      <br /><br />
                      <span
                        [innerHTML]="
                          sanitizer.bypassSecurityTrustHtml(
                            experience.workprofile
                          )
                        "
                      ></span>
                    </div>
                    <div class="editIcon">
                      <mat-icon [matMenuTriggerFor]="meaningFullWorkExperience"
                        >more_vert</mat-icon
                      >
                      <mat-menu
                        #meaningFullWorkExperience="matMenu"
                        class="search-menunw mat-menu-connections small-panel-width"
                      >
                        <form>
                          <div class="mat-menu-search">
                            <div
                              class="memg-btn-go"
                              (click)="
                                viewMeaningFullWorkExperienceFormProfile(
                                  'edit',
                                  experience.id
                                )
                              "
                              style="cursor: pointer"
                            >
                              Edit
                            </div>
                            <div
                              class="memg-btn-go"
                              (click)="
                                viewMeaningFullWorkExperienceFormProfileDelete(
                                  experience.id
                                )
                              "
                              style="cursor: pointer"
                            >
                              Delete
                            </div>
                          </div>
                        </form>
                      </mat-menu>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="experience_count == 0">
                  <p>You have not added any work highlights!</p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <!-- Education -->
        <div class="profile_card back_card_background_clr">
          <div class="row">
            <div class="col">
              <table border="0" width="100%">
                <tr>
                  <td align="left">
                    <table>
                      <tr>
                        <td>
                          <h1 class="fs-20">Education Highlights</h1>
                        </td>
                        <td>
                          <span
                            theme="light"
                            tooltipClass="tooltip_design"
                            [tooltip]="educationToolTip"
                            placement="right"
                            showDelay="500"
                          >
                            <span class="icon-info">
                              <mat-icon class="info_material_icon"
                                >info</mat-icon
                              >
                            </span>
                          </span>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td align="right">
                    <mat-icon
                      (click)="viewEducationFormProfile('add', 0)"
                      style="cursor: pointer"
                      >add_circle
                    </mat-icon>
                  </td>
                </tr>
              </table>
              <hr />
              <div
                class="multi-collapse experiance-scroll"
                id="multiCollapseExample7"
              >
                <ng-container *ngIf="education_count > 0">
                  <div class="boxData" *ngFor="let education of educationData">
                    <div class="content expanded">
                      <span>
                        {{ education.title }} |
                        {{ education.start_date | date: "MMM d, y" }} -
                        {{ education.end_date | date: "MMM d, y" }}</span
                      ><br /><br />
                      <span> {{ education.name }}</span
                      ><br />
                      <span
                        [innerHTML]="
                          sanitizer.bypassSecurityTrustHtml(education.location)
                        "
                      ></span
                      ><br />
                    </div>
                    <div class="editIcon">
                      <mat-icon [matMenuTriggerFor]="education1"
                        >more_vert</mat-icon
                      >
                      <!-- <img src="./assets/media/icon/ActionMenu_White@2x.png"  alt="" /> -->
                      <mat-menu
                        #education1="matMenu"
                        class="search-menunw mat-menu-connections small-panel-width"
                      >
                        <form>
                          <div class="mat-menu-search">
                            <div
                              class="memg-btn-go"
                              (click)="
                                viewEducationFormProfileEdit(
                                  'edit',
                                  education.id,
                                  education.start_date
                                )
                              "
                              style="cursor: pointer"
                            >
                              Edit
                            </div>
                            <div
                              class="memg-btn-go"
                              (click)="
                                viewEducationFormProfileDelete(education.id)
                              "
                              style="cursor: pointer"
                            >
                              Delete
                            </div>
                          </div>
                        </form>
                      </mat-menu>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="education_count == 0">
                  <p>You have not added any educational highlights!</p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <!-- Volunteer Experiance -->
        <div class="profile_card back_card_background_clr">
          <div class="row">
            <div class="col">
              <a>
                <table border="0" width="100%">
                  <tr>
                    <td align="left">
                      <table>
                        <tr>
                          <td>
                            <h1 class="fs-20">Volunteer Highlights</h1>
                          </td>
                          <td>
                            <span
                              theme="light"
                              tooltipClass="tooltip_design"
                              [tooltip]="volunteerExperienceToolTip"
                              placement="right"
                              showDelay="500"
                            >
                              <span class="icon-info">
                                <mat-icon class="info_material_icon"
                                  >info</mat-icon
                                >
                              </span>
                            </span>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td align="right">
                      <mat-icon
                        (click)="viewVolunteerExperianceFormProfile('add', 0)"
                        style="cursor: pointer"
                      >
                        add_circle</mat-icon
                      >
                    </td>
                  </tr>
                </table>
              </a>
              <hr />
              <div
                class="multi-collapse experiance-scroll"
                id="multiCollapseExample7"
              >
                <ng-container *ngIf="volunteering_count > 0">
                  <div
                    class="boxData"
                    *ngFor="let volunteer of volunteeringData"
                  >
                    <div class="content expanded">
                      <span>
                        {{ volunteer.title }} |
                        {{ volunteer.start_date | date: "MMM d, y" }} -
                        {{ volunteer.end_date | date: "MMM d, y" }}</span
                      ><br /><br />
                      <span> {{ volunteer.name }}</span
                      ><br />
                      <span
                        [innerHTML]="
                          sanitizer.bypassSecurityTrustHtml(volunteer.location)
                        "
                      ></span>
                      <br /><br />
                      <span
                        [innerHTML]="
                          sanitizer.bypassSecurityTrustHtml(
                            volunteer.workprofile
                          )
                        "
                      ></span
                      ><br />
                    </div>
                    <div class="editIcon">
                      <mat-icon [matMenuTriggerFor]="volunteerExperiance"
                        >more_vert</mat-icon
                      >
                      <mat-menu
                        #volunteerExperiance="matMenu"
                        class="search-menunw mat-menu-connections small-panel-width"
                      >
                        <form>
                          <div class="mat-menu-search">
                            <div
                              class="memg-btn-go"
                              (click)="
                                viewVolunteerExperianceFormProfileEdit(
                                  'edit',
                                  volunteer.id,
                                  volunteer.start_date
                                )
                              "
                              style="cursor: pointer"
                            >
                              Edit
                            </div>
                            <div
                              class="memg-btn-go"
                              (click)="
                                viewVolunteerExperianceFormProfileDelete(
                                  volunteer.id
                                )
                              "
                              style="cursor: pointer"
                            >
                              Delete
                            </div>
                          </div>
                        </form>
                      </mat-menu>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="volunteering_count == 0">
                  <p>You have not added any volunteer highlights!</p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-3">
      <!-- My Meaning Moments -->
      <div class="profile_card imagePosts back_card_background_clr">
        <div class="row">
          <div class="col">
            <table border="0">
              <tr>
                <td align="left">
                  <table>
                    <tr>
                      <td>
                        <h1 class="fs-20">My Meaning Moments</h1>
                      </td>
                      <td>
                        <ng-template #HtmlContentMeaningMoments>
                          <p>
                            {{ myMeaningLogToolTip }}&nbsp;<a
                              href="https://meaningsphere.zendesk.com/hc/en-us/articles/5860136371732"
                              target="_blank"
                              >&nbsp;Learn more »</a
                            >
                          </p>
                        </ng-template>
                        <kt-tooltip-html
                          [position]="'left'"
                          [theme]="'light'"
                          [htmlValue]="HtmlContentMeaningMoments"
                        >
                        </kt-tooltip-html>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <hr />
            <div class="scroll_class scroll_class_profile_network">
              <ng-container *ngIf="meaning_moments_count > 0">
                <ng-container *ngFor="let log of meaningLog">
                  <div class="content expanded meaning-log-card">
                    <div class="dis-prof i-pad-sect-upload">
                      <div class="row">
                        <div class="col-sm-12">
                          <span class="time">{{
                            log.action_date | date: "MMM d, y"
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="postedData">
                      <span class="title only_two_line_show">{{
                        log.collab_content_details[0].title
                      }}</span>
                      <ng-container
                        *ngIf="
                          log.collab_content_details[0].video_indicator == 1
                        "
                      >
                        <img
                          [src]="
                            log.collab_content_details[0].attachment_path
                              ? log.collab_content_details[0].attachment_path
                              : './assets/media/default/post_default.png'
                          "
                          class="constellation_img all_other_img"
                          alt=""
                        />
                      </ng-container>
                      <ng-container
                        *ngIf="
                          log.collab_content_details[0].video_indicator == 0
                        "
                      >
                        <img
                          src="./assets/media/default/video_default.png"
                          class="profile-posdata-img"
                          alt=""
                        />
                      </ng-container>
                      <div
                        class="postedDataContent only_two_line_show mt-2"
                        [innerHTML]="
                          sanitizer.bypassSecurityTrustHtml(
                            log.collab_content_details[0].description
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="meaning_moments_count == 0">
                <p>You have not created any Meaning Moments items!</p>
              </ng-container>
            </div>
            <hr />
            <a
              [routerLink]="'/meaning-network/meaning-moments'"
              routerLinkActive="active"
              class="vieaAll hover-text-color"
            >
              View All
            </a>
          </div>
        </div>
      </div>
      <!-- My Treasures -->
      <div
        class="profile_card imagePosts imagePostsComments back_card_background_clr"
      >
        <div class="row">
          <div class="col">
            <table border="0" width="100%">
              <tr>
                <td align="left">
                  <table>
                    <tr>
                      <td>
                        <h1 class="fs-20">My Treasures</h1>
                      </td>
                      <td>
                        <ng-template #HtmlContentTreasures>
                          <p>
                            {{ myTreasuresToolTip }}&nbsp;<a
                              href="https://meaningsphere.zendesk.com/hc/en-us/articles/5860161880340"
                              target="_blank"
                              >&nbsp;Learn more »</a
                            >
                          </p>
                        </ng-template>
                        <kt-tooltip-html
                          [position]="'left'"
                          [theme]="'light'"
                          [htmlValue]="HtmlContentTreasures"
                        >
                        </kt-tooltip-html>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <hr />
            <div class="scroll_class scroll_class_profile_network">
              <ng-container *ngIf="tresure_count > 0">
                <ng-container *ngFor="let gem of myTreasure">
                  <div class="content expanded meaning-log-card">
                    <div class="dis-prof i-pad-sect-upload">
                      <div class="row">
                        <div class="col-sm-3">
                          <label
                            for="file"
                            class="i-pad-sect-label"
                            style="position: relative"
                          >
                            <img
                              alt="Pic"
                              class="profile_img"
                              [src]="
                                gem.collab_content_details[0]
                                  .created_by_curator_indicator
                                  ? './assets/media/users/sphere.png'
                                  : gem.collab_content_details[0].picture_path
                                  ? gem.collab_content_details[0].picture_path
                                  : './assets/media/users/default.png'
                              "
                            />
                          </label>
                        </div>
                        <div class="col-sm-9">
                          <span class="name pr-5">{{
                            gem.collab_content_details[0]
                              .created_by_curator_indicator
                              ? "MeaningSphere Editor"
                              : gem.collab_content_details[0].full_name
                          }}</span>
                          <span class="time">{{
                            gem.action_date | date: "MMM d, y"
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="postedData">
                      <span class="star-text only_two_line_show">{{
                        gem.collab_content_details[0].title
                      }}</span>
                      <ng-container
                        *ngIf="
                          gem.collab_content_details[0].video_indicator == 1
                        "
                      >
                        <img
                          [src]="
                            gem.collab_content_details[0].attachment_path
                              ? gem.collab_content_details[0].attachment_path
                              : './assets/media/default/post_default.png'
                          "
                          class="constellation_img all_other_img"
                          alt=""
                        />
                      </ng-container>
                      <ng-container
                        *ngIf="
                          gem.collab_content_details[0].video_indicator == 0
                        "
                      >
                        <img
                          src="./assets/media/default/video_default.png"
                          alt=""
                          class="constellation_img mt-2 all_other_img"
                        />
                      </ng-container>
                      <div
                        class="postedDataContent only_two_line_show mt-2"
                        [innerHTML]="
                          sanitizer.bypassSecurityTrustHtml(
                            gem.collab_content_details[0].description
                          )
                        "
                      ></div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="tresure_count == 0">
                <p>You do not have any treasure items!</p>
              </ng-container>
            </div>
            <hr />
            <a
              [routerLink]="'/meaning-network/my-treasures'"
              routerLinkActive="active"
              class="vieaAll hover-text-color"
            >
              View All
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
