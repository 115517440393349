<ngx-spinner
  size="small"
  class="preload-btn"
  color="#ffffff"
  bdColor="transparent"
  [fullScreen]="false"
  template="<img src='/assets/media/loader.gif'/>"
>
</ngx-spinner>

<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1 class="popup_card_title">Message Details</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />

  <div class="row">
    <div class="col-sm-12">
        <p>{{message}}</p>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-sm-12 tr">
      <button class="btn begin_quiz ml-2 mt-1" (click)="withdrawRequest()" *ngIf="requestActive==6">
        Withdraw
      </button>
      <button
        mat-dialog-close
        class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
