<div class="col-sm-12 mt-3">
 <h2 class="section-title1 mt-10">Key Takeaways About Your PMP</h2>
 <p class="section-text mt-4">Scores that fall in the:
 </p>
<ul class="section-text">
  <li> Top 25% / 75-100 percentile are considered high</li>
  <li> Middle 50% / 26-74 percentile are considered typical</li>
  <li>Bottom 25% / 1-25 percentile are considered low</li></ul>
  <p class="section-text">In general, the higher your scores, the more meaning you find from that element of the Map. </p>
  <p class="section-text mt-10">Meaningful work also depends on the degree to which you personally derive meaning from each of the four pathways.</p>
  <p class="section-text mt-10">As you start thinking about how to apply your PMP scores to your work situation, please remember these four points:</p>

  <ol class="section-text mt-4">
    <li>Scores are a point of reference only. There’s no need to try and improve a score just because it is typical or low.</li>
    <li>If you have a high score in Unity with Others, that doesn’t mean this is your “type” or “workstyle”; it merely means you are currently finding a high degree of meaning at work in this pathway.</li>
    <li>Whether you’re concerned by a low score or satisfied with a high score, you can decide to change something based on your circumstances at work and the meaning you find in both work and other life roles.</li>
    <li>If you feel stuck or unsure about your PMP score, go back to the survey items and your scores. Identify which specific work experiences underlie these scores and how they matter to you. You can schedule a session with a MeaningSphere Guide if you’d like some help making sense of your results and discovering next steps.</li>
  </ol>
  <!-- <div class="section-text mt-3">
    To do this, shift your focus away from the four meaning pathways and, focus
    on how much of the shape falls:
    <ul>
      <li>
        In the top half of the Map compared to the bottom half of the Map (BEING
        versus DOING)
      </li>
      <li>
        On the left side of the Map compared to the right side of the Map (SELF
        versus OTHERS)
      </li>
    </ul>
  </div> -->

  <!-- <div class="col-sm-12 text-center">
    <div class="survey_title mt-3">
      Healthy Balance Based On Your PMP Score
    </div>
  </div>
  -->
  <table class="d-none" width="85%" border="0" align="center">
    <tr>
      <td width="15%">&nbsp;</td>
      <td width="25%" align="right">
        <div class="d-flex">
          <div class="box">&nbsp;</div>
          <label for="self">Your PMP Score</label>
        </div>
        <div class="d-flex">
          <div class="box1">&nbsp;</div>
          <label for="average">Typical PMP Score</label>
        </div>
      </td>
      <td align="right">
        <canvas class="psedoclassforBG" id="radar_pie_chart_2"></canvas>
      </td>
    </tr>
  </table> 
  <!-- <div class="row mt-3">
      <div class="col-sm-11 text-center mt-1">
        <div class="row">
          <div class="col-sm-4 pos-relative">
            <div class="d-flex">
              <div class="box">&nbsp;</div>
              <label for="self">Your PMP Score</label>
            </div>
            <div class="d-flex">
              <div class="box1">&nbsp;</div>
              <label for="average">Typical PMP Score</label>
            </div>
          </div>
          <div class="col-sm-8">
            <canvas class="psedoclassforBG" id="radar_pie_chart_2"></canvas>
          </div>
        </div>
      </div>
    </div> -->
  <!-- </div> -->
  <!-- <div class="section-text mt-8">
    Self-assess your degree of balance based on your Personal Meaning Profile:
    <ul>
      <li>
        If a significant amount of the area in the shape is either to the left
        or to the right of the vertical line then you may want to re-balance
        SELF-OTHERS activities in your life.
      </li>
      <li>
        If a significant amount of the area in the shape is either above or
        below the horizontal line then you may want to re-balance BEING-DOING
        activities in your life.
      </li>
    </ul>
  </div> -->
</div>
