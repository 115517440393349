import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../../../service/notification.service';
import { MeaningNetworkService } from '../../../../service/meaning-network.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  NgForm,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { message_properties } from '../../../../../environments/message_properties';
import {
  FixedSizeVirtualScrollStrategy,
  VIRTUAL_SCROLL_STRATEGY,
} from '@angular/cdk/scrolling';
import { Subscription, fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { AccountsService } from './../../../../service/accounts.service';
export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(0, 100, 400);
  }
}
@Component({
  selector: 'kt-education-form-profile',
  templateUrl: './education-form-profile.component.html',
  styleUrls: ['./education-form-profile.component.scss'],
  providers: [
    { provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy },
  ],
})
export class EducationFormProfileComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('educationSearchInput', { static: false })
  educationSearchInput: ElementRef;
  educationForm: UntypedFormGroup;
  memberId: string;
  addOrEdit: string;
  memberid: any;
  educationId: number;
  InstituteList = [];
  instituteCount: number;
  EducationBox: boolean = false;
  minDate;
  maxDate = new Date();
  saveButton: boolean = false;
  educationSaveSubscription: Subscription;
  instituteSubscription: Subscription;
  getEducationSubscription: Subscription;

  async findMemberId() {
    await this.route.queryParams.subscribe((params) => {
      return (this.memberId = params['member']);
    });
  }
  constructor(
    public dialogRef: MatDialogRef<EducationFormProfileComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private toastr: NotificationService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private meaning_network_service: MeaningNetworkService,
    private accountService: AccountsService
  ) {
    this.addOrEdit = data.addOrEdit;
    this.educationId = data.educationId;
    this.minDate = data.start_date;
  }

  ngOnInit(): void {
    this.findMemberId();
    this.memberid = this.accountService.muser;
    this.educationForm = new UntypedFormGroup({
      title: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(96),
      ]),
      company: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(96),
      ]),
      location: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(96),
      ]),
      volunteer_id: new UntypedFormControl(this.educationId),
      start_date: new UntypedFormControl('', [Validators.required]),
      end_date: new UntypedFormControl('', [Validators.required]),
      check_value: new UntypedFormControl(2),
      member: new UntypedFormControl(this.memberid),
    });
    if (this.addOrEdit !== 'add') {
      this.getEducation();
    }
  }
  educationSave(ngForm: NgForm) {
    this.spinner.show();
    this.saveButton = true;
    if (this.educationId === 0) {
      this.educationSaveSubscription = this.meaning_network_service
        .postEducationData(ngForm)
        .subscribe(
          (data) => {
            if (data.status === 201) {
              this.emptyForm();
              this.saveButton = false;
              this.dialogRef.close();
              this.toastr.showSuccess(
                message_properties.EDUCATION_FORM_PROFILE_SAVE_SUCCESS,
                ''
              );
              this.cdRef.detectChanges();
              this.spinner.hide();
            } else if (data.status === 204) {
              this.toastr.showError(data.message, '');
              this.cdRef.detectChanges();
              this.spinner.hide();
            }
          },
          (error) => {
            this.toastr.showError(
              message_properties.EDUCATION_FORM_PROFILE_SAVE_ERROR,
              ''
            );
            this.cdRef.detectChanges();
            this.spinner.hide();
          }
        );
    } else {
      this.educationSaveSubscription = this.meaning_network_service
        .updateEducationData(ngForm)
        .subscribe((data) => {
          if (data.status === 201) {
            this.emptyForm();
            this.saveButton = false;
            this.dialogRef.close();
            this.toastr.showSuccess(
              message_properties.EDUCATION_FORM_PROFILE_SAVE_SUCCESS,
              ''
            );
            this.cdRef.detectChanges();
            this.spinner.hide();
          } else if (data.status === 204) {
            this.toastr.showError(data.message, '');
            this.cdRef.detectChanges();
            this.spinner.hide();
          } else if (data.status === 200) {
            this.toastr.showError(
              message_properties.EDUCATION_FORM_PROFILE_SAVE_ERROR,
              ''
            );
            this.cdRef.detectChanges();
            this.spinner.hide();
          }
        });
    }
  }

  emptyForm() {
    this.educationForm.patchValue({
      company: '',
      location: '',
      title: '',
      end_date: '',
      start_date: '',
    });
  }

  getEducation() {
    const data = this.educationForm.value;
    this.getEducationSubscription = this.meaning_network_service
      .getMemberCompanyDetail(data)
      .subscribe((data) => {
        this.educationForm.patchValue({
          title: data.title,
          company: data.name,
          location: data.location,
          start_date: data.start_date,
          end_date: data.end_date,
          check_value: 2,
          member: this.memberid,
        });
        this.cdRef.detectChanges();
      });
  }
  ngAfterViewInit() {
    this.educationInstituteSearch();
  }
  educationInstituteSearch() {
    fromEvent(this.educationSearchInput.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // if character length greater then 2
        filter((res) => res.length > 1),
        // Time in milliseconds between key events
        debounceTime(500),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((sendData: string) => {
        this.instituteSubscription = this.meaning_network_service
          .getEducationInstitute(sendData)
          .subscribe((response) => {
            this.InstituteList = response.results;
            this.instituteCount = response.count;
            if (this.instituteCount > 0) {
              this.EducationBox = true;
            } else {
              this.EducationBox = false;
            }
            this.cdRef.detectChanges();
          });
      });
  }
  setInstitute(name, id) {
    this.EducationBox = false;
    this.educationForm.patchValue({
      title: name,
    });
  }
  onChangeFrom(event) {
    this.minDate = event.value;
    this.educationForm.patchValue({
      endDate: this.minDate,
    });
    this.cdRef.detectChanges();
  }
  onChangeTo(event) {
    if (
      this.educationForm.value.startDate === '' ||
      this.educationForm.value.startDate === null
    ) {
      this.educationForm.patchValue({
        startDate: '2020-01-01',
        // startDate: this.maxDate,
      });

      if (
        this.educationForm.value.startDate > this.educationForm.value.endDate
      ) {
        this.educationForm.patchValue({
          // startDate: '2020-01-01',
          startDate: new Date(
            // '2020-01-01'
            new Date(this.educationForm.value.endDate).setDate(
              new Date(this.educationForm.value.endDate).getDate() - 1
            )
          ),
        });
      }
    }
    if (this.educationForm.value.startDate > this.educationForm.value.endDate) {
      this.educationForm.patchValue({
        startDate: new Date(
          new Date(this.educationForm.value.endDate).setDate(
            new Date(this.educationForm.value.endDate).getDate() - 1
          )
        ),
      });
    }
  }
  ngOnDestroy(): void {
    if (this.educationSaveSubscription) {
      this.educationSaveSubscription.unsubscribe();
    }
    if (this.instituteSubscription) {
      this.instituteSubscription.unsubscribe();
    }
    if (this.getEducationSubscription) {
      this.getEducationSubscription.unsubscribe();
    }
  }
}
