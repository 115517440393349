import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { AccountsService } from './../service/accounts.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req, next) {
    let authService = this.injector.get(AccountsService);
    const jwt = authService.token;
    if (!!jwt) {
      req = req.clone({
        setHeaders: {
          'authtoken': `Bearer ${jwt}`,
        },
      });
      
    }
    return next.handle(req);
  }
}
