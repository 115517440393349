<mat-card class="full_screen_background_card_height">
  <h1>Next Steps</h1>
  <hr />
  <mat-card-content class="next-step-scroll map-of-meaning/next-steps card2-style-height-with-hr-heading">
    <table width="100%" border="0">
      <tr>
        <td align="center">
          <div class="left_image">
            <img src="assets/media/next_step/CYP.png" class="img-icn" (click)="firstVideo()" />
          </div>
        </td>
        <td></td>
        <td>
          <div class="title_name1">Choosing your Path</div>
          <p>
            Listen to other Explorers like you tell their personal stories of
            how they’ve used their Survey Report to create more meaning at work.
            Then, explore any of the three activities you’d like to try.
          </p>
        </td>
        <td></td>
        <!-- <td colspan="2">
          <button class="btn begin_quiz" (click)="openVideo()">VIEW</button>
        </td> -->
      </tr>

      <tr>
        <td colspan="6">&nbsp;</td>
      </tr>
      <tr>
        <td width="10%" align="center">
          <div class="left_image">
            <img src="assets/media/next_step/Guide.png" class="img-icn" (click)="secondVideo()" />
          </div>
        </td>
        <td width="1%">&nbsp;</td>
        <td width="80%">
          <div class="title_name1">Guided Discussion</div>
          <p>
            Review your Survey Report with a Guide. Guides are good listeners
            and they’re trained to discover insights about where and how you
            find meaning at work using your Report. They’ll help you identify
            what you can stop, start and continue doing to help you create a
            more meaningful work day!
          </p>
        </td>
        <td width="3%">&nbsp;</td>
        <td>
        </td>
        <td>
          <ng-container *ngIf="guide_count > 0">
            <button class="btn begin_quiz" (click)="guideDiscussion(guide_id, guide_full_name, guide_pic)">
              GO
            </button>
          </ng-container>
          <ng-container *ngIf="guide_count == 0">
            <button class="btn begin_quiz" (click)="linkWithGuide()">GO</button>
          </ng-container>
        </td>
      </tr>
      <tr>
        <td colspan="6">&nbsp;</td>
      </tr>

      <tr>
        <td align="center">
          <div class="left_image">
            <img src="assets/media/next_step/DIY.png" class="img-icn" (click)="thirdVideo()" />
          </div>
        </td>
        <td></td>
        <td>
          <div class="title_name1">DIY</div>
          <p>
            Build your own action plan using a 3-step process that we’ve created
            for you. Need help along the way? You can always reach out to a
            Guide.
          </p>
        </td>
        <td></td>
        <td>
          <!-- <button class="btn begin_quiz-digit">0</button> -->
        </td>
        <td>
          <button class="btn begin_quiz" (click)="goToPageDiy()">GO</button>
        </td>
      </tr>
      <tr>
        <td colspan="6">&nbsp;</td>
      </tr>

      <tr>
        <td align="center">
          <div class="img_community">
            <!-- <img src="assets/media/next_step/MoMCom.png" class="img-icn" (click)="fourthVideo()" /> -->
            <img src="assets/media/next_step/MoMCom.png" class="img-icn" />
          </div>
        </td>
        <td></td>
        <td>
          <div class="title_name1">Visit the Meaningful Work Survey Community</div>
          <p>
            MeaningSphere hosts this Community. It's a great place to meet other Explorers who use the Meaningful Work
            Survey in creative and fun ways. The community also offers you a place to relax and interact with friendly
            Map of Meaning experts and find out about other learning opportunities with the Map of Meaning.
          </p>
        </td>
        <td></td>
        <td></td>
        <td>
          <!-- <button *ngIf="community_count == 0" class="btn begin_quiz" (click)="viewDetail()">
            GO
          </button>
          <button *ngIf="community_count != 0" class="btn begin_quiz" (click)="goToCommunity()">
            GO
          </button> -->
        </td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>