<kt-spinner></kt-spinner>
<mat-card class="full_screen_background_card_height">
  <table width="100%" border="0">
    <tr>
      <td align="left" width="90%"><h1>My Guided Explorers</h1></td>
      <td>
        <button
          class="btn begin_quiz cu_community_date"
          [routerLink]="'/discover/guide-reports'"
        >
          Guide Reports
        </button>
      </td>
    </tr>
  </table>

  <hr />

  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Current">
      <ng-template matTabContent>
        <kt-currnt-explorer></kt-currnt-explorer>
      </ng-template>
    </mat-tab>
    <mat-tab label="Past">
      <ng-template matTabContent>
        <kt-past-explorer></kt-past-explorer>
      </ng-template>
    </mat-tab>
    <mat-tab label="Received Requests">
      <ng-template matTabContent>
        <kt-requests></kt-requests>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-card>
