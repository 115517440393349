import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { AccountsService } from './accounts.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  folder_path: string;
  gobj = {};
  AWS_BUCKET_NAME: string;

  constructor(private accountService: AccountsService) {
    this.AWS_BUCKET_NAME = this.accountService.AWS_BUCKET_777;
  }
  private getS3Bucket(): any {
    const bucket = new S3({
      accessKeyId: this.accountService.AWS_BUCKET_222,
      secretAccessKey: this.accountService.AWS_BUCKET_333,
      region: this.accountService.AWS_BUCKET_444,
    });
    return bucket;
  }

  async fileUpload(file, file_name, folder = 6) {
    if (folder === 1) {
      this.folder_path = 'content';
    } else if (folder === 2) {
      this.folder_path = 'defaultappimages';
    } else if (folder === 3) {
      this.folder_path = 'event';
    } else if (folder === 4) {
      this.folder_path = 'extract';
    } else if (folder === 5) {
      this.folder_path = 'group';
    } else if (folder === 6) {
      this.folder_path = 'profile';
    }
    const contentType = file.type;
    let fileName = file_name;
    const bucket = new S3({
      accessKeyId: this.accountService.AWS_BUCKET_222,
      secretAccessKey: this.accountService.AWS_BUCKET_333,
      region: this.accountService.AWS_BUCKET_444,
    });
    const params = {
      Bucket: `${this.AWS_BUCKET_NAME}/${this.folder_path}`,
      Key: fileName,
      Body: file,
      ContentType: contentType,
    };

    return bucket.upload(params, function (err, s3data) {
      this.url = s3data;
      const result = Object.values(s3data);
      return result[0];
    });
  }

  //Base64 to image
  base64toFileInformation(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}
