import { NotificationService } from '../../../service/notification.service';
import { CookieService } from 'ngx-cookie-service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { message_properties } from '../../../../environments/message_properties';

import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';

import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ThirdWelcomePageComponent } from '../welcome/third-welcome-page/third-welcome.component';
import { CityStateCountryService } from '../../../service/city-state-country.service';
import { AccountsService } from '../../../service/accounts.service';
import jwt_decode from 'jwt-decode';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('email') email: ElementRef;
  loginForm: UntypedFormGroup;
  ActiveRouteParam;
  returnUrl: string = '/meaning-network/my-feed';
  code: number;
  message: string;
  login_title: string;
  loginText: string;
  data: {};
  saveButton: boolean = false;
  showPassword: boolean = false;
  loginData: any;
  loginTokenDecodedData: any;
  userSnapDetailSubscription: Subscription;
  submitSubscription: Subscription;
  constructor(
    private accountService: AccountsService,
    private router: Router,
    private route: ActivatedRoute,
    private _cdref: ChangeDetectorRef,
    private cookieService: CookieService,
    private toastr: NotificationService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private cityStateCountry: CityStateCountryService
  ) {
    if (
      this.router.getCurrentNavigation() != null &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.loginText = this.router.getCurrentNavigation().extras.state.text;
    }
    if (!this.loginText) {
      this.loginText = this.cookieService.check('welcome')
        ? 'back_general'
        : 'general';
    }
  }
  ngOnInit() {
    this.pendoAndUserSnapDetails();
    if (this.accountService.token) {
      this.router.navigate(['meaning-network', 'my-feed']);
    }

    this.login_title = this.cookieService.check('welcome')
      ? 'Welcome back!'
      : 'Welcome';

    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(250),
      ]),
      password: new UntypedFormControl('', [Validators.required]),
      checkme: new UntypedFormControl(''),
    });
    this.returnUrl =
      this.route.snapshot.queryParams['returnUrl'] ||
      '/meaning-network/my-feed';
  }

  ngAfterViewInit(): void {
    this.email.nativeElement.focus();
  }

  onSubmit(ngForm: NgForm) {
    this.saveButton = true;
    this.spinner.show();
    return (this.submitSubscription = this.accountService
      .loginChecking(ngForm)
      .subscribe(
        (res) => {
          if (res.status === 200) {
            this.loginData = res;
            localStorage.setItem(
              'token_refresh_data',
              JSON.stringify(this.loginData)
            );
            this.loginTokenDecodedData = jwt_decode(this.loginData.token);
            localStorage.setItem(
              'past_mentor_indicator',
              this.accountService.past_mentor_indicator
            );
            localStorage.setItem(
              'past_admin_indicator',
              this.accountService.past_admin_indicator
            );
            localStorage.setItem(
              'past_curator_indicator',
              this.accountService.past_curator_indicator
            );
            localStorage.setItem(
              'past_program_director_indicator',
              this.accountService.past_program_director_indicator
            );
            localStorage.setItem(
              'current_time_zone',
              this.accountService.current_time_zone
            );

            localStorage.setItem(
              'user_email',
              window.btoa(this.accountService.user_email)
            );
            localStorage.setItem(
              'image',
              this.accountService.image ? this.accountService.image : '0'
            );
            localStorage.setItem('cityId', this.accountService.city_id);
            localStorage.setItem('countryId', this.accountService.country_id);
            localStorage.setItem(
              'secondaryCityId',
              this.accountService.secondary_city_id
            );
            localStorage.setItem(
              'secondaryCountryId',
              this.accountService.secondary_country_id
            );
            localStorage.setItem(
              'banner_message',
              this.accountService.banner_message
            );
            localStorage.setItem(
              'banner_message_count',
              this.accountService.banner_message_count
            );
            localStorage.setItem('banner_id', this.accountService.banner_id);
            localStorage.setItem(
              'country',
              window.btoa(
                this.accountService.country ? this.accountService.country : ''
              )
            );

            localStorage.setItem(
              'COMPAIR_DATE_FOR_LOGOUT',
              window.btoa(this.accountService.COMPAIR_DATE_FOR_LOGOUT)
            );

            if (!this.accountService.onboarding_complete) {
              this.dialog.open(ThirdWelcomePageComponent, {
                width: '70%',
                disableClose: true,
              });
            }
            this.message = '';
            this.toastr.showSuccess(message_properties.LOGGED_IN_SUCCESS, '');
            this.router.navigateByUrl(this.returnUrl);
            this.spinner.hide();
            this._cdref.detectChanges();
          } else {
            this.saveButton = false;
            this.toastr.showError(res.message, '');
            this.spinner.hide();
            this._cdref.detectChanges();
          }
        },
        (error) => {
          this.saveButton = false;
          this.toastr.showError(
            message_properties.USER_REQUEST_SAVE_MEMBER_ERROR,
            ''
          );
          this.spinner.hide();
        }
      ));
  }
  forgotpassword() {
    this.router.navigateByUrl('/login/forgotpassword');
  }

  loginpage() {
    this.router.navigate(['/login'], { state: { text: 'general' } });
  }
  pendoAndUserSnapDetails() {
    if (localStorage.getItem('var1')) {
      return;
    }
    this.userSnapDetailSubscription = this.cityStateCountry
      .getAllCountryList()
      .subscribe((response) => {
        localStorage.setItem('var1', response.pando_api);
        localStorage.setItem('var2', btoa(response.user_snap));
      });
  }
  ngOnDestroy(): void {
    if (this.userSnapDetailSubscription) {
      this.userSnapDetailSubscription.unsubscribe();
    }
    if (this.submitSubscription) {
      this.submitSubscription.unsubscribe();
    }
  }
}
