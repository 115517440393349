import { MeaningNetworkService } from './../../../../service/meaning-network.service';
import { Router } from '@angular/router';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AccountsService } from './../../../../service/accounts.service';

@Component({
  selector: 'kt-do-not-show-pop-up',
  templateUrl: './do-not-show-pop-up.component.html',
  styleUrls: ['./do-not-show-pop-up.component.scss'],
})
export class DoNotShowPopUpComponent implements OnInit, OnDestroy {
  param: number;
  name: string;
  parameter_value: string;
  loggedInUser: number;
  check_uncheck: boolean = false;
  subscriptions: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DoNotShowPopUpComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private router: Router,
    private dialog: MatDialog,
    public sanitizer: DomSanitizer,
    private meaning_network_service: MeaningNetworkService,
    private accountService: AccountsService
  ) {
    this.param = data.param;
    this.name = data.name;
    this.parameter_value = data.parameter_value;
  }

  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
  }
  close(): void {
    this.dialogRef.close();
  }

  redirectTo(param): void {
    if (param === 100) {
      this.router.navigateByUrl('/discover/search-for-a-guide');
    } else if (param === 102) {
      this.router.navigateByUrl('/meaningful-work-survey/my-surveys');
    } else if (param === 101) {
      this.router.navigateByUrl('/meaning-mirror/give-reflection');
    } else if (param === 105) {
      this.router.navigateByUrl('/meaning-communities/community');
    } else if (param === 103) {
      this.router.navigateByUrl('/meaning-circle/circles');
    } else if (param === 122) {
      this.router.navigateByUrl('/library/all');
    } else if (param === 124) {
      this.router.navigateByUrl('/meaningful-work-survey/diy-page/first-page');
    } else if (param === 133) {
      this.router.navigateByUrl('/meaning-network/my-profile');
    } else if (param === 134) {
      this.router.navigateByUrl('/discover/my-guided-explorers');
    }
    this.dialog.closeAll();
  }

  addPost(param): void {
    this.router.navigateByUrl('/meaning-network/my-feed');
    this.dialog.closeAll();
  }
  donotSave($event: Event, param) {
    const checked_value = $event.target['checked'];

    let data = {
      overview_id: param,
      checked_value: checked_value,
      member: this.loggedInUser,
    };
    this.subscriptions = this.meaning_network_service
      .postDoNotPopUp(data)
      .subscribe((data) => {
        if (param === 100) {
          localStorage.setItem('show_guide_overview', 'false');
        } else if (param === 102) {
          localStorage.setItem('show_mom_overview', 'false');
        } else if (param === 101) {
          localStorage.setItem('show_mirror_overview', 'false');
        } else if (param === 105) {
          localStorage.setItem('show_community_overview', 'false');
        } else if (param === 103) {
          localStorage.setItem('show_circle_overview', 'false');
        } else if (param === 122) {
          localStorage.setItem('show_constellation_overview', 'false');
        } else if (param === 104) {
          localStorage.setItem('show_network_overview', 'false');
        } else if (param === 124) {
          localStorage.setItem('show_diy_overview', 'false');
        } else if (param === 133) {
          localStorage.setItem('show_portrait_overview', 'false');
        } else if (param === 134) {
          localStorage.setItem('show_explorer_overview', 'false');
        }
      });
  }
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
