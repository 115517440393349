import { AdminPageService } from './../../../../service/admin-page.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { SendIntroSessionRequestComponent } from './../send-intro-session-request/send-intro-session-request.component';
import { SendRequestToBeMyGuideComponent } from './../send-request-to-be-my-guide/send-request-to-be-my-guide.component';
import { MentorService } from '../../../../service/mentor.service';
import { ViewProfileComponent } from '../../admin-page/view-profile/view-profile.component';
import { SendMassageComponent } from '../../admin-page/send-massage/send-massage.component';
import { ViewGuideVideoComponent } from '../../admin-page/view-guide-video/view-guide-video.component';
import { Router } from '@angular/router';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-search-for-a-guide',
  templateUrl: './search-for-a-guide.component.html',
  styleUrls: ['./search-for-a-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchForAGuideComponent implements OnInit, OnDestroy {
  throttle = 300;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;

  searchMentorForm: UntypedFormGroup;
  guides = [];
  count: number;
  loggedInUser: number;
  guide_count: number;
  page: number = 2;
  isShowDivIf = false;
  searchGuideSubscription: Subscription;
  userGuideSubscription: Subscription;
  toggleDisplayDivIf() {
    this.isShowDivIf = !this.isShowDivIf;
  }
  togglehideDisplayDivIf() {
    this.isShowDivIf = !this.isShowDivIf;
  }

  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private mentorService: MentorService,
    private _cdref: ChangeDetectorRef,
    private adminService: AdminPageService,
    private router: Router,
    private accountService: AccountsService
  ) {
    this.searchMentorForm = this.formBuilder.group({
      order_by_select: new UntypedFormControl(''),
      orderBy: new UntypedFormControl(''),
      keywords: new UntypedFormControl(''),
      country: new UntypedFormControl(''),
      available: new UntypedFormControl(''),
      all: new UntypedFormControl(''),
      country_select: new UntypedFormControl(''),
      keywords_select: new UntypedFormControl(''),
      q: new UntypedFormControl(''),
      logged_in_user_id: new UntypedFormControl(this.loggedInUser),
      page: 1,
    });
  }

  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
    this.searchGuideForm();
    this.getLoggedInUserHaveGuide();
  }

  getLoggedInUserHaveGuide() {
    this.userGuideSubscription = this.adminService
      .loggedInUserHaveGuide(this.loggedInUser)
      .subscribe((data) => {
        this.guide_count = data.count;
        this._cdref.detectChanges();
      });
  }
  setCountry(ev) {
    this.searchMentorForm.patchValue({
      country: ev[0].text,
    });
  }

  pushValue(ev?) {
    if (this.count !== this.guides.length) {
      this.searchMentorForm.patchValue({
        page: this.page,
      });
      this.searchGuideForm();
      this.page++;
    }
  }

  searchGuideForm(params = 0) {
    let searchGuideData = this.searchMentorForm.value;
    if (params === 1) {
      this.guides = [];
      this.page = 2;
      this.searchMentorForm.patchValue({
        page: 1,
      });
    }
    this.spinner.show();
    this.searchGuideSubscription = this.mentorService
      .searchGuidesForm(searchGuideData)
      .subscribe((guides) => {
        this.guides = Array.from(
          new Map<any, any>(
            (this.guides.concat(guides.results) as any[][])
              .map((item) =>
                item && item['id'] ? ([item['id'], item] as [any, any]) : null
              )
              .filter(Boolean) as Iterable<readonly [any, any]>
          ).values()
        );
        this.count = guides.count;
        this.spinner.hide();
        this._cdref.detectChanges();
      });
  }

  SendIntroSessionRequest(member, first_name, last_name, pic_path) {
    this.dialog.open(SendIntroSessionRequestComponent, {
      disableClose: true,
      width: '50%',
      data: {
        mentorId: member,
        fullName: first_name + ' ' + last_name,
        picPath: pic_path,
      },
    });
  }
  SendRequestToBeMyGuide(member, first_name, last_name, pic_path) {
    this.dialog.open(SendRequestToBeMyGuideComponent, {
      disableClose: true,
      width: '50%',
      data: {
        mentorId: member,
        fullName: first_name + ' ' + last_name,
        picPath: pic_path,
      },
    });
  }

  openGuideVideo(guide_video_path) {
    this.dialog.open(ViewGuideVideoComponent, {
      disableClose: true,
      width: '50%',
      data: { source: guide_video_path },
    });
  }

  viewProfile(member_id, first_name, last_name, picture_path) {
    let dialogRef = this.dialog.open(ViewProfileComponent, {
      width: '80%',
      disableClose: true,
      data: {
        member: member_id,
        first_name: first_name,
        last_name: last_name,
        picture_path: picture_path,
        click_from: 'guide_search',
      },
    });
  }

  sendMassage(collabContentId, mentee_id, full_name, profile_picture) {
    this.dialog.open(SendMassageComponent, {
      disableClose: true,
      width: '40%',
      data: {
        collabContentId: collabContentId,
        mentee_id: mentee_id,
        clicked_from: 1008,
        full_name: full_name,
        profile_picture: profile_picture,
      },
    });
  }

  resetForm() {
    this.searchMentorForm.patchValue({
      order_by_select: '',
      orderBy: '',
      keywords: '',
      country: '',
      available: '',
      all: '',
      country_select: '',
      keywords_select: '',
      q: '',
    });
    this.searchGuideForm(1);
  }

  myGuide() {
    this.router.navigate(['discover', 'my-guides']);
  }
  ngOnDestroy(): void {
    if (this.searchGuideSubscription) {
      this.searchGuideSubscription.unsubscribe();
    }
    if (this.userGuideSubscription) {
      this.userGuideSubscription.unsubscribe();
    }
  }
}
