import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'kt-surveyreport9',
  templateUrl: './surveyreport9.component.html',
  styleUrls: ['./surveyreport9.component.scss'],
})
export class Surveyreport9Component implements OnInit {
  @Input() currentSelectedSurvey;
  @Input() memberId;
  @Input() json_report;
  reportStr: string;
  reportData = [];
  question_31: number;
  question_30: number;
  question_29: number;
  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.reportData = JSON.parse(this.json_report);
    this.question_31 = this.reportData['question_31'];
    this.question_30 = this.reportData['question_30'];
    this.question_29 = this.reportData['question_29'];

    let barchart_ref3 = this.elementRef.nativeElement.querySelector(
      `#barchart_hor3`
    );
    var myChart_hor3 = new Chart(barchart_ref3, {
      type: 'horizontalBar',
      data: {
        labels: [
          'I am satisfied with my life',
          'I have a sense of well-being',
          'I am able to appreciate the good things in life',
        ],
        datasets: [
          {
            data: [this.question_29, this.question_30, this.question_31],
            backgroundColor: ['#A2D45E', '#A2D45E', '#A2D45E'],
            borderWidth: 0,
          },
        ],
      },
      options: {
        scales: {
          responsive: true,
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: '#1f1547',
                max: 5,
                fontSize: 13,
                stepSize: 1,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: '#1f1547',
                stepSize: 1,
                fontSize: 13,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
    new Chart('barchart_hor4', {
      type: 'horizontalBar',
      data: {
        labels: [
          'I am satisfied with my life (Example)',
          'I have a sense of well-being (Example)',
          'I am able to appreciate the good things in life (Example)',
        ],
        datasets: [
          {
            data: [2, 3, 2],
            backgroundColor: ['#CDD0E3', '#CDD0E3', '#CDD0E3'],
            borderWidth: 1,
          },
        ],
        showTooltips: false,
      },
      options: {
        toolTip: {
          enabled: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 5,
                fontColor: '#1f1547',
                stepSize: 1,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,

                stepSize: 1,
                fontColor: '#1f1547',
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
  }
}
