import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../../../service/notification.service';
import { SubscriptionService } from '../../../../service/subscription.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  OnDestroy,
} from '@angular/core';
import { message_properties } from '../../../../../environments/message_properties';
import { CuratorPageService } from '../../../../service/curator-page.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
import { TimezoneService } from '../../../../service/timezone.service';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-setup-discussion-from-guide',
  templateUrl: './setup-discussion-from-guide.component.html',
  styleUrls: ['./setup-discussion-from-guide.component.scss'],
})
export class SetupDiscussionFromGuideComponent implements OnInit, OnDestroy {
  dialogForm: UntypedFormGroup;
  current_timezone: string;
  // current_time_zone_date: string;
  mentee: string;
  eventId: number;
  menteeDp: string;
  menteeId: number;
  loggedinUserid: number;
  ufullname: string;
  myDate: Date = new Date();
  myTime: string;
  minTime;
  user_profile_path: string;
  groupid: number;
  clicked_from: string;
  header_set_up_discussion: string;
  endTime: string;
  saveButton: boolean = false;
  profile_path: string;
  user_profile_path_data: boolean = false;
  guide_service_count: number;
  GuideServiceList = [];
  member_relationship_id: number;
  current_time_zone_date: Date;
  event_bool: boolean = false;
  both_date: boolean = false;
  ifToday: boolean = false;
  start_date: string;
  rescheduletimeselected: boolean = false;
  public from_date: moment.Moment;
  guideServiceSubscription: Subscription;
  createSubscription: Subscription;
  eventDetailSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<SetupDiscussionFromGuideComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private toastr: NotificationService,
    private curatorService: CuratorPageService,
    private subscriptionservice: SubscriptionService,
    private date_pipe: DatePipe,
    private timeZoneService: TimezoneService,
    private accountService: AccountsService
  ) {
    this.mentee = data.mentee;
    this.eventId = data.eventId;
    this.menteeDp =
      data.dp !== 'null' ? data.dp : './assets/media/users/default.png';
    this.menteeId = data.menteeId;
    this.clicked_from = data.clicked_from;
    this.member_relationship_id = data.member_relationship_id;
  }

  ngOnInit() {
    this.header_set_up_discussion =
      this.eventId === 0
        ? 'Set up a discussion with your Explorer'
        : 'Reschedule discussion with your Explorer';
    this.loggedinUserid = this.accountService.muser;
    this.ufullname = this.accountService.full_name;
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.current_time_zone_date = this.myDate;
    this.myTime = moment(this.myDate)
      .tz(this.current_timezone)
      .format('hh:mm A');
    this.minTime = moment(this.myDate)
      .tz(this.current_timezone)
      .format('hh:mm A');
    this.user_profile_path = localStorage.getItem('image')
      ? localStorage.getItem('image')
      : './assets/media/users/default.png';
    this.dialogForm = this.formBuilder.group({
      name: new UntypedFormControl('New Set Up'),
      description: new UntypedFormControl('', [Validators.required]),
      virtual_link_address: new UntypedFormControl('New Discussion'),
      max_participant: new UntypedFormControl('1'),
      visibility_type: new UntypedFormControl('1953'),
      event_type: new UntypedFormControl(2733),
      creating_member: new UntypedFormControl(this.loggedinUserid),
      check_uncheck: new UntypedFormControl(true),
      active: new UntypedFormControl(3),
      start_date: new UntypedFormControl('', [Validators.required]),
      timeFrom: new UntypedFormControl('', [Validators.required]),
      timeTo: new UntypedFormControl(''),
      duration: new UntypedFormControl(15, [Validators.required]),
      group: this.groupid,
      imageUrl: [''],
      processing_member: new UntypedFormControl(this.loggedinUserid), // guide
      subscribing_member: new UntypedFormControl(this.menteeId), // explorer
      create_from: new UntypedFormControl(this.clicked_from), // create_from
      member_relationship_id: new UntypedFormControl(
        this.member_relationship_id
      ), // create_from
    });

    this.guideServiceList();
    if (this.eventId !== 0) {
      this.getEventDetailInDialog(0, this.eventId);
      this.event_bool = true;
    }
  }

  ngOnChanges(changes) {
    this.onChangeFrom(changes);
  }

  onChangedate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dialogForm.patchValue({ timeFrom: '' });
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate());
    if (tomorrow.toDateString() === event.value.toDateString()) {
      this.minTime = moment(this.myDate)
        .tz(this.current_timezone)
        .format('hh:mm A');
      this.both_date = true;
    } else {
      this.minTime = '12:00 AM';
    }
  }

  onChangeFromTime(event) {
    var timeFrm = this.dialogForm.get('timeFrom').value;
    var durat = this.dialogForm.get('duration').value;
    var timeToVal;
    timeToVal = timeFrm;
    if (timeFrm) {
      if (durat !== null || durat !== '') {
        timeToVal = moment(timeFrm, 'hh:mm A')
          .add(durat, 'minutes')
          .format('hh:mm A');
      }
      this.endTime = timeToVal;
      this.dialogForm.patchValue({
        timeTo: timeToVal,
      });
    }
    this._cdr.detectChanges();
  }
  onChangeFrom(event) {
    var durat = this.dialogForm.get('duration').value;
    var timeFrm = event;
    var timeToVal;

    if (
      (timeFrm !== null || timeFrm !== '') &&
      (durat !== '' || durat !== null)
    ) {
      timeToVal = moment(timeFrm, 'hh:mm A')
        .add(durat, 'minutes')
        .format('hh:mm A');
    } else {
      timeToVal = timeFrm;
    }
    this.endTime = timeToVal;
    this.dialogForm.patchValue({
      timeTo: timeToVal,
    });
    this.rescheduletimeselected = false;
    this._cdr.detectChanges();
  }
  async createDialog(ngForm: NgForm) {
    this.saveButton = true;
    const object1 = ngForm;
    let start_date = '';
    const start_date_obj = object1['start_date'];
    if (String(start_date_obj).includes('Z')) {
      const start_date_obj_new = moment(this.start_date)
        .tz(this.current_timezone)
        .toDate();
      start_date = moment(start_date_obj_new).format('YYYY-MM-DD');
    } else {
      start_date =
        object1['start_date'].getFullYear() +
        '-' +
        (object1['start_date'].getMonth() + 1) +
        '-' +
        object1['start_date'].getDate();
    }
    const time_from = moment(object1['timeFrom'], 'hh:mm A').format('HH:mm');
    const time_to = moment(object1['timeTo'], 'hh:mm A').format('HH:mm');
    const start_date_time = moment(
      start_date + ' ' + time_from,
      'YYYY-MM-DD HH:mm'
    ).format('YYYY-MM-DD HH:mm');
    const end_date_time = moment(
      start_date + ' ' + time_to,
      'YYYY-MM-DD HH:mm'
    ).format('YYYY-MM-DD HH:mm');

    const object2 = {
      start_date_time: moment.tz(start_date_time, this.current_timezone),
      end_date_time: moment.tz(end_date_time, this.current_timezone),
    };
    const object3 = { ...object1, ...object2 };
    this.spinner.show();
    if (this.eventId === 0) {
      this.createSubscription = this.curatorService
        .createSetUpDiscussionFromGuide(object3)
        .subscribe(
          (data) => {
            if (data.status === 201) {
              this.dialogRef.close();
              this.spinner.hide();
              this.saveButton = false;
              this.toastr.showSuccess(
                message_properties.ADD_SETUP_DISCUSSION_SUCCESS,
                ''
              );
            } else if (data.status === 402) {
              this.saveButton = false;
              this.spinner.hide();
              this.toastr.showError(data.message, '');
            } else {
              this.saveButton = false;
              this.spinner.hide();
              this.toastr.showError(
                message_properties.SETUP_DISCUSSION_ERROR,
                ''
              );
            }
          },
          (error) => {
            this.saveButton = false;
            this.spinner.hide();
            this.toastr.showError(
              message_properties.SETUP_DISCUSSION_ERROR,
              ''
            );
          }
        );
    } else {
      this.createSubscription = this.curatorService
        .updateSetUpDiscussionFromGuide(object3, this.eventId)
        .subscribe(
          (data) => {
            if (data.status === 201) {
              this.dialogRef.close();
              this.spinner.hide();
              this.getEventDetailInDialog(0, this.eventId);
              this.saveButton = false;
              this.toastr.showSuccess(
                message_properties.UPDATE_SETUP_DISCUSSION_SUCCESS,
                ''
              );
            } else if (data.status === 402) {
              this.saveButton = false;
              this.spinner.hide();
              this.toastr.showError(data.message, '');
            } else {
              this.saveButton = false;
              this.spinner.hide();
              this.toastr.showError(
                message_properties.SETUP_DISCUSSION_ERROR,
                ''
              );
            }
          },
          (error) => {
            this.saveButton = false;
            this.spinner.hide();
            this.toastr.showError(
              message_properties.SETUP_DISCUSSION_ERROR,
              ''
            );
          }
        );
    }
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  returnImage(image) {
    if (image || image !== null) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }
  getEventDetailInDialog(groupId, sessionId) {
    this.eventDetailSubscription = this.curatorService
      .getEventDetailInDialog(groupId, sessionId)
      .subscribe((data) => {
        this.profile_path = data.image_path;
        this.ufullname = JSON.parse(
          JSON.stringify(data.creating_member_details)
        )[0]['full_name'];
        if (
          JSON.parse(JSON.stringify(data.creating_member_details))[0][
            'picture_path'
          ]
        ) {
          this.user_profile_path_data = true;
          this.user_profile_path = JSON.parse(
            JSON.stringify(data.creating_member_details)
          )[0]['picture_path'];
        }

        this.start_date = String(data.end_date);
        var event_time = this.date_pipe.transform(
          String(data.start_date),
          'HH:mm a'
        );
        var minTime2 = moment(this.myDate)
          .tz(this.current_timezone)
          .format('HH:mm A');

        var today_date = moment(this.myDate)
          .tz(this.current_timezone)
          .format('yyyy-MM-DD');
        var event_start_date = this.date_pipe.transform(
          this.start_date,
          'yyyy-MM-dd'
        );

        if (event_start_date === today_date) {
          this.ifToday = true;
          if (event_time < minTime2) {
            this.rescheduletimeselected = true;
          }
        } else {
          this.ifToday = false;
        }

        this.dialogForm.patchValue({
          start_date: new Date(
            this.date_pipe.transform(
              this.dateInUserTimezome(data.start_date),
              'YYYY,MM,dd'
            )
          ),
          timeFrom: moment
            .tz(data.start_date, this.current_timezone)
            .format('hh:mm A'),
          timeTo: moment
            .tz(data.end_date, this.current_timezone)
            .format('hh:mm A'),
          address: data.address,
          createdOn: data.creation_date,
          event_type: data.event_type,
          name: data.name,
          description: data.description,
          max_participant: data.max_participants,
          check_uncheck: data.virtual_indicator === 'true' ? true : false,
          virtual_link_address: data.virtual_indicator
            ? data.virtual_link
            : data.address,
          duration: data.time_duration,
          catalog_item: data.catalog_item,
        });
        this._cdr.detectChanges();
      });
  }
  guideServiceList() {
    this.guideServiceSubscription = this.subscriptionservice
      .catalogListBasisOnOffering(1249)
      .subscribe(
        (data) => {
          this.guide_service_count = data.count;
          this.GuideServiceList = data.results;
          this._cdr.detectChanges();
        },
        (error) =>
          console.error('Technical Issue. Guide Service not available!')
      );
  }
  plus_item(interval) {
    var interval_2 = Number(this.dialogForm.value['duration']);
    if (interval_2 >= 60) {
      return;
    }
    this.dialogForm.patchValue({
      duration: interval_2 + interval,
    });
  }
  minus_item(interval) {
    var interval_2 = Number(this.dialogForm.value['duration']);
    if (interval_2 === interval) {
      return;
    }
    this.dialogForm.patchValue({
      duration: interval_2 - interval,
    });
  }
  dateInUserTimezome(datetime: string) {
    this.from_date = moment(datetime).utc();
    this.timeZoneService.setTenantTimeZone(this.current_timezone);
    return this.timeZoneService.utcToTenantString(this.from_date, 'LL');
  }
  ngOnDestroy(): void {
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
    if (this.guideServiceSubscription) {
      this.guideServiceSubscription.unsubscribe();
    }
    if (this.eventDetailSubscription) {
      this.eventDetailSubscription.unsubscribe();
    }
  }
}
