<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1 class="cmn_title_top">Thank you!</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />

  <div class="text_area text_area-scrool my-4 report-first">
    <div class="row">
      <div class="col-sm-12 col-lg-12">
        <div class="title_name1_head1">
          Thank you for completing the survey!
        </div>
        <div class="title_name1_des1 mt-3">
          Before moving on to your Survey Report, please consider helping us
          advance the body of research on how and where people find meaning at
          work. MeaningSphere collaborates with a highly accredited university
          that conducts ongoing research in this area. We hope you will agree to
          share your survey results and some demographic information to support
          this ongoing research. If you click the agree button below, you can
          still change your mind on the next page.
        </div>
        <br />
        <div class="title_name1_head">Our promise to you</div>
        <div class="title_name1_des mt-3">
          The information that you provide will be disassociated from your
          identifying information and stored in MeaningSphere's secure data
          storage system. MeaningSphere complies with all applicable privacy
          requirements. Once we transmit your demographic information to our
          research partner, your information cannot be linked to your name or to
          your email address on record with us.
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="button_area mt-3">
    <div class="">
      <div class="">
        <table align="right" border="0">
          <tr>
            <td>
              <button
                color="primary"
                class="btn begin_quiz"
                (click)="goToSelectedSurveyReport()"
              >
                Skip and go to my report
              </button>
            </td>
            <td>
              <button
                color="primary"
                class="btn begin_quiz"
                (click)="openSharedDataForm()"
              >
                Yes, I agree to share some data
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
