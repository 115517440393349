import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { message_properties } from '../../../../../environments/message_properties';
import { NotificationService } from '../../../../../app/service/notification.service';
import { SurveyService } from '../../../../../app/service/survey.service';
import { FinalReportFirstPageComponent } from '../final-report-first-page/final-report-first-page.component';
import { ReportFirstPageComponent } from '../report-first-page/report-first-page.component';

@Component({
  selector: 'kt-after-survey-shared-data',
  templateUrl: './after-survey-shared-data.component.html',
  styleUrls: ['./after-survey-shared-data.component.scss'],
})
export class AfterSurveySharedDataComponent implements OnInit, OnDestroy {
  sharedpersonaldataForm: UntypedFormGroup;
  saveButton: boolean = false;
  dataSharedSubscription: Subscription;
  json_report: string;
  currentSelectedSurvey: any;
  memberId: number;
  reportStr: string;

  constructor(
    public dialogRef: MatDialogRef<AfterSurveySharedDataComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private surveyService: SurveyService,
    private toastr: NotificationService
  ) {
    this.currentSelectedSurvey = data.currentSelectedSurvey;
    this.memberId = data.memberId;
    this.json_report = data.json_report1;
    this.reportStr = this.json_report;
  }

  ngOnInit(): void {
    this.sharedpersonaldataForm = new UntypedFormGroup({
      gender: new UntypedFormControl('', [Validators.required]),
      age_group: new UntypedFormControl('', [Validators.required]),
      highest_education_level_attained: new UntypedFormControl('', [
        Validators.required,
      ]),
      region_you_live: new UntypedFormControl('', [Validators.required]),
      region_of_birth: new UntypedFormControl('', [Validators.required]),
      industry: new UntypedFormControl('', [Validators.required]),
      occupation: new UntypedFormControl('', [Validators.required]),
      tenant: new UntypedFormControl(1),
      loggedInUser: new UntypedFormControl(this.memberId),
      currentSelectedSurvey: new UntypedFormControl(this.currentSelectedSurvey),
      survey_id: new UntypedFormControl(1),
    });
  }
  sharedataSave(ngForm: NgForm) {
    this.saveButton = true;
    this.spinner.show();
    this.dataSharedSubscription = this.surveyService
      .sendPersonalData(ngForm)
      .subscribe((data) => {
        if (data.status === 201) {
          this.saveButton = false;
          this.spinner.hide();
          this.closePopUpWindows();
          this.toastr.showSuccess(
            message_properties.RESEARCH_DATA_SAVE_SUCCESS,
            ''
          );
          this.dialogRef.close();
          this.dialog.open(FinalReportFirstPageComponent, {
            width: '70%',
            disableClose: true,
            data: {
              memberId: this.memberId,
              currentSelectedSurvey: this.currentSelectedSurvey,
              json_report1: this.json_report,
            },
          });
        } else {
          this.saveButton = false;
          this.spinner.hide();
          this.toastr.showError(
            message_properties.RESEARCH_DATA_SAVE_ERROR,
            ''
          );
        }
      });
  }
  openPreviousPopup() {
    this.dialogRef.close();
    let dialogRef = this.dialog.open(ReportFirstPageComponent, {
      width: '55%',
      disableClose: true,
      data: {
        currentSelectedSurvey: this.currentSelectedSurvey,
        memberId: this.memberId,
        json_report: this.json_report,
      },
    });
  }
  closePopUpWindows() {
    this.dialog.open(FinalReportFirstPageComponent, {
      width: '70%',
      disableClose: true,
      data: {
        memberId: this.memberId,
        currentSelectedSurvey: this.currentSelectedSurvey,
        json_report1: this.json_report,
      },
    });
  }
  notsharedata() {
    this.dialogRef.close();
    this.dialog.open(FinalReportFirstPageComponent, {
      width: '70%',
      disableClose: true,
      data: {
        memberId: this.memberId,
        currentSelectedSurvey: this.currentSelectedSurvey,
        json_report1: this.json_report,
      },
    });
  }
  ngOnDestroy() {
    if (this.dataSharedSubscription) {
      this.dataSharedSubscription.unsubscribe();
    }
  }
}
