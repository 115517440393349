import { MentorService } from './../../../../../service/mentor.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
  Optional,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  NgForm,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';
import * as moment from 'moment-timezone';
import { AccountsService } from './../../../../../service/accounts.service';
export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(4, 200, 400);
  }
}
@Component({
  selector: 'kt-your-endorse-discussion-list',
  templateUrl: './your-endorse-discussion-list.component.html',
  styleUrls: ['./your-endorse-discussion-list.component.scss'],
})
export class YourEndorseDiscussionListComponent implements OnInit, OnDestroy {
  guidesearchForm: UntypedFormGroup;

  minDate;
  disabled = true;
  maxDate = new Date();
  isFromDateNoted: boolean = false;
  reportData: any[];
  guidesdata = [];
  count: number;
  allDiscussionSubscription: Subscription;
  loggedSubscription: Subscription;
  searchSubscription: Subscription;
  summarySubscription: Subscription;
  memberDiscussionSubscription: Subscription;
  loggedInUser: number;
  indicator: number;
  mentorId: any;
  loggedinUserid: number;
  eventId: number;
  start_date: string;
  first_name: string;
  last_name: string;
  image_path: string;
  guideDiscussionData = [];
  registration_type: number;
  completed_mom_discussions: number;
  completed_guide_discussions: number;
  completed_diy_discussions: number;
  available_mom_discussions: number;
  available_guide_discussions: number;
  available_diy_discussions: number;
  scheduled_mom_discussions: number;
  scheduled_guide_discussions: number;
  scheduled_diy_discussions: number;
  requested_mom_discussions: number;
  requested_guide_discussions: number;
  requested_diy_discussions: number;
  selected_tab: string;
  isPastGuide: number;
  current_timezone: string;
  clicked_from: string;
  member_relationship_id: number;
  constructor(
    public dialogRef: MatDialogRef<YourEndorseDiscussionListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _cdref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private mentor_service: MentorService,
    private accountsService: AccountsService
  ) {
    this.mentorId = data.mentorId;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.image_path = data.image_path || '0';
    this.registration_type = data.registration_type;
    this.isPastGuide = data.isPastGuide;
    this.clicked_from = data.clicked_from;
    this.member_relationship_id = data.member_relationship_id;
  }

  ngOnInit(): void {
    this.loggedinUserid = this.accountsService.muser;
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.guidesearchForm = this.formBuilder.group({
      within_date_range: new UntypedFormControl(false),
      date_from: new UntypedFormControl(''),
      date_to: new UntypedFormControl(''),
      status_checked: new UntypedFormControl(false),
      status_option: new UntypedFormControl(''),
    });
    if (this.clicked_from === 'endorse_guide') {
      this.getAllGuideExplorerDiscussionList(
        this.member_relationship_id,
        this.loggedinUserid,
        this.mentorId
      );
      this.getDiscussionSummaryDetails(this.loggedinUserid, this.mentorId);
    }
    if (this.clicked_from === 'shared_space') {
      this.getAllGuideExplorerDiscussionList(
        this.member_relationship_id,
        this.mentorId,
        this.loggedinUserid
      );
      this.getDiscussionSummaryDetails(this.mentorId, this.loggedinUserid);
    }
    if (this.clicked_from === 'my_guides') {
      this.getLoggedInMemberDiscussionList(this.loggedinUserid);
      this.getLoggedInMemberDiscussionSummaryData(this.loggedinUserid);
    }
  }
  tabChanged(event) {
    this.selected_tab = event.tab.textLabel;
  }
  onChangeFrom(event) {
    this.minDate = event.value;
    this.guidesearchForm.patchValue({
      endDate: this.minDate,
    });
    this._cdref.detectChanges();
  }
  onChangeTo(event) {
    if (
      this.guidesearchForm.value.startDate === '' ||
      this.guidesearchForm.value.startDate === null
    ) {
      this.guidesearchForm.patchValue({
        startDate: '2020-01-01',
        // startDate: this.maxDate,
      });

      if (
        this.guidesearchForm.value.startDate >
        this.guidesearchForm.value.endDate
      ) {
        this.guidesearchForm.patchValue({
          // startDate: '2020-01-01',
          startDate: new Date(
            // '2020-01-01'
            new Date(this.guidesearchForm.value.endDate).setDate(
              new Date(this.guidesearchForm.value.endDate).getDate() - 1
            )
          ),
        });
      }
    }
    if (
      this.guidesearchForm.value.startDate > this.guidesearchForm.value.endDate
    ) {
      this.guidesearchForm.patchValue({
        startDate: new Date(
          new Date(this.guidesearchForm.value.endDate).setDate(
            new Date(this.guidesearchForm.value.endDate).getDate() - 1
          )
        ),
      });
    }
  }

  getAllGuideExplorerDiscussionList(member_relationship_id, guide, explorer) {
    this.allDiscussionSubscription = this.mentor_service
      .getExplorerGuideDiscussionList(member_relationship_id, guide, explorer)
      .subscribe((data) => {
        this.guideDiscussionData = data.results;
        this.count = data.count;
        this._cdref.detectChanges();
      });
  }
  getLoggedInMemberDiscussionList(logged_in_member) {
    this.loggedSubscription = this.mentor_service
      .getMyDiscussionListByGet(this.loggedinUserid, 1)
      .subscribe((data) => {
        this.guideDiscussionData = data.results;
        this.count = data.count;
        this.spinner.hide();
        this._cdref.detectChanges();
      });
  }

  searchFromGuideDiscussionForm(ngForm: NgForm) {
    this.guideDiscussionData = [];
    this.spinner.show();
    this.searchSubscription = this.mentor_service
      .getGuideDiscussionListByPost(this.mentorId, this.loggedinUserid, ngForm)
      .subscribe((data) => {
        this.guideDiscussionData = data.results;
        this.count = data.count ? data.count : 0;
        this.spinner.hide();
        this._cdref.detectChanges();
      });
  }
  returnImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }
  refreshFullList() {
    this.guidesearchForm.reset();
    if (this.clicked_from === 'endorse_guide') {
      this.getAllGuideExplorerDiscussionList(
        this.member_relationship_id,
        this.loggedinUserid,
        this.mentorId
      );
    }
    if (this.clicked_from === 'shared_space') {
      this.getAllGuideExplorerDiscussionList(
        this.member_relationship_id,
        this.mentorId,
        this.loggedinUserid
      );
    }
    if (this.clicked_from === 'my_guides') {
      this.getLoggedInMemberDiscussionList(this.loggedinUserid);
    }
  }
  event_type_textReturn(event_type) {
    if (event_type === 2548) {
      return 'Get Acquainted Discussion';
    } else if (event_type === 2733) {
      return 'Complimentary Discussion';
    } else if (event_type === 2549) {
      return 'Be My Guide Session';
    } else if (event_type === 2550) {
      return 'Guide Discussion';
    } else if (event_type === 2554) {
      return 'Map of Meaning Debrief';
    } else if (event_type === 2555) {
      return 'Mirror Reflection Discussion';
    } else {
      return 'DiY Discussion';
    }
  }
  event_status_textReturn(status) {
    if (status === 0) {
      return 'Requested';
    } else if (status === 1) {
      return 'Published';
    } else if (status === 2) {
      return 'Cancelled';
    } else if (status === 3) {
      return 'Scheduled';
    } else if (status === 4) {
      return 'Auth Cancelled';
    } else if (status === 5) {
      return 'Completed';
    } else if (status === 6) {
      return 'Marked Completed';
    } else {
      return 'Auth Completed';
    }
  }
  getDiscussionSummaryDetails(guide, explorer) {
    this.summarySubscription = this.mentor_service
      .getDiscussionSummaryData(this.member_relationship_id, guide, explorer)
      .subscribe((data) => {
        this.completed_mom_discussions =
          data.results['completed_guided_mom_discussion'];
        this.completed_guide_discussions =
          data.results['completed_general_discussions_time'];
        this.completed_diy_discussions =
          data.results['completed_guided_reflection_discussions'];
        this.available_mom_discussions =
          data.results['available_guided_mom_discussion'];
        this.available_guide_discussions =
          data.results['available_general_discussions_time'];
        this.available_diy_discussions =
          data.results['available_guided_reflection_discussions'];
        this.scheduled_mom_discussions =
          data.results['schedule_mom_discussion'];
        this.scheduled_guide_discussions =
          data.results['schedule_general_discussions_time'];
        this.scheduled_diy_discussions =
          data.results['schedule_guided_reflection_discussions'];
        this.requested_mom_discussions =
          data.results['requested_guided_mom_discussion'];
        this.requested_guide_discussions =
          data.results['requested_general_discussions_time'];
        this.requested_diy_discussions =
          data.results['requested_guided_reflection_discussions'];
        this._cdref.detectChanges();
      });
  }
  getLoggedInMemberDiscussionSummaryData(logged_in_member) {
    this.memberDiscussionSubscription = this.mentor_service
      .getLoggedInMemberDiscussionSummaryData(logged_in_member)
      .subscribe((data) => {
        this.completed_mom_discussions =
          data.results['completed_guided_mom_discussion'];
        this.completed_guide_discussions =
          data.results['completed_general_discussions_time'];
        this.completed_diy_discussions =
          data.results['completed_guided_reflection_discussions'];
        this.available_mom_discussions =
          data.results['available_guided_mom_discussion'];
        this.available_guide_discussions =
          data.results['available_general_discussions_time'];
        this.available_diy_discussions =
          data.results['available_guided_reflection_discussions'];
        this.scheduled_mom_discussions =
          data.results['schedule_mom_discussion'];
        this.scheduled_guide_discussions =
          data.results['schedule_general_discussions_time'];
        this.scheduled_diy_discussions =
          data.results['schedule_guided_reflection_discussions'];
        this.requested_mom_discussions =
          data.results['requested_guided_mom_discussion'];
        this.requested_guide_discussions =
          data.results['requested_general_discussions_time'];
        this.requested_diy_discussions =
          data.results['requested_guided_reflection_discussions'];
        this._cdref.detectChanges();
      });
  }

  onlyTimeTimeZone(startDateTime) {
    return moment(startDateTime).tz(this.current_timezone).format('h:mm a');
  }
  onlyDateTimeZone(startDateTime) {
    return moment(startDateTime).tz(this.current_timezone).format('MMM d, y');
  }
  ngOnDestroy() {
    if (this.allDiscussionSubscription) {
      this.allDiscussionSubscription.unsubscribe();
    }
    if (this.loggedSubscription) {
      this.loggedSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
    if (this.memberDiscussionSubscription) {
      this.memberDiscussionSubscription.unsubscribe();
    }
  }
}
