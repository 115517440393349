import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTabNav, MatTabsModule } from '@angular/material/tabs';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TagInputModule } from 'ngx-chips';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatNativeDateModule } from '@angular/material/core';

import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SetHostsPopUpComponent } from './set-hosts-pop-up/set-hosts-pop-up.component';
import { SetMessageToPupupComponent } from './set-message-to-pupup/set-message-to-pupup.component';

import { NgxSelectModule } from 'ngx-select-ex';
import { SearchDropdownComponent } from './search-dropdown/search-dropdown.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { SpinnerComponent } from './spinner/spinner.component';
import { TooltipHtmlComponent } from './tooltip-html/tooltip-html.component';
import { SetMembersPopUpComponent } from './set-members-pop-up/set-members-pop-up.component';

export const MyDefaultTooltipOptions: TooltipOptions = {
  'show-delay': 500
};

@NgModule({
  declarations: [
    SetHostsPopUpComponent,
    SetMessageToPupupComponent,
    SearchDropdownComponent,
    ClickOutsideDirective,
    SpinnerComponent,
    TooltipHtmlComponent,
    SetMembersPopUpComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatIconModule,
    MatSelectModule,
    MatListModule,
    MatButtonModule,
    MatToolbarModule,
    MatRadioModule,
    MatOptionModule,
    MatSelectModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatCheckboxModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    MatDatepickerModule,
    ScrollingModule,
    NgbModule,
    MatMenuModule,
    MatTabsModule,
    NgSelectModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    MatTreeModule,
    TagInputModule,
    MatProgressBarModule,
    NgxSummernoteModule,
    NgxMaterialTimepickerModule,
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
    MatAutocompleteModule,
    NgxUsefulSwiperModule,
    ClipboardModule,
    InfiniteScrollModule,
    NgxSelectModule,
    NgxSpinnerModule.forRoot({ type: 'ball-pulse' }),
    MatProgressSpinnerModule,
    MatNativeDateModule
  ],
  exports: [
    ClickOutsideDirective,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatIconModule,
    MatSelectModule,
    MatListModule,
    MatButtonModule,
    MatToolbarModule,
    MatRadioModule,
    MatOptionModule,
    MatSelectModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatCheckboxModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    ScrollingModule,
    NgbModule,
    MatMenuModule,
    MatTabsModule,
    NgSelectModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    MatTreeModule,
    TagInputModule,
    MatProgressBarModule,
    NgxSummernoteModule,
    NgxMaterialTimepickerModule,
    MatTabNav,
    TooltipModule,
    MatAutocompleteModule,
    NgxUsefulSwiperModule,
    ClipboardModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    SearchDropdownComponent,
    SpinnerComponent,
    TooltipHtmlComponent,
    SetMembersPopUpComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
