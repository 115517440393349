import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'kt-each-vedio',
  templateUrl: './each-vedio.component.html',
  styleUrls: ['./each-vedio.component.scss'],
})
export class EachVedioComponent implements OnInit {
  source: any;

  constructor(
    public dialogRef: MatDialogRef<EachVedioComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    this.source = this.sanitizer.bypassSecurityTrustResourceUrl(data.source);
  }

  ngOnInit(): void {}
}
