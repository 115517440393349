import { AdminPageService } from './../../../../service/admin-page.service';
import { CuratorPageService } from './../../../../service/curator-page.service';
import { SubscriptionService } from './../../../../service/subscription.service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MeaningNetworkService } from '../../../../service/meaning-network.service';
import { ActivatedRoute } from '@angular/router';
import { CustomValidators } from './../../../pages/login/register/custom-validators';
import { NotificationService } from '../../../../service/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../../../service/auth.service';
import { message_properties } from '../../../../../environments/message_properties';
import { UpdateAddressComponent } from './update-address/update-address.component';

import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  NgForm,
  UntypedFormBuilder,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AllPurchaseHistoryComponent } from './all-purchase-history/all-purchase-history.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Subscription } from 'rxjs';
import { AccountsService } from '../../../../service/accounts.service';

@Component({
  selector: 'kt-my-settings',
  templateUrl: './my-settings.component.html',
  styleUrls: ['./my-settings.component.scss'],
})
export class MySettingsComponent implements OnInit, OnDestroy {
  shwChgPwd = false;
  profile_path: any;
  memberId: string;
  memberid: any;
  profileData: {};
  full_name: any;
  first_name: string;
  last_name: string;
  city: string;
  country: string;
  state: string;
  line1: string;
  line2: string;
  postalcode: string;
  country_id: string;
  secondary_city: string;
  secondary_state: string;
  secondary_country: string;
  secondary_line1: string;
  secondary_line2: string;
  secondary_postalcode: string;
  secondary_country_id: string;
  secondary_city_id: string;
  secondary_state_code: string;
  topics = [];
  message: any;
  role: boolean = false;
  adminIndicator: boolean = false;
  changePasswordForm: UntypedFormGroup;
  whaticareAboutForm: UntypedFormGroup;
  change_timezoneForm: UntypedFormGroup;
  deleteUserForm: UntypedFormGroup;
  mentor_indicator: boolean;
  topic_count: number;
  purchase_item_list = [];
  purchased_total_amount: number;
  purcahse_item_count: number;
  timeZoneArr: [];

  purcahse_history: boolean = false;
  purcahse_history2: boolean = false;
  purcahse_history3: boolean = false;
  purcahse_history4: boolean = false;
  purcahse_history5: boolean = false;
  purcahse_history6: boolean = false;
  confirmPassword1: boolean = false;
  confirmPassword2: boolean = false;
  confirmPassword3: boolean = false;
  passwordEntered: boolean = false;
  memberAddressData = [];
  gdpr_type: boolean = false;
  gdpr_type_value: number = 0;
  loggedInUser: number;
  subscription: Subscription;
  logoutSubscription: Subscription;
  addressSaveSubscription: Subscription;
  changeTimeSubscription: Subscription;
  memberTimeSubscription: Subscription;
  timeSubscription: Subscription;
  purchaseSubscription: Subscription;
  passwordSubscription: Subscription;
  whatCareSubscription: Subscription;
  profileSubscription: Subscription;
  my_address =
    'This address is not visible to other Explorers. It will be used as the billing address if you decide to purchase offerings.';
  constructor(
    private meaning_network_service: MeaningNetworkService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private toastr: NotificationService,
    private spinner: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    private curatorService: CuratorPageService,
    private adminService: AdminPageService,
    private dialog: MatDialog,
    private accountService: AccountsService
  ) {
    this.changePasswordForm = this.formBuilder.group(
      {
        password: new UntypedFormControl(
          '',
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true,
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true,
              }
            ),
            Validators.minLength(8),
          ])
        ),
        confirmpassword: new UntypedFormControl(
          '',
          Validators.compose([Validators.required])
        ),
        currentPassword: new UntypedFormControl(
          '',
          Validators.compose([Validators.required])
        ),
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator,
      }
    );
  }

  ngOnInit(): void {
    this.role = this.accountService.role === '2223' ? true : false;
    this.loggedInUser = this.accountService.muser;
    this.adminIndicator = this.accountService.admin_indicator ? true : false;
    this.findMemberId();
    if (this.memberId) {
      this.memberid = this.memberId;
    } else {
      this.memberid = this.accountService.muser;
    }
    this.change_timezoneForm = this.formBuilder.group({
      time_zone_id: new UntypedFormControl('', [Validators.required]),
      logged_in_member_id: new UntypedFormControl(this.memberid),
    });
    this.whaticareAboutForm = new UntypedFormGroup({
      matter1: new UntypedFormControl('', [Validators.maxLength(10)]),
      matter2: new UntypedFormControl('', [Validators.maxLength(10)]),
      matter3: new UntypedFormControl('', [Validators.maxLength(10)]),
      matter4: new UntypedFormControl('', [Validators.maxLength(10)]),
      matter5: new UntypedFormControl('', [Validators.maxLength(10)]),
      matter6: new UntypedFormControl('', [Validators.maxLength(10)]),
      property_id: new UntypedFormControl(2354),
      check_value: new UntypedFormControl(9),
      member: new UntypedFormControl(this.memberid),
      tenant: new UntypedFormControl(1),
    });
    this.change_timezoneForm = this.formBuilder.group({
      time_zone_id: new UntypedFormControl('', [Validators.required]),
      logged_in_member_id: new UntypedFormControl(this.memberid),
    });
    this.deleteUserForm = this.formBuilder.group({
      member: new UntypedFormControl(this.loggedInUser),
      gdpr_type: new UntypedFormControl(this.gdpr_type),
      gdpr_type_value: new UntypedFormControl(this.gdpr_type_value),
      logged_in_member: new UntypedFormControl(this.loggedInUser),
    });

    this.meaning_network_service.refreshSecondaryAddress.subscribe((data) => {
      this.getSavedAddress();
    });
    this.getProfileWithAddress();
    this.getWhatICareAbout();
    this.myPurchaseItemsList();
    this.allTimeZoneList();
    this.getMemberTimezone();
    this.getSavedAddress();
  }
  async findMemberId() {
    await this.route.queryParams.subscribe((params) => {
      return (this.memberId = params['member']);
    });
  }
  loadEditPwd() {
    this.shwChgPwd = !this.shwChgPwd;
  }
  getProfileWithAddress() {
    this.profileSubscription = this.meaning_network_service
      .getProfileDetails(this.memberid)
      .subscribe((data) => {
        this.city = data.city;
        this.country = data.country;
        this.mentor_indicator = Boolean(data.mentor_indicator);
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.full_name = data.full_name;
        this.profile_path = data.profile_path
          ? data.profile_path
          : './assets/media/users/default.png';
        localStorage.setItem('ufullname', btoa(this.full_name));
        localStorage.setItem(
          'image',
          data.profile_path !== null ? data.profile_path : '0'
        );
        this.cdRef.detectChanges();
      });
  }
  getWhatICareAbout() {
    this.whatCareSubscription = this.meaning_network_service
      .getWhatICareAbout(this.memberid)
      .subscribe((data) => {
        this.topic_count = data.count;
        this.topics = data.results;
        var topicPatch = {};
        for (var i = 0; i < data.count; i++) {
          topicPatch['matter' + (i + 1)] = this.topics[i].topic;
        }
        this.whaticareAboutForm.patchValue(topicPatch);
      });
  }
  passwordChangeEntered(ev) {
    if (ev.target.value.length > 0) {
      this.passwordEntered = true;
    } else {
      this.passwordEntered = false;
    }
  }

  changePasswordSubmit(ngForm: NgForm) {
    this.spinner.show();
    return (this.passwordSubscription = this.accountService
      .passwordChange(ngForm, this.accountService.user_id)
      .subscribe(
        (res) => {
          if (res.code === 200) {
            this.spinner.hide();
            this.changePasswordForm.reset();
            this.toastr.showSuccess(message_properties.MY_SETTINGS_SUCCESS, '');
          } else if (res.code === 404) {
            this.spinner.hide();
            this.toastr.showError(res.message, '');
          } else {
            this.toastr.showError(message_properties.MY_SETTINGS_ERROR, '');
            this.message = '';
          }
          this.spinner.hide();
        },
        (error) => {
          this.toastr.showError(message_properties.MY_SETTINGS_ERROR1, '');
        }
      ));
  }

  myPurchaseItemsList() {
    this.purchaseSubscription = this.subscriptionService
      .myAllTransactionList(this.memberid)
      .subscribe((data) => {
        this.purchase_item_list = data.results;
        this.purcahse_item_count = data.count;
        this.cdRef.detectChanges();
      });
  }

  allTimeZoneList() {
    this.timeSubscription = this.curatorService
      .getAllLookupValueInDropDown(12440)
      .subscribe((data) => {
        this.timeZoneArr = data.results;
        this.cdRef.detectChanges();
      });
  }
  getMemberTimezone() {
    this.memberTimeSubscription = this.adminService
      .getMemberTimezone(this.memberid)
      .subscribe((data) => {
        this.cdRef.detectChanges();
        this.change_timezoneForm.patchValue({
          time_zone_id: [data.current_timezone],
        });
      });
  }
  changeTimeZone(ngForm: NgForm) {
    this.changeTimeSubscription = this.meaning_network_service
      .memberTimeZoneUpdate(ngForm)
      .subscribe((data) => {
        if (data.status === 201) {
          localStorage.setItem('current_time_zone', data.current_time_zone);
          this.toastr.showSuccess('Timezone Changed Successfully!', '');
        } else {
          this.toastr.showSuccess(
            'Some Technical Issue seems here,Contact to administrator',
            ''
          );
        }
      });
  }
  togglePurchase() {
    this.purcahse_history = !this.purcahse_history;
    this.purcahse_history2 = false;
    this.purcahse_history3 = false;
    this.purcahse_history4 = false;
    this.purcahse_history5 = false;
    this.purcahse_history6 = false;
  }
  togglePurchase2() {
    this.purcahse_history2 = !this.purcahse_history2;
    this.purcahse_history = false;
    this.purcahse_history3 = false;
    this.purcahse_history4 = false;
    this.purcahse_history5 = false;
    this.purcahse_history6 = false;
  }
  togglePurchase6() {
    this.purcahse_history6 = !this.purcahse_history6;
    this.purcahse_history2 = false;
    this.purcahse_history = false;
    this.purcahse_history3 = false;
    this.purcahse_history4 = false;
    this.purcahse_history5 = false;
  }
  togglePurchase3() {
    this.purcahse_history3 = !this.purcahse_history3;
    this.purcahse_history = false;
    this.purcahse_history2 = false;
    this.purcahse_history4 = false;
    this.purcahse_history5 = false;
    this.purcahse_history6 = false;
  }
  togglePurchase4() {
    this.purcahse_history4 = !this.purcahse_history4;
    this.purcahse_history = false;
    this.purcahse_history2 = false;
    this.purcahse_history3 = false;
    this.purcahse_history5 = false;
    this.purcahse_history6 = false;
  }
  togglePurchase5() {
    this.purcahse_history5 = !this.purcahse_history5;
    this.purcahse_history = false;
    this.purcahse_history2 = false;
    this.purcahse_history3 = false;
    this.purcahse_history4 = false;
    this.purcahse_history6 = false;
  }
  getSavedAddress() {
    this.addressSaveSubscription = this.meaning_network_service
      .getProfileNameWithSecondaryAddress(this.memberid)
      .subscribe((data) => {
        this.secondary_city = data.city;
        this.secondary_city_id = data.city_id;
        this.secondary_state = data.state;
        this.secondary_country = data.country;
        this.secondary_line1 = data.line1;
        this.secondary_line2 = data.line2;
        this.secondary_postalcode = data.postal_code;
        this.secondary_country_id = data.country_id;
        this.secondary_state_code = data.state_code
          ? `(${data.state_code})`
          : '';
        this.cdRef.detectChanges();
      });
  }
  editAddress() {
    const dialogRef = this.dialog.open(UpdateAddressComponent, {
      width: '50%',
      disableClose: true,
      data: {
        memberId: this.memberid,
        secondary_city: this.secondary_city,
        secondary_state: this.secondary_state,
        secondary_country: this.secondary_country,
        secondary_line1: this.secondary_line1,
        secondary_line2: this.secondary_line2,
        secondary_postalcode: this.secondary_postalcode,
        secondary_country_id: this.secondary_country_id,
        secondary_city_id: this.secondary_city_id,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getSavedAddress();
      this.getMemberTimezone();
    });
  }
  OpenPurchases() {
    const dialogRef = this.dialog.open(AllPurchaseHistoryComponent, {
      width: '50%',
      disableClose: true,
      data: {
        purchase_item_list: this.purchase_item_list,
        purcahse_item_count: this.purcahse_item_count,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getSavedAddress();
      this.getMemberTimezone();
    });
  }
  deleteYourAccount(ngForm: NgForm) {
    let gdpr_text = null;
    if (this.gdpr_type && !this.checkRole()) {
      gdpr_text =
        'By deleting your account and personal data:<br/>- you will lose any unused purchased services on your account<br/>- your Portrait, Reflections, Surveys, and other personal work will be deleted<br/>- your posts and comments will become anonymized and there will be no reference to you on MeaningSphere<br/><br/>Are you sure you want to delete your account and personal information?';
    } else if (this.gdpr_type && this.checkRole()) {
      gdpr_text =
        'By deleting your account and personal data:<br/>- you will lose any unused purchased services on your account<br/>- your Portrait, Reflections, Surveys, and other personal work will be deleted<br/>- activities where you served in an official role or capacity for MeaningSphere will be retained<br/>- all personal data, except name, will be deleted from the platform<br/>- your name will be retained as you have acted in an official role or capacity for MeaningSphere<br/>Are you sure you want to delete your account and personal information?';
    } else {
      gdpr_text =
        'By deleting your account, you will lose any unused purchased services on your account. Your Portrait, Reflections, Surveys, and other personal work will be deleted. <br/><br/>Are you sure you want to delete your account?';
    }
    Swal.fire({
      title: 'Please confirm!',
      html: gdpr_text,
      icon: 'warning',
      customClass: 'DeleteAccount',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.subscription = this.adminService
          .deletePermanentUserAllData(ngForm)
          .subscribe((data) => {
            if (data.status === 200) {
              this.spinner.hide();
              this.logoutusers();
            } else {
              this.spinner.hide();
              this.toastr.showError(data.message, '');
            }
            (error) => {
              this.spinner.hide();
              this.toastr.showError(data.message, '');
            };
          });
      }
    });
  }
  checkGdpr($event) {
    if ($event.target['checked']) {
      this.gdpr_type = true;
      this.gdpr_type_value = 1;
    } else {
      this.gdpr_type = false;
      this.gdpr_type_value = 0;
    }
    this.deleteUserForm.patchValue({
      gdpr_type: this.gdpr_type,
      gdpr_type_value: this.gdpr_type_value,
    });
  }
  logoutusers() {
    const postData = {
      refresh: this.accountService.refresh_token,
      token: this.accountService.token,
    };
    this.logoutSubscription = this.accountService
      .logoutuser(postData)
      .subscribe((data) => {
        localStorage.removeItem('token_refresh_data');
        localStorage.clear();
        this.cdRef.detectChanges();
        window.location.href = '/';
      });
  }
  redirectUrl(link_url) {
    if (link_url) {
      return window.open(
        link_url,
        'popUpWindow',
        'height=this.height*.8,width=this.width*.8, left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes'
      );
    } else {
      this.toastr.showWarning(message_properties.INVOICE_NOT_GENERATE, '');
    }
  }

  checkRole(): boolean {
    const admin_indicator = this.accountService.admin_indicator;
    const curator_indicator = this.accountService.curator_indicator;
    const mentor_indicator = this.accountService.mentor_indicator;
    const program_director_indicator = this.accountService
      .program_director_indicator;

    const past_mentor_indicator = this.accountService.past_mentor_indicator;
    const past_admin_indicator = this.accountService.past_admin_indicator;
    const past_curator_indicator = this.accountService.past_curator_indicator;
    const past_program_director_indicator = this.accountService
      .past_program_director_indicator;
    if (
      admin_indicator ||
      curator_indicator ||
      mentor_indicator ||
      program_director_indicator ||
      past_mentor_indicator ||
      past_admin_indicator ||
      past_curator_indicator ||
      past_program_director_indicator
    ) {
      return true;
    }
    return false;
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
    if (this.addressSaveSubscription) {
      this.addressSaveSubscription.unsubscribe();
    }
    if (this.changeTimeSubscription) {
      this.changeTimeSubscription.unsubscribe();
    }
    if (this.memberTimeSubscription) {
      this.memberTimeSubscription.unsubscribe();
    }
    if (this.timeSubscription) {
      this.timeSubscription.unsubscribe();
    }
    if (this.purchaseSubscription) {
      this.purchaseSubscription.unsubscribe();
    }
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    if (this.whatCareSubscription) {
      this.whatCareSubscription.unsubscribe();
    }
    if (this.passwordSubscription) {
      this.passwordSubscription.unsubscribe();
    }
  }
}
