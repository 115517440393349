import { MySettingsComponent } from './my-settings/my-settings.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouteGuard } from '../../../guard/route.guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MeaningCentralProfileComponent } from './meaning-central-profile/meaning-central-profile.component';
import { SearchExplorersComponent } from './search-explorers/search-explorers.component';
import { MeaningLogComponent } from './meaning-log/meaning-log.component';
import { MatMenuModule } from '@angular/material/menu';
import { MeaningFullWorkExperienceFormProfileComponent } from './meaning-full-work-experience-form-profile/meaning-full-work-experience-form-profile.component';
import { VolunteerExperianceFormProfileComponent } from './volunteer-experiance-form-profile/volunteer-experiance-form-profile.component';
import { EducationFormProfileComponent } from './education-form-profile/education-form-profile.component';
import { MyTreasuresComponent } from './my-treasures/my-treasures.component';
import { MeaningLogAndTreasurePopupComponent } from './meaning-log-and-treasure-popup/meaning-log-and-treasure-popup.component';

import { MyBookmarksComponent } from './my-bookmarks/my-bookmarks.component';
import { AddMeaningLogComponent } from './add-meaning-log/add-meaning-log.component';

import { RouterModule, CanActivate, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { UpdateAddressComponent } from './my-settings/update-address/update-address.component';
import { AllPurchaseHistoryComponent } from './my-settings/all-purchase-history/all-purchase-history.component';

export const DateFormats = {
  parse: {
    dateInput: ['YYYY-MM-DD'],
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [
    MeaningCentralProfileComponent,
    MeaningLogComponent,
    SearchExplorersComponent,
    MeaningFullWorkExperienceFormProfileComponent,
    VolunteerExperianceFormProfileComponent,
    EducationFormProfileComponent,
    MyTreasuresComponent,
    MySettingsComponent,
    MeaningLogAndTreasurePopupComponent,
    MyBookmarksComponent,
    AddMeaningLogComponent,
    UpdateAddressComponent,
    AllPurchaseHistoryComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    NgbModule,
    NgSelectModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: 'my-profile',
        component: MeaningCentralProfileComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'settings',
        component: MySettingsComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'meaning-moments',
        component: MeaningLogComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'my-treasures',
        component: MyTreasuresComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'my-bookmarks',
        component: MyBookmarksComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'search-explorers',
        component: SearchExplorersComponent,
        canActivate: [RouteGuard],
      },
    ]),
  ],
  providers: [DatePipe],
})
export class MeaningNetworkModule { }
