import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { MentorService } from '../../../../../service/mentor.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountsService } from './../../../../../service/accounts.service';

@Component({
  selector: 'kt-past-my-guide',
  templateUrl: './past-my-guide.component.html',
  styleUrls: ['./past-my-guide.component.scss'],
})
export class PastMyGuideComponent implements OnInit, OnDestroy {
  pastGuides = [];
  past_guide_count: number;
  pastGuideForm: UntypedFormGroup;
  loggedinUserid: number;
  subscription: Subscription;
  page: number = 2;
  throttle = 300;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;
  constructor(
    private route: Router,
    private _cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private mentorService: MentorService,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedinUserid = this.accountService.muser;
    this.pastGuideForm = this.formBuilder.group({
      member: new UntypedFormControl(this.loggedinUserid),
      active: new UntypedFormControl(0),
      page: 1,
    });
    this.searchPastGuide();
  }
  pushValuePast(ev?) {
    if (this.past_guide_count !== this.pastGuides.length) {
      this.pastGuideForm.patchValue({
        page: this.page,
      });
      this.searchPastGuide();
      this.page++;
    }
  }
  searchPastGuide(params = 0) {
    if (params === 1) {
      this.pastGuides = [];
      this.page = 2;
      this.pastGuideForm.patchValue({
        page: 1,
      });
    }
    const Formdata = this.pastGuideForm.value;
    this.spinner.show();
    this.subscription = this.mentorService
      .postPastMentors(Formdata)
      .subscribe((data) => {
        this.pastGuides = [
          ...new Map(
            this.pastGuides
              .concat(data.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.past_guide_count = data.count;
        this._cdr.detectChanges();
        this.spinner.hide();
      });
  }
  returnImage(image) {
    if (image) {
      return image;
    } else {
      return 'assets/media/users/default.png';
    }
  }

  viewSharedSpace(mentor, isPastGuide, member_status) {
    const mentorId = mentor?.relating_member_profile[0].id;
    const first_name = mentor?.relating_member_profile[0].first_name;
    const last_name = mentor?.relating_member_profile[0].last_name;
    const image_path = mentor?.relating_member_profile[0].picture_path
      ? mentor?.relating_member_profile[0].picture_path
      : './assets/media/users/default.png';
    const city = mentor?.member_address[0].city
      ? mentor?.member_address[0].city
      : '----';
    const country = mentor?.member_address[0].country
      ? mentor?.member_address[0].country
      : '----';
    const start_date = mentor?.start_date;
    const end_date = mentor?.end_date;
    const relationshipId = mentor?.id;
    const requested_from =
      mentor?.member_connect_arr.requested_from !== 0
        ? mentor?.member_connect_arr.requested_from
        : this.loggedinUserid;
    const connection_status = mentor?.member_connect_arr.connection_status;
    const requested_to =
      mentor?.member_connect_arr.requested_to !== 0
        ? mentor?.member_connect_arr.requested_to
        : mentor?.relating_member_profile[0].id;
    const connection_count = mentor?.member_connect_arr.connection_count;
    const helper = new JwtHelperService();
    const jit_from_current_user = helper.decodeToken(this.accountService.token)
      .jti;
    const param_string = `${mentorId}|${first_name}|${last_name}|${image_path}|${city}|${country}|${start_date}|${relationshipId}|${isPastGuide}|${requested_from}|${connection_status}|${requested_to}|${connection_count}|${jit_from_current_user}|${member_status}|${end_date}`;
    const param_data = window.btoa(unescape(encodeURIComponent(param_string)));
    this.route.navigate(['discover', 'my-guide', 'shared-space', param_data]);
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
