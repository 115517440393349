import { NotificationService } from '../../../../service/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { MentorService } from '../../../../service/mentor.service';
import { message_properties } from '../../../../../environments/message_properties';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-send-intro-session-request',
  templateUrl: './send-intro-session-request.component.html',
  styleUrls: ['./send-intro-session-request.component.scss'],
})
export class SendIntroSessionRequestComponent implements OnInit, OnDestroy {
  sendRequestForm: UntypedFormGroup;
  mentorId: number;
  fullName: string;
  picPath: string;
  sendRequestSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<SendIntroSessionRequestComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private mentorService: MentorService,
    private toastr: NotificationService,
    private accountService: AccountsService
  ) {
    this.mentorId = data.mentorId;
    this.fullName = data.fullName;
    this.picPath = data.picPath;
  }

  ngOnInit(): void {
    this.sendRequestForm = this.formBuilder.group({
      description: new UntypedFormControl('', [Validators.required]),

      creating_user: this.accountService.muser,
      tenant: 1,
      context: '',
      company: '',
      collab_content_category: 2461,

      wiki_type: '',
      collab_content_type: 2684,
      collab_content_status: 1324,
      faq_for: '',
      forum_item_type: '',
      assessment_id: '',
      no_of_views: 0,
      no_of_likes: 0,
      visible_to_all: 1,
      title: 'Get Acquainted Request',
      additional_info: '',
      references: '',
      attachment_path: '',
      parent_id: '',
      creation_date: '',
      suggesting_user: this.mentorId,
      active: 6,
      content: 'isr',
    });
  }
  sendRequest(ngForm) {
    this.sendRequestSubscription = this.mentorService
      .createCollabContent(ngForm.value)
      .subscribe(
        (success) => {
          if (success.status !== 208) {
            this.dialogRef.close();
            this.toastr.showSuccess(
              message_properties.REQUEST_INTRO_SESSION_SUCCESS,
              ''
            );
          } else {
            this.dialogRef.close();
            this.toastr.showError(
              message_properties.REQUEST_INTRO_SESSION_ERROR,
              ' '
            );
          }
        },
        (error) => {
          this.toastr.showError(
            message_properties.REQUEST_INTRO_SESSION_ERROR1,
            ''
          );
        }
      );
  }
  ngOnDestroy(): void {
    if (this.sendRequestSubscription) {
      this.sendRequestSubscription.unsubscribe();
    }
  }
}
