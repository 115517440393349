import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../service/auth.service';
import { NotificationService } from '../service/notification.service';
import { AccountsService } from './../service/accounts.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(
    private authService: AccountsService,
    private router: Router,
    private toastr: NotificationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.authService.loggedIn) {
      if (this.authService.admin_indicator) {
        return true;
      } else {
        this.toastr.showError(
          'You do not have privileges to access this functionality!',
          'Looks like you are trying to access a functionality which requires different privilege level. If this happens repeatedly, contact your System Administrator.'
        );
        this.router.navigate(['/home']);
        return false;
      }
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }
}
