import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { AccountsService } from './../service/accounts.service';

@Injectable()
export class HeaderInformationInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let authService = this.injector.get(AccountsService);
    const jwt = authService.token;
    const authReq = request.clone({
      headers: new HttpHeaders({
        authtoken: `Bearer ${jwt}`,
        user_details: 'raghvendra',
        web_information: 'test',
      }),
    });
    return next.handle(authReq);
  }
}
