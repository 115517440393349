<kt-spinner></kt-spinner>
<div class="profile_section meaningCentralProfil">
  <div class="row">
    <div class="col-sm-12 col-lg-3">
      <!-- Profile User Data -->
      <div class="profile_card back_card_background_clr profile_card-top">
        <div class="edit_section positnAbslt">
          <a class="mb-4">
            <table border="0" width="100%">
              <tr>
                <td align="left"></td>
                <td align="right"></td>
              </tr>
            </table>
          </a>
        </div>
        <div class="dis-prof i-pad-sect-upload d-flex flex-direction-column align-items-center">
          <label for="file" class="i-pad-sect-label w-250-pp" style="position: relative">
            <img alt="Pic" class="w-250-pp" [src]="
                profile_path ? profile_path : './assets/media/users/default.png'
              " />
          </label>
          <div class="text-align-center w-100">
            <h1>{{ first_name }}</h1>
            <h2>{{ last_name }}</h2>
            <hr class="hr-5" />
            <h3 class="loc">{{ city }}, {{ country }}</h3>
          </div>
        </div>
      </div>
      <!-- What Matters To You -->
      <div class="profile_card back_card_background_clr">
        <div class="edit_section">
          <span class="mb-4">
            <table border="0" width="100%">
              <tr>
                <td align="left">
                  <h1 class="fs-20">What Matters To Me</h1>
                </td>
              </tr>
            </table>
          </span>
          <hr />
          <div class="multi-collapse" id="multiCollapseExample7" *ngIf="!shwChgPwd">
            <div class="boxData">
              <div class="content">
                <ul *ngIf="topic_count > 0">
                  <li *ngFor="let topic of topics">
                    {{ topic.alternate_name }}
                  </li>
                </ul>
                <p *ngIf="topic_count == 0">You have not added any topics!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-9">
      <div class="profile_card back_card_background_clr">
        <div class="row">
          <div class="col">
            <h1 class="cmn_title_top">My Settings</h1>
          </div>
        </div>
        <hr />
        <div class="settingsScroll scroll_class_list">
          <!-- Change Address -->
          <div class="row settingBox">
            <div class="col">
              <table border="0" width="100%">
                <tr style="color: #343a5e">
                  <td align="left">
                    <h1 class="fs-20">
                      My Address
                      <span tooltipClass="tooltip_design" theme="light" tooltip="{{ my_address }}" placement="right"
                        showDelay="500" animation="fade" noArrow="false">
                        <span class="icon-info">
                          <mat-icon class="info_material_icon">info</mat-icon>
                        </span>
                      </span>
                    </h1>
                  </td>
                  <td align="right">
                    <button (click)="togglePurchase6()" class="arrow" [ngClass]="{ active: purcahse_history6 }">
                      <img src="./assets/media/icon/Arrow_Black@2x.png" alt="" />
                    </button>
                  </td>
                </tr>
              </table>
              <hr />
              <div class="multi-collapse" id="multiCollapseExample1" *ngIf="purcahse_history6">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-6" *ngIf="secondary_country">
                      {{ secondary_line1 ? secondary_line1 + ", " : "" }}{{ secondary_line2 ? secondary_line2 + ", " :
                      ""}} <br />
                      {{ secondary_city ? secondary_city + ", " : "" }}
                      {{ secondary_state ? secondary_state+secondary_state_code + ", " : "" }}<br />
                      {{ secondary_country ? secondary_country + " " : "" }} {{ secondary_postalcode }}
                    </div>
                    <div class="col-sm-6" *ngIf="!secondary_country">
                      <p>No data found!</p>
                    </div>

                    <div class="col-sm-6 justify-content-end d-flex">
                      <span class="profile-edit-icn-pop" (click)="editAddress()" style="
                          display: block;
                          width: 20px;
                          margin-right: 10px;
                          cursor: pointer;
                        ">
                        <mat-icon style="cursor: pointer" title="Edit">edit</mat-icon>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- My Purchase  History -->
          <div class="row settingBox">
            <div class="col">
              <table border="0" width="100%">
                <tr style="color: #343a5e">
                  <td align="left">
                    <h1 class="fs-20">Purchase History</h1>
                  </td>
                  <td align="right">
                    <button (click)="togglePurchase()" class="arrow" [ngClass]="{ active: purcahse_history }">
                      <img src="./assets/media/icon/Arrow_Black@2x.png" alt="" />
                    </button>
                  </td>
                </tr>
              </table>
              <hr />
              <div class="multi-collapse" id="multiCollapseExample1" *ngIf="purcahse_history">
                <table class="table">
                  <tr>
                    <th>Purchase Date</th>
                    <th>Item(s) Purchased</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                  <ng-container *ngIf="purcahse_item_count > 0">
                    <tr *ngFor="let itemdata of purchase_item_list;
                    let i = index;">
                    <ng-container  *ngIf="i < 3">
                      <td>{{ itemdata?.transaction_time | date: "d MMM y" }}</td>
                      <td>
                        {{ itemdata?.transaction_item_details[0]['item_name'] }}
                          <small *ngIf="itemdata.transaction_item_count>0">
                            + {{itemdata?.transaction_item_count}} Items
                          </small>
                      </td>
                      <td>
                        US ${{
                          itemdata.transaction_total_amount | number: "1.2-2"
                          }}
                      </td>
                      <td><a (click)="redirectUrl(itemdata.invoice_link)" style="cursor: pointer">View Invoice</a></td>
                    </ng-container>
                    </tr>
                    <tr>
                      <td align="right" colspan="4">
                        <div class="d-flex justify-content-end" *ngIf="purcahse_item_count > 3">
                          <button class="btn btn-quiz" (click)="OpenPurchases()">
                            View All
                          </button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="purcahse_item_count == 0">
                    <tr>
                      <td colspan="4"><p>No data found!</p></td>
                    </tr>
                    
                  </ng-container>
                </table>
                <!-- <ng-container>
                  <ul class="table-header">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                  <hr />
                  <cdk-virtual-scroll-viewport itemSize="0" minBufferPx="200" maxBufferPx="400"
                    class="scroll_class_list scroll-gap" style="height: 130px !important">
                    <ng-container *cdkVirtualFor="
                        let itemdata of purchase_item_list;
                        let i = index;
                        templateCacheSize: 0
                      ">
                      <ul class="table-body" *ngIf="i < 3">
                        <li class="item">
                          
                        </li>
                        <li class="item">
                          
                        </li>
                        <li class="item">
                          
                        </li>
                        <li class="item">
                          
                        </li>
                      </ul>
                    </ng-container>

                  </cdk-virtual-scroll-viewport>
                  <div class="d-flex justify-content-end" *ngIf="purcahse_item_count > 3">
                    <button class="btn btn-quiz" (click)="OpenPurchases()">
                      View All
                    </button>
                  </div>
                </ng-container>
                 -->
              </div>
            </div>
          </div>
          <!-- end my purchase history-->
          <!-- Change Password -->
          <div class="row settingBox">
            <div class="col">
              <table border="0" width="100%">
                <tr style="color: #343a5e">
                  <td align="left">
                    <h1 class="fs-20">Change Password</h1>
                  </td>
                  <td align="right">
                    <button (click)="togglePurchase2()" class="arrow" [ngClass]="{ active: purcahse_history2 }">
                      <img src="./assets/media/icon/Arrow_Black@2x.png" alt="" />
                    </button>
                  </td>
                </tr>
              </table>
              <hr />
              <div class="multi-collapse" id="multiCollapseExample1" *ngIf="purcahse_history2">
                <div class="card-body">
                  <form [formGroup]="changePasswordForm" (ngSubmit)="changePasswordSubmit(changePasswordForm.value)"
                    novalidate>
                    <div class="row">
                      <div class="col-sm-6">
                        <mat-form-field>
                          <mat-label>Current Password</mat-label>
                          <input [type]="confirmPassword1 ? 'text' : 'password'" matInput autocomplete="off"
                            formControlName="currentPassword" />
                          <img class="eyeicon" (click)="confirmPassword1 = !confirmPassword1" *ngIf="!confirmPassword1"
                            src="../../../../assets/media/visible.png" />
                          <img class="eyeicon" (click)="confirmPassword1 = !confirmPassword1" *ngIf="confirmPassword1"
                            src="../../../../assets/media/invisible.png" />
                        </mat-form-field>
                        <mat-form-field>
                          <mat-label>New Password</mat-label>
                          <input [type]="confirmPassword2 ? 'text' : 'password'" matInput autocomplete="off"
                            formControlName="password" />
                          <img class="eyeicon" (click)="confirmPassword2 = !confirmPassword2" *ngIf="!confirmPassword2"
                            src="../../../../assets/media/visible.png" />
                          <img class="eyeicon" (click)="confirmPassword2 = !confirmPassword2" *ngIf="confirmPassword2"
                            src="../../../../assets/media/invisible.png" />
                        </mat-form-field>

                        <mat-form-field>
                          <mat-label>Confirm Password</mat-label>
                          <input [type]="confirmPassword3 ? 'text' : 'password'" matInput autocomplete="off" (input)="passwordChangeEntered($event)"
                            formControlName="confirmpassword" />
                          <img class="eyeicon" (click)="confirmPassword3 = !confirmPassword3" *ngIf="!confirmPassword3"
                            src="../../../../assets/media/visible.png" />
                          <img class="eyeicon" (click)="confirmPassword3 = !confirmPassword3" *ngIf="confirmPassword3"
                            src="../../../../assets/media/invisible.png" />
                        </mat-form-field>

                        <label class="text-danger" *ngIf="passwordEntered &&
                            changePasswordForm.controls[
                              'confirmpassword'
                            ].hasError('NoPassswordMatch')
                          ">
                          Password do not match
                        </label>
                      </div>
                      <div class="col-sm-6 mt-4">
                        Note: Password should have at least 8 characters with 1
                        numeric, 1 upper case, 1 lower case and 1 special
                        character in it.
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <button [disabled]="!changePasswordForm.valid" class="btn begin_quiz"
                          style="float: right; margin-top: 0px">
                          Change Password
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- My Time Zone -->
          <div class="row settingBox">
            <div class="col">
              <table border="0" width="100%">
                <tr style="color: #343a5e">
                  <td align="left">
                    <h1 class="fs-20">My Time Zone</h1>
                  </td>
                  <td align="right">
                    <button (click)="togglePurchase3()" class="arrow" [ngClass]="{ active: purcahse_history3 }">
                      <img src="./assets/media/icon/Arrow_Black@2x.png" alt="" />
                    </button>
                  </td>
                </tr>
              </table>
              <hr />
              <!-- experiance-scroll -->
              <div class="multi-collapse" id="multiCollapseExample7" *ngIf="purcahse_history3">
                <form [formGroup]="change_timezoneForm" (ngSubmit)="changeTimeZone(change_timezoneForm.value)"
                  novalidate>
                  <div class="boxData">
                    <div class="content">
                      <div class="row">
                        <div class="col-sm-6">
                          <ng-select [dropdownPosition]="'bottom'" formControlName="time_zone_id" multiple="true"
                            maxSelectedItems="1">
                            <ng-option [value]="timezone.id" *ngFor="let timezone of timeZoneArr">
                              {{ timezone.lookup_value }}
                            </ng-option>
                          </ng-select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <label>This will show all date / time in the selected time
                            zone.</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <button [disabled]="!change_timezoneForm.valid" class="btn begin_quiz my-2"
                            style="float: right">
                            Change Time zone
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Delete My Account -->
          <div class="row settingBox">
            <div class="col">
              <table border="0" width="100%">
                <tr style="color: #343a5e">
                  <td align="left">
                    <h1 class="fs-20">Delete My Account</h1>
                  </td>
                  <td align="right">
                    <button (click)="togglePurchase5()" class="arrow" [ngClass]="{ active: purcahse_history5 }">
                      <img src="./assets/media/icon/Arrow_Black@2x.png" alt="" />
                    </button>
                  </td>
                </tr>
              </table>
              <hr />
              <div class="multi-collapse" id="multiCollapseExample7" *ngIf="purcahse_history5">
                <form [formGroup]="deleteUserForm" novalidate>
                  <div class="boxData">
                    <div class="content">
                      <span>
                        <input type="checkbox" id="checkGdprId" (change)="checkGdpr($event)" />&nbsp;&nbsp;I would also
                        like to delete all of my
                        personal information from the platform.
                      </span>
                      <br /><br />
                      <button class="btn begin_quiz my-2" style="float: right"
                        (click)="deleteYourAccount(deleteUserForm.value)">
                        Delete My Account
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>