import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NotificationService } from '../../../../../service/notification.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  OnDestroy,
} from '@angular/core';
import { message_properties } from '../../../../../../environments/message_properties';
import { Subscription } from 'rxjs';
import { CuratorPageService } from '../../../../../service/curator-page.service';
import { InsightFormComponent } from '../../../curator-page/view-community/dialog-view-community/view-community-dialog/insights-view-community-dialog/insight-form/insight-form.component';
import * as moment from 'moment-timezone';
import { AccountsService } from './../../../../../service/accounts.service';

@Component({
  selector: 'kt-session-insights',
  templateUrl: './session-insights.component.html',
  styleUrls: ['./session-insights.component.scss'],
})
export class SessionInsightsComponent implements OnInit, OnDestroy {
  session_type: number;
  session_start_date: any;
  session_end_date: any;
  guide = 'tk';
  mentee = 'reag';
  guide_dp = '';
  mentee_dp = '';
  eventSummaryForm: UntypedFormGroup;
  titleHeader: string;
  ufullname: string;
  active: number;
  attachment_path: string = '';
  base64textString: string;
  myDate = new Date();
  myTime;
  minTime;
  toTime: boolean = true;
  valueArr: any;
  public value: string[];
  public current: string;
  show_address: boolean = false;
  saveButton: boolean = false;
  user_profile_path: string;
  user_profile_path_data: boolean;
  fileToUpload: File;
  imageUrl: File;
  profile_path: any;
  title: any;
  menteeId: any;
  group: number = 0;
  event: number = 0;
  allInsightDatas: any[] = [];
  countinsite: number;
  allActionsDatas: any[] = [];
  allCommentsDatas: any[] = [];
  countaction: number;
  countcomment: number;
  difference: string;
  guideId: number;
  loggedinUserid: number;
  current_timezone: string;
  actual_duration: number;
  insightSubscription: Subscription;
  actionSubscription: Subscription;
  deleteSubscription: Subscription;
  commentSubscription: Subscription;
  eventDetailSubscription: Subscription;
  editSubscription: Subscription;
  summarySubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<SessionInsightsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _cdref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private toastr: NotificationService,
    private curatorService: CuratorPageService,
    private dialog: MatDialog,
    private router: Router,
    private accountService: AccountsService
  ) {
    this.event = Number(window.atob(data.eventId));
    this.guide = data.guideFirstName + ' ' + data.guideLastName;
    this.mentee = data.menteeFullName;
    this.guideId = data.guideId | 0;
    this.guide_dp = window.atob(data.guideDp);

    this.mentee_dp = window.atob(data.menteeDp);
    this.countcomment = 0;
    this.countaction = 0;
    this.countinsite = 0;
  }
  ngOnInit(): void {
    this.loggedinUserid = this.accountService.muser;
    this.current_timezone = localStorage.getItem('current_time_zone');

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.pageRefreshed();
    this.eventSummaryForm = this.formBuilder.group({
      remarks: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(1024),
      ]),
      visibility_type: new UntypedFormControl('1953'),
      summary_type: new UntypedFormControl('2405'),
      creating_member: new UntypedFormControl(this.loggedinUserid),
      event: new UntypedFormControl(this.event),
    });
    this.getEventDetails();
  }
  ngOnChanges(changes) {
    this.onChangeFrom(changes);
  }
  onChangeDate(event) {
    if (this.myDate.toDateString() !== event.value.toDateString()) {
      this.minTime = '12:00 AM';
    }
    this._cdref.detectChanges();
  }

  onChangeFrom(event) {
    this.minTime = event;
    if (event.value !== null || event.value !== '') {
      this.toTime = false;
    } else {
      this.toTime = true;
    }
    if (event === undefined) {
      this.toTime = false;
      this.minTime = this.myTime;
    }
    this._cdref.detectChanges();
  }

  returnImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }
  getEventDetails() {
    this.eventDetailSubscription = this.curatorService
      .getEventDetailInDialog(0, this.event)
      .subscribe((data) => {
        this.active = data.active;
        this.session_start_date = data.start_date;
        this.session_end_date = data.end_date;
        this.session_type = data.event_type;
        this.actual_duration = data.actual_duration;
      });
  }
  onlyTimeTimeZone(startDateTime) {
    return moment(startDateTime).tz(this.current_timezone).format('h:mm a');
  }
  editSummary(ngForm) {
    this.editSubscription = this.curatorService
      .createInsights(ngForm.value)
      .subscribe((data) => {
        if (data.status === 201) {
          this.toastr.showSuccess(
            message_properties.MY_GUIDED_EXPLORER_SESSION_SUMMARY_SUCCESS,
            ''
          );
        } else {
          this.toastr.showError(
            message_properties.MY_GUIDED_EXPLORER_SESSION_SUMMARY_ERROR,
            ''
          );
        }
      });
  }
  getSummary() {
    this.summarySubscription = this.curatorService
      .getSummaryDetails(this.event, 2405)
      .subscribe((data) => {
        this.eventSummaryForm.patchValue({
          visibility_type: 1953,
          summary_type: 2405,
          remarks: data.remarks,
          creating_member: this.loggedinUserid,
          event: this.event,
        });
      });
  }

  openInsights(symmary_type) {
    this.dialog.open(InsightFormComponent, {
      disableClose: true,
      width: '50%',
      data: {
        summaryId: 0,
        event: this.event,
        symmary_type: symmary_type,
      },
    });
  }
  editInsights(symmary_type, id) {
    this.dialog.open(InsightFormComponent, {
      disableClose: true,
      width: '50%',
      data: {
        summaryId: id,
        event: this.event,
        symmary_type: symmary_type,
      },
    });
  }

  getAllInsight() {
    this.insightSubscription = this.curatorService
      .getAllEventSummary(this.event, 2407)
      .subscribe((data) => {
        this.allInsightDatas = data.results;
        this.countinsite = data.count;
        this._cdref.detectChanges();
      });
  }
  getAllAction() {
    this.actionSubscription = this.curatorService
      .getAllEventSummary(this.event, 2406)
      .subscribe((data) => {
        this.allActionsDatas = data.results;
        this.countaction = data.count;
        this._cdref.detectChanges();
      });
  }
  getAllComment() {
    this.commentSubscription = this.curatorService
      .getAllEventSummary(this.event, 2586)
      .subscribe(
        (data) => {
          this.allCommentsDatas = data.results;
          this.countcomment = data.count;
          this._cdref.detectChanges();
        },
        (error) =>
          this.toastr.showError(
            message_properties.MY_GUIDED_EXPLORER_SESSION_INSIGHTS_ALL_COMMENTS_ERROR,
            ''
          )
      );
  }

  deleteInsightOrAction(summary_type, summary_id) {
    this.deleteSubscription = this.curatorService
      .deleteActionOrInsight(summary_id)
      .subscribe(
        (data) => {
          if (data.status === 200) {
            if (summary_type === 2407) {
              this.toastr.showSuccess(
                message_properties.INSIGHT_DELETE_SUCCESS,
                ''
              );
            } else if (summary_type === 2406) {
              this.toastr.showSuccess(
                message_properties.ACTION_DELETE_SUCCESS,
                ''
              );
            } else {
              this.toastr.showSuccess(
                message_properties.SUMMARY_DELETE_SUCCESS,
                ''
              );
            }
          } else {
            this.toastr.showError(
              message_properties.MY_GUIDED_EXPLORER_SESSION_INSIGHTS_ALL_COMMENTS_ERROR,
              ''
            );
          }
        },
        (error) => {
          this.toastr.showError(
            message_properties.MY_GUIDED_EXPLORER_SESSION_INSIGHTS_ALL_COMMENTS_ERROR,
            ''
          );
        }
      );
  }

  pageRefreshed() {
    this.curatorService.refreshneededEventSummaryList.subscribe((data) => {
      this.getSummary();
      this.getAllInsight();
      this.getAllAction();
      this.getAllComment();
    });
    this.curatorService.refreshneededEventActionList.subscribe((data) => {
      this.getSummary();
      this.getAllInsight();
      this.getAllAction();
      this.getAllComment();
    });
    this.curatorService.refreshneededEventSummaryList.subscribe((data) => {
      this.getSummary();
      this.getAllInsight();
      this.getAllAction();
      this.getAllComment();
    });

    this.getSummary();
    this.getAllInsight();
    this.getAllAction();
    this.getAllComment();
  }
  ngOnDestroy(): void {
    if (this.insightSubscription) {
      this.insightSubscription.unsubscribe();
    }
    if (this.actionSubscription) {
      this.actionSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
    if (this.commentSubscription) {
      this.commentSubscription.unsubscribe();
    }
    if (this.eventDetailSubscription) {
      this.eventDetailSubscription.unsubscribe();
    }
    if (this.editSubscription) {
      this.editSubscription.unsubscribe();
    }
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
  }
}
