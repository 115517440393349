import { DomSanitizer } from '@angular/platform-browser';
import { AdminPageService } from '../../../../service/admin-page.service';
import { ViewMyMeaningLogComponent } from './../view-my-meaning-log/view-my-meaning-log.component';
import { ViewMyTrasorComponent } from './../view-my-trasor/view-my-trasor.component';
import { SendRequestToBeMyGuideComponent } from './../../my-mentor/send-request-to-be-my-guide/send-request-to-be-my-guide.component';
import { SendIntroSessionRequestComponent } from './../../my-mentor/send-intro-session-request/send-intro-session-request.component';
import { NotificationService } from '../../../../service/notification.service';
import { MeaningNetworkService } from '../../../../service/meaning-network.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  OnDestroy,
} from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { message_properties } from './../../../../../environments/message_properties';
import { RequestConnectionComponent } from '../../meaning-network/my-connections/request-connection/request-connection.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../../../../service/common.service';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss'],
})
export class ViewProfileComponent implements OnInit, OnDestroy {
  model: NgbDateStruct;
  relation_count: number;
  member_to: number;
  fullName: string;
  picPath: string;
  guide_visible: boolean = false;
  loggedinUserid: number;
  member_id: number;
  title: string;
  guide_count: number;
  relationship_count: number;
  guide_id: number;
  click_from: string;

  topic_id: string;
  topic_type: string;
  topic: string;
  member: string;
  tenant: string;
  member_topic_results: [];
  member_topic_count: number;

  member_profile_id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  status: number;
  mentor_indicator: boolean;
  admin_indicator: boolean;
  curator_indicator: boolean;
  picture_path: string;
  profile_show: boolean = false;
  member_profile_results: [];
  member_profile_count: number;

  endorse_id: string;
  endorse_first_name: string;
  endorse_last_name: string;
  endorse_full_name: string;
  endorse_member__status: string;
  endorse_picture_path: string;
  remarks: string;
  member_endorsement_results: [];
  member_endorsement_count: number;

  expertise_id: string;
  expertise_level: string;
  description: string;
  available: boolean = false;
  highlight: string;
  no_of_years: string;
  member_expertise_results: [];
  member_expertise_count: number;

  experience_id: string;
  experience_title: string;
  experience_name: string;
  experience_location: string;
  experience_workprofile: string;
  member_employee_experience_results: [];
  member_employee_experience_count: number;

  education_id: string;
  education_title: string;
  education_name: string;
  education_location: string;
  education_workprofile: string;
  member_employee_education_results: [];
  member_employee_education_count: number;

  volunteer_id: string;
  volunteer_title: string;
  volunteer_name: string;
  volunteer_location: string;
  volunteer_workprofile: string;
  member_employee_volunteer_results: [];
  member_employee_volunteer_count: number;

  work_history_id: string;
  property_value: string;
  member_work_history_results: [];
  member_work_history_count: number;

  address_id: string;
  city: string;
  state: string;
  country: string;
  country_id: string;
  city_id: string;
  member_address_results: [];
  member_address_count: number;

  my_treasure_id: string;
  my_treasure_title: string;
  my_treasure_description: string;
  my_treasure_video_indicator: string;
  my_treasure_created_by_curator_indicator: string;
  my_treasure_references: string;
  my_treasure_collab_content_category: string;
  my_treasure_attachment_path: string;
  member_my_treasure_results: [];
  member_my_treasure_count: number;

  meaning_moments_id: string;
  meaning_moments_title: string;
  meaning_moments_description: string;
  meaning_moments_video_indicator: string;
  meaning_moments_created_by_curator_indicator: string;
  meaning_moments_references: string;
  meaning_moments_collab_content_category: string;
  meaning_moments_attachment_path: string;
  member_meaning_moments_results: [];
  member_meaning_moments_count: number;

  connection_count: number;
  requested_to: number;
  requested_from: number;
  connection_status: number;
  connection_between_member_and_logged_in_member_results: [];
  connection_between_member_and_logged_in_member__count: number;
  member_connect_count: number;
  member_connect_count_from_popup: number;
  profileDetailSubscription: Subscription;
  checkGuideSubscription: Subscription;
  userHaveGuideSubscription: Subscription;
  connectionAcceptSubscription: Subscription;
  connectionDeclineSubscription: Subscription;
  connectionWithdrawSubscription: Subscription;
  connectionRemoveSubscription: Subscription;
  connectionStatusSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<ViewProfileComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private meaning_network_service: MeaningNetworkService,
    private toastr: NotificationService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private router: Router,
    private adminservice: AdminPageService,
    public sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private accountService: AccountsService
  ) {
    this.loggedinUserid = this.accountService.muser;
    this.member_to = data.member;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.fullName = `${this.first_name} ${this.last_name}`;
    this.picPath = data.picture_path
      ? data.picture_path
      : './assets/media/users/default.png';
    this.click_from = data.click_from;
  }

  ngOnInit() {
    if (this.click_from === 'guide_search') {
      this.guide_visible = true;
      this.profile_show = true;
    }
    this.getLoggedInUserHaveGuide(this.loggedinUserid, this.member_to);
    this.getProfileDetails();
    this.title = 'Profile of ' + this.fullName;
  }

  getProfileDetails() {
    this.spinner.show();
    const data__ = {
      member: this.member_to,
      logged_in_member: this.loggedinUserid,
    };
    this.profileDetailSubscription = this.adminservice
      .getMemberDetailsPost(data__)
      .subscribe((response) => {
        if (response.status === 200) {
          this.member_topic_count = response?.final_data['member_topic_count'];
          this.member_topic_results =
            this.member_topic_count > 0
              ? response?.final_data['member_topic_results']
              : [];

          this.member_profile_count =
            response.final_data['member_profile_count'];
          this.member_profile_results =
            this.member_profile_count > 0
              ? response.final_data['member_profile_results']
              : [];
          this.mentor_indicator =
            this.member_profile_count > 0
              ? response.final_data['member_profile_results'][0][
                  'mentor_indicator'
                ]
              : false;

          this.member_endorsement_count =
            response.final_data['member_endorsement_count'];
          this.member_endorsement_results =
            this.member_endorsement_count > 0
              ? response.final_data['member_endorsement_results']
              : [];

          this.member_expertise_count =
            response.final_data['member_expertise_count'];
          this.member_expertise_results =
            this.member_expertise_count > 0
              ? response.final_data['member_expertise_results']
              : [];
          this.no_of_years =
            this.member_expertise_count > 0
              ? response.final_data['member_expertise_results'][0][
                  'no_of_years'
                ]
              : '';
          this.expertise_level =
            this.member_expertise_count > 0
              ? response.final_data['member_expertise_results'][0][
                  'expertise_level'
                ]
              : '';
          this.description =
            this.member_expertise_count > 0
              ? response.final_data['member_expertise_results'][0][
                  'description'
                ]
              : '';
          this.available =
            this.member_expertise_count > 0
              ? response.final_data['member_expertise_results'][0]['available']
              : '';
          this.highlight =
            this.member_expertise_count > 0
              ? response.final_data['member_expertise_results'][0]['highlight']
              : '';

          this.member_employee_experience_count =
            response.final_data['member_employee_experience_count'];
          this.member_employee_experience_results =
            this.member_employee_experience_count > 0
              ? response.final_data['member_employee_experience_results']
              : [];

          this.member_employee_education_count =
            response.final_data['member_employee_education_count'];
          this.member_employee_education_results =
            this.member_employee_education_count > 0
              ? response.final_data['member_employee_education_results']
              : [];

          this.member_employee_volunteer_count =
            response.final_data['member_employee_volunteer_count'];
          this.member_employee_volunteer_results =
            this.member_employee_volunteer_count > 0
              ? response.final_data['member_employee_volunteer_results']
              : [];

          this.member_work_history_count =
            response?.final_data['member_work_history_count'];
          this.property_value =
            this.member_work_history_count > 0
              ? response?.final_data['member_work_history_results'][0][
                  'property_value'
                ]
              : '';

          this.member_address_count =
            response?.final_data['member_address_count'];
          this.city =
            this.member_address_count > 0
              ? response?.final_data['member_address_results'][0]['city']
              : '';
          this.country =
            this.member_address_count > 0
              ? response?.final_data['member_address_results'][0]['country']
              : '';

          this.member_my_treasure_results =
            response?.final_data['member_my_treasure_results'];
          this.member_my_treasure_count =
            response?.final_data['member_my_treasure_count'];

          this.member_meaning_moments_results =
            response?.final_data['member_meaning_moments_results'];
          this.member_meaning_moments_count =
            response?.final_data['member_meaning_moments_count'];
          this.requested_to = response?.final_data['requested_to'];
          this.requested_from = response?.final_data['requested_from'];
          this.connection_count = response?.final_data['connection_count'];
          this.connection_status = response?.final_data['connection_status'];
          this.checkHeIsaGuideOrNotOfLoggedInUser(
            this.requested_to,
            this.requested_from
          );
          this.getConnection(this.requested_from, this.requested_to);
          this.meaning_network_service.refreshneededRequestConnection.subscribe(
            () => {
              this.getConnection(this.requested_from, this.requested_to);
            }
          );
          this.cdRef.detectChanges();
          this.spinner.hide();
        }
      });
  }
  getLoggedInUserHaveGuide(member, clicked_member) {
    this.userHaveGuideSubscription = this.adminservice
      .loggedInUserHaveGuidePost(member, clicked_member)
      .subscribe((data) => {
        this.guide_count = data.count;
        this.relationship_count = data.logged_in_relation_count;
        if (this.guide_count === 0) {
          this.guide_id = 0;
        } else {
          this.guide_id = data.members[0]['relating_member'];
        }
      });
  }

  toogleGuideData() {
    this.guide_visible = !this.guide_visible;
  }

  acceptConnection(requested_to, requested_from) {
    let formData = {
      connection_status: 2295,
      requested_member: requested_from,
    };
    this.connectionAcceptSubscription = this.meaning_network_service
      .updateConnectionRequest(formData, requested_to)
      .subscribe((data) => {
        this.getConnection(requested_from, requested_to);
        this.toastr.showSuccess(
          message_properties.VIEW_PROFILE_ACCEPT_CONNECTION_SUCCESS +
            this.fullName,
          ''
        );
        this.dialog.closeAll();
      });
  }
  declineConnection(requested_to, requested_from) {
    let formData = {
      connection_status: 2294,
      requested_member: requested_from,
    };
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to Decline this connection request.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.connectionDeclineSubscription = this.meaning_network_service
          .updateConnectionRequest(formData, requested_to)
          .subscribe((data) => {
            this.getConnection(requested_from, requested_to);
            this.toastr.showSuccess(
              message_properties.VIEW_PROFILE_DECLINE_CONNECTION_SUCCESS +
                this.fullName,
              ''
            );
            this.dialog.closeAll();
          });
      }
    });
  }

  requestConnection() {
    this.dialog.open(RequestConnectionComponent, {
      width: '60%',
      disableClose: true,
      data: { connected_member: this.member_to, message: '' },
    });
  }
  withdrawConnectionRequest(requested_to, requested_from) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to withdraw your connection request.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        let formData = {
          connection_status: 2293,
          requested_member: requested_from,
        };
        this.connectionWithdrawSubscription = this.meaning_network_service
          .updateConnectionRequest(formData, requested_to)
          .subscribe(() => {
            this.getConnection(requested_from, requested_to);
            this.toastr.showSuccess(
              message_properties.VIEW_PROFILE_WITHDRAW_CONNECTION_SUCCESS +
                this.fullName,
              ''
            );
            this.dialogRef.close();
          });
      }
    });
  }
  removeConnection(requested_to, requested_from) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to remove your connection request.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.connectionRemoveSubscription = this.meaning_network_service
          .deleteConnectionRequest(requested_from, requested_to)
          .subscribe(() => {
            this.getConnection(requested_from, requested_to);
            this.toastr.showSuccess(
              message_properties.VIEW_PROFILE_REMOVE_CONNECTION_SUCCESS +
                this.fullName,
              ''
            );
            this.dialogRef.close();
          });
      }
    });
  }
  getConnection(requested_from, requested_to) {
    this.connectionStatusSubscription = this.meaning_network_service
      .getConnection(requested_from, requested_to)
      .subscribe((conn) => {
        this.member_connect_count_from_popup = conn.count;
        this.member_connect_count = conn.count;
        this.connection_status =
          this.member_connect_count > 0
            ? JSON.parse(JSON.stringify(conn.results))[0]['connection_status']
            : 0;
        this.cdRef.detectChanges();
      });
  }
  getMessageDetails() {
    this.dialog.open(RequestConnectionComponent, {
      width: '30%',
      disableClose: true,
      data: { connected_member: this.member_to, message: 'received' },
    });
  }

  sendIntroSessionRequest() {
    this.dialog.open(SendIntroSessionRequestComponent, {
      disableClose: true,
      width: '50%',
      data: {
        mentorId: this.member_to,
        fullName: this.fullName,
        picPath: this.picPath,
      },
    });
  }
  sendRequestToBeMyGuide() {
    this.dialog.open(SendRequestToBeMyGuideComponent, {
      disableClose: true,
      width: '50%',
      data: {
        mentorId: this.member_to,
        fullName: this.fullName,
        picPath: this.picPath,
      },
    });
  }
  treasuresList() {
    this.dialog.open(ViewMyTrasorComponent, {
      disableClose: true,
      width: '80%',
      data: { requested_to: this.member_to, full_name: this.fullName },
    });
  }
  meaninglogList() {
    this.dialog.open(ViewMyMeaningLogComponent, {
      disableClose: true,
      width: '80%',
      data: { requested_to: this.member_to, full_name: this.fullName },
    });
  }
  goToProfilePage() {
    this.router.navigate(['/meaning-network/meaning-central-profile']);
  }

  returnImage(image) {
    if (image) {
      return image;
    } else {
      return 'assets/media/users/default.png';
    }
  }
  profileEdit() {
    this.dialogRef.close();
    this.router.navigate(['/meaning-network/meaning-central-profile']);
  }

  checkHeIsaGuideOrNotOfLoggedInUser(requested_to, requested_from) {
    this.checkGuideSubscription = this.adminservice
      .guideAcceptedToUser(requested_to, requested_from)
      .subscribe((data) => {
        this.relation_count = data.relation_count;
      });
  }
  checkStatus(status) {
    return CommonService.checkMemberStatus(status);
  }
  ngOnDestroy(): void {
    if (this.profileDetailSubscription) {
      this.profileDetailSubscription.unsubscribe();
    }
    if (this.checkGuideSubscription) {
      this.checkGuideSubscription.unsubscribe();
    }
    if (this.userHaveGuideSubscription) {
      this.userHaveGuideSubscription.unsubscribe();
    }
    if (this.connectionAcceptSubscription) {
      this.connectionAcceptSubscription.unsubscribe();
    }
    if (this.connectionDeclineSubscription) {
      this.connectionDeclineSubscription.unsubscribe();
    }
    if (this.connectionWithdrawSubscription) {
      this.connectionWithdrawSubscription.unsubscribe();
    }
    if (this.connectionRemoveSubscription) {
      this.connectionRemoveSubscription.unsubscribe();
    }
    if (this.connectionStatusSubscription) {
      this.connectionStatusSubscription.unsubscribe();
    }
  }
}
