import { EachVedioComponent } from './../each-vedio/each-vedio.component';
import { Component, OnInit, Inject, Optional } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'kt-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss'],
})
export class VideoListComponent implements OnInit {
  videoUrl =
    'https://www.youtube.com/embed/zma0U-havuE?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://mspr1app.vlabsinc.com';
  videoLists = [];
  videoPic = 'https://mdbootstrap.com/img/screens/yt/screen-video-1.jpg';

  config: SwiperOptions = {
    effect: 'coverflow',
    zoom: true,
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30,
  };
  images = [
    this.videoPic,
    this.videoPic,
    this.videoPic,
    this.videoPic,
    this.videoPic,
    this.videoPic,
    this.videoPic,
    this.videoPic,
  ];
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<VideoListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.videoLists = [
      {
        videoTitle: 'Map of Meaning',
        videoDescription: 'Introduction to Map of Meaning',
        videoLink:
          'https://www.youtube.com/embed/kR321k9JVNU?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://mspr1app.vlabsinc.com',
        videoPic: './assets/media/video_template/intro.png',
      },
      {
        videoTitle: 'Map of Meaning',
        videoDescription: 'Maya: My score isn’t typical!',
        videoLink:
          'https://www.youtube.com/embed/ROhF3mC1k80?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://mspr1app.vlabsinc.com',
        videoPic: './assets/media/video_template/maya.png',
      },
      {
        videoTitle: 'Map of Meaning',
        videoDescription: 'Oliver: Something’s missing!',
        videoLink:
          'https://www.youtube.com/embed/vklBsKb_duY?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://mspr1app.vlabsinc.com',
        videoPic: './assets/media/video_template/olive.png',
      },
      {
        videoTitle: 'Map of Meaning',
        videoDescription: 'Sam: It’s a nice idea!',
        videoLink:
          'https://www.youtube.com/embed/4tPBCMLmOTk?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://mspr1app.vlabsinc.com',
        videoPic: './assets/media/video_template/sam.png',
      },
      {
        videoTitle: 'Map of Meaning',
        videoDescription: 'Dmitri: I found a trick that works!',
        videoLink:
          'https://www.youtube.com/embed/OQ4iuVCPMpE?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://mspr1app.vlabsinc.com',
        videoPic: './assets/media/video_template/dmitri.png',
      },
      {
        videoTitle: 'Map of Meaning',
        videoDescription: 'Lucy: The next chapter in my life!',
        videoLink:
          'https://www.youtube.com/embed/X5hNRSjCx3c?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://mspr1app.vlabsinc.com',
        videoPic: './assets/media/video_template/lucy.png',
      },
    ];
  }
  onCreate(val) {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: { source: val },
    });
  }
  onClose(): void {
    this.dialogRef.close(0);
  }
}
