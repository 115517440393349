<div class="col-sm-12">
  <!-- <span class="fs-20" [innerHtml]="report_2_body"></span>
   -->
   <h1 class="section-title mb-5">Your Personal Meaning Profile</h1>
   <p class="section-text">Your Personal Meaning Profile (PMP) is calculated using your responses to the survey items that relate to the four pathways illustrated in the framework below. Each pathway represents a way you find meaning at work. Take a moment to review these survey items, and then look at your scores below.</p>
   <div class="row">
    <div class="col-sm-12 mt-20 text-center">
      <img src="./assets/media/images/second_page_pdf.png" class="img-fluid" />
    </div>
   </div>
   <p class="section-text mt-20 mb-10">The higher your score, the more meaning you are finding in that pathway. Your results include a comparison to the typical scores of everyone who has taken the survey (across cultures, countries, and occupations).
  </p>
</div>
