export const message_properties = {
  /*  ADMIN PAGE  */
  ADD_MEMBER_SUCCESS: 'Explorer added!',
  ADD_MEMBER_ERROR: 'It looks like something’ s out of place. Please make sure all information is correct. If this error persists, contact your system administrator.',
  ADD_MEMBER_UPDATE_SUCCESS: 'Explorer updated!',
  ADD_MEMBER_UPDATE_ERROR: 'It looks like somethings out of place. Please make sure all information is correct. If this error persists, contact your system administrator.',
  ADMIN_NOTIFICATION__MOVE_TO_TRACE_SUCCESS: 'Notification deleted.',
  EMAIL_TEMPLATE_SAVE_MEMBER_SUCCESS: 'Email template updated.',
  EMAIL_TEMPLATE_SAVE_MEMBER_ERROR: 'It looks like something’s out of place. Please correct the errors. If you see this message repeatedly, contact your system administrator.',
  LOCK_USER_SUCCESS: 'Explorer privileges updated.',
  LOCK_USER_ERROR: 'We encountered an error while updating Explorer privileges. Please try again.',
  MEMBER_LIST_WITH_SEARCH_RESET_PASSWORD_SUCCESS: 'Password successfully reset for this Explorer.',
  MEMBER_LIST_WITH_SEARCH_RESET_PASSWORD_ERROR: 'We encountered an error in resetting this Explorer’s password. Please try again.',
  SEND_MESSAGE_SAVE_MESSSAGE_SUCCESS: 'Message sent!',
  SEND_MESSAGE_SAVE_MESSAGE_SUCCESS1: 'Message sent!',
  USER_REQUEST_SAVE_MEMBER_SUCCESS: 'Request updated!',
  USER_REQUEST_SAVE_MEMBER_ERROR: 'It looks like something’s out of place. Please correct the errors. If you see this message repeatedly, contact your system administrator.',
  VIEW_PROFILE_ACCEPT_CONNECTION_SUCCESS: 'You are now connected to ',
  VIEW_PROFILE_DECLINE_CONNECTION_SUCCESS: 'You have declined the connection request from ',
  VIEW_PROFILE_WITHDRAW_CONNECTION_SUCCESS: 'You have withdrawn your request to ',
  VIEW_PROFILE_REMOVE_CONNECTION_SUCCESS: 'You have removed your connection with ',


  /* CURATOR PAGE */
  ADD_DIALOG_SUCCESS: 'Dialog saved!',
  ADD_DIALOG_ERROR: 'We encountered an error saving the Dialog. Please try again.',
  ADD_MEMBER_IN_CIRCLE_SUCCESS: 'Member added to Circle!',
  ADD_MEMBER_IN_CIRCLE_ERROR: 'We encountered an error adding member to Circle. Please try again.',
  ADD_MEMBER_IN_COMMUNITY_SUCCESS: 'Member added to Community!',
  ADD_MEMBER_IN_COMMUNITY_ERROR: 'We encountered an error adding member to Community. Please try again.',
  ADD_MIRROR_TOPIC_SUCCESS: 'Mirror topic added!',
  ADD_MIRROR_TOPIC__UPDATE_SUCCESS: 'Mirror topic updated!',
  ADD_SESSION_CIRCLE_SUCCESS: 'Session saved!',
  ADD_SESSION_CIRCLE_ERROR: 'We encountered an error saving the Session. Please try again.',
  CREATE_CIRLCE_SUCCESS: 'Circle created!',
  CREATE_CIRCLE_ERROR: 'We encountered an error creating the Circle.',
  CREATE_COMMUNITY_SUCCESS: 'Community created!',
  CREATE_COMMUNITY_ERROR: 'We encountered an error creating the Community.',
  CREATE_MEANING_CENTRAL_POPUP_CREATE_WALLOF_FAME_SUCCESS: 'Content published!',
  CREATE_MEANING_CENTRAL_POPUP_CREATE_WALLOF_FAME_ERROR: 'We encountered an error publishing your content.',
  CREATE_MEANING_CENTRAL_POPUP_UPDATE_WALLOF_FAME_SUCCESS: 'Content updated!',
  CREATE_MEANING_CENTRAL_POPUP_UPDATE_WALLOF_FAME_ERROR: 'We encountered an error updating your content.',
  CREATE_MEANING_CENTRAL_POPUP_REMOVE_POST_SUCCESS: 'Content removed.',
  CREATE_MEANING_CENTRAL_POPUP_VIDEO_PREVIEW_ERROR: 'File type invalid. You can only upload video files.',
  CREATE_MEANING_CENTRAL_POPUP_VIDEO_PREVIEW_SIZE_ERROR: 'Video file size must be less than 15MB.',
  CREATE_MEANING_CENTRAL_POPUP_IMAGE_PREVIEW_ERROR: 'File type invalid. You can only upload image files.',
  CREATE_MEANING_CENTRAL_POPUP_IMAGE_PREVIEW_SIZE_ERROR: 'Image file size must be less than 2MB.',
  CREATE_MEANING_CONSELLATION_POPUP_CREATE_WALL_OF_FAME_SUCCESS: 'Content published!',
  CREATE_MEANING_CONSELLATION_POPUP_CREATE_WALL_OF_FAME_ERROR: 'We encountered an error publishing your content.',
  CREATE_MEANING_CONSELLATION_POPUP_UPDATE_WALL_OF_FAME_SUCCESS: 'Content updated!',
  CREATE_MEANING_CONSELLATION_POPUP_UPDATE_WALL_OF_FAME_ERROR: 'We encountered an error updating your content.',
  CREATE_MEANING_CONSELLATION_POPUP_REMOVE_POST_SUCCESS: 'Content removed.',
  CREATE_MEANING_CONSELLATION_POPUP_VIDEO_PREVIEW_ERROR: 'File type invalid. You can only upload video files.',
  CREATE_MEANING_CONSELLATION_POPUP_VIDEO_PREVIEW_SIZE_ERROR: 'Video file size must be less than 15MB.',
  CREATE_MEANING_CONSELLATION_POPUP_IMAGE_PREVIEW_ERROR: 'File type invalid. You can only upload image files.',
  CREATE_MEANING_CONSELLATION_POPUP_IMAGE_PREVIEW_SIZE_ERROR: 'Image file size must be less than 2MB.',
  FLAG_CONTENT_DETAILS_FLAG_CONTENT_DETAILS_POPUP_DELETE_COLLAB_CONTENT_SUCCESS: 'Content removed.',
  VIEW_CIRCLE_MEMBER_VIEW_CIRCLE_SUCCESS: 'Member removed from the Circle.',
  VIEW_CIRCLE_PROFILE_VIEW_CIRCLE_CIRCLE_UPDATE_SUCCESS: 'Circle updated!',
  VIEW_CIRCLE_PROFILE_VIEW_CIRCLE_CIRCLE_UPDATE_ERROR: 'We encountered an error updating the Circle.',
  VIEW_CIRCLE_PROFILE_VIEW_CIRCLE_OPEN_RETRIVE_ALERT_SUCCESS: 'Circle retired.',
  VIEW_CIRCLE_PROFILE_VIEW_CIRCLE_OPEN_RESTORE_ALERT_SUCCESS: 'Circle restored!',
  VIEW_CIRCLE_SESSION_VIEW_CIRCLE__VIEW_CIRCLE_SESSION__PROFILE_VIEW_SESSION_CIRCLE_SESSION_UPDATE_SUCCESS: 'Session updated!',
  VIEW_CIRCLE_SESSION_VIEW_CIRCLE__VIEW_CIRCLE_SESSION__PROFILE_VIEW_SESSION_CIRCLE_SESSION_UPDATE_ERROR: 'We encountered an error updating the Session.',
  VIEW_CIRCLE_SESSION_VIEW_CIRCLE__VIEW_CIRCLE_SESSION__PROFILE_VIEW_SESSION_CIRCLE_SET_SESSION_SUCCESS: 'This Session has been ',
  VIEW_CIRCLE_SESSION_VIEW_CIRCLE__VIEW_CIRCLE_SESSION__PROFILE_VIEW_SESSION_CIRCLE_SET_SESSION_CANCELLED: 'Session canceled.',
  VIEW_CIRCLE_SESSION_VIEW_CIRCLE__VIEW_CIRCLE_SESSION__PROFILE_VIEW_SESSION_CIRCLE_SET_SESSION_PUBLISHED: 'Session published!',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__COMMENT_FORM__ERROR: 'We encountered an error saving the Comment! Please try again. If you see this message repeatedly, contact your System Administrator.',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__COMMENT_FORM__SUCCESS: 'Comment has been saved!',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__INSIGHT_FORM__SUCCESS: 'Insight saved!',
  VIEW_COMMUNITY_UPDATE_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__INSIGHT_FORM__SUCCESS: 'Insight has been updated!',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__INSIGHT_FORM__ERROR: 'We encountered an error saving this Insight. ',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__ACTION_FORM__SUCCESS: 'Action saved!',
  VIEW_COMMUNITY_UPDATE_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__ACTION_FORM__SUCCESS: 'Action has been updated!',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__ACTION_FORM__ERROR: 'We encountered an error saving this Action.',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__INSIGHT_VIEW_COMMUNITY_DIALOGS__SUCCESS: 'Action saved!',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__INSIGHT_VIEW_COMMUNITY_DIALOGS__ERROR: 'We encountered an error saving this Action.',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__PROFILE_VIEW_COMMUNITY_DIALOGS__UPDATE_DIALOGS_SUCCESS: 'Dialog updated!',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__PROFILE_VIEW_COMMUNITY_DIALOGS__UPDATE_DIALOGS_ERROR_MAXIMUM_PARTICIPANT: 'Maximum number of participants have registered for this event.',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__PROFILE_VIEW_COMMUNITY_DIALOGS__UPDATE_DIALOGS_ERROR: 'We encountered an error updating the Dialog.',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__PROFILE_VIEW_COMMUNITY_DIALOGS__SET_ACTION_CANCELLED: 'Dialog canceled.',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__PROFILE_VIEW_COMMUNITY_DIALOGS__SET_ACTION_PUBLISHED: 'Dialog published!',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY_MEMBER_VIEW_COMMUNITY_MEMBER_REMOVE_SUCCESS: 'Member removed from the Community.',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY_PROFILE_VIEW_COMMUNITY_UPDATE_COMMUNITY_SUCCESS: 'Community updated!',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY_PROFILE_VIEW_COMMUNITY_UPDATE_COMMUNITY_ERROR: 'We encountered an error updating the Community.',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY_PROFILE_VIEW_COMMUNITY_RETRIVE_SUCCESS: 'Community retired.',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY_PROFILE_VIEW_COMMUNITY_RESTORE_SUCCESS: 'Community restored!',
  MY_GUIDED_EXPLORER_SESSION_SUMMARY_SUCCESS: 'Summary saved! ',
  MY_GUIDED_EXPLORER_SESSION_SUMMARY_ERROR: 'We encountered an error saving the summary.',
  /* LOGIN REGISTER */
  LOGGED_IN_SUCCESS: 'Logged in successfully!',
  LOGIN_FORGOTPASSWORD_SUCCESS: 'Check your email for instructions to reset your password.',
  LOGIN_FORGOTPASSWORD_ERROR1: 'This email has not been registered with MeaningSphere.',
  LOGIN_FORGOTPASSWORD_ERROR2: 'We encountered an error sending the email. Please try again. If this error persists, contact Support at support.meaningsphere.com. ',
  LOGIN_FORGOTPASSWORD_ERROR3: 'We have encountered an error in processing your request! Please try again. If you encounter this issue repeatedly, please get in touch with the Administrator at admin@meaningsphere.com.',
  LOGIN_REGISTER_ERROR: 'We encountered an error with your login. Please try again. If this error persists, please contact contact Support at support.meaningsphere.com.',
  LOGIN_REGISTERVARIFICATION_ERROR: 'We encountered an error with your registration verification. Please try again. If this issue persists, please contact contact Support at support.meaningsphere.com.',
  REGISTER_ERROR: 'We encountered an error with your registration. Please try again. If this issue persists, please contact contact Support at support.meaningsphere.com.',
  OTP_SUCCESS: 'Your code has been verified. Please log in to MeaningSphere.',
  SENT_VERIFICATION_AGAIN_SUCCESS: 'We have resent another verification code to your Email ID.',
  CHANGE_PASSWORD_SUCCESS: 'Password has been changed!',
  CHANGE_PASSWORD_ERROR: 'We encountered an error changing your password. Please try again.',
  EMAIL_SENT_SUCCESS: 'Email has been sent!',
  EMAIL_SENT_ERROR: 'We encountered an error sending Email.',
  METHOD_NOT_IMPLIMENTED: 'Method not implemented',
  /* MAP OF MEANING */
  SAVE_SURVEY_SUCCESS: 'Response saved!',
  SAVE_SURVEY_ERROR: 'We encountered an error saving your response.',
  FINAL_REPORT_SHARE_WITH_GUIDE_SUCCESS: 'Report shared with your Guide!',
  FINAL_REPORT_SHARE_WITH_GUIDE_ALREADY_SHARE_SUCCESS: 'You have already shared this DIY activity with your Guide.',
  FINAL_REPORT_SHARE_WITH_GUIDE_ERROR: 'You do not currently have a Guide.',
  DIY_FOURTH_PAGE_COMPLETE_PAGE_SUCCESS: 'DIY activity completed!',
  DIY_SECOND_PAGE_onChecklistWithInputBoxChange_ERROR: 'Select up to 5 items only.',
  GIVE_SURVEY_RESPONSE_SUCCESS: 'Survey response added successfully!',
  NEXT_STEP_GO_TO_PAGE_INFO: 'Survey not complete. Next steps are only available after completing the survey. ',
  SURVEY_SUBMITTED_LIST_SHARE_WITH_GUIDE_SUCCESS: 'Report shared with your Guide!',
  SURVEY_SUBMITTED_LIST_SHARE_WITH_GUIDE_ALREADY_SUCCESS: 'You have already shared this report with your Guide.',
  SURVEY_SUBMITTED_LIST_SHARE_WITH_GUIDE_ERROR: 'You do not currently have a Guide.',
  GUIDE_DISCUSSION_SUCCESS: 'Your request has been sent to your Guide. If the date and time do not work, your Guide will message you with alternatives.',
  GUIDE_DISCUSSION_ALLREADY_PRESENT: 'You have already sent a request to your Guide.',
  GUIDE_DISCUSSION_ERROR: 'We encountered an error sending your request. Please try again. If the issues persists, please contact contact Support at support.meaningsphere.com.',
  RESEARCH_DATA_SAVE_SUCCESS: 'Thank you for contributing to the ongoing research ongoing research on finding Meaning at Work. You will now be redirected to your survey report.',
  RESEARCH_DATA_SAVE_ERROR: 'We encountered an error sending your request. Please try again. If the issues persists, please contact contact Support at support.meaningsphere.com.',
  /* MEANING CIRCLE */
  ADD_REQUEST_CIRCLE__CREATE_COMMUNITY_SUCCESS: 'Request saved!',
  ADD_REQUEST_CIRCLE__CREATE_COMMUNITY_ERROR: 'We encountered an error saving your request.',
  ADD_REQUEST_CIRCLE__CREATE_COMMUNITY_ERROR1: 'We encountered an error saving your request! Please try again. If you see this message repeatedly, contact your System Administrator.',
  ADD_REQUEST_CIRCLE__UPDATE_COMMUNITY__REJECT_SUCCESS: 'Explorer request rejected.',
  ADD_REQUEST_CIRCLE__UPDATE_COMMUNITY__APPROVE_SUCCESS: 'Explorer request approved.',
  ADD_REQUEST_CIRCLE__UPDATE_COMMUNITY_ERROR: 'We encountered an error saving the data.',
  SINGLE_CIRCLE_ACTION_INSIGHT_POPUP_CREATE_ACTION_INSIGHT_SUCCESS: 'Insight saved!',
  SINGLE_CIRCLE_ACTION_INSIGHT_POPUP_CREATE_ACTION_INSIGHT_ERROR: 'We encountered an error saving this Insight.',
  USER_CIRCLE_CREATE_MESSAGE_SUCCESS: 'Message sent!',
  USER_CIRCLE_CREATE_MESSAGE_ERROR: 'We encountered an error sending your message.',
  USER_CIRCLE_ADD_SESSION_SUCCESS: 'Session saved!',
  USER_CIRCLE_ADD_SESSION_ERROR: 'We encountered an error saving the Session.',
  USER_CIRCLE_FEED_DETAILS_POPUP_VIEW_WALL_OF_FAME_ERROR: 'Content no longer available.',
  USER_CIRCLE_FEED_DETAILS_POPUP_TAG_AS_CHEER_SUCCESS: 'Content has been tagged with your ‘cheer’ tag!',
  USER_CIRCLE_FEED_DETAILS_POPUP_UNTAG_AS_CHEER_SUCCESS: 'Your ‘cheer’ tag has been removed!',
  USER_CIRCLE_FEED_DETAILS_POPUP_TAG_AS_RESONATE_SUCCESS: 'Content has been tagged with your ‘I Resonate’ tag!',
  USER_CIRCLE_FEED_DETAILS_POPUP_UNTAG_AS_RESONATE_SUCCESS: 'Your ‘I Resonate’ tag has been removed!',
  USER_CIRCLE_FEED_DETAILS_POPUP_TAG_AS_LEARND_SUCCESS: 'Content has been tagged with your ‘I Learned’ tag!',
  USER_CIRCLE_FEED_DETAILS_POPUP_UNTAG_AS_LEARND_SUCCESS: 'Your ‘I Learned’ tag has been removed!',
  USER_CIRCLE_FEED_DETAILS_POPUP_TAG_AS_INSPIRED_SUCCESS: 'Content has been tagged with your ‘I am Inspired’ tag!',
  USER_CIRCLE_FEED_DETAILS_POPUP_UNTAG_AS_INSPIRED_SUCCESS: 'Your ‘I am Inspired’ tag has been removed!',
  USER_CIRCLE_FEED_DETAILS_POPUP_SHARED_AS_POST_SUCCESS: 'Content shared as a post in your Meaning Feed.',
  USER_CIRCLE_FEED_DETAILS_POPUP_ADD_TO_MY_TRESSURE_SUCCESS: 'Added to Treasures!',
  USER_CIRCLE_FEED_DETAILS_POPUP_ADD_TO_MY_TRESSURE_ALREADY_SUCCESS: 'This content has already been added to your Treasures.',
  USER_CIRCLE_FEED_DETAILS_POPUP_DELETE_COLLAB_CONTENT_SUCCESS: 'Content deleted.',
  USER_CIRCLE_FEED_DETAILS_POPUP_DELETE_COMMENT_SUCCESS: 'Comment deleted.',
  SINGLE_MAIN_PAGE_CREATE_MEANING_POST_SUCCESS: 'Content published!',
  SINGLE_MAIN_PAGE_CREATE_MEANING_POST_ERROR: 'We encountered an error saving your content.',
  SINGLE_MAIN_PAGE_VIDEO_PREVIEW_ERROR: 'File type invalid. You can only upload video files.',
  SINGLE_MAIN_PAGE_VIDEO_SIZE_ERROR: 'Video file size must be less than 15MB.',
  SINGLE_MAIN_PAGE_IMAGE_PREVIEW_ERROR: 'File type invalid. You can only upload image files.',
  SINGLE_MAIN_PAGE_IMAGE_SIZE_ERROR: 'Image file size must be less than 2MB.',
  JOIN_CIRCLE_FROM_CLIENT_SIDE: 'You have joined this Circle!',
  LEAVE_CIRCLE_FROM_CLIENT_SIDE: 'You have left this Circle.',
  REGISTER_SESSION_FROM_CLIENT_SIDE: 'Session registration successful!',
  WITHDRAW_SESSION_FROM_CLIENT_SIDE: 'You have withdrawn from this Session.',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__PROFILE_VIEW_CIRCLE_SESSION__UPDATE_SESSION_ERROR_MAXIMUM_PARTICIPANT: 'Max participants should not be less than already registered member count!',
  WIDTHDRAWN_SUCCESS_MESSAGE: 'Registration withdrawn.',
  /* MEANING COMMUNITY*/
  ADD_REQUEST_COMMUNITY_CREATE_COMMUNITY_SUCCESS: 'Request saved!',
  ADD_REQUEST_COMMUNITY_CREATE_COMMUNITY_ERROR: 'We encountered an error saving your request.',
  ADD_REQUEST_COMMUNITY_CREATE_COMMUNITY_ERROR1: 'We encountered an error saving your data.',
  ADD_REQUEST_COMMUNITY_UPDATE_COMMUNITY_REJECT_SUCCESS: 'Explorer request rejected.',
  ADD_REQUEST_COMMUNITY_UPDATE_COMMUNITY_APPROVE_SUCCESS: 'Explorer request approved.',
  ADD_REQUEST_COMMUNITY_UPDATE_COMMUNITY_ERROR: 'We encountered an error saving your data.',
  ACTIVITY_FEED_DETAILS_POPUP_VIEW_CONTENT_DETAILS_ERROR: 'Content no longer available.',
  ACTIVITY_FEED_DETAILS_POPUP_TAG_AS_CHARE_SUCCESS: 'Content has been tagged with your ‘cheer’ tag!',
  ACTIVITY_FEED_DETAILS_POPUP_UNTAG_AS_CHARE_SUCCESS: 'Your ‘cheer’ tag has been removed!',
  ACTIVITY_FEED_DETAILS_POPUP_TAG_AS_RESONATE_SUCCESS: 'Content has been tagged with your ‘I Resonate’ tag!',
  ACTIVITY_FEED_DETAILS_POPUP_UNTAG_AS_RESONATE_SUCCESS: 'Your ‘I Resonate’ tag has been removed!',
  ACTIVITY_FEED_DETAILS_POPUP_TAG_AS_LEARND_SUCCESSS: 'Content has been tagged with your ‘I Learned’ tag!',
  ACTIVITY_FEED_DETAILS_POPUP_UNTAG_AS_LEARND_SUCCESS: 'Your ‘I Learned’ tag has been removed!',
  ACTIVITY_FEED_DETAILS_POPUP_TAG_AS_INSIPED_SUCCESS: 'Content has been tagged with your ‘I am Inspired’ tag!',
  ACTIVITY_FEED_DETAILS_POPUP_UNTAG_AS_INSIPED_SUCCESS: 'Your ‘I am Inspired’ tag has been removed!',
  ACTIVITY_FEED_DETAILS_POPUP_SHARED_AS_POST: 'Content shared as a post in your Meaning Feed.',
  ACTIVITY_FEED_DETAILS_POPUP_ADD_AS_TREASSURER_SUCCESS: 'Added to Treasures!',
  ACTIVITY_FEED_DETAILS_POPUP_ADD_AS_TRESSURER_ALREADY_SUCCESS: 'This content has already been added to your Treasures.',
  ACTIVITY_FEED_DETAILS_POPUP_DELETE_COLLAB_CONTENT_SUCCESS: 'Content deleted.',
  ACTIVITY_FEED_DETAILS_POPUP_DELETE_COMMENT_SUCCESS: 'Comment deleted.',
  MYCOMMUNITY_CREATE_NEW_DIALOG: '',
  MYCOMMUNITY_CREATE_NEW_DIALOG_SUCCESS: 'Dialog saved!',
  MYCOMMUNITY_CREATE_NEW_DIALOG_ERROR: 'We encountered an error saving the Dialog.',
  MYCOMMUNITY_CREATE_ACTION_SUCCESS: 'Action saved!',
  MYCOMMUNITY_CREATE_ACTION_ERROR: 'We encountered an error saving this Action.',
  MYCOMMUNITY_CREATE_INSIGHTS_SUCCESS: 'Insight saved!',
  MYCOMMUNITY_CREATE_INSIGHTS_ERROR: 'We encountered an error saving this Insight.',
  MYCOMMUNITY_CREATE_MESSAGE_SUCCESS: 'Message sent!',
  MYCOMMUNITY_CREATE_MESSAGE_ERROR: 'We encountered an error sending your message.',
  MY_COMMUNITY_CREATE_MEANING_POST_SUCCESS: 'Content published!',
  MY_COMMUNITY_CREATE_MEANING_POST_ERROR: 'We encountered an error saving your content.',
  MY_COMMUNITY_VIDEO_PREVIEW_ERROR: 'File type invalid. You can only upload video files.',
  MY_COMMUNITY_VIDEO_PREVIEW_SIZE_ERROR: 'Video file size must be less than 15MB.',
  MY_COMMUNITY_IMAGE_PREVIEW_ERROR: 'File type invalid. You can only upload image files.',
  MY_COMMUNITY_IMAGE_SIZE_ERROR: 'Image file size must be less than 2MB.',
  MY_COMMUNITIES_VIEW_DIALOG_DETAILS_WITHDRAW_REGISTRATION_SUCCESS: 'Registration withdrawn.',
  JOIN_COMMUNITY_FROM_CLIENT_SIDE: 'You have joined this Community!',
  LEAVE_COMMUNITY_FROM_CLIENT_SIDE: 'You have left this Community.',
  REGISTER_DIALOG_FROM_CLIENT_SIDE: 'Dialog registration successful!',
  WITHDRAW_DIALOG_FROM_CLIENT_SIDE: 'You have withdrawn from this Dialog.',
 /* MEANING CONSELLATION */
  COLLAB_CONTENT_DETAILS_POPUP_RATING_SUCCESS: 'Rating you provided has been updated!',
  COLLAB_CONTENT_DETAILS_POPUP_TAG_AS_CHEER_SUCCESS: 'Content has been tagged with your ‘cheer’ tag!',
  COLLAB_CONTENT_DETAILS_POPUP_UNTAG_AS_CHEER_SUCCESS: 'Your ‘cheer’ tag has been removed!',
  COLLAB_CONTENT_DETAILS_POPUP_TAG_AS_RESONATE_SUCCESS: 'Content has been tagged with your ‘I Resonate’ tag!',
  COLLAB_CONTENT_DETAILS_POPUP_UNTAG_AS_RESONATE_SUCCESS: 'Your ‘I Resonate’ tag has been removed!',
  COLLAB_CONTENT_DETAILS_POPUP_TAG_AS_LEARND_SUCCESS: 'Content has been tagged with your ‘I Learned’ tag!',
  COLLAB_CONTENT_DETAILS_POPUP_UNTAG_AS_LEARND_SUCCESS: 'Your ‘I Learned’ tag has been removed!',
  COLLAB_CONTENT_DETAILS_POPUP_TAG_AS_INSPIRED_SUCCESS: 'Content has been tagged with your ‘I am Inspired’ tag!',
  COLLAB_CONTENT_DETAILS_POPUP_UNTAG_AS_INSPIRED_SUCCESS: 'Your ‘I am Inspired’ tag has been removed!',
  COLLAB_CONTENT_DETAILS_POPUP_AS_TO_TREASURER_SUCCESS: 'Added to Treasures!',
  COLLAB_CONTENT_DETAILS_POPUP_AS_TO_TREASURER_ALREADY_SUCCESS: 'This content has already been added to your Treasures.',
  COLLAB_CONTENT_DETAILS_POPUP_COLLAB_CONTENT_DELETE_SUCCESS: 'Content deleted.',
  COLLAB_CONTENT_DETAILS_POPUP_DELETE_COMMENT_SUCCESS: 'Comment deleted.',
  REQUEST_NEW_CONSTELLATION_CREATE_SUCCESS: 'Request saved!',
  REQUEST_NEW_CONSTELLATION_CREATE_ERROR: 'We encountered an error saving your request.',
  REQUEST_NEW_CONSTELLATION_ACCEPT_STATUS_SUCCESS: 'Content request accepted.',
  REQUEST_NEW_CONSTELLATION_REJECT_STATUS_SUCCESS: 'Content request rejected.',
  SHARED_AS_POST_POPUP_SAVE_SUCCESS: 'Post shared!',
  SHARED_AS_POST_POPUP_SAVE_ERROR: 'It looks like something’s out of place. Please try again. If this error persists, please contact Support at support.meaningsphere.com.',
  SHARED_AS_POST_POPUP_UPDATE_SUCCESS: 'Post updated!',
  SHARED_AS_POST_POPUP_UPDATE_ERROR: 'It looks like something’s out of place. Please try again. If this error persists, contact your system administrator.',
  SHARES_AS_POST_POPUP_VIEW_WALLOFFAME_ERROR: 'Content no longer available.',
  /* MEANING MIRROR */
  FEEDBACK_UPDATE_SUCCESS: 'Feedback saved!',
  MIRROR_LIST_SHARE_WITH_GUIDE_SUCCESS: 'Reflection shared with your Guide.',
  MIRROR_LIST_SHARE_WITH_GUIDE_ALREADY_SUCCESS: 'This Reflection has already been shared with your Guide. ',
  MIRROR_LIST_SHARE_WITH_GUIDE_ERROR: 'You do not currently have a Guide.',
  TAG_TOPIC_UPDATE_SUCCESS: 'Topics updated!',
  TAG_TOPIC_UPDATE_ERROR: 'We encountered an error saving your topic.',
  TAG_TOPIC_onChecklistWithInputBoxChange_ERROR: 'You can only select up to 3 topics.',
  /* MEANING NETWORK */
  EDUCATION_FORM_PROFILE_SAVE_SUCCESS: 'Profile saved!',
  EDUCATION_FORM_PROFILE_SAVE_ERROR: 'We encountered an error saving your profile. Please try again.',
  MEANING_CENTRAL_PROFILE_HANDLE_FILE_INPUT_ERROR: 'File type invalid. You can only upload image files.',
  MEANING_CENTRAL_PROFILE_POST_PROFILE_PHOTO_DATA_SUCCESS: 'Profile photo updated!',
  MEANING_CENTRAL_PROFILE_POST_PROFILE_PHOTO_DATA_ERROR: 'We encountered an error uploading your profile photo. Please try again.',
  MEANING_CENTRAL_PROFILE_WHAT_ICARE_ABOUT_SUCCESS: 'Profile saved!',
  MEANING_CENTRAL_PROFILE_WHAT_ICARE_ABOUT_ERROR: 'We encountered an error saving your profile. Please try again.',
  MEANING_CENTRAL_PROFILE_GUIDE_DESCRIPTION__SUCCESS: 'Profile saved!',
  MEANING_CENTRAL_PROFILE_GUIDE_DESCRIPTION__ERROR: 'We encountered an error saving your profile. Please try again.',
  MEANING_CENTRAL_PROFILE_GUIDE_HIGHLIGHT__SUCCESS: 'Profile saved!',
  MEANING_CENTRAL_PROFILE_GUIDE_HIGHLIGHT__ERROR: 'We encountered an error saving your profile. Please try again.',
  MEANING_CENTRAL_PROFILE_MEMBER_ADDITIONAL__SUCCESS: 'Profile saved!',
  MEANING_CENTRAL_PROFILE_MEMBER_ADDITIONAL__ERROR: 'We encountered an error saving your profile. Please try again.',
  MEANING_CENTRAL_PROFILE_POST_PROFILE_DATA_SUCCESS: 'Profile saved!',
  MEANING_CENTRAL_PROFILE_POST_PROFILE_DATA_ERROR: 'We encountered an error saving your profile. Please try again.',
  MEANING_CENTRAL_PROFILE_WITH_ADDRESS_DATA_SUCCESS: 'Address updated successfully!',
  MEANING_CENTRAL_PROFILE_WITH_ADDRESS_DATA_ERROR: 'We encountered an error saving your profile. Please try again.',
  MEANING_CENTRAL_PROFILE_handleVideoFileInput_ERROR: 'File type invalid. You can only upload video files.',
  MEANING_CENTRAL_PROFILE_handleVideoFileInput_SIZE_ERROR: 'Video file size must be less than 15MB.',
  MEANING_CENTRAL_PROFILE_GUIDE_VIDEO_UPLOAD_SUCCESS: 'Guide audio updated!',
  MEANING_CENTRAL_PROFILE_GUIDE_VIDEO_UPLOAD_ERROR: 'We encountered an error uploading your Guide video. Please try again.',
  MEANING_CENTRAL_PROFILE_GET_REQUESTS_SENT_TO_ME_ERROR: 'We are having trouble loading your Received Requests. Please try again.',
  MEANING_CENTRAL_PROFILE_HANDLE_REQUEST_ACCEPT_SUCCESS: 'Connection request accepted!',
  MEANING_CENTRAL_PROFILE_HANDLE_REQUEST_DECLINED_SUCCESS: 'Connection request declined.',
  MEANING_CENTRAL_PROFILE_HANDLE_REQUEST_ERROR: 'We encountered an error. Please try again.',
  MEANING_CENTRAL_PROFILE_ENDORSEMENT_ACCEPT_SUCCESS: 'Endorsement accepted!',
  MEANING_CENTRAL_PROFILE_ENDORSEMENT_DECLINED_SUCCESS: 'Endorsement declined.',
  MEANING_CENTRAL_PROFILE_REMOVE_PIC: 'Profile photo removed.',
  MEANING_FEEDS_HOME_CREATE_MEANING_FEED_SUCCESS: 'Post published!',
  MEANING_FEEDS_HOME_CREATE_MEANING_FEED_ERROR: 'We encountered an error publishing your post. Please try again.',
  MEANING_FEEDS_HOME_VIDEO_PREVIEW_ERROR: 'File type invalid. You can only upload video files.',
  MEANING_FEEDS_HOME_VIDEO_PREVIEW_SIZE_ERROR: 'Video file size must be less than 15MB.',
  PROFILE_IMAGE_SIZE_ERROR: 'Size of the Profile photo should be less than 2MB!',
  MEANING_FEEDS_HOME_IMAGE_PREVIEW_ERROR: 'File type invalid. You can only upload image files.',
  MEANING_FEEDS_HOME_IMAGE_PREVIEW_SIZE_ERROR: 'Video file size must be less than 2MB.',
  MEANING_FEEDS_MEANING_CENTRAL_EDIT_SUCCESS: 'Content updated!',
  MEANING_FEEDS_MEANING_CENTRAL_EDIT_ERROR: 'It looks like something’s out of place. Please try again. If this error persists, contact your system administrator.',
  MEANING_FEEDS_MEANING_CENTRAL_FLAG_SAVE_SUCCESS: 'Content flagged.',
  MEANING_FEEDS_MEANING_CENTRAL_FLAG_SAVE_ERROR: 'We encountered an error flagging this content. Please try again.',
  MEANING_FEED_MEANING_CENTRAL_POPUP_VIEW_COLLAB_CONTENT_ERROR: 'This content is no longer available.',
  MEANING_FEED_MEANING_CENTRAL_POPUP_TAG_AS_CHEER_SUCCESS: 'Content has been tagged with your ‘Cheer’ tag!',
  MEANING_FEED_MEANING_CENTRAL_POPUP_UNTAG_AS_CHEER_SUCCESS: 'Your ‘Cheer’ tag has been removed!',
  MEANING_FEED_MEANING_CENTRAL_POPUP_TAG_AS_RESONATE_SUCCESS: 'Content has been tagged with your ‘I Resonate’ tag!',
  MEANING_FEED_MEANING_CENTRAL_POPUP_UNTAG_AS_RESONATE_SUCCESS: 'Your ‘I Resonate’ tag has been removed!',
  MEANING_FEED_MEANING_CENTRAL_POPUP_TAG_AS_LEARND_SUCCESS: 'Content has been tagged with your ‘I Learned’ tag!',
  MEANING_FEED_MEANING_CENTRAL_POPUP_UNTAG_AS_LEARND_SUCCESS: 'Your ‘I Learned’  tag has been removed!',
  MEANING_FEED_MEANING_CENTRAL_POPUP_TAG_AS_INSPIRED_SUCCESS: 'Content has been tagged with your ‘I am Inspired’ tag!',
  MEANING_FEED_MEANING_CENTRAL_POPUP_UNTAG_AS_INSPIRED_SUCCESS: 'Your ‘I am Inspired’ tag has been removed!',
  MEANING_FEED_MEANING_CENTRAL_POPUP_POST_SHARED_SUCCESS: 'Content shared as a post.',
  MEANING_FEED_MEANING_CENTRAL_POPUP_AS_TO_TREASURER_SUCCESS: 'Added to Treasures!',
  MEANING_FEED_MEANING_CENTRAL_POPUP_AS_TO_TREASURER_ALREADY_SUCCESS: 'This content has already been added to your Treasures.',
  MEANING_FEED_MEANING_CENTRAL_POPUP_COLLAB_CONTENT_DELETE_SUCCESS: 'Content deleted.',
  MEANING_FEED_MEANING_CENTRAL_POPUP_DELETE_COMMENT_SUCCESS: 'Comment deleted.',
  MEANINGFULL_WORK_EXPERIENCE_SAVE_SUCCESS: 'Profile saved!',
  MEANINGFULL_WORK_EXPERIENCE_SAVE_ERROR: 'We encountered an error saving your profile. Please try again.',
  MEANING_LOG_CREATE_SAVE_SUCCESS: 'Meaning Moment published!',
  MEANING_LOG_CREATE_SAVE_ERROR: 'We encountered an error publishing your Meaning Moment.',
  MEANING_LOG_VIDEO_PREVIEW_ERROR: 'File type invalid. You can only upload video files.',
  MEANING_LOG_VIDEO_PREVIEW_SIZE_ERROR: 'Video file size must be less than 15MB.',
  MEANING_LOG_IMAGE_PREVIEW_ERROR: 'File type invalid. You can only upload image files.',
  MEANING_LOG_IMAGE_PREVIEW_SIZE_ERROR: 'Video file size must be less than 2MB.',
  MEANING_LOG_AND_TRESSURER_POPUP_TAG_AS_CHEER_SUCCESS: 'Content has been tagged with your ‘Cheer’ tag!',
  MEANING_LOG_AND_TRESSURER_POPUP_UNTAG_AS_CHEER_SUCCESS: 'Your ‘Cheer’ tag has been removed!',
  MEANING_LOG_AND_TRESSURER_POPUPTAG_AS_RESONATE_SUCCESS: 'Content has been tagged with your ‘I Resonate’ tag!',
  MEANING_LOG_AND_TRESSURER_POPUP_UNTAG_AS_RESONATE_SUCCESS: 'Your ‘I Resonate’ tag has been removed!',
  MEANING_LOG_AND_TRESSURER_POPUP_TAG_AS_LEARND_SUCCESS: 'Content has been tagged with your ‘I Learned’ tag!',
  MEANING_LOG_AND_TRESSURER_POPUP_UNTAG_AS_LEARND_SUCCESS: 'Your ‘I Learned’ tag has been removed!',
  MEANING_LOG_AND_TRESSURER_POPUP_TAG_AS_INSPIRED_SUCCESS: 'Content has been tagged with your ‘I am Inspired’ tag!',
  MEANING_LOG_AND_TRESSURER_POPUP_UNTAG_AS_INSPIRED_SUCCESS: 'Your ‘I am Inspired’ tag has been removed!',
  MEANING_LOG_AND_TRESSURER_POPUP_AS_TO_TREASURER_SUCCESS: 'Added to Treasures!',
  MEANING_LOG_AND_TRESSURER_POPUP_AS_TO_TREASURER_ALREADY_SUCCESS: 'This content has already been added to your Treasures.',
  MEANING_LOG_AND_TRESSURER_POPUP_COLLAB_CONTENT_DELETE_SUCCESS: 'Content deleted.',
  MEANING_LOG_AND_TRESSURER_POPUP_DELETE_TREASURER_SUCCESS: 'Content removed from Treasures. ',
  MEANING_LOG_AND_TRESSURER_POPUP_DELETE_COMMENT_SUCCESS: 'Comment deleted.',
  MY_CONNECTIONS_SUCCESS: 'Connection request sent!',
  MY_CONNECTIONS_ERROR: 'We encountered an error sending your connection request.',
  MY_SETTINGS_SUCCESS: 'Password updated!',
  MY_SETTINGS_ERROR: 'We encountered an error updating your password. Please try again!',
  MY_SETTINGS_ERROR1: 'We encountered an error saving your password. If this error persists, please contact contact Support at support.meaningsphere.com.',
  VOLUNTEER_SAVE_SUCCESS: 'Profile saved!',
  VOLUNTEER_SAVE_ERROR: 'We encountered an error saving your profile. Please try again.',
  BOOKMARKS_SUCCESS: 'You have removed the Bookmark item!',
  TREASURER_SUCCESS: 'You have removed the Treasure item!',
  MEANING_MOMENT_SUCCESS: 'You have removed the Meaning Moments item!',
  WHAT_I_CARE_ABOUT_SUCCESS: 'What Matters To Me saved!',
  WHAT_I_CARE_ABOUT_ERROR: 'We encountered an error to saving What Matters To Me. Please try again!',

  MEANING_FEED_MEANING_CENTRAL_POPUP_AS_TO_BOOKMARK_SUCCESS: 'Added to Bookmarks!',
  MEANING_FEED_MEANING_CENTRAL_POPUP_AS_TO_BOOKMARK_ALREADY_SUCCESS: 'This content has already been added to your Bookmarks.',
  /* MEANING MENTOR */
  DISCONTINEW_GUIDE_SUCCESS: ' has been removed as your Guide.',
  DISCONTINEW_GUIDE_ERROR_STRING1: 'We encountered an error processing your request to discontinue ',
  DISCONTINEW_GUIDE_ERROR_STRING2: ' as your Guide. Please try again.',
  ENDORSE_GUIDE_ENDORSE_DISCUSSION_SUCCESS: 'Your document has been uploaded!',
  ENDORSE_GUIDE_ENDORSE_DISCUSSION_ERROR: 'We encountered an error uploading your document. Please try again.',
  ENDORSE_GUIDE_ENDORSE_DOCUMENT_SUCCESS1: 'Document shared with ',
  ENDORSE_GUIDE_ENDORSE_DOCUMENT_SUCCESS2: ' successfully!',
  ENDORSE_GUIDE_ENDORSE_DOCUMENT_SUCCESS3: 'Sharing revoked for this document.',
  ENDORSEMENT_DIALOG_SUCCESS: 'Endorsement sent!',
  ENDORSEMENT_DIALOG_ERROR: 'We encountered an error sending your endorsement. Please try again.',
  MY_GUIDED_EXPLORER_REQUESTS_ACCEPT_SUCCESS: 'Request accepted!',
  MY_GUIDED_EXPLORER_REQUESTS_DECLINED_SUCCESS: 'Request declined.',
  MY_GUIDED_EXPLORER_SESSION_INSIGHTS_SUCCESS: 'Insight saved!',
  MY_GUIDED_EXPLORER_SESSION_INSIGHTS_ERROR: 'We encountered an error saving this Insight.',
  MY_GUIDED_EXPLORER_SESSION_INSIGHTS_ALL_COMMENTS_ERROR: 'We encountered an error loading the Insights. Please try again.',
  MY_GUIDED_EXPLORER_SHARED_SPACE_DOCUMENT_SUCCESS1: 'Document has been shared with ',
  MY_GUIDED_EXPLORER_SHARED_SPACE_DOCUMENT_SUCCESS2: ' successfully',
  MY_GUIDES_READ_REQUESTS_MESSAGE_SUCCESS: 'Request withdrawn.',
  MY_MENTEE_INFO: 'Explorer has not yet completed the Map of Meaning Survey!',
  PRIVATE_NOTE_SUCCESS: 'Notes updated!',
  PRIVATE_NOTE_ERROR: 'We encountered an error saving your Notes. Please try again.',
  REQUEST_TO_BECOME_A_GUIDE_SUCCESS: 'Request sent!',
  REQUEST_TO_BECOME_A_GUIDE_ERROR: 'You have already sent request to this Guide.',
  REQUEST_TO_BECOME_A_GUIDE_ERROR1: 'We encountered an error sending your request. Please try again.',
  REQUEST_INTRO_SESSION_SUCCESS: 'Request for Get Acquainted session sent.',
  REQUEST_INTRO_SESSION_ERROR: 'You have already requested a complimentary session with this Guide.',
  REQUEST_INTRO_SESSION_ERROR1: 'We encountered an error sending your request. Please try again.',
  SEND_REQUEST_TOBE_MY_GUIDE_SUCCESS: 'Request sent!',
  SEND_REQUEST_TOBE_MY_GUIDE_ERROR: 'You have already requested this Guide.',
  SEND_REQUEST_TOBE_MY_GUIDE_ERROR1: 'We encountered an error sending your request. Please try again.',
  SETUP_SESSION_SUCCESS: 'Session created.',
  SETUP_SESSION_ERROR: 'We encountered an error creating the session. Please try again.',
  UPDATE_SESSION_REQUEST_SUCCESS: 'Session updated.',
  UPDATE_SESSION_REQUEST_ERROR: 'We encountered an error updating the session. Please try again.',
  /*  THEME MESSAGES */
  NOTIFICATIONS_SUCCESS: 'Notification deleted.',
  /* Cart Item */
  CART_ITEM_ADD_SUCCESS: 'Added to cart!',
  CART_ITEM_ADD_ERROR: 'We encountered an error adding that item to the cart. Please try again.',
  PAYMENT_MESSAGE_SUCCESS: 'Your purchase has been successfully processed!',
  PAYMENT_MESSAGE_ERRROR: 'We encountered an error while processing the Cart! Please try again. If you see this message repeatedly, contact your System Administrator.',
  ITEM_REMOVED_FROM_CART_MESSAGE_SUCCESS: 'Removed from cart.',
  ITEM_REMOVED_FROM_CART_MESSAGE_ERROR: 'We encountered an error removing that item from the cart.',
  /* Set up discussion */
  ADD_SETUP_DISCUSSION_SUCCESS: 'Discussion created!',
  SETUP_DISCUSSION_ERROR: 'We encountered an error creating this discussion. Please try again.',
  UPDATE_SETUP_DISCUSSION_SUCCESS: 'Discussion updated!',
  ACCEPT_DISCUSSION_SUCCESS: 'Discussion accepted and meeting created!',
  ACCEPT_DISCUSSION_ERROR: 'We encountered an error and the discussion was not accepted. Please try again.',
  DECLINED_DISCUSSION_SUCCESS: 'Discussion declined!',
  DECLINED_DISCUSSION_ERROR: 'We encountered an error and the discussion was not declined. Please try again.',
  START_DISCUSSION_SUCCESS: 'Discussion has been started!',
  START_DISCUSSION_ERROR: 'We encountered an error starting the discussion. Please try again.',
  CANCELLED_DISCUSSION_SUCCESS: 'Discussion canceled!',
  CANCELLED_DISCUSSION_ERROR: 'We encountered an error and the discussion was not canceled. Please try again.',
  MARK_AS_COMPLETE_DISCUSSION_SUCCESS: 'Discussion marked as completed.',
  MARK_AS_COMPLETE_DISCUSSION_ERROR: 'We encountered an error and the discussion was not marked as completed. Please try again.',
  JOIN_DISCUSSION_SUCCESS: 'You have joined the discussion!',
  JOIN_DISCUSSION_ERROR: 'We encountered an error while you were attempting to join the discussion. Please try again.',
  WITHDRAW_DISCUSSION_SUCCESS: 'Discussion request withdrawn.',
  WITHDRAW_DISCUSSION_ERROR: 'We encountered an error and the discussion request was not withdrawn. Please try again.',
  EVENT_CANCELLATION_SUCCESS: 'Cancelation request shared with your Guide.',
  EVENT_CANCELLATION_ERROR: 'We encountered an error sharing your cancelation request with your Guide. Please try again.',
  EVENT_CANCELLED_SUCCESS: 'Discussion canceled.',
  EVENT_CANCELLED_ERROR: 'We encountered an error while you were attempting to cancel the discussion. Please try again.',

  CIRCLE_DIALOG_UPDATE_SUCCESS: 'Dialog has been updated!',
  CIRCLE_DIALOG_UPDATE_ERROR: 'We encountered an error updating the Dialog! Please try again. If you see this message repeatedly, contact your System Administrator.',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__PROFILE_VIEW_DIALOG_COMMUNITY_SET_DIALOG_CANCELLED: 'This Dialog has been Canceled!',
  VIEW_COMMUNITY_DIALOG_VIEW_COMMUNITY__VIEW_COMMUNITY_DIALOG__PROFILE_VIEW_DIALOG_COMMUNITY_SET_DIALOG_PUBLISHED: 'This Dialog has been Published!',
  ACTION_DELETE_SUCCESS: 'Action deleted Successfully',
  INSIGHT_DELETE_SUCCESS: 'Insight deleted Successfully',
  SUMMARY_DELETE_SUCCESS: 'Summary deleted Successfully',
  ZOOM_MEETING_TIME_ERROR: 'This discussion is already in the past. You can schedule a new discussion appropriately.',
  COMMENTS_SUCCESS: 'Your comment has been saved successfully!',
  COUNTRY_FLAG_VALIDATION_ERROR: 'Thank you for your interest in MeaningSphere! Currently, we are not able to accept payments from your country. We apologize for the inconvenience as we continue to expand access to paid MeaningSphere offerings. We will let you know once we are able to accept payments from your country.',
  FLAG_CONTENT_DETAILS_FLAG_COMMENT_KEEP_SUCCESS: 'This comment has been retained.',
  FLAG_CONTENT_DETAILS_FLAG_COMMENTS_DETAILS_POPUP_DELETE_COLLAB_CONTENT_SUCCESS: 'Comment removed.',
  FLAG_CONTENT_DETAILS_FLAG_COMMENT_KEEP_ERROR: 'We encountered an error in retaining the comment! Please try again. If you see this message repeatedly, contact your System Administrator.',
  FLAG_CONTENT_DETAILS_FLAG_POST_KEEP_SUCCESS: 'This post has been retained.',
  DISCONNECT_GUIDE_EXPLORER_DIRECTOR_ERROR: 'We encountered an error while disconnecting the relationship between guide and explorer.',
  DIY_ACTIVITY_SAVE_FOR_LATER: 'DiY activity saved for completion later.',
  MIRROR_REFLECTION_SAVE_FOR_LATER: 'Mirror reflection saved for completion later.',
  MEANINGFUL_SURVEY_SAVE_FOR_LATER: 'Meaningful work survey saved for completion later.',
  INVOICE_NOT_GENERATE: 'Invoice not available for this purchase.',
  PAYMENT_SUCCESS: 'Thank you for your purchase. Your payment has been processed successfully!',
  PAYMENT_ERROR: 'We encountered an error while processing your payment. You have not been charged for this payment. Please try again.',
  REQUEST_USER_INFO: 'This Explorer is no longer Active!',
  /* Topic message */
  TOPIC_ERROR: 'We encountered an error processing your request. Please try again.',
  TOPIC_CREATE_SUCCESS: 'Topic created successfully!',
  TOPIC_UPDATE_SUCCESS: 'Topic updated successfully!',
  TOPIC_DEPRECATED_SUCCESS: 'Topic deprecated successfully!',
  TOPIC_ARCHIVE_SUCCESS: 'Topic archived successfully!',
  TOPIC_PUBLISHED_SUCCESS: 'Topic published successfully!',
  /* Download report */
  DOWNLOAD_REPORT_SUCCESS: 'Your report has been downloaded!',
  DOWNLOAD_REPORT_ERROR: 'We encountered an error while processing your request. Please try again.',
  DOWNLOAD_REPORT_NO_DATA_FOUND: 'No data found to download report.',
  /* common error for backend */
  GENERAL_DATA_ERROR: 'We encountered an error while processing your request. Please try again.',
  TOPIC_DRAFT_SUCCESS: 'Topic moved to draft successfully!',
  COUNTRY_STATUS_CHANGE_LOG_SUCCESS: 'Country status has been changed.',
  COUNTRY_STATUS_CHANGE_LOG_ERROR: 'Please select different status!',
  CATALOG_PRICE_UPDATE_SUCCESS: 'Catalog price updated!',
};
