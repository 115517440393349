import { Subscription } from 'rxjs';
import { SharedAsPostPopupComponent } from './../shared-as-post-popup/shared-as-post-popup.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { SendMassageComponent } from './../../admin-page/send-massage/send-massage.component';
import { NotificationService } from '../../../../service/notification.service';
import { MeaningConstellationService } from '../../../../service/meaning-constellation.service';
import {
  NgForm,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { CuratorPageService } from '../../../../service/curator-page.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MeaningCentralEdit } from '../../meaning-network/meaning-feeds/meaning-central-edit/meaning-central-edit';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { message_properties } from '../../../../../environments/message_properties';

import {
  Component,
  OnInit,
  Optional,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { MeaningCentralFlagComponent } from '../../meaning-network/meaning-feeds/meaning-central-flag/meaning-central-flag.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewProfileComponent } from '../../admin-page/view-profile/view-profile.component';
import { CommonService } from '../../../../service/common.service';
import { AccountsService } from './../../../../service/accounts.service';
import { TimezoneService } from '../../../../service/timezone.service';
import * as moment from 'moment';

@Component({
  selector: 'kt-collab-content-details-popup',
  templateUrl: './collab-content-details-popup.component.html',
  styleUrls: [
    './collab-content-details-popup.component.scss',
    './../../../shared/post-popup-common.scss',
  ],
})
export class CollabContentDetailsPopupComponent implements OnInit, OnDestroy {
  commentsForm: UntypedFormGroup;
  collabContentId: number;
  attachment_path: string;
  ufullname: string;
  user_profile_path: string;
  description: string;
  link_url: any;
  commentsArr: [];
  cheerUrl: string = './assets/my-feeds/Cheer_Icon.png';
  iresonateUrl: string = './assets/my-feeds/Resonate_Icon.png';
  ilearnedUrl: string = './assets/my-feeds/Learned_Icon.png';
  iaminspiredUrl: string = './assets/my-feeds/Inspired_Icon.png';
  comments_count: number = 0;
  loggedInUserId: number;
  iaminspired_count: number;
  ilearned_count: number;
  iresonate_count: number;
  cheer_count: number;
  title: string;
  recommended_indicator: boolean = false;
  cheerOpen: boolean = false;
  startRatingOpen: boolean = false;
  shareOpen: boolean = false;
  ms_recommends: string;
  video_path: string;
  referenceUrl: string = null;

  ratings: any[] = [
    { points: null },
    { points: 1 },
    { points: 2 },
    { points: 3 },
    { points: 4 },
    { points: 5 },
  ];
  stars: any[] = [];
  averageStars: any[] = [];
  star: number = 0;
  rate: number;
  over: number;
  leave: number;
  video_indicator: number;
  collab_content_category: number;
  avg_rating: number;
  my_rating: number;
  my_cheer: number;
  my_iaminspired: number;
  my_ilearned: number;
  my_iresonate: number;
  clickFrom: number;
  creatingUserFromDatabase: number;
  link_preview_data: [];
  link_title: string;
  link_description: string;
  link_absolute_image: string;
  link_image: string;
  link_force_title: string;
  found: boolean = true;
  how_many_person_give_rating_count: number;
  profileData = [];
  profile_path: string;
  starRatingSubscription: Subscription;
  viewWallFameSubscription: Subscription;
  saveCommentSubscription: Subscription;
  cheerSubscription: Subscription;
  resonateSubscription: Subscription;
  learnSubscription: Subscription;
  inspiredSubscription: Subscription;
  myTreasureSubscription: Subscription;
  collabDeleteSubscription: Subscription;
  commentDeleteSubscription: Subscription;
  bucketBookmarkSubscription: Subscription;
  view_rating: number;
  additional_info: string;
  preview = {};
  post_treasured_user_wise: boolean = false;
  post_bookmarked_user_wise: boolean = false;
  saveButton: boolean = false;
  public from_date: moment.Moment;
  current_timezone: string;
  constructor(
    public dialogRef: MatDialogRef<CollabContentDetailsPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private curatorService: CuratorPageService,
    private _cdr: ChangeDetectorRef,
    private meaningConstalletion: MeaningConstellationService,
    private toastr: NotificationService,
    private dialog: MatDialog,
    public sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private accountService: AccountsService,
    private timeZoneService: TimezoneService
  ) {
    if (data.from_message !== 'from_message') {
      this.preview = data.link_preview;
      this.additional_info = data.additional_info ?? '';
      this.title = data.title;
      this.description = data.description;
      this.attachment_path =
        data.attachment_path ?? './assets/media/default/post_default.png';
      this.video_indicator = data.video_indicator;
      this.clickFrom = data.clickFrom;
    }
    this.collabContentId = data.collabContentId;
    this.loggedInUserId = this.accountService.muser;

    this.commentsForm = new UntypedFormGroup({
      remarks: new UntypedFormControl('', [Validators.required]),
      rating: new UntypedFormControl(''),
      collab_content: new UntypedFormControl(this.collabContentId),
      user: new UntypedFormControl(this.loggedInUserId),
    });
  }

  ngOnInit(): void {
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.viewWallOfFameComponent(this.collabContentId);
    this.meaningConstalletion.refreshneededMeaningCentralList.subscribe(
      (data) => {
        this.viewWallOfFameComponent(this.collabContentId);
      }
    );
    this.setRating(0);
    this.setAverageRating(0);
    this.loadImage();
  }
  loadImage() {
    this.profile_path =
      localStorage.getItem('image') !== '0'
        ? localStorage.getItem('image')
        : '/assets/media/users/default.png';
  }
  cheerToggle() {
    this.cheerOpen = !this.cheerOpen;
    this.startRatingOpen = false;
    this.shareOpen = false;
  }
  startRatingToggle() {
    this.startRatingOpen = !this.startRatingOpen;
    this.shareOpen = false;
    this.cheerOpen = false;
  }
  shareToggle() {
    this.shareOpen = !this.shareOpen;
    this.startRatingOpen = false;
    this.cheerOpen = false;
  }
  setAverageRating(rate) {
    this.averageStars = [];
    if (rate > 0) {
      for (var i = 0; i < 5; i++) {
        if (i < rate) {
          this.averageStars[i] = { color: 'colored' };
        } else {
          this.averageStars[i] = { color: 'blank' };
        }
      }
    } else {
      for (var i = 0; i < 5; i++) {
        this.averageStars.push({ color: 'blank' });
      }
    }
  }
  setRating(rate) {
    if (rate > 0) {
      for (var i = 0; i < 5; i++) {
        if (i < rate) {
          this.stars[i] = { color: 'colored' };
        } else {
          this.stars[i] = { color: 'blank' };
        }
      }
    } else {
      for (var i = 0; i < 5; i++) {
        this.stars.push({ color: 'blank' });
      }
    }
  }
  lightUpStars() {
    for (var k = 0; k < 5; k++) {
      if (k < this.over) {
        this.stars[k] = { color: 'colored' };
      } else {
        this.stars[k] = { color: 'blank' };
      }
    }
  }
  lightOffStars() {
    if (this.star === 0) {
      for (var j = 0; j <= 4; j++) {
        this.stars[j] = { color: 'blank' };
      }
      this.rate = 0;
    } else {
      for (var j = 0; j < 5; j++) {
        if (j < this.star) {
          this.stars[j] = { color: 'colored' };
        } else {
          this.stars[j] = { color: 'blank' };
        }
      }
      this.rate = this.ratings[this.star].points;
    }
  }
  starRating(rating: number) {
    const formData = {
      rating: rating,
      collab_content: this.collabContentId,
      tenant: 1,
      member: this.loggedInUserId,
    };
    this.starRatingSubscription = this.meaningConstalletion
      .giveRating(formData)
      .subscribe((data) => {
        this.setRating(rating);
        this.toastr.showSuccess(
          message_properties.COLLAB_CONTENT_DETAILS_POPUP_RATING_SUCCESS,
          ''
        );
        this.viewWallOfFameComponent(this.collabContentId);
        this._cdr.detectChanges();
      });
  }
  setCheersData(my_cheer, my_iaminspired, my_ilearned, my_iresonate) {
    if (my_cheer > 0) {
      this.cheerUrl = './assets/my-feeds/Cheer_Icon_Hover.png';
    }
    if (my_iaminspired > 0) {
      this.iaminspiredUrl = './assets/my-feeds/Inspired_Icon_Hover.png';
    }
    if (my_ilearned > 0) {
      this.ilearnedUrl = './assets/my-feeds/Learned_Icon_Hover.png';
    }
    if (my_iresonate > 0) {
      this.iresonateUrl = './assets/my-feeds/Resonate_Icon_Hover.png';
    }
    this._cdr.detectChanges();
  }

  viewWallOfFameComponent(id) {
    this.spinner.show();
    let data = [];
    this.viewWallFameSubscription = this.curatorService
      .getWallOfFameById(id, this.loggedInUserId)
      .subscribe((result) => {
        if (result.code === 200) {
          this.found = true;
          data = JSON.parse(JSON.stringify(result.results));
          this.video_indicator = data['video_indicator'];
          this.attachment_path = data['attachment_path']
            ? data['attachment_path']
            : './assets/media/default/post_default.png';
          this.description = data['description'];
          this.title = data['title'];
          this.avg_rating = data['avg_rating'];
          this.view_rating = data['view_rating'] ?? 0;
          this.how_many_person_give_rating_count =
            data['how_many_person_give_rating_count'];
          this.my_rating =
            data['my_rating'].length > 0 ? data['my_rating'][0].rating : 0;
          this.setAverageRating(this.avg_rating);
          this.setRating(this.my_rating);

          this.cheer_count = data['cheer_count'];
          this.iresonate_count = data['iresonate_count'];
          this.ilearned_count = data['ilearned_count'];
          this.iaminspired_count = data['iaminspired_count'];

          this.my_cheer = data['my_cheer'];
          this.my_iaminspired = data['my_iaminspired'];
          this.my_ilearned = data['my_ilearned'];
          this.my_iresonate = data['my_iresonate'];
          this.setCheersData(
            this.my_cheer,
            this.my_iaminspired,
            this.my_ilearned,
            this.my_iresonate
          );
          this.recommended_indicator = data['recommended_indicator'];
          this.ms_recommends = data['ms_recommends'];

          this.collab_content_category = data['collab_content_category'];
          this.commentsArr = data['comments'];
          this.comments_count = data['comments_count'];
          this.referenceUrl = data['references'];
          this._cdr.detectChanges();
          this.spinner.hide();
        } else {
          this.found = false;
          this.title = 'This content is no longer available';
          this.attachment_path = './assets/media/default/content_not_found.png';
          this._cdr.detectChanges();
          this.spinner.hide();
        }
        this.post_treasured_user_wise = data['post_treasured_user_wise'];
        this.post_bookmarked_user_wise = data['post_bookmarked_user_wise'];
      });
  }

  redirectUrl() {
    return window.open(
      this.link_url,
      'popUpWindow',
      'height=this.height*.8,width=this.width*.8, left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes'
    );
  }

  commentsSave(ngForm: NgForm) {
    this.saveButton = true;
    this.saveCommentSubscription = this.meaningConstalletion
      .createComments(ngForm)
      .subscribe((data) => {
        if (data.status === 201) {
          this.commentsForm.patchValue({
            remarks: '',
          });
          this.saveButton = false;
          this.viewWallOfFameComponent(this.collabContentId);
          this.toastr.showSuccess(message_properties.COMMENTS_SUCCESS, '');
          this._cdr.detectChanges();
        } else if (data.status === 204) {
          this.toastr.showError(data.message, '');
          this._cdr.detectChanges();
        }
      });
  }
  cheer(collabContentId) {
    let formData = {
      collab_content: collabContentId,
      member: this.loggedInUserId,
      action_type: 2569,
      action_for: 2574,
      remarks: 'Cheer',
      collab_content_comment: 0,
    };
    this.cheerSubscription = this.meaningConstalletion
      .createAction(formData)
      .subscribe((data) => {
        if (data.status === 201) {
          this.viewWallOfFameComponent(this.collabContentId);
          this.toastr.showSuccess(
            message_properties.COLLAB_CONTENT_DETAILS_POPUP_TAG_AS_CHEER_SUCCESS,
            ''
          );
          this.cheerUrl = './assets/my-feeds/Cheer_Icon_Hover.png';
          this._cdr.detectChanges();
        } else {
          this.cheerUrl = './assets/my-feeds/Cheer_Icon.png';
          this.viewWallOfFameComponent(this.collabContentId);
          this.toastr.showSuccess(
            message_properties.COLLAB_CONTENT_DETAILS_POPUP_UNTAG_AS_CHEER_SUCCESS,
            ''
          );
          this._cdr.detectChanges();
        }
      });
  }
  iresonate(collabContentId) {
    let formData = {
      collab_content: collabContentId,
      member: this.loggedInUserId,
      action_type: 2569,
      action_for: 2571,
      remarks: 'I Resonate',
      collab_content_comment: 0,
    };
    this.resonateSubscription = this.meaningConstalletion
      .createAction(formData)
      .subscribe((data) => {
        if (data.status === 201) {
          this.viewWallOfFameComponent(this.collabContentId);
          this.toastr.showSuccess(
            message_properties.COLLAB_CONTENT_DETAILS_POPUP_TAG_AS_RESONATE_SUCCESS,
            ''
          );
          this.iresonateUrl = './assets/my-feeds/Resonate_Icon_Hover.png';
          this._cdr.detectChanges();
        } else {
          this.iresonateUrl = './assets/my-feeds/Resonate_Icon.png';
          this.viewWallOfFameComponent(this.collabContentId);
          this.toastr.showSuccess(
            message_properties.COLLAB_CONTENT_DETAILS_POPUP_UNTAG_AS_RESONATE_SUCCESS,
            ''
          );
          this._cdr.detectChanges();
        }
      });
  }
  ilearned(collabContentId) {
    let formData = {
      collab_content: collabContentId,
      member: this.loggedInUserId,
      action_type: 2569,
      action_for: 2572,
      remarks: 'I Learned',
      collab_content_comment: 0,
    };
    this.learnSubscription = this.meaningConstalletion
      .createAction(formData)
      .subscribe((data) => {
        if (data.status === 201) {
          this.viewWallOfFameComponent(this.collabContentId);
          this.toastr.showSuccess(
            message_properties.COLLAB_CONTENT_DETAILS_POPUP_TAG_AS_LEARND_SUCCESS,
            ''
          );
          this.ilearnedUrl = './assets/my-feeds/Learned_Icon_Hover.png';
          this._cdr.detectChanges();
        } else {
          this.ilearnedUrl = './assets/my-feeds/Learned_Icon.png';
          this.viewWallOfFameComponent(this.collabContentId);
          this.toastr.showSuccess(
            message_properties.COLLAB_CONTENT_DETAILS_POPUP_UNTAG_AS_LEARND_SUCCESS,
            ''
          );
          this._cdr.detectChanges();
        }
      });
  }
  iaminspired(collabContentId) {
    let formData = {
      collab_content: collabContentId,
      member: this.loggedInUserId,
      action_type: 2569,
      action_for: 2573,
      remarks: 'I am Inspired',
      collab_content_comment: 0,
    };
    this.inspiredSubscription = this.meaningConstalletion
      .createAction(formData)
      .subscribe((data) => {
        if (data.status === 201) {
          this.viewWallOfFameComponent(this.collabContentId);
          this.toastr.showSuccess(
            message_properties.COLLAB_CONTENT_DETAILS_POPUP_TAG_AS_INSPIRED_SUCCESS,
            ''
          );
          this.iaminspiredUrl = './assets/my-feeds/Inspired_Icon_Hover.png';
          this._cdr.detectChanges();
        } else {
          this.iaminspiredUrl = './assets/my-feeds/Inspired_Icon.png';
          this.viewWallOfFameComponent(this.collabContentId);
          this.toastr.showSuccess(
            message_properties.COLLAB_CONTENT_DETAILS_POPUP_UNTAG_AS_INSPIRED_SUCCESS,
            ''
          );
          this._cdr.detectChanges();
        }
      });
  }
  shareAsPost(collabContentId) {
    this.dialog.open(SharedAsPostPopupComponent, {
      disableClose: true,
      width: '40%',
      data: {
        collabContentId: collabContentId,
        member: this.loggedInUserId,
        bucket_type: 2551,
        visibility: 2573,
      },
    });
  }
  sendMassage(collabContentId) {
    this.dialog.open(SendMassageComponent, {
      disableClose: true,
      width: '40%',
      data: {
        collabContentId: collabContentId,
        messageFrom: 0,
        clicked_from: 2474,
      },
    });
  }
  addToMyTreasures(collabContentId) {
    let formData = {
      collab_content: collabContentId,
      member: this.loggedInUserId,
      bucket_type: 2552,
      visibility: 2573,
    };
    this.myTreasureSubscription = this.meaningConstalletion
      .putInBucket(formData)
      .subscribe((data) => {
        if (data.status === 201) {
          this.post_treasured_user_wise = true;
          this.toastr.showSuccess(
            message_properties.COLLAB_CONTENT_DETAILS_POPUP_AS_TO_TREASURER_SUCCESS,
            ''
          );
          this._cdr.detectChanges();
        } else {
          this.toastr.showError(
            message_properties.COLLAB_CONTENT_DETAILS_POPUP_AS_TO_TREASURER_ALREADY_SUCCESS,
            ''
          );
        }
      });
  }
  addToMyBookmarks(collabContentId) {
    let formData = {
      collab_content: collabContentId,
      member: this.loggedInUserId,
      bucket_type: 2734,
      visibility: 2573,
    };
    this.bucketBookmarkSubscription = this.meaningConstalletion
      .putInBucket(formData)
      .subscribe((data) => {
        if (data.status === 201) {
          this.post_bookmarked_user_wise = true;
          this.toastr.showSuccess(
            message_properties.MEANING_FEED_MEANING_CENTRAL_POPUP_AS_TO_BOOKMARK_SUCCESS,
            ''
          );
          this._cdr.detectChanges();
        } else {
          this.toastr.showError(
            message_properties.MEANING_FEED_MEANING_CENTRAL_POPUP_AS_TO_BOOKMARK_ALREADY_SUCCESS,
            ''
          );
        }
      });
  }
  updateCollabContent() {
    this.dialog.open(MeaningCentralEdit, {
      width: '50%',
      disableClose: true,
      data: { collabContantId: this.collabContentId },
    });
  }
  deleteCollabContent(id) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to DELETE this content.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        if (this.clickFrom !== 2552) {
          this.collabDeleteSubscription = this.meaningConstalletion
            .deleteCollabContentDetailsWithRelative(id)
            .subscribe((data) => {
              this.toastr.showSuccess(
                message_properties.COLLAB_CONTENT_DETAILS_POPUP_COLLAB_CONTENT_DELETE_SUCCESS,
                ''
              );
              this.dialog.closeAll();
            });
          this._cdr.detectChanges();
        }
      }
    });
  }
  basicPopup(url) {
    return window.open(
      url,
      'popUpWindow',
      'height=this.height*.8,width=this.width*.8,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
    );
  }
  deleteComments(commentId) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to DELETE this comment.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.commentDeleteSubscription = this.meaningConstalletion
          .deleteComments(commentId)
          .subscribe((data) => {
            this.viewWallOfFameComponent(this.collabContentId);
            this.toastr.showSuccess(
              message_properties.COLLAB_CONTENT_DETAILS_POPUP_DELETE_COMMENT_SUCCESS,
              ''
            );
          });
        this._cdr.detectChanges();
      }
    });
  }

  clickOnEnterButton(event, commentsForm) {
    if (event.keyCode === 13) {
      this.commentsSave(commentsForm);
      this.commentsForm.patchValue({
        remarks: '',
      });
      this.saveButton = false;
    }
  }
  flagCommentsOpen(collabContentId, commentId, comment_flagged_user_wise_data) {
    let flag_data_id = 0;
    let inappropriate_content_indicator = false;
    let unrelated_content_indicator = false;
    let other_indicator = false;
    let remarks = null;
    if (comment_flagged_user_wise_data.length > 0) {
      flag_data_id = comment_flagged_user_wise_data[0]['id'];
      inappropriate_content_indicator =
        comment_flagged_user_wise_data[0]['inappropriate_content_indicator'];
      unrelated_content_indicator =
        comment_flagged_user_wise_data[0]['unrelated_content_indicator'];
      other_indicator = comment_flagged_user_wise_data[0]['other_indicator'];
      remarks = comment_flagged_user_wise_data[0]['remarks'];
    }

    const dialogRef = this.dialog.open(MeaningCentralFlagComponent, {
      disableClose: true,
      width: '30%',
      data: {
        collabContentId: collabContentId,
        commentId: commentId,
        flag_data_id: flag_data_id,
        inappropriate_content_indicator: inappropriate_content_indicator,
        unrelated_content_indicator: unrelated_content_indicator,
        other_indicator: other_indicator,
        remarks: remarks,
        action_for: 2758,
        flag_from: 'meaning_central_comment',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.viewWallOfFameComponent(this.collabContentId);
    });
  }
  viewProfile(member_id, first_name, last_name, picture_path, member_status) {
    if (!this.checkStatus(member_status)) {
      let dialogRef = this.dialog.open(ViewProfileComponent, {
        width: '80%',
        disableClose: true,
        data: {
          member: member_id,
          first_name: first_name,
          last_name: last_name,
          picture_path: picture_path,
          click_from: 'popup',
        },
      });
    }
  }
  checkStatus(status) {
    return CommonService.checkMemberStatus(status);
  }
  dateInUserTimezome(datetime: string) {
    this.from_date = moment(datetime).utc();
    this.timeZoneService.setTenantTimeZone(this.current_timezone);
    return this.timeZoneService.utcToTenantString(this.from_date, 'LLL');
  }
  ngOnDestroy(): void {
    if (this.starRatingSubscription) {
      this.starRatingSubscription.unsubscribe();
    }
    if (this.viewWallFameSubscription) {
      this.viewWallFameSubscription.unsubscribe();
    }
    if (this.saveCommentSubscription) {
      this.saveCommentSubscription.unsubscribe();
    }
    if (this.cheerSubscription) {
      this.cheerSubscription.unsubscribe();
    }
    if (this.resonateSubscription) {
      this.resonateSubscription.unsubscribe();
    }
    if (this.learnSubscription) {
      this.learnSubscription.unsubscribe();
    }
    if (this.inspiredSubscription) {
      this.inspiredSubscription.unsubscribe();
    }
    if (this.myTreasureSubscription) {
      this.myTreasureSubscription.unsubscribe();
    }
    if (this.collabDeleteSubscription) {
      this.collabDeleteSubscription.unsubscribe();
    }
    if (this.commentDeleteSubscription) {
      this.commentDeleteSubscription.unsubscribe();
    }
    if (this.bucketBookmarkSubscription) {
      this.bucketBookmarkSubscription.unsubscribe();
    }
  }
}
