import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kt-surveyreport5',
  templateUrl: './surveyreport5.component.html',
  styleUrls: ['./surveyreport5.component.scss'],
})
export class Surveyreport5Component implements OnInit {
  myChart: [];
  reportStr: string;
  reportData = [];
  unity_with_other: number;
  service_to_others: number;
  expressing_full_potential: number;
  integrity_with_self: number;
  unity_with_other_default: number;
  service_to_others_default: number;
  expressing_full_potential_default: number;
  integrity_with_self_default: number;
  @Input() memberId;
  @Input() currentSelectedSurvey;
  @Input() json_report;

  constructor() {}
  ngOnInit() {
    this.reportData = JSON.parse(this.json_report);
    this.unity_with_other = this.reportData['unity_with_others_value'];
    this.unity_with_other_default = this.reportData[
      'unity_with_others_default'
    ];
    this.service_to_others = this.reportData['service_to_others_value'];
    this.service_to_others_default = this.reportData[
      'service_to_others_default'
    ];
    this.expressing_full_potential = this.reportData[
      'expressing_full_potential_value'
    ];
    this.expressing_full_potential_default = this.reportData[
      'expressing_full_potential_default'
    ];
    this.integrity_with_self = this.reportData['integrity_with_self_value'];
    this.integrity_with_self_default = this.reportData[
      'integrity_with_self_default'
    ];
  }
}
