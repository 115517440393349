
<kt-spinner></kt-spinner>
<div class="card register_card absolutueCenter" id="registercard">
  <div class="title_section">
    <mat-card-title>
      <h1 class="d-flex align-items-center justify-content-between">
        <span class="register_heading">
          Create your MeaningSphere account!
          <h1 class="fs-18 mt-2">All fields are required. Your name, city, and country will be visible on your profile.
          </h1>
        </span>
        <img class="sphere" src="./assets/media/users/default1.png" />
      </h1>

    </mat-card-title>
    <button class="close-dialog" routerLink="/login">X</button>
    <!-- <button class="close-dialog" routerLink="/home">X</button> -->
  </div>

  <form [formGroup]="RegisterForm" (ngSubmit)="onSubmit(RegisterForm.value)" novalidate autocomplete="off"
    class="d-flex align-items-end">
    <mat-dialog-content class="mtn20">
      <div class="example-container register_example w-100">
        <div class="row registerRow">
          <div class="form-group col-sm-2 mt-20 df-center">
            <span> My Name Is</span>
          </div>
          <div class="form-group col-sm-3 ">
            <mat-form-field class="">
              <mat-label>First Name</mat-label>

              <input matInput type="text" formControlName="first_name" #first_name
                oninput="this.value = this.value.replace(/[^A-Za-z-'\- ]|^ /g,'')" autocomplete="off" [tabindex]="1" />
            </mat-form-field>
          </div>
          <div class="form-group col-sm-3">
            <mat-form-field>
              <mat-label>Last Name</mat-label>

              <input matInput type="text" formControlName="last_name"
                oninput="this.value = this.value.replace(/[^A-Za-z-'\- ]|^ /g,'')" autocomplete="off" [tabindex]="2" />
            </mat-form-field>
          </div>
        </div>
        <div class="row registerRow">
          <div class="form-group col-sm-2 mt-20 df-center">
            <span> And I live in</span>
          </div>
          <div class="form-group col-sm-3 country-parent-div d-flex flex-column align-items-start justify-content-end">
            <label *ngIf="countrySelected">Country *</label>
            <ngx-select class="w-100 register-c" placeholder="Country *" [items]="countryList" optionValueField="id"
              optionTextField="name" formControlName="countryText" (selectionChanges)="setCountry($event)"
              [tabIndex]="3"></ngx-select>
          </div>
          <div class="form-group col-sm-3  city-parent-div">
            <mat-form-field>
              <mat-label>City</mat-label>

              <input matInput type="text" formControlName="cityText" autocomplete="do-not-autofill" [tabindex]="4"
                #citySearchInput oninput="this.value = this.value.replace(/[^A-Za-z\. ]|^ /g,'')" />
            </mat-form-field>
            <div class="city-box-child-div" *ngIf="cityBox">
              <div class="search-results city-viewport" infinite-scroll [infiniteScrollDistance]="modalScrollDistance"
                [infiniteScrollThrottle]="modalScrollThrottle" [scrollWindow]="false" (scrolled)="pushValue($event)">
                <div class="city-autocom-box">
                  <li *ngFor="let city of cityList" (click)="setCity(city.name, city.state, city.id)">
                    {{ city.city_with_country_code }}
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row registerRow">
          <div class="form-group mt-20 col-sm-2 df-center">
            <span> My email is</span>
          </div>
          <div class="form-group col-sm-6 email100">
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput type="text" formControlName="email" autocomplete="off"
                pattern="^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;._+-@ ]|^ /g,'')" [tabindex]="5" />
            </mat-form-field>

            <label class="text-danger" *ngIf="RegisterForm.controls['email'].hasError('email')">
              Enter a valid email address!
            </label>
            <!-- <label class="text-danger">{{ message }}</label> -->
          </div>
        </div>
        <div class="row registerRow">
          <div class="form-group col-sm-3 mt-20 df-center">
            <span> And I want my password to be</span>
          </div>
          <div class="form-group col-sm-3">
            <mat-form-field>
              <mat-label>Password *</mat-label>
              <input matInput [type]="showPassword ? 'text' : 'password'" type="password" formControlName="password"
                autocomplete="off" tabindex="5" />
              <img class="eyeicon" (click)="showPassword = !showPassword" *ngIf="!showPassword"
                src="../../../../assets/media/visible.png" />
              <img class="eyeicon" (click)="showPassword = !showPassword" *ngIf="showPassword"
                src="../../../../assets/media/invisible.png" />
            </mat-form-field>
          </div>
          <div class="form-group col-sm-3">
            <mat-form-field>
              <mat-label>Confirm Password * </mat-label>
              <input matInput [type]="confirmPassword ? 'text' : 'password'" type="password"
                formControlName="confirmpassword" autocomplete="off" (input)="passwordChangeEntered($event)"
                tabindex="6" />
              <img class="eyeicon" (click)="confirmPassword = !confirmPassword" *ngIf="!confirmPassword"
                src="../../../../assets/media/visible.png" />
              <img class="eyeicon" (click)="confirmPassword = !confirmPassword" *ngIf="confirmPassword"
                src="../../../../assets/media/invisible.png" />
            </mat-form-field>

            <label class="text-danger" *ngIf=" passwordEntered &&
                RegisterForm.controls['confirmpassword'].hasError(
                  'NoPassswordMatch'
                )
              ">
              Passwords do not match
            </label>
          </div>
        </div>
        <div class="row justify-content-betwen">
          <div class="w-100 d-flex flex-wrap justify-content-start">
            <label class="col" [ngClass]="
                RegisterForm.controls['password'].hasError('required') ||
                RegisterForm.controls['password'].hasError('minlength')
                  ? 'text-danger'
                  : 'text-success'
              ">
              <i class="material-icons">{{
                RegisterForm.controls["password"].hasError("required") ||
                RegisterForm.controls["password"].hasError("minlength")
                ? "cancel"
                : "check_circle"
                }}</i>
              At least 8 characters!
            </label>
            <label class="col" [ngClass]="
                RegisterForm.controls['password'].hasError('required') ||
                RegisterForm.controls['password'].hasError('hasNumber')
                  ? 'text-danger'
                  : 'text-success'
              ">
              <i class="material-icons">{{
                RegisterForm.controls["password"].hasError("required") ||
                RegisterForm.controls["password"].hasError("hasNumber")
                ? "cancel"
                : "check_circle"
                }}</i>
              At least 1 number!
            </label>
            <label class="col" [ngClass]="
                RegisterForm.controls['password'].hasError('required') ||
                RegisterForm.controls['password'].hasError('hasCapitalCase')
                  ? 'text-danger'
                  : 'text-success'
              ">
              <i class="material-icons">{{
                RegisterForm.controls["password"].hasError("required") ||
                RegisterForm.controls["password"].hasError("hasCapitalCase")
                ? "cancel"
                : "check_circle"
                }}</i>
              At least 1 in Capital Case!
            </label>
            <label class="col" [ngClass]="
                RegisterForm.controls['password'].hasError('required') ||
                RegisterForm.controls['password'].hasError('hasSmallCase')
                  ? 'text-danger'
                  : 'text-success'
              ">
              <i class="material-icons">{{
                RegisterForm.controls["password"].hasError("required") ||
                RegisterForm.controls["password"].hasError("hasSmallCase")
                ? "cancel"
                : "check_circle"
                }}</i>
              At least 1 in Small Case!
            </label>
            <label class="col" [ngClass]="
                RegisterForm.controls['password'].hasError('required') ||
                RegisterForm.controls['password'].hasError(
                  'hasSpecialCharacters'
                )
                  ? 'text-danger'
                  : 'text-success'
              ">
              <i class="material-icons">{{
                RegisterForm.controls["password"].hasError("required") ||
                RegisterForm.controls["password"].hasError(
                "hasSpecialCharacters"
                )
                ? "cancel"
                : "check_circle"
                }}</i>
              At least 1 Special Character!
            </label>
            <!--Validation end-->
          </div>
          <div class="form-group mt-10">
            <div class="row registerRow">
              <div class="form-group col-md-12 col-lg-2 df-center">
                <span> </span>
              </div>
              <div class="form-group col-md-12 col-lg-6 email100 ">
                <div class="row">
                  <div class="col-md-2 col-lg-1 mt-5">
                    <mat-checkbox formControlName="beta_agreement_indicator" (change)="checkUncheck($event)"
                      class="ml-10" required tabindex="7">
                    </mat-checkbox>
                  </div>
                  <div class="col-md-10 col-lg-11 pl-0">
                    <p>
                      By checking the box and clicking on the "Join Now" button, I confirm that I have read and accepted
                      the MeaningSphere
                      <a href="https://support.meaningsphere.com/hc/en-us/articles/9485738538388-What-are-your-Terms-of-Use-"
                        class="pp_underline" target="__blank" tabindex="8">Terms of Use</a> and <a
                        href="https://support.meaningsphere.com/hc/en-us/articles/9485678228372-What-is-your-Code-of-Conduct-"
                        class="pp_underline" target="__blank" tabindex="9">Code of Conduct</a>, and I consent to the
                      MeaningSphere
                      <a href="https://support.meaningsphere.com/hc/en-us/articles/8446382859028-What-is-your-Privacy-Policy-"
                        class="pp_underline" target="__blank" tabindex="10">Privacy Policy</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="w-100 d-flex pr-15 justify-content-between row">
            <div class="captcha_section mx-3 text-center ml-4 col-lg-6 col-sm-12">
              <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (ready)="handleReady()"
                (load)="handleLoad()" (success)="handleSuccess($event)" [useGlobalDomain]="false" [theme]="theme"
                [type]="type" [badge]="'inline'" [ngModel]="recaptcha" [ngModelOptions]="{ standalone: true }">
              </ngx-invisible-recaptcha>
            </div>
            <div class="col-lg-3 col-sm-12 register_section d-flex align-items-end flex-direction-column">
              <button [disabled]="!RegisterForm.valid || saveButton" class="btn begin_quiz mt-1" type="submit"
                tabindex="11">
                Join Now
              </button>
              <div class="mt-2">
                <span>OR &nbsp; &nbsp;</span>
                <span class="underline"><a [routerLink]="['/login']" queryParamsHandling="preserve" tabindex="12">
                    Sign In
                  </a></span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-sm-12 text-align-center">
            <span class=""
              >By clicking Join Now, you agree to the Meaningsphere
              <span class="underline"
                ><a
                  href="./assets/media/MeaningSphere_Privacy_Policy.pdf"
                  target="__blank"
                  >Privacy Policy</a
                ></span
              >.
            </span>
          </div>
        </div> -->
      </div>
    </mat-dialog-content>
  </form>
</div>