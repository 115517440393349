import { DomSanitizer } from '@angular/platform-browser';
import { MeaningLogAndTreasurePopupComponent } from './../../meaning-network/meaning-log-and-treasure-popup/meaning-log-and-treasure-popup.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { MeaningNetworkService } from '../../../../service/meaning-network.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Optional,
  Inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../../service/common.service';

@Component({
  selector: 'kt-view-my-trasor',
  templateUrl: './view-my-trasor.component.html',
  styleUrls: ['./view-my-trasor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewMyTrasorComponent implements OnInit, OnDestroy {
  throttle = 300;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;
  logsForm: UntypedFormGroup;
  page: number = 2;
  treasureLogSubscription: Subscription;
  myTreasure = [];
  requested_to: number;
  t_count: number;
  full_name: string;

  constructor(
    public dialogRef: MatDialogRef<ViewMyTrasorComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private meaning_network_service: MeaningNetworkService,
    private spinner: NgxSpinnerService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    public sanitizer: DomSanitizer,
    private formbuilder: UntypedFormBuilder
  ) {
    this.requested_to = data.requested_to;
    this.full_name = data.full_name;
  }

  ngOnInit(): void {
    this.logsForm = this.formbuilder.group({
      bucket_type: new UntypedFormControl(2552),
      loggedInUser: new UntypedFormControl(this.requested_to),
      limit: new UntypedFormControl(0),
      page: 1,
    });
    this.postTreasureLogs();
  }
  pushValue(ev?) {
    if (this.t_count !== this.myTreasure.length) {
      this.logsForm.patchValue({
        page: this.page,
      });
      this.postTreasureLogs();
      this.page++;
    }
  }
  postTreasureLogs(params = 0) {
    if (params === 1) {
      this.page = 2;
      this.logsForm.patchValue({
        page: 1,
      });
      this.myTreasure = [];
    }
    const data = this.logsForm.value;
    this.spinner.show();
    this.treasureLogSubscription = this.meaning_network_service
      .postAllTreasureLogsOfUser(data)
      .subscribe((data) => {
        this.myTreasure = [
          ...new Map(
            this.myTreasure
              .concat(data.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.t_count = data.count;
        this.spinner.hide();
        this.cdRef.detectChanges();
      });
  }

  collabContentDetails(
    collabContentId,
    bucket_type,
    title,
    description,
    video_indicator,
    attachment_path
  ) {
    this.dialog.open(MeaningLogAndTreasurePopupComponent, {
      disableClose: true,
      width: '80%',
      data: {
        collabContentId: collabContentId,
        popup_call_from: 'popup_window',
        bucket_type: bucket_type,
        title: title,
        description: description,
        video_indicator: video_indicator,
        attachment_path: attachment_path,
      },
    });
  }

  checkStatus(status) {
    return CommonService.checkMemberStatus(status);
  }
  ngOnDestroy() {
    if (this.treasureLogSubscription) {
      this.treasureLogSubscription.unsubscribe();
    }
  }
}
