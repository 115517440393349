// Angular
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';

// Loading bar
import { LoadingBarService } from '@ngx-loading-bar/core';


@Component({
  selector: 'kt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  // Public properties
  @Output() sidebarToggleEvent = new EventEmitter<boolean>();
  show = false;
  menuHeaderDisplay: boolean;
  fluid: boolean;
  myfeeds = false;

  @ViewChild('ktHeader', { static: true }) ktHeader: ElementRef;

  constructor(
    private router: Router,
    public loader: LoadingBarService
  ) {
    // page progress bar percentage
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // set page progress bar loading to start on NavigationStart event router
        this.loader.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loader.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loader.increment(75);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        // set page progress bar loading to end on NavigationEnd event router
        this.loader.complete();
      }
    });
  }
  ngOnInit(): void {}
  toggleSidebar() {
    this.show = !this.show;
    this.sidebarToggleEvent.emit(this.show);
  }
}
