<div class="">
  <table width="100%">
    <tr>
      <td>
        <button mat-button mat-dialog-close class="modal_close_button_top">
          X
        </button>
      </td>
    </tr>
  </table>
  <!-- <mat-card> -->
  <table border="0" width="100%">
    <tr>
      <td>
        <h1 class="cmn_title_top">Your Discussion Details</h1>
      </td>
    </tr>
  </table>
  <hr />
  <mat-dialog-content>
    <mat-tab-group
      animationDuration="0ms"
      (selectedTabChange)="tabChanged($event)"
    >
      <mat-tab label="DISCUSSIONS SUMMARY" style="color: white">
        <br/>
        <ng-container *ngIf="isPastGuide == 0">
          <div class="scroll_class scroll_class_list_scroll-gap">
            <p>Available Discussions</p>
            <ul>
              <li>
                <span
                  >Guided MoM -
                  {{ available_mom_discussions }} Session(s)</span
                >
              </li>
              <li>
                <span
                  >Guided Reflection -
                  {{ available_diy_discussions }} Session(s)</span
                >
              </li>
              <li>
                <span>
                  General Discussion Time-
                  {{ available_guide_discussions }} Minutes
                </span>
              </li>
            </ul>
            <p>Completed Discussions</p>
            <ul>
              <li>
                <span>
                  Completed Guided MoM -
                  {{ completed_mom_discussions }} Session(s)
                </span>
              </li>
              <li>
                <span>
                  Completed Guided Reflection -
                  {{ completed_diy_discussions }} Session(s)
                </span>
              </li>
              <li>
                <span>
                  Completed General Discussion Time-
                  {{ completed_guide_discussions }} Minutes
                </span>
              </li>
            </ul>
            <p>Scheduled Discussions</p>
            <ul>
              <li>
                <span>
                  Scheduled Guided MoM -
                  {{ scheduled_mom_discussions }} Session(s)
                </span>
              </li>
              <li>
                <span>
                  Scheduled Guided Reflection -
                  {{ scheduled_diy_discussions }} Session(s)
                </span>
              </li>
              <li>
                <span>
                  Scheduled General Discussions -
                  {{ scheduled_guide_discussions }} Minutes
                </span>
              </li>
            </ul>
            <p>Requested Discussions</p>
            <ul>
              <li>
                <span>
                  Requested Guided MoM -
                  {{ requested_mom_discussions }} Session(s)
                </span>
              </li>
              <li>
                <span>
                  Requested Guided Reflection -
                  {{ requested_diy_discussions }}  Session(s)
                </span>
              </li>
              <li>
                <span>
                  Requested General Discussion Time -
                  {{ requested_guide_discussions }} Minutes
                </span>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-container *ngIf="isPastGuide == 1">
          <div class="scroll_class scroll_class_list_scroll-gap">
            <p>Completed Discussions</p>
            <ul>
              <li>
                <span>
                  Completed Guided MoM -
                  {{ completed_mom_discussions }} Session(s)
                </span>
              </li>
              <li>
                <span>
                  Completed Guided Reflection -
                  {{ completed_diy_discussions }} Session(s)
                </span>
              </li>
              <li>
                <span>
                  Completed General Discussion Time-
                  {{ completed_guide_discussions }} of Minutes
                </span>
              </li>
            </ul>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab label="DISCUSSIONS" style="color: white" *ngIf="clicked_from!='my_guides'">
        <br/>
        <div class="scroll_class scroll_class_list_scroll-gap">
          <ng-container *ngIf="count > 0">
            <div *ngFor="let guidedata of guideDiscussionData">
              <div class="community-box">
                <P>
                  {{ event_type_textReturn(guidedata.event_type) }} with
                  <span
                    *ngIf="guidedata.guide_profile[0].id == loggedinUserid"
                    >{{ guidedata.explorer_profile[0].full_name }}</span
                  ><span
                    *ngIf="guidedata.explorer_profile[0].id == loggedinUserid"
                    >{{ guidedata.guide_profile[0].full_name }}</span
                  >
                  on
                  {{ guidedata.start_date | date: "MMM d, y":current_timezone }}
                  at
                  {{ onlyTimeTimeZone(guidedata.start_date) }}
                  for {{ guidedata.time_duration }} Minutes
                </P>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="count == 0">
            <p>No Data Found!</p>
          </ng-container>
        </div>
      </mat-tab>
      <mat-tab label="DISCUSSIONS" style="color: white" *ngIf="clicked_from=='my_guides'">
        <br/>
        <div class="scroll_class scroll_class_list_scroll-gap">
          <ng-container *ngIf="count > 0">
            <div *ngFor="let mydata of guideDiscussionData">
              <div class="community-box">
                <P>
                  {{event_type_textReturn(mydata.event_details[0].event_type)}} with
                  {{
                    mydata.member_details[0].id == loggedinUserid
                      ? mydata.guide_member_details[0].full_name
                      : mydata.member_details[0].full_name
                  }}
                  on
                  {{ mydata.event_details[0].start_date | date: "MMM d, y":current_timezone }} at
                  {{  onlyTimeTimeZone(mydata.event_details[0].start_date) }} for
                  {{ mydata.event_details[0].time_duration }} Minutes
                </P>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="count == 0">
            <p>No Data Found!</p>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
</div>
