    <h1
      class="text-align-left"
      style="color: rgb(206, 123, 16)"
      mat-dialog-title
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(name)"
    ></h1>
    <hr/>
    <mat-dialog-content>
      <p
        [innerHTML]="sanitizer.bypassSecurityTrustHtml(parameter_value)"
      ></p>
    </mat-dialog-content>
    <mat-card-actions>
      <table width="100%">
        <tr>
          <td width="35%" align="left">
            <button
              (click)="close()"
              type="button"
              class="btn begin_quiz canceled_btn"
            >
              Close
            </button>
          </td>
          <td width="50%" align="right">
            <button
              (click)="redirectTo(param)"
              type="submit"
              class="btn begin_quiz"
              [innerHTML]="sanitizer.bypassSecurityTrustHtml(name)"
            ></button>
          </td>
        </tr>
      </table>
    </mat-card-actions>

