<ng-container *ngIf="mode  == 'edit'  && fieldLable  == ''">
    <form [formGroup]="CountrySlect">
        <label *ngIf="countrySelected && fieldLableDisplay">Country *</label>
        <ngx-select class="w-100" placeholder="Country *" [items]="countryList" optionValueField="id"
            formControlName="selectedCountry" [disabled]="disabled" optionTextField="name" (selectionChanges)="sendResponse($event)">
        </ngx-select>
    </form>
</ng-container>
<ng-container *ngIf="mode  == 'edit' && fieldLable  != ''">
    <form [formGroup]="CountrySlect">
    <label *ngIf="countrySelected  && fieldLableDisplay">{{fieldLable}}</label>
        <ngx-select class="w-100" [disabled]="disabled" [placeholder]="fieldLable" [items]="countryList" optionValueField="id"
            formControlName="selectedCountry" optionTextField="name" (selectionChanges)="sendResponse($event)">
        </ngx-select>
    </form>    
</ng-container>
<ng-container *ngIf="mode  != 'edit' && fieldLable  == ''">
    <label *ngIf="countrySelected  && fieldLableDisplay">Country *</label>
    <ngx-select class="w-100" [disabled]="disabled" placeholder="Country *" [items]="countryList" optionValueField="id" optionTextField="name"
        (selectionChanges)="sendResponse($event)">
    </ngx-select>
</ng-container>
<ng-container *ngIf="mode  != 'edit' && fieldLable  != ''">
    <label *ngIf="countrySelected  && fieldLableDisplay">{{fieldLable}}</label>
    <ngx-select class="w-100" [disabled]="disabled" [placeholder]="fieldLable" [items]="countryList" optionValueField="id" optionTextField="name"
        (selectionChanges)="sendResponse($event)">
    </ngx-select>
</ng-container>