import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MeaningNetworkService } from '../../../../service/meaning-network.service';
import { SecondWelcomePageComponent } from './../second-welcome-page/second-welcome.component';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-first-welcome',
  templateUrl: './first-welcome.component.html',
  styleUrls: ['./first-welcome.component.scss'],
})
export class FirstWelcomePageComponent implements OnInit, OnDestroy {
  loggedInUser: number;
  onboarding_complete: boolean;
  constructor(
    public dialogRef: MatDialogRef<FirstWelcomePageComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private meaning_network_service: MeaningNetworkService,
    private accountsService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedInUser = this.accountsService.muser;
  }
  start(): void {
    this.onboarding_complete = true;
    let data = {
      member: this.loggedInUser,
      onboarding_complete: this.onboarding_complete,
    };

    this.meaning_network_service
      .postwelcomeFinish(data)
      .subscribe((data) => {
        this.dialogRef.close();
      });
  }
  back(): void {
    this.dialogRef.close();
    this.dialog.open(SecondWelcomePageComponent, {
      width: '70%',
      disableClose: true,
    });
  }
  close(): void {
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    
  }
}
