// Angular
import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../core/_base/layout';

@Component({
  selector: 'kt-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss'],
})
export class BrandComponent implements OnInit, AfterViewInit {
  // Public properties
  output;
  headerLogo: string;
  headerStickyLogo: string;

  @HostBinding('class') classes = 'kt-header__brand kt-grid__item';

  constructor(
    private layoutConfigService: LayoutConfigService,
  ) {}

  ngOnInit(): void {
    this.headerLogo = this.layoutConfigService.getLogo();
    this.headerStickyLogo = this.layoutConfigService.getStickyLogo();
  }

  ngAfterViewInit(): void {}
}
