import { NotificationService } from './../../../../service/notification.service';
import { SurveyService } from './../../../../service/survey.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { message_properties } from '../../../../../environments/message_properties';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-add-survey',
  templateUrl: './add-survey.component.html',
  styleUrls: ['./add-survey.component.scss'],
})
export class AddSurveyComponent implements OnInit, OnDestroy {
  surveyForm: UntypedFormGroup;
  surveySaveSubscription: Subscription;
  constructor(
    private route: Router,
    private _cdr: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private surveyService: SurveyService,
    private toastr: NotificationService
  ) {
    this.surveyForm = this.formBuilder.group({
      name: new UntypedFormControl('', [Validators.required]),
      total_no_participants: new UntypedFormControl('', [Validators.required]),
      completed_participants: new UntypedFormControl('', [Validators.required]),
      avg_rating: new UntypedFormControl('', [Validators.required]),
      avg_time_to_complete: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl('', [Validators.required]),
      additional_details: new UntypedFormControl('', [Validators.required]),
      survey_type: new UntypedFormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {}

  backToSurvey() {
    this.route.navigateByUrl('/meaningful-work-survey/survey-list');
  }
  saveSurvey(ngForm: NgForm) {
    this.surveySaveSubscription = this.surveyService
      .postSurvey(ngForm)
      .subscribe(
        (data) => {
          if (data.status === 201) {
            this._cdr.detectChanges();
            this.toastr.showSuccess(message_properties.SAVE_SURVEY_SUCCESS, '');
            this.route.navigateByUrl('/meaningful-work-survey/survey-list');
          } else {
            this.toastr.showError(message_properties.SAVE_SURVEY_ERROR, '');
          }
        },
        (error) => {
          this.toastr.showError(message_properties.SAVE_SURVEY_ERROR, '');
        }
      );
  }
  ngOnDestroy(): void {
    if (this.surveySaveSubscription) {
      this.surveySaveSubscription.unsubscribe();
    }
  }
}
