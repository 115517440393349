export const environment = {
  production: false,
  apiUrl: 'http://mspr2web.vlabsinc.com/api/',
  // apiUrl: 'https://msplaypenweb.meaningsphere.com/api/',
  // apiUrl: 'https://eaweb.meaningsphere.com/api/',
  // apiUrl: 'http://localhost:8002/api/',

  urlValidationStrr: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
  regExp:
    '/^(http://www.|https://www.|ftp://www.|www.)?{1}([0-9A-Za-z]+.)([A-Za-z]){2,3}(/)?/',
  videoSize: 1024 * 1,
  imageSize: 2998576,
  profileImageSize: 2998576,
  mediaArray: [
    '.jpeg',
    '.jpg',
    '.mp4',
    '.png',
    '.svg',
    '.gif',
    '.avi',
    '.mpg',
    '.mpeg',
    '.3gp',
  ],
};
