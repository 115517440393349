import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  apiUrl = environment.apiUrl;
  private _requestrefreshneeded = new Subject<void>();
  private _refreshneededMyCartItemsList = new Subject<void>();
  private _refreshPaymentList = new Subject<void>();
  private _refreshCatalogList = new Subject<void>();

  get refreshCatalogList() {
    return this._refreshCatalogList;
  }
  get refreshPaymentList() {
    return this._refreshPaymentList;
  }
  get requestrefreshneeded() {
    return this._requestrefreshneeded;
  }
  get refreshneededMyCartItemsList() {
    return this._refreshneededMyCartItemsList;
  }

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  addToCart(ngForm) {
    return this.http
      .post<any>(this.apiUrl + 'subscription/member-cart-item/create/', ngForm)
      .pipe(
        tap(() => {
          this._refreshneededMyCartItemsList.next();
        })
      );
  }
  removeItemToCart(ngForm, id) {
    return this.http
      .put<any>(
        this.apiUrl + 'subscription/member-cart-item/remove-cart-item/' + id,
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededMyCartItemsList.next();
        })
      );
  }
  myCartItemsList(logged_in_member) {
    return this.http.get<any>(
      this.apiUrl +
        'subscription/member-cart-item/my-cart-item-list/' +
        logged_in_member
    );
  }
  myCartItemsCount(logged_in_member) {
    return this.http.get<any>(
      this.apiUrl +
        'subscription/member-cart-item/my-cart-item-count/' +
        logged_in_member
    );
  }

  getDiscussionDetailsData(subscriving_member, processing_member) {
    const data = {
      guide: subscriving_member,
      explorer: processing_member,
    };
    return this.http.post<any>(
      this.apiUrl + 'subscription/guide-time-balance/guide-discussion-details/',
      data
    );
  }

  paymentInCartItems(ngform) {
    return this.http
      .post<any>(
        this.apiUrl + 'subscription/member-account-transaction/create',
        ngform
      )
      .pipe(
        tap(() => {
          this._refreshPaymentList.next();
        })
      );
  }

  paymentInCartItemsEaServer(ngform) {
    return this.http
      .post<any>(
        this.apiUrl +
          'subscription/member-account-transaction/create-ea-server',
        ngform
      )
      .pipe(
        tap(() => {
          this._refreshPaymentList.next();
        })
      );
  }

  paymentComplete(ngform) {
    return this.http
      .post<any>(
        this.apiUrl +
          'subscription/member-account-transaction/transaction-complete',
        ngform
      )
      .pipe(
        tap(() => {
          this._refreshPaymentList.next();
        })
      );
  }

  myCartItemsPurchasedList(logged_in_member) {
    return this.http.get<any>(
      this.apiUrl +
        'subscription/member-cart-item/my-cart-item-purchased-list/' +
        logged_in_member
    );
  }

  myAllTransactionList(logged_in_member) {
    const data = {
      logged_in_member: logged_in_member,
    };
    return this.http.post<any>(
      this.apiUrl + 'subscription/member-cart-item/my-all-transaction-list',
      data
    );
  }
  catalogListBasisOnOffering(offer_id) {
    return this.http.get<any>(
      this.apiUrl +
        'subscription/catalog/guide-service-list-basis-on-offering/' +
        offer_id
    );
  }

  getBraintreeToken123(loggedInUser) {
    const data = {
      loggedInUser: loggedInUser,
    };
    return this.http.post<any>(
      this.apiUrl +
        'subscription/member-account-transaction/get-braintree-token/',
      data
    );
  }

  getBraintreeCheckout(loggedInUser) {
    const data = {
      loggedInUser: loggedInUser,
    };
    return this.http
      .post<any>(
        this.apiUrl +
          'subscription/member-account-transaction/braintree-checkout/',
        data
      )
      .pipe(
        tap(() => {
          this._refreshPaymentList.next();
        })
      );
  }

  allCatalogItems(postData): Observable<CatalogData> {
    return this.http.post<CatalogData>(
      `${this.apiUrl}subscription/catalog/list/`,
      postData
    );
  }
  updateCatalogPrice(postData): Observable<CatalogData> {
    return this.http
      .put<CatalogData>(
        `${this.apiUrl}subscription/catalog/catalog-update-price/`,
        postData
      )
      .pipe(
        tap(() => {
          this._refreshCatalogList.next();
        })
      );
  }
}

export interface CatalogData {
  offering_type: string;
  offering: string;
  offering_name: string;
  location: string;
  pricing_status: string;
  price: string;
  tenant: string;
  discounted_indicator: string;
  discount_percentage: string;
  unit: string;
  unit_type: string;
  interval: string;
  item_category: string;
  item_tax_category: string;
  creation_date: string;
  release_date: string;
  closure_date: string;
  catalog_small_description: string;
  results: [];
  count: number;
  item_SKU_code: string;
  status: number;
  message: string;
}
