import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MentorService } from '../../../../service/mentor.service';
import { NotificationService } from '../../../../service/notification.service';
import { message_properties } from '../../../../../environments/message_properties';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-my-guides-read-requests-message',
  templateUrl: './my-guides-read-requests-message.component.html',
  styleUrls: ['./my-guides-read-requests-message.component.scss'],
})
export class MyGuidesReadRequestsMessageComponent implements OnInit, OnDestroy {
  message: string;
  requestId: number;
  requestActive: number;
  withDrawSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<MyGuidesReadRequestsMessageComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private mentorService: MentorService,
    private toastr: NotificationService
  ) {
    this.message = data.message;
    this.requestId = data.requestId;
    this.requestActive = data.requestActive;
  }

  ngOnInit() {}

  withdrawRequest() {
    // change status of collabcontent to 9
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to Withdraw this Request.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.withDrawSubscription = this.mentorService
          .updateCollabContent(this.requestId, 'W', '', 0)
          .subscribe((data) => {
            this.dialogRef.close();
            this.toastr.showSuccess(
              message_properties.MY_GUIDES_READ_REQUESTS_MESSAGE_SUCCESS,
              ''
            );
          });
      }
    });
  }
  ngOnDestroy(): void {
    if (this.withDrawSubscription) {
      this.withDrawSubscription.unsubscribe();
    }
  }
}
