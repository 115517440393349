<kt-spinner></kt-spinner>

<div id="feeds" class="mat-card-style2 p-0">
  <div class="row">
    <div class="col-sm-12 col-lg-3 sub-card-left">
      <div class="profile_card">
        <div class="profile-div d-flex flex-direction-column align-items-center">
          <div class="image-parent w-250-pp pos-relative">
            <img alt="Pic" class="w-250-pp" [src]="profile_path" />
          </div>
          <div class="content-parent text-align-center w-100">
            <div class="profDetail w-100">
              <h1>
                {{ first_name }}
              </h1>
              <h2>
                {{ last_name }}
              </h2>
              <hr class="hr-5" />
              <h3>{{ city ? city+', ': '' }} {{ country }}</h3>
            </div>
          </div>
        </div>
        <div class="myProdileMenu">
          <span class="title"></span>
          <ul class="list-style-none p-0">
            <li>
              <table>
                <tr>
                  <td>
                    <img src="./assets/media/sidebar/profile.png" class="side_bar_icon" />
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <a [routerLink]="
                        '/meaning-network/my-profile'
                      " routerLinkActive="active" style="color: white">My Portrait</a>
                  </td>
                </tr>
              </table>
            </li>
            <li>
              <table>
                <tr>
                  <td>
                    <img src="./assets/media/sidebar/treasures.png" class="side_bar_icon" />
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <a [routerLink]="'/meaning-network/my-treasures'" routerLinkActive="active" style="color: white">My
                      Treasures</a>
                  </td>
                </tr>
              </table>
            </li>
            <li>
              <table>
                <tr>
                  <td>
                    <img src="./assets/media/sidebar/meaning_moments.png" class="side_bar_icon" />
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <a [routerLink]="'/meaning-network/meaning-moments'" routerLinkActive="active"
                      style="color: white">Meaning Moments</a>
                  </td>
                </tr>
              </table>
            </li>
          </ul>
        </div>
      </div>
      <!-- What Matters To You -->
      <div class="profile_card mn_network1">
        <div class="edit_section">
          <span class="mb-4">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="m-0">What Matters To Me</h2>
              <button (click)="toggleWhatMatters()" class="arrow" [ngClass]="{ active: WhatMatters }">
                <img src="./assets/media/icon/Arrow_Black@2x.png" alt="" />
              </button>
            </div>
          </span>
          <hr />
          <div class="multi-collapse" id="multiCollapseExample7" *ngIf="WhatMatters">
            <div class="boxData left-menu-card-left">
              <div class="content">
                <ul *ngIf="topic_count > 0">
                  <li *ngFor="let topic of topics">
                    {{ topic.alternate_name }}
                  </li>
                </ul>
                <spna *ngIf="topic_count == 0">
                  You have not added any topics!
                </spna>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-9 sub-card-right">
      <!-- My Meaning Bookmark Header-->
      <div class="profile_card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start align-items-center">
            <h1 class="m-0">My Bookmarks</h1>
            <ng-template #HtmlContentTreasures>
              <p>{{myBookmarkToolTip}}<a href="https://meaningsphere.zendesk.com/hc/en-us/articles/5860161880340"
                  target="_blank">&nbsp;Learn more »</a> </p>
            </ng-template>
            <kt-tooltip-html [position]="'right'" [theme]="'light'"
              [htmlValue]="HtmlContentTreasures"></kt-tooltip-html>
          </div>
        </div>
        <hr />
        <!-- <cdk-virtual-scroll-viewport
          class="imagePosts card2-style-height-with-hr-buttons scroll_class_list"
        > -->
        <div class="search-results imagePosts card2-style-height-with-hr-buttons scroll_class_list" infinite-scroll
          [infiniteScrollDistance]="modalScrollDistance" [infiniteScrollThrottle]="modalScrollThrottle"
          [scrollWindow]="false" (scrolled)="pushValue($event)">
          <ng-container *ngIf="count > 0">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-12 post" *ngFor="let gem of myBookmark;">
                <div class="innerPost card archive_card front_card_background_clr" style="cursor: pointer">
                  <div class="dis-prof i-pad-sect-upload feedBoxHeader">
                    <label aria-colcount="feedBoxIcon"
                      [ngClass]="{'cursorn':gem.collab_content_details[0].created_by_curator_indicator, 'cursorp':!checkStatus(gem.collab_content_details[0].member__status)}"
                      for="file" class="i-pad-sect-label" style="position: relative;" (click)="
                        viewProfile(
                          gem.collab_content_details[0].created_by_curator_indicator,
                          gem.collab_content_details[0].member_id,
                          gem.collab_content_details[0].first_name,
                          gem.collab_content_details[0].last_name,
                          gem.collab_content_details[0].picture_path ? gem.collab_content_details[0].picture_path : '',
                          gem.collab_content_details[0].member__status 
                        )
                      ">
                      <img alt="Pic" class="profile_img"
                        title="{{checkStatus(gem.collab_content_details[0].member__status) ? '(This Explorer is no longer active)': ''}}"
                        [ngClass]="{'cursorn':gem.collab_content_details[0].created_by_curator_indicator, 'cursorp':!checkStatus(gem.collab_content_details[0].member__status)}"
                        [src]="
                          gem.collab_content_details[0].created_by_curator_indicator
                            ? './assets/media/users/sphere.png'
                            : gem.collab_content_details[0].picture_path
                            ? gem.collab_content_details[0].picture_path
                            : './assets/media/users/default.png'
                        " />
                      <div class="userInfo userDetail">
                        <span class="name only_one_line_show"
                          [ngClass]="{'cursorn':gem.collab_content_details[0].created_by_curator_indicator, 'cursorp':!checkStatus(gem.collab_content_details[0].member__status)}">{{
                          gem.collab_content_details[0].created_by_curator_indicator
                          ? "MeaningSphere Editor"
                          : gem.collab_content_details[0].full_name
                          }}</span>
                        <span class="feedTime m-0 fs-10"
                          *ngIf="checkStatus(gem.collab_content_details[0].member__status)">(This Explorer is no longer
                          active)</span>
                        <span class="time" style="color: rgb(111, 111, 121)">{{
                          gem.action_date | date: "MMM d, y"
                          }}</span>
                      </div>
                    </label>
                  </div>
                  <div class="postedData" (click)="
                      collabContentDetails(
                        gem?.collab_content_details[0].collab_content_id,
                        gem?.member,
                        gem?.bucket_type,
                        gem?.collab_content_details[0]?.title,
                        gem?.collab_content_details[0]?.description,
                        gem?.collab_content_details[0]?.video_indicator,
                        gem?.collab_content_details[0]?.attachment_path,
                        gem?.collab_content_details[0]?.references,
                        gem?.collab_content_details[0]?.collab_content_category
                      )
                    ">
                    <div class="row">
                      <span class="name olny_two_line_show">
                        {{ gem.collab_content_details[0].title }}
                      </span>
                    </div>

                    <ng-container *ngIf="gem.collab_content_details[0].video_indicator == 1">
                      <img [src]="
                          gem.collab_content_details[0].attachment_path
                            ? sanitizer.bypassSecurityTrustResourceUrl(
                                gem.collab_content_details[0].attachment_path
                              )
                            : './assets/media/default/post_default.png'
                        " alt="" class="constellation_img all_other_img mt-2" />
                    </ng-container>

                    <div class="postedDataInfo mt-2">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="name_description olny_three_line_show word_break" [innerHTML]="
                              sanitizer.bypassSecurityTrustHtml(
                                gem.collab_content_details[0].description
                              )
                            "></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="count == 0">
            <table>
              <tbody>
                <tr>
                  <td style="padding: 6px !important">
                    <img src="./assets/media/no_data_found.png" style="border-radius: 0px" />
                  </td>
                  <td colspan="2" style="padding: 6px !important">
                    <span>You do not have any bookmarks items!</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>