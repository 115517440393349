import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { OverviewService } from '../../../../service/overview.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['../../map-of-meaning/overview/overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
  overview_title;
  overview_body;
  overviewSubscription: Subscription;
  constructor(
    private overview: OverviewService,
    private cdRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getoverfromservice();
  }
  getoverfromservice() {
    this.spinner.show();
    this.overviewSubscription = this.overview
      .getoverviewcontent(103)
      .subscribe((data) => {
        (this.overview_title = data.name),
          (this.overview_body = data.parameter_value);
        this.cdRef.detectChanges();
        this.spinner.hide();
      });
  }
  ngOnDestroy(): void {
    if (this.overviewSubscription) {
      this.overviewSubscription.unsubscribe();
    }
  }
}
