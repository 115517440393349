import { SubscriptionService } from './../../../../service/subscription.service';
import { NotificationsService } from './../notifications/notifications.service';
import { MessagesService } from '../../../../service/messages.service';
import { SendMassageComponent } from './../../../pages/admin-page/send-massage/send-massage.component';
import { NotificationService } from '../../../../service/notification.service';
import { CookieService } from 'ngx-cookie-service';
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
// Angular
import { Component, HostBinding, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { OverviewService } from '../../../../service/overview.service';
import { InfoPopupComponent } from './../info-popup/info-popup.component';
import { CuratorPageService } from '../../../../service/curator-page.service';
import { Subscription } from 'rxjs';
import { message_properties } from '../../../../../environments/message_properties';
import { AccountsService } from './../../../../service/accounts.service';
import { MentorService } from './../../../../../app/service/mentor.service';

@Component({
  selector: 'kt-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, OnDestroy {
  message: any;
  overview_title;
  public show = false;
  username_label: string;
  full_name: string;
  overview_body;
  @HostBinding('class') classes = 'kt-header__topbar kt-grid__item';
  type_val: any;

  loginForm: UntypedFormGroup;
  changePasswordForm: UntypedFormGroup;
  passwordResetForm: UntypedFormGroup;
  showprofile = false;
  showLogin = true;
  login_title: string;
  profile_path: string;
  user_name: string;
  data: {};
  public shwMenu = false;
  public shwMenu1 = false;
  public shwMenu2 = false;
  public shwMenu3 = false;
  public shwMenu4 = false;
  public shwMenu5 = false;
  public shwMenu6 = false;
  adminIndicator:boolean = false;
  curatorIndicator: boolean = false;
  programDirectorIndicator: boolean = false;
  isLoggedInUser = false;
  loggedInUser: number;

  messagesScreen = true;
  messagesID: number;
  unreadMessageCount: number;
  unreadNotificationCount: number;
  check_count = true;
  my_cart_count = 0;
  scheduled_discussions_count = 0;
  is_logged_in = false;
  subscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private spinner: NgxSpinnerService,
    private cdRef: ChangeDetectorRef,
    private overview: OverviewService,
    private cookieService: CookieService,
    private toastr: NotificationService,
    private message_service: MessagesService,
    private notifications: NotificationsService,
    private subscriptionService: SubscriptionService,
    private curatorService: CuratorPageService,
    private accountService: AccountsService,
    private mentorService: MentorService
  ) {
    this.is_logged_in = this.accountService.loggedIn;
  }

  ngOnInit() {
    this.loggedInUser = this.accountService.muser;
    this.login_title = this.cookieService.check('welcome')
      ? 'WELCOME BACK'
      : 'WELCOME';
    this.full_name = this.accountService.full_name;
    this.username_label = this.accountService.uname;
    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(150),
      ]),
      password: new UntypedFormControl('', [Validators.required]),
    });
    this.passwordResetForm = new UntypedFormGroup({
      username: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
    });
    if (this.accountService.loggedIn) {
      this.showprofile = true;
      this.subscriptionService.refreshneededMyCartItemsList.subscribe(
        (data) => {
          this.getAllCount();
        }
      );
      this.message_service.messagessListRefreshed.subscribe((data) => {
        this.getAllCount();
      });
      this.notifications.refreshneeded.subscribe((data) => {
        this.getAllCount();
      });
      this.subscriptionService.refreshPaymentList.subscribe((data) => {
        this.getAllCount();
      });
      this.curatorService.all_status_change_refresh.subscribe((data) => {
        this.getAllCount();
      });
      this.mentorService.requestrefreshneededForMarkAsClosed.subscribe((data)=>{
        this.getAllCount();
      });
      this.getAllCount();
      this.lastActivity();
      setInterval(() => {
        this.getAllCount();
        this.lastActivity();
      }, 30000);
    }
    this.getoverfromservice();
    this.user_name = '';
    this.loadImage();
  }

  lastActivity() {
    let data = {
      memberId: this.accountService.muser,
      refresh: this.accountService.refresh_token,
      token: this.accountService.token,
    };

    this.subscription = this.overview
      .lastActivityDate(data)
      .subscribe((response) => {
        const last_activity_date = new Date(response.last_activity_date);
        const compair_date_for_log_out = new Date(
          atob(localStorage.getItem('COMPAIR_DATE_FOR_LOGOUT'))
        );

        if (response.banner_message_count > 0) {
          localStorage.setItem('banner_message', response.banner_message);
          localStorage.setItem(
            'banner_message_count',
            String(response.banner_message_count)
          );
          localStorage.setItem('banner_id', response.banner_id);
        } else {
          localStorage.setItem('banner_message_count', '0');
        }

        localStorage.setItem('admin_indicator', response.admin_indicator);
        localStorage.setItem('curator_indicator', response.curator_indicator);
        localStorage.setItem('mentor_indicator', response.mentor_indicator);
        localStorage.setItem(
          'program_director_indicator',
          response.program_director_indicator
        );
        if (
          response.locked_count === 1 ||
          response.mentor_count === 1 ||
          response.admin_count === 1 ||
          response.curator_count === 1 ||
          response.program_director_count === 1 ||
          compair_date_for_log_out > last_activity_date
        ) {
          localStorage.removeItem('token_refresh_data');
          localStorage.clear();

          this.cdRef.detectChanges();
          window.location.href = '/';
        }
      });
  }

  getAllCount() {
    this.message_service
      .getAllCountData(this.loggedInUser)
      .subscribe((data) => {
        this.my_cart_count = data.cart_count_data;
        this.unreadMessageCount = data.message_count_data;
        this.unreadNotificationCount = data.notification_count_data;
        this.scheduled_discussions_count = data.event_count_data;
        this.cdRef.detectChanges();
      });
  }

  ngDoCheck() {
    if (this.accountService.loggedIn) {
      this.loggedInUser = this.accountService.muser;
      this.showprofile = true;
      this.isLoggedInUser = true;
      this.adminIndicator = this.accountService.admin_indicator;
      this.full_name = this.accountService.full_name;
      this.curatorIndicator = this.accountService.curator_indicator;
      this.programDirectorIndicator = this.accountService.program_director_indicator;
      this.cdRef.detectChanges();
      this.loadImage();
    }
  }

  public getMessageData(value): void {
    this.messagesID = value;
    this.messagesScreen = false;
  }

  backToMessages() {
    this.messagesScreen = true;
  }
  loadFrgtPwd() {
    this.router.navigate(['/login/forgotpassword']);
  }

  loadSignup() {
    this.showprofile = false;
    this.showLogin = true;
  }
  loadMenu() {
    this.shwMenu = !this.shwMenu;
    this.shwMenu1 = false;
    this.shwMenu2 = false;
    this.shwMenu3 = false;
    this.shwMenu4 = false;
    this.shwMenu5 = false;
    this.shwMenu6 = false;
  }
  loadMenu1() {
    this.shwMenu1 = !this.shwMenu1;
    this.shwMenu = false;
    this.shwMenu2 = false;
    this.shwMenu3 = false;
    this.shwMenu4 = false;
    this.shwMenu5 = false;
    this.shwMenu6 = false;
  }
  loadMenu2() {
    // this.router.navigate(['/meaningsphere/about'])
  }
  loadMenu3() {
    this.shwMenu3 = !this.shwMenu3;
    this.shwMenu = false;
    this.shwMenu1 = false;
    this.shwMenu2 = false;
    this.shwMenu4 = false;
    this.shwMenu5 = false;
    this.shwMenu6 = false;
  }
  loadMenu4() {
    this.shwMenu4 = !this.shwMenu4;
    this.shwMenu = false;
    this.shwMenu1 = false;
    this.shwMenu2 = false;
    this.shwMenu3 = false;
    this.shwMenu5 = false;
    this.shwMenu6 = false;
  }
  loadMenu5() {
    this.shwMenu5 = !this.shwMenu5;
    this.shwMenu = false;
    this.shwMenu1 = false;
    this.shwMenu2 = false;
    this.shwMenu3 = false;
    this.shwMenu4 = false;
    this.shwMenu6 = false;
  }
  loadMenu6() {
    this.shwMenu6 = !this.shwMenu6;
    this.shwMenu = false;
    this.shwMenu1 = false;
    this.shwMenu2 = false;
    this.shwMenu3 = false;
    this.shwMenu4 = false;
    this.shwMenu5 = false;
  }
  toggle_top() {
    this.show = !this.show;
  }
  hasRoute(route: string) {
    return this.router.url.includes(route);
  }
  getoverfromservice() {
    this.overview.getoverviewcontent(106).subscribe((data) => {
      this.overview_title = data.name;
      this.overview_body = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }

  logoutusers() {
    const postData = {
      refresh: this.accountService.refresh_token,
      token: this.accountService.token,
    };
    this.accountService.logoutuser(postData).subscribe((data) => {
      localStorage.removeItem('token_refresh_data');
      localStorage.clear();
      this.cdRef.detectChanges();
      window.location.href = '/';
    });
  }

  changePasswordSubmit(ngForm: NgForm) {
    this.spinner.show();
    return this.accountService
      .passwordChange(ngForm, this.accountService.currentUser)
      .subscribe(
        (res) => {
          if (res.code !== 404) {
            this.spinner.hide();
            this.toastr.showSuccess(
              message_properties.CHANGE_PASSWORD_SUCCESS,
              ''
            );
          } else {
            this.spinner.hide();
            this.toastr.showError(message_properties.CHANGE_PASSWORD_ERROR, '');
            this.message = '';
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.showError(message_properties.CHANGE_PASSWORD_ERROR, '');
        }
      );
  }

  username(arg0: string, username: any) {
    throw new Error(message_properties.METHOD_NOT_IMPLIMENTED);
  }

  loadProfile() {
    this.loadMenu();
    this.router.navigate(['/meaning-network/my-profile']);
  }
  loadSetting() {
    this.loadMenu();
    this.router.navigate(['/meaning-network/settings']);
  }

  manageAdmin() {
    this.loadMenu();
    this.router.navigate(['/admin-page/member-list-with-search']);
  }
  manageCurator() {
    this.loadMenu();
    this.router.navigate(['/curator-page/manage-constellation']);
  }
  manageProgramedirector() {
    this.loadMenu();
    this.router.navigate(['/program-director-page/mentor-list-with-search']);
  }

  loadImage() {
    this.profile_path =
      localStorage.getItem('image') !== '0'
        ? localStorage.getItem('image')
        : '/assets/media/users/default.png';
  }

  hasRouteActive(route: []) {
    let count = 0;
    route.forEach((path) => {
      if (this.router.url.includes(path)) {
        count++;
      }
    });
    if (count >= 1) {
      return 'active';
    } else {
      return '';
    }
  }
  createMessage() {
    var clicked_from = 1007;
    if (this.adminIndicator) {
      clicked_from = 1009;
    }
    this.dialog.open(SendMassageComponent, {
      disableClose: true,
      width: '40%',
      data: { collabContentId: 0, clicked_from: clicked_from },
    });
  }
  myCartCount(loggedInUser) {
    this.subscriptionService
      .myCartItemsCount(loggedInUser)
      .subscribe((data) => {
        this.my_cart_count = data.count;
        this.cdRef.detectChanges();
      });
  }

  infoDetailsOpen() {
    this.shwMenu = false;
    this.shwMenu1 = false;
    this.shwMenu2 = false;
    this.shwMenu3 = false;
    this.shwMenu4 = false;
    this.shwMenu6 = false;
    this.dialog.open(InfoPopupComponent, {
      disableClose: true,
      width: '60%',
      height: '65%',
    });
  }

  public closeCartpopUp(value): void {
    this.shwMenu5 = value;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
