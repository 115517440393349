<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1 class="popup_card_title">Discontinue Guide - {{ fullName }}</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <ng-container>
    <form
      [formGroup]="terminationForm"
      (ngSubmit)="termination(terminationForm.value)"
      novalidate
      autocomplete="off"
    >
      <div class="">
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field style="width: 100%;">
              <mat-label>Reasons why you want to discontinue</mat-label>
              <textarea
                maxlength="1024"
                required
                formControlName="remarks"
                matInput
                cols="30"
                rows="10"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.()@!? ]|^ /g,'')"
              ></textarea>
            </mat-form-field>
            <p><span>Please note the following:</span></p>
            <ul style="color:white;">
              <li><p>All scheduled discussions will be canceled</p></li>
              <li><p>All completed discussions will be marked as completed and duration updated</p></li>
              <li><p>All pending requests will be withdrawn</p></li>
              <li><p>Any remaining balance will be credited to your account in MeaningSphere</p></li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
      <div class="row">
        <div class="col-sm-12 tr">
          <button
            class="btn begin_quiz ml-2"
            type="submit"
            [disabled]="!terminationForm.valid"
          >
            DISCONTINUE
          </button>

          <button
            mat-dialog-close
            class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
          >
            CANCEL
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</div>
