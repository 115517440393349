<div class="search-results scroll_class card2-style-height-with-tabs-hr-heading" infinite-scroll
  [infiniteScrollDistance]="modalScrollDistance" [infiniteScrollThrottle]="modalScrollThrottle" [scrollWindow]="false"
  (scrolled)="pushValue($event)">
  <ng-container *ngIf="count > 0">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-3 card-explorer" *ngFor="let mentee of menteeData;">
        <div class="card archive_card front_card_background_clr document" (click)="
                  showSharedSpace(
                    mentee.id,
                    mentee.member,
                    mentee.member_profile[0].full_name,
                    mentee.member_profile[0].picture_path
                      ? mentee.member_profile[0].picture_path
                      : './assets/media/users/default_rectangle.png',
                    1,
                    mentee.start_date,
                    mentee.member_address?.length > 0
                      ? mentee.member_address[0]['city']
                      : '__',
                    mentee.member_address?.length > 0
                      ? mentee.member_address[0]['country']
                      : '__',
                      0
                  )
                ">
          <div class="img_section_mirror text-center cursorp">
            <img [src]="returnImage(mentee.member_profile[0].picture_path)" alt="" class="cursorp archive_img w-124-pm new-c-photo new-c-photo-guid archive_img {{
                mentee.member_accepted_count == 1 ? 'archive_img_border' : ''
              }} curator" />
          </div>

          <div class="title">
            <div class="text_on_image mt-3 member_card_name">
              <h1 class="cursorp">{{ mentee.member_profile[0].first_name }}</h1>
              <h2 class="fs-18">{{ mentee.member_profile[0].last_name }}</h2>
              <h3>
                {{ mentee?.member_address[0]?.city??"-------" }},
                {{ mentee?.member_address[0]?.country??"-------" }}
              </h3>

              <h4 class="fs-18 text-align-left">
                Guiding since: {{ mentee.start_date | date: "MMM d, y" }}
                {{
                mentee.end_date == "null"
                ? "Present"
                : (mentee.end_date | date: "MMM d, y")
                }}
                <br />
                <!-- Endorsed on:
                <span *ngIf="mentee?.member_endorsement?.length > 0"> {{mentee?.member_endorsement[0]["creation_date"] |
                  date:"MMM d, y"}}</span><span *ngIf="mentee?.member_endorsement?.length == 0">---</span> -->
              </h4>

            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="count == 0">
    <table>
      <tbody>
        <tr>
          <td class="p-2">
            <img src="./assets/media/no_data_found.png" class="br-0" />
          </td>
          <td colspan="2" class="p-2">
            You do not have any Guided Explorers currently!
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
<!-- </cdk-virtual-scroll-viewport> -->