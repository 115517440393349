<div class="search-results scroll_class card2-style-height-with-tabs-hr-heading" infinite-scroll
    [infiniteScrollDistance]="modalScrollDistance" [infiniteScrollThrottle]="modalScrollThrottle" [scrollWindow]="false"
    (scrolled)="pushValueRequested($event)">
    <div class="">
        <ng-container *ngIf="count>0">
            <div class="help-desk row" id="loadelements">
                <div *ngFor="let request of requestData" class="col-lg-3 col-md-6 col-sm-12">
                    <div class="curator_card_main mt-3 front_card_background_clr">
                        <div class="row">
                            <div class="col-md-12 img_section_mirror text-center">
                                <img alt="" class="w-124-pm archive_img new-c-photo cursorp" [src]="
                            returnImage(
                              request.requestingToUser[0]['picture_path']
                            )
                          " (click)="
                          viewConnectProfile(
                            request.requestingToUser[0].id,
                            request.requestingToUser[0].first_name,
                            request.requestingToUser[0].last_name,
                            request.requestingToUser[0].picture_path ? request.requestingToUser[0].picture_path : './assets/media/users/default.png')" />
                            </div>

                            <div class="col-sm-12">
                                <div class="text-center title text_on_image">
                                    <span class="cursorp"
                                        (click)="
                                    viewConnectProfile(
                                      request.requestingToUser[0].id,
                                      request.requestingToUser[0].first_name,
                                      request.requestingToUser[0].last_name,
                                      request.requestingToUser[0].picture_path ? request.requestingToUser[0].picture_path : './assets/media/users/default.png')">
                                        <h1>
                                            {{ request.requestingToUser[0]["first_name"] }}
                                        </h1>
                                        <h2 class="fs-18">
                                            {{ request.requestingToUser[0]["last_name"] }}
                                        </h2>
                                    </span>

                                    <p class="stat-p">
                                        {{
                                        request.requestingToUserAddress.length > 0
                                        ? request.requestingToUserAddress[0]["city"]
                                        : "--"
                                        }},
                                        {{
                                        request.requestingToUserAddress.length > 0
                                        ? request.requestingToUserAddress[0]["country"]
                                        : "--"
                                        }}
                                    </p>
                                    <br />
                                </div>
                                <div class="bordertopbottom">
                                    <p>Type: {{ request.title }}</p>
                                    <p>
                                        Status:
                                        <label *ngIf="request.active == 6">Requested</label>
                                        <label *ngIf="request.active == 7">Accepted</label>
                                        <label *ngIf="request.active == 8">Declined</label>
                                        <label *ngIf="request.active == 9">Withdrawn</label>
                                    </p>
                                    <p>
                                        Date of Request:
                                        {{ request.creation_date | date: "MMM d, y" }}
                                    </p>
                                </div>
                            </div>

                            <div class="col-sm-12 messagesSec">
                                <!-- <span class="headmsg">Message</span> -->
                                <img class="imageProfile" [src]="
                            request.creatingUser[0].picture_path
                              ? request.creatingUser[0].picture_path
                              : './assets/media/users/default.png'
                          " />
                                <span class="namemsg">
                                    {{ request.creatingUser[0].full_name }}
                                </span>
                                <br>
                                <span class="feedTime m-0 ml-10 fs-14" *ngIf="checkStatusMember(request.creatingUser[0].member__status)">(This Explorer is no longer active)</span>

                                <span class="datemsg">
                                    {{ request.creation_date | date: "MMM d, y" }}</span>
                                <div>
                                    <p style="color: white" class="" readonly="true" rows="4" cols="40"
                                        class="olny_three_line_show right-textarea">
                                        {{ request.description }}
                                    </p>
                                </div>

                                <table align="right" class="mt-2">
                                    <tr>
                                        <td>
                                            <button class="btn-widthr" (click)="
                                  messageDetails(
                                    request.id,
                                    request.active,
                                    request.description
                                  )
                                ">
                                                <img src="./assets/media/view.png" title="Details"
                                                    class="img_radius_none" />
                                            </button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="count==0">
            <table>
                <tbody>
                    <tr>
                        <td class="p-2">
                            <img src="./assets/media/no_data_found.png" class="search_no_data_found_image" />
                        </td>
                        <td colspan="2" class="p-2">
                            You do not have any Guide requests!
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </div>
</div>