import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kt-tooltip-html',
  templateUrl: './tooltip-html.component.html',
  styleUrls: ['./tooltip-html.component.scss']
})
export class TooltipHtmlComponent implements OnInit {

  @Input() htmlValue: any = {};
  @Input() position: any = {};
  @Input() theme: any = {};
  constructor() { }

  ngOnInit(): void {
  }

}
