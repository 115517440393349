import { MatDialog } from '@angular/material/dialog';
import { VideoListComponent } from '../../video-list/video-list.component';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kt-surveyreport2',
  templateUrl: './surveyreport2.component.html',
  styleUrls: ['./surveyreport2.component.scss'],
})
export class Surveyreport2Component implements OnInit {
  report_2_body: any;
  data = {};
  @Input() memberId;
  @Input() currentSelectedSurvey;
  @Input() json_report;
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  callVideoList() {
    this.dialog.open(VideoListComponent, {
      width: '60%',
      disableClose: true,
    });
  }
}
