import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kt-surveyreport8',
  templateUrl: './surveyreport8.component.html',
  styleUrls: ['./surveyreport8.component.scss'],
})
export class Surveyreport8Component implements OnInit {
  @Input() currentSelectedSurvey;
  @Input() memberId;
  @Input() json_report;
  constructor() {}

  ngOnInit(): void {}
}
