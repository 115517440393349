import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

import { EachVedioComponent } from '../../each-vedio/each-vedio.component';
@Component({
  selector: 'kt-surveyreport12',
  templateUrl: './surveyreport12.component.html',
  styleUrls: ['./surveyreport12.component.scss'],
})
export class Surveyreport12Component implements OnInit {
  @Input() currentSelectedSurvey;
  @Input() memberId;
  @Input() json_report;
  constructor(private route: Router, private dialog: MatDialog) {}
  ngOnInit() {}
  redirectToNextStep() {
    this.dialog.closeAll();
    this.route.navigate(['/meaningful-work-survey/next-steps']);
  }
  lucyVideo() {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: {
        source: 'https://player.vimeo.com/video/759650970?h=2dada23c51',
      },
    });
  }
}
