import { Subscription } from 'rxjs';
// Angular
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService } from './core/_base/layout';
import * as moment from 'moment-timezone';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  // Public properties
  title = 'Meaning Sphere';
  loader: boolean;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private router: Router,
    private layoutConfigService: LayoutConfigService
  ) {}

  ngOnInit(): void {
    this.loader = this.layoutConfigService.getConfig('loader.enabled');

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }
  ngAfterViewInit(): void {
    this.getUserSnap();
  }
  ngOnChanges(): void {}

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  private getUserSnap() {
    const win = window as any;
    const hostStr = window.location.host; //check url voc2 is not requred alpha1r2.meaningsphere.com
    const hostArr = hostStr.split('.');

    if (hostArr[0] !== 'voc2') {
      win.onUsersnapCXLoad = (api) => {
        const today = new Date();
        const start_date_time = moment(today, 'YYYY-MM-DD HH:mm').format(
          'YYYY-MM-DD HH:mm'
        );
        api.init({
          custom: {
            instance: hostArr[0], //Alpha, VOC, VOC2, Playpen
            buildVersion: '3.3.9',
            reportedOn: start_date_time,
          },
        });
        win.Usersnap = api;
      };
    }
    const script = document.createElement('script');
    script.src =
      'https://widget.usersnap.com/global/load/1051a890-4c5b-48a5-b5b9-90738ee190aa?onload=onUsersnapCXLoad';
    script.async = true;
    script.defer = true;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
