import { RouteGuard } from './../../../guard/route.guard';
import { AddRequestCommunityComponent } from './add-request-community/add-request-community.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, CanActivate, Routes } from '@angular/router';

import { MeaningCommunityPostEditComponent } from './meaning-community-post-edit/meaning-community-post-edit.component';
import { InsightActionSummaryInCommunityTabComponent } from './insight-action-summary-in-community-tab/insight-action-summary-in-community-tab.component';
import { UserCommunityAddDialogComponent } from './user-community-add-dialog/user-community-add-dialog.component';
import { UserCommunityCreateActionInsitePopupComponent } from './user-community-create-action-insite-popup/user-community-create-action-insite-popup.component';
import { UserCommunityCreateMessagePopupComponent } from './user-community-create-message-popup/user-community-create-message-popup.component';
import { UserCommunityFeedDetailsPopupComponent } from './user-community-feed-details-popup/user-community-feed-details-popup.component';
import { UserCommunityFeedEditComponent } from './user-community-feed-edit/user-community-feed-edit.component';
import { UserCommunityDialogDetailsPopupComponent } from './user-community-dialog-details-popup/user-community-dialog-details-popup.component';
import { UserCommunityWithdrawPopupComponent } from './user-community-withdraw-popup/user-community-withdraw-popup.component';
import { UserMyCommunityDetailsPopupComponent } from './user-my-community-details-popup/user-my-community-details-popup.component';
import { EventActionInsightPopupComponent } from './event-action-insight-popup/event-action-insight-popup.component';
import { EventMessagePopupComponent } from './event-message-popup/event-message-popup.component';
import { SharedModule } from '../../shared/shared.module';
import { SingleCommunityPopupUpcomingDialogListComponent } from './user-my-community-details-popup/single-community-popup-upcoming-dialog-list/single-community-popup-upcoming-dialog-list.component';
import { SingleCommunityPopupHostListComponent } from './user-my-community-details-popup/single-community-popup-host-list/single-community-popup-host-list.component';
import { SingleDialogMessageListComponent } from './user-community-dialog-details-popup/single-dialog-message-list/single-dialog-message-list.component';
import { SingleDialogPopupHostListComponent } from './user-community-dialog-details-popup/single-dialog-popup-host-list/single-dialog-popup-host-list.component';

@NgModule({
  declarations: [
    AddRequestCommunityComponent,
    MeaningCommunityPostEditComponent,
    InsightActionSummaryInCommunityTabComponent,
    UserCommunityAddDialogComponent,
    UserCommunityCreateActionInsitePopupComponent,
    UserCommunityCreateMessagePopupComponent,
    UserCommunityFeedDetailsPopupComponent,
    UserCommunityFeedEditComponent,
    UserCommunityDialogDetailsPopupComponent,
    UserCommunityWithdrawPopupComponent,
    UserMyCommunityDetailsPopupComponent,
    EventActionInsightPopupComponent,
    EventMessagePopupComponent,
    SingleCommunityPopupUpcomingDialogListComponent,
    SingleCommunityPopupHostListComponent,
    SingleDialogMessageListComponent,
    SingleDialogPopupHostListComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: 'community',
        loadChildren: () =>
          import('./user-community/user-community.module').then(
            (m) => m.UserCommunityModule
          ),
        canActivate: [RouteGuard],
      },
      {
        path: 'my-community',
        loadChildren: () =>
          import(
            './user-my-community-with-all-feed-and-all-dialog/user-my-community-with-all-feed-and-all-dialog.module'
          ).then((m) => m.UserMyCommunityWithAllFeedAndAllDialogModule),
        canActivate: [RouteGuard],
      },
      {
        path: 'my-community/:communityId',
        loadChildren: () =>
          import(
            './user-single-community-feed-and-dialog/user-single-community-feed-and-dialog.module'
          ).then((m) => m.UserSingleCommunityFeedAndDialogModule),
        canActivate: [RouteGuard],
      },
      {
        path: 'my-community',
        loadChildren: () =>
          import(
            './user-my-community-with-all-feed-and-all-dialog/user-my-community-with-all-feed-and-all-dialog.module'
          ).then((m) => m.UserMyCommunityWithAllFeedAndAllDialogModule),
        canActivate: [RouteGuard],
      },
    ]),
  ],
})
export class MeaningCommunitiesModule {}
