import { Inject, OnDestroy } from '@angular/core';
import { Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SurveyService } from './../../../../service/survey.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-survey-response',
  templateUrl: './survey-response.component.html',
  styleUrls: ['./survey-response.component.scss'],
})
export class SurveyResponseComponent implements OnInit, OnDestroy {
  questions;
  mirrorData: {};
  surveyParticipantId: number;
  count: number;
  my_response: string = 'My response: ';
  mirrorresponse = [
    'Never/hardly ever',
    'Seldom',
    'Sometimes',
    'Often',
    'Always/almost always',
  ];
  reportSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<SurveyResponseComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    private reports: SurveyService,
    private spinner: NgxSpinnerService
  ) {
    this.surveyParticipantId = data.currentSelectedSurvey;
  }

  ngOnInit() {
    this.getreports();
  }
  getreports() {
    this.spinner.show();
    this.reportSubscription = this.reports
      .getreports(this.surveyParticipantId)
      .subscribe((data) => {
        this.mirrorData = data.results;
        this.count = data.count ? data.count : 0;
        this.spinner.hide();
        this.cdRef.detectChanges();
      });
  }
  ngOnDestroy(): void {
    if (this.reportSubscription) {
      this.reportSubscription.unsubscribe();
    }
  }
}
