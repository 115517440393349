<div>
  <br />
  <ng-container *ngIf="myScheduledDiscussionCount > 0">
    <cdk-virtual-scroll-viewport
      itemSize="0"
      minBufferPx="200"
      maxBufferPx="400"
      class="scroll_class_list scroll-gap"
    >
      <ng-container
        *cdkVirtualFor="
          let mydata of myScheduledDiscussionData;
          templateCacheSize: 0
        "
      >
        <!-- {{mydata.event_details[0].active}} -->
        <div class="community-box">
          <span
            >{{ event_type_textReturn(mydata.event_details[0].event_type) }}
            with
            {{
              mydata.member_details[0].id == loggedinUserid
                ? mydata.guide_member_details[0].full_name
                : mydata.member_details[0].full_name
            }}
            on
            {{
              dateInUserTimezome(mydata.event_details[0].start_date)
                | date: "MMM d, y"
            }}
            at
            {{
              dateInUserTimezome(mydata.event_details[0].start_date)
                | date: "h:mm a"
            }}
            for {{ mydata.event_details[0].time_duration }} Minutes
          </span>
          <table width="100%">
            <tr>
              <td align="left">
                <label
                  *ngIf="mydata.event_details[0].active == 10"
                  style="color: #ec7422 !important"
                  >Cancelation Requested</label
                >
                <!-- <label *ngIf="mydata.event_details[0].active==3">Scheduled</label> -->
                <!-- {{mydata.event_details[0].active}} -->
              </td>
              <td align="right" valign="bottom">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="beforeMenu"
                  aria-label="Example icon-button with a menu"
                  class="dot-button"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                  <button
                    (click)="acceptDiscussion(mydata.event_details[0].id, 3)"
                    mat-menu-item
                    *ngIf="
                      mydata.am_i_guide_in_this_event &&
                      mydata.event_details[0].active == 0
                    "
                  >
                    <span>Accept Request</span>
                  </button>
                  <button
                    (click)="
                      declinedDiscussion(
                        mydata.event_details[0].id,
                        9,
                        mydata.guide_member_details[0].id,
                        mydata.member_details[0].id
                      )
                    "
                    mat-menu-item
                    *ngIf="
                      mydata.am_i_guide_in_this_event &&
                      mydata.event_details[0].active == 0
                    "
                  >
                    <span>Decline Request</span>
                  </button>

                  <button
                    (click)="
                      startDiscussion(
                        mydata.event_details[0].id,
                        100,
                        mydata.event_details[0].host_meeting_link,
                        mydata.zoom_start_join_check,
                        mydata.guide_member_details[0].id,
                        mydata.member_details[0].id
                      )
                    "
                    mat-menu-item
                    *ngIf="
                      mydata.am_i_guide_in_this_event &&
                      mydata.event_details[0].active == 3
                    "
                  >
                    <span>Start</span>
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="
                      mydata.am_i_explorer_in_this_event &&
                      mydata.event_details[0].active == 3
                    "
                    (click)="
                      joinDiscussion(
                        mydata.event_details[0].id,
                        3,
                        mydata.event_details[0].member_meeting_link,
                        mydata.zoom_start_join_check,
                        mydata.guide_member_details[0].id,
                        mydata.member_details[0].id
                      )
                    "
                  >
                    <span>Join</span>
                  </button>

                  <ng-container
                    *ngIf="
                      mydata.event_details[0].active == 0 &&
                      mydata.discussion_created_by_guide == true &&
                      mydata.event_details[0].creating_member_id ==
                        loggedinUserid
                    "
                  >
                    <button
                      (click)="
                        openPopupForDiscussionEditGuide(
                          mydata.event_details[0].id,
                          mydata?.member_details[0]?.picture_path == null
                            ? './assets/media/users/default.png'
                            : mydata?.member_details[0]?.picture_path,
                          mydata?.member_details[0]?.id,
                          mydata?.member_details[0]?.full_name,
                          mydata?.discussion_created_by_guide
                        )
                      "
                      mat-menu-item
                    >
                      <span>Update</span>
                    </button>
                  </ng-container>

                  <button
                    mat-menu-item
                    *ngIf="
                      mydata.am_i_explorer_in_this_event &&
                      mydata.event_details[0].active == 3
                    "
                    (click)="
                      requestForCancelledDiscussion(
                        mydata.event_details[0].id,
                        mydata.guide_member_details[0].id,
                        mydata.member_details[0].id
                      )
                    "
                  >
                    <span>Request Cancelation</span>
                  </button>
                  <button
                    (click)="
                      resheduledDiscussion(
                        mydata?.event_details[0]?.id,
                        mydata?.member_details[0]?.picture_path == null
                          ? './assets/media/users/default.png'
                          : mydata?.member_details[0]?.picture_path,
                        mydata?.member_details[0]?.id,
                        mydata?.member_details[0]?.full_name,
                        mydata?.discussion_created_by_guide,
                        mydata?.event_details[0]?.member_relationship_id != null
                          ? mydata?.event_details[0]?.member_relationship_id
                          : 0,
                        mydata.event_details[0].active
                      )
                    "
                    mat-menu-item
                    *ngIf="
                      mydata?.am_i_guide_in_this_event &&
                      mydata?.event_details[0]?.active == 3 &&
                      mydata?.event_details[0]?.event_type != 2548
                    "
                  >
                    <span>Reschedule</span>
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="
                      mydata.am_i_guide_in_this_event &&
                      mydata.event_details[0].active == 3
                    "
                    (click)="
                      cancelledDiscussion(
                        mydata.event_details[0].id,
                        2,
                        mydata.guide_member_details[0].id,
                        mydata.member_details[0].id
                      )
                    "
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="
                      mydata.am_i_guide_in_this_event &&
                      mydata.event_details[0].active == 10 &&
                      mydata.event_details[0].event_type == 2548
                    "
                    (click)="
                      cancelledDiscussion(
                        mydata.event_details[0].id,
                        2,
                        mydata.guide_member_details[0].id,
                        mydata.member_details[0].id
                      )
                    "
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="
                      mydata.am_i_guide_in_this_event &&
                      mydata.event_details[0].active == 10 &&
                      mydata.event_details[0].event_type != 2548
                    "
                    (click)="
                      requestedCancelledDiscussionfromexlorer(
                        mydata.event_details[0].id,
                        2,
                        mydata.guide_member_details[0].id,
                        mydata.member_details[0].id,
                        mydata.event_details[0].event_type
                      )
                    "
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="
                      mydata.am_i_explorer_in_this_event &&
                      mydata.event_details[0].active == 0
                    "
                    (click)="
                      withdrawnDiscussion(
                        mydata.event_details[0].id,
                        8,
                        mydata.guide_member_details[0].id,
                        mydata.member_details[0].id
                      )
                    "
                  >
                    <span>Withdraw Request</span>
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="
                      mydata.am_i_explorer_in_this_event &&
                      mydata.event_details[0].active == 0
                    "
                    (click)="
                      requestCancelledDiscussion(
                        mydata.event_details[0].id,
                        2,
                        mydata.guide_member_details[0].id,
                        mydata.member_details[0].id
                      )
                    "
                  >
                    <span>Request Cancelation</span>
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="
                      mydata.am_i_guide_in_this_event &&
                      mydata.event_details[0].active == 3
                    "
                    (click)="
                      markDiscussionCompleted(
                        mydata.event_details[0].id,
                        6,
                        returnImage(
                          mydata.guide_member_details[0].id != loggedinUserid
                            ? mydata.guide_member_details[0].picture_path
                            : mydata.member_details[0].picture_path
                        ),
                        mydata.guide_member_details[0].id != loggedinUserid
                          ? mydata.guide_member_details[0].full_name
                          : mydata.member_details[0].full_name,
                        mydata.event_details[0].event_type,
                        mydata?.member_details[0].id
                      )
                    "
                  >
                    <span>Mark as Closed</span>
                  </button>

                  <button
                    mat-menu-item
                    (click)="
                      viewDetails(
                        mydata.event_details[0].id,
                        mydata.guide_member_details[0].id != loggedinUserid
                          ? mydata.guide_member_details[0].id
                          : mydata.member_details[0].id,
                        returnImage(
                          mydata.guide_member_details[0].id != loggedinUserid
                            ? mydata.guide_member_details[0].picture_path
                            : mydata.member_details[0].picture_path
                        ),
                        mydata.guide_member_details[0].id != loggedinUserid
                          ? mydata.guide_member_details[0].full_name
                          : mydata.member_details[0].full_name,
                        mydata.discussion_created_by_guide,
                        mydata.event_details[0].member_relationship_id != null
                          ? mydata.event_details[0].member_relationship_id
                          : 0
                      )
                    "
                  >
                    <span>View Details</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </table>
        </div>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </ng-container>

  <ng-container *ngIf="myScheduledDiscussionCount == 0">
    <p>No Data Found!</p>
  </ng-container>
</div>
