import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionService } from './../../../../service/subscription.service';
import { NotificationService } from './../../../../service/notification.service';
import { message_properties } from './../../../../../environments/message_properties';
import { BraintreeComponent } from '../braintree/braintree.component';
import { AccountsService } from './../../../../service/accounts.service';
@Component({
  selector: 'kt-review-cart-items',
  templateUrl: './review-cart-items.component.html',
  styleUrls: ['./review-cart-items.component.scss'],
})
export class ReviewCartItemsComponent implements OnInit {
  all_catalog_str: any;
  memberid: any;
  full_name: string;
  first_name: any;
  last_name: any;
  city: any;
  country: any;
  line1: any;
  line2: any;
  postalcode: any;
  state: any;
  billing_full_name: string;
  loggedInUser: number;
  myCartItemsData = [];
  final_amount_details_with_tax = [];
  final_amount_details_with_tax_count: number;
  count: number;
  country_id: number;
  transaction_total_amount: number;
  total_amount_pay_through_payment_gateway: string;
  member_account_transaction_id: string;
  secondary_state_code: string;

  constructor(
    public dialogRef: MatDialogRef<ReviewCartItemsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private subscriptionService: SubscriptionService,
    private _cdref: ChangeDetectorRef,
    private toastr: NotificationService,
    private accountService: AccountsService
  ) {
    this.all_catalog_str = data.all_catalog_str;
    this.memberid = data.member;
    this.billing_full_name = data.billing_full_name;
    this.city = data.city;
    this.state = data.state;
    this.country = data.country;
    this.line1 = data.line1;
    this.line2 = data.line2;
    this.postalcode = data.postalcode;
    this.country_id = data.country_id;
    this.final_amount_details_with_tax = data.final_amount_details_with_tax;
    this.final_amount_details_with_tax_count = data.final_amount_details_with_tax_count;
    this.transaction_total_amount = data.transaction_total_amount;
    this.total_amount_pay_through_payment_gateway = data.total_amount_pay_through_payment_gateway;
    this.member_account_transaction_id = data.member_account_transaction_id;
    this.secondary_state_code = data.secondary_state_code;
  }

  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
    this.getMyCartItemsList(this.loggedInUser);
  }
  getMyCartItemsList(logged_in_user_id) {
    this.subscriptionService
      .myCartItemsList(logged_in_user_id)
      .subscribe((data) => {
        this.myCartItemsData = data.results;
        this.count = data.count;
        if (this.count > 0) {
          this.all_catalog_str = this.myCartItemsData[0]['all_catalog_id_str'];
        }
        this._cdref.detectChanges();
      });
  }

  paymentCompleteFrom() {
    const data = {
      member: this.loggedInUser,
      all_catalog_str: this.all_catalog_str,
    };
    this.subscriptionService.paymentComplete(data).subscribe((data) => {
      if (data.status === 201) {
        this.getMyCartItemsList(this.loggedInUser);
        this.toastr.showSuccess(message_properties.PAYMENT_MESSAGE_SUCCESS, '');
        this.dialog.closeAll();
      } else {
        this.toastr.showError(message_properties.PAYMENT_MESSAGE_ERRROR, '');
      }
      () => {
        this.toastr.showError(message_properties.PAYMENT_MESSAGE_ERRROR, '');
      };
    });
  }

  payNow(){
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(BraintreeComponent, {
      disableClose: true,
      width: '30%',
      data: {
        all_catalog_str: this.all_catalog_str,
        total_amount_pay_through_payment_gateway: this.total_amount_pay_through_payment_gateway,
        member_account_transaction_id: this.member_account_transaction_id,
        member: this.loggedInUser,
      },
    });
  }
}
