import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MentorService } from '../../../../service/mentor.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountsService } from '../../../../service/accounts.service';

@Component({
  selector: 'kt-private-notes-list',
  templateUrl: './private-notes-list.component.html',
  styleUrls: ['./private-notes-list.component.scss'],
})
export class PrivateNotesListComponent implements OnInit, OnDestroy {
  guide: string;
  mentee: string;
  guide_dp: string;
  mentee_dp: string;
  menteeId: number;
  loggedInUserId: number;
  user_profile_path: string;
  ufullname: string;
  all_notes_data = [];
  notes_count: number;
  relationship_id: number;
  current_timezone: string;

  privatenotesForm: UntypedFormGroup;
  throttle = 300;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;
  subscription: Subscription;
  page: number = 2;

  constructor(
    public dialogRef: MatDialogRef<PrivateNotesListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _cdref: ChangeDetectorRef,
    private mentor_service: MentorService,
    private formBuilder: UntypedFormBuilder,
    public sanitizer: DomSanitizer,
    private accountsService: AccountsService
  ) {
    this.relationship_id = data.relationship_id;
    this.guide = data.guideFirstName + ' ' + data.guideLastName;
    this.mentee = data.menteeFullName;
    this.guide_dp = data.guideDp;
    this.mentee_dp = data.mentee_dp;
    this.menteeId = Number(data.menteeId);
    this.all_notes_data = data.private_note_list;
  }

  ngOnInit(): void {
    this.loggedInUserId = this.accountsService.muser;
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.user_profile_path = localStorage.getItem('image')
      ? localStorage.getItem('image')
      : './assets/media/users/default.png';
    this.ufullname = this.accountsService.full_name;
    this.privatenotesForm = this.formBuilder.group({
      member: new UntypedFormControl(this.loggedInUserId),
      relationship_id: new UntypedFormControl(this.relationship_id),
      page: 1,
    });
    this.getAllPrivateNote();
  }

  returnImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }
  eventType(event_type) {
    if (event_type === 2548) {
      return 'Get Acquainted';
    } else if (event_type === 2549) {
      return 'Be My Guide Discussion';
    } else if (event_type === 2733) {
      return 'Complimentary Discussion';
    } else if (event_type === 2550) {
      return 'General Discussion';
    } else if (event_type === 2554) {
      return 'Meaningful Work Survey';
    } else if (event_type === 2556) {
      return 'Mirror Reflection';
    } else {
      return 'DIY Discussion';
    }
  }
  pushValue(ev?) {
    if (this.notes_count !== this.all_notes_data.length) {
      this.privatenotesForm.patchValue({
        page: this.page,
      });
      this.getAllPrivateNote();
      this.page++;
    }
  }
  getAllPrivateNote(params = 0) {
    if (params === 1) {
      this.all_notes_data = [];
      this.page = 2;
      this.privatenotesForm.patchValue({
        page: 1,
      });
    }
    const Formdata = this.privatenotesForm.value;
    this.subscription = this.mentor_service
      .getPrivateNoteData(Formdata)
      .subscribe((data) => {
        this.all_notes_data = [
          ...new Map(
            this.all_notes_data
              .concat(data.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.notes_count = data.count;
        this._cdref.detectChanges();
      });
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
