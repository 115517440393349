<kt-spinner></kt-spinner>
<div class="">
  <table width="100%">
    <tr>
      <td>
        <h1 class="cmn_title_top">Edit Post</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />

  <form
    class="collabContentForm edit-collab"
    [formGroup]="collabContentForm"
    (ngSubmit)="saveCollabContent(collabContentForm.value)"
    novalidate
    enctype="multipart/form-data"
    autocomplete="off"
  >
    <mat-dialog-content>
      <div class="row p-2">
        <div class="col-md-12 col-lg-8">
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field style="width: 100%; color: #ffffff">
                <mat-label>Title</mat-label>
                <input
                  matInput
                  autocomplete="off"
                  formControlName="title"
                  oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&!@#$%=*-+?() ]|^ /g,'')"
                  maxlength="100"
                />
              </mat-form-field>

              <label>Description *</label>
              <textarea
                [ngxSummernote]="config"
                autocomplete="off"
                formControlName="description"
                rows="4"
              >
              </textarea>
              <mat-form-field style="width: 100%">
                <input
                  matInput
                  formControlName="textUrl"
                  type="url"
                  placeholder="Link"
                  autocomplete="off"
                  (change)="linkPreview($event)"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-4">
          <div class="previewWindow">
            <div class="preview" *ngIf="image_path != null">
              <img
                class="image_preview_dwidth"
                [src]="image_path"
                height="200"
              />
            </div>
          </div>

          <div class="button-home-bottom">
            <div>
              <ul class="button-home">
                <li>
                  <div class="image-upload" *ngIf="image_path == null">
                    <label for="image-input2">
                      <img
                        src="./assets/media/icon/Photo-Video2x-white.png"
                        alt=""
                        (click)="imageUpload()"
                      />
                    </label>
                  </div>
                </li>
              </ul>

              <div *ngIf="image_path != null">
                <span class="bottom_preview">
                  <img
                    src="./assets/my-feeds/feed-delete.png"
                    (click)="delete_ImageVedioUrl()"
                    alt=""
                    class="delete_image_video delete_icon_img"
                    title="Delete"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr />
    <div class="row">
      <div class="col-sm-12 text-align-right">
        <button
          [disabled]="!collabContentForm.valid || saveButton"
          class="btn begin_quiz ml-2 mt-1"
          type="submit"
        >
          Save
        </button>
        <button
          mat-dialog-close
          class="btn begin_quiz mt-1 ml-2 button-change-scroll canceled_btn"
        >
          Cancel
        </button>
      </div>
      <br />
    </div>
  </form>
</div>
