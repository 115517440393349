import { Surveyreport12Component } from './final-report/surveyreport12/surveyreport12.component';
import { Surveyreport11Component } from './final-report/surveyreport11/surveyreport11.component';
import { Surveyreport10Component } from './final-report/surveyreport10/surveyreport10.component';
import { Surveyreport9Component } from './final-report/surveyreport9/surveyreport9.component';
import { Surveyreport8Component } from './final-report/surveyreport8/surveyreport8.component';
import { Surveyreport7Component } from './final-report/surveyreport7/surveyreport7.component';
import { Surveyreport6Component } from './final-report/surveyreport6/surveyreport6.component';
import { Surveyreport5Component } from './final-report/surveyreport5/surveyreport5.component';
import { Surveyreport4Component } from './final-report/surveyreport4/surveyreport4.component';
import { Surveyreport3Component } from './final-report/surveyreport3/surveyreport3.component';
import { Surveyreport2Component } from './final-report/surveyreport2/surveyreport2.component';
import { FinalReportComponent } from './final-report/final-report.component';
import { RouteGuard } from './../../../guard/route.guard';
import { SurveyInfoComponent } from './survey-info/survey-info.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddSurveyComponent } from './add-survey/add-survey.component';
import { CreateSurveyComponent } from './create-survey/create-survey.component';
import { OverviewComponent } from './overview/overview.component';
import { GiveSurveyComponent } from './give-survey/give-survey.component';
import { ReportFirstPageComponent } from './report-first-page/report-first-page.component';
import { EachVedioComponent } from './each-vedio/each-vedio.component';
import { Surveyreport1Component } from './final-report/surveyreport1/surveyreport1.component';

import { RouterModule } from '@angular/router';
import { SurveyResponseComponent } from './survey-response/survey-response.component';
import { SurveySubmittedListComponent } from './survey-submitted-list/survey-submitted-list.component';
import { CompareTwoSurveyComponent } from './compare-two-survey/compare-two-survey.component';
import { VideoListComponent } from './video-list/video-list.component';
import { NextStepsComponent } from './next-steps/next-steps.component';
import { CompareSurveyReportComponent } from './compare-survey-report/compare-survey-report.component';
import { GuideDiscussionComponent } from './guide-discussion/guide-discussion.component';
import { SharedModule } from '../../shared/shared.module';
import { AfterSurveySharedDataComponent } from './after-survey-shared-data/after-survey-shared-data.component';
import { BuyMeaningfulSurveyComponent } from './buy-meaningful-survey/buy-meaningful-survey.component';
import { FinalReportFirstPageComponent } from './final-report-first-page/final-report-first-page.component';

@NgModule({
  declarations: [
    AddSurveyComponent,
    CreateSurveyComponent,
    OverviewComponent,
    GiveSurveyComponent,
    ReportFirstPageComponent,
    EachVedioComponent,
    FinalReportComponent,
    Surveyreport1Component,
    Surveyreport2Component,
    Surveyreport3Component,
    Surveyreport4Component,
    Surveyreport5Component,
    Surveyreport6Component,
    Surveyreport7Component,
    Surveyreport8Component,
    Surveyreport9Component,
    Surveyreport10Component,
    Surveyreport11Component,
    Surveyreport12Component,
    SurveyResponseComponent,
    SurveyInfoComponent,
    SurveySubmittedListComponent,
    CompareTwoSurveyComponent,
    VideoListComponent,
    NextStepsComponent,
    CompareSurveyReportComponent,
    GuideDiscussionComponent,
    AfterSurveySharedDataComponent,
    BuyMeaningfulSurveyComponent,
    FinalReportFirstPageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: 'create-survey-list',
        component: CreateSurveyComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'survey-add',
        component: AddSurveyComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'take-the-survey',
        component: GiveSurveyComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'my-surveys',
        component: SurveySubmittedListComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'report-first-page',
        component: ReportFirstPageComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'view-selected-survey-report',
        component: FinalReportComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'each-survey-response',
        component: SurveyResponseComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'compare-survey-report',
        component: CompareSurveyReportComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'next-steps',
        component: NextStepsComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'lets-get-started',
        component: SurveyInfoComponent,
        canActivate: [RouteGuard],
      },
    ]),
  ],
})
export class MapOfMeaningModule { }
