<div class="col-sm-12 mt-5">
  <div class="row mr-10 ml-10">
    <div class="col-sm-12 mt-5">
    <div class="row mr-5 ml-5">
      <div class="col-lg-3 col-md-6 col-sm-12">
              <div id="divchartt4" class="chart_details">
        <h6 class="h4_title1 upper_class text-center" 
        >
        Integrity with Self
        </h6>

        <div class="row my-3 pad survey3_img_chartLatest">
          <div class="col-sm-6 pr-0"><div class="cen_alirate">{{ integrity_with_self }}</div><div class="cen_ali">
            {{ integrity_with_self_label }}<sup>th</sup>
            <br />Percentile.
          </div></div>
          <div class="col-sm-6">
          <img src="./assets/media/users/Q4.png" class="survey3_img" />
        </div></div>
        
        
      </div>
      <div id="divchart3" class="mt-5">
        <canvas id="barchart3" height="150"></canvas>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12">
            <div id="divchartt1" class="chart_details">
        <h6
        class="h4_title1 upper_class text-center" 
        >Unity with Others
        </h6>
        <div class="row my-3 pad survey3_img_chartLatest" >
             <div class="col-sm-6 pr-0">
              <div class="cen_alirate">
          {{ unity_with_other }}</div>
         <div class="cen_ali">
          {{ unity_with_others_label }}<sup>th</sup><br />
          Percentile.
        </div></div>
        <div class="col-sm-6">
          <img src="./assets/media/users/Q1.png" class="survey3_img" />
        </div>
      
      </div>

      </div>
      <div id="divchart" class="mt-5">
        <canvas id="barchart" height="150"></canvas>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12">
            <div id="divchartt3" class="chart_details">
        <h6
        class="h4_title1 upper_class text-center" 
        >Expressing Full Potential
        </h6>

        <div class="row my-3 pad survey3_img_chartLatest">
          <div class="col-sm-6 pr-0"> <div class="cen_alirate">{{ expressing_full_potential }}</div><div class="cen_ali">
            {{ expressing_full_potential_label }}<sup>th</sup
            ><br />
            Percentile.
          </div></div>
          <div class="col-sm-6"> <img src="./assets/media/users/Q2.png" class="survey3_img" />
          </div></div>
        
      </div>
      <div id="divchart2" class="mt-5">
          <canvas id="barchart2" height="150"></canvas>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12">
            <div id="divchartt2" class="chart_details">
        <h6
        class="h4_title1 upper_class text-center" 
        >Service to Others
        </h6>
        

        <div class="row my-3 pad survey3_img_chartLatest">
          <div class="col-sm-6 pr-0"><div class="cen_alirate">{{ service_to_others }}</div>
          <div class="cen_ali">{{ service_to_others_label }}<sup>th</sup><br />
            Percentile.
          </div></div>
          <div class="col-sm-6"> <img src="./assets/media/users/Q3.png" class="survey3_img" />
          </div></div>
      </div>
      <div id="divchart1" class="mt-5">
        <canvas id="barchart1" height="150"></canvas>
      </div>
    </div>
  </div>
</div></div>
</div>
<div class="col-sm-12 mt-5">
  <div class="row d-none">
    <div class="col-sm-3 border_class">
      <div id="divchartt1" class="chart_details">
        <h6
        class="h4_title1 upper_class text-center" 
        >
        Unity with Others
        </h6>
        <h3 class="my-3 pad survey3_img_chartLatest" >
         <span> {{ unity_with_other }}</span>
          <img src="./assets/media/users/Q1.png" class="survey3_img" />
        </h3>
        <div class="cen_ali">
          You are in the {{ unity_with_others_label }}<sup>th</sup><br />
          Percentile.
        </div>
      </div>
      <div id="divchart" class="mt-5">
        <canvas id="barchart" height="150"></canvas>
      </div>
    </div>
    <div class="col-sm-3 border_class">
      <div id="divchartt2" class="chart_details">
        <h6
        class="h4_title1 upper_class text-center" 
        >
        Service to Others
        </h6>

        <h3 class="my-3 pad survey3_img_chartLatest">
          {{ service_to_others }}
          <img src="./assets/media/users/Q2.png" class="survey3_img" />
        </h3>
        <div class="cen_ali">
          You are in the {{ service_to_others_label }}<sup>th</sup><br />
          Percentile.
        </div>
      </div>
      <div id="divchart1" class="mt-5">
        <canvas id="barchart1" height="150"></canvas>
      </div>
    </div>
    <div class="col-sm-3 border_class">
      <div id="divchartt3" class="chart_details">
        <h6
        class="h4_title1 upper_class text-center" 
        >
        Expressing Full Potential
        </h6>

        <h3 class="my-3 pad survey3_img_chartLatest">
          {{ expressing_full_potential }}
          <img src="./assets/media/users/Q3.png" class="survey3_img" />
        </h3>
        <div class="cen_ali">
          You are in the {{ expressing_full_potential_label }}<sup>th</sup
          ><br />
          Percentile.
        </div>
      </div>
      <div id="divchart2" class="mt-5">
        <canvas id="barchart2" height="150"></canvas>
      </div>
    </div>
    <div class="col-sm-3">
      <div id="divchartt4" class="chart_details">
        <h6 class="h4_title1 upper_class text-center" 
        >
        Integrity with Self
        </h6>

        <h3 class="my-3 pad survey3_img_chartLatest">
          {{ integrity_with_self }}
          <img src="./assets/media/users/Q4.png" class="survey3_img" />
        </h3>
        <div class="cen_ali">
          You are in the {{ integrity_with_self_label }}<sup>th</sup>
          <br />Percentile.
        </div>
      </div>
      <div id="divchart3" class="mt-5">
       <canvas id="barchart3" height="150"></canvas>
      </div>
    </div>
  </div>
</div>

<!-- <div class="col-sm-12 mt-5">
  <div [innerHtml]="report_3_body" class="mt-5"></div>
</div> -->
  <!-- <div class="row"> -->
      
<!-- <div class="col-sm-12 mt-5">
  <div class="row">
    <div class="col-sm-4"></div>
    <div class="col-sm-4">
      <p class="section-text">
        <span
          class="arrow-highlight cursorp"
          (click)="mayaVideo()"
          >View</span
        >
        Maya: "My score isn't typical!"
      </p>
    </div>
    <div class="col-sm-4"></div>
  </div>
</div> -->

  
<div class="col-sm-12 mt-15">
  <div class="row">
    <div class="col-sm-12 ">
      <h2 class="section-title1">
        Your PMP: A combined view
      </h2>
      <p class="section-text">Another way to view your PMP is to look at all four pathways together. The green shape below depicts your PMP score compared to the typical PMP score shown in gray.</p>
      <p class="section-text mb-5">Look at your PMP shape. Is it…</p>
<ul class="section-text">
<li>larger or smaller than the typical PMP?</li>
<li>favoring a particular part of the circle?</li>
</ul>
<p class="section-text pt-5">Your profile may be relatively balanced across the four pathways. Or it may lean towards the top, bottom, left, or right of the circle.</p>

</div>
</div>
<table align="center" border="0" class="w-100">
  <tr>
    <td align="left">

            <div class="mr-10 d-flex">
              <div class="box">&nbsp;</div>
              <label for="self">Your PMP Score</label>
            </div>
            <div class="d-flex">
              <div class="box1">&nbsp;</div>
              <label for="average">Typical PMP Score</label>
            </div>
      </td>
      <td >
        <div class="row">
          <div class="col-sm-9">
            <canvas class="psedoclassforBG" id="radar_pie_chart"></canvas>
          </div>
        </div>
      </td>
    </tr>
  </table>
  <p class="section-text">Everyday life has its natural imbalances, so don't be concerned if you're not ‘perfectly balanced’. Consider what the size and shape mean for you, then read the examples below.</p>  <!-- </div> -->
  <div class="row mt-20 section-text">
    <div class="col-sm-2">
      <img src="./assets/media/pmp-score.png" class="img-fluid margin_auto" />
      <canvas class="psedoclassforBG d-none" id="radar_pie_chart1"></canvas>
    </div>
    <div class="col-sm-10 pl-0">
      <h2 class="section-title1">PMP Practical Examples</h2>
<p  class="section-text">Consider a situation in which a person has the following PMP scores (shown in green):</p>
<ul class="section-text">
  <li>Medium scores on “Integrity with Self” and “Unity with Others”</li>
  <li>High score on “Service to Others”</li>
  <li>Low score on “Expressing Full Potential”</li>
</ul>


    </div>
  </div>
</div>
