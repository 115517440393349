
<kt-spinner></kt-spinner>

<div class="card register_card absolutueCenter lg loginn-card">
  <button class="close-dialog" routerLink="/home">X</button>

  <h1 class="card_title">Forgot Password</h1>

  <div class="subtitle">
    <h6 class="verify_edit_text">
      Enter the Email ID you had used while registering with MeaningSphere.
    </h6>
  </div>

  <br />

  <form
    class="row"
    [formGroup]="passwordResetForm"
    (ngSubmit)="OnPwdResetSubmit(passwordResetForm.value)"
    novalidate
  >
    <div class="col-sm-12 text-center my-3">
      <mat-form-field class="w-100">
        <mat-label>Email ID</mat-label>
        <input
          matInput
          formControlName="username" pattern="^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$"
          oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;._+-@ ]|^ /g,'')"
        />
      </mat-form-field>
    </div>
    <div class="col-sm-12 mt-20 d-flex justify-content-end">
      <button
        [disabled]="!passwordResetForm.valid"
        class="btn my_btn begin_quiz"
        type="submit"
      >
        Send
      </button>
    </div>
  </form>
</div>
