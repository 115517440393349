import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OverviewService } from '../../../../service/overview.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'kt-info-mom-details',
  templateUrl: './info-mom-details.component.html',
  styleUrls: ['./info-mom-details.component.scss'],
})
export class InfoMomDetailsComponent implements OnInit {
  param: number;
  name = '';
  parameter_value = '';

  constructor(
    public dialogRef: MatDialogRef<InfoMomDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private overviewservice: OverviewService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    public sanitizer: DomSanitizer
  ) {
    this.param = data.param;
    this.name = data.name;
    this.parameter_value = data.parameter_value;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
  redirectTo(param): void {
    if (param === 128) {
      this.router.navigateByUrl('/discover/my-guides');
    } else if (param === 126) {
      this.router.navigateByUrl('/meaningful-work-survey/my-surveys');
    } else if (param === 127) {
      this.router.navigateByUrl('/meaning-mirror/mirror-list');
    } else if (param === 130) {
      this.router.navigateByUrl('/meaning-network/my-feed');
    } else if (param === 131) {
      this.router.navigateByUrl('/meaning-communities/my-community');
    } else if (param === 132) {
      this.router.navigateByUrl('/meaning-circle/my-circles');
    } else if (param === 129) {
      this.router.navigateByUrl('/library/all');
    } else if (param === 125) {
      this.router.navigateByUrl('/meaning-network/my-profile');
    }

    this.dialog.closeAll();
  }
}
