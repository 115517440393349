import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'kt-surveyreport3',
  templateUrl: './surveyreport3.component.html',
  styleUrls: ['./surveyreport3.component.scss'],
})
export class Surveyreport3Component implements OnInit {
  data1: any;
  report_3_body: any;
  @Input() memberId;
  @Input() currentSelectedSurvey;
  @Input() json_report;
  constructor(private elementRef: ElementRef) {}
  myChart: [];
  reportStr: string;
  reportData = [];
  unity_with_other: number;
  service_to_others: number;
  expressing_full_potential: number;
  integrity_with_self: number;
  unity_with_other_default: number;
  service_to_others_default: number;
  expressing_full_potential_default: number;
  integrity_with_self_default: number;
  unity_with_others_label: string;
  service_to_others_label: string;
  expressing_full_potential_label: string;
  integrity_with_self_label: string;
  ngOnInit() {
    this.reportData = JSON.parse(this.json_report);

    this.unity_with_others_label = this.reportData['unity_with_others_label'];
    this.service_to_others_label = this.reportData['service_to_others_label'];
    this.expressing_full_potential_label = this.reportData[
      'expressing_full_potential_label'
    ];
    this.integrity_with_self_label = this.reportData[
      'integrity_with_self_label'
    ];

    this.unity_with_other = this.reportData['unity_with_others_value'];
    this.unity_with_other_default = this.reportData[
      'unity_with_others_default'
    ];
    this.service_to_others = this.reportData['service_to_others_value'];
    this.service_to_others_default = this.reportData[
      'service_to_others_default'
    ];
    this.expressing_full_potential = this.reportData[
      'expressing_full_potential_value'
    ];
    this.expressing_full_potential_default = this.reportData[
      'expressing_full_potential_default'
    ];
    this.integrity_with_self = this.reportData['integrity_with_self_value'];
    this.integrity_with_self_default = this.reportData[
      'integrity_with_self_default'
    ];
    let radio_ref = this.elementRef.nativeElement.querySelector(
      `#radar_pie_chart`
    );
    let radatCharWithCIrcular = new Chart(radio_ref, {
      type: 'radar',
      data: {
        labels: ['', '', '', ''],
        datasets: [
          {
            label: 'Your PMP Score',
            backgroundColor: '#a1d45e',
            borderColor: '#a1d45e',
            pointBackgroundColor: '#a1d45e',
            data: [
              this.unity_with_other,
              this.service_to_others,
              this.expressing_full_potential,
              this.integrity_with_self,
            ],
            borderWidth: 1,
            lineTension: 0.4,
            pointBorderWidth: 0,
          },
          {
            label: 'Typical PMP Score',
            backgroundColor: '#8a8a8e',
            borderColor: '#8a8a8e',
            pointBackgroundColor: '#8a8a8e',
            data: [
              this.unity_with_other_default,
              this.service_to_others_default,
              this.expressing_full_potential_default,
              this.integrity_with_self_default,
            ],
            borderWidth: 1,
            lineTension: 0.4,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 5,
            bottom: 5,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scale: {
          legend: {
            display: true,
            labels: {
              fontColor: 'rgb(255, 99, 132)',
            },
          },
          ticks: {
            display: false,
            beginAtZero: true,
            fontColor: '#536087',
            stepSize: 10,
          },
          pointLabels: {
            display: false,
            fontSize: 15,
            fontColor: '#536087',
          },

          gridLines: {
            circular: true,
            color: '', // #536087
          },
          angleLines: {
            display: false,
            color: '#536087',
          },
        },
      },
    });
    radatCharWithCIrcular.options.legend.display = false;
    radatCharWithCIrcular.options.tooltips.enabled = false;
    let radio_ref1 = this.elementRef.nativeElement.querySelector(
      `#radar_pie_chart1`
    );
    let radatCharWithCIrcular1 = new Chart(radio_ref1, {
      type: 'radar',
      data: {
        labels: ['', '', '', ''],
        datasets: [
          {
            label: 'Your PMP Score',
            backgroundColor: '#a1d45e',
            borderColor: '#a1d45e',
            pointBackgroundColor: '#a1d45e',
            data: [
              this.unity_with_other,
              this.service_to_others,
              this.expressing_full_potential,
              this.integrity_with_self,
            ],
            borderWidth: 1,
            lineTension: 0.4,
            pointBorderWidth: 0,
          },
          {
            label: 'Typical PMP Score',
            backgroundColor: '#8a8a8e',
            borderColor: '#8a8a8e',
            pointBackgroundColor: '#8a8a8e',
            data: [
              this.unity_with_other_default,
              this.service_to_others_default,
              this.expressing_full_potential_default,
              this.integrity_with_self_default,
            ],
            borderWidth: 1,
            lineTension: 0.4,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 5,
            bottom: 5,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scale: {
          legend: {
            display: true,
            labels: {
              fontColor: 'rgb(255, 99, 132)',
            },
          },
          ticks: {
            display: false,
            beginAtZero: true,
            fontColor: '#536087',
            stepSize: 10,
          },
          pointLabels: {
            display: false,
            fontSize: 15,
            fontColor: '#536087',
          },

          gridLines: {
            circular: true,
            color: '', // #536087
          },
          angleLines: {
            display: false,
            color: '#536087',
          },
        },
      },
    });
    radatCharWithCIrcular1.options.legend.display = false;
    radatCharWithCIrcular1.options.tooltips.enabled = false;

    let htmlRef1 = this.elementRef.nativeElement.querySelector(`#barchart`);
    let htmlRef2 = this.elementRef.nativeElement.querySelector(`#barchart1`);
    let htmlRef3 = this.elementRef.nativeElement.querySelector(`#barchart2`);
    let htmlRef4 = this.elementRef.nativeElement.querySelector(`#barchart3`);
    var myChart = new Chart(htmlRef1, {
      scaleSteps: 10,
      scaleOverride: true,
      type: 'bar',
      data: {
        labels: ['Your Score', 'Typical'],
        datasets: [
          {
            data: [
              this.reportData['unity_with_others_value'],
              this.reportData['unity_with_others_default'],
            ],
            backgroundColor: ['#8765AC', '#CDD0E3'],
            borderWidth: 0,
            fontColor: '#000000',
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: '#000000',

                stepSize: 1,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                max: 5,
                min: 0,
                fontColor: '#000000',
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
    var myChart1 = new Chart(htmlRef2, {
      type: 'bar',
      data: {
        labels: ['Your Score', 'Typical'],
        datasets: [
          {
            data: [
              this.reportData['service_to_others_value'],
              this.reportData['service_to_others_default'],
            ],
            backgroundColor: ['#F58737', '#CDD0E3'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: '#000000',
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: '#000000',
                max: 5,
                min: 0,
                stepSize: 1,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
    var myChart2 = new Chart(htmlRef3, {
      type: 'bar',
      data: {
        labels: ['Your Score', 'Typical'],
        datasets: [
          {
            data: [
              this.reportData['expressing_full_potential_value'],
              this.reportData['expressing_full_potential_default'],
            ],
            backgroundColor: ['#8BC13F', '#CDD0E3'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: '#000000',
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: '#000000',
                max: 5,
                min: 0,
                stepSize: 1,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
    var myChart3 = new Chart(htmlRef4, {
      scaleFontColor: '#000000',

      type: 'bar',
      data: {
        labels: ['Your Score', 'Typical'],

        datasets: [
          {
            data: [
              this.reportData['integrity_with_self_value'],
              this.reportData['integrity_with_self_default'],
            ],
            backgroundColor: ['#F05573', '#CDD0E3'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: '#000000',
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: '#000000',
                max: 5,
                min: 0,
                stepSize: 1,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
  }
}
