<div class="mat-dialog-popup">
  <table width="100%" class="mb-3">
    <tr>
      <td>
        <h1 class="cmn_title_top">{{ full_name }}'s Treasures</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <mat-dialog-content>
    <div class="fixed_cntent my-3">
      <div class="search-results imagePosts scrollHeigh scroll_class_list" infinite-scroll
        [infiniteScrollDistance]="modalScrollDistance" [infiniteScrollThrottle]="modalScrollThrottle"
        [scrollWindow]="false" (scrolled)="pushValue($event)">
        <ng-container *ngIf="t_count > 0">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4" *ngFor="let gem of myTreasure;">
              <div class="card archive_card front_card_background_clr constellation cursorp" (click)="
                  collabContentDetails(
                    gem.collab_content_details[0].collab_content_id,
                    gem.bucket_type,
                    gem.collab_content_details[0].title,
                    gem.collab_content_details[0].description,
                    gem.collab_content_details[0]?.video_indicator,
                    gem.collab_content_details[0].attachment_path
                  )
                ">
                <div class="feedBoxHeader">
                  <div class="feedBoxIcon">
                   
                    <img class="profile_feedheader_img profile_img" [src]="
                          gem.collab_content_details[0].created_by_curator_indicator ? './assets/media/users/sphere.png' : gem.collab_content_details[0].picture_path
                            ? gem.collab_content_details[0].picture_path
                            : './assets/media/users/default.png'
                        " />
                   
                        <div class="userDetail">
                          <span class="name">
        {{
                          gem.collab_content_details[0].created_by_curator_indicator ? "MeaningSphere Editor" :
                          gem.collab_content_details[0].full_name
                          }}</span>
                        
                          <span class="feedTime m-0 fs-14" *ngIf="checkStatus(gem.collab_content_details[0].member__status)">(This Explorer is no longer active)</span>
                        <span class="feedTime mt-0">{{
                          gem.action_date | date: "MMM d, y"
                          }}</span>
                      </div>
                   
                  </div>
                </div>

                <div class="postedData">
                  <div class="">
                    <span class="name olny_two_line_show">
                      {{ gem.collab_content_details[0].title }}
                    </span>
                  </div>

                  <ng-container *ngIf="gem.collab_content_details[0].video_indicator == 1 && gem.collab_content_details[0].attachment_path">
                    <img src="{{
                        gem.collab_content_details[0].attachment_path
                          ? gem.collab_content_details[0].attachment_path
                          : './assets/media/default/post_default.png'
                      }}" alt="" class="constellation_img all_other_img mt-2" />
                  </ng-container>
                 
                  <div class="postedDataInfo mt-2">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="name_description olny_three_line_show word_break" [innerHTML]="
                            sanitizer.bypassSecurityTrustHtml(
                              gem.collab_content_details[0].description
                            )
                          "></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="t_count == 0">
          <span>You do not have any treasure items!</span>
        </ng-container>
      </div>
    </div>
  </mat-dialog-content>
</div>