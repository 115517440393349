<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1 class="popup_card_title">Cancelation Request</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <ng-container>
    <form [formGroup]="cancellationRequestForm" (ngSubmit)="requestCancellationSave(cancellationRequestForm.value)"
      novalidate autocomplete="off">
      <div class="">
        <div class="row">
          <div class="col-sm-12">
            <p>
              Discussion With
              <span *ngIf="loggedInUserId == guide">{{ ExplorerFullname }}</span><span
                *ngIf="loggedInUserId == explorer">{{
                GuideFullname
                }}</span>
              On {{ start_date | date: "d MMMM y":current_timezone }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field style="width: 100%">
              <mat-label>Reason for requesting cancelation</mat-label>
              <textarea maxlength="700" required formControlName="reason" matInput cols="30" rows="10"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.\-_+()@!?#$% ]|^ /g,'')"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <ng-container *ngIf="
                clicked_from != 'endorse_guide' &&
                diffrence_in_hours < 24 &&
                eventType != 2733
              ">
              <table>
                <tr>
                  <td colspan="2">
                    <mat-radio-group aria-label="Select an option">
                      <div class="row">
                        <div class="col-md-6">
                          <mat-radio-button checked="true" value="1" (click)="selectDeduction(1)"><small>Cancel without
                              Balance Deduction</small></mat-radio-button>
                        </div>
                        <div class="col-md-6">
                          <mat-radio-button value="2" (click)="selectDeduction(2)"><small>Cancel with Balance
                              Deduction</small></mat-radio-button>
                        </div>
                      </div>
                    </mat-radio-group>
                  </td>
                </tr>
              </table>
            </ng-container>
          </div>
        </div>
        <hr />
      </div>
      <div class="row">
        <div class="col-sm-12 tr">
          <button class="btn begin_quiz ml-2" type="submit" [disabled]="!cancellationRequestForm.valid || saveButton">
            {{
            clicked_from != "endorse_guide"
            ? "Cancel Request"
            : "Request Cancelation"
            }}
          </button>

          <button mat-dialog-close class="btn begin_quiz ml-2 button-change-scroll canceled_btn">
            CANCEL
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</div>