import { message_properties } from './../../../../../../environments/message_properties';
import { environment } from './../../../../../../environments/environment';
import { MeaningFeedsService } from './../../../../../service/meaning-feeds.service';
import { UploadService } from './../../../../../service/upload.service';
import { CuratorPageService } from './../../../../../service/curator-page.service';
import { NotificationService } from './../../../../../service/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxImageCompressService } from 'ngx-image-compress';
import { folder_path } from '../../../../../../environments/folder_path';
import { AccountsService } from './../../../../../service/accounts.service';

@Component({
  selector: 'kt-edit-collab-content',
  templateUrl: './edit-collab-content.component.html',
  styleUrls: ['./edit-collab-content.component.scss'],
})
export class MeaningCentralEdit implements OnInit, OnDestroy {
  collabContentForm: UntypedFormGroup;
  showAndHide: boolean = false;
  usersdata: any[];
  selected: number;
  collabContantType: number = 0;
  collabContentCategory: number = 0;
  mentor_indicator: number = 0;
  curator_indicator: number = 0;
  admin_indicator: number = 0;
  creatingUser: number;
  titleHeader: string;
  ufullname: string;
  vedio_path: any;
  image_path: any;
  user_profile_path: string = './assets/media/users/default.png';
  user_profile_path_data: boolean;
  viewdelete: boolean = true;

  suggesteduserdata: any[];
  active: number;
  linkSubscription: Subscription;
  collabSaveSubscription: Subscription;
  saveButton: boolean = false;

  fileToUpload: File;
  imageUrl: File;
  videoUrl: File;
  linkUrl: string;
  link_preview: boolean = false;
  image_preview: boolean = false;
  profile_path: any;

  selectCollabContantTypes = [];
  myDate;
  collabContantId: number;
  type: string;
  categoryLabel: string = '';
  loggedInUser: number;
  link_preview_data_arr: [];
  link_absolute_image: string;
  link_description: string;
  link_force_title: string;
  link_image: string;
  link_title: string;
  link_preview_data = [];
  link_url: any;
  videoSize: number;
  imageSize: number;
  attachment_indicator: boolean = false;
  is_image_null: boolean = false;
  config = {
    placeholder: 'Enter a brief description',
    tabsize: 2,
    height: 150,

    toolbar: [],
    disableDragAndDrop: true,
    disableResizeEditor: true,
    callbacks: {
      onPaste: function (e) {
        var bufferText = (e.originalEvent || e).clipboardData.getData('Text');
        e.preventDefault();
        document.execCommand('insertText', false, bufferText);
      },
    },
  };
  file_name: string;

  image_link_uploaded: boolean = false;
  attachment_path: string = '';
  title: string = '';
  description: string = '';
  referenceUrl: string = '';
  enableUrl: boolean = true;
  collab_content_category: number;
  constructor(
    public dialogRef: MatDialogRef<MeaningCentralEdit>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private toastr: NotificationService,
    private curatorService: CuratorPageService,
    private upload: UploadService,
    private meaningFeed: MeaningFeedsService,
    private imageCompress: NgxImageCompressService,
    private accountService: AccountsService
  ) {
    this.collabContantId = data.collabContantId;
    this.videoSize = environment.videoSize;
    this.imageSize = environment.imageSize;
    this.title = data.title;
    this.description = data.description;
    this.image_path = data.attachment_path
      ? data.attachment_path !== './assets/media/default/post_default.png'
        ? data.attachment_path
        : null
      : null;
    this.referenceUrl = data.referenceUrl;
    this.collab_content_category = data.collab_content_category;
  }

  ngOnInit(): void {
    this.viewdelete = false;
    const regExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    this.loggedInUser = this.accountService.muser;
    this.collabContentForm = this.formBuilder.group({
      title: new UntypedFormControl(this.title, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      collabcontenttype: new UntypedFormControl(2576),
      collabcontentcategory: new UntypedFormControl(
        this.collab_content_category
      ),
      collabcontentstatus: new UntypedFormControl(1324),
      description: new UntypedFormControl(this.description, [
        Validators.required,
      ]),
      suggesting_user: new UntypedFormControl(''),
      we_recomended_by: new UntypedFormControl(''),
      creating_user: new UntypedFormControl(this.creatingUser),
      created_by_curator_indicator: new UntypedFormControl(1),
      active: 1,
      form_type: new UntypedFormControl('from_curator'),
      imageUrl: new UntypedFormControl(this.image_path),
      videoUrl: new UntypedFormControl(''),
      collab_content_id: new UntypedFormControl(this.collabContantId),
      textUrl: new UntypedFormControl(
        this.referenceUrl ? this.referenceUrl : '',
        [Validators.pattern(regExp)]
      ),
      attachment_file: new UntypedFormControl(true),
    });
  }

  async saveCollabContent(ngForm: NgForm) {
    this.saveButton = true;
    this.collabSaveSubscription = this.curatorService
      .updateWallOfFame(ngForm)
      .subscribe(
        (data) => {
          if (data.status === 201) {
            this.dialogRef.close();
            this.spinner.hide();
            this.saveButton = false;
            this.toastr.showSuccess(
              message_properties.CREATE_MEANING_CENTRAL_POPUP_UPDATE_WALLOF_FAME_SUCCESS,
              ''
            );
          } else if (data.status === 204) {
            this.spinner.hide();
            this.saveButton = false;
            this.toastr.showError(data.message, '');
          } else {
            this.saveButton = false;
            this.spinner.hide();
            this.toastr.showError(
              message_properties.CREATE_MEANING_CENTRAL_POPUP_UPDATE_WALLOF_FAME_ERROR,
              ''
            );
          }
        },
        () => {
          this.saveButton = false;
          this.spinner.hide();
          this.toastr.showError(
            message_properties.CREATE_MEANING_CENTRAL_POPUP_UPDATE_WALLOF_FAME_ERROR,
            ''
          );
        }
      );
  }

  linkPreview(event) {
    this.linkUrl = event.target.value;
    if (this.linkUrl.includes('http://')) {
      this.linkUrl = this.linkUrl.replace('http://', '');
    }
    if (this.linkUrl.includes('https://')) {
      this.linkUrl = this.linkUrl.replace('https://', '');
    }
    if (this.linkUrl.includes('/')) {
      this.linkUrl = this.linkUrl.split('/')[0];
    }
    this.collabContentForm.patchValue({
      attachment_file: true,
    });
    this.linkSubscription = this.meaningFeed
      .linkPreview(this.linkUrl)
      .subscribe((data) => {
        this.link_preview_data = data['result'];
        if (
          this.link_preview_data['image'] !== null &&
          this.link_preview_data['image'] !== ''
        ) {
          this.image_path = this.link_preview_data['image'];
          this.collabContentForm.patchValue({
            imageUrl: this.link_preview_data['image'],
          });
        } else {
          let formData = this.collabContentForm.value;
          this.image_path = formData['imageUrl'];
          if (this.image_path !== '' && this.image_path !== null) {
            this.image_path = formData['imageUrl'];
          } else {
            this.image_path = null;
          }
        }
        this._cdr.detectChanges();
      });

    this._cdr.detectChanges();
  }
  delete_ImageVedioUrl() {
    this.deleteLinkImagePathData();
  }
  imageUpload() {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      let mimeType = image.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
      let letmineTypeArr = mimeType.split('/');
      if (
        mimeType !== 'image/jpeg' &&
        mimeType !== 'image/jpg' &&
        mimeType !== 'image/png'
      ) {
        this.toastr.showError(
          message_properties.MEANING_CENTRAL_PROFILE_HANDLE_FILE_INPUT_ERROR,
          ''
        );
        return false;
      }
      if (this.imageCompress.byteCount(image) > this.imageSize) {
        this.toastr.showError(
          message_properties.CREATE_MEANING_CONSELLATION_POPUP_IMAGE_PREVIEW_SIZE_ERROR,
          ''
        );
        return false;
      }
      this.imageCompress
        .compressFile(image, orientation, 40, 40) // 50% ratio, 50% quality
        .then((compressedImage) => {
          this.image_path = compressedImage;
          let base64ToImage = this.upload.base64toFileInformation(
            compressedImage,
            (Math.floor(Math.random() * 1000) + 1).toString() +
              Date.now().toString() +
              '.' +
              letmineTypeArr[1]
          );
          this.upload.fileUpload(base64ToImage, base64ToImage.name, 1);
          this.file_name = `${this.accountService.AWS_BUCKET_888}${folder_path.folder_content}${base64ToImage.name}`;
          this.collabContentForm.patchValue({
            imageUrl: this.file_name,
          });
          this._cdr.detectChanges();
        });
    });
  }
  deleteLinkImagePathData() {
    this.image_path = undefined;
    this.linkUrl = undefined;
    this.collabContentForm.patchValue({
      imageUrl: '',
    });
  }
  ngOnDestroy(): void {
    if (this.linkSubscription) {
      this.linkSubscription.unsubscribe();
    }
    if (this.collabSaveSubscription) {
      this.collabSaveSubscription.unsubscribe();
    }
  }
}
