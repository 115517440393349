<div class="mirror-view">
  <h1 mat-dialog-title>
    <button mat-button mat-dialog-close class="modal_close_button_top">
      X
    </button>
  </h1>
  <div class="fixed_cntent">
    <div class="example-full-width">
      <h1
        class="only_one_line_show"
        *ngIf="mirror['survey_status'] == '2252'"
        title="{{ mirror.survey_response[4]!!['free_format_answer'] }}"
        [innerHTML]="mirror.survey_response[4]!!['free_format_answer']"
      ></h1>
      <hr />
    </div>
  </div>

  <div class="content_conta content_conta-scroll">
    <div
      class="content_part mt-2 word_break_for_mirror_view"
      *ngFor="let mirror of mirrorData; let j = index"
    >
      <p
        class="p_edit word_break_for_mirror_view"
        *ngIf="mirror.question_response_text[0]['question_type'] == '2254'"
        [innerHTML]="
          sanitizer.bypassSecurityTrustHtml(
            j !== 4 ? mirror.question_response_text[0]['question_text'] : ''
          )
        "
      ></p>
      <p
        class="p_edit word_break_for_mirror_view"
        *ngIf="mirror.question_response_text[0]['question_type'] == '2360'"
        [innerHTML]="
          sanitizer.bypassSecurityTrustHtml(
            mirror.question_response_text[0]['question_text']
          )
        "
      ></p>
      <p
        class="p_edit word_break_for_mirror_view"
        *ngIf="mirror.question_response_text[0]['question_type'] == '2253'"
        [innerHTML]="
          sanitizer.bypassSecurityTrustHtml(
            mirror.question_response_text[0]['question_text']
          )
        "
      ></p>
      <p
        class="p_edit word_break_for_mirror_view"
        *ngIf="mirror.question_response_text[0]['question_type'] == '2535'"
        [innerHTML]="
          sanitizer.bypassSecurityTrustHtml(
            mirror.question_response_text[0]['question_text']
          )
        "
      ></p>
      <p
        class="p_edit word_break_for_mirror_view"
        *ngIf="mirror.question_response_text[0]['question_type'] == '2253'"
        [innerHTML]="
          sanitizer.bypassSecurityTrustHtml(
            mirror.question_response_text[0]['question_text']
          )
        "
      ></p>
      <p class="p_edit_ans word_break_for_mirror_view">
        <ng-container
          *ngIf="mirror.question_response_text[0]['question_type'] == '2254'"
        >
          <span *ngIf="j !== 4">
            <span
              class="word_break_for_mirror_view"
              [innerHTML]="
                sanitizer.bypassSecurityTrustHtml(mirror.free_format_answer)
              "
            >
            </span>
          </span>
          <!-- {{ j !== 4 ? mirror.free_format_answer : "" }} -->
        </ng-container>
        <ng-container
          *ngIf="mirror.question_response_text[0]['question_type'] == '2360'"
        >
          <span *ngFor="let checkbox of mirror.free_format_answer.split(',')">
            <!--156:Happy:false:true,157:Hopeless:false:true,158:Angry:false:false,159:Hurt:false:false,-->
            <div
              *ngIf="checkbox.split(':')[3] == 'true'"
              class="word_break_for_mirror_view"
            >
              {{
                checkbox.split(":")[2] == "true"
                  ? mirror.checkbox_with_option_texbox
                  : checkbox.split(":")[1]
              }}
            </div>
          </span>
        </ng-container>
        <ng-container
          *ngIf="mirror.question_response_text[0]['question_type'] == '2253'"
        >
          <span *ngFor="let checkbox of mirror.free_format_answer.split(',')">
            <!--156:Happy:false:true,157:Hopeless:false:true,158:Angry:false:false,159:Hurt:false:false,-->
            <div
              *ngIf="checkbox.split(':')[3] == 'true'"
              class="word_break_for_mirror_view"
            >
              {{ checkbox.split(":")[1] }}
            </div>
          </span>
        </ng-container>
        <ng-container
          *ngIf="mirror.question_response_text[0]['question_type'] == '2535'"
        >
          <!--"1:Relieved,1:Happy,1:Thrilled,1:Energized,2:Uneasy,2:Nervous,2:Disappointed,2:Impatient,2:Resentful"-->
          <table width="100%">
            <tr>
              <td width="50%" *ngIf="group_selected_one != ''">
                I feel <b>{{ group_selected_one }}</b> because.
              </td>

              <td width="50%" *ngIf="group_selected_two != ''">
                I feel <b>{{ group_selected_two }}</b> because.
              </td>
            </tr>
            <tr>
              <td width="50%" *ngIf="select_group_text_value_text1 != '0'">
                <p class="word_break_for_mirror_view"
                [innerHTML]="sanitizer.bypassSecurityTrustHtml(select_group_text_value_text1)">
                </p>
              </td>

              <td width="50%" *ngIf="select_group_text_value_text2 != '0'">
                <p class="word_break_for_mirror_view"
                [innerHTML]="sanitizer.bypassSecurityTrustHtml(select_group_text_value_text2)"
                >
                </p>
              </td>
            </tr>
          </table>
          <span *ngFor="let checkbox of mirror.group_answer.split(',')">
            <!--156:1:Happy:false:true,157:Hopeless:false:true,158:Angry:false:false,159:Hurt:false:false,-->
            <div *ngIf="checkbox.split(':')[3] == 'true'">
              {{ checkbox.split(":")[1] }},
            </div>
          </span>
        </ng-container>
      </p>
    </div>
    <div>
      <label class="word_break_for_mirror_view">
        <ng-container *ngFor="let mirror of mirrorData; let i = index">
          <p
            class="p_edit word_break_for_mirror_view"
            *ngIf="
              i == 0 && mirror.survey_participant_text[0]['feedback'] != null
            "
          >
            My updates on this Reflection
          </p>
          <p
            [innerHTML]="
              sanitizer.bypassSecurityTrustHtml(
                i == 0
                  ? mirror.survey_participant_text[0]['feedback'] != null
                    ? mirror.survey_participant_text[0]['feedback']
                    : ''
                  : ''
              )
            "
          ></p>
        </ng-container>
      </label>
    </div>
  </div>
</div>
