<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1>Buy Guide Time</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form
    [formGroup]="guidepurchaseForm"
    (ngSubmit)="purchaseGuideBalance(guidepurchaseForm.value)"
    novalidate
    enctype="multipart/form-data"
    autocomplete="off"
  >
    <div class="row">
      <div class="col-sm-12">
        <p>
          Your guide:&nbsp;
          <img
            class="img-fluid curator-createimg"
            [src]="image_path"
          /> {{ first_name }} {{ last_name }}
        </p>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <table width="100%" border="0">
          <tr *ngFor="let guideData of GuideServiceList; let i = index">
            <td class="check_pos">
              <p>
                <input
                  (change)="onChecklistChange($event)"
                  type="checkbox"
                  [value]="guideData.id"
                  [id]="guideData.id"
                />
                <input type="hidden" formControlName="check_guide_discussion"/>
              </p>
            </td>
            <td>&nbsp;</td>
            <td class="desc_pos">
              <label [for]="guideData.id">{{ guideData.offering_name }}<br/><small>{{guideData.catalog_small_description}}</small></label>
            </td>
            <td>
              <table>
                <tr>
                  <td>
                    <a
                      (click)="minus_item(guideData.id, guideData.interval)"
                      class="btn begin_quiz"
                      id="minus-btn"
                    >
                    <mat-icon>remove</mat-icon>
                    </a>
                  </td>
                  <td>
                    <input 

                    class="input_time_interval text-center" 

                    readonly 

                    formControlName="time_interval_{{ guideData.id }}" 

                    type="text" 

                    min="1" 

                  /> 

                  </td>
                  <td>
                    <a
                      (click)="plus_item(guideData.id, guideData.interval)"
                      class="btn begin_quiz"
                      id="plus-btn"
                    >
                    <mat-icon>add</mat-icon>
                    </a>
                  </td>
                  <td> <p class="desc_dtls">&nbsp; {{descInternal(guideData.id)}}</p></td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br />
    <!-- <br />
    <br /> -->
    <hr />
    <div class="row">
      <div class="col-sm-12 tr">
        <button
          class="btn begin_quiz ml-2"
          [disabled]="!guidepurchaseForm.valid || saveBtn"
          type="submit"
        >
          Add To Cart
        </button>
        <button
          mat-dialog-close
          class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
