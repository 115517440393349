import { DomSanitizer } from '@angular/platform-browser';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kt-diy-details',
  templateUrl: './diy-details.component.html',
  styleUrls: ['./diy-details.component.scss'],
})
export class DiyDetailsComponent implements OnInit {
  data_value: string;
  participantDiyID: number;
  diyItems: any[];
  count: number;
  diyActionItems: any[];
  actionItem;
  stop_doing: string;
  start_doing: string;
  continue_doing: string;
  config = {
    tabsize: 2,
    height: 120,
    color: 'white',
    toolbar: [['']],
  };
  constructor(
    public dialogRef: MatDialogRef<DiyDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer
  ) {
    this.participantDiyID = data.participantDiyID;
    this.data_value = data.data_value;
    this.stop_doing = data.stop_doing;
    this.start_doing = data.start_doing;
    this.continue_doing = data.continue_doing;
    this.diyItems = data.question_answer_list;
  }

  ngOnInit(): void {}
}
