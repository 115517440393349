import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SecondWelcomePageComponent } from './../second-welcome-page/second-welcome.component';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MeaningNetworkService } from './../../../../service/meaning-network.service';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-third-welcome',
  templateUrl: './third-welcome.component.html',
  styleUrls: ['./third-welcome.component.scss'],
})
export class ThirdWelcomePageComponent implements OnInit, OnDestroy {
  loggedInUser: number;
  selectedworkLifeBox: number;
  moodForm: UntypedFormGroup;
  shwCare = false;
  onboarding_complete: boolean;
 
  constructor(
    public dialogRef: MatDialogRef<ThirdWelcomePageComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private meaning_network_service: MeaningNetworkService,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
    this.moodForm = new UntypedFormGroup({
      visibility_type: new UntypedFormControl(1953),
      member_mood: new UntypedFormControl(),
      mood_reason: new UntypedFormControl('any'),
      member_mood_message: new UntypedFormControl('', [
        Validators.maxLength(30),
      ]),
      member: new UntypedFormControl(this.loggedInUser),
    });
  }
  previousPage(): void {
    this.dialog.open(SecondWelcomePageComponent, {
      width: '70%',
      disableClose: true,
    });
    this.meaning_network_service
      .getWhatICareAbout(this.loggedInUser)
      .subscribe((data) => {});
    this.dialogRef.close();
  }

  close(): void {
    this.start();
    this.dialogRef.close();
  }
  nextPage(): void {
    this.dialogRef.close();
    this.dialog.open(SecondWelcomePageComponent, {
      width: '70%',
      disableClose: true,
    });
    this.dialogRef.close();
  }
  selectworkLifeBox(t, mood_type) {
    this.selectedworkLifeBox = t;
    this.moodForm.patchValue({
      member_mood: mood_type,
    });
  }
  moodSave(ngForm) {
    this.meaning_network_service
      .moodCreate(ngForm)
      .subscribe((data) => {
        localStorage.setItem('member_mood_inserted', 'true');
      });
  }
  start() {
    this.onboarding_complete = true;
    let data = {
      member: this.loggedInUser,
      onboarding_complete: this.onboarding_complete,
    };

    this.meaning_network_service
      .postwelcomeFinish(data)
      .subscribe((data) => {
        this.dialogRef.close();
      });
  }
  ngOnDestroy(): void {
    
  }
}
