import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kt-surveyreport1',
  templateUrl: './surveyreport1.component.html',
  styleUrls: ['./surveyreport1.component.scss'],
})
export class Surveyreport1Component implements OnInit {
  report_1_body: any;
  data = {};
  @Input() memberId;
  @Input() currentSelectedSurvey;
  @Input() json_report;

  constructor() {}

  ngOnInit(): void {}
}
