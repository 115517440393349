import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountsService } from './../../../../service/accounts.service';

@Component({
  selector: 'kt-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
})
export class HomeHeaderComponent implements OnInit {
  @Input() back;

  isLoggedInUser: boolean = false;
  constructor(
    private _router: Router,
    private accountsService: AccountsService
  ) {}

  ngOnInit(): void {
    if (this.accountsService.loggedIn) {
      this.isLoggedInUser = true;
    } else {
      this.isLoggedInUser = false;
    }
  }
  loginpage() {
    this._router.navigate(['/login']);
  }

  registerpage() {
    this._router.navigate(['/login/register']);
  }
  goBack() {
    this._router.navigate(['/about-meaningsphere']);
  }
  goHome() {
    this._router.navigate(['/home']);
  }
}
