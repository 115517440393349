<div class="mat-dialog-popup">
    <table width="100%">
      <tr>
        <td>
          <h2 class="cmn_title_top">
            Request for a Guided Discussion
          </h2>
        </td>
        <td>
          <h1 mat-dialog-title>
            <button mat-button mat-dialog-close class="modal_close_button_top">
              X
            </button>
          </h1>
        </td>
      </tr>
    </table>
    <hr />
    <ng-container>
      <form class="sendRequestForm"
        [formGroup]="sendRequestForm"
        (ngSubmit)="sendRequest(sendRequestForm.value)"
        novalidate
        autocomplete="off"
      >
        <div class="">
          <div class="row">
            <div class="col-lg-12 col-sm-12">
                            <p class="">
                Your Guide: &nbsp;
                <img
                  class="img-fluid curator-createimg"
                  [src]="guide_pic"  class="img20 br-50"
                />
                &nbsp;&nbsp;{{ fullName }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field class="w-100" >
                <mat-label>Personalize your message</mat-label>
                <textarea
                  matInput
                  formControlName="message"
                  cols="30"
                  rows="10"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-sm-12 tr">
            <button class="btn begin_quiz ml-2" type="submit"  [disabled]="!sendRequestForm.valid || saveButton">Request</button>
  
            <button mat-dialog-close class="btn begin_quiz ml-2 canceled_btn">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
  
