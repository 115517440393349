
<div class="row mt-2">
  <div class="col-sm-12 my-2">
    <div
      class="scroll_class card2-style-height-with-tabs-hr-heading"
      infinite-scroll
      [infiniteScrollDistance]="modalScrollDistance"
      [infiniteScrollThrottle]="modalScrollThrottle"
      [scrollWindow]="false"
      (scrolled)="pushValuePast($event)"
    >
      <ng-container *ngIf="past_guide_count > 0">
        <div class="row">
          <div
            class="col-lg-3 col-md-6 col-sm-12 mt-2 card-explorer"
            *ngFor="let mentor of pastGuides"
          >
            <div
              class="card archive_card front_card_background_clr document document-card"
              (click)="viewSharedSpace(mentor, 1, mentor.relating_member_profile[0].member__status)"
            >
              <div
                class="img_section_mirror text-center img-sectoin-chng cursorp"
              >
                <img
                  [src]="
                    returnImage(
                      mentor.relating_member_profile[0].picture_path
                    )
                  "
                  alt=""
                  class="w-124-pm archive_img new-c-photo new-c-photo-guid archive_img {{
                    mentor.member_accepted_count == 1
                      ? 'archive_img_border'
                      : ''
                  }} curator"
                />
              </div>

              <div class="title mt-2">
                <div class="member_card_name">
                  <h1 class="profilInfo cursorp">
                    {{
                      mentor.relating_member_profile[0].first_name
                    }}
                  </h1>
                  <h2 class="fs-18">
                    {{
                      mentor.relating_member_profile[0].last_name
                    }}
                  </h2>

                  <h3>
                    {{
                      mentor.member_address[0].city
                        ? mentor.member_address[0].city
                        : "------"
                    }},
                    {{
                      mentor.member_address[0].country
                        ? mentor.member_address[0].country
                        : "------"
                    }}
                  </h3>

                  <div class="archive_title">
                    <table border="0">
                      <tr>
                        <td>
                          <h4 class="fs-18">
                            Started on:
                            {{
                              mentor.start_date | date: "MMM d, y"
                            }}
                          </h4>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4 class="fs-18">
                            Ended on:
                            {{ mentor.end_date | date: "MMM d, y" }}
                          </h4>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="past_guide_count == 0">
        <table>
          <tbody>
            <tr>
              <td class="p-2">
                <img
                  src="./assets/media/no_data_found.png"
                  class="search_no_data_found_image"
                />
              </td>
              <td colspan="2" class="p-2">
                You did not have any Guides in the past!
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
</div>
    