import { MatDialog } from '@angular/material/dialog';
import { CollabContentDetailsPopupComponent } from './../../../../pages/meaning-constellation/collab-content-details-popup/collab-content-details-popup.component';
import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from '@angular/core';
import { MessagesService } from '../../../../../service/messages.service';
import { Subscription } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment-timezone';
import { CommonService } from '../../../../../service/common.service';
import { AccountsService } from './../../../../../service/accounts.service';
import { TimezoneService } from '../../../../../service/timezone.service';

@Component({
  selector: 'kt-message-details',
  templateUrl: './message-details.component.html',
  styleUrls: ['./message-details.component.scss'],
})
export class MessageDetailsComponent implements OnInit {
  allMessagesDetails: any[];
  creating_user: string;

  creatingUser: number;
  messageReplyForm: UntypedFormGroup;
  chat_for: number;
  results: [];
  count: number;
  creatingUser_Fullname: string;
  creatingUser_image: string;
  relatedUser_Fullname: string;
  relatedUser_image: string;
  writeACommentForm = false;
  subscription: Subscription;
  saveButton = false;
  varArr = [];
  dateArry = [];
  system_message_data = [];
  system_message_count: number;
  current_timezone: string;
  loggedinUserId: number;
  member_status = 0;
  @ViewChild('target') private myScrollContainer: ElementRef;

  @Input() childProp;
  @Output() childPropChange = new EventEmitter<{}>();
  public from_date: moment.Moment;

  constructor(
    private eRef: ElementRef,
    private message_service: MessagesService,
    private _cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private accountService: AccountsService,
    private timeZoneService: TimezoneService
  ) {}

  ngOnInit(): void {
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.varArr = JSON.parse(this.childProp);
    this.messageDetails();
    this.creatingUser = this.accountService.muser;
    this.loggedinUserId = this.accountService.muser;
    this.messageReplyForm = this.formBuilder.group({
      message: new UntypedFormControl('', [Validators.required]),
      chat_for: 1791,
      criticality: 1577,
      creating_user: new UntypedFormControl(this.varArr['logged_in_user_id']),
      attachment_path: new UntypedFormControl('test attachment'),
      receiving_user: new UntypedFormControl(this.varArr['selected_member_id']),
      collabContentId: new UntypedFormControl('0'),
      tenant: new UntypedFormControl(1),
    });
    this.systemGeneratedMessages();
  }
  scrollToElement(el): void {
    this.myScrollContainer.nativeElement.scroll({
      top: this.myScrollContainer.nativeElement.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }
  createResponse(ngForm: NgForm) {
    this.saveButton = true;
    this.message_service.chatReply(ngForm).subscribe(
      (data) => {
        if (data.status === 201) {
          this.messageReplyForm.patchValue({ message: '' });
          this.saveButton = false;
          this.messageDetails();
        }
      },
      (error) => {
        this.saveButton = false;
      }
    );
  }
  messageDetails() {
    this.subscription = this.message_service
      .getMessageDetails(
        this.varArr['selected_member_id'],
        this.varArr['logged_in_user_id']
      )
      .subscribe((data) => {
        this.allMessagesDetails = JSON.parse(JSON.stringify(data.results));
        this.member_status = this.allMessagesDetails[0]['final_data'][0][
          'member_status'
        ];
        setTimeout(() => this.scrollToElement('target'), 500);
        this._cdr.detectChanges();
      });
  }

  collabContentDetails(
    collabContentId,
    title,
    description,
    video_indicator,
    attachment_path,
    additional_info,
    link_preview
  ) {
    this.dialog.open(CollabContentDetailsPopupComponent, {
      disableClose: true,
      width: '80%',
      data: {
        collabContentId: collabContentId,
        title: title,
        description: description,
        video_indicator: video_indicator,
        attachment_path: attachment_path,
        additional_info: additional_info,
        link_preview: link_preview,
      },
    });
  }
  systemGeneratedMessages() {
    this.subscription = this.message_service
      .systenMessages(this.loggedinUserId)
      .subscribe((data) => {
        this.system_message_data = JSON.parse(JSON.stringify(data.results));
        this.system_message_count = data.count;
        this._cdr.detectChanges();
      });
  }
  onlyTimeTimeZone(startDateTime) {
    return moment(startDateTime).tz(this.current_timezone).format('h:mm a');
  }

  clickOnEnterButton(event, commentsForm, from_message_details) {
    if (
      event.keyCode === 13 &&
      from_message_details === 'from_message_details'
    ) {
      this.createResponse(commentsForm);
      this.messageReplyForm.patchValue({ message: '' });
      this.saveButton = false;
    }
  }
  checkStatusMember(status) {
    return CommonService.checkMemberStatus(status);
  }
  dateInUserTimezome(datetime: string) {
    this.from_date = moment(datetime).utc();
    this.timeZoneService.setTenantTimeZone(this.current_timezone);
    return this.timeZoneService.utcToTenantString(this.from_date, 'LLL');
  }
}
