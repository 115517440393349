<kt-spinner></kt-spinner>
<mat-card class="full_screen_background_card_height">
  <div class="d-flex justify-content-between align-items-center" [ngClass]="
  take_survey == '1' && check_survey_amount ||
  !check_survey_amount
    ? 'take-survey-height'
    : 'buy-survey-height'
">
    <h1 class="m-0">My Surveys</h1>
    <div class="d-flex justify-content-between align-items-center" [ngClass]="
    take_survey == '1' && check_survey_amount ||
    !check_survey_amount
      ? 'take-survey'
      : 'buy-survey'
  ">
      <p class="pt-5 mb-0 pr-5" *ngIf="take_survey == '1' && check_survey_amount">{{remaining_survey}} Survey(s) available by {{survey_valid | date: "MMMM d, y":current_timezone
        }} 
        <span tooltipClass="tooltip_design" theme="light" tooltip="{{ info_highlights }}" placement="right"
          showDelay="500" animation="fade" noArrow="false">
          <span class="icon-info">
            <mat-icon class="info_material_icon">info</mat-icon>
          </span>
        </span>
      </p>
      <a class="btn begin_quiz mr-2" *ngIf="show_buy_survey == '1' && check_survey_amount" (click)="buyMeaningfulSurvey()">Buy Survey Experience</a>
      <a class="btn begin_quiz mr-2" *ngIf="take_survey == '1' && check_survey_amount" (click)="takeSurvey()">Take Survey</a>
      <a class="btn begin_quiz mr-2" *ngIf="!check_survey_amount" (click)="takeSurvey()">Take Survey</a>
    </div>
  </div>

  <hr />
  <div class="search-results scroll_class card2-style-height-with-hr-heading" infinite-scroll
    [infiniteScrollDistance]="modalScrollDistance" [infiniteScrollThrottle]="modalScrollThrottle" [scrollWindow]="false"
    (scrolled)="pushValue($event)">
    <div class="">
      <ng-container *ngIf="count > 0">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12" *ngFor="let mom of momData;">
            <div class="card archive_card front_card_background_clr">
              <div class="title">
                <div class="text_on_image mt-3 archive_title">
                  <table classs="w-100" width="100%">
                    <tr>
                      <td align="left">
                        {{ mom["submitted_at"] | date: "MMM d, y" }}
                      </td>
                      <td align="right">
                        <img src="./assets/media/sidebar/mom.png" class="mom-img" />
                      </td>
                    </tr>
                  </table>
                </div>
                <hr />
                <div class="archive_title" *ngIf="mom['survey_status'] == '2252'">
                  <span>Completed</span>
                </div>
                <div class="archive_title" *ngIf="mom['survey_status'] == '2251'">
                  <span class="colorprogress">In Progress</span>
                </div>
                <div class="archive_title">
                  <table align="right">
                    <tr>
                      <td>
                        <button mat-icon-button [matMenuTriggerFor]="beforeMenu"
                          aria-label="Example icon-button with a menu" class="dot-button">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #beforeMenu="matMenu" xPosition="before">
                          <button mat-menu-item (click)="responsePageCall(mom['id'])"
                            *ngIf="mom['survey_status'] == '2252'">
                            <span>View my Response</span>
                          </button>
                          <button mat-menu-item (click)="goToSelectedSurveyReport(mom['id'], mom['json_report'])"
                            *ngIf="mom['survey_status'] == '2252'">
                            <span>View Report</span>
                          </button>
                          <button mat-menu-item (click)="
                              compareTwoSurvey(mom['id'], mom['submitted_at'])
                            " *ngIf="mom['survey_status'] == '2252'">
                            <span>Compare my Responses</span>
                          </button>
                          <button mat-menu-item (click)="
                              completeSurvey(mom['id'], mom['no_of_saves'])
                            " *ngIf="mom['survey_status'] != '2252'">
                            <span>Complete Survey</span>
                          </button>
                          <button mat-menu-item (click)="nextStep()" *ngIf="mom['id'] == latestGivenSurveyID">
                            <span>Next Steps</span>
                          </button>
                          <button *ngIf="
                              mom['survey_status'] == '2252' &&
                              mom['member_relationship'].length > 0
                            " mat-menu-item (click)="
                              shareWithGuide(
                                mom['id'],
                                mom['member_relationship'][0].relating_member
                              )
                            ">
                            <span>Share with Guide</span>
                          </button>
                        </mat-menu>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="count == 0">
        <table>
          <tbody>
            <tr>
              <td class="p-2">
                <img src="./assets/media/no_data_found.png" class="search_no_data_found_image" />
              </td>
              <td colspan="2" class="p-2">
                You have not taken the Survey yet!
                <a *ngIf="show_buy_survey == 1 && check_survey_amount" (click)="buyMeaningfulSurvey()" class="hyperlink_hand">
                  Buy Survey Experience</a>
                <a *ngIf="!check_survey_amount" (click)="takeSurvey()" class="hyperlink_hand">
                  Take Survey</a>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
  <!-- </cdk-virtual-scroll-viewport> -->
</mat-card>