<div class="mat-dialog-popup">
  <h1 class="cmn_title_top">Review Your Information</h1>

  <button mat-button mat-dialog-close class="close-dialog">X</button>

  <hr />
  <div class="fixed_cntent review-info">
    <mat-card-content>
      <div class="d-flex justify-content-between">
        <p class="m-0"><b>Name</b></p>
        <span>
          <mat-icon (click)="editName()" title="Edit" style="cursor: pointer;">edit</mat-icon>
        </span>
      </div>
      <hr class="hr-5" />
      <div *ngIf="!nameForm">
        <div class="row">
          <div class="form-group col-sm-12 d-flex align-items-center justify-content-between">
            <span class="d-flex align-items-center justify-content-between">{{
              billing_full_name
              }}</span>
          </div>
        </div>
      </div>
      <form *ngIf="nameForm" class="addressForm" [formGroup]="nameAForm"
        (ngSubmit)="billingFullNameUpdate(nameAForm.value)" enctype="multipart/form-data" novalidate>
        <div class="row">
          <div class="col-sm-12 pr-10">
            <table class="w-100">
              <tr class="w-100">
                <td width="90%" class="w-100">
                  <input type="text" class="w-100" formControlName="billing_full_name" autocomplete="off" tabindex="1"
                    style="box-shadow: none; border: 1px solid; height: 2.45rem"
                    oninput="this.value = this.value.replace(/[^A-Za-z ]|^ /g,'')" />
                </td>
                <td width="10%">
                  <button class="btn begin_quiz" type="submit" [disabled]="!nameAForm.valid">
                    Save
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <br />
      </form>
      <div class="d-flex justify-content-between">
        <p class="m-0">
          <b>Address</b>
        </p>
        <span>
          <mat-icon (click)="editAddress()" title="Edit" style="cursor: pointer;">edit</mat-icon>
        </span>
      </div>
      <hr class="hr-5" />
      <p class="m-0" *ngIf="secondary_country">
        {{ secondary_line1 ? secondary_line1 + ", " : "" }}
        {{ secondary_line2 ? secondary_line2 + ", " : "" }}<br />
        {{ secondary_city ? secondary_city+ ", " : "" }} {{ secondary_state? secondary_state + secondary_state_code+", "
        : "" }} <br />
        {{ secondary_country ? secondary_country + " " : "" }} {{ secondary_postalcode }}
      </p>
      <p class="m-0" *ngIf="!secondary_country">No data found!</p>
      <br />
      <!-- <span *ngIf="secondary_country"> -->
        <form class="addressForm" [formGroup]="confirmBillingAddress" enctype="multipart/form-data" novalidate>
          <mat-checkbox formControlName="check_box" class="w-100 mt-5" (change)="checkUncheck($event)">I declare that my
            country of residence is <b>{{ secondary_country }}</b> for tax and billing purposes*
          </mat-checkbox>
          <small>*Please update the address provided if it is not your country of residence.</small>
        </form>
      <!-- </span> -->
    </mat-card-content>
  </div>
</div>
<hr />
<div class="row">
  <div class="form-group col-sm-12 tr marg-none">
    <ng-container *ngIf="total_amount>0">
      <button class="btn begin_quiz ml-2 mt-1" [disabled]="!confirmBillingAddress.valid"
        (click)="continue(confirmBillingAddress.value)">
        Continue
      </button>
    </ng-container>
    <ng-container *ngIf="total_amount==0">
      <button class="btn begin_quiz ml-2 mt-1" [disabled]="!confirmBillingAddress.valid"
        (click)="continueWithZeroAmount(confirmBillingAddress.value)">
        Continue
      </button>
    </ng-container>
    <button mat-dialog-close class="btn begin_quiz mt-1 ml-2 button-change-scroll canceled_btn">
      Cancel
    </button>
  </div>
</div>