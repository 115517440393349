<kt-spinner></kt-spinner>
<mat-card class="full_screen_background_card_height">
  <table width="100%" border="0">
    <tr>
      <td width="89%">
        <h1 class="mb-0">My Guides</h1>
      </td>
      <td width="5%">
        <button
          class="btn begin_quiz cu_community_date"
          (click)="viewReport()"
        >
          View Report
        </button>
      </td>
      <td width="1%">&nbsp;</td>
      <td width="5%">
        <button
          class="btn begin_quiz cu_community_date"
          (click)="findAGuide()"
        >
          Find a Guide
        </button>
      </td>
    </tr>
  </table>

  <hr />

  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Current Guide">
      <ng-template matTabContent>
        <kt-current-my-guide></kt-current-my-guide>
      </ng-template>
    </mat-tab>
    <mat-tab label="Past Guide(s)">
      <ng-template matTabContent>
        <kt-past-my-guide></kt-past-my-guide>
      </ng-template>
    </mat-tab>
    <mat-tab label="Sent Requests">
      <ng-template matTabContent>
        <kt-sent-request></kt-sent-request>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-card>
