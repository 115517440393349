import { Router } from '@angular/router';
// Angular
import {
  Component,
  OnInit,
  DoCheck,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../core/_base/layout';
// Object-Path
import * as objectPath from 'object-path';
import { ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OverviewService } from '../../../service/overview.service';
import { MeaningNetworkService } from '../../../service/meaning-network.service';
import { DoNotShowPopUpComponent } from '../../pages/welcome/do-not-show-pop-up/do-not-show-pop-up.component';
import { SurveyService } from '../../../service/survey.service';
import { AuthService } from '../../../service/auth.service';
import { AccountsService } from './../../../service/accounts.service';

@Component({
  selector: 'kt-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit, DoCheck {
  @Output() sidebarEvent = new EventEmitter<string>();
  @ViewChild('meaning', { static: true }) input: ElementRef;
  @Input() show;

  submenu1 = false;
  submenu2 = false;
  submenu3 = false;
  submenu4 = false;
  submenu5 = false;
  submenu6 = false;
  submenu7 = false;
  submenu8 = false;
  layout: string;
  fluid;
  clear;
  shwLinkShadow = false;
  menu_selected: string;
  active_bar = false;
  param: number;
  name: string;
  parameter_value: string;

  name_map: string;
  parameter_value_map: string;
  name_guide: string;
  parameter_value_guide: string;
  name_mirror: string;
  parameter_value_mirror: string;
  name_lib: string;
  parameter_value_lib: string;
  name_net: string;
  parameter_value_net: string;
  name_cir: string;
  parameter_value_cir: string;
  name_com: string;
  parameter_value_com: string;
  name_diy: string;
  parameter_value_diy: string;
  name_explo: string;
  parameter_value_explo: string;
  name_por: string;
  parameter_value_por: string;

  memberconfigid: number;
  show_guide_overview: boolean;
  show_mirror_overview: boolean;
  show_mom_overview: boolean;
  show_circle_overview: boolean;
  show_network_overview: boolean;
  show_community_overview: boolean;
  show_constellation_overview: boolean;
  show_diy_overview: boolean;
  show_portrait_overview: boolean;
  show_explorer_overview: boolean;
  isLoggedInUser: boolean;
  check_overview: boolean;
  loggedInUser: number;
  param_id: number;

  adminIndicator: any;
  curatorIndicator: any;
  programDirectorIndicator: any;
  mentorIndicator: any;
  latestGivenSurveyID: number;
  diy_show = false;
  is_logged_in = false;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private _router: Router,
    private dialog: MatDialog,
    private overviewservice: OverviewService,
    private cdRef: ChangeDetectorRef,
    private meaning_network_service: MeaningNetworkService,
    private surveyService: SurveyService,
    private authService: AuthService,
    private accountService: AccountsService
  ) {
    this.is_logged_in = this.accountService.loggedIn;
  }

  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
    this.latestGivenSurveyID = Number(localStorage.getItem('latestGivenSurveyID'));
    const config = this.layoutConfigService.getConfig();
    this.layout = objectPath.get(config, 'subheader.layout');
    this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
    this.clear = objectPath.get(config, 'subheader.clear');
    if (this.is_logged_in) {
      this.toggle();
      this.getMap();
      this.getGuide();
      this.getMirror();
      this.getLibrary();
      this.getNetwork();
      this.getCircle();
      this.getCommunity();
      this.getDiy();
      this.getPortrait();
      this.getGuideExplorer();
      this.getLatestSurveyId();
      this.meaning_network_service.sidebarRefresh.subscribe((data) => {
        this.getDoNotPopUp(this.loggedInUser);
      });
      this.getDoNotPopUp(this.loggedInUser);
    }
  }

  ngDoCheck() {
    if (this.is_logged_in) {
      this.loggedInUser = this.accountService.muser;
      this.isLoggedInUser = true;
      this.adminIndicator = this.accountService.admin_indicator;
      this.curatorIndicator = this.accountService.curator_indicator;
      this.programDirectorIndicator = this.accountService.program_director_indicator;
      this.mentorIndicator = this.accountService.mentor_indicator;
      this.latestGivenSurveyID = Number(localStorage.getItem('latestGivenSurveyID'));
      this.cdRef.detectChanges();
    }
  }

  hasRoute(route: string) {
    return this._router.url.includes(route);
  }

  hasRouteActive(_route: []) {
    let count = 0;
    _route.forEach((path) => {
      if (this._router.url.includes(path)) {
        count++;
      }
    });

    if (count >= 1) {
      return true;
    } else {
      return false;
    }
  }

  toggle() {
    this.show = !this.show;
    if (this.show) {
      this.sidebarEvent.emit('expand');
    } else {
      this.sidebarEvent.emit('');
    }
  }
  toggle2() {
    this.show = true;
    this.sidebarEvent.emit('expand-hover');
  }
  toggle3() {
    this.show = false;
    this.sidebarEvent.emit('collapse');
  }
  getDoNotPopUp(member) {
    this.meaning_network_service.getDoNotPopUp(member).subscribe((data) => {
      this.show_circle_overview = Boolean(data.show_circle_overview);
      this.show_guide_overview = Boolean(data.show_guide_overview);
      this.show_diy_overview = Boolean(data.show_diy_overview);
      this.show_mirror_overview = Boolean(data.show_mirror_overview);
      this.show_mom_overview = Boolean(data.show_mom_overview);
      this.show_network_overview = Boolean(data.show_network_overview);
      this.show_community_overview = Boolean(data.show_community_overview);
      this.show_constellation_overview = Boolean(
        data.show_constellation_overview
      );
      this.show_portrait_overview = Boolean(data.show_portrait_overview);
      this.show_explorer_overview = Boolean(data.show_explorer_overview);
      this.cdRef.detectChanges();
    });
  }

  loadProfile() {
    this._router.navigate([
      'meaning-network',
      'my-profile'
    ]);
  }
  loadSetting() {
    this._router.navigate(['meaning-network', 'settings']);
  }

  manageAdmin() {
    this._router.navigate(['admin-page', 'member-list-with-search']);
  }
  manageCurator() {
    this._router.navigate(['curator-page', 'manage-constellation']);
  }
  manageProgramedirector() {
    this._router.navigate(['program-director-page', 'mentor-list-with-search']);
  }

  aboutus() {
    this._router.navigate(['meaningsphere', 'about']);
  }

  aboutSubmenu() {
    this.submenu1 = !this.submenu1;
    this.submenu2 = false;
    this.submenu3 = false;
    this.submenu4 = false;
    this.submenu5 = false;
    this.submenu6 = false;
    this.submenu7 = false;
    this.submenu8 = false;
  }
  discoverSubmenu() {
    this.submenu2 = !this.submenu2;
    this.submenu1 = false;
    this.submenu3 = false;
    this.submenu4 = false;
    this.submenu5 = false;
    this.submenu6 = false;
    this.submenu7 = false;
    this.submenu8 = false;
  }
  explorerSubmenu() {
    this.submenu3 = !this.submenu3;
    this.submenu1 = false;
    this.submenu2 = false;
    this.submenu4 = false;
    this.submenu5 = false;
    this.submenu6 = false;
    this.submenu7 = false;
    this.submenu8 = false;
  }
  collaborateSubmenu() {
    this.submenu4 = !this.submenu4;
    this.submenu1 = false;
    this.submenu2 = false;
    this.submenu3 = false;
    this.submenu5 = false;
    this.submenu6 = false;
    this.submenu7 = false;
    this.submenu8 = false;
  }
  adminSubmenu() {
    this.submenu5 = !this.submenu5;
    this.submenu1 = false;
    this.submenu2 = false;
    this.submenu4 = false;
    this.submenu3 = false;
    this.submenu6 = false;
    this.submenu7 = false;
    this.submenu8 = false;
  }
  curatorSubmenu() {
    this.submenu6 = !this.submenu6;
    this.submenu1 = false;
    this.submenu2 = false;
    this.submenu3 = false;
    this.submenu4 = false;
    this.submenu5 = false;
    this.submenu7 = false;
    this.submenu8 = false;
  }
  guideprogrameSubmenu() {
    this.submenu7 = !this.submenu7;
    this.submenu1 = false;
    this.submenu2 = false;
    this.submenu4 = false;
    this.submenu3 = false;
    this.submenu5 = false;
    this.submenu6 = false;
    this.submenu8 = false;
  }
  impactSubmenu() {
    this.submenu8 = !this.submenu8;
    this.submenu1 = false;
    this.submenu2 = false;
    this.submenu4 = false;
    this.submenu3 = false;
    this.submenu5 = false;
    this.submenu6 = false;
    this.submenu7 = false;
  }

  myGuide(param, name_guide, parameter_value_guide) {
    if (param === 100) {
      if (this.show_guide_overview === true) {
        this.dialog.open(DoNotShowPopUpComponent, {
          width: '45%',
          disableClose: true,
          data: {
            param: param,
            name: name_guide,
            parameter_value: parameter_value_guide,
          },
        });
      }
    }
    this._router.navigateByUrl('/discover/my-guides');
  }
  myDiy(param, name_diy, parameter_value_diy) {
    if (param === 124) {
      if (this.show_diy_overview === true) {
        this.dialog.open(DoNotShowPopUpComponent, {
          width: '45%',
          disableClose: true,
          data: {
            param: param,
            name: name_diy,
            parameter_value: parameter_value_diy,
          },
        });
      }
    }
    this._router.navigateByUrl('/meaningful-work-survey/diy-page/final-report-diy');
  }
  mirrorList(param, name_mirror, parameter_value_mirror) {
    if (param === 101) {
      if (this.show_mirror_overview === true) {
        this.dialog.open(DoNotShowPopUpComponent, {
          width: '45%',
          disableClose: true,
          data: {
            param: param,
            name: name_mirror,
            parameter_value: parameter_value_mirror,
          },
        });
      }
    }
    this._router.navigateByUrl('/meaning-mirror/mirror-list');
  }
  mapOfMeaning(param, name_map, parameter_value_map) {
    if (param === 102) {
      if (this.show_mom_overview === true) {
        this.dialog.open(DoNotShowPopUpComponent, {
          width: '45%',
          disableClose: true,
          data: {
            param: param,
            name: name_map,
            parameter_value: parameter_value_map,
          },
        });
      }
    }
    this._router.navigateByUrl('/meaningful-work-survey/my-surveys');
  }

  library(param, name_lib, parameter_value_lib) {
    if (param === 122) {
      if (this.show_constellation_overview === true) {
        this.dialog.open(DoNotShowPopUpComponent, {
          width: '45%',
          disableClose: true,
          data: {
            param: param,
            name: name_lib,
            parameter_value: parameter_value_lib,
          },
        });
      }
    }
    this._router.navigateByUrl('/library/all');
  }
  network(param, name_net, parameter_value_net) {
    if (param === 104) {
      if (this.show_network_overview === true) {
        this.dialog.open(DoNotShowPopUpComponent, {
          width: '45%',
          disableClose: true,
          data: {
            param: param,
            name: name_net,
            parameter_value: parameter_value_net,
          },
        });
      }
    }

    this._router.navigateByUrl('/meaning-network/my-feed');
  }
  circles(param, name_cir, parameter_value_cir) {
    if (param === 103) {
      if (this.show_circle_overview === true) {
        this.dialog.open(DoNotShowPopUpComponent, {
          width: '45%',
          disableClose: true,
          data: {
            param: param,
            name: name_cir,
            parameter_value: parameter_value_cir,
          },
        });
      }
    }
    this._router.navigateByUrl('/meaning-circle/my-circles');
  }
  community(param, name_com, parameter_value_com) {
    if (param === 105) {
      if (this.show_community_overview === true) {
        this.dialog.open(DoNotShowPopUpComponent, {
          width: '45%',
          disableClose: true,
          data: {
            param: param,
            name: name_com,
            parameter_value: parameter_value_com,
          },
        });
      }
    }
    this._router.navigateByUrl('/meaning-communities/my-community');
  }
  myPortrait(param, name_por, parameter_value_por) {
    if (param === 133) {
      if (this.show_portrait_overview === true) {
        this.dialog.open(DoNotShowPopUpComponent, {
          width: '45%',
          disableClose: true,
          data: {
            param: param,
            name: name_por,
            parameter_value: parameter_value_por,
          },
        });
      }
    }
    this._router.navigateByUrl(
      '/meaning-network/my-profile'
    );
  }
  myGuideExplorer(param, name_explo, parameter_value_explo) {
    if (param === 134) {
      if (this.show_explorer_overview === true) {
        this.dialog.open(DoNotShowPopUpComponent, {
          width: '45%',
          disableClose: true,
          data: {
            param: param,
            name: name_explo,
            parameter_value: parameter_value_explo,
          },
        });
      }
    }
    this._router.navigateByUrl('/discover/my-guided-explorers');
  }
  getMap() {
    this.overviewservice.getoverviewcontent(102).subscribe((data) => {
      this.name_map = data.name;
      this.parameter_value_map = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getLibrary() {
    this.overviewservice.getoverviewcontent(122).subscribe((data) => {
      this.name_lib = data.name;
      this.parameter_value_lib = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getGuide() {
    this.overviewservice.getoverviewcontent(100).subscribe((data) => {
      this.name_guide = data.name;
      this.parameter_value_guide = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getCircle() {
    this.overviewservice.getoverviewcontent(103).subscribe((data) => {
      this.name_cir = data.name;
      this.parameter_value_cir = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getNetwork() {
    this.overviewservice.getoverviewcontent(104).subscribe((data) => {
      this.name_net = data.name;
      this.parameter_value_net = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getCommunity() {
    this.overviewservice.getoverviewcontent(105).subscribe((data) => {
      this.name_com = data.name;
      this.parameter_value_com = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getMirror() {
    this.overviewservice.getoverviewcontent(101).subscribe((data) => {
      this.name_mirror = data.name;
      this.parameter_value_mirror = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getDiy() {
    this.overviewservice.getoverviewcontent(124).subscribe((data) => {
      this.name_diy = data.name;
      this.parameter_value_diy = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getPortrait() {
    this.overviewservice.getoverviewcontent(133).subscribe((data) => {
      this.name_por = data.name;
      this.parameter_value_por = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getGuideExplorer() {
    this.overviewservice.getoverviewcontent(134).subscribe((data) => {
      this.name_explo = data.name;
      this.parameter_value_explo = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }

  async getLatestSurveyId() {
    await this.surveyService
      .getLatestSurveyID(this.loggedInUser)
      .subscribe((data) => {
        this.latestGivenSurveyID = data.latestGivenSurveyID;
        localStorage.setItem('latestGivenSurveyID', String(this.latestGivenSurveyID));
        this.diy_show = true;
      });
  }
}
