<div class="search">
  <form [formGroup]="messageSearchForm" novalidate autocomplete="off">
    <div class="search-style2 pos-relative">
      <input
      type="textarea"
      #search_text
      formControlName="search_text"
      name="q"
      placeholder="Search"
      class="my_search_input mb-5 input-width-change"
      (keyup.enter)="searchMessage($event, 'e')"
      />
      <div class="clear icons-sets-new">
        <img
        src="/assets/media/icon/search.png"
        class="posi-dots fa-two"
        style="cursor: pointer"
        (click)="searchMessage(search_text.value, 'b')"
        />
        <img
        src="/assets/media/icon/Refresh@2xWhite.png"
        class="posi-dots-two"
        style="cursor: pointer"
        (click)="messageReset()"
        />
      </div>
    </div>
  </form>
</div>
<hr class="hr_line" style="top: 0px" />
<div class="listNotifications row">
  <ul class="scroll_class_list">
    <li id="notifications{{ 0 }}" class="receivedRequest">
      <div class="innerMessage" (click)="change_status_broadcast_message(); openMessage(0);">
        <div class="userPic">
          <img src="./assets/media/users/sphere.png" alt="" />
        </div>
        <div class="userInfo">
          <span class="name">Broadcast Messages <label *ngIf="broadcast_message_count>0">({{broadcast_message_count}})</label></span>
        </div>
      </div>
      <hr/>
    </li>
    <ng-container *ngIf="count > 0">
      <ng-container *ngFor="let message_data of allRelatingUserMessages">
        <li
          id="notifications{{ message_data.member_id }}"
          class="receivedRequest"
        >
          <div
            class="innerMessage"
            (click)="
              change_status(message_data.member_id);
              openMessage(message_data.member_id);
            "
          >
            <div class="userPic">
              <img
                src="{{
                  message_data.picture_path
                    ? message_data.picture_path
                    : './assets/media/users/default_rectangle.png'
                }}"
                alt=""
              />
            </div>
            <div class="userInfo">
              <span class="name" >{{ message_data.full_name }} {{message_data?.read_unread_count !=0? '('+message_data?.read_unread_count+')' :'' }}</span>
              <span class="feedTime m-0 fs-14" *ngIf="checkStatusMember(message_data.member_status)">(This Explorer is no longer active)</span>
            </div>
          </div>
        </li>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="count == 0">
      <table>
        <tbody>
          <tr>
            <td style="padding: 6px !important">
              <img
                src="./assets/media/no_data_found.png"
                style="border-radius: 0px"
              />
            </td>
            <td colspan="2" style="padding: 6px !important">
              No messages found!
            </td>          
          </tr>
        </tbody>
      </table>
    </ng-container>
  </ul>
</div>
