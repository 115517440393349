import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { Chart } from 'chart.js';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MentorService } from '../../../../service/mentor.service';
import * as moment from 'moment-timezone';
import { NotificationService } from '../../../../service/notification.service';
import { message_properties } from '../../../../../environments/message_properties';
import { AccountsService } from './../../../../service/accounts.service';
@Component({
  selector: 'kt-guide-reports',
  templateUrl: './guide-reports.component.html',
  styleUrls: ['./guide-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuideReportsComponent implements OnInit, OnDestroy {
  throttle = 300;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;
  monthNames = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  currentMonth = new Date().getMonth() + 1;
  pastSixMonthsArray: any[];
  guidesearchForm: UntypedFormGroup;

  minDate;
  disabled = true;
  maxDate = new Date();
  isFromDateNoted: boolean = false;
  reportData: any[];
  guidesExpdata = [];
  count: number;
  loggedInUser: number;
  indicator: number;
  hours_count: number = 0;
  sessions_count: number = 0;
  totalEvents: any[];
  include_checked = false;
  type_checked = false;
  status_checked = false;
  within_date_range = false;
  filter_name: string = '';
  filter_pic_path: string = '';
  filter_status: string = '';
  filter_type: string = '';
  filter_from: string = '';
  filter_to: string = '';
  event_val: number;
  status_val: number;
  allExplorers: any[];
  explorerer_count: number;
  select_val: string;
  type_val: string;
  profile_id: number;
  page: number = 2;
  search_button = false;
  discussionSubscription: Subscription;
  presentPastSubscription: Subscription;
  searchSubscription: Subscription;
  downloadSubscription: Subscription;
  constructor(
    private _cdref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private elementRef: ElementRef,
    private spinner: NgxSpinnerService,
    private mentor_service: MentorService,
    private toastr: NotificationService,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;

    this.guidesearchForm = this.formBuilder.group({
      include_checked: new UntypedFormControl(false),
      type_checked: new UntypedFormControl(false),
      include_option: new UntypedFormControl(''),
      status_checked: new UntypedFormControl(false),
      status_option: new UntypedFormControl(''),
      discussion_type: new UntypedFormControl(''),
      within_date_range: new UntypedFormControl(false),
      date_from: new UntypedFormControl(''),
      date_to: new UntypedFormControl(''),
      logged_in_user: new UntypedFormControl(this.loggedInUser),
      indicator: new UntypedFormControl(1),
      registration_type: new UntypedFormControl(2681),
      search_button: new UntypedFormControl(false), // Initialization stage is true if click filter become false
      page: 1,
    });

    this.getDiscussionSummaryDetails(this.loggedInUser, 1);
    this.getPastPresentExplorers();
    this.GuideSearchFilterForm(1);
  }
  pushValue(ev?) {
    if (this.count !== this.guidesExpdata.length) {
      this.guidesearchForm.patchValue({
        page: this.page,
      });
      this.GuideSearchFilterForm();
      this.page++;
    }
  }

  onChange(deviceValue) {
    this.GuideSearchFilterForm();
  }
  changeFn(val) {
    this.GuideSearchFilterForm();
  }
  onChangeFrom(event) {
    this.minDate = event.value;
    this.guidesearchForm.patchValue({
      date_to: this.minDate,
    });
    this._cdref.detectChanges();
  }

  getSixMonths() {
    var j = 0;
    var pastSixMonthsArray = [];
    var k = this.currentMonth - 1;
    for (var i = 0; i < 6; i++) {
      pastSixMonthsArray[j] = this.monthNames[k];
      if (k === 0) {
        k = 11;
      } else {
        k--;
      }
      j++;
    }
    return pastSixMonthsArray.reverse();
  }
  getDiscussionSummaryDetails(member, indicator) {
    this.pastSixMonthsArray = this.getSixMonths();
    this.discussionSubscription = this.mentor_service
      .getLoggedInMemberDiscussionSummaryDataReport(
        this.loggedInUser,
        indicator
      )
      .subscribe((data) => {
        this.totalEvents = [
          data.results['pastSixMonthDiscussion']['pastSixMonthTotalEvent'],
          data.results['pastSixMonthDiscussion']['pastFiveMonthTotalEvent'],
          data.results['pastSixMonthDiscussion']['pastFourMonthTotalEvent'],
          data.results['pastSixMonthDiscussion']['pastThreeMonthTotalEvent'],
          data.results['pastSixMonthDiscussion']['pastTwoMonthTotalEvent'],
          data.results['pastSixMonthDiscussion']['pastOneMonthTotalEvent'],
        ];
        this._cdref.detectChanges();

        let htmlRef1 = this.elementRef.nativeElement.querySelector(`#barchart`);
        var myChart = new Chart(htmlRef1, {
          scaleSteps: 10,
          scaleOverride: true,
          type: 'bar',
          data: {
            labels: this.pastSixMonthsArray,

            datasets: [
              {
                data: this.totalEvents,
                backgroundColor: [
                  '#CBC3E3',
                  '#C3B1E1',
                  '#CCCCFF',
                  '#E0B0FF',
                  '#BDB5D5',
                  '#CF9FFF',
                ],
                borderWidth: 0,
              },
            ],
          },
          options: {
            title: {
              display: true,
              text: 'Completed Discussion by Month',
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    fontColor: 'black',
                    min: 0,
                    stepSize: 10,
                  },
                  gridLines: {
                    display: false,
                  },
                  scaleLabel: {
                    display: true,
                    fontColor: 'black',
                    fontSize: 20,
                    //   labelString:
                    //     '*This chart diplay both Completed and Closed discussion',
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    // max: 5,
                    min: 0,
                    fontColor: 'black',
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
            legend: {
              display: false,
            },
          },
        });

        this._cdref.detectChanges();
      });
  }
  onChangeTo(event) {
    if (this.guidesearchForm.value.date_from === '') {
      this.guidesearchForm.patchValue({
        date_from: '2020-01-01',
        // date_from: this.maxDate,
      });

      if (
        this.guidesearchForm.value.date_from >
        this.guidesearchForm.value.date_to
      ) {
        this.guidesearchForm.patchValue({
          // date_from: '2020-01-01',
          date_from: new Date(
            // '2020-01-01'
            new Date(this.guidesearchForm.value.date_to).setDate(
              new Date(this.guidesearchForm.value.date_to).getDate() - 1
            )
          ),
        });
      }
    }
    if (
      this.guidesearchForm.value.date_from > this.guidesearchForm.value.date_to
    ) {
      this.guidesearchForm.patchValue({
        date_from: new Date(
          new Date(this.guidesearchForm.value.date_to).setDate(
            new Date(this.guidesearchForm.value.date_to).getDate() - 1
          )
        ),
      });
    }
  }
  returnImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }
  getPastPresentExplorers() {
    this.presentPastSubscription = this.mentor_service
      .getAllExplorer(this.loggedInUser)
      .subscribe((data) => {
        this.allExplorers = data.results;
        this.explorerer_count = data.count;
        this._cdref.detectChanges();
      });
  }

  GuideSearchFilterForm(params = 0) {
    if (params === 1) {
      this.page = 2;
      this.guidesearchForm.patchValue({
        page: 1,
      });
      this.guidesExpdata = [];
    }
    let Formvalue = this.guidesearchForm.value;
    this.include_checked = Formvalue['include_checked'];
    this.type_checked = Formvalue['type_checked'];
    this.status_checked = Formvalue['status_checked'];
    this.within_date_range = Formvalue['within_date_range'];
    let full_start_date = Formvalue['date_from']
      ? moment(Formvalue['date_from'], 'YYYY-MM-DD').format('YYYY-MM-DD')
      : '';
    let full_end_date = Formvalue['date_to']
      ? moment(Formvalue['date_to'], 'YYYY-MM-DD').format('YYYY-MM-DD')
      : '';
    this.guidesearchForm.patchValue({
      date_from: full_start_date,
      date_to: full_end_date,
    });
    this.filter_from = full_start_date;
    this.filter_to = full_end_date;
    if (this.status_checked) {
      this.select_val = Formvalue['status_option'];
      this.statusValue(this.select_val);
    } else {
      this.guidesearchForm.patchValue({ status_option: '' });
    }
    if (this.type_checked) {
      this.type_val = Formvalue['discussion_type'];
      this.discussionType(this.type_val);
    } else {
      this.guidesearchForm.patchValue({ discussion_type: '' });
    }
    if (this.include_checked) {
      this.profile_id = Formvalue['include_option'];
      let member_id = +this.profile_id;
      this.userProfileData(member_id);
    } else {
      this.guidesearchForm.patchValue({ include_option: '' });
    }
    if (!this.within_date_range) {
      this.guidesearchForm.patchValue({ date_from: '', date_to: '' });
    }
    if (
      this.include_checked ||
      this.type_checked ||
      this.status_checked ||
      this.within_date_range
    ) {
      this.guidesearchForm.patchValue({
        search_button: true,
      });
    }
    const Formdata = this.guidesearchForm.value;
    this.spinner.show();
    this.searchSubscription = this.mentor_service
      .allGuideExplorerEventData(Formdata)
      .subscribe((data) => {
        this.guidesExpdata = [
          ...new Map(
            this.guidesExpdata
              .concat(data.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.count = data.count;
        this.spinner.hide();
        this._cdref.detectChanges();
      });
  }

  eventType(event) {
    if (event === 2550) {
      return 'General';
    } else if (event === 2554) {
      return 'Meaningful Work Survey';
    } else if (event === 2556) {
      return 'Mirror Reflection';
    } else if (event === 2548) {
      return 'Get Acquainted';
    } else if (event === 2733) {
      return 'Complimentary';
    } else {
      return 'DIY';
    }
  }
  statusValue(id_val) {
    if (id_val === '0') {
      this.filter_status = 'Requested';
    } else if (id_val === '1') {
      this.filter_status = 'Published';
    } else if (id_val === '2') {
      this.filter_status = 'Canceled';
    } else if (id_val === '3') {
      this.filter_status = 'Scheduled';
    } else if (id_val === '4') {
      this.filter_status = 'Auto Canceled';
    } else if (id_val === '5') {
      this.filter_status = 'Completed';
    } else if (id_val === '6') {
      this.filter_status = 'Closed';
    } else if (id_val === '7') {
      this.filter_status = 'Auto completed';
    } else if (id_val === '8') {
      this.filter_status = 'Withdrawn';
    } else if (id_val === '9') {
      this.filter_status = 'Declined';
    } else if (id_val === '10') {
      this.filter_status = 'Requested for cancelation';
    } else if (id_val === '11') {
      this.filter_status = 'Canceled due to request';
    } else if (id_val === '12') {
      this.filter_status = 'Auto Withdraw';
    }
    this._cdref.detectChanges();
    this.spinner.hide();
  }
  discussionType(val_id) {
    if (val_id === '2733') {
      this.filter_type = 'Complimentary';
    } else if (val_id === '2548') {
      this.filter_type = 'Get Acquainted';
    } else if (val_id === '2550') {
      this.filter_type = 'General';
    } else if (val_id === '2554') {
      this.filter_type = 'Meaningful Work Survey';
    } else if (val_id === '2556') {
      this.filter_type = 'Mirror Reflection';
    }
    this._cdref.detectChanges();
    this.spinner.hide();
  }
  userProfileData(member_id) {
    for (let obj in this.allExplorers) {
      let item = this.allExplorers[obj];
      if (member_id === item.id) {
        this.filter_name = item?.full_name;
        if (this.filter_pic_path === null) {
          this.filter_pic_path = './assets/media/users/default.png';
        }
        this._cdref.detectChanges();
      }
    }
  }
  patchFormSearchButton() {
    this.guidesearchForm.patchValue({
      search_button: true,
    });
  }
  refreshFullList() {
    this.resetForm();
    this.GuideSearchFilterForm(1);
  }
  resetForm() {
    this.guidesearchForm.patchValue({
      page: 1,
      include_checked: false,
      include_option: '',
      type_checked: false,
      discussion_type: '',
      status_checked: false,
      status_option: '',
      within_date_range: false,
      date_from: '',
      date_to: '',
      logged_in_user: this.loggedInUser,
      indicator: 1,
      registration_type: 2681,
      search_button: false,
    });
  }
  downloadReportCSV() {
    const sendingData = {
      indicator: 1,
      registration_type: 2681,
      logged_in_user: this.loggedInUser,
    };
    this.spinner.show();
    this.downloadSubscription = this.mentor_service
      .downloadDiscussionReport(sendingData)
      .subscribe((data) => {
        if (data.status === 200) {
          window.location.href = data.download_url;
          this.toastr.showSuccess(
            message_properties.DOWNLOAD_REPORT_SUCCESS,
            ''
          );
        } else {
          this.toastr.showError(data.message, '');
        }
        this._cdref.detectChanges();
        this.spinner.hide();
      });
  }
  ngOnDestroy() {
    if (this.discussionSubscription) {
      this.discussionSubscription.unsubscribe();
    }
    if (this.presentPastSubscription) {
      this.presentPastSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe();
    }
  }
}
