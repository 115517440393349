<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1>
          Request {{ fullName }} to be My Guide
        </h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <ng-container>
    <form
      [formGroup]="sendRequestForm"
      (ngSubmit)="sendRequest(sendRequestForm)"
      novalidate
      autocomplete="off"
    >
      <div class="">
        <div class="row">
          <div class="col-12">
            <p class="dis-prof i-pad-sect-upload">
              Requesting: &nbsp;
              <img
                class="profile_img"
                [src]="picPath"
              />
              &nbsp;&nbsp;{{ fullName }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field style="width: 100%; color: #ffffff;">
              <mat-label>Personalize your message </mat-label>
              <textarea
                matInput
                formControlName="description"
                name=""
                id=""
                cols="30"
                rows="10"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.()@!? ]|^ /g,'')"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <hr/>
      <div class="row pt-5">
        <div class="col-sm-12 tr">
          <button class="btn begin_quiz ml-2" type="submit" [disabled]="!sendRequestForm.valid">Send</button>

          <button
            mat-dialog-close
            class="btn begin_quiz ml-2 canceled_btn"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</div>
