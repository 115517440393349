<kt-spinner></kt-spinner>
<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1 class="cmn_title_top">{{title}}</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form [formGroup]="flagForm" (ngSubmit)="onSubmit(flagForm.value)" novalidate>
    <div class="multi-collapse" id="multiCollapseExample5">
      <div class="">
        <div class="row">
          <div class="col-md-4">
            <div class="memg-btn-go">
              <mat-checkbox
                formControlName="inappropriate_content_indicator"
                [value]="1"
                (change)="flagResign($event)"
              >
                Inappropriate Content
              </mat-checkbox>
            </div>
            <div class="memg-btn-go">
              <mat-checkbox
                formControlName="unrelated_content_indicator"
                [value]="3"
                (change)="flagResign($event)"
              >
                Irrelevant content
              </mat-checkbox>
            </div>
            <div class="memg-btn-go">
              <mat-checkbox
                formControlName="other_indicator"
                [value]="4"
                (change)="flagResign($event)"
              >
                Other
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field style="width: 100%">
              <mat-label>Remarks</mat-label>
              <textarea
                matInput
                formControlName="remarks"
                [readonly]="flag_data_id == 0 ? 'false' : 'true'"
                rows="8"
                autocomplete="off"
                maxlength="240"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.\-_+()@!?#$% ]|^ /g,'')"
              ></textarea>
            </mat-form-field>
          </div>
        </div>

        <hr />
        <div class="row">
          <div class="col-md-12">
            <table width="100%" align="right" border="0" class="mt-5">
              <tr>
                <td *ngIf="flag_data_id != 0" valign="center">
                  <p>{{ action_date_time | date: "MMM d, y, h:mm a" }}</p>
                </td>
                <td align="right">
                  <table>
                    <tr>
                      <td *ngIf="flag_data_id != 0 && (flag_from=='meaning_central_post' || flag_from=='meaning_central_comment')">
                        <a
                          class="btn begin_quiz my_btn"
                          (click)="unflagPostComment(flag_data_id, commentId)"
                        >
                          UnFlag
                        </a>
                      </td>
                      <td *ngIf="flag_data_id == 0">
                        <button
                        class="btn begin_quiz my_btn"
                        [disabled]="!flagForm.valid"
                      >
                        Report
                      </button>
                      </td>
                      <td>
                        <button
                        mat-dialog-close
                        class="btn begin_quiz ml-2 canceled_btn"
                      >
                        Cancel
                      </button>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
