import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommunitiesService {
  apiUrl = environment.apiUrl;

  private _refreshAfterJoin = new Subject<CommunityAndCircleData>();

  get refreshAfterJoin() {
    return this._refreshAfterJoin;
  }

  constructor(private http: HttpClient) {}

  getMostPopularCommunityDetail(
    groupFor,
    loggedInUser,
    limitNo
  ): Observable<CommunityAndCircleData> {
    return this.http.get<CommunityAndCircleData>(
      this.apiUrl +
        'group/circle-and-community/most-popular-community/' +
        groupFor +
        '/' +
        loggedInUser +
        '/' +
        limitNo
    );
  }

  checkLoggedInUserPresentInThisGroup(
    loggedInUserId,
    groupId
  ): Observable<CommunityAndCircleData> {
    return this.http.get<CommunityAndCircleData>(
      this.apiUrl +
        'group/circle-and-community/check-loggedin-user-present-in-this-group/' +
        loggedInUserId +
        '/' +
        groupId
    );
  }

  getMeaningCommunityDetail(
    groupFor,
    loggedInUserId,
    param
  ): Observable<CommunityAndCircleData> {
    return this.http.get<CommunityAndCircleData>(
      this.apiUrl +
        'group/circle-and-community/community-circle-list/' +
        groupFor +
        '/' +
        loggedInUserId +
        '?q=' +
        param
    );
  }

  getMeaningCommunityDialogDetail(
    groupId,
    userId,
    map_for,
    mapped_object_type
  ): Observable<CommunityAndCircleData> {
    return this.http.get<CommunityAndCircleData>(
      this.apiUrl +
        `group/circle-and-community/community-details/${groupId}/${userId}/${map_for}/${mapped_object_type}`
    );
  }

  joinGroup(dataObject) {
    return this.http
      .post(
        this.apiUrl + 'group/join-circle-and-community/join-group',
        dataObject
      )
      .pipe(
        tap(() => {
          this._refreshAfterJoin.next();
        })
      );
  }

  leaveGroup(groupId, userId, dataObject) {
    return this.http
      .put(
        this.apiUrl +
          'group/circle-and-community/leave-community/' +
          groupId +
          '/' +
          userId,
        dataObject
      )
      .pipe(
        tap(() => {
          this._refreshAfterJoin.next();
        })
      );
  }
  getMeaningCommunityDetailByPost(ngForm): Observable<CommunityAndCircleData> {
    return this.http.post<CommunityAndCircleData>(
      this.apiUrl + 'group/circle-and-community/community-circle-list-post',
      ngForm
    );
  }
  getSingleGroupPopupDetails(sendData): Observable<CommunityAndCircleData> {
    return this.http.post<CommunityAndCircleData>(
      this.apiUrl +`group/circle-and-community/single-group-details-popup`, sendData
    );
  }
}

export interface CommunityAndCircleData {
  visibility_type: number;
  group_for: number;
  group_object_id: number;
  auto_created_indicator: number;
  total_participants: number;
  company_id: number;
  creation_date: string;
  name: string;
  description: string;
  charter: string;
  context: string;
  image_path: string;
  creating_member: number;
  creatingMember: {};
  addressed_by: string;
  addressed_on: string;
  remarks: string;
  group_rating: number;
  isGroupMemberIndicator: number;
  EventDialogWithGroupData: {};
  communityTopics: any;
  CommunityMembersDetails: {};
  upComingDialogs: {};
  monthActive: string;
  CommunityMemberCount: number;
  CommunityPostCounts: number;
  MyNetworkConnectedMemberCount: number;
  status: number;
  results: [];
  count: number;
  my_rating: {};
  group_role: number;
  is_this_user_is_host:string;
}
