<kt-spinner></kt-spinner>
<div class="">
  <table width="100%">
    <tr>
      <td>
        <h1 class="cmn_title_top only_two_line_show fs-28" title="{{ title }}">
          {{ title }}
        </h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <mat-dialog-content>
    <div class="front_card_background_clr scroll_class_list-pop">
      <div class="">
        <div class="row" *ngIf="additional_info !== null">
          <div class="col-sm-12">
            <p class="word_break" [innerHTML]="sanitizer.bypassSecurityTrustHtml(additional_info)">
            </p>
          </div>
        </div>
        <div class="feedcontent feedMedia text-align-left">
          <ng-container *ngIf="video_indicator == 1 && attachment_path !=''">
            <img [src]="sanitizer.bypassSecurityTrustResourceUrl(attachment_path)" alt="" />
          </ng-container>
        </div>

        <div class="row">
          <div class="col-md-6">
            <p class="popup-icon">
              <mat-icon *ngIf="clickFrom == 2564 && member_in_bucket == loggedInUserId" title="Edit" (click)="
                    updateCollabContent(
                      title,
                      description,
                      attachment_path,
                      referenceUrl,
                      collab_content_category
                    )
                  " style="cursor: pointer">edit</mat-icon>
              <mat-icon *ngIf="clickFrom == 2564 && member_in_bucket == loggedInUserId" title="Delete" (click)="
                    deleteCollabContentBucket(collabContentId, bucket_type)
                  " style="cursor: pointer">delete</mat-icon>
              <mat-icon *ngIf="clickFrom == 2552 && member_in_bucket == loggedInUserId" title="Delete" (click)="deleteTreasure(collabContentId, 2552)" style="cursor: pointer">delete</mat-icon>
              <mat-icon *ngIf="clickFrom == 2734 && member_in_bucket == loggedInUserId" title="Delete" (click)="deleteBookmark(collabContentId, 2734)" style="cursor: pointer">delete</mat-icon>
              <a class="pl-20" *ngIf="referenceUrl !== ''" style="cursor: pointer"
                (click)="basicPopup(referenceUrl)"><u>View Source</u>&nbsp;&nbsp;<i
                  class="fas fa-external-link-alt"></i></a>
            </p>
          </div>

          <div class="col-md-4">
            <div class="star-rating star-rating-collabs">
              <img *ngIf="references_id !== 0" src="./assets/media/icon/Details@2x.png" width="15px" height="15px"
                style="cursor: pointer" title="View in Library" (click)="moveToParentPost(references_id)" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="feedcontent word_break">
              <span [innerHTML]="sanitizer.bypassSecurityTrustHtml(description)"></span>
            </div>
          </div>
        </div>

        <div class="feedOptions" *ngIf="popup_call_from != '0'">
          <!-- <ul>
          <li>
            <a
              (click)="addToMyBookmarks(collabContentId)"
              style="cursor: pointer"
              ><span class="icon"
                ><img src="./assets/my-feeds/feed-add-to-treasure.png" alt=""
              /></span>
              Add to My Bookmarks</a
            >
          </li>
          <li>
            <a
              (click)="addToMyTreasures(collabContentId)"
              style="cursor: pointer"
              ><span class="icon"
                ><img src="./assets/my-feeds/feed-add-to-treasure.png" alt=""
              /></span>
              Add to My Treasures</a
            >
          </li>
        </ul> -->
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>