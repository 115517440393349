<!-- <ngx-spinner > <p style="color: white" > Please Wati... </p></ngx-spinner> -->
<ngx-spinner bdColor="transparent" size="large" color="#f57116">
	<p class="colorpw">Please Wait...</p>
</ngx-spinner>
<mat-card class="example-card">
	<table class="w-100">
		<tr>
			<td align="left"><h1 class="card_title">Survey List</h1></td>
			<td align="right">
				<button
					(click)="add_new_survey()"
					type="button"
					class="btn begin_quiz"
				>
					Add New
				</button>
			</td>
		</tr>
	</table>

	<hr />
	<mat-card-content>
		<table class="table">
			<thead>
				<tr>
					<td>NAME</td>
					<td>AVG RATING</td>
					<td>Action</td>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let survey of surveyData">
					<td>{{ survey.name }}</td>
					<td>{{ survey.avg_time_to_complete }}</td>
					<td>
						<a class="ml-2"><i class="fa fa-eye"></i></a>
						<a class="ml-2"><i class="fa fa-edit"></i></a>
						<a class="ml-2"><i class="fa fa-trash"></i></a>
					</td>
				</tr>
			</tbody>
		</table>
	</mat-card-content>
</mat-card>
