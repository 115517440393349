<div class="mat-dialog-popup">
  <table class="w-100">
    <tr>
      <td>
        <h1 class="popup_card_title">{{ title }}</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <div class="row">
    <div class="col-sm-12 pos-relative search-style1">
      <input type="textarea" class="my_search_input mb-5 input-width-change" placeholder="Search"
        (change)="filterData($event)" />
      <div class="clear icons-sets-new">
        <i class="fa fa-search posi-dots fa-two" aria-hidden="true"></i>
      </div>
    </div>
  </div>
  <div class="scroll-class-list" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    [scrollWindow]="false" (scrolled)="updateScrollDown($event)">
    <form [formGroup]="hostsForm">
      <div class="">
        <div class="row">
          <div class="col-sm-12 hostAlllist set-message">

            <ng-container *ngIf="count>0">
              <ng-container formArrayName="hosts" *ngIf="mode == 'multiple-selection'">
                <div *ngFor="let host of hostData; let i = index" class="hostlist p-1">
                  <mat-checkbox [checked]="checkhosts(host.member_table[0].id)" (change)="onChange($event, host)" [value]="host['member_table'][0]['id']">
                    <ng-container *ngIf="type == 'messageto'">
                      <img src="{{
                      host.member_table[0].picture_path
                        ? host.member_table[0].picture_path
                        : './assets/media/users/default.jpg'
                    }}" class="img2rem br-50" />&nbsp;&nbsp;
                      {{ host.member_table[0].full_name }}
                    </ng-container>
                    <ng-container *ngIf="type != 'messageto'">
                      <img src="{{
                      host.image
                        ? host.image
                        : './assets/media/users/default.jpg'
                    }}" class="img2rem br-50" />&nbsp;&nbsp;
                      {{ host.text }}
                    </ng-container>

                  </mat-checkbox>
                </div>
              </ng-container>
              <ng-container formArrayName="hosts" *ngIf="mode == 'single-selection'">
                <div *ngFor="let host of hostData; let i = index" class="hostlist p-1">
                  <mat-radio-group name="hosts" (change)="onChange($event, host)">
                    <mat-radio-button [checked]="checkhosts(host.member_table[0].id)" [value]="host['member_table'][0]['id']">
                      <ng-container *ngIf="type == 'messageto'">
                        <img src="{{
                        host.member_table[0].picture_path
                          ? host.member_table[0].picture_path
                          : './assets/media/users/default.jpg'
                      }}" class="img2rem br-50" />&nbsp;&nbsp;
                        {{ host.member_table[0].full_name }}
                      </ng-container>
                      <ng-container *ngIf="type != 'messageto'">
                        <img src="{{
                        host.member_table[0].picture_path
                          ? host.member_table[0].picture_path
                          : './assets/media/users/default.jpg'
                      }}" class="img2rem br-50" />&nbsp;&nbsp;
                        {{ host.member_table[0].full_name }}
                      </ng-container>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="count==0">
              No {{ title }} found!
            </ng-container>
          </div>
          <hr />
        </div>
      </div>
    </form>
  </div>
  <hr />
  <div class="row">
    <div class="col-sm-12 tr">
      <button class="btn begin_quiz ml-2" (click)="sendData(hostsForm.value)" mat-dialog-close>
        Select
      </button>

      <button class="btn begin_quiz ml-2 button-change-scroll canceled_btn" mat-dialog-close>
        Cancel
      </button>
    </div>
  </div>
</div>