<div class="margin-minus">
  <div mat-dialog-title class="tr">
    <button mat-button mat-dialog-close class="modal_close_button_top">X</button>
  </div>

  <div class="mb-1">
    <iframe
      class="iframe_video"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      type="text/html"
      [src]="source"
      allow="autoplay; fullscreen" 
      allowfullscreen
    ></iframe>
  </div>
</div>
