<kt-spinner></kt-spinner>
<mat-card
  class="bg-color-transp back_card_background_clr archive_main_card-marg full_screen_background_card_height"
>
  <table width="100%">
    <tr>
      <td align="left">
        <h1 class="m-0">GUIDE REPORTS</h1>
      </td>
      <td align="right">
        <a
          class="btn begin_quiz cu_community_date"
          [routerLink]="'/discover/my-guides'"
        >
          My Guide
        </a>
      </td>
    </tr>
  </table>
  <hr />
  <mat-card-content>
    <div class="community-box">
      <div class="d-flex justify-content-between row">
        <div class="d-flex flex-direction-column pr-30 col-sm-12 col-lg-3">
          <h2>Available Balance</h2>
          <div class="inlin d-flex justify-content-between mt-10">
            <strong>General Discussion (Minutes)</strong>
            <strong class="fs-20 bg-small">{{
              available_general_discussions_time
            }}</strong>
          </div>
          <div class="inlin d-flex justify-content-between mt-10">
            <strong>Mirror Reflection Discussion(s)</strong>
            <strong class="fs-20 bg-small">{{
              available_guided_reflection_discussions
            }}</strong>
          </div>
          <div class="inlin d-flex justify-content-between mt-10">
            <strong>Meaningful Work Survey Discussion(s)</strong>
            <strong class="fs-20 bg-small">{{
              available_guided_mom_discussion
            }}</strong>
          </div>
        </div>
        <div
          class="col-sm-12 col-lg-9 d-flex justify-content-between align-items-center h40vh"
        >
          <!-- <h3 class="text-align-left w-100">Complete Discussion by Month</h3> -->
          <canvas id="barchart" height="80"></canvas>
        </div>
        <hr />
      </div>
      <p class="text-align-right">
        *This chart represents both Completed and Closed discussion
      </p>

      <hr class="hr-5" />
      <div class="d-flex justify-content-between mt-20">
        <h2>Discussion Details</h2>
        <div>
          <a class="btn begin_quiz " (click)="downloadReportCSV()">Download</a>&nbsp;
          <i
            class="fa fa-filter posi-dots fa-one"
            aria-hidden="true"
            title="Filter"
            [matMenuTriggerFor]="menu_search_form"
          ></i>
          <mat-menu
            #menu_search_form="matMenu"
            class="search-menunw guide-reports"
          >
            <form
              [formGroup]="guidesearchForm"
              (keydown.tab)="$event.stopPropagation()"
              (ngSubmit)="GuideSearchFilterForm(1)"
            >
              <div class="mat-menu-search" (click)="$event.stopPropagation()">
                <!-- Guide -->
                <div class="row">
                  <div class="col-sm-6">
                    <div class="memg-btn-go">
                      <mat-checkbox
                        class="mem-radio-btn"
                        (click)="$event.stopPropagation()"
                        formControlName="include_checked"
                        >Guide
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <select
                      class="memb-select"
                      (click)="$event.stopPropagation()"
                      formControlName="include_option"
                      [attr.disabled]="
                        !guidesearchForm.get('include_checked').value
                          ? disabled
                          : null
                      "
                    >
                      <option
                        disabled
                        selected
                        value
                        style="display: none"
                      ></option>
                      <option
                        [value]="guide?.id"
                        *ngFor="let guide of allGuides"
                      >
                        {{ guide?.full_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- Status -->
                <div class="row">
                  <div class="col-sm-6">
                    <div class="memg-btn-go">
                      <mat-checkbox
                        class="mem-radio-btn"
                        (click)="$event.stopPropagation()"
                        formControlName="status_checked"
                        >Discussion Status
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <select
                      class="memb-select"
                      (click)="$event.stopPropagation()"
                      formControlName="status_option"
                      [attr.disabled]="
                        !guidesearchForm.get('status_checked').value
                          ? disabled
                          : null
                      "
                    >
                      <option
                        disabled
                        selected
                        value
                        style="display: none"
                      ></option>
                      <option value="0">Requested</option>
                      <!-- <option value="1">Published</option> -->
                      <option value="2">Canceled</option>
                      <option value="3">Scheduled</option>
                      <option value="4">Auto Canceled</option>
                      <option value="5">Completed</option>
                      <option value="6">Closed</option>
                      <option value="7">Auto Completed</option>
                      <option value="8">Withdrawn</option>
                      <option value="9">Declined</option>
                      <option value="10">Requested for cancelation</option>
                      <option value="11">Canceled due to request</option>
                      <option value="12">Auto Withdraw</option>
                    </select>
                  </div>
                </div>
                <!-- Discussion Type -->
                <div class="row">
                  <div class="col-sm-6">
                    <div class="memg-btn-go">
                      <mat-checkbox
                        class="mem-radio-btn"
                        (click)="$event.stopPropagation()"
                        formControlName="type_checked"
                        >Discussion Type
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <select
                      class="memb-select"
                      (click)="$event.stopPropagation()"
                      formControlName="discussion_type"
                      [attr.disabled]="
                        !guidesearchForm.get('type_checked').value
                          ? disabled
                          : null
                      "
                    >
                      <option
                        disabled
                        selected
                        value
                        style="display: none"
                      ></option>
                      <option value="2733">Complimentary</option>
                      <option value="2548">Get Acquainted</option>
                      <option value="2550">General</option>
                      <option value="2554">Meaningful Work Survey</option>
                      <option value="2556">Mirror Reflection</option>
                    </select>
                  </div>
                </div>
                <!-- From To -->
                <div class="memg-btn-go mbn075">
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    formControlName="within_date_range"
                  >
                    Discussion held between
                  </mat-checkbox>
                </div>
                <div>
                  <mat-form-field
                    style="width: 50%; color: white"
                    class="new-div-inpt"
                  >
                    <input
                      matInput
                      [matDatepicker]="start_date"
                      formControlName="date_from"
                      placeholder="From"
                      (dateChange)="onChangeFrom($event)"
                      (click)="$event.stopPropagation()"
                      readonly
                      [disabled]="
                        !guidesearchForm.get('within_date_range').value
                      "
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="start_date"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #start_date></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field
                    style="width: 50%; color: white"
                    class="new-div-inpt-rht"
                  >
                    <input
                      matInput
                      [min]="minDate"
                      [matDatepicker]="end_date"
                      formControlName="date_to"
                      placeholder="To"
                      (dateChange)="onChangeTo($event)"
                      (click)="$event.stopPropagation()"
                      readonly
                      [disabled]="
                        !guidesearchForm.get('within_date_range').value
                      "
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="end_date"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #end_date></mat-datepicker>
                  </mat-form-field>
                </div>
                <div align="right" class="memg-btn-go">
                  <table>
                    <tr style="margin-top: 5px; display: block">
                      <td>
                        <img
                          src="/assets/media/icon/Refresh@2xWhite.png"
                          width="25px"
                          height="25px"
                          style="cursor: pointer"
                          (click)="refreshFullList()"
                        />
                      </td>
                      <td>
                        <button class="btn begin_quiz">GO</button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </form>
          </mat-menu>
        </div>
      </div>
      <!-- This represents the discussion details -->
      <div class="d-flex justify-content-between mt-5">
        <div *ngIf="!include_checked"><strong>With Guide: </strong> All</div>
        <div *ngIf="include_checked">
          <strong>With Guide: </strong
          ><span class="innerData"
            >{{ filter_name }}
          </span>
        </div>
        <div *ngIf="!status_checked"><strong>Status: </strong> All</div>
        <div *ngIf="status_checked">
          <strong>Status: </strong>{{ filter_status }}
        </div>

        <div *ngIf="!type_checked"><strong>Discussion Type: </strong>All</div>
        <div *ngIf="type_checked">
          <strong>Discussion Type: </strong>{{ filter_type }}
        </div>

        <div *ngIf="!within_date_range">
          <strong>Discussion Held Between: </strong> All
        </div>
        <div *ngIf="within_date_range">
          <strong>Discussion Held Between: </strong>
          {{ filter_from | date: "MMM d, y" }} -
          {{ filter_to | date: "MMM d, y" }}
        </div>
      </div>

      <!-- <cdk-virtual-scroll-viewport class="scroll_class_list scroll-gap"> -->
      <table class="table headfixed">
        <thead>
          <tr>
            <td>Date</td>
            <td class="w-20">Guide</td>
            <td class="w-20">Discussion Type</td>
            <td>Scheduled Duration</td>
            <td>Actual Duration</td>
            <td>Status</td>
          </tr>
        </thead>
      </table>

      <!-- <cdk-virtual-scroll-viewport class="scroll_class_list scroll-gap"> -->
        <div
    class="search-results scroll_class_list scroll-gap"
    infinite-scroll
    [infiniteScrollDistance]="modalScrollDistance"
    [infiniteScrollThrottle]="modalScrollThrottle"
    [scrollWindow]="false"
    (scrolled)="pushValue($event)"
  >
        <table class="table">
          <thead></thead>
          <tbody>
            <ng-container *ngIf="count > 0">
              <tr
                *ngFor="let guidedata of guidesdata;"
              >
                <td>{{ guidedata?.start_date | date: "MMM d, y" }}</td>
                <td class="w-20-1">
                  <span class="innerData">
                    {{ guidedata?.guide_name}}
                  </span>
                </td>
                <td class="w-20-1">{{ eventType(guidedata?.event_type) }}</td>
                <td>{{ guidedata?.time_duration }} min</td>
                <td>{{ guidedata?.actual_duration }} min</td>
                <td>
                  <span *ngIf="guidedata.active == 0">Requested</span>
                  <!-- <span *ngIf="guidedata.active == 1">Published</span> -->
                  <span *ngIf="guidedata.active == 2">Canceled</span>
                  <span *ngIf="guidedata.active == 3">Scheduled</span>
                  <span *ngIf="guidedata.active == 4">Auto Canceled</span>
                  <span *ngIf="guidedata.active == 5">Completed</span>
                  <span *ngIf="guidedata.active == 6">Closed</span>
                  <span *ngIf="guidedata.active == 7">Auto completed</span>
                  <span *ngIf="guidedata.active == 8">Withdrawn</span>
                  <span *ngIf="guidedata.active == 9">Declined </span>
                  <span *ngIf="guidedata.active == 10">Requested for cancelation</span>
                  <span *ngIf="guidedata.active == 11">Canceled due to request</span>
                  <span *ngIf="guidedata.active == 12">Auto Withdraw</span>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="count == 0">
              <td colspan="7">
                <p>No Data Found!</p>
              </td>
            </tr>
          </tbody>
        </table>
      <!-- </cdk-virtual-scroll-viewport> -->
      </div>
    </div>
    <p class="text-align-right">
      Report Generated on: {{ maxDate | date: "MMM d, y, h:mm a" }}
    </p>
  </mat-card-content>
</mat-card>
