import { DomSanitizer } from '@angular/platform-browser';
import { MirrorService } from '../../../../service/mirror.service';
import {
  Component,
  Optional,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-mirror-view',
  templateUrl: './mirror-view.component.html',
  styleUrls: ['./mirror-view.component.scss'],
})
export class MirrorViewComponent implements OnInit, OnDestroy {
  survey_participant_id: string;
  submission_date: string;
  mirrorData: {};
  survey_response: {};
  count: number;
  date: any;
  question_text_: any;
  text: any;

  total_group_response: number;
  group_answer: string;
  group_answer_array: any[];
  group_answer_response_array: any[];
  free_format_answer: string;
  free_format_answer_array: any[];
  checkbox_with_option_texbox: any[] = [];
  mirror = {};
  group_response_selected: {};
  group_selected_one: string;
  group_selected_two: string;
  select_group_text_value_text1: string;
  select_group_text_value_text2: string;
  mirrorDetailSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<MirrorViewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private surveyService: MirrorService,
    private _cdref: ChangeDetectorRef,
    public sanitizer: DomSanitizer
  ) {
    this.mirror = data.mirror;
    this.group_response_selected = JSON.parse(
      JSON.stringify(this.mirror['group_response_selected'])
    );
    this.group_selected_one = this.group_response_selected[
      'group_selected_one'
    ];
    this.group_selected_two = this.group_response_selected[
      'group_selected_two'
    ];
    this.select_group_text_value_text1 = this.group_response_selected[
      'select_group_text_value_text1'
    ];
    this.select_group_text_value_text2 = this.group_response_selected[
      'select_group_text_value_text2'
    ];
    this.survey_participant_id = data.survey_participant_id;
    this.submission_date = data.submission_date;
    this.total_group_response = data.total_group_response;
    this.group_answer = data.group_answer;
    this.free_format_answer = data.free_format_answer;
  }

  ngOnInit(): void {
    this.group_answer_array = [];
    for (let i = 0; i < this.total_group_response; i++) {
      this.group_answer_array.push(i);
    }
    this.group_answer_response_array = this.group_answer.split(',');
    this.free_format_answer_array = this.free_format_answer.split('[]');
    this.getMirrorDetails(this.survey_participant_id);
  }

  getMirrorDetails(participant) {
    this.mirrorDetailSubscription = this.surveyService
      .getSingleMirrorDetailsData(participant)
      .subscribe((data) => {
        this.mirrorData = data.results;
        this.count = data.count ? data.count : 0;
        this.checkbox_with_option_texbox = this.mirrorData[2]
          .checkbox_with_option_texbox
          ? this.mirrorData[2].checkbox_with_option_texbox.split(',')
          : [];
        this._cdref.detectChanges();
      });
  }

  closeDialog() {
    this.dialogRef.close({
      event: 'close',
      data: this.survey_participant_id,
    });
  }
  ngOnDestroy(): void {
    if (this.mirrorDetailSubscription) {
      this.mirrorDetailSubscription.unsubscribe();
    }
  }
}
