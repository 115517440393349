import { MapOfMeaningModule } from './map-of-meaning/map-of-meaning.module';
import { NotificationService } from '../../service/notification.service';
import { OverviewService } from '../../service/overview.service';
// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials

import { MyMentorModule } from './my-mentor/my-mentor.module';
import { MeaningCommunitiesModule } from './meaning-communities/meaning-communities.module';
import { MeaningCircleModule } from './meaning-circle/meaning-circle.module';
import { MeaningNetworkModule } from './meaning-network/meaning-network.module';
import { LoginModule } from './login/login.module';
import { MatCardModule } from '@angular/material/card';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { HomeModule } from './home/home.module';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    HomeModule,
    HttpClientModule,
    FormsModule,
    MapOfMeaningModule,
    MatCardModule,
    LoginModule,
    MyMentorModule,
    MeaningCommunitiesModule,
    MeaningNetworkModule,
    MeaningCircleModule,
    NgSelectModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
  ],
  providers: [OverviewService, CookieService, NotificationService],
})
export class PagesModule {}
