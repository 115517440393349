import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InfoMomDetailsComponent } from './../info-mom-details/info-mom-details.component';
import { OverviewService } from '../../../../service/overview.service';
import { AccountsService } from './../../../../service/accounts.service';
@Component({
  selector: 'kt-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss'],
})
export class InfoPopupComponent implements OnInit {
  loggedInUser: number;
  fullname: string;
  param: number;
  name: string;
  parameter_value: string;

  name_map: string;
  parameter_value_map: string;
  name_guide: string;
  parameter_value_guide: string;
  name_mirror: string;
  parameter_value_mirror: string;
  name_lib: string;
  parameter_value_lib: string;
  name_net: string;
  parameter_value_net: string;
  name_cir: string;
  parameter_value_cir: string;
  name_com: string;
  parameter_value_com: string;
  name_port: string;
  parameter_value_port: string;

  constructor(
    public dialogRef: MatDialogRef<InfoPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private overviewservice: OverviewService,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
    this.fullname = this.accountService.full_name;
    this.getMap();
    this.getGuide();
    this.getMirror();
    this.getLibrary();
    this.getNetwork();
    this.getCircle();
    this.getCommunity();
    this.getPortrait();
  }

  close() {
    this.dialogRef.close();
  }
  getPortrait() {
    this.overviewservice.getoverviewcontent(125).subscribe((data) => {
      this.name_port = data.name;
      this.parameter_value_port = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }


  getMap() {
    this.overviewservice.getoverviewcontent(126).subscribe((data) => {
      this.name_map = data.name;
      this.parameter_value_map = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getLibrary() {
    this.overviewservice.getoverviewcontent(129).subscribe((data) => {
      this.name_lib = data.name;
      this.parameter_value_lib = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getGuide() {
    this.overviewservice.getoverviewcontent(128).subscribe((data) => {
      this.name_guide = data.name;
      this.parameter_value_guide = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getCircle() {
    this.overviewservice.getoverviewcontent(132).subscribe((data) => {
      this.name_cir = data.name;
      this.parameter_value_cir = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getNetwork() {
    this.overviewservice.getoverviewcontent(130).subscribe((data) => {
      this.name_net = data.name;
      this.parameter_value_net = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getCommunity() {
    this.overviewservice.getoverviewcontent(131).subscribe((data) => {
      this.name_com = data.name;
      this.parameter_value_com = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  getMirror() {
    this.overviewservice.getoverviewcontent(127).subscribe((data) => {
      this.name_mirror = data.name;
      this.parameter_value_mirror = data.parameter_value;
      this.cdRef.detectChanges();
    });
  }
  myPortrait(param, name_port, parameter_value_port) {
    // this.getGuide();
    this.dialog.open(InfoMomDetailsComponent, {
      width: '40%',
      disableClose: true,
      data: {
        param: param,
        name: name_port,
        parameter_value: parameter_value_port,
      },
    });
  }

  myGuide(param, name_guide, parameter_value_guide) {
    // this.getGuide();
    this.dialog.open(InfoMomDetailsComponent, {
      width: '40%',
      disableClose: true,
      data: {
        param: param,
        name: name_guide,
        parameter_value: parameter_value_guide,
      },
    });
  }

  mirrorList(param, name_mirror, parameter_value_mirror) {
    // this.getMirror();
    this.dialog.open(InfoMomDetailsComponent, {
      width: '40%',
      disableClose: true,
      data: {
        param: param,
        name: name_mirror,
        parameter_value: parameter_value_mirror,
      },
    });
  }

  mapOfMeaning(param, name_map, parameter_value_map) {
    // this.getMap();
    this.dialog.open(InfoMomDetailsComponent, {
      width: '40%',
      disableClose: true,
      data: {
        param: param,
        name: name_map,
        parameter_value: parameter_value_map,
      },
    });
  }

  library(param, name_lib, parameter_value_lib) {
    // this.getLibrary();
    this.dialog.open(InfoMomDetailsComponent, {
      width: '40%',
      disableClose: true,
      data: {
        param: param,
        name: name_lib,
        parameter_value: parameter_value_lib,
      },
    });
  }

  network(param, name_net, parameter_value_net) {
    // this.getNetwork();
    this.dialog.open(InfoMomDetailsComponent, {
      width: '40%',
      disableClose: true,
      data: {
        param: param,
        name: name_net,
        parameter_value: parameter_value_net,
      },
    });
  }

  circles(param, name_cir, parameter_value_cir) {
    // this.getCircle();
    this.dialog.open(InfoMomDetailsComponent, {
      width: '40%',
      disableClose: true,
      data: {
        param: param,
        name: name_cir,
        parameter_value: parameter_value_cir,
      },
    });
  }

  community(param, name_com, parameter_value_com) {
    // this.getCommunity();
    this.dialog.open(InfoMomDetailsComponent, {
      width: '40%',
      disableClose: true,
      data: {
        param: param,
        name: name_com,
        parameter_value: parameter_value_com,
      },
    });
  }
}
