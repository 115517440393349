import { SurveyResponseComponent } from './../survey-response/survey-response.component';
import { NotificationService } from './../../../../service/notification.service';
import { SurveyService } from './../../../../service/survey.service';
import { Router } from '@angular/router';
import { CompareTwoSurveyComponent } from './../compare-two-survey/compare-two-survey.component';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  DoCheck,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { message_properties } from '../../../../../environments/message_properties';
import { MatDialog } from '@angular/material/dialog';
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { SubscriptionService } from '../../../../service/subscription.service';
import { BuyMeaningfulSurveyComponent } from '../buy-meaningful-survey/buy-meaningful-survey.component';
import { FinalReportFirstPageComponent } from '../final-report-first-page/final-report-first-page.component';
import { AccountsService } from './../../../../service/accounts.service';

@Component({
  selector: 'kt-survey-submitted-list',
  templateUrl: './survey-submitted-list.component.html',
  styleUrls: ['./survey-submitted-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveySubmittedListComponent
  implements OnInit, DoCheck, OnDestroy {
  throttle = 300;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;

  mirrorForm: UntypedFormGroup;
  momData = [];
  survey_response: {};
  count: number;
  date: any;
  question_text_: any;
  participant: any;

  survey_id: string;
  paticipant: number;
  latestGivenSurveyID: number;
  page: number = 2;
  listSurveySubscription: Subscription;
  // guideListSubscription: Subscription;
  // latestSurveySubscription: Subscription;
  // shareSurveySubscription: Subscription;
  GuideServiceList = [];
  info_highlights =
    'Indicates the number of attempts remaining and the date by which it ends.';
  popup_description: string;
  catalog_buy_survey_id: string;
  survey_price: string;
  purchase_survey = false;
  remaining_survey = '';
  survey_valid = '';
  show_buy_survey = '';
  take_survey = '';
  current_timezone: string;
  check_survey_amount: boolean;

  constructor(
    private dialog: MatDialog,
    private surveyService: SurveyService,
    private spinner: NgxSpinnerService,
    private _cdref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private toastr: NotificationService,
    private subscriptionService: SubscriptionService,
    private accountService: AccountsService
  ) {}

  ngOnInit() {
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.survey_id = '1';
    this.paticipant = this.accountService.muser;
    this.mirrorForm = this.formBuilder.group({
      participant: new UntypedFormControl(this.paticipant),
      survey_id: new UntypedFormControl(this.survey_id),
      page: 1,
    });
    this.subscriptionService.refreshPaymentList.subscribe((data) => {
      this.allSurveyListbyPost();
    });
    this.allSurveyListbyPost();
    localStorage.removeItem('participantSurveyId');
    localStorage.removeItem('number_of_save');
    this.guideServiceList();
  }
  ngDoCheck() {
    this.getLatestSurveyId();
  }

  pushValue(ev?) {
    if (this.count !== this.momData.length) {
      this.mirrorForm.patchValue({
        page: this.page,
      });
      this.allSurveyListbyPost();
      this.page++;
    }
  }
  allSurveyListbyPost(params = 0) {
    if (params === 1) {
      this.page = 2;
      this.mirrorForm.patchValue({
        page: 1,
      });
      this.momData = [];
    }
    let formData = this.mirrorForm.value;
    this.spinner.show();
    formData = this.mirrorForm.value;
    this.listSurveySubscription = this.surveyService
      .allAllMapOfMeaningListbyPost(formData)
      .subscribe((data) => {
        this.momData = [
          ...new Map(
            this.momData
              .concat(data['results'])
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.count = data['count'] ? data['count'] : 0;
        this.remaining_survey = data['remaining_survey'];
        this.survey_valid = data['survey_validity'];
        this.purchase_survey = data['buy_survey'];
        this.check_survey_amount = data['check_amount'] === false ? true : false;
        if (this.purchase_survey) {
          this.take_survey = '1';
          this.show_buy_survey = '0';
        } else if (!this.purchase_survey) {
          this.take_survey = '0';
          this.show_buy_survey = '1';
        }
        this.spinner.hide();
        this._cdref.detectChanges();
      });
  }
  responsePageCall(id) {
    let dialogRef = this.dialog.open(SurveyResponseComponent, {
      width: '70%',
      disableClose: true,
      data: {
        currentSelectedSurvey: id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  compareTwoSurvey(selectedSurveyId, datetime) {
    const dialogRef = this.dialog.open(CompareTwoSurveyComponent, {
      width: '40%',
      disableClose: true,
      data: { currentSelectedSurvey: selectedSurveyId, _datetime: datetime },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  goToSelectedSurveyReport(selectedSurveyId, json_report) {
    let dialogRef = this.dialog.open(FinalReportFirstPageComponent, {
      width: '80%',
      disableClose: true,
      data: {
        memberId: this.paticipant,
        currentSelectedSurvey: selectedSurveyId,
        json_report1: json_report,
      },
    });
  }

  completeSurvey(participantSurveyId, noOfSaves) {
    localStorage.setItem('participantSurveyId', participantSurveyId);
    localStorage.setItem('number_of_save', noOfSaves);
    this.router.navigate(['/meaningful-work-survey/take-the-survey']);
  }

  async getLatestSurveyId() {
    this.surveyService
      .getLatestSurveyID(this.paticipant)
      .subscribe((data) => {
        this.latestGivenSurveyID = data.latestGivenSurveyID;
        window.localStorage.setItem(
          'latestGivenSurveyID',
          this.latestGivenSurveyID.toString()
        );
      });
  }
  nextStep() {
    this.router.navigate(['/meaningful-work-survey/next-steps']);
  }

  shareWithGuide(surveyId, guideId) {
    Swal.fire({
      title: 'Please confirm!',
      text:
        'You want to share this Meaningful Work Survey with your current Guide.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        if (guideId !== null) {
          const data = {
            object_id: surveyId,
            shared_with_member: guideId,
            relationship_type: 2177,
            member: this.paticipant,
            object_type: 2560,
          };
          this.surveyService
            .sharedWithGuide(data)
            .subscribe((data) => {
              if (data.status === 200) {
                this.toastr.showSuccess(
                  message_properties.SURVEY_SUBMITTED_LIST_SHARE_WITH_GUIDE_SUCCESS,
                  ''
                );
              } else {
                this.toastr.showError(
                  message_properties.SURVEY_SUBMITTED_LIST_SHARE_WITH_GUIDE_ALREADY_SUCCESS,
                  ''
                );
              }
            });
        } else {
          this.toastr.showError(
            message_properties.SURVEY_SUBMITTED_LIST_SHARE_WITH_GUIDE_ERROR,
            ''
          );
        }
      }
    });
  }
  takeSurvey() {
    this.router.navigate(['meaningful-work-survey', 'lets-get-started']);
  }
  myDiyActivity() {
    this.router.navigate([
      'meaningful-work-survey',
      'diy-page',
      'final-report-diy',
    ]);
  }

  guideServiceList() {
    this.GuideServiceList = [];
    this.subscriptionService
      .catalogListBasisOnOffering(2651)
      .subscribe(
        (data) => {
          this.GuideServiceList = data.results;
          this.popup_description = this.GuideServiceList[0][
            'catalog_small_description'
          ];
          this.catalog_buy_survey_id = this.GuideServiceList[0]['id'];
          this.survey_price = this.GuideServiceList[0]['price'];
          this._cdref.detectChanges();
        },
        (error) => console.error('Technical Issue. Guide Service not available')
      );
  }
  buyMeaningfulSurvey() {
    this.dialog.open(BuyMeaningfulSurveyComponent, {
      width: '40%',
      disableClose: true,
      data: {
        description: this.popup_description,
        catalog_id: this.catalog_buy_survey_id,
        survey_price: this.survey_price,
      },
    });
  }
  ngOnDestroy() {
    if (this.listSurveySubscription) {
      this.listSurveySubscription.unsubscribe();
    }
  }
}
