import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CityStateCountryService {
  apiUrl: string;
  private _refreshCountrylist = new Subject<void>();

  get refreshCountrylist() {
    return this._refreshCountrylist;
  }
  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  getCity(sendValue) {
    return this.http
      .post<CityData>(
        this.apiUrl +
          'country-state-city/list-of-city-inside-a-country-and-state/',
        sendValue
      )
      .pipe(
        map((data) => {
          return { results: data.results, count: data.count };
        }),
        debounceTime(5000)
      );
  }

  getCountry(name) {
    const data = {
      name: name,
    };
    return this.http
      .post<CountryData>(
        this.apiUrl + 'country-state-city/get-list-of-country/',
        data
      )
      .pipe(
        map((data) => {
          return { results: data.results, count: data.count };
        })
      );
  }
  getCountryFlagDetails() {
    return this.http.get<any>(this.apiUrl + 'common/current-location-details/');
  }
  getAllCountryList() {
    return this.http.get<any>(
      this.apiUrl + 'country-state-city/list-of-country/'
    );
  }
  getAllCountryListBlockAndunblock(postData) {
    return this.http.post<any>(
      this.apiUrl + 'country-state-city/all-country-list/',
      postData
    );
  }

  countryFlagValidation(postData) {
    return this.http.post<CountryData>(
      this.apiUrl + 'common/country-flag-validation/',
      postData
    );
  }
  changeCountryStatusLog(postData) {
    return this.http
      .post<CountryData>(
        this.apiUrl + 'country-state-city/country-status-change-log/',
        postData
      )
      .pipe(
        tap((data) => {
          this._refreshCountrylist.next();
        })
      );
  }
}

export interface CityData {
  id: number;
  name: string;
  results: [];
  count: number;
  state: number;
  city_with_country_code: string;
}
export interface CountryData {
  id: number;
  name: string;
  results: [];
  count: number;
  status: number;
  message: string;
}
