<div class="scroll_class card2-style-height-with-tabs-hr-heading">
    <div class="">
      <div class="row mt-2">
        <div class="col-sm-12">
          <!-- <span class="card_title smaller">CURRENT GUIDE</span>
          <hr /> -->
          <ng-container *ngIf="current_guide_count > 0">
            <!-- horizontal-scrool -->
            <div class="row">
              <div
                class="col-lg-3 col-md-6 col-sm-12 card-explorer"
                *ngFor="let mentor of currentGuides; let i = index"
              >
                <div
                  class="card archive_card front_card_background_clr document cursorp"
                  (click)="viewSharedSpace(mentor, 0, mentor.relating_member_profile[0].member__status)"
                >
                  <div class="img_section_mirror cursorp text-center">
                    <img
                      [src]="
                        returnImage(
                          mentor.relating_member_profile[0].picture_path
                        )
                      "
                      alt=""
                      class="w-124-pm archive_img new-c-photo new-c-photo-guid archive_img {{
                        mentor.member_accepted_count == 1
                          ? 'archive_img_border'
                          : ''
                      }} curator"
                    />
                  </div>

                  <div class="title">
                    <div class="member_card_name text_on_image mt-3">
                      <h1>
                        {{ mentor.relating_member_profile[0].first_name }}
                      </h1>
                      <h2 class="fs-18">
                        {{ mentor.relating_member_profile[0].last_name }}
                      </h2>
                      <h3>
                        {{
                          mentor.member_address[0].city
                            ? mentor.member_address[0].city
                            : "------"
                        }},
                        {{
                          mentor.member_address[0].country
                            ? mentor.member_address[0].country
                            : "------"
                        }}
                      </h3>
                      <!-- </p> -->

                      <div class="archive_title">
                        <table border="0" width="100%">
                          <tr>
                            <td>
                              <h4 class="fs-18">
                                Guide Since:
                                {{ mentor.start_date | date: "MMM d, y" }}
                              </h4>
                            </td>
                          </tr>
                          <!-- <tr>
                            <td>
                              <h4 class="fs-18">
                                Endorsed on:
                                <span
                                  *ngIf="
                                    mentor.member_endorsement.length > 0
                                  "
                                >
                                  {{
                                    mentor.member_endorsement[0][
                                      "creation_date"
                                    ] | date: "MMM d, y"
                                  }}</span
                                ><span
                                  *ngIf="
                                    mentor.member_endorsement.length == 0
                                  "
                                  >---</span
                                >
                              </h4>
                            </td>
                          </tr> -->
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="current_guide_count == 0">
            <table>
              <tbody>
                <tr>
                  <td class="p-2">
                    <img
                      src="./assets/media/no_data_found.png"
                      class="search_no_data_found_image"
                    />
                  </td>
                  <td colspan="2" class="p-2">
                    You do not have any Guide currently!
                    <a
                      [routerLink]="'/discover/search-for-a-guide'"
                      class="hyperlink_hand"
                    >
                      Find a Guide</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
  </div>