<kt-spinner></kt-spinner>
<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1 class="cmn_title_top">View my Response</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />

  <div class="content_part mt-4 mob-display-none">
    <table width="100%">
      <thead>
        <tr>
          <td>&nbsp;</td>
          <td
            *ngFor="let option of mirrorresponse"
            width="12%"
            align="center"
            class="text-uppercase" 
          >
            <p class="p_edit_table_response_header">
              {{ option }}
            </p>
          </td>
        </tr>
      </thead>
      <tbody class="view-scrool">
        <ng-container *ngFor="let mirror of mirrorData">
          <tr>
            <td>
              <p class="p_edit mt-2">
                {{ mirror.question_response_text[0]["question_category"] }}
                {{ mirror.question_response_text[0]["question_text"] }}
              </p>
            </td>
            <td
              *ngFor="let option of mirrorresponse"
              align="center"
              width="12%"
            >
              <mat-radio-group aria-label="Select an option">
                <mat-radio-button
                  [disabled]="true"
                  value="1"
                  [checked]="
                    option == mirror.answer_text[0]['text'] ? true : false
                  "
                ></mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <hr />
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="content_part mt-4 pc-display-none">
    <table class="w-100 d-none">
      <thead>
        <tr>
          <td>&nbsp;</td>
          <td
            *ngFor="let option of mirrorresponse"
            width="12%"
            align="center" class="text-uppercase"
          >
            <p class="p_edit_table_response_header">
              {{ option }}
            </p>
          </td>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let mirror of mirrorData">
          <tr>
            <td>
              <p class="p_edit mt-2">
                {{ mirror.question_response_text[0]["question_category"] }}
                {{ mirror.question_response_text[0]["question_text"] }}
              </p>
            </td>
            <td
              *ngFor="let option of mirrorresponse"
              align="center"
              width="12%"
            >
              <mat-radio-group aria-label="Select an option">
                <mat-radio-button
                  [disabled]="true"
                  value="1"
                  [checked]="
                    option == mirror.answer_text[0]['text'] ? true : false
                  "
                ></mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <hr/>
        </ng-container>
      </tbody>
    </table>

    <div class="mob-table">
      <div class="mob-report-title-tr" *ngFor="let mirror of mirrorData">
        <p class="mob-report-title text-uppercase">
          {{ mirror.question_response_text[0]["question_category"] }}
          {{ mirror.question_response_text[0]["question_text"] }}
        </p>
        <div class="d-flex text-uppercase flex-direction-row"
        >
          <p
            class="p_edit_table_response_header"
            *ngFor="let option of mirrorresponse"
          >
            {{ option }}
          </p>
        </div>
        <div class="mob-tr-radio">
          <div class="radio">
            <mat-radio-group
              aria-label="Select an option"
              *ngFor="let option of mirrorresponse"
            >
              <mat-radio-button
                [disabled]="true"
                value="1"
                [checked]="
                  option == mirror.answer_text[0]['text'] ? true : false
                "
              ></mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
