import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../../../service/notification.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { RegisterService } from '../../../../service/register.service';
import { message_properties } from '../../../../../environments/message_properties';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-registerverification',
  templateUrl: './registerverification.component.html',
  styleUrls: ['./registerverification.component.scss'],
})
export class RegisterverificationComponent implements OnInit, OnDestroy {
  notificationForm: UntypedFormGroup;
  email: any;
  otp_code: number;
  verifyOtpSubscription: Subscription;
  generateOtpSubscription: Subscription;
  constructor(
    private toastr: NotificationService,
    private register: RegisterService,
    private spinner: NgxSpinnerService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.email = atob(localStorage.getItem('xyze'));
    this.notificationForm = new UntypedFormGroup({
      otpcode: new UntypedFormControl('', [Validators.required]),
    });
  }

  onSubmit(ngForm: NgForm) {
    this.spinner.show();
    return (this.verifyOtpSubscription = this.register
      .OnVerifyOtpSubmit(ngForm)
      .subscribe(
        (res) => {
          if (res.code === 201) {
            this.spinner.hide();
            this.toastr.showSuccess(message_properties.OTP_SUCCESS, '');
            this._router.navigate(['/login'], {
              queryParamsHandling: 'preserve',
            });
          } else {
            this.spinner.hide();
            this.toastr.showError(res.message, '');
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.showError(message_properties.REGISTER_ERROR, '');
        }
      ));
  }

  sentAgain() {
    this.spinner.show();
    const in_data = { email: this.email };
    return (this.generateOtpSubscription = this.register
      .generateNewOtp(in_data)
      .subscribe(
        (res) => {
          if (res.code === 201) {
            this.spinner.hide();
            this.toastr.showSuccess(
              message_properties.SENT_VERIFICATION_AGAIN_SUCCESS,
              ''
            );
          } else {
            this.spinner.hide();
            this.toastr.showError(message_properties.EMAIL_SENT_ERROR, '');
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.showError(message_properties.EMAIL_SENT_ERROR, '');
        }
      ));
  }
  ngOnDestroy(): void {
    if (this.verifyOtpSubscription) {
      this.verifyOtpSubscription.unsubscribe();
    }
    if (this.generateOtpSubscription) {
      this.generateOtpSubscription.unsubscribe();
    }
  }
}
