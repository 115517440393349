import { YourEndorseDiscussionListComponent } from './../../endorse-guide/your-endorse-discussion-list/your-endorse-discussion-list.component';
import { SubscriptionService } from './../../../../../service/subscription.service';
import { ViewProfileComponent } from './../../../admin-page/view-profile/view-profile.component';
import { PrivateNotesListComponent } from './../../private-notes-list/private-notes-list.component';
import { MeaningNetworkService } from './../../../../../service/meaning-network.service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MentorService } from '../../../../../service/mentor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SendMassageComponent } from '../../../admin-page/send-massage/send-massage.component';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../../../../../service/auth.service';
import { CuratorPageService } from '../../../../../service/curator-page.service';
import { AccountsService } from './../../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-shared-space',
  templateUrl: './shared-space.component.html',
  styleUrls: ['./shared-space.component.scss'],
})
export class SharedSpaceComponent implements OnInit, OnDestroy {
  privatenotesForm: UntypedFormGroup;
  discussions = [];
  menteeId: number;
  fullName: string;
  currentGuides: any;
  pastGuides: Array<any>;
  menteeDp: string;
  first_name: string;
  last_name: string;
  loggedinUserid: number;
  guideDp: string;
  menteeData: any[] = [];
  menteeStr: string;
  status: number;
  valueStr: string;
  start_date: string;
  address: any;
  city: string;
  state: string;
  country: string;
  profileData = [];
  menteeprofileData = [];
  mentor_indicator: boolean = false;
  full_name: string;
  profile_path_for_logged_in_user: string;
  test: string;
  discussion_details: any;
  completed_discussion: any;
  scheduled_discussion: any;
  requested_discussion: any;
  remaining_balance: any;
  completed_mom_discussions: number;
  completed_guide_discussions: number;
  completed_diy_discussions: number;
  name: string[];
  isPastGuide: number;
  available_general_discussions_time: number;
  available_guided_mom_discussion: number;
  available_guided_reflection_discussions: number;
  member_relationship_id: number;
  previous_guide_count: number;
  requested_from: number;
  connection_status: number;
  requested_to: number;
  connection_count: number;
  params: string;
  splited_string: any;
  requested_to_spilted: any;
  request_to_after_split: number;
  dropDown1: boolean = false;
  dropDown2: boolean = false;
  notes_count: number;
  private_notes_arr = [];
  come_from_url: string;
  notePrivateSubscription: Subscription;
  summarySubscription: Subscription;
  currentGuideSubscription: Subscription;
  discussionSubscription: Subscription;
  menteeSubscription: Subscription;
  profileSubscription: Subscription;
  authSubscription: Subscription;
  constructor(
    private _cdref: ChangeDetectorRef,
    private mentorService: MentorService,
    private router: Router,
    private active_route: ActivatedRoute,
    private dialog: MatDialog,
    private meaning_network_service: MeaningNetworkService,
    private subscriptionService: SubscriptionService,
    private formBuilder: UntypedFormBuilder,
    private curatorService: CuratorPageService,
    private accountService: AccountsService
  ) {
    this.loggedinUserid = this.accountService.muser;

    this.pastGuides = [];
    this.currentGuides = [];
    this.params = this.active_route.snapshot.paramMap.get('params');
    const paramsArr = atob(this.params).split(']||[');
    this.member_relationship_id = Number(paramsArr[0]);
    this.menteeId = Number(paramsArr[1]);
    this.fullName = paramsArr[2];
    this.splited_string = this.fullName.split(' ');
    this.first_name = this.splited_string[0];
    this.last_name = this.splited_string[1];
    if (
      paramsArr[3] === '0' ||
      paramsArr[3] === 'null' ||
      paramsArr[3] === 'NaN'
    ) {
      this.menteeDp = '../../../../../../assets/media/users/default.png';
    } else {
      this.menteeDp = String(paramsArr[3]);
    }
    this.status = Number(paramsArr[4]);
    this.start_date = paramsArr[5];
    this.city = paramsArr[6];
    this.country = paramsArr[7];
    this.isPastGuide = Number(paramsArr[8]);
    this.come_from_url = paramsArr[9];
  }

  ngOnInit() {
    const helper = new JwtHelperService();
    const jti_for_current_logged_in_user = helper.decodeToken(
      this.accountService.token
    ).jti;
    const postData = {
      refresh: this.accountService.refresh_token,
      token: this.accountService.token,
    };
    if (String(this.come_from_url) !== String(jti_for_current_logged_in_user)) {
      this.authSubscription = this.accountService
        .logoutuser(postData)
        .subscribe((data) => {
          localStorage.removeItem('token_refresh_data');
          localStorage.clear();
          this._cdref.detectChanges();
          window.location.href = '/';
        });
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.loggedinUserid = this.accountService.muser;
    this.privatenotesForm = this.formBuilder.group({
      member: new UntypedFormControl(this.loggedinUserid),
      relationship_id: new UntypedFormControl(this.member_relationship_id),
      page: 1,
    });
    this.curatorService.all_status_change_refresh.subscribe((data) => {
      this.getDiscussionSummaryDetails();
    });
    this.mentorService.requestrefreshneededForMarkAsClosed.subscribe((data) => {
      this.getDiscussionSummaryDetails();
    });
    this.menteeStr = `${this.member_relationship_id}]||[${this.menteeId}]||[${this.fullName}]||[${this.menteeDp}]||[${this.status}]||[${this.start_date}]||[${this.city}]||[${this.state}]||[${this.country}]||[${jti_for_current_logged_in_user}`;
    this.getDiscussionsDocsMentors(this.menteeStr);
    this.getAllMentee();
    this.getPreviousCurrentGuides();
    this.getProfileWithAddressOfLoggedInUser();
    this.getDiscussionDetails();
    this.getDiscussionSummaryDetails();
    this.getAllPrivateNote();
  }

  getProfileWithAddressOfLoggedInUser() {
    this.profileSubscription = this.meaning_network_service
      .getProfileDetails(this.loggedinUserid)
      .subscribe((data) => {
        this.mentor_indicator = Boolean(data.mentor_indicator);
        this.full_name = data.full_name;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.profile_path_for_logged_in_user = data.profile_path
          ? data.profile_path
          : './assets/media/users/default.png';
        localStorage.setItem('ufullname', btoa(this.full_name));
        localStorage.setItem(
          'image',
          data.profile_path !== null ? data.profile_path : '0'
        );
        this._cdref.detectChanges();
      });
  }

  getAllMentee() {
    this.menteeSubscription = this.mentorService
      .getMenteeData(this.loggedinUserid, this.status)
      .subscribe((data) => {
        this.menteeData = data['guided_explorer']['results'];
        this._cdref.detectChanges();
      });
  }

  getDiscussionsDocsMentors(valueStr) {
    this.valueStr = valueStr;
    this.requested_to_spilted = this.valueStr.split(']||[');
    this.request_to_after_split = this.requested_to_spilted[1];
  }
  getDiscussionDetails() {
    this.discussionSubscription = this.subscriptionService
      .getDiscussionDetailsData(this.menteeId, this.loggedinUserid)
      .subscribe((data) => {
        this.completed_discussion = data.completed_discussions_time;
        this.scheduled_discussion = data.scheduled_discussions_time;
        this.requested_discussion = data.requested_discussions_time;
        this.remaining_balance = data.available_balance_time;
        this._cdref.detectChanges();
      });
  }
  returnImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }

  showSharedSpace() {
    const valueArr = this.valueStr.split(']||[');
    this.member_relationship_id = Number(valueArr[0]);
    this.menteeId = Number(valueArr[1]);
    this.fullName = valueArr[2];
    this.menteeDp = valueArr[3];
    this.status = Number(valueArr[4]);
    this.start_date = valueArr[5];
    this.city = valueArr[6];
    this.state = valueArr[7];
    this.country = valueArr[8];
    const helper = new JwtHelperService();
    const jti_for_current_logged_in_user = helper.decodeToken(
      this.accountService.token
    ).jti;
    const param_string = `${this.member_relationship_id}]||[${this.menteeId}]||[${this.fullName}]||[${this.menteeDp}]||[${this.status}]||[${this.start_date}]||[${this.city}]||[${this.state}]||[${this.country}]||[${jti_for_current_logged_in_user}`;
    const param_data = window.btoa(param_string);
    this.router.navigate([
      'discover',
      'my-guided-explorers',
      'shared-space',
      param_data,
    ]);
  }
  getPreviousCurrentGuides() {
    this.currentGuideSubscription = this.mentorService
      .getPastMentors(this.menteeId)
      .subscribe((success) => {
        this.pastGuides = success.results;
        this.previous_guide_count = success.count;
      });
  }
  sendMassage(collabContentId, menteeId) {
    this.dialog.open(SendMassageComponent, {
      disableClose: true,
      width: '40%',
      data: {
        collabContentId: collabContentId,
        mentee_id: menteeId,
        clicked_from: 1008,
        full_name: this.fullName,
        profile_picture: this.menteeDp,
      },
    });
  }

  viewProfile(member_id, first_name, last_name, picture_path) {
    let dialogRef = this.dialog.open(ViewProfileComponent, {
      width: '80%',
      disableClose: true,
      data: {
        member: member_id,
        first_name: first_name,
        last_name: last_name,
        picture_path: picture_path,
        click_from: 'guide_search',
      },
    });
  }

  viewGuideProfile(member, full_name, picPath) {
    this.dialog.open(ViewProfileComponent, {
      width: '67%',
      disableClose: true,
      data: {
        requested_from: this.requested_from,
        fullName: full_name,
        picPath: picPath,
        connection_status: this.connection_status,
        requested_to: this.requested_to,
        member_connect_count: this.connection_count,
        click_from: 'guide_search',
      },
    });
  }
  yourGuideDiscussionPopoup() {
    this.dialog.open(YourEndorseDiscussionListComponent, {
      disableClose: true,
      width: '80%',
      data: {
        mentorId: this.menteeId,
        member_relationship_id: this.member_relationship_id,
        first_name: this.first_name,
        last_name: this.last_name,
        registration_type: 2682,
        isPastGuide: this.isPastGuide,
        clicked_from: 'shared_space',
      },
    });
  }
  getDiscussionSummaryDetails() {
    this.summarySubscription = this.mentorService
      .getDiscussionSummaryData(
        this.member_relationship_id,
        this.loggedinUserid,
        this.menteeId
      )
      .subscribe((data) => {
        this.completed_mom_discussions =
          data.results['completed_guided_mom_discussion'];
        this.completed_guide_discussions =
          data.results['completed_general_discussions_time'];
        this.completed_diy_discussions =
          data.results['completed_guided_reflection_discussions'];
        this.available_general_discussions_time =
          data.results['available_general_discussions_time'];
        this.available_guided_mom_discussion =
          data.results['available_guided_mom_discussion'];
        this.available_guided_reflection_discussions =
          data.results['available_guided_reflection_discussions'];

        this._cdref.detectChanges();
      });
  }
  toggledrpDown1() {
    this.dropDown1 = !this.dropDown1;
    this.dropDown2 = false;
  }
  toggledrpDown2() {
    this.dropDown2 = !this.dropDown2;
    this.dropDown1 = false;
  }
  getAllPrivateNote() {
    const Formdata = this.privatenotesForm.value;
    this.notePrivateSubscription = this.mentorService
      .getPrivateNoteData(Formdata)
      .subscribe((data) => {
        this.private_notes_arr = data.results;
        this.notes_count = data.count;
        this._cdref.detectChanges();
      });
  }
  openPrivateNotes() {
    this.dialog.open(PrivateNotesListComponent, {
      disableClose: true,
      width: '60%',
      data: {
        relationship_id: this.member_relationship_id,
        menteeId: this.menteeId,
        guideFirstName: this.first_name,
        guideLastName: this.last_name,
        menteeFullName: this.fullName,
        mentee_dp: this.menteeDp,
        private_note_list: this.private_notes_arr,
        private_note_count: this.notes_count,
      },
    });
  }
  ngOnDestroy(): void {
    if (this.notePrivateSubscription) {
      this.notePrivateSubscription.unsubscribe();
    }
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
    if (this.currentGuideSubscription) {
      this.currentGuideSubscription.unsubscribe();
    }
    if (this.discussionSubscription) {
      this.discussionSubscription.unsubscribe();
    }
    if (this.menteeSubscription) {
      this.menteeSubscription.unsubscribe();
    }
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
function jwt_decode(arg0: string): any {
  throw new Error('Function not implemented.');
}
