
<kt-spinner></kt-spinner>
<div class="card register_card absolutueCenter lg loginn-card">
  <div class="title_section login_title mb-2">
    <h1 mat-dialog-title class="d-flex justify-content-between">
      <div>
        <span class="login_header">{{ login_title }}</span>
        <br />
        <span class="loginText" *ngIf="loginText == 'Survey'">
          To take the Survey, please log in or create an account.
        </span>
        <span class="loginText" *ngIf="loginText == 'Network'">
          To join the Network, please log in or create an account.
        </span>
        <span class="loginText" *ngIf="loginText == 'Guide'">
          To find a Guide, please log in or create an account.
        </span>
        <span class="loginText" *ngIf="loginText == 'Constellation'">
          To view the Library, please log in or create an account.
        </span>
        <span class="loginText" *ngIf="loginText == 'general'">
          To explore MeaningSphere, please log in or create an account.
        </span>
        <span class="loginText" *ngIf="loginText == 'back_general'">
          To explore MeaningSphere, please log in.
        </span>
      </div>
      <img class="sphere" src="./assets/media/users/default1.png" />
    </h1>

  </div>
  <button class="close-dialog" routerLink="/home">X</button>
  <small class="logind">{{ message }}</small>


  <form class="mtn20" [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)" novalidate>

    <div class="form-group">
      <mat-form-field>
        <!-- <mat-label>Email</mat-label> -->
        <input matInput placeholder="Email" formControlName="username" #email pattern="^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$"
          autocomplete="true" />
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <!-- <mat-label>Password</mat-label> -->
        <input matInput [type]="showPassword ? 'text' : 'password'" onpaste="false" oncopy="false"
          formControlName="password" placeholder="Password"/>
        <img class="eyeicon" (click)="showPassword = !showPassword" *ngIf="!showPassword"
          src="../../../../assets/media/visible.png">
        <img class="eyeicon" (click)="showPassword = !showPassword" *ngIf="showPassword"
          src="../../../../assets/media/invisible.png">
      </mat-form-field>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-sm-6 col-xs-6 div-log-forgt login1">
          <mat-checkbox class="left">Remember me</mat-checkbox>
        </div>
        <div class="col-sm-6 col-xs-6 tr div-log-forgt">
          <span class="right cursorp" (click)="forgotpassword()">
            Forgot Password ?
          </span>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-sm-12">
          By logging in, I confirm my agreement to the MeaningSphere <a
            href="https://support.meaningsphere.com/hc/en-us/articles/9485738538388-What-are-your-Terms-of-Use-"
            class="pp_underline" target="__blank">Terms of Use</a> and <a
            href="https://support.meaningsphere.com/hc/en-us/articles/9485678228372-What-is-your-Code-of-Conduct-"
            class="pp_underline" target="__blank">Code of Conduct</a> and acknowledge the MeaningSphere <a
            href="https://support.meaningsphere.com/hc/en-us/articles/8446382859028-What-is-your-Privacy-Policy-" class="pp_underline" target="__blank">Privacy
            Policy</a>.

        </div>
      </div>

    </div>
    <div class="form-group text-right">
      <button [disabled]="!loginForm.valid || saveButton" type="submit" class="btn begin_quiz">
        Login
      </button>
    </div>
  </form>
  <div class="register_section">
    <span>Not Registered ?</span> &nbsp;
    <a routerLink="/login/register" class="register_link">
      Start Your Journey</a>
  </div>
</div>