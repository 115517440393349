<kt-spinner></kt-spinner>
<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1 class="cmn_title_top">Mark Discussion as Closed</h1>
      </td>
    </tr>
  </table>
  <button mat-button mat-dialog-close class="modal_close_button_top">X</button>
  <hr />

  <div class="fixed_cntent">
    <form
      [formGroup]="markAsCompletedForm"
      (ngSubmit)="saveMarkAsCompleted(markAsCompletedForm.value)"
      novalidate
      autocomplete="off"
    >
      <mat-dialog-content>
        <div class="row">
          <div class="form-group col-sm-12">
            <p>
              Get {{event_type_textReturn(event_type)}} with {{ menteeFullName }} on
              {{ start_date | date: "MMM d, y":current_timezone }}
              at
              {{ onlyTimeTimeZone(start_date) }} for {{ duration }} Minutes
            </p>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <label>Actual Duration</label>
              <mat-form-field class="w-100 actual_top">
                <table class="w-100">
                  <tr>
                    <td class="desc_flex">
                      <a
                        (click)="minus_item(15)"
                        class="btn begin_quiz text-center"
                        id="minus-btn"
                      >
                        <mat-icon>remove</mat-icon>
                      </a>
                    </td>
                    <td>
                      <input
                        matInput
                        readonly
                        formControlName="actual_duration"
                        type="text"
                        min="0"
                        step="15"
                        class="duration-input marked-closed"
                                              />
                    </td>
                    <td class="desc_flex">
                      <a
                        (click)="plus_item(15)"
                        class="btn begin_quiz text-center"
                        id="plus-btn"
                      >
                        <mat-icon>add</mat-icon>
                      </a>
                    </td>
                  </tr>
                </table>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <hr />
      <div class="row mt-3">
        <div class="form-group col-sm-12 tr m-0">
          <ng-container>
            <button
              [disabled]="!markAsCompletedForm.valid"
              class="btn begin_quiz ml-2 mt-1"
              type="submit"
            >
              Save
            </button>
          </ng-container>
          <button
            mat-dialog-close
            class="btn begin_quiz mt-1 ml-2 button-change-scroll canceled_btn"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
