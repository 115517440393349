import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiUrl = environment.apiUrl;
  environment;
  email: string;
  password: string;
  var_check: number;
  data: any;
  constructor(public http: HttpClient, private router: Router) {
    this.apiUrl = environment.apiUrl;
  }
  username: string;

  loginChecking(ngForm): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post<any>(
      this.apiUrl + 'member/token-based-auth',
      ngForm,
      {
        headers: headers,
      }
    );
  }
  OnPwdResetSubmit(ngForm): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post<any>(
      this.apiUrl + 'member/member-password-reset',
      ngForm,
      {
        headers: headers,
      }
    );
  }
  passwordReset(ngForm) {
    return this.http.post<PasswordData>(
      this.apiUrl + 'member/member-password-reset',
      ngForm
    );
  }

  getToken() {
    return this.token;
  }

  get loggedIn() {
    const token = this.token;
    if (!token) {
      return false;
    }
    const helper = new JwtHelperService();
    //const expirationDate = helper.getTokenExpirationDate(token);
    const isExpire = helper.isTokenExpired(token);
    if (isExpire) {
    }
    return !isExpire;
  }

  logoutuser(postData) {
    return this.http.post<LogoutData>(
      this.apiUrl + 'member/token-auth-delete',
      postData
    );
  }
  
  get currentUser() {
    const token = this.token;
    if (!token) {
      return false;
    }
    return new JwtHelperService().decodeToken(token);
  }

  get token(){
    if(localStorage.getItem('token_refresh_data')){
      const token_refresh_data = localStorage.getItem('token_refresh_data');
      return JSON.parse(JSON.stringify(token_refresh_data))['token'];
    }
    return false;
  }
  //member-password-change
  passwordChange(ngForm, userid) {
    return this.http.put<PasswordData>(
      this.apiUrl + 'member/member-password-change/' + userid,
      ngForm
    );
  }
}

export interface LoginData {
  var_check: number;
  username: string;
  password: string;
  token: string;
  code: number;
  muser: number;
  full_name: any;
  mfid: number;
  number_of_save: any;
  admin_indicator: any;
  curator_indicator: any;
  mentor_indicator: any;
  user_email: string;
}
export interface PasswordData {
  code: number;
  password: string;
  message: string;
}
export interface LogoutData {
  response: string;
  refresh: string;
  access: string;
}
