<mat-card class="welcomPopup archive_main_card bg-color-transp back_card_background_clr">
  <!-- <span class="close-dialog" (click)="close()">X</span> -->
  <mat-card-content>
    <div class="row welcom-content">
      <div class="col-lg-6 col-md-6 col-sm-12 welcom-content-desc">
        <p>Tour MeaningSphere's carefully-crafted experiences of <strong>connection and reflection.</strong></p>
        <span class="span-block-center mt-15 w-100 mt-30 mb-30">
          <iframe width="100%" height="315" src="https://player.vimeo.com/video/744743998?h=5efb84815a" title="Meaning Sphere Welcome" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </span>
        <div class="welcome-buttons">
        <button (click)="start()" type="button" class="btn canceled_btn">
          Finish: Embark on your Adventure!
        </button>
        <button (click)="back()" type="button" class="btn  ml-10">
          Back
        </button>
      </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 welcom-content-bg">
        <img src="./assets/images/welcome2.jpg" id="dropdownMenuButton" alt="" srcset="" />
      </div>
    </div>
  </mat-card-content>
</mat-card>