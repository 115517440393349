import { message_properties } from './../../../../../environments/message_properties';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from './../../../../service/notification.service';
import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserCommunityWithdrawPopupComponent } from '../user-community-withdraw-popup/user-community-withdraw-popup.component';
import { CommunitiesService } from './../../../../service/communities.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment-timezone';
import { TimezoneService } from './../../../../service/timezone.service';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-user-my-community-details-popup',
  templateUrl: './user-my-community-details-popup.component.html',
  styleUrls: [
    './user-my-community-details-popup.component.scss',
    '../user-community/user-community.component.scss',
  ],
})
export class UserMyCommunityDetailsPopupComponent implements OnInit, OnDestroy {
  circleData: any = {};
  communityId: number;
  is_member_present_in_this_group: number;
  loggedInUserId: number;
  upcomingDialogListUnderCircleDatas = [];
  upcoming_circle_count: number;
  group_role: number;
  current_timezone: string;
  topicArr: string[];
  topic_count: number;
  groupEventForm: UntypedFormGroup;
  group_name: string;
  group_description: string;
  rating: any;
  image_path: string =
    '../../../../../assets/media/default/community_default.png';
  public from_date: moment.Moment;
  groupJoinSubscription: Subscription;
  memberPresentSubscription: Subscription;
  groupDetailSubscription: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserMyCommunityDetailsPopupComponent>,
    private communitiesService: CommunitiesService,
    private cdRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private router: Router,
    private dialog: MatDialog,
    private toastr: NotificationService,
    public sanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder,
    private timeZoneService: TimezoneService,
    private accountService: AccountsService
  ) {
    this.communityId = data.communityId;
    this.circleData = data.circleData;
    this.group_name = data.group_name;
    this.group_description = data.group_description;
    this.rating = data.rating;
    this.image_path =
      data.image_path !== ''
        ? data.image_path
        : '../../../../../assets/media/default/community_default.png';
  }

  ngOnInit(): void {
    this.loggedInUserId = this.accountService.muser;
    this.groupEventForm = this.formBuilder.group({
      groupId: new UntypedFormControl(this.communityId),
      eventType: new UntypedFormControl(2480),
      past_or_upcoming: new UntypedFormControl(0), // 0 = scheduled 1= completed 2=canceled
      limit: new UntypedFormControl(0),
      loggedInUser: new UntypedFormControl(this.loggedInUserId),
      page: 1,
    });
    this.communitiesService.refreshAfterJoin.subscribe((data) => {
      this.getCirclesDetail();
    });
    this.getCirclesDetail();
    this.check_logged_in_user_present_in_this_group();
  }

  check_logged_in_user_present_in_this_group() {
    this.memberPresentSubscription = this.communitiesService
      .checkLoggedInUserPresentInThisGroup(
        this.loggedInUserId,
        this.communityId
      )
      .subscribe((data) => {
        this.group_role = data.group_role;
        this.cdRef.detectChanges();
      });
  }

  getCirclesDetail() {
    this.spinner.show();
    let userId = this.accountService.muser;
    const sendData = {
      groupId: this.communityId,
      user: userId,
      map_for: 2270,
      mapped_object_type: 2623,
    };
    this.groupDetailSubscription = this.communitiesService
      .getSingleGroupPopupDetails(sendData)
      .subscribe((response) => {
        let responseData: any = response;
        this.circleData = responseData;
        this.is_member_present_in_this_group = this.circleData.is_member_present_in_this_group;
        this.topicArr = this.circleData.communityTopics[0];
        this.topic_count = this.circleData.communityTopics[1];
        this.cdRef.detectChanges();
        this.spinner.hide();
      });
  }

  counter(length: number) {
    if (!isNaN(length) && typeof length !== 'undefined') {
      return new Array(length);
    }
  }
  memberJoinCommunity() {
    let userId = this.accountService.muser;
    let dataObject = {
      visibility_type: this.circleData.visibility_type,
      active: 1,
      group_role: 1158,
      creation_date: this.circleData.creation_date,
      group: this.communityId,
      member: userId,
      creating_member: this.circleData.creating_member,
    };
    this.spinner.show();
    this.groupJoinSubscription = this.communitiesService
      .joinGroup(dataObject)
      .subscribe((response) => {
        let responseData: any = response;
        this.spinner.hide();
        if (responseData.status === 201) {
          this.dialogRef.close();
          const helper = new JwtHelperService();
          const jit_from_current_user = helper.decodeToken(
            this.accountService.token
          ).jti;
          const final_string_send = btoa(
            this.communityId +
              ']||||[' +
              'false' +
              ']||||[' +
              jit_from_current_user
          );
          this.router.navigate([
            'meaning-communities/my-community',
            final_string_send,
          ]);
          this.toastr.showSuccess(
            message_properties.JOIN_COMMUNITY_FROM_CLIENT_SIDE,
            ''
          );
          this.getCirclesDetail();
        }
      });
    this.spinner.hide();
  }
  withDrawCommunity() {
    const dialogRef = this.dialog.open(UserCommunityWithdrawPopupComponent, {
      width: '30%',
      data: {
        communityId: this.communityId,
      },
    });
    this.dialogRef.close();
  }
  dateInUserTimezome(datetime: string) {
    this.from_date = moment(datetime).utc();
    this.timeZoneService.setTenantTimeZone(this.current_timezone);
    return this.timeZoneService.utcToTenantString(this.from_date, 'LLL');
  }
  ngOnDestroy(): void {
    if (this.groupJoinSubscription) {
      this.groupJoinSubscription.unsubscribe();
    }
    if (this.memberPresentSubscription) {
      this.memberPresentSubscription.unsubscribe();
    }
    if (this.groupDetailSubscription) {
      this.groupDetailSubscription.unsubscribe();
    }
  }
}
