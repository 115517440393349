<div class="kt-header__topbar" *ngIf="isLoggedInUser">
  <div class="" style="display: flex; align-items: center">
    <div class="row">
      <div class="col-sm-8"></div>
      <div class="col-sm-4 pos_edit">
        <!--cart-->
        <div
          class="dropdown"
          [ngClass]="{ show: shwMenu5 }"
          *ngIf="isLoggedInUser"
        >
          <img
            src="./assets/media/header_icon/cart.png"
            id="dropdownMenuButton"
            class="top-bar-marg"
            alt=""
            srcset=""
            *ngIf="isLoggedInUser"
            (click)="loadMenu5()"
            title="Cart"
          />
          <span class="notofi" *ngIf="isLoggedInUser && my_cart_count>0">{{ my_cart_count }}</span>
          <div
            class="new-dropdown-menu notifications notifications-cart topbar-dropdown-bg-color"
            *ngIf="shwMenu5"
            [ngClass]="{ show: shwMenu5 }"
          >
            <div class="new-arrow"></div>
            <div class="">
              <div class="row">
                <span class="profile_user">My Cart</span>
                <button
                  mat-button
                  (click)="loadMenu5()"
                  class="close-dialog-small"
                >
                  X
                </button>
              </div>
              <hr />
              <kt-guide-discussion-cart
              (closeCartpopUp)="closeCartpopUp($event)"
              [myCartPopUp]="shwMenu5"
                style="position: static"
              ></kt-guide-discussion-cart>
            </div>
          </div>
        </div>
        <!--Event list-->
        <div
          class="dropdown"
          [ngClass]="{ show: shwMenu6 }"
          *ngIf="isLoggedInUser"
        >
          <img
            src="./assets/media/header_icon/event.png"
            id="dropdownMenuButton"
            class="top-bar-marg"
            alt=""
            srcset=""
            *ngIf="isLoggedInUser"
            (click)="loadMenu6()"
            title="Event"
          />
          <span class="notofi" *ngIf="isLoggedInUser && scheduled_discussions_count>0">{{scheduled_discussions_count}}</span>
          <div
            class="new-dropdown-menu notifications notifications-event topbar-dropdown-bg-color"
            *ngIf="shwMenu6"
            [ngClass]="{ show: shwMenu6 }"
          >
            <div class="new-arrow"></div>
            <div class="">
              <div class="row">
                <span class="profile_user">Scheduled Discussions</span>

                <button
                  mat-button
                  (click)="loadMenu6()"
                  class="close-dialog-small"
                >
                  X
                </button>
              </div>
              <hr />
              <kt-guide-discussion-event
                style="position: static"
              ></kt-guide-discussion-event>
            </div>
          </div>
        </div>
        <!-- messages -->
        <div class="dropdown" [ngClass]="{ show: shwMenu4 }">
          <img
            src="./assets/media/header_icon/message.png"
            id="dropdownMenuButton"
            class="top-bar-marg"
            alt=""
            srcset=""
            *ngIf="isLoggedInUser"
            (click)="loadMenu4()"
            title="Messages"
          />

          <span class="notofi" *ngIf="isLoggedInUser && unreadMessageCount > 0">{{
            unreadMessageCount
          }}</span>

          <div
            class="new-dropdown-menu notifications messages topbar-dropdown-bg-color"
            *ngIf="shwMenu4"
            [ngClass]="{ show: shwMenu4 }"
          >
            <div class="new-arrow"></div>
            <div class="">
              <div class="row d-flex justify-content-between">
                <button
                  class="backButton"
                  (click)="backToMessages()"
                  *ngIf="!messagesScreen"
                >
                  &lt;
                </button>
                <span class="profile_user"> Messages</span>
                <table>
                  <tr>
                    <td>
                      <span
                        *ngIf="messagesScreen"
                        class="spa-btn-change float-right spa-btn-change_new_img img18"
                        (click)="createMessage()"
                      >
                        <img
                          src="./assets/media/icon/AddWhite@2x.png"
                          title="Create New Message"
                        />
                      </span>
                    </td>
                    <td>
                      <button
                        mat-button
                        (click)="loadMenu4()"
                        class="close-dialog-small"
                      >
                        X
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
              <hr class="hr_line" style="top: 0px" />
              <kt-message-list
                (getData)="getMessageData($event)"
                style="position: static"
                *ngIf="messagesScreen"
              >
              </kt-message-list>
              <kt-message-details
                [childProp]="messagesID"
                (childPropChange)="fromChild($event)"
                style="position: static"
                *ngIf="!messagesScreen"
              ></kt-message-details>
            </div>
          </div>
        </div>
        <!-- Notifications -->
        <div
          class="dropdown"
          [ngClass]="{ show: shwMenu3 }"
          *ngIf="isLoggedInUser"
        >
          <img
            src="./assets/media/header_icon/notification.png"
            id="dropdownMenuButton"
            class="top-bar-marg"
            alt=""
            srcset=""
            *ngIf="isLoggedInUser"
            (click)="loadMenu3()"
            title="Notifications"
          />
          <span class="notofi" *ngIf="isLoggedInUser && unreadNotificationCount>'0'">{{
            unreadNotificationCount
          }}</span>
          <div
            class="new-dropdown-menu notifications topbar-dropdown-bg-color"
            *ngIf="shwMenu3"
            [ngClass]="{ show: shwMenu3 }"
          >
            <div class="new-arrow"></div>
            <div class="">
              <div class="row">
                <span class="profile_user"> Notifications</span>
                <button
                  mat-button
                  (click)="loadMenu3()"
                  class="close-dialog-small"
                >
                  X
                </button>
              </div>
              <hr />
              <kt-notifications style="position: static"></kt-notifications>
            </div>
          </div>
        </div>
        <!-- users -->
        <div class="dropdown" *ngIf="!hasRoute('/login')">
          <div style="position: relative">
            <img
              src="./assets/media/header_icon/profile.png"
              id="dropdownMenuButton"
              class="top-bar-marg"
              alt=""
              srcset=""
              (click)="loadMenu()"
              title="{{ isLoggedInUser ? 'My Portrait' : 'Login' }}"
            />
          </div>
          <div
            class="new-dropdown-menu topbar-dropdown-bg-color"
            *ngIf="showprofile && shwMenu"
          >
            <div class="new-arrow"></div>
            <div class="row">
              <button
                mat-button
                (click)="loadMenu()"
                class="close-dialog-small"
              >
                X
              </button>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <table width="100%" border="0">
                  <tr>
                    <td align="center">
                      <img [src]="profile_path" class="profile_img w-124-title" />
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="center"> <h1 class="fs-20">{{full_name}}</h1></td>
                  </tr>
                </table>
                
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 mt-4">
                <div class="title_name1" (click)="loadProfile()">Portrait</div>
                <div class="title_name1" (click)="loadSetting()">Settings</div>
                <hr class="hr_line" style="top: 0px" />
                <div class="title_name1" (click)="logoutusers()">Sign Out</div>
              </div>
            </div>
          </div>
        </div>
        <!-- info -->
        <div class="dropdown" [ngClass]="{ show: shwMenu2 }">
          <img
            src="./assets/media/header_icon/about.png"
            id="dropdownMenuButton"
            class="top-bar-marg top-bar-marg-last"
            alt=""
            srcset=""
            (click)="infoDetailsOpen()"
            title="MeaningSphere Tools"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end:: Header Topbar -->
