import { ViewProfileComponent } from './../../../admin-page/view-profile/view-profile.component';
import { MatDialog } from '@angular/material/dialog';
import { MentorService } from '../../../../../service/mentor.service';
import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountsService } from './../../../../../service/accounts.service';

@Component({
  selector: 'kt-currnt-explorer',
  templateUrl: './currnt-explorer.component.html',
  styleUrls: ['./currnt-explorer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrntExplorerComponent implements OnInit, OnDestroy {
  currentMentees = [];
  loggedinUserid: number;
  menteeData = [];
  pastMentees = [];
  count: number;
  currentExplorerForm: UntypedFormGroup;
  throttle = 300;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;
  currentSubscription: Subscription;
  page: number = 2;
  constructor(
    private route: Router,
    private _cdr: ChangeDetectorRef,
    private mentorService: MentorService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private accountService: AccountsService
  ) {}
  ngOnInit(): void {
    this.loggedinUserid = this.accountService.muser;
    this.currentExplorerForm = this.formBuilder.group({
      member: new UntypedFormControl(this.loggedinUserid),
      status: new UntypedFormControl(1),
      page: 1,
    });
    this.getAllCurrentMentee();
  }

  returnImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default_rectangle.png';
    }
  }

  showSharedSpace(
    member_relationship_id,
    menteeId,
    full_name,
    dp,
    status,
    start_date,
    city,
    country,
    isPastGuide
  ) {
    const helper = new JwtHelperService();
    const param_string = `${member_relationship_id}]||[${menteeId}]||[${full_name}]||[${dp}]||[${status}]||[${start_date}]||[${city}]||[${country}]||[${isPastGuide}]||[${
      helper.decodeToken(this.accountService.token).jti
    }`;
    const param_data = window.btoa(param_string);
    this.route.navigate([
      'discover',
      'my-guided-explorers',
      'shared-space',
      param_data,
    ]);
  }

  viewProfile(member_id, connection_data, member_data) {
    let dialogRef = this.dialog.open(ViewProfileComponent, {
      width: '80%',
      disableClose: true,
      data: {
        member_id: member_id,
        connection_data: connection_data,
        member_data: member_data,
        click_from: 'guide_search',
      },
    });
  }
  pushValue(ev?) {
    if (this.count !== this.menteeData.length) {
      this.currentExplorerForm.patchValue({
        page: this.page,
      });
      this.getAllCurrentMentee();
      this.page++;
    }
  }
  getAllCurrentMentee(params = 0) {
    this.spinner.show();
    if (params === 1) {
      this.menteeData = [];
      this.page = 2;
      this.currentExplorerForm.patchValue({
        page: 1,
      });
    }
    const Formdata = this.currentExplorerForm.value;
    this.currentSubscription = this.mentorService
      .getMenteeDataByPost(Formdata)
      .subscribe((data) => {
        this.menteeData = [
          ...new Map(
            this.menteeData
              .concat(data.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.count = data.count;
        this.spinner.hide();
        this._cdr.detectChanges();
      });
  }
  ngOnDestroy() {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }
  }
}
function jwt_decode(token: any) {
  throw new Error('Function not implemented.');
}
