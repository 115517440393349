import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private QuizResult = new BehaviorSubject(null);
  private QuizReQuizQuestion = new BehaviorSubject(null);
  QuizQuestionObject = this.QuizReQuizQuestion.asObservable();
  QuizResultObject = this.QuizResult.asObservable();
  private __result_data: any;
  private __mentee_name: any;
  constructor() {}

  getquestion(value: string) {
    return this.QuizReQuizQuestion.next(value);
  }
  SetSaveResult(value: string) {
    return this.QuizResult.next(value);
  }

  setResultData(val) {
    this.__result_data = val;
  }

  getResultData() {
    return this.__result_data;
  }

  setMenteeName(val) {
    this.__mentee_name = val;
  }

  getMenteeName() {
    return this.__mentee_name;
  }
}
