import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  FormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { MeaningNetworkService } from '../../../../../service/meaning-network.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../../../../service/notification.service';
import { message_properties } from '../../../../../../environments/message_properties';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AccountsService } from './../../../../../service/accounts.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-request-connection',
  templateUrl: './request-connection.component.html',
  styleUrls: ['./request-connection.component.scss'],
})
export class RequestConnectionComponent implements OnInit, OnDestroy {
  requestForm: UntypedFormGroup;
  connected_member: number;
  member: number;
  message: string = '';
  connectionData = [];

  resonate_check: boolean = false;
  inspired_check: boolean = false;
  care_check: boolean = false;
  career_check: boolean = false;
  other_check: boolean = false;
  valueArr = [];
  searchSubscription: Subscription;
  messageSubscription: Subscription;
  constructor(
    private searchService: MeaningNetworkService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<RequestConnectionComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: NotificationService,
    private accountService: AccountsService
  ) {
    this.connected_member = data.connected_member;
    this.message = data.message;
  }

  ngOnInit(): void {
    this.member = this.accountService.muser;

    this.requestForm = new UntypedFormGroup({
      connected_member: new UntypedFormControl(this.connected_member),
      connected_date: new UntypedFormControl(''),
      connection_status: new UntypedFormControl(2296),
      company_id: new UntypedFormControl(1),
      visibility_type: new UntypedFormControl(1953),
      active: new UntypedFormControl(1),
      resonate: new UntypedFormControl(this.resonate_check),
      inspired: new UntypedFormControl(this.inspired_check),
      care: new UntypedFormControl(this.care_check),
      career: new UntypedFormControl(this.career_check),
      other: new UntypedFormControl(this.other_check),
      request_message: new UntypedFormControl(
        { value: '', disabled: this.message !== '' ? true : false },
        [Validators.required]
      ),
      member: new UntypedFormControl(this.member),
      check_box: new UntypedFormControl('', [Validators.required]),
    });

    if (this.message !== '') {
      this.sendMessageDetails();
    }
  }
  onSubmit(ngForm) {
    this.spinner.show();
    this.searchSubscription = this.searchService
      .requestConnection(ngForm)
      .subscribe(
        (success) => {
          this.toastr.showSuccess(
            message_properties.MY_CONNECTIONS_SUCCESS,
            ''
          );
          this.requestForm.reset();
          this.spinner.hide();
          this.dialogRef.close();
        },
        (error) => {
          this.toastr.showError(message_properties.MY_CONNECTIONS_ERROR, '');
          this.spinner.hide();
        }
      );
  }

  sendMessageDetails() {
    this.messageSubscription = this.searchService
      .getMessageDetails(this.member, this.connected_member)
      .subscribe((data) => {
        this.connectionData = JSON.parse(JSON.stringify(data.results));
        this.requestForm.patchValue({
          request_message: this.connectionData[0]['request_message'],
          resonate: this.connectionData[0]['resonate_indicator'] ? true : false,
          inspired: this.connectionData[0]['inspired_indicator'] ? true : false,
          care: this.connectionData[0]['care_indicator'] ? true : false,
          career: this.connectionData[0]['career_path_indicator']
            ? true
            : false,
          other: this.connectionData[0]['other_indicator'] ? true : false,
        });
      });
  }

  requestResign(ob: MatCheckboxChange) {
    if (ob.checked) {
      this.valueArr.push(ob.source.value);
    } else {
      this.removeFromArray(this.valueArr, ob.source.value);
    }
    if (this.valueArr.length > 0) {
      this.requestForm.get('check_box').clearValidators();
      this.requestForm.get('check_box').updateValueAndValidity();
    } else {
      this.requestForm.get('check_box').setValidators([Validators.required]);
      this.requestForm.get('check_box').updateValueAndValidity();
    }
  }

  removeFromArray(arr, checked_value = '') {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  }
  ngOnDestroy(): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }
}
