<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1>Update {{ title }}</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form
    [formGroup]="dialogForm"
    (ngSubmit)="createDialog(dialogForm.value)"
    novalidate
    enctype="multipart/form-data"
    autocomplete="off"
  >
    <div class="">
      <div class="row">
        <div class="col-sm-8">
          <p>
            Requesting Explorer:
            <img
              class="img-fluid curator-createimg"
              [src]="returnImage(requestor_dp)"
              width="20px"
              height="20px"
              style="border-radius: 10px"
            />
            <label style="cursor: pointer">&nbsp;&nbsp;{{ requestor }}</label>
          </p>
        </div>
        <div class="col-sm-4">
          <p class="session-type-para">
            Session Type:
            <select
              formControlName="event_type"
              class="general_select guid-select"
            >
              <option disabled selected value style="display:none"></option>
              <option value="2548">Intro Session</option>
            </select>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <div class="row">
            <div class="col-sm-11">
              <mat-form-field style="width: 100%; color: white">
                <label> </label>
                <mat-label class="label-time">Discussion on:</mat-label>
                <input
                  matInput
                  [matDatepicker]="start_date"
                  (dateChange)="onChangeDate($event)"
                  formControlName="start_date"
                  [min]="myDate"
                  style="height: 24px;"
                />
               
              </mat-form-field>
            </div>
            <div class="col-sm-1">
              <mat-datepicker-toggle matSuffix [for]="start_date" class="date-pick-tog">
              </mat-datepicker-toggle>
              <mat-datepicker #start_date></mat-datepicker>
            </div>
          </div>
         
        </div>
        <div class="col-sm-3">
          <div class="mat-form-field" style="width: 100%; color: white">
            <label>Scheduled Time From: </label>
            <ngx-timepicker-field
              [toggleIcon]="icon"
              [min]="minTime"
              formControlName="timeFrom"
              (click)="onChangeFrom($event)"
              (timeChanged)="onChangeFrom($event)"
            ></ngx-timepicker-field>
            <ng-template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="30px"
                height="30px"
              >
                <path
                  d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,16H7.995 C7.445,16,7,15.555,7,15.005v-0.011C7,14.445,7.445,14,7.995,14H14V5.995C14,5.445,14.445,5,14.995,5h0.011 C15.555,5,16,5.445,16,5.995V16z"
                />
              </svg>
            </ng-template>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="mat-form-field" style="width: 100%; color: white">
            <label>Scheduled Time To: </label>
            <ngx-timepicker-field
              [toggleIcon]="icon"
              [min]="minTime"
              formControlName="timeTo"
              [disabled]="toTime"
            >
            </ngx-timepicker-field>
            <ng-template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="30px"
                height="30px"
              >
                <path
                  d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,16H7.995 C7.445,16,7,15.555,7,15.005v-0.011C7,14.445,7.445,14,7.995,14H14V5.995C14,5.445,14.445,5,14.995,5h0.011 C15.555,5,16,5.445,16,5.995V16z"
                />
              </svg>
            </ng-template>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="mat-form-field" style="width: 100%; color: white">
            <label class="label-time">Actual Duration: </label>
            <input
              class="input-time"
              type="number"
              maxlength="3"
              matInput
              formControlName="actual_duration"
              style=""
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <mat-form-field style="width: 100%; color: #ffffff">
            <mat-label>Notes</mat-label>
            <textarea
              matInput
              autocomplete="off"
              maxlength="1024"
              oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&!@#$%=*-+?() ]|^ /g,'')"
              formControlName="description"
              rows="4"
            >
            </textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 tr">
        <button
          [disabled]="!dialogForm.valid || saveButton"
          class="btn begin_quiz ml-2"
          type="submit"
        >
          Save
        </button>

        <button
          mat-dialog-close
          class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
