<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1>Buy Meaningful Work Survey Experience</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form
    [formGroup]="buysurveyForm"
    (ngSubmit)="purchaseSurvey(buysurveyForm.value)"
    novalidate
    enctype="multipart/form-data"
    autocomplete="off"
  >
    <div class="row">
      <div class="col-sm-9">
        <h2 class="survey_head_buy">Meaningful Work Survey Experience</h2>
      </div>
      <div class="col-sm-3">
        <p>US ${{survey_amount | number: '1.2-2'}}</p>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-sm-9 col-md-9 col-lg-9">
        <p class="survey_para_data">
          {{description}}
        </p>
      </div>
    </div>
    <br />

    <hr />
    <div class="row">
      <div class="col-sm-12 tr mt-5">
        <button
          class="btn begin_quiz ml-2"
          type="submit"
        >
          Add To Cart
        </button>
        <button
          mat-dialog-close
          class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
