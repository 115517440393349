import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'kt-surveyreport6',
  templateUrl: './surveyreport6.component.html',
  styleUrls: ['./surveyreport6.component.scss'],
})
export class Surveyreport6Component implements OnInit {
  data1: any;
  report_6_body: any;
  myChart: [];
  reportStr: string;
  reportData = [];
  horizontal_barchart_1_1: number;
  horizontal_barchart_1_2: number;
  @Input() memberId;
  @Input() currentSelectedSurvey;
  @Input() json_report;
  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.reportData = JSON.parse(this.json_report);
    this.horizontal_barchart_1_1 = this.reportData['horizontal_barchart_1_1'];
    this.horizontal_barchart_1_2 = this.reportData['horizontal_barchart_1_2'];
    let barchart_ref1 = this.elementRef.nativeElement.querySelector(
      `#barchart_hor`
    );
    let __barchart_hor1 = new Chart(barchart_ref1, {
      type: 'horizontalBar',
      data: {
        labels: ['Being-Doing', 'Self-Others'],
        fontColor: '#1f1547',
        datasets: [
          {
            data: [this.horizontal_barchart_1_1, this.horizontal_barchart_1_2],
            backgroundColor: [' #13B0C5', ' #13B0C5'],
            fontColor: '#1f1547',
            borderWidth: 0,
          },
        ],
      },
      fontColor: '#1f1547',
      options: {
        tooltips: {},
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 5,
                min: 0,
                fontSize: 13,
                fontColor: '#1f1547',
                fontFamily: 'lato',
                stepSize: 1,
              },
              gridLines: {
                display: false,
                fontColor: '#1f1547',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,

                stepSize: 1,
                fontSize: 13,
                fontFamily: 'lato',
                fontColor: '#1f1547',
              },
              gridLines: {
                display: false,
                fontColor: '#1f1547',
              },
            },
          ],
          fontColor: '#1f1547',
        },
        legend: {
          display: false,
        },
      },
    });

    __barchart_hor1.options.tooltips.enabled = true;
  }
}
