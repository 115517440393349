<!-- begin:: Brand -->
<div class="kt-header__brand kt-grid__item" id="kt_header_brand">
  <div class="kt-header__brand-logo">
    <a [routerLink]="'/meaning-network/my-feed'">
      <img
        width="255"
        height="51"
        alt="logo"
        [src]="'../../../../assets/media/logos/meaningspherelogo.png'"
        src=""
        class="header_logo"
      />
    </a>
  </div>
</div>
<!-- end:: Brand -->
