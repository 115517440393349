<!-- begin:: Footer -->
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
  <div class="kt-container" [ngClass]="{ 'kt-container--fluid': fluid }">
    <div class="kt-footer__wrapper">
      <div class="kt-footer__copyright">
        <div style="color: white; font-size: 0.8rem">
          <div class="text-align-center">
            <a style="font-size: 0.5rem"
              href="https://support.meaningsphere.com/hc/en-us/articles/9485738538388-What-are-your-Terms-of-Use-"
              class="pp_underline" target="__blank">Terms of Use
            </a>
            &nbsp;| &nbsp;
            <a style="font-size: 0.5rem"
              href="https://support.meaningsphere.com/hc/en-us/articles/9485678228372-What-is-your-Code-of-Conduct-"
              class="pp_underline" target="__blank">Code of Conduct
            </a>
            &nbsp;| &nbsp;
            <a style="font-size: 0.5rem" href="https://support.meaningsphere.com/hc/en-us/articles/8446382859028-What-is-your-Privacy-Policy-" class="pp_underline"
              target="__blank">Privacy Policy
            </a>
          </div>
          <div class="text-center mt-5">&nbsp;&copy; {{ today | date: "yyyy" }} MeaningSphere LLC
            <span *ngIf="adminIndicator=='true'">
              <span tooltipClass="tooltip_design" theme="light" tooltip="{{ version_details }}" placement="right"
                showDelay="500" animation="fade" noArrow="false">
                <span class="icon-info">
                  <mat-icon class="info_material_icon">info</mat-icon>
                </span>
              </span>
            </span>
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end:: Footer -->