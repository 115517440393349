<div class="bg-cart">
  <ng-container *ngIf="count > 0">
    <cdk-virtual-scroll-viewport
      itemSize="20"
      minBufferPx="200"
      maxBufferPx="400"
      class="scroll_class_list scroll-gap"
    >
      <ng-container
        *cdkVirtualFor="let mydata of myCartItemsData; templateCacheSize: 0"
      >
        <div class="community-box">
          <table width="100%">
            <tr>
              <td align="left">
                <b *ngIf="mydata.catalog_item != 4"
                  >Guide Discussions: {{ mydata.item_name }}</b
                >
                <b *ngIf="mydata.catalog_item == 4">MWS Survey Experience</b>
              </td>
              <td class="discussion-cost" align="right">
                US $ &nbsp;{{ mydata.item_total_amount | number: "1.2-2" }}
              </td>
            </tr>
            <tr>
              <td align="left">
                <span *ngIf="mydata.catalog_item != 4">
                  {{ mydata.item_quantity }}
                  {{ mydata.catalog_item == 2 ? "Minutes" : "Discussion(s)" }}
                </span>
                <span *ngIf="mydata.catalog_item == 4">
                  {{ mydata.item_quantity }}
                  {{ survey_str }}
                </span>
              </td>
              <td align="right">
                <i
                  class="fa fa-trash"
                  (click)="removeItemFromCart(mydata.id)"
                  title="Remove"
                ></i>
              </td>
            </tr>
          </table>
        </div>
      </ng-container>
    </cdk-virtual-scroll-viewport>
    <div class="community-box">
      <hr />
      <table width="100%">
        <tr>
          <td align="left"><b>Subtotal</b></td>
          <td align="right">US $ &nbsp;{{ total_amount | number: "1.2-2" }}</td>
        </tr>
      </table>
    </div>
    <br />
  </ng-container>

  <ng-container *ngIf="count == 0">
    <p>No items found in your cart!</p>
  </ng-container>
</div>

<div class="community-box" *ngIf="count > 0">
  <table width="100%">
    <tr>
      <td align="right">
        <!-- <button
          class="btn begin_quiz ml-2 mt-5"
          type="button"
          (click)="paymentIncartItems()"
        >
          PAY NOW
        </button> -->

        <button
          class="btn begin_quiz ml-2 mt-5"
          type="button"
          (click)="proceedToCheckout()"
        >
          Proceed to Checkout
        </button>
      </td>
    </tr>
  </table>
</div>
