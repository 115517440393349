import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CityStateCountryService } from './../../../service/city-state-country.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as countries from './../../../../assets/static-data/country.json';
@Component({
  selector: 'kt-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss'],
})
export class SearchDropdownComponent implements OnInit {
  @Input() fieldType;
  @Input() fieldLable = '';
  @Input() disabled = false;
  @Input() fieldLableDisplay = true;
  @Input() mode;
  @Input() countrySelected = false;
  @Output() sendbackresponse = new EventEmitter<string>();

  countryList: any = (countries as any).default;
  countryCount: any;
  countryId: any;
  private _ngxDefault;
  CountrySlect = new UntypedFormGroup({
    selectedCountry: new UntypedFormControl('', []),
  });

  constructor(
    private _cdr: ChangeDetectorRef,
    private cityStateCountry: CityStateCountryService,
    private spinner: NgxSpinnerService
  ) {}
  ngOnInit(): void {
    if (this.mode === 'edit') {
      const idx = Number(localStorage.getItem('countryIdForEdit'));
      this._ngxDefault = this.countryList.filter(
        (country) => Number(country.id) === idx
      );
      this.CountrySlect.patchValue({
        selectedCountry: this._ngxDefault[0]?.id,
      });
      this.spinner.hide();
    }
  }
  countrylistSearch() {
    this.spinner.show();
    this.cityStateCountry.getAllCountryList().subscribe((response) => {
      this.countryList = response.results;
      this._cdr.detectChanges();
      this.spinner.hide();
    });
  }
  companylistSearch() {}
  sendResponse(ev) {
    this.sendbackresponse.emit(ev);
  }
}
