import { CompareSurveyReportComponent } from './../compare-survey-report/compare-survey-report.component';
import { SurveyService } from './../../../../service/survey.service';
import { NgForm } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  OnDestroy,
} from '@angular/core';
import * as moment from 'moment-timezone';
import { TimezoneService } from '../../../../service/timezone.service';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-compare-two-survey',
  templateUrl: './compare-two-survey.component.html',
  styleUrls: ['./compare-two-survey.component.scss'],
})
export class CompareTwoSurveyComponent implements OnInit, OnDestroy {
  participant: any;
  momData: {};
  currentSelectedSurvey: any;
  _datetime: any;
  compareForm: UntypedFormGroup;
  current_timezone: string;
  public from_date: moment.Moment;
  listSurveySubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<CompareTwoSurveyComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private surveyService: SurveyService,
    private _cdref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private timeZoneService: TimezoneService,
    private accountService: AccountsService
  ) {
    this.currentSelectedSurvey = data.currentSelectedSurvey;
    this._datetime = data._datetime;
  }
  ngOnInit(): void {
    this.current_timezone = localStorage.getItem('current_time_zone');

    this.participant = this.accountService.muser;
    this.getSurveyList(this.participant);
    this.compareForm = this.formBuilder.group({
      selected_survey: new UntypedFormControl('', [Validators.required]),
    });
  }
  getSurveyList(participant) {
    const data__ = {
      participant: participant,
      survey: 1,
      currentSelectedSurvey: this.currentSelectedSurvey,
    };
    this.listSurveySubscription = this.surveyService
      .compairSurveyList(data__)
      .subscribe((data) => {
        this.momData = data['results'];
        this._cdref.detectChanges();
      });
  }
  compareSurvey(ngForm: NgForm) {
    let dialogRef = this.dialog.open(CompareSurveyReportComponent, {
      width: '70%',
      disableClose: true,
      data: {
        currentSelectedSurvey: btoa(this.currentSelectedSurvey),
        currentDateTime: btoa(this._datetime),
        selectedSurvey: btoa(ngForm['selected_survey']),
      },
    });
  }
  dateInUserTimezome(datetime: string) {
    this.from_date = moment(datetime).utc();
    this.timeZoneService.setTenantTimeZone(this.current_timezone);
    return this.timeZoneService.utcToTenantString(this.from_date, 'LLL');
  }
  ngOnDestroy(): void {
    if (this.listSurveySubscription) {
      this.listSurveySubscription.unsubscribe();
    }
  }
}
