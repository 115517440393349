import { MentorService } from './../../../../service/mentor.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../../../service/notification.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  OnDestroy,
} from '@angular/core';
import * as moment from 'moment-timezone';
import { CuratorPageService } from '../../../../service/curator-page.service';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-marked-as-completed',
  templateUrl: './marked-as-completed.component.html',
  styleUrls: ['./marked-as-completed.component.scss'],
})
export class MarkedAsCompletedComponent implements OnInit, OnDestroy {
  markAsCompletedForm: UntypedFormGroup;
  menteeFullName: string;
  action: number;
  menteeDp: string;
  eventId: number;
  current_timezone: string;
  event_type: number;
  duration: number;
  start_date: string;
  loggedInUserId: number;
  menteeId: string;
  detailSubscription: Subscription;
  markSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<MarkedAsCompletedComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private toastr: NotificationService,
    private curatorService: CuratorPageService,
    private mentor_service: MentorService,
    private accountService: AccountsService
  ) {
    this.eventId = data.eventId;
    this.menteeId = data.menteeId;
    this.menteeDp =
      data.dp !== 'null' ? data.dp : './assets/media/users/default.png';
    this.menteeFullName = data.menteeFullName;
    this.action = data.action;
    this.event_type = data.event_type;
  }

  ngOnInit(): void {
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.loggedInUserId = this.accountService.muser;

    this.markAsCompletedForm = this.formBuilder.group({
      actual_duration: new UntypedFormControl('', [Validators.required]),
      active: new UntypedFormControl(6),
      eventId: new UntypedFormControl(this.eventId),
      guideId: this.loggedInUserId,
      exploterId: this.menteeId,
      event_type: this.event_type,
    });
    this.curatorService.all_status_change_refresh.subscribe((data) => {
      this.getEventDetailInDialog();
    });
    this.getEventDetailInDialog();
  }

  saveMarkAsCompleted(NgForm: NgForm) {
    this.markSubscription = this.mentor_service
      .discussionMarkedAsCompleted(NgForm)
      .subscribe(
        (data) => {
          if (data.status === 201) {
            this.dialogRef.close();
            this.spinner.hide();
            this.toastr.showSuccess(data.message, '');
          } else if (data.status === 204) {
            this.spinner.hide();
            this.toastr.showError(data.message, '');
          } else {
            this.spinner.hide();
            this.toastr.showError('Not Saved', '');
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.showError('Not Saved', '');
        }
      );
  }
  event_type_textReturn(event_type) {
    if (event_type === 2548) {
      return 'Get Acquainted';
    } else if (event_type === 2549) {
      return 'Be My Guide Session';
    } else if (event_type === 2733) {
      return 'Complimentary';
    } else if (event_type === 2550) {
      return 'General Discussion';
    } else if (event_type === 2554) {
      return 'Meaningful Work Survey';
    } else if (event_type === 2556) {
      return 'Mirror Reflection';
    } else {
      return 'DiY Discussion';
    }
  }
  getEventDetailInDialog() {
    this.detailSubscription = this.curatorService
      .getEventDetailInDialog(0, this.eventId)
      .subscribe((data) => {
        this.event_type = data.event_type;
        this.start_date = data.start_date;
        this.duration = data.time_duration;
        this.markAsCompletedForm.patchValue({ actual_duration: this.duration });
        this._cdr.detectChanges();
      });
  }
  event_status_textReturn(status) {
    if (status === 0) {
      return 'Requested';
    } else if (status === 1) {
      return 'Published';
    } else if (status === 2) {
      return 'Cancelled';
    } else if (status === 3) {
      return 'Scheduled';
    } else if (status === 4) {
      return 'Auth Cancelled';
    } else if (status === 5) {
      return 'Completed';
    } else if (status === 6) {
      return 'Marked Completed';
    } else {
      return 'Auto Completed';
    }
  }
  onlyTimeTimeZone(startDateTime) {
    return moment(startDateTime).tz(this.current_timezone).format('h:mm a');
  }
  plus_item(interval) {
    var interval_2 = Number(this.markAsCompletedForm.value['actual_duration']);
    if (interval_2 >= this.duration) {
      return;
    }
    this.markAsCompletedForm.patchValue({
      actual_duration: interval_2 + interval,
    });
  }
  minus_item(interval) {
    var interval_2 = Number(this.markAsCompletedForm.value['actual_duration']);
    if (interval_2 === 0) {
      return;
    }
    this.markAsCompletedForm.patchValue({
      actual_duration: interval_2 - interval,
    });
  }
  ngOnDestroy() {
    if (this.detailSubscription) {
      this.detailSubscription.unsubscribe();
    }
    if (this.markSubscription) {
      this.markSubscription.unsubscribe();
    }
  }
}
