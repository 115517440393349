import { NotificationService } from '../../../../service/notification.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { AuthService } from '../../../../service/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { message_properties } from '../../../../../environments/message_properties';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss'],
})
export class ForgotpasswordComponent implements OnInit, OnDestroy {
  passwordResetForm: UntypedFormGroup;
  email: any;
  user_name: string;
  message: string;
  passwordResetSubscription: Subscription;
  constructor(
    private loginService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: NotificationService
  ) {}

  ngOnInit() {
    this.email = atob(localStorage.getItem('xyze'));
    this.passwordResetForm = new UntypedFormGroup({
      username: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
    });
  }
  OnPwdResetSubmit(ngForm: NgForm) {
    this.spinner.show();
    return (this.passwordResetSubscription = this.loginService
      .passwordReset(ngForm)
      .subscribe(
        (res) => {
          if (res.code === 200) {
            this.spinner.hide();
            this.router.navigateByUrl('/home');
            this.toastr.showSuccess(
              message_properties.LOGIN_FORGOTPASSWORD_SUCCESS,
              ''
            );
          } else if (res.code === 301) {
            this.spinner.hide();

            this.toastr.showError(
              message_properties.LOGIN_FORGOTPASSWORD_ERROR1,
              ''
            );
          } else if (res.code === 204) {
            this.spinner.hide();

            this.toastr.showError(
              message_properties.LOGIN_FORGOTPASSWORD_ERROR1,
              ''
            );
          } else {
            this.spinner.hide();
            this.toastr.showError(
              message_properties.LOGIN_FORGOTPASSWORD_ERROR2,
              ''
            );
            this.message = '';
          }

          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
          this.toastr.showError(
            message_properties.LOGIN_FORGOTPASSWORD_ERROR3,
            ''
          );
        }
      ));
  }
  ngOnDestroy(): void {
    if (this.passwordResetSubscription) {
      this.passwordResetSubscription.unsubscribe();
    }
  }
}
