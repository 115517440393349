import { EachVedioComponent } from './../each-vedio/each-vedio.component';
import { SurveyService } from './../../../../service/survey.service';
import { SurveyResponseComponent } from './../survey-response/survey-response.component';
import { VideoListComponent } from '../video-list/video-list.component';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Optional,
  Inject,
  ChangeDetectorRef,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { jsPDF } from 'jspdf';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DatePipe } from '@angular/common';
import { font_lato } from '../../../../../environments/font';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-final-report',
  templateUrl: './final-report.component.html',
  styleUrls: ['./final-report.component.scss'],
})
export class FinalReportComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('target0') target0: ElementRef;
  @ViewChild('target1') target1: ElementRef;
  @ViewChild('target2') target2: ElementRef;
  @ViewChild('target3') target3: ElementRef;
  @ViewChild('target4') target4: ElementRef;
  memberId: any;
  reportStr: string;
  lastPageStringValue: any;
  reportData: any;
  currentSelectedSurvey: any;
  fromDia: string;
  tabIndex: any;
  togglecmp: boolean = false;

  toggle0: string;
  toggle1: string;
  toggle2: string;
  toggle3: string;
  toggle4: string;
  explorer_name: string;
  explorer_pic: string;
  guide_name: string;
  guide_pic: string;
  guide_avalable: boolean = false;
  loggedInUser: number;
  submission_date: string;
  json_report: string;

  integrity_with_self_label: string;
  expressing_full_potential_label: string;
  service_to_others_label: string;
  unity_with_others_label: string;
  target0Height: any;
  target1Height: any;
  target2Height: any;
  target3Height: any;
  target4Height: any;
  surveyDetailSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<FinalReportComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private surveyService: SurveyService,
    private datePipe: DatePipe
  ) {
    this.currentSelectedSurvey = data.currentSelectedSurvey;
    this.memberId = data.memberId;
    this.json_report = data.json_report1;
    this.reportStr = this.json_report;
  }

  ngOnInit(): void {
    this.tabIndex = 1;
    this.toggle1 = this.toggle2 = this.toggle3 = this.toggle4 = 'inactive';
    this.toggle0 = 'active';
    this.getSurveyDetailsWithPersonalDetails();
    this.reportData = JSON.parse(this.reportStr);
    this.lastPageStringValue = this.reportData['lastPageStringValue'];
    this.integrity_with_self_label = this.reportData[
      'integrity_with_self_label'
    ];
    this.expressing_full_potential_label = this.reportData[
      'expressing_full_potential_label'
    ];
    this.service_to_others_label = this.reportData['service_to_others_label'];
    this.unity_with_others_label = this.reportData['unity_with_others_label'];
  }
  getLatoFont() {
    var script = document.createElement('script');
    script.src = '../assets/js/Lato-Regular-normal.js';
    script.async = true;
    script.defer = true;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
  ngAfterViewInit(): void {
    this.target0Height = this.target0.nativeElement.offsetHeight;
    this.target1Height = this.target1.nativeElement.offsetHeight;
    this.target2Height = this.target2.nativeElement.offsetHeight;
    this.target3Height = this.target3.nativeElement.offsetHeight;
    this.target4Height = this.target4.nativeElement.offsetHeight;
    this.target4Height = this.target4.nativeElement.offsetHeight;

    var font = font_lato.FONT_LATO;

    var callAddFont = function () {
      this.addFileToVFS('Lato-Regular-normal.ttf', font);
      this.addFont('Lato-Regular-normal.ttf', 'Lato-Regular', 'normal');
    };
    jsPDF.API.events.push(['addFonts', callAddFont]);
  }

  getSurveyDetailsWithPersonalDetails() {
    this.surveyDetailSubscription = this.surveyService
      .getSurveyDetaislsWithPersonalDetails(
        this.currentSelectedSurvey,
        this.memberId
      )
      .subscribe((data) => {
        if (data['explorer_details'].length > 0) {
          this.explorer_name = data['explorer_details'][0]['full_name'];
          this.explorer_pic =
            data['explorer_details'][0]['picture_path'] ??
            '../assets/media/users/default.png';
        }
        if (data['guide_details'].length > 0) {
          this.guide_avalable = true;
          this.guide_name = data['guide_details'][0]['full_name'];
          this.guide_pic =
            data['guide_details'][0]['picture_path'] ??
            '../assets/media/users/default.png';
        }
        this.submission_date = data['submitted_at'];
        this.cdRef.detectChanges();
      });
  }

  printPdf() {
    Swal.fire({
      title: 'Please wait...',
      text: 'We are preparing your report for download.',
      icon: 'warning',
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.togglecmp = true;
    setTimeout(() => {
      this.convetToPDF();
      Swal.close();
    }, 500);
  }

  convetToPDF() {
    const pdf = new jsPDF('p', 'mm', 'a4', true);
    var margins = {
      top: 40,
      bottom: 60,
      left: 40,
      width: 522,
    };
    const addFooters = (pdf) => {
      const pageCount = pdf.internal.getNumberOfPages();

      pdf.setFont('lato');
      pdf.setFontSize(10);

      for (var i = 2; i <= pageCount; i++) {
        pdf.setPage(i);

        pdf.text(' ' + String(i - 1), pdf.internal.pageSize.width - 25, 290);
      }
    };
    pdf.setLineWidth(0.00625 / 2);

    var position = 15;
    var logo = new Image();
    logo.src = './assets/media/images/pdf_icon.png';
    pdf.setTextColor(0, 0, 0, 0.6);
    pdf.setFont('Lato-Regular', 'normal');

    var totalPagesExp = '{total_pages_count_string}';

    let full_name;

    const user_name = this.explorer_name;
    full_name = [[user_name]];

    if (this.tabIndex === 1) {
      var header_lbl = [['']];
      var column = [''];

      /////////////////////// Page 1 ///////////////////////////////////////

      var imgpage = new Image();
      imgpage.src = './assets/media/images/Cover_Page.png';
      pdf.addImage(imgpage, 'png', 0, 0, 260, 310, undefined, 'FAST');
      pdf.setTextColor(0, 0, 0, 0.9);
      pdf.setFont('Lato-Regular', 'normal');

      var img1 = new Image();
      img1.src = './assets/media/images/pdf_icon.png';
      pdf.addImage(img1, 'png', 25, 190, 100, 20, undefined, 'FAST');

      pdf.setFontSize(16);
      pdf.text('Meaningful Work Survey Report', 30, 225);

      pdf.setFontSize(12);
      pdf.text('Prepared for ' + full_name, 30, 235);

      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(10);
      pdf.text(
        String(this.datePipe.transform(this.submission_date, 'MMM d, y')),
        30,
        244
      );

      pdf.setLineWidth(0.1);

      pdf.addPage();
      ///////////////////// Page 2 /////////////////////////////

      pdf.setDrawColor(0, 0, 0, 0.6);
      pdf.line(
        pdf.internal.pageSize.width - 15,
        pdf.internal.pageSize.height - 14,
        15,
        pdf.internal.pageSize.height - 14
      );
      // Header

      pdf.setFontSize(11);
      pdf.text('Explorer: ' + full_name, pdf.internal.pageSize.width - 70, 15);

      if (logo) {
        pdf.addImage(logo, 'JPEG', 18, 10, 40, 8, undefined, 'FAST');
      }

      //////////////
      // Footer

      pdf.setFontSize(10);

      pdf.text(
        'Thank you for completing the Meaningful Work Survey. This PDF version of your Survey Report was generated ',
        18,
        30
      );
      pdf.text(
        'based on  the results that you viewed on the MeaningSphere website.',
        18,
        35
      );
      pdf.setFontSize(10);
      pdf.text(
        'This document provides you offline access to your Survey Report. However, there is no functionality embedded in ',
        18,
        41
      );
      pdf.text(
        'the report. In order to watch example videos or access your next steps, please view your report from the My ',
        18,
        46
      );
      pdf.text('Surveys section of the MeaningSphere website.', 18, 51);

      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(18);

      pdf.text('About Your Report', 18, 65),
        {
          maxWidth: 240,
        };

      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(10);
      pdf.text('In this Survey Report, you’ll see your results as', 18, 72),
        {
          maxWidth: 240,
        };
      pdf.text('they relate to the seven elements you were', 18, 77),
        {
          maxWidth: 240,
        };
      pdf.text('shown in the video introducing you to the Map', 18, 82),
        {
          maxWidth: 240,
        };

      pdf.text('of Meaning. These elements – four pathways', 18, 87),
        {
          maxWidth: 240,
        };

      pdf.text('and three tensions – provide a snapshot of how', 18, 92),
        {
          maxWidth: 240,
        };
      pdf.text('and where you currently find meaning at work;', 18, 97, {
        maxWidth: 240,
      });

      pdf.text('this is not a personality test – it doesn’t “type”', 18, 102),
        {
          maxWidth: 240,
        };
      pdf.text('you.', 18, 107),
        {
          maxWidth: 240,
        };
      pdf.text('Your Survey Report will help you answers', 18, 113),
        {
          maxWidth: 240,
        };
      pdf.text('questions, like do I:', 18, 118),
        {
          maxWidth: 240,
        };
      pdf.setFontSize(10);
      pdf.text('• Find meaning in my current work situation?', 23, 123),
        {
          maxWidth: 240,
        };
      pdf.text('Where am I gaining and losing energy?', 23, 128),
        {
          maxWidth: 240,
        };

      pdf.text('• Have a healthy tension between focusing on ', 23, 133),
        {
          maxWidth: 240,
        };
      pdf.text('“self” versus “others” and “being” versus ', 23, 138),
        {
          maxWidth: 240,
        };

      pdf.text('“doing”?', 23, 143),
        {
          maxWidth: 240,
        };

      pdf.text('• Balance what inspires me and the constraints ', 23, 148),
        {
          maxWidth: 240,
        };
      pdf.text('of my current reality?', 23, 153),
        {
          maxWidth: 240,
        };
      pdf.text('Once you have a basic understanding of your results,', 18, 160),
        {
          maxWidth: 240,
        };

      pdf.text('you can begin turning your insights into action', 18, 165),
        {
          maxWidth: 240,
        };

      pdf.text('and identify the next steps that are right for you.', 18, 170),
        {
          maxWidth: 240,
        };
      var img1 = new Image();
      img1.src = './assets/media/images/first_page_pdf.png';
      pdf.addImage(img1, 'png', 90, 60, 115, 98, undefined, 'FAST');
      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(10);
      pdf.text('© Dr Marjolein Lips-Wiersma', 125, 170);

      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(15);
      pdf.text('Your Personal Meaning Profile', 18, 190),
        {
          maxWidth: 440,
        };
      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(10);
      pdf.text(
        'Your Personal Meaning Profile (PMP) is calculated using your responses to the survey items that relate to the four',
        18,
        200
      );
      pdf.text(
        'pathways illustrated in the framework below. Each pathway represents a way you find meaning at work. Take a',
        18,
        205
      );
      pdf.text(
        'moment to review these survey items, and then look at your scores below. ',
        18,
        210
      );
      pdf.addPage();
      //// Page 3/////
      pdf.setDrawColor(0, 0, 0, 0.6);
      pdf.line(
        pdf.internal.pageSize.width - 15,
        pdf.internal.pageSize.height - 14,
        15,
        pdf.internal.pageSize.height - 14
      );
      // Header

      pdf.setFontSize(11);
      pdf.text('Explorer: ' + full_name, pdf.internal.pageSize.width - 70, 15);

      if (logo) {
        pdf.addImage(logo, 'JPEG', 18, 10, 40, 8, undefined, 'FAST');
      }

      var img2 = new Image();
      img2.src = './assets/media/pdf/second_page_pdf.png';

      pdf.addImage(img2, 'png', 5, 10, 200, 258, undefined, 'FAST');

      pdf.setFontSize(10);
      pdf.text(
        'The higher your score, the more meaning you are finding in that pathway. Your results include a comparison to the',
        18,
        271
      );
      pdf.text(
        'typical scores of everyone who has taken the survey (across cultures, countries, and occupations). ',
        18,
        277
      );
      pdf.addPage();
      //// Page 4 /////
      pdf.setDrawColor(0, 0, 0, 0.6);
      pdf.line(
        pdf.internal.pageSize.width - 15,
        pdf.internal.pageSize.height - 14,
        15,
        pdf.internal.pageSize.height - 14
      );
      // Header

      pdf.setFontSize(11);
      pdf.text('Explorer: ' + full_name, pdf.internal.pageSize.width - 70, 15);

      if (logo) {
        pdf.addImage(logo, 'JPEG', 18, 10, 40, 8, undefined, 'FAST');
      }

      pdf.setFontSize(8);
      pdf.text('Integrity with Self', 21, 30);
      pdf.text('Unity with Others', 69, 30);
      pdf.text('Expressing Full Potential', 108, 30);
      pdf.text('Service to Others', 161, 30);

      var img3 = new Image();
      img3.src = './assets/media/pdf/Q4.png';

      var img4 = new Image();
      img4.src = './assets/media/pdf/Q1.png';

      var img5 = new Image();
      img5.src = './assets/media/pdf/Q2.png';

      var img6 = new Image();
      img6.src = './assets/media/pdf/Q3.png';

      const my3pads = document.querySelectorAll(
        'h3[class="my-3 pad survey3_img_chartLatest"]'
      );
      if (img3) {
        pdf.setFont('Lato-Regular', 'normal');
        pdf.setFontSize(8);
        pdf.text(my3pads[3].textContent, 26, 35);
        pdf.addImage(img3, 'png', 42, 30, 13, 13, undefined, 'FAST');
      }

      if (img4) {
        pdf.setFont('Lato-Regular', 'normal');
        pdf.setFontSize(8);
        pdf.text(my3pads[0].textContent, 72, 35);
        pdf.addImage(img4, 'png', 85, 30, 13, 13, undefined, 'FAST');
      }

      if (img5) {
        pdf.setFont('Lato-Regular', 'normal');
        pdf.setFontSize(8);
        pdf.text(my3pads[2].textContent, 118, 35);
        pdf.addImage(img5, 'png', 133, 30, 13, 13, undefined, 'FAST');
      }

      if (img6) {
        pdf.setFont('Lato-Regular', 'normal');
        pdf.setFontSize(8);
        pdf.text(my3pads[1].textContent, 166, 35);
        pdf.addImage(img6, 'png', 180, 30, 13, 13, undefined, 'FAST');
      }
      const cenalis = document.querySelectorAll('div[class=""]');

      let body = [];

      var text_total = '.';

      for (var i = 0; i < cenalis.length; i++) {
        const text_new =
          cenalis[i].textContent.substring(
            0,
            cenalis[i].textContent.length - 12
          ) + '';
        text_total = text_total + text_new;
      }
      body.push([text_total]);

      var text_new = [
        [
          cenalis[0].textContent.substring(
            0,
            cenalis[0].textContent.length - 11
          ),
        ],
      ];

      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(8);

      pdf
        .setFont('Lato-Regular', 'normal')
        .text(this.integrity_with_self_label + 'th', 23, 40, {
          maxWidth: 15,
        });
      pdf.setFont('Lato-Regular', 'normal').text('percentile.', 22, 43, {
        maxWidth: 15,
      });
      var text_new = [
        [
          cenalis[1].textContent.substring(
            0,
            cenalis[1].textContent.length - 11
          ),
        ],
      ];

      pdf
        .setFont('Lato-Regular', 'normal')
        .text(this.unity_with_others_label + 'th', 70, 40, {
          maxWidth: 15,
        });
      pdf.setFont('Lato-Regular', 'normal').text('percentile.', 69, 43, {
        maxWidth: 15,
      });
      var text_new = [
        [
          cenalis[2].textContent.substring(
            0,
            cenalis[2].textContent.length - 11
          ),
        ],
      ];

      pdf
        .setFont('Lato-Regular', 'normal')
        .text(this.expressing_full_potential_label + 'th', 115, 40, {
          maxWidth: 15,
        });
      pdf.setFont('Lato-Regular', 'normal').text('percentile.', 114, 43, {
        maxWidth: 15,
      });
      var text_new = [
        [
          cenalis[3].textContent.substring(
            0,
            cenalis[3].textContent.length - 11
          ),
        ],
      ];

      pdf.text(
        cenalis[3].textContent.substring(0, cenalis[3].textContent.length - 11),
        157,
        160,
        {
          maxWidth: 15,
        }
      );

      pdf
        .setFont('Lato-Regular', 'normal')
        .text(this.service_to_others_label + 'th', 163, 40, {
          maxWidth: 15,
        });
      pdf.setFont('Lato-Regular', 'normal').text('percentile.', 162, 43, {
        maxWidth: 15,
      });

      canvas = document.querySelector('#barchart3') as HTMLCanvasElement;
      canvasImg = canvas.toDataURL('image/png', 0.5);

      if (canvasImg) {
        pdf.addImage(canvasImg, 'png', 17, 50, 35, 35, undefined, 'FAST');
      }
      canvas = document.querySelector('#barchart') as HTMLCanvasElement;
      canvasImg = canvas.toDataURL('image/png', 0.5);

      if (canvasImg) {
        pdf.addImage(canvasImg, 'png', 61, 50, 35, 35, undefined, 'FAST');
      }
      canvas = document.querySelector('#barchart2') as HTMLCanvasElement;
      canvasImg = canvas.toDataURL('image/png', 0.5);

      if (canvasImg) {
        pdf.addImage(canvasImg, 'png', 110, 50, 35, 35, undefined, 'FAST');
      }
      canvas = document.querySelector('#barchart1') as HTMLCanvasElement;
      canvasImg = canvas.toDataURL('image/png', 0.5);

      if (canvasImg) {
        pdf.addImage(canvasImg, 'png', 155, 50, 35, 35, undefined, 'FAST');
      }
      pdf.setFontSize(12);

      pdf.text('Your PMP: A combined view', 18, 96);
      pdf.setFontSize(10);

      pdf.setFont('Lato-Regular', 'normal');

      pdf.text(
        'Another way to view your PMP is to look at all four pathways together. The green shape below depicts your ',
        18,
        103
      );
      pdf.text(
        'PMP score compared to the typical PMP score shown in gray.',
        18,
        108
      );
      pdf.text('Look at your PMP shape. Is it…', 18, 113);
      pdf.setFontSize(10);
      pdf.text('• larger or smaller than the typical PMP?', 23, 118);
      pdf.text('• favoring a particular part of the circle?', 23, 123);

      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(10);
      pdf.text(
        'Your profile may be relatively balanced across the four pathways. Or it may lean towards the top, bottom, left, or',
        18,
        128
      );
      pdf.text('right of the circle.', 18, 133);

      var img_new = new Image();
      img_new.src = './assets/media/radar_chart_01.png';

      pdf.addImage(img_new, 'png', 65, 128, 111, 77, undefined, 'FAST');

      var canvas = document.querySelector(
        '#radar_pie_chart'
      ) as HTMLCanvasElement;
      var canvasImg = canvas.toDataURL('image/png', 0.6);

      pdf.addImage(canvasImg, 'png', 65, 98, 100, 55, undefined, 'FAST', 320);
      img4 = new Image();
      img4.src = './assets/media/icon/Your_PMP_Score.png';

      pdf.addImage(img4, 'PNG', 20, 158, 5, 5, undefined, 'FAST');

      pdf.text('Your PMP Score', 30, 161.5);

      img5 = new Image();
      img5.src = './assets/media/icon/Typical_PMP_Score.png';

      pdf.addImage(img5, 'PNG', 20, 168, 5, 5, undefined, 'FAST');
      pdf.text('Typical PMP Score', 30, 171.5);
      pdf.setFontSize(10);
      pdf.text(
        'Everyday life has its natural imbalances, so don’t be concerned if you’re not ‘perfectly balanced’. Consider what',
        18,
        210
      );
      pdf.text(
        'the size and shape mean for you, then read the examples below.',
        18,
        215
      );
      img4 = new Image();
      img4.src = './assets/media/pmp-score.png';

      if (img4) {
        pdf.addImage(img4, 'PNG', 15, 225, 40, 40, undefined, 'FAST');
      }

      pdf.setFontSize(12);

      pdf.text('PMP Practical Examples', 63, 232);

      pdf.setFontSize(10);
      pdf.setFont('Lato-Regular', 'normal');
      pdf.text(
        'Consider a situation in which a person has the following PMP scores (shown in green):',
        63,
        239
      );
      pdf.setFontSize(10);

      pdf.text(
        '• Medium scores on “Integrity with Self” and “Unity with Others”',
        68,
        244
      );
      pdf.text('• High score on “Service to Others”', 68, 250);
      pdf.text('• Low score on “Expressing Full Potential”', 68, 255);

      pdf.addPage();
      //// Page 5 /////
      pdf.setDrawColor(0, 0, 0, 0.6);
      pdf.line(
        pdf.internal.pageSize.width - 15,
        pdf.internal.pageSize.height - 14,
        15,
        pdf.internal.pageSize.height - 14
      );

      // Header

      pdf.setFontSize(11);
      pdf.text('Explorer: ' + full_name, pdf.internal.pageSize.width - 70, 15);

      if (logo) {
        pdf.addImage(logo, 'JPEG', 18, 10, 40, 8, undefined, 'FAST');
      }

      pdf.setFontSize(10);
      pdf.text(
        'In this case, the person may feel they make quite a difference to others at work, but work might feel less',
        18,
        28
      );
      pdf.text(
        'meaningful because their skills and talents are not used to their fullest. In a situation like this, taking courses,',
        18,
        33
      );
      pdf.text(
        'signing up for projects, or trying a different role might make work more meaningful.',
        18,
        38
      );
      pdf.text(
        'If scores were low in all four pathways, that person may not be in the right job and could consider looking for',
        18,
        45
      );
      pdf.text(
        'another position, seeking meaning in other life roles, or talking to a trusted individual for guidance.',
        18,
        50
      );
      pdf.setFontSize(12);

      pdf.text('Key Takeaways About Your PMP', 18, 60);
      pdf.setFontSize(10);

      pdf.setFont('Lato-Regular', 'normal');
      pdf.text('Scores that fall in the:', 18, 68);
      pdf.setFontSize(10);

      pdf.text('• Top 25% / 75-100 percentile are considered high', 23, 73);
      pdf.text(
        '• Middle 50% / 26-74 percentile are considered typical',
        23,
        78
      );
      pdf.text('• Bottom 25% /1-25 percentile are considered low', 23, 83);
      pdf.setFontSize(10);

      pdf.text(
        'In general, the higher your scores, the more meaning you find from that element of the Map. ',
        18,
        89
      );
      pdf.text(
        'Meaningful work also depends on the degree to which you personally derive meaning from each of the four',
        18,
        95
      );
      pdf.text('pathways.', 18, 100);
      pdf.text(
        'As you start thinking about how to apply your PMP scores to your work situation, please remember these four',
        18,
        106
      );
      pdf.text('points:', 18, 111);
      pdf.setFontSize(10);

      pdf.text(
        '1. Scores are a point of reference only. There’s no need to try and improve a score just because it is typical or',
        23,
        116
      );
      pdf.text('low.', 23, 121);
      pdf.text(
        '2. If you have a high score in Unity with Others, that doesn’t mean this is your “type” or “workstyle”; it merely',
        23,
        127
      );
      pdf.text(
        'means you are currently finding a high degree of meaning at work in this pathway.',
        23,
        132
      );
      pdf.text(
        '3. Whether you’re concerned by a low score or satisfied with a high score, you can decide to change',
        23,
        138
      );
      pdf.text(
        'something based on your circumstances at work and the meaning you find in both work and other life roles.',
        23,
        143
      );

      pdf.text(
        '4. If you feel stuck or unsure about your PMP score, go back to the survey items and your scores. Identify',
        23,
        149
      );
      pdf.text(
        'which specific work experiences underlie these scores and how they matter to you. You can schedule a session',
        23,
        154
      );
      pdf.text(
        'with a MeaningSphere Guide if you’d like some help making sense of your results and discovering next steps',
        23,
        159
      );
      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(15);
      pdf.text('Your Healthy Tensions', 18, 168);

      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(10);
      pdf.text(
        'Finding meaning in the four pathways causes natural tensions for all of us. That’s because we have an inherent ',
        18,
        175
      );
      pdf.text(
        'need to focus on ourselves and others. The same goes for the tension created between making time to just ',
        18,
        180
      );
      pdf.text('“be” and insuring things get done. ', 18, 185);
      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(10);

      pdf.text(
        'These tensions push and pull us in opposite directions. Although there’s no need for perfect harmony, you need',
        18,
        191
      );
      pdf.text(
        ' to maintain a healthy balance. When tensions become persistently “unhealthy” there’s a risk of an overall loss',
        18,
        196
      );

      pdf.text(' of meaning or even loss of personal wellbeing. ', 18, 201);
      pdf.text(
        'Your aggregated responses to the survey items in the graph below, give you a sense whether your current',
        18,
        207
      );
      pdf.text(
        '“Being-Doing” and “Self-Others” tensions are healthy.',
        18,
        212
      );

      pdf.addPage();
      //// Page 6 /////
      pdf.setDrawColor(0, 0, 0, 0.6);
      pdf.line(
        pdf.internal.pageSize.width - 15,
        pdf.internal.pageSize.height - 14,
        15,
        pdf.internal.pageSize.height - 14
      );

      // Header

      pdf.setFontSize(11);
      pdf.text('Explorer: ' + full_name, pdf.internal.pageSize.width - 70, 15);

      if (logo) {
        pdf.addImage(logo, 'JPEG', 18, 10, 40, 8, undefined, 'FAST');
      }

      img4 = new Image();
      img4.src = './assets/media/pdf/third_page_pdf.png';

      if (img4) {
        pdf.addImage(img4, 'PNG', 15, 20, 180, 190);
      }
      pdf.setDrawColor(0, 0, 0, 0.6);

      pdf.text(
        'The chart below displays your scores and the typical scores for other people who have completed the Survey.',
        18,
        210
      );
      canvas = document.querySelector('#barchart_hor') as HTMLCanvasElement;
      canvasImg = canvas.toDataURL('image/png', 0.9);

      if (canvasImg) {
        pdf.addImage(canvasImg, 'PNG', 20, 220, 80, 40, undefined, 'FAST');
      }

      pdf.setFont('Lato-Regular', 'italic');

      pdf.text('The typical score for both these tensions is 3.5', 18, 265);

      pdf.text('', 18, 245);
      pdf.text('', 18, 250);

      pdf.addPage();
      //// Page 7 /////
      pdf.setDrawColor(0, 0, 0, 0.6);
      pdf.line(
        pdf.internal.pageSize.width - 15,
        pdf.internal.pageSize.height - 14,
        15,
        pdf.internal.pageSize.height - 14
      );
      // Header

      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(11);
      pdf.text('Explorer: ' + full_name, pdf.internal.pageSize.width - 70, 15);

      if (logo) {
        pdf.addImage(logo, 'JPEG', 18, 10, 40, 8, undefined, 'FAST');
      }
      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(10);
      pdf.text(
        'Tension scores are "healthy" when survey items within the tension are rated relatively high. Conversely, if these',
        18,
        30
      );

      pdf.text(
        'items are rated low, this tension is probably “unhealthy”. If you feel stuck or unsure about your tensions scores, ',
        18,
        35
      );
      pdf.text(
        'go back to the individual survey items. Ask yourself, "what’s going on in my work situation that’s ',
        18,
        40
      );

      pdf.text(
        'this item low? In what ways could I handle this situation better?”',
        18,
        45
      );
      pdf.text(
        'Too much or too little focus on one side of a tension can impact other elements where you find meaning. See the',
        18,
        52
      );
      pdf.text(
        'examples below that point to situations in which tensions may not be healthy.',
        18,
        57
      );

      pdf.setFontSize(12);

      pdf.text('Healthy Tension Practical Examples', 18, 64);

      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(10);
      pdf.text(
        'Someone whose pattern at work is almost exclusively focused on getting things done for others is at risk of not',
        18,
        70
      );
      pdf.text(
        'finding enough space for themselves. Over time, this kind of tension imbalance can drain your energy.',
        18,
        75
      );

      pdf.setFontSize(12);
      pdf.text('Inspiration and Reality: A special tension', 18, 83);

      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(10);
      pdf.text(
        'The four pathways held in the two tensions play out in the overall context of our “Inspiration” and the “Reality of',
        18,
        90
      );
      pdf.text(
        'Self and Circumstances”. As Inspiration expands our thinking, Reality binds us to what’s possible. An “unhealthy"',
        18,
        95
      );
      pdf.text(
        'tension may occur when our thoughts and actions are dominated by Reality so that Inspiration doesn’t have a',
        18,
        100
      );
      pdf.text(
        'voice, and vice versa. In addition, when we cannot see a connection between our Inspiration and Reality, an',
        18,
        105
      );
      pdf.text('unhealthy tension usually follows.', 18, 110);
      pdf.text(
        'The survey items that highlight your perception of “Inspiration” and the “Reality of Self and Circumstances” are',
        18,
        115
      );
      pdf.text('displayed below', 18, 120);
      img4 = new Image();
      img4.src = './assets/media/pdf/forth_page_pdf.png';

      if (img4) {
        pdf.addImage(img4, 'PNG', 40, 123, 130, 155, undefined, 'FAST');
      }
      pdf.addPage();

      pdf.setFont('Lato-Regular', 'normal');
      //// Page 8 /////
      pdf.setDrawColor(0, 0, 0, 0.6);
      pdf.line(
        pdf.internal.pageSize.width - 15,
        pdf.internal.pageSize.height - 14,
        15,
        pdf.internal.pageSize.height - 14
      );
      // Header

      pdf.setFontSize(11);
      pdf.text('Explorer: ' + full_name, pdf.internal.pageSize.width - 70, 15);

      if (logo) {
        pdf.addImage(logo, 'JPEG', 18, 10, 40, 8, undefined, 'FAST');
      }

      pdf.setDrawColor(0, 0, 0, 0.6);
      pdf.text(
        'The chart below displays your scores and the typical scores for other people who have completed the Survey.',
        18,
        27
      );
      canvas = document.querySelector('#barchart_hor2') as HTMLCanvasElement;
      canvasImg = canvas.toDataURL('image/png', 0.9);

      if (canvasImg) {
        pdf.addImage(canvasImg, 'PNG', 20, 30, 80, 40, undefined, 'FAST');
      }
      pdf.setFont('Lato-Regular', 'italic');
      pdf.text(
        'The typical score for both Reality and Inspiration is 3.5.',
        18,
        80
      );

      pdf.setFont('Lato-Regular', 'normal');

      pdf.setFontSize(10);

      pdf.text(
        'This tension can be considered “healthy” when both Inspiration and Reality are rated relatively high. However, if',
        18,
        88
      );
      pdf.text(
        'one or both are low, this tension is probably “unhealthy.”',
        18,
        93
      );
      pdf.setFontSize(12);
      pdf.text('Inspiration and Reality Practical Examples', 18, 103);

      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(10);
      pdf.text(
        'Our inspiration can  be suppressed if we feel stuck or experience a lack of progress. In a work situation characterized',
        18,
        110
      );
      pdf.text(
        'by a "heads down, get it done" environment where people are constantly reminded to “be realistic,” a person ',
        18,
        115
      );
      pdf.text(
        'may find their inspiration suppressed. If this person desires more activities that inspire hope, ideals, or vision, ',
        18,
        120
      );
      pdf.text(
        'they may volunteer in an organization that provides them these opportunities or consider switching roles.',
        18,
        125
      );
      pdf.setFontSize(12);
      pdf.text('Key Takeaways About Healthy Tensions', 18, 135);

      pdf.setFont('Lato-Regular', 'normal');
      pdf.setFontSize(10);
      pdf.text(
        'As you think about Healthy Tensions, please remember:',
        18,
        142
      );

      pdf.text(
        '1. Your tension scores are point-in-time snapshots. If something significant changes at work or in life away from',
        23,
        147
      );
      pdf.text('work, tensions may also change, for better or worse', 23, 152);
      pdf.text(
        '2. Research shows that imbalances may lead to an overall loss of meaning, and over time this can become',
        23,
        157
      );
      pdf.text(
        'unhealthy because it may lead to physical and psychological issues.',
        23,
        162
      );
      pdf.text(
        '3. There are no “correct” answers. You decide whether you are concerned with imbalances. Your decision to',
        23,
        167
      );
      pdf.text(
        'take some re-balancing action should be based on your work circumstances and the meaning you find in your work.',
        23,
        172
      );

      pdf.setFontSize(12);
      pdf.text('Do You Have Life-Work Balance?', 18, 182);

      pdf.setFont('Lato-Regular', 'normal');

      pdf.setFontSize(10);
      pdf.text(
        'While the Meaningful Work Survey focuses primarily on work, there are three items that go beyond work to capture',
        18,
        190
      );
      pdf.text(
        'your overall sense of wellbeing and a feeling of having life-work balance. These survey items are a useful check',
        18,
        195
      );
      pdf.text('against your PMP and Healthy Tension scores. ', 18, 200);
      pdf.text(
        'Your wellbeing responses are displayed below. What’s your impression of your current life-work balance? ',
        18,
        206
      );

      canvas = document.querySelector('#barchart_hor3') as HTMLCanvasElement;

      canvasImg = canvas.toDataURL('image/png', 0.5);
      if (canvasImg) {
        pdf.addImage(canvasImg, 'PNG', 40, 207, 120, 70, undefined, 'FAST');
      }

      pdf.addPage();
      //// Page 9 /////
      pdf.setDrawColor(0, 0, 0, 0.6);
      pdf.line(
        pdf.internal.pageSize.width - 15,
        pdf.internal.pageSize.height - 14,
        15,
        pdf.internal.pageSize.height - 14
      );
      // Header
      pdf.setFontSize(11);
      pdf.text('Explorer: ' + full_name, pdf.internal.pageSize.width - 70, 15);

      if (logo) {
        pdf.addImage(logo, 'JPEG', 18, 10, 40, 8, undefined, 'FAST');
      }

      pdf.setFontSize(12);
      pdf.text('Life-Work Balance Practical Examples', 18, 30);

      pdf.setFontSize(10);
      pdf.setFont('Lato-Regular', 'normal');

      pdf.text(
        'Below is an example of someone who has relatively high PMP scores. Yet, their scores on the wellbeing items are',
        18,
        37
      );
      pdf.text(
        'relatively low. When this person considers their survey results, they may feel there’s more to life than work and',
        18,
        42
      );
      pdf.text(
        'choose to rebalance their life and work as it makes sense for their needs.',
        18,
        47
      );

      canvas = document.querySelector('#barchart_hor4') as HTMLCanvasElement;

      canvasImg = canvas.toDataURL('image/png', 0.5);
      if (canvasImg) {
        pdf.addImage(canvasImg, 'PNG', 40, 50, 120, 70, undefined, 'FAST');
      }
    }
    // second theme #3
    if (this.tabIndex === 1) {
      pdf.setFont('Lato-Regular', 'normal');

      pdf.setFontSize(15);
      pdf.text('Your Meaning-Seeking Patterns', 18, 130);

      pdf.setFontSize(10);

      pdf.setFont('Lato-Regular', 'normal');
      pdf.text(
        'When you explore your everyday situation, you will find what’s fueling and what’s draining your energy',
        18,
        138
      );
      pdf.text('and motivation.', 18, 143);
      pdf.text(
        'Your PMP and Healthy Tension scores point to the following pattern(s) of meaning-seeking in your current',
        18,
        148
      );
      pdf.text('work situation. Based on these scores you have... ', 18, 153);

      pdf.setFontSize(10);

      pdf.text('• ' + this.lastPageStringValue, 23, 159);

      pdf.setFontSize(15);

      pdf.setFont('Lato-Regular', 'normal');
      pdf.text('Your Next Steps', 18, 170);
      pdf.setFontSize(10);
      pdf.text(
        'The search for meaning doesn’t always have to be a very serious and profound matter. Please enjoy',
        18,
        178
      );
      pdf.text(
        'experimenting with your next steps to discover what works for you!',
        18,
        183
      );
      pdf.text('To review your options:', 18, 188);
      pdf.setFontSize(10);

      pdf.text('1. Return to the MeaningSphere website', 23, 193);
      pdf.text('2. Navigate to the ‘My Surveys’ page', 23, 198);
      pdf.text(
        '3. Click on the ‘Next Steps’ option of your most recent Meaningful Work Survey',
        23,
        203
      );
    }
    addFooters(pdf);
    pdf.save('Survey_Report.pdf');
    this.togglecmp = false;
  }
  callVideoList() {
    this.dialog.open(VideoListComponent, {
      width: '50%',
      disableClose: true,
    });
  }
  scrollToElement($element, tog): void {
    this.toggle0 = this.toggle1 = this.toggle2 = this.toggle3 = this.toggle4 =
      'inactive';
    if (tog === 'toggle0') {
      this.toggle0 = 'active';
    } else if (tog === 'toggle1') {
      this.toggle1 = 'active';
    } else if (tog === 'toggle2') {
      this.toggle2 = 'active';
    } else if (tog === 'toggle3') {
      this.toggle3 = 'active';
    } else {
      this.toggle4 = 'active';
    }
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
  onScroll($element) {
    this.target0Height = this.target0.nativeElement.offsetHeight;
    this.target1Height = this.target1.nativeElement.offsetHeight;
    this.target2Height = this.target2.nativeElement.offsetHeight;
    this.target3Height = this.target3.nativeElement.offsetHeight;
    this.target4Height = this.target4.nativeElement.offsetHeight;

    this.toggle0 = this.toggle1 = this.toggle2 = this.toggle3 = this.toggle4 =
      'inactive';
    if ($element.target.scrollTop < this.target0Height) {
      this.toggle0 = 'active';
    } else if (
      $element.target.scrollTop > this.target0Height - 70 &&
      $element.target.scrollTop < this.target1Height + this.target0Height
    ) {
      this.toggle1 = 'active';
    } else if (
      $element.target.scrollTop >
        this.target1Height + this.target0Height - 70 &&
      $element.target.scrollTop <
        this.target1Height + this.target0Height + this.target2Height
    ) {
      this.toggle2 = 'active';
    } else if (
      $element.target.scrollTop >
        this.target1Height + this.target0Height + this.target2Height - 50 &&
      $element.target.scrollTop <
        this.target1Height +
          this.target0Height +
          this.target2Height +
          this.target3Height
    ) {
      this.toggle3 = 'active';
    } else if (
      $element.target.scrollTop >
        this.target1Height +
          this.target0Height +
          this.target2Height +
          this.target3Height -
          50 &&
      $element.target.scrollTop <
        this.target1Height +
          this.target0Height +
          this.target2Height +
          this.target3Height +
          this.target4Height
    ) {
      this.toggle4 = 'active';
    }
  }
  responsePageCall() {
    let dialogRef = this.dialog.open(SurveyResponseComponent, {
      width: '70%',
      disableClose: true,
      data: {
        currentSelectedSurvey: this.currentSelectedSurvey,
      },
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  introVideo() {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: {
        source: 'https://player.vimeo.com/video/759651221?h=643dc2318f',
      },
    });
  }
  ngOnDestroy(): void {
    if (this.surveyDetailSubscription) {
      this.surveyDetailSubscription.unsubscribe();
    }
  }
}
