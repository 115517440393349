<div class="">
  <table width="100%">
    <tr>
      <td>
        <h1 class="cmn_title_top">
          Endorse Guide - {{ first_name }} {{ last_name }}
        </h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <ng-container>
    <form
      [formGroup]="endorsementForm"
      (ngSubmit)="endorsement(endorsementForm)"
      novalidate
      autocomplete="off"
    >
      <div class="">
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field style="width: 100%; color: #ffffff">
              <mat-label>Endorsement Message</mat-label>
              <textarea
                maxlength="2048"
                formControlName="remarks"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&!@#$%=*-+?() ]|^ /g,'')"
                matInput
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
        <hr />
      </div>
      <div class="row">
        <div class="col-sm-12 tr">
          <button
            class="btn begin_quiz ml-2"
            type="submit"
            [disabled]="!endorsementForm.valid || saveButton"
          >
            Endorse
          </button>

          <button
            mat-dialog-close
            class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</div>
