<div class="mat-dialog-popup meaningfeedForm">
  <table class="w-100">
    <tr>
      <td>
        <h1>Please complete all seven fields</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form
    class="meaningfeedForm"
    [formGroup]="sharedpersonaldataForm"
    (ngSubmit)="sharedataSave(sharedpersonaldataForm.value)"
    enctype="multipart/form-data"
  >
    <div class="row after-survey">
      <div class="col-sm-12">
        <div class="w-100" appearance="fill" floatLabel="always">
          <label>Age group</label>
          <select class="w-100" formControlName="age_group">
            <option value="" selected hidden disabled>Please select</option>
            <option value="2770">18-24 years</option>
            <option value="2771">25-34 years</option>
            <option value="2772">35-44 years</option>
            <option value="2773">45-54 years</option>
            <option value="2774">55-64 years</option>
            <option value="2775">65 years and over</option>
          </select>
        </div>

        <div class="w-100" appearance="fill" floatLabel="always">
          <label>Highest education level attained</label>
          <select
            class="w-100"
            formControlName="highest_education_level_attained"
          >
            <option value="" selected hidden disabled>Please select</option>
            <option value="2776">Secondary school</option>
            <option value="2777">College/technical degree</option>
            <option value="2778">Masters level and beyond</option>
          </select>
        </div>
        <div class="w-100" appearance="fill" floatLabel="always">
          <label>Gender</label>
          <select formControlName="gender" class="w-100" required>
            <option value="" selected hidden disabled>Please select</option>
            <option value="1014">Male</option>
            <option value="1015">Female</option>
            <option value="1016">Other</option>
          </select>
        </div>

        <div class="w-100" appearance="fill" floatLabel="always">
          <label>Region of birth</label>
          <select class="w-100" formControlName="region_of_birth">
            <option value="" selected hidden disabled>Please select</option>
            <option value="2789">Asia</option>
            <option value="2790">India</option>
            <option value="2791">Middle East</option>
            <option value="2792">North Africa</option>
            <option value="2793">Sub Saharan Africa</option>
            <option value="2846">North America</option>
            <option value="2794">Central America</option>
            <option value="2795">South America</option>
            <option value="2796">Europe</option>
            <option value="2797">Eurasia</option>
            <option value="2798">Oceania</option>
          </select>
        </div>

        <div class="w-100" appearance="fill" floatLabel="always">
          <label>Region you live</label>
          <select class="w-100" formControlName="region_you_live">
            <option value="" selected hidden disabled>Please select</option>
            <option value="2779">Asia</option>
            <option value="2780">India</option>
            <option value="2781">Middle East</option>
            <option value="2782">North Africa</option>
            <option value="2783">Sub Saharan Africa</option>
            <option value="2847">North America</option>
            <option value="2784">Central America</option>
            <option value="2785">South America</option>
            <option value="2786">Europe</option>
            <option value="2787">Eurasia</option>
            <option value="2788">Oceania</option>
          </select>
        </div>

        <div class="w-100" appearance="fill" floatLabel="always">
          <label>Industry</label>
          <select class="w-100" formControlName="industry">
            <option value="" selected hidden disabled>Please select</option>
            <option value="2799">
              Agriculture, Forestry, Fishing and Hunting
            </option>
            <option value="2800">
              Mining, Quarrying, and Oil and Gas Extraction
            </option>
            <option value="2801">Utilities</option>
            <option value="2802">Construction</option>
            <option value="2803">Manufacturing</option>
            <option value="2804">Wholesale Trade</option>
            <option value="2805">Retail Trade</option>
            <option value="2806">Transportation and Warehousing</option>
            <option value="2807">Information</option>
            <option value="2808">Finance and Insurance</option>
            <option value="2809">Real Estate and Rental and Leasing</option>
            <option value="2810">
              Professional, Scientific, and Technical Services
            </option>
            <option value="2811">
              Management of Companies and Enterprises
            </option>
            <option value="2812">
              Administrative and Support and Waste Management and Remediation
              Services
            </option>
            <option value="2813">Educational Services</option>
            <option value="2814">Health Care and Social Assistance</option>
            <option value="2815">Arts, Entertainment, and Recreation</option>
            <option value="2816">Accommodation and Food Services</option>
            <option value="2817">
              Other Services (except Public Administration)
            </option>
            <option value="2818">Public Administration</option>
          </select>
        </div>

        <div class="w-100" appearance="fill" floatLabel="always">
          <label>Occupation</label>
          <select class="w-100" formControlName="occupation">
            <option value="" selected hidden disabled>Please select</option>
            <option value="2819">Management Occupations</option>
            <option value="2820">
              Business and Financial Operations Occupations
            </option>
            <option value="2821">Computer and Mathematical Occupations</option>
            <option value="2822">
              Architecture and Engineering Occupations
            </option>
            <option value="2823">
              Life, Physical, and Social Science Occupations
            </option>
            <option value="2824">
              Community and Social Service Occupations
            </option>
            <option value="2825">Legal Occupations</option>
            <option value="2826">
              Educational Instruction and Library Occupations
            </option>
            <option value="2827">
              Arts, Design, Entertainment, Sports, and Media Occupations
            </option>
            <option value="2828">
              Healthcare Practitioners and Technical Occupations
            </option>
            <option value="2829">Healthcare Support Occupations</option>
            <option value="2830">Protective Service Occupations</option>
            <option value="2831">
              Food Preparation and Serving Related Occupations
            </option>
            <option value="2832">
              Building and Grounds Cleaning and Maintenance Occupations
            </option>
            <option value="2833">Personal Care and Service Occupations</option>
            <option value="2834">Sales and Related Occupations</option>
            <option value="2835">
              Office and Administrative Support Occupations
            </option>
            <option value="2836">
              Farming, Fishing, and Forestry Occupations
            </option>
            <option value="2837">
              Construction and Extraction Occupations
            </option>
            <option value="2838">
              Installation, Maintenance, and Repair Occupations
            </option>
            <option value="2839">Production Occupations</option>
            <option value="2840">
              Transportation and Material Moving Occupations
            </option>
            <option value="2841">Military Specific Occupations</option>
          </select>
        </div>
      </div>
    </div>
    <hr />

    <div class="text-align-right">
      <button
        class="btn begin_quiz"
        type="submit"
        [disabled]="!sharedpersonaldataForm.valid || saveButton"
      >
        Submit
      </button>
      <a class="btn begin_quiz canceled_btn" (click)="openPreviousPopup()"><span>Back</span></a>

      <a class="btn begin_quiz canceled_btn" (click)="notsharedata()"
        ><span>I no longer wish to share any data</span></a
      >
    </div>
  </form>
</div>
