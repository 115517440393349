import { message_properties } from './../../../../../environments/message_properties';
import { NotificationService } from '../../../../service/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { MessagesService } from '../../../../service/messages.service';
import { switchMap } from 'rxjs/operators';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-guide-discussion',
  templateUrl: './guide-discussion.component.html',
  styleUrls: ['./guide-discussion.component.scss'],
})
export class GuideDiscussionComponent implements OnInit, OnDestroy {
  sendRequestForm: UntypedFormGroup;
  mentorId: number;
  fullName: string;
  guide_pic: string;
  creatingUser: number;
  saveButton: boolean = false;
  messageFrom: number;
  requestSendSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<GuideDiscussionComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private toastr: NotificationService,
    private messageService: MessagesService,
    private accountService: AccountsService
  ) {
    this.mentorId = data.mentorId;
    this.fullName = data.fullName;
    this.guide_pic = data.guide_pic
      ? data.guide_pic
      : 'assets/media/users/default.png';
  }

  ngOnInit(): void {
    this.creatingUser = this.accountService.muser;
    this.sendRequestForm = this.formBuilder.group({
      message: new UntypedFormControl(
        'I would like to schedule a Guided Discussion with you.',
        [Validators.required]
      ),
      chat_for: new UntypedFormControl(1796),
      criticality: new UntypedFormControl(1576),
      receiving_user: new UntypedFormControl(this.mentorId),
      creating_user: new UntypedFormControl(this.creatingUser),
      tenant: new UntypedFormControl(1),
      all_admin_indicator: new UntypedFormControl(false),
      all_curator_indicator: new UntypedFormControl(false),
      all_guide_indicator: new UntypedFormControl(false),
      all_explorer_indicator: new UntypedFormControl(false),
      messageFrom: 0,
    });
  }
  sendRequest(ngForm: NgForm) {
    this.saveButton = true;
    let observer = new Promise((resolve) => setTimeout(resolve, 1000));
    this.requestSendSubscription = this.messageService
      .postMassage(ngForm)
      .pipe(
        switchMap((event) => {
          this.saveButton = true;
          this.dialogRef.close();
          return observer;
        })
      )
      .subscribe((data) => {
        this.dialogRef.close();
        if (this.messageFrom === 0) {
          this.saveButton = false;
          this.toastr.showSuccess(
            message_properties.SEND_MESSAGE_SAVE_MESSSAGE_SUCCESS,
            ''
          );
        } else {
          this.saveButton = false;
          this.toastr.showSuccess(
            message_properties.SEND_MESSAGE_SAVE_MESSAGE_SUCCESS1,
            ''
          );
        }
      });
  }
  ngOnDestroy(): void {
    if (this.requestSendSubscription) {
      this.requestSendSubscription.unsubscribe();
    }
  }
}
