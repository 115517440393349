import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { message_properties } from '../../../../../environments/message_properties';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from '../../../../service/notification.service';
import { Subject } from 'rxjs';
import { AccountsService } from './../../../../service/accounts.service';
@Component({
  selector: 'kt-braintree',
  templateUrl: './braintree.component.html',
  styleUrls: ['./braintree.component.scss'],
})
export class BraintreeComponent implements OnInit {
  client_token: string;
  customer_id: number;
  all_catalog_str: string;
  all_catalog_ids: string;
  btn_enabled = false;
  total_amount_pay_through_payment_gateway: string;
  member_account_transaction_id: string;
  apiUrl = '';
  paymentProcessDone = 0;
  total_amount_pay: number;
  private _refreshPaymentList = new Subject<void>();
  get refreshPaymentList() {
    return this._refreshPaymentList;
  }
  constructor(
    public dialogRef: MatDialogRef<BraintreeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private http: HttpClient,
    private _cdr: ChangeDetectorRef,
    private toastr: NotificationService,
    private accountService: AccountsService
  ) {
    this.all_catalog_ids = btoa(data.all_catalog_str);
    this.total_amount_pay_through_payment_gateway =
      data.total_amount_pay_through_payment_gateway;
    this.member_account_transaction_id = data.member_account_transaction_id;
    this.customer_id = this.accountService.muser;
    this.apiUrl = environment.apiUrl;
    this.total_amount_pay = Number(
      window.atob(this.total_amount_pay_through_payment_gateway)
    );
  }

  ngOnInit(): void {}
  getClientToken = () => {
    return this.http
      .post(
        `${this.apiUrl}subscription/member-account-transaction/get-braintree-token/`,
        { responseType: 'json' }
      )
      .pipe(
        map((response: any) => {
          return response.token;
        })
      );
  }

  getCreatePurchase = (nonce: string, chargeAmount: number) => {
    const send_data = {
      payment_method_nonce: nonce,
      total_amount_pay_through_payment_gateway: btoa(chargeAmount.toString()),
      member_account_transaction_id: this.member_account_transaction_id,
      all_catalog_str: this.all_catalog_ids,
      customer_id: this.accountService.muser,
    };
    return this.http
      .post(
        `${this.apiUrl}subscription/member-account-transaction/braintree-checkout/`,
        send_data
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            this.paymentProcessDone = 1;
            return response;
          }
          this.paymentProcessDone = 2;
          return response;
        })
      );
  }
  onPaymentStatus($event: Event) {
    if ($event['status'] === 200) {
      this._refreshPaymentList.next();
      this.toastr.showSuccess(message_properties.PAYMENT_SUCCESS, '');
      this.dialogRef.close();
      setTimeout(() => {
        location.reload();
      }, 3000);
    } else if ($event['status'] === 204) {
      this.toastr.showError($event['message'], '');
      this.dialogRef.close();
    }
  }
}
