import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { message_properties } from '../../../../../environments/message_properties';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../../../service/notification.service';
import { MeaningNetworkService } from '../../../../service/meaning-network.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  NgForm,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { CityStateCountryService } from '../../../../service/city-state-country.service';
import { AccountsService } from '../../../../service/accounts.service';
import {
  FixedSizeVirtualScrollStrategy,
  VIRTUAL_SCROLL_STRATEGY,
} from '@angular/cdk/scrolling';
export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(0, 100, 400);
  }
}
@Component({
  selector: 'kt-volunteer-experiance-form-profile',
  templateUrl: './volunteer-experiance-form-profile.component.html',
  styleUrls: ['./volunteer-experiance-form-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy },
  ],
})
export class VolunteerExperianceFormProfileComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('countrySearchInput', { static: false })
  countrySearchInput: ElementRef;
  config = {
    placeholder: 'Enter a brief description',
    tabsize: 2,
    height: 150,
    toolbar: [],
    disableDragAndDrop: true,
    disableResizeEditor: true,
    linkTargetBlank: false,
    callbacks: {
      onPaste: function (e) {
        const bufferText = (e.originalEvent || e).clipboardData.getData('Text');
        e.preventDefault();
        document.execCommand('insertText', false, bufferText);
      },
    },
  };
  volunteerForm: UntypedFormGroup;
  memberId: string;
  addOrEdit: string;
  memberid: any;
  volunteerId: number;
  countryList = [];
  countryCount: number;
  countryBox1: boolean = false;
  minDate;
  maxDate = new Date();
  saveButton: boolean = false;
  countrySubscription: Subscription;
  volunteerSubscription: Subscription;
  getCompanySubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<VolunteerExperianceFormProfileComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private toastr: NotificationService,
    private cdRef: ChangeDetectorRef,
    private meaning_network_service: MeaningNetworkService,
    private cityStateCountry: CityStateCountryService,
    private accountService: AccountsService
  ) {
    this.addOrEdit = data.addOrEdit;
    this.volunteerId = data.volunteerId;
    this.minDate = data.start_date;
  }

  ngOnInit(): void {
    this.memberid = this.accountService.muser;

    this.volunteerForm = new UntypedFormGroup({
      title: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(96),
      ]),
      company: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(96),
      ]),
      location: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(96),
      ]),
      volunteer_id: new UntypedFormControl(this.volunteerId),
      check_value: new UntypedFormControl(3),
      workprofile: new UntypedFormControl(''),
      start_date: new UntypedFormControl('', [Validators.required]),
      end_date: new UntypedFormControl('', [Validators.required]),
      member: new UntypedFormControl(this.memberid),
    });
    if (this.addOrEdit !== 'add') {
      this.getVolunteering();
    }
  }
  onChangeFrom(event) {
    this.minDate = event.value;
    this.volunteerForm.patchValue({
      endDate: this.minDate,
    });
    this.cdRef.detectChanges();
  }
  volunteerSave(ngForm: NgForm) {
    this.spinner.show();
    this.saveButton = true;
    if (this.volunteerId === 0) {
      this.volunteerSubscription = this.meaning_network_service
        .postVolunteerData(ngForm)
        .subscribe(
          (data) => {
            if (data.status === 201) {
              this.emptyForm();
              this.saveButton = false;
              this.dialogRef.close();
              this.toastr.showSuccess(
                message_properties.VOLUNTEER_SAVE_SUCCESS,
                ''
              );
              this.cdRef.detectChanges();
              this.spinner.hide();
            } else if (data.status === 204) {
              this.saveButton = false;
              this.toastr.showError(data.message, '');
              this.cdRef.detectChanges();
              this.spinner.hide();
            } else {
              this.saveButton = false;
              this.toastr.showError(data.message, '');
              this.cdRef.detectChanges();
              this.spinner.hide();
            }
          },
          (error) => {
            this.saveButton = false;
            this.toastr.showError(message_properties.VOLUNTEER_SAVE_ERROR, '');
            this.cdRef.detectChanges();
            this.spinner.hide();
          }
        );
    } else {
      this.volunteerSubscription = this.meaning_network_service
        .updateVolunteerData(ngForm)
        .subscribe((data) => {
          if (data.status === 201) {
            this.emptyForm();
            this.saveButton = false;
            this.dialogRef.close();
            this.toastr.showSuccess(
              message_properties.VOLUNTEER_SAVE_SUCCESS,
              ''
            );
            this.cdRef.detectChanges();
            this.spinner.hide();
          } else if (data.status === 204) {
            this.saveButton = false;
            this.toastr.showError(data.message, '');
            this.cdRef.detectChanges();
            this.spinner.hide();
          } else if (data.status === 200) {
            this.saveButton = false;
            this.toastr.showError(message_properties.VOLUNTEER_SAVE_ERROR, '');
            this.cdRef.detectChanges();
            this.spinner.hide();
          }
        });
    }
  }
  emptyForm() {
    this.volunteerForm.patchValue({
      company: '',
      location: '',
      title: '',
      end_date: '',
      start_date: '',
      workprofile: '',
    });
  }
  getVolunteering() {
    const formValue = this.volunteerForm.value;
    this.spinner.show();
    this.getCompanySubscription = this.meaning_network_service
      .getMemberCompanyDetail(formValue)
      .subscribe((data) => {
        this.volunteerForm.patchValue({
          title: data.title,
          company: data.name,
          location: data.location,
          workprofile: data.workprofile,
          check_value: 3,
          member: this.memberid,
          start_date: data.start_date,
          end_date: data.end_date,
        });
        this.cdRef.detectChanges();
        this.spinner.hide();
      });
  }
  ngAfterViewInit(): void {
    this.countrylistSearch();
  }
  countrylistSearch() {
    fromEvent(this.countrySearchInput.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // if character length greater then 2
        filter((res) => res.length > 2),
        // Time in milliseconds between key events
        debounceTime(500),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((sendData: string) => {
        this.countrySubscription = this.cityStateCountry
          .getCountry(sendData)
          .subscribe((response) => {
            this.countryList = response.results;
            this.countryCount = response.count;
            if (this.countryCount > 0) {
              this.countryBox1 = true;
            } else {
              this.countryBox1 = false;
            }
            this.cdRef.detectChanges();
          });
      });
  }
  setCountry(name, id) {
    this.countryBox1 = false;
    localStorage.setItem('countryId', id);
    this.volunteerForm.patchValue({
      location: name,
    });
  }
  ngOnDestroy(): void {
    if (this.countrySubscription) {
      this.countrySubscription.unsubscribe();
    }
    if (this.volunteerSubscription) {
      this.volunteerSubscription.unsubscribe();
    }
    if (this.getCompanySubscription) {
      this.getCompanySubscription.unsubscribe();
    }
  }
}
