import { RouteGuard } from './guard/route.guard';
import { LayoutConfig } from './core/_config/layout.config';
// Angular
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';

// Copmponents
import { AppComponent } from './app.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ThemeModule } from './views/theme/theme.module';

// Layout Services
import { TokenInterceptor } from './interceptor/token-interceptor.service';

import {
  // KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  // MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SubheaderService,
} from './core/_base/layout';

import { AdminGuard } from './guard/admin.guard';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderInformationInterceptor } from './interceptor/header-information.interceptor';
import {
  NgxZendeskWebwidgetConfig,
  NgxZendeskWebwidgetModule,
} from 'ngx-zendesk-webwidget';
import { NgxPendoModule } from 'ngx-pendo';
import { PendoInterceptor } from './interceptor/pendo-interceptor.service';


export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  accountUrl = 'yourdomain.zendesk.com';
  callback(zE) {
    // You can call every command you want in here
    // zE('webWidget', 'hide');
    // zE('webWidget', 'show');
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    OverlayModule,
    TranslateModule.forRoot(),
    SweetAlert2Module.forRoot(),
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    ThemeModule,
    NgbModule,
    NgxPendoModule.forRoot({
      pendoApiKey: localStorage.getItem('var1') ? localStorage.getItem('var1') : '',
      pendoIdFormatter: (value: any) => value.toString().toLowerCase(),
    })
  ],
  exports: [],
  providers: [
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    RouteGuard,
    AdminGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PendoInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInformationInterceptor,
      multi: true,
    },

    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
    // template services
    SubheaderService,
    MenuHorizontalService,
    // MenuAsideService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
