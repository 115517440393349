<kt-spinner></kt-spinner>
<div class="mat-dialog-popup">
  <h1 mat-dialog-title>
    <button mat-button mat-dialog-close class="modal_close_button_top">
      X
    </button>
  </h1>
  <div class="">
    <div class="report-cont">
      <div class="row">
        <div class="col-md-6">
          <!-- <div class="row">
            <div class="col-md-12"> -->
          <h1 class="fs-20">
            Survey date: {{ submission_date | date: "MMM d, y" }}
          </h1>
          <!-- </div>
          </div> -->
        </div>

        <div class="col-md-6">
          <h1 class="fs-20 cu_community_date">Explorer:
            <!-- <img [src]="explorer_pic" /> -->
            {{ explorer_name }}
          </h1>

        </div>
      </div>
      <hr />



      <!-- <div class="col-md-6">
          <h1 class="fs-20">Summary:</h1>
          <hr />

          <ul class="report-list report-list-t survey-left-sect">
            <span class="section-text">
              There are three type of information provided in this Survey
              Report. Your:
            </span>
            <li>1. Personal Meaning Profile</li>
            <li>2. Healthy Balance Score</li>
            <li>3. Meaning Seeking Patterns</li>
            <span class="section-text">
              After examining your Survey results, you can decide what actions,
              if any, you want to take to make changes in how and where you find
              meaning in your work.
            </span>
          </ul>
        </div> -->
      <div class="survey-tab">
        <div class="survey">
          <div class="survey_header">

            <a (click)="scrollToElement(target0, 'toggle0')" [className]="toggle0">
              <div class="py-2">About Your Report</div>
            </a>

            <a (click)="scrollToElement(target1, 'toggle1')" [className]="toggle1">
              <div class="py-2">Your Personal Meaning Profile</div>
            </a>
            <a (click)="scrollToElement(target2, 'toggle2')" [className]="toggle2">
              <div class="py-2">Your Healthy Tensions</div>
            </a>
            <a (click)="scrollToElement(target3, 'toggle3')" [className]="toggle3">
              <div class="py-2">Your Meaning-Seeking Patterns</div>
            </a>
            <a (click)="scrollToElement(target4, 'toggle4')" [className]="toggle4">
              <div class="py-2">Your Next Steps</div>
            </a>
          </div>
          <cdk-virtual-scroll-viewport itemSize="40" minBufferPx="200" maxBufferPx="400"
            class="survey_result scroll_class_list" (scroll)="onScroll($event)">
            <!-- (scrolledIndexChange)="onScroll($event)" -->
            <div #target0 class="target target0">

              <div class="col-sm-12 p-0">
                <div class="survey-left-sect-chnag">
                  <div class="row">
                    <div class="col-md-6" *ngIf="guide_avalable">
                      <h3>Guide: {{ guide_name }}</h3>
                    </div>
                  </div>
                  <ul class="report-list">
                    <h3 class="section-title1">Thank you for completing the Meaningful Work Survey. In addition to
                      viewing your Survey Report, you can click to:</h3>
                    <li>
                      <span class="rond-wht"></span> <span class="cursorp"
                        (click)="introVideo()">WATCH</span><span>&nbsp;an introductory video (recommended) </span>
                    </li>

                    <li>
                      <span class="rond-wht"></span>
                      <span class="cursorp" (click)="responsePageCall()">VIEW</span>
                      <span>&nbsp;your Survey responses</span>
                    </li>
                    <li>
                      <span class="rond-wht"> </span>
                      <span class="cursorp" (click)="printPdf()">DOWNLOAD</span><span>&nbsp;your report</span>
                    </li>
                  </ul>
                </div>
              </div>
              <kt-surveyreport1 [currentSelectedSurvey]="currentSelectedSurvey" [json_report]="json_report"
                [memberId]="memberId"></kt-surveyreport1>
            </div>
            <div #target1 class="target target1">
              <kt-surveyreport2 [json_report]="json_report" [currentSelectedSurvey]="currentSelectedSurvey"
                [memberId]="memberId"></kt-surveyreport2>
              <kt-surveyreport3 [json_report]="json_report" [currentSelectedSurvey]="currentSelectedSurvey"
                [memberId]="memberId"></kt-surveyreport3>
              <kt-surveyreport4 [json_report]="json_report" [currentSelectedSurvey]="currentSelectedSurvey"
                [memberId]="memberId"></kt-surveyreport4>
              <kt-surveyreport5 [json_report]="json_report" [currentSelectedSurvey]="currentSelectedSurvey"
                [memberId]="memberId"></kt-surveyreport5>
            </div>
            <div #target2 class="target target2">
              <kt-surveyreport6 [json_report]="json_report" [currentSelectedSurvey]="currentSelectedSurvey"
                [memberId]="memberId"></kt-surveyreport6>
              <kt-surveyreport7 [json_report]="json_report" [currentSelectedSurvey]="currentSelectedSurvey"
                [memberId]="memberId"></kt-surveyreport7>
              <kt-surveyreport8 [json_report]="json_report" [currentSelectedSurvey]="currentSelectedSurvey"
                [memberId]="memberId"></kt-surveyreport8>
              <kt-surveyreport9 [json_report]="json_report" [currentSelectedSurvey]="currentSelectedSurvey"
                [memberId]="memberId"></kt-surveyreport9>
              <kt-surveyreport10 [json_report]="json_report" [currentSelectedSurvey]="currentSelectedSurvey"
                [memberId]="memberId"></kt-surveyreport10>
            </div>
            <div #target3 class="target target3">
              <kt-surveyreport11 [json_report]="json_report" [currentSelectedSurvey]="currentSelectedSurvey"
                [memberId]="memberId"></kt-surveyreport11>
            </div>
            <div #target4 class="target target4">
              <kt-surveyreport12 [json_report]="json_report" [currentSelectedSurvey]="currentSelectedSurvey"
                [memberId]="memberId"></kt-surveyreport12>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
    </div>
  </div>
