<kt-spinner></kt-spinner>
<div class="mat-dialog-popup eventSummaryPop">
  <table width="100%">
    <tr>
      <td>
        <h1 class="popup_card_title popup_card_titlesession">
          Session Insights & Actions
        </h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form class="eventSummaryForm" [formGroup]="eventSummaryForm" novalidate enctype="multipart/form-data"
    autocomplete="off">
    <div class="eventSessionInsights">
    <mat-dialog-content class="eventSummaryContent">
      <div class="row">
        <div class="col-md-12 col-lg-7">
          <label class="sess-lab">
            <span *ngIf="session_type == 2550">General Discussion</span>
            <span *ngIf="session_type == 2554">Meaningful Work Survey</span>
            <span *ngIf="session_type == 2556">Mirror Reflection</span>
            <span *ngIf="session_type == 2548">Get Acquainted</span>
              <span *ngIf="session_type == 2549">Be My Guide Session</span>
            <span *ngIf="session_type == 2733">Complimentary</span>
          </label>
          <div class="row">
            <div class="col-sm-6">
              <h6 class="sess-guid">Guided Explorer:</h6>
              <div class="img_section_mirror push_left" style="color: white">
                <img [src]="returnImage(mentee_dp)" alt="" style="
                    cursor: pointer;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                  " />
                <label>&nbsp;&nbsp;{{ mentee }}</label>
              </div>
            </div>
            <div class="col-sm-6">
              <h6 class="sess-guid">Guide:</h6>
              <div class="img_section_mirror push_left">
                <img [src]="returnImage(guide_dp)" alt="" style="
                    cursor: pointer;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                  " />
                <label>&nbsp;&nbsp;{{ guide }}</label>
              </div>
            </div>
            <div class="session-details-nw col-sm-12">
              <h1 class="fs-18">
                Discussion on: &nbsp;
                {{ session_start_date | date: "MMM d, y":current_timezone }}
              </h1>
              <h1 class="fs-18">
                Scheduled Time: &nbsp;{{
                onlyTimeTimeZone(session_start_date)
                }}
                -
                {{ onlyTimeTimeZone(session_end_date) }}
              </h1>
              <h1 class="fs-18">
                Actual Duration: &nbsp;
                <span class="sess-span-time"> {{ actual_duration }} </span>
              </h1>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-5 pl-0">
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field class="example-full-width" style="width: 100%; color: white">
                <mat-label>Write Summary</mat-label>
                <textarea class="p-2 sess-textarea" matInput maxlength="2048" autocomplete="off"
                  oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&!@#$%=*-+?() ]|^ /g,'')" rows="4"
                  formControlName="remarks">
                </textarea>
              </mat-form-field>
            </div>
          </div>
          <mat-dialog-actions>
            <table width="100%" class="mb-15">
              <tr>
                <td width="90%">&nbsp;</td>
                <td>
                  <button *ngIf="guideId == loggedinUserid" class="btn begin_quiz my_btn" type="button"
                    (click)="editSummary(eventSummaryForm)" [disabled]="!eventSummaryForm.valid">
                    SAVE
                  </button>
                </td>
              </tr>
            </table>
          </mat-dialog-actions>
        </div>
      </div>
      <hr />

      <div class="tob-ses-main">
        <mat-tab-group animationDuration="0ms" class="">
          <mat-tab label="INSIGHTS" style="color: white">
            <ng-template matTabContent>
              <mat-card-title>
                <table width="100%">
                  <tr>
                    <td width="95%">&nbsp;</td>
                    <td width="5%">
                      <span *ngIf="guideId == loggedinUserid" class="spa-btn-change-icn my_btn__"
                        (click)="openInsights(2407)"><img src="./assets/media/icon/AddWhite@2x.png" /></span>
                    </td>
                  </tr>
                </table>
                <div class="row"></div>
                <div class="sess-tab-cont scroll_class w-100">
                  <ng-container *ngIf="countinsite > 0">
                    <div class="row" *ngFor="let item1 of allInsightDatas">
                      <div class="col-sm-5 col-md-5 col-lg-3">
                        <div class="row marg-top">
                          <div class="col-sm-3">
                            <img src="{{
                                item1.creating_member[0].picture_path
                                  ? item1.creating_member[0].picture_path
                                  : './assets/media/users/default.png'
                              }}" />
                          </div>
                          <div class="col-sm-9 pr-0">
                            <h1 class="fs-18">
                              {{ item1.creating_member[0].full_name }}
                            </h1>
                            <h1 class="fs-18">
                              {{ item1.creation_date | date: "MMM d, y" }}
                            </h1>
                            <h1 class="fs-18" *ngIf="guideId == loggedinUserid">
                              <span>
                                <mat-icon style="cursor: pointer" title="Edit"
                                  (click)="editInsights(item1.summary_type, item1.id)">edit</mat-icon>
                              </span>&nbsp;<span>
                                <mat-icon style="cursor: pointer" title="Delete" (click)="deleteInsightOrAction(item1.summary_type,item1.id)">delete</mat-icon>
                              </span>
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-7 col-md-7 col-lg-7 pl-0">
                        <p class="sess-tat-cnt-p">
                          {{ item1["remarks"] }}
                        </p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="countinsite == 0">
                    <p>No insights found!</p>
                  </ng-container>
                </div>
              </mat-card-title>
            </ng-template>
          </mat-tab>
          <mat-tab label="ACTIONS" style="color: white">
            <ng-template matTabContent>
              <mat-card-title>
                <table width="100%">
                  <tr>
                    <td width="95%">&nbsp;</td>
                    <td width="5%">
                      <span *ngIf="guideId == loggedinUserid" class="spa-btn-change-icn my_btn__"
                        (click)="openInsights(2406)"><img src="./assets/media/icon/AddWhite@2x.png" /></span>
                    </td>
                  </tr>
                </table>
                <div class="row"></div>
                <div class="sess-tab-cont scroll_class w-100">
                  <ng-container *ngIf="countaction > 0">
                    <div class="row" *ngFor="let item1 of allActionsDatas">
                      <div class="col-sm-5 col-md-5 col-lg-3">
                        <div class="row marg-top">
                          <div class="col-sm-3">
                            <img src="{{
                                item1.creating_member[0].picture_path
                                  ? item1.creating_member[0].picture_path
                                  : './assets/media/users/default.png'
                              }}" />
                          </div>
                          <div class="col-sm-9">
                            <h1 class="fs-18">
                              {{ item1.creating_member[0].full_name }}
                            </h1>
                            <h1 class="fs-18">
                              {{ item1.creation_date | date: "MMM d, y" }}
                            </h1>
                            <h1 class="fs-18" *ngIf="guideId == loggedinUserid">
                              <span>
                                <mat-icon style="cursor: pointer" title="Edit"
                                (click)="editInsights(item1.summary_type, item1.id)">edit</mat-icon>
                               </span>&nbsp;<span>
                                <mat-icon style="cursor: pointer" title="Delete" (click)="deleteInsightOrAction(item1.summary_type,item1.id)">delete</mat-icon>
                              </span>
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-5 col-md-7 col-lg-7">
                        <p class="sess-tat-cnt-p" title="{{ item1['remarks'] }}">
                          {{ item1["remarks"] }}
                        </p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="countaction == 0">
                    <p>No actions found!</p>
                  </ng-container>
                </div>
              </mat-card-title>
            </ng-template>
          </mat-tab>
          <mat-tab label="COMMENTS" style="color: white">
            <ng-template matTabContent>
              <mat-card-title>
                <table width="100%">
                  <tr>
                    <td width="95%">&nbsp;</td>
                    <td width="5%">
                      <span class="spa-btn-change-icn my_btn__" (click)="openInsights(2586)"><img
                          src="./assets/media/icon/AddWhite@2x.png" /></span>
                    </td>
                  </tr>
                </table>
                <!-- <div class="row"></div> -->
                <div class="sess-tab-cont scroll_class w-100">
                  <ng-container *ngIf="countcomment > 0">
                    <div class="row" *ngFor="let comment of allCommentsDatas">
                      <div class="col-sm-5 col-md-5 col-lg-3">
                        <div class="row marg-top">
                          <div class="col-sm-3 col-lg-3">
                            <img src="{{
                                comment.creating_member[0].picture_path
                                  ? comment.creating_member[0].picture_path
                                  : './assets/media/users/default.png'
                              }}" />
                          </div>
                          <div class="col-sm-9 col-lg-9">
                            <h1 class="fs-18">
                              {{ comment.creating_member[0].full_name }}
                            </h1>
                            <h1 class="fs-18">
                              {{ comment.creation_date | date: "MMM d, y" }}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-7 col-md-7 col-lg-9">
                        <p class="sess-tat-cnt-p">
                          {{ comment["remarks"] }}
                        </p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="countcomment == 0">
                    <p>No comments found!</p>
                  </ng-container>
                </div>
              </mat-card-title>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-dialog-content>
   
  </div> <hr/>
    <div class="row">
      <div class="col-sm-12">
        <mat-dialog-actions>
          <table width="100%">
            <tr>
              <td width="100%">&nbsp;</td>
              <td>
                <button mat-dialog-close class="btn begin_quiz button-change-scroll canceled_btn">
                  Cancel
                </button>
              </td>
            </tr>
          </table>
        </mat-dialog-actions>
      </div>
    </div>
  </form>
</div>