// Angular
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
// Auth

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./views/pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./views/pages/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'meaningful-work-survey',
        loadChildren: () =>
          import('./views/pages/map-of-meaning/map-of-meaning.module').then(
            (m) => m.MapOfMeaningModule
          ),
      },
      {
        path: 'meaningful-work-survey/diy-page',
        loadChildren: () =>
          import(
            './views/pages/map-of-meaning/diy-activity/diy-activity.module'
          ).then((m) => m.DiyActivityModule),
      },
      {
        path: 'discover',
        loadChildren: () =>
          import('./views/pages/my-mentor/my-mentor.module').then(
            (m) => m.MyMentorModule
          ),
      },
      {
        path: 'meaning-communities',
        loadChildren: () =>
          import(
            './views/pages/meaning-communities/meaning-communities.module'
          ).then((m) => m.MeaningCommunitiesModule),
      },
      {
        path: 'library',
        loadChildren: () =>
          import(
            './views/pages/meaning-constellation/meaning-constellation.module'
          ).then((m) => m.MeaningConstellationModule),
      },
      {
        path: 'meaning-network',
        loadChildren: () =>
          import('./views/pages/meaning-network/meaning-network.module').then(
            (m) => m.MeaningNetworkModule
          ),
      },
      {
        path: 'meaning-circle',
        loadChildren: () =>
          import('./views/pages/meaning-circle/meaning-circle.module').then(
            (m) => m.MeaningCircleModule
          ),
      },
      {
        path: 'meaning-mirror',
        loadChildren: () =>
          import('./views/pages/meaning-mirror/meaning-mirror.module').then(
            (m) => m.MeaningMirrorModule
          ),
      },

      {
        path: 'admin-page',
        loadChildren: () =>
          import('./views/pages/admin-page/admin-page.module').then(
            (m) => m.AdminPageModule
          ),
      },

      {
        path: 'curator-page',
        loadChildren: () =>
          import('./views/pages/curator-page/curator-page.module').then(
            (m) => m.CuratorPageModule
          ),
      },

      {
        path: 'program-director-page',
        loadChildren: () =>
          import('./views/pages/program-director-page/program-director-page.module').then(
            (m) => m.ProgramDirectorPageModule
          ),
      },

      {
        path: 'my-account',
        loadChildren: () =>
          import('./views/theme/theme.module').then((m) => m.ThemeModule),
      },
      {
        path: 'meaning-network/my-feed',
        loadChildren: () =>
          import(
            './views/pages/meaning-network/meaning-feeds/meaning-feeds.module'
          ).then((m) => m.MeaningFeedsModule),
      },
      {
        path: 'meaningsphere',
        loadChildren: () =>
          import('./views/pages/about/about.module').then((m) => m.AboutModule),
      },
      {
        path: 'meaning-network/my-connections',
        loadChildren: () =>
          import(
            './views/pages/meaning-network/my-connections/my-connections.module'
          ).then((m) => m.MyConnectionsModule),
      },
      { path: '', redirectTo: '/home', pathMatch: 'full' },
    ],
  },

  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
