import { EachVedioComponent } from './../each-vedio/each-vedio.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { AfterSurveySharedDataComponent } from '../after-survey-shared-data/after-survey-shared-data.component';
import { SurveyService } from '../../../../../app/service/survey.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FinalReportFirstPageComponent } from '../final-report-first-page/final-report-first-page.component';

@Component({
  selector: 'kt-report-first-page',
  templateUrl: './report-first-page.component.html',
  styleUrls: ['./report-first-page.component.scss'],
})
export class ReportFirstPageComponent implements OnInit, OnDestroy {
  surveyParticipantId: any;
  _data = {};
  memberId: number;
  fromDia: string;
  currentSelectedSurvey: number;
  json_report: string;
  videoUrl =
    'https://www.youtube.com/embed/kR321k9JVNU?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://voc2.meaningsphere.com';
  constructor(
    public dialogRef: MatDialogRef<ReportFirstPageComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private router: Router,
    private surveyService: SurveyService
  ) {
    this.currentSelectedSurvey = data.currentSelectedSurvey;
    this.memberId = data.memberId;
    this.json_report = data.json_report;
  }

  ngOnInit(): void {}

  goToSelectedSurveyReport() {
    this.closePopUpWindows();
    let dialogRef = this.dialog.open(FinalReportFirstPageComponent, {
      width: '70%',
      disableClose: true,
      data: {
        memberId: this.memberId,
        currentSelectedSurvey: this.currentSelectedSurvey,
        json_report1: this.json_report,
      },
    });
    this.closePopUpWindows();
  }
  closePopUpWindows() {
    this.dialogRef.close();
  }
  onCreate() {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: { source: this.videoUrl },
    });
  }

  backToList() {
    this.router.navigate(['/meaningful-work-survey/my-surveys']);
  }
  openSharedDataForm() {
    this.dialogRef.close();
    this.surveyService
      .checkReportSubmissionDate(this.memberId)
      .subscribe((data) => {
        if (data.status === 200) {
          this.openSharedDataPopUp();
        } else if (data.status === 201) {
          this.goToSelectedSurveyReport();
        }
      });
  }

  openSharedDataPopUp() {
    this.dialog.open(AfterSurveySharedDataComponent, {
      width: '50%',
      disableClose: true,
      data: {
        memberId: this.memberId,
        currentSelectedSurvey: this.currentSelectedSurvey,
        json_report1: this.json_report,
      },
    });
  }
  ngOnDestroy(): void {}
}
