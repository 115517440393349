import { GuideDiscussionComponent } from './../guide-discussion/guide-discussion.component';
import { AdminPageService } from './../../../../service/admin-page.service';
import { NotificationService } from './../../../../service/notification.service';
import { SurveyService } from './../../../../service/survey.service';
import { EachVedioComponent } from './../each-vedio/each-vedio.component';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { VideoListComponent } from '../video-list/video-list.component';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { message_properties } from '../../../../../environments/message_properties';
import { CommunitiesService } from './../../../../service/communities.service';
import { UserMyCommunityDetailsPopupComponent } from './../../meaning-communities/user-my-community-details-popup/user-my-community-details-popup.component';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-next-steps',
  templateUrl: './next-steps.component.html',
  styleUrls: ['./next-steps.component.scss'],
})
export class NextStepsComponent implements OnInit, OnDestroy {
  paticipant: number;
  latestGivenSurveyID: number;
  relationShipData: [];
  guide_id: number;
  guide_count: number;
  guide_full_name: string;
  guide_pic: string;
  community_count: number;
  is_this_user_is_host: string;
  videoUrl =
    'https://www.youtube.com/embed/zma0U-havuE?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://mspr1app.vlabsinc.com';
  firstvideoUrl = 'https://player.vimeo.com/video/759651553?h=e46909e89b';
  secondvideoUrl = 'https://player.vimeo.com/video/756931752?h=94ed0e029f';
  thirdvideoUrl = 'https://player.vimeo.com/video/756931671?h=097256a6bf';
  fourthvideoUrl =
    'https://www.youtube.com/embed/6HVRujRShyI?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://mspr1app.vlabsinc.com';
  userHaveGuideSubscription: Subscription;
  memberPresentSubscription: Subscription;
  surveyLatestSubscription: Subscription;
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private router: Router,
    private surveyService: SurveyService,
    private toastr: NotificationService,
    private adminService: AdminPageService,
    private _cdr: ChangeDetectorRef,
    private communities: CommunitiesService,
    private accountService: AccountsService
  ) {
    iconRegistry.addSvgIcon(
      'arrow-forward',
      sanitizer.bypassSecurityTrustResourceUrl('assets/media/arrow_forward.svg')
    );

    this.getLatestSurveyId();
  }

  ngOnInit(): void {
    this.paticipant = this.accountService.muser;
    this.getLatestSurveyId();
    this.latestGivenSurveyID = Number(
      localStorage.getItem('latestGivenSurveyID')
    );
    this.getLoggedInUserHaveGuide(this.paticipant);
    this.check_logged_in_user_present_in_this_group();
  }

  getLoggedInUserHaveGuide(loggedInUserId) {
    this.userHaveGuideSubscription = this.adminService
      .loggedInUserHaveGuide(loggedInUserId)
      .subscribe((data) => {
        this.relationShipData = JSON.parse(JSON.stringify(data.members))[0];
        this.guide_count = data.count;
        if (this.guide_count > 0) {
          this.guide_id = this.relationShipData['relating_member'];
          this.guide_full_name = this.relationShipData[
            'relating_member__member_table__full_name'
          ];
          this.guide_pic = this.relationShipData[
            'relating_member__member_table__picture_path'
          ];
        }
        this._cdr.detectChanges();
      });
  }

  guideDiscussion(guide_id, guide_full_name, guide_pic) {
    this.dialog.open(GuideDiscussionComponent, {
      disableClose: true,
      width: '40%',
      data: {
        mentorId: guide_id,
        fullName: guide_full_name,
        guide_pic: guide_pic,
      },
    });
  }

  eachViddeoCall() {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: { source: this.videoUrl },
    });
  }
  openVideo() {
    this.dialog.open(VideoListComponent, {
      disableClose: true,
      data: { source: this.videoUrl },
    });
  }
  goToPageDiy() {
    if (this.latestGivenSurveyID !== 0) {
      this.router.navigate(['/meaningful-work-survey/diy-page/first-page']);
    } else {
      this.toastr.showInfo(message_properties.NEXT_STEP_GO_TO_PAGE_INFO, '');
    }
  }

  async getLatestSurveyId() {
    this.surveyLatestSubscription = this.surveyService
      .getLatestSurveyID(this.paticipant)
      .subscribe((data) => {
        this.latestGivenSurveyID = data.latestGivenSurveyID;
        window.localStorage.setItem(
          'latestGivenSurveyID',
          String(this.latestGivenSurveyID)
        );
      });
  }

  linkWithGuide() {
    Swal.fire({
      title: 'You currently do not have a MeaningSphere Guide.',
      text:
        'Click on the ‘Find a Guide’ button to explore Guides in the platform!',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Find a Guide',
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['/discover/search-for-a-guide']);
      }
    });
  }
  goToCommunity() {
    const final_string_send = btoa('1]||||[' + this.is_this_user_is_host);
    this.router.navigate([
      '/meaning-communities/my-community',
      final_string_send,
    ]);
  }
  check_logged_in_user_present_in_this_group() {
    this.memberPresentSubscription = this.communities
      .checkLoggedInUserPresentInThisGroup(this.paticipant, 1)
      .subscribe((data) => {
        this.community_count = data.count;
        this.is_this_user_is_host = data.is_this_user_is_host;
        this._cdr.detectChanges();
      });
  }
  viewDetail() {
    const dialogRef = this.dialog.open(UserMyCommunityDetailsPopupComponent, {
      width: '80%',
      data: {
        communityId: 1,
        is_member_present_in_this_group: 0,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.check_logged_in_user_present_in_this_group();
    });
  }

  firstVideo() {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: { source: this.firstvideoUrl },
    });
  }

  secondVideo() {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: { source: this.secondvideoUrl },
    });
  }

  thirdVideo() {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: { source: this.thirdvideoUrl },
    });
  }

  fourthVideo() {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: { source: this.fourthvideoUrl },
    });
  }

  ngOnDestroy(): void {
    if (this.userHaveGuideSubscription) {
      this.userHaveGuideSubscription.unsubscribe();
    }
    if (this.memberPresentSubscription) {
      this.memberPresentSubscription.unsubscribe();
    }
    if (this.surveyLatestSubscription) {
      this.surveyLatestSubscription.unsubscribe();
    }
  }
}
