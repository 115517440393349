import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  apiUrl = environment.apiUrl;
  private _refreshneeded = new Subject<void>();
  private _messagesListRefreshed = new Subject<void>();
  private _replyMessagesListRefreshed = new Subject<void>();
  private _unreadMessageCountRefreshed = new Subject<void>();
  get refreshneeded() {
    return this._refreshneeded;
  }
  get messagessListRefreshed() {
    return this._messagesListRefreshed;
  }
  get replyMessagesListRefreshed() {
    return this._replyMessagesListRefreshed;
  }
  get unreadMessageCountRefreshed() {
    return this._unreadMessageCountRefreshed;
  }
  constructor(private http: HttpClient) {}

  getAllMessages(relating_member_id, search_text): Observable<MessageData> {
    return this.http.get<MessageData>(
      this.apiUrl +
        'chat/messages/sender/' +
        relating_member_id +
        '?q=' +
        search_text
    );
  }
  getCountAllBroadcast(relating_member_id): Observable<MessageData> {
    return this.http.get<MessageData>(
      this.apiUrl + 'chat/messages/count-broad-cast/' + relating_member_id
    );
  }
  getMessageDetails(
    selected_user_id,
    logged_in_user_id
  ): Observable<MessageData> {
    return this.http.get<MessageData>(
      this.apiUrl +
        'chat/messages/user-messages-details/' +
        selected_user_id +
        '/' +
        logged_in_user_id
    );
  }
  chatCreate(ngForm): Observable<MessageData> {
    return this.http.post<MessageData>(this.apiUrl + 'chat/create', ngForm);
  }
  chatReply(ngForm): Observable<MessageData> {
    return this.http.post<MessageData>(this.apiUrl + 'chat/chat-reply', ngForm);
  }
  getAllChatReply(chat_id): Observable<MessageData> {
    return this.http.get<MessageData>(
      this.apiUrl + 'chat/messages/reply/' + chat_id
    );
  }
  loggedInUserMessageCount(loggedInUserId): Observable<MessageData> {
    return this.http.get<MessageData>(
      this.apiUrl + 'chat/messages/count-total-message/' + loggedInUserId
    );
  }

  postMassage(ngForm) {
    return this.http
      .post<MessageData>(this.apiUrl + 'chat/create', ngForm)
      .pipe(
        tap(() => {
          this._messagesListRefreshed.next();
        })
      );
  }
  message_ChangeReadStatus(ngForm): Observable<ChangeStatusData> {
    return this.http
      .put<ChangeStatusData>(
        this.apiUrl + 'chat/messages/message-read-status-change',
        ngForm
      )
      .pipe(
        tap(() => {
          this._messagesListRefreshed.next();
        })
      );
  }
  systenMessages(loggedInUserId): Observable<MessageData> {
    return this.http.get<MessageData>(
      `${this.apiUrl}chat/messages/system-messages/${loggedInUserId}`
    );
  }
  getdiscussionMessageDetails(
    selected_user_id,
    logged_in_user_id,
    related_id
  ): Observable<MessageData> {
    return this.http.get<MessageData>(
      this.apiUrl +
        'chat/messages/user-discussion-messages-details/' +
        selected_user_id +
        '/' +
        logged_in_user_id +
        '/' +
        related_id
    );
  }
  getAllCountData(loggedInUserId): Observable<AllCountData> {
    return this.http.get<AllCountData>(
      this.apiUrl + 'chat/all-total-count/' + loggedInUserId
    );
  }
  changeReadStatusForBrodCastMessage(ngForm): Observable<ChangeStatusData> {
    return this.http
      .put<ChangeStatusData>(
        this.apiUrl + 'chat/messages/message-read-status-change-for-broadcast',
        ngForm
      )
      .pipe(
        tap(() => {
          this._messagesListRefreshed.next();
        })
      );
  }
}
export interface AllCountData {
  message_count_data: number;
  notification_count_data: number;
  cart_count_data: number;
  event_count_data: number;
}
export interface ChangeStatusData {
  logged_in_user: number;
  receiving_user: number;
}
export interface MessageData {
  chat_for: number;
  criticality: number;
  creation_date: any;
  message: string;
  attachment_path: string;
  chat_parent_id: number;
  creating_user: string;
  related_object_id: string;
  difference: string;
  status: number;
  tenant: number;
  results: [];
  count: number;
  creatingUser: {};
  all_admin_indicator: boolean;
  all_curator_indicator: boolean;
  all_guide_indicator: boolean;
  all_explorer_indicator: boolean;
  all_program_director_indicator: boolean;
  all_role6_indicator: boolean;
  all_role7_indicator: boolean;
  all_role8_indicator: boolean;
  all_role9_indicator: boolean;
  broadcast_message_count: number;
}
