<div class="margin-minus">
  <div mat-dialog-title>
    <button mat-button mat-dialog-close class="modal_close_button_top">X</button>
  </div>

  <div class="mb-1 video-height">
    <ng-container *ngIf="video_url != '0'">
      <vg-player>
        <vg-scrub-bar>
          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>

        <vg-controls>
          <vg-play-pause></vg-play-pause>
          <vg-playback-button></vg-playback-button>

          <vg-time-display
            vgProperty="current"
            vgFormat="mm:ss"
          ></vg-time-display>

          <vg-scrub-bar class="pe-none" ></vg-scrub-bar>

          <vg-time-display
            vgProperty="left"
            vgFormat="mm:ss"
          ></vg-time-display>
          <vg-time-display
            vgProperty="total"
            vgFormat="mm:ss"
          ></vg-time-display>

          <vg-mute></vg-mute>
          <vg-volume></vg-volume>

          <vg-fullscreen></vg-fullscreen>
        </vg-controls>
        <video
          [vgMedia]="media"
          #media
          id="singleVideo"
          preload="auto"
          crossorigin
        >
          <source src="{{ source }}" type="video/mp4" />
          <source src="{{ source }}" type="video/ogg" />
          <source src="{{ source }}" type="video/webm" />
        </video>
      </vg-player>
      <!-- <iframe
        class="iframe_video"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        type="text/html"
        [src]="source"
        
      ></iframe> -->
    </ng-container>
    <ng-container *ngIf="video_url == '0'">
      <p>No data found!</p>
    </ng-container>
  </div>
</div>
