import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { SharedService } from './shared.service';
@Injectable({
  providedIn: 'root',
})
export class MirrorService {
  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private route: Router
  ) {
    this.apiUrl = environment.apiUrl;
  }

  getMirrorData(
    survey,
    sequence,
    survey_type,
    datetime,
    qresponce_id,
    participantSurveyIdForMirror
  ): Observable<Survey> {
    let data = {
      survey: survey,
      sequence: sequence,
      survey_type: survey_type,
      datetime: datetime,
      qresponce_id: qresponce_id,
      participantSurveyId: participantSurveyIdForMirror,
    };
    return this.http.post<Survey>(
      this.apiUrl + 'survey/surveyresponse/get-one-question-detail-with-answer',
      data
    );
  }

  getNextQuestionData(json_data): Observable<Survey> {
    return this.http.post<Survey>(
      this.apiUrl +
        'survey/surveyresponse/submit-answer-with-response-next-question',
      json_data
    );
  }
  getQuestionSubmittedData(user_id, survey_id): Observable<Survey> {
    let data = {
      user_id: user_id,
      survey_id: survey_id,
    };
    return this.http.post<Survey>(
      this.apiUrl + 'survey/surveyresponse/get-question-submitted-data',
      data
    );
  }

  getMirrorListData(
    participant,
    select_year,
    select_drop_down,
    search_input
  ): Observable<Survey> {
    return this.http.get<Survey>(
      this.apiUrl +
        'survey/surveyresponse/all-mirror-list/' +
        participant +
        '?select_year=' +
        select_year +
        '&&select_dropdown=' +
        select_drop_down +
        '&&search_input=' +
        search_input
    );
  }
  getMirrorListbyDateData(
    participant,
    day,
    month,
    year,
    page_number
  ): Observable<Survey> {
    let data = {
      survey_participant: participant,
      surveyday: day != '' ? day : 0,
      surveymonth: month != 0 ? month : 0,
      surveyyear: year != 0 ? year : 0,
      page_number: page_number,
    };
    return this.http.post<Survey>(
      this.apiUrl + 'survey/surveyresponse/all-user-mirror-list-bydate/',
      data
    );
  }
  advancedMirrorSearch(participant, data): Observable<Survey> {
    return this.http.post<Survey>(
      this.apiUrl +
        'survey/surveyresponse/advanced-mirror-search/' +
        participant,
      data
    );
  }
  getMirrorListYearsData(participant): Observable<Survey> {
    return this.http.get<Survey>(
      this.apiUrl +
        'survey/surveyresponse/all-user-mirror-years/' +
        participant +
        ''
    );
  }

  getSingleMirrorDetailsData(survey_participant): Observable<Survey> {
    return this.http.get<Survey>(
      this.apiUrl +
        'survey/surveyresponse/single-mirror-response/' +
        survey_participant +
        ''
    );
  }
  //Update by Prashanth T.K. Jan 11,2021
  updateFeedback(participant_survey, ngForm) {
    return this.http.put<Survey>(
      this.apiUrl + 'survey/mirror-get-or-update/' + participant_survey,
      ngForm
    );
  }
  getParticipantSurvey(param) {
    return this.http.get<Survey>(
      this.apiUrl + 'survey/mirror-get-or-update/' + param
    );
  }
  updateTopics(param, ngForm) {
    return this.http.post<Survey>(
      this.apiUrl + 'survey/surveyresponse/tag-topics/' + param,
      ngForm
    );
  }
  //Update by TK Jan 14,2021
  getAnswers(question, survey_type) {
    return this.http.get<Survey>(
      this.apiUrl +
        'survey/survey-question/get-mirror-choices/' +
        question +
        '/' +
        survey_type
    );
  }
  searchMirrorText(participant, search_text): Observable<Survey> {
    return this.http.get<Survey>(
      this.apiUrl +
        'survey/surveyresponse/advanced-mirror-search/' +
        participant +
        '?search_text=' +
        search_text
    );
  }
  sharedWithGuide(ngForm) {
    return this.http.post<SharedWithGuide>(
      this.apiUrl + 'survey/surveyresponse/shared-with-guide',
      ngForm
    );
  }
  getMirrorListDataPost(ngform): Observable<Mirror> {
    return this.http.post<Mirror>(
      this.apiUrl + 'survey/surveyresponse/all-mirror-list-post',
      ngform
    );
  }
}
export interface SharedWithGuide {
  relationship_type: number;
  object_type: number;
  object_id: number;
  shared_date: string;
  shared_with_member: number;
  member: number;
  status: number;
}
export interface Survey {
  Id: number;
  survey_id: number;
  survey: number;
  sequence: number;
  question_text: string;
  help_text: string;
  answers: any[];
  selected_answer: number;
  survey_response_view: any[];
  max_sequence_number: number;
  number_of_save: string;
  survey_status: number;
  rating: number;
  time_to_complete: any;
  no_of_saves: number;
  start_date: any;
  submission_date: any;
  submitted_at: any;
  feedback: any;
  results: {};
  count: number;
  question: number;
  survey_response: {};
  question_response_text: any[];
  text: string;
  answer_text: any[];
  submitted_answer_free_format: string;
  submitted_answer_option: string;
  qresponce_id: number;
  participant_survey_id: number;
  total_group_response: number;
  checkbox_with_option_texbox: string;
  group_response_selected: {};
  group_selected_one: string;
  group_selected_two: string;
  select_group_text_value_text1: string;
  select_group_text_value_text2: string;
  search_criteria: boolean;
  code: number;
}
export interface Mirror {
  Id: number;
  survey_id: number;
  survey: number;
  sequence: number;
  question_text: string;
  help_text: string;
  answers: any[];
  selected_answer: number;
  survey_response_view: any[];
  max_sequence_number: number;
  number_of_save: string;
  survey_status: number;
  rating: number;
  time_to_complete: any;
  no_of_saves: number;
  start_date: any;
  submission_date: any;
  submitted_at: any;
  feedback: any;
  results: {};
  count: number;
  question: number;
  survey_response: {};
  question_response_text: any[];
  text: string;
  answer_text: any[];
  submitted_answer_free_format: string;
  submitted_answer_option: string;
  qresponce_id: number;
  participant_survey_id: number;
  total_group_response: number;
  checkbox_with_option_texbox: string;
  group_response_selected: {};
  group_selected_one: string;
  group_selected_two: string;
  select_group_text_value_text1: string;
  select_group_text_value_text2: string;
  search_criteria: boolean;
}
