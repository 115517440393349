import { message_properties } from './../../../../../../environments/message_properties';
import { EventCancellationRequestComponent } from './../../event-cancellation-request/event-cancellation-request.component';
import { CuratorPageService } from './../../../../../service/curator-page.service';
import { MeaningNetworkService } from './../../../../../service/meaning-network.service';
import { NotificationService } from '../../../../../service/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MentorService } from '../../../../../service/mentor.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { SendMassageComponent } from '../../../admin-page/send-massage/send-massage.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SessionInsightsComponent } from '../../my-guided-explorers/session-insights/session-insights.component';
import { SetupDiscussionComponent } from './../../setup-discussion/setup-discussion.component';
import { SetupDiscussionViewComponent } from '../../setup-discussion-view/setup-discussion-view.component';
import * as moment from 'moment-timezone';
import { TimezoneService } from '../../../../../service/timezone.service';
import { AccountsService } from './../../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-endorse-discussion',
  templateUrl: './endorse-discussion.component.html',
  styleUrls: ['./endorse-discussion.component.scss'],
})
export class EndorseDiscussionComponent implements OnInit, OnDestroy {
  @Input() member_relationship_id;
  @Input() isPastGuide;
  @Input() first_name;
  @Input() last_name;
  @Input() image_path;
  @Input() mentorId;
  discussions = [];
  relationshipId: number;
  currentTab: number = 1;

  eventId: number;
  mentorDp: string;
  loggedinUserid: number;
  mentee_full_name: string;
  menteeDp: string;
  count: number;
  params: string;
  current_timezone: string;
  public from_date: moment.Moment;
  allGuideExplorerSubscription: Subscription;
  allDiscussionSubscription: Subscription;
  profileSubscription: Subscription;
  joinSubscription: Subscription;
  withdrawSubscription: Subscription;
  constructor(
    private dialog: MatDialog,
    private _cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private toastr: NotificationService,
    private meaning_network_service: MeaningNetworkService,
    private curatorService: CuratorPageService,
    private mentor_service: MentorService,
    private timeZoneService: TimezoneService,
    private accountsService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedinUserid = this.accountsService.muser;
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.curatorService.all_status_change_refresh.subscribe((data) => {
      this.getAllGuideExplorerDiscussionList(
        this.member_relationship_id,
        this.mentorId,
        this.loggedinUserid
      );
    });
    this.getAllGuideExplorerDiscussionList(
      this.member_relationship_id,
      this.mentorId,
      this.loggedinUserid
    );
    this.getGuideProfileDetails();
  }
  changeTab(changeTab: number) {
    this.currentTab = changeTab;
  }

  onChange(deviceValue) {
    this.searchAllGuideExplorerDiscussionList(deviceValue);
  }
  searchAllGuideExplorerDiscussionList(selected_value) {
    const data = {
      selected: selected_value,
    };
    this.spinner.show();
    this.allGuideExplorerSubscription = this.mentor_service
      .getGuideExplorerDiscussionListByPost(
        this.member_relationship_id,
        this.mentorId,
        this.loggedinUserid,
        data
      )
      .subscribe((data) => {
        this.discussions = data.results;
        this.count = data.count;
        this.spinner.hide();
        this._cdref.detectChanges();
      });
  }

  getAllGuideExplorerDiscussionList(member_relationship_id, guide, explorer) {
    this.allDiscussionSubscription = this.mentor_service
      .getGuideExplorerDiscussionList(member_relationship_id, guide, explorer)
      .subscribe((data) => {
        this.discussions = data.results;
        this.count = data.count;
        this._cdref.detectChanges();
      });
  }

  sendMessage(collabContentId, mentorId) {
    this.dialog.open(SendMassageComponent, {
      disableClose: true,
      width: '40%',
      data: {
        collabContentId: collabContentId,
        mentee_id: mentorId,
        clicked_from: 1008,
      },
    });
  }

  getGuideProfileDetails() {
    this.profileSubscription = this.meaning_network_service
      .getProfileDetails(this.loggedinUserid)
      .subscribe((data) => {
        this.mentee_full_name = data.first_name + ' ' + data.last_name;
        this.menteeDp = data.picture_path ?? './assets/media/users/default.png';
      });
  }

  insightsActions(eventId) {
    this.dialog.open(SessionInsightsComponent, {
      disableClose: true,
      width: '60%',
      data: {
        eventId: window.btoa(eventId),
        guideFirstName: this.first_name,
        guideLastName: this.last_name,
        menteeFullName: this.mentee_full_name,
        guideDp: window.btoa(this.image_path),
        menteeDp: window.btoa(this.menteeDp),
      },
    });
  }

  openPopupForDiscussionEditAndMessage(eventId) {
    this.dialog.open(SetupDiscussionViewComponent, {
      disableClose: true,
      data: {
        eventId: eventId,
        dp: this.image_path,
        menteeId: this.mentorId,
        mentee: this.first_name + ' ' + this.last_name,
        clicked_from: 'from_explorer',
      },
    }),
      this.dialog.afterAllClosed.subscribe(() => {
        this.getAllGuideExplorerDiscussionList(
          this.member_relationship_id,
          this.mentorId,
          this.loggedinUserid
        );
      });
  }

  setUpDiscission(eventId) {
    this.dialog.open(SetupDiscussionComponent, {
      disableClose: true,
      width: '40%',
      data: {
        eventId: eventId,
        dp: this.image_path,
        menteeId: this.mentorId,
        mentee: this.first_name + ' ' + this.last_name,
        clicked_from: 'from_explorer',
        member_relationship_id: this.member_relationship_id,
      },
    }),
      this.dialog.afterAllClosed.subscribe(() => {
        this.getAllGuideExplorerDiscussionList(
          this.member_relationship_id,
          this.mentorId,
          this.loggedinUserid
        );
      });
  }

  joinDiscussion(eventId, action, member_meeting_link, zoom_start_join_check) {
    if (zoom_start_join_check) {
      this.toastr.showError(message_properties.ZOOM_MEETING_TIME_ERROR, '');
    } else {
      Swal.fire({
        title: 'Please confirm!',
        text: 'You want to join the discussion.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.joinSubscription = this.curatorService
            .updateEventDiscussionStatusChange(
              eventId,
              action,
              3,
              this.loggedinUserid,
              this.mentorId
            )
            .subscribe((data) => {
              if (data.code === 200) {
                this.toastr.showSuccess(
                  message_properties.JOIN_DISCUSSION_SUCCESS,
                  ''
                );
                this._cdref.detectChanges();
              } else {
                this.toastr.showSuccess(
                  message_properties.JOIN_DISCUSSION_ERROR,
                  ''
                );
                this._cdref.detectChanges();
              }
            });
          window.open(member_meeting_link);
        }
      });
    }
  }

  withdrawnDiscussion(eventId, action) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to withdraw discussion request.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.withdrawSubscription = this.curatorService
          .updateEventDiscussionStatusChange(
            eventId,
            action,
            0,
            this.loggedinUserid,
            this.mentorId
          )
          .subscribe((data) => {
            if (data.code === 204) {
              this.toastr.showError(data.message, '');
            } else if (data.code === 200) {
              this.toastr.showSuccess(
                message_properties.WITHDRAW_DISCUSSION_SUCCESS,
                ''
              );
              this._cdref.detectChanges();
            } else {
              this.toastr.showSuccess(
                message_properties.WITHDRAW_DISCUSSION_ERROR,
                ''
              );
              this._cdref.detectChanges();
            }
          });
      }
    });
  }
  requestCancelledDiscussion(event_id, status, difference) {
    if (difference < 24) {
      Swal.fire({
        title:
          'The discussion you are requesting to cancel is within the next 24 hours. Your guide may choose to deduct payment from your balance.',
        text: 'Are you sure you want to request for a cancelation?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.dialog.open(EventCancellationRequestComponent, {
            disableClose: true,
            data: {
              eventId: event_id,
              guide: this.mentorId,
              explorer: this.loggedinUserid,
              clicked_from: 'endorse_guide',
            },
          });
        }
      });
    } else {
      this.dialog.open(EventCancellationRequestComponent, {
        disableClose: true,
        data: {
          eventId: event_id,
          guide: this.mentorId,
          explorer: this.loggedinUserid,
          clicked_from: 'endorse_guide',
        },
      });
    }
  }
  viewDetails(event_id, created_by_guide, member_relationship) {
    this.dialog.open(SetupDiscussionViewComponent, {
      disableClose: true,
      data: {
        eventId: event_id,
        dp: this.image_path,
        menteeId: this.mentorId,
        mentee: this.first_name + ' ' + this.last_name,
        clicked_from: 'view_only',
        created_by_guide: created_by_guide,
        member_relationship_id: member_relationship,
      },
    });
  }
  eventType(event) {
    if (event === 2550) {
      return 'General';
    } else if (event === 2554) {
      return 'Meaningful Work Survey';
    } else if (event === 2556) {
      return 'Mirror Reflection';
    } else if (event === 2548) {
      return 'Get Acquainted';
    } else if (event === 2733) {
      return 'Complimentary';
    } else {
      return 'DIY';
    }
  }
  // # 0= Draft/Requested, 1 = publish,
  // # 2 = Canceled, 3 = SCHEDULED/Accept,
  // # 4 = Auto cancelled, 5= Complete,
  // # 6 = Marked Complete,
  // # 7= Auto complete,
  // # 8 = withdrawn,
  // # 9 = Declined Request,
  // # 11 = Join
  StatusChange(status) {
    if (status === 0) {
      return 'Requested';
    } else if (status === 1) {
      return 'Published';
    } else if (status === 2) {
      return 'Canceled';
    } else if (status === 3) {
      return 'Scheduled';
    } else if (status === 4) {
      return 'Canceled'; // Auto canceled
    } else if (status === 5) {
      return 'Completed';
    } else if (status === 6) {
      return 'Closed';
    } else if (status === 7) {
      return 'Completed'; // Auto Completed
    } else if (status === 8) {
      return 'Withdrawn';
    } else if (status === 9) {
      return 'Request Declined';
    } else if (status === 10) {
      return 'Cancelation Requested';
    } else if (status === 11) {
      return 'Canceled due to request';
    } else if (status === 12) {
      return 'Auto Withdrawn';
    }
  }

  requestCancelcomplimentaryDis(event_id, status, difference) {
    this.dialog.open(EventCancellationRequestComponent, {
      disableClose: true,
      data: {
        eventId: event_id,
        guide: this.mentorId,
        explorer: this.loggedinUserid,
        clicked_from: 'endorse_guide',
      },
    });
  }
  dateInUserTimezome(datetime: string) {
    this.from_date = moment(datetime).utc();
    this.timeZoneService.setTenantTimeZone(this.current_timezone);
    return this.timeZoneService.utcToTenantString(this.from_date, 'LLL');
  }
  ngOnDestroy(): void {
    if (this.allGuideExplorerSubscription) {
      this.allGuideExplorerSubscription.unsubscribe();
    }
    if (this.allDiscussionSubscription) {
      this.allDiscussionSubscription.unsubscribe();
    }
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    if (this.joinSubscription) {
      this.joinSubscription.unsubscribe();
    }
    if (this.withdrawSubscription) {
      this.withdrawSubscription.unsubscribe();
    }
  }
}
