import { DomSanitizer } from '@angular/platform-browser';
import { environment } from './../../../../../environments/environment';
import { MeaningConstellationService } from '../../../../service/meaning-constellation.service';
import { MeaningLogAndTreasurePopupComponent } from './../meaning-log-and-treasure-popup/meaning-log-and-treasure-popup.component';
import { MeaningFeedsService } from '../../../../service/meaning-feeds.service';
import { MatDialog } from '@angular/material/dialog';
import { UploadService } from '../../../../service/upload.service';
import { NotificationService } from '../../../../service/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { message_properties } from '../../../../../environments/message_properties';

import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Subscription } from 'rxjs';
import { folder_path } from '../../../../../environments/folder_path';
import { AccountsService } from './../../../../service/accounts.service';

export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(4, 200, 400);
  }
}
@Component({
  selector: 'kt-add-meaning-log',
  templateUrl: './add-meaning-log.component.html',
  styleUrls: ['./add-meaning-log.component.scss'],
})
export class AddMeaningLogComponent implements OnInit, OnDestroy {
  writeAPostForm: boolean = false;

  config = {
    placeholder: 'Enter a brief description',
    tabsize: 2,
    height: 150,
    // uploadImagePath: '/api/upload',
    toolbar: [],
    disableDragAndDrop: true,
    disableResizeEditor: true,
    fontsize: ['16'],
    fontNames: ['Lato'],
    lineHeights: ['0.2'],
    shortcuts: false,
    codeviewFilter: true,
    codeviewIframeFilter: true,
    callbacks: {
      onPaste: function (e) {
        const bufferText = (e.originalEvent || e).clipboardData.getData('Text');
        e.preventDefault();
        document.execCommand('insertText', true, bufferText);
      },
    },
  };

  profileData: {};
  ecount: number;
  emcount: number;
  vcount: number;
  adcount: number;
  full_name: any;
  memberid: any;
  shwUpload: boolean;
  message: any;
  changePasswordForm: UntypedFormGroup;
  memberId: string;
  first_name: string;
  last_name: string;
  adminIndicator: boolean = false;
  topics: Array<any>;
  fileToUpload: File;
  profile_path: any;
  meaningLog = [];
  myTreasure = [];
  ccForm: UntypedFormGroup;
  loggedInUserFullName: string;
  city: string;
  state: string;
  country: string;
  loggedInUser: number;

  imageUrl: File;
  videoUrl: File;
  linkUrl: string;
  vedio_path: any;
  image_path: any;
  enableUrl: boolean = true;
  video_preview: boolean = false;
  link_preview: boolean = false;
  image_preview: boolean = false;
  link_preview_data: any;
  loggedInUserImage: string;
  mentor_indicator: boolean = false;
  count: number;
  topic_count: number;
  videoSize: number;
  imageSize: number;
  saveButton: boolean = false;
  file_name: string = '';
  image_link_uploaded: boolean = false;

  linkSubscription: Subscription;
  createLogSubscription: Subscription;
  constructor(
    private meaningFeed: MeaningFeedsService,
    private meaningMoments: MeaningConstellationService,
    private spinner: NgxSpinnerService,
    private toastr: NotificationService,
    private cdRef: ChangeDetectorRef,
    private upload: UploadService,
    private route: ActivatedRoute,
    private aniDialog: MatDialog,
    public sanitizer: DomSanitizer,
    private imageCompress: NgxImageCompressService,
    private accountService: AccountsService
  ) {
    this.videoSize = environment.videoSize;
    this.imageSize = environment.imageSize;
  }
  async findMemberId() {
    await this.route.queryParams.subscribe((params) => {
      return (this.memberId = params['member']);
    });
  }
  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
    this.loggedInUserFullName = this.accountService.full_name;
    this.city = this.accountService.city ? this.accountService.city + ' ,' : '';
    this.state = this.accountService.state
      ? this.accountService.state + ' ,'
      : '';
    this.country = this.accountService.country
      ? this.accountService.country
      : '';
    this.adminIndicator = this.accountService.admin_indicator ? true : false;

    this.findMemberId();
    if (this.memberId) {
      this.memberid = this.memberId;
    } else {
      this.memberid = this.accountService.muser;
    }
    const regExp = /^(http:\/\/|https:\/\/|http:\/\/www.|https:\/\/www.|ftp:\/\/www.|www.)?([0-9A-Za-z]+\.)([A-Za-z]){2,3}(\/)?/;
    this.ccForm = new UntypedFormGroup({
      title: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(450),
      ]),
      postDetail: new UntypedFormControl('', [Validators.required]),
      textUrl: new UntypedFormControl('', [Validators.pattern(regExp)]),
      tagAsMeaningLog: new UntypedFormControl(false),
      tagAsMeaningPractice: new UntypedFormControl(false),
      member: new UntypedFormControl(this.loggedInUser),
      imageUrl: new UntypedFormControl(''),
      videoUrl: new UntypedFormControl(''),
      bucket_type: new UntypedFormControl(2564),
      collab_content_category: new UntypedFormControl(2557),
    });
  }

  collabContentDetails(collabContentId, member_in_bucket) {
    this.aniDialog.open(MeaningLogAndTreasurePopupComponent, {
      disableClose: true,
      width: '50%',
      position: { right: '0' },
      // animation: { to: 'left' },
      data: {
        collabContentId: collabContentId,
        member_in_bucket: member_in_bucket,
        clickFrom: 2564,
      },
    });
  }
  linkPreview(event) {
    this.linkUrl = event.target.value;
    if (this.linkUrl.includes('http://')) {
      this.linkUrl = this.linkUrl.replace('http://', '');
    }
    if (this.linkUrl.includes('https://')) {
      this.linkUrl = this.linkUrl.replace('https://', '');
    }
    if (this.linkUrl.includes('/')) {
      this.linkUrl = this.linkUrl.split('/')[0];
    }
    this.linkSubscription = this.meaningFeed
      .linkPreview(this.linkUrl)
      .subscribe((data) => {
        this.link_preview_data = data['result'];
        if (
          this.link_preview_data.image !== null &&
          this.link_preview_data.image !== ''
        ) {
          this.image_path = this.link_preview_data.image;
          this.image_link_uploaded = true;
          this.link_preview = true;
          this.image_preview = true;
          // when url has a image in
          this.ccForm.patchValue({
            imageUrl: this.link_preview_data.image,
          });
        } else {
          let formData = this.ccForm.value;
          this.image_path = formData['imageUrl'];
          if (this.image_path !== '' && this.image_path !== null) {
            this.image_link_uploaded = true;
          } else {
            this.image_link_uploaded = false;
          }
          this.link_preview_data = false;
          this.link_preview = false;
        }
        this.cdRef.detectChanges();
      });
    this.cdRef.detectChanges();
  }
  imageRemove() {
    this.image_path = undefined;
    this.ccForm.patchValue({
      imageUrl: '',
    });
    this.link_preview = false;
    this.image_preview = false;
    this.image_link_uploaded = false;
  }
  async createLog(ngForm) {
    this.spinner.show();
    this.saveButton = true;
    this.createLogSubscription = this.meaningMoments
      .createMeaningMoments(ngForm)
      .subscribe(
        (data) => {
          if (data.status === 201) {
            this.empltyForm();
            this.spinner.hide();
            this.aniDialog.closeAll();
            this.toastr.showSuccess(
              message_properties.MEANING_LOG_CREATE_SAVE_SUCCESS,
              ''
            );
          } else if (data.status === 204) {
            this.saveButton = false;
            this.spinner.hide();
            this.toastr.showError(data.message, '');
          } else {
            this.saveButton = false;
            this.spinner.hide();
            this.toastr.showError(
              message_properties.MEANING_LOG_CREATE_SAVE_ERROR,
              ''
            );
          }
        },
        (error) => {
          this.saveButton = false;
          this.spinner.hide();
          this.toastr.showError(
            message_properties.MEANING_LOG_CREATE_SAVE_ERROR,
            ''
          );
        }
      );
  }

  empltyForm() {
    this.imageRemove();
    this.link_preview = false;
    this.image_preview = false;
    this.enableUrl = false;
    this.imageUrl = undefined;
    this.ccForm.reset();
    this.ccForm.patchValue({
      title: '',
      postDetail: '',
      videoUrl: '',
      imageUrl: '',
      textUrl: '',
      tagAsMeaningLog: false,
      tagAsMeaningPractice: false,
      member: this.loggedInUser,
      bucket_type: 2564,
      collab_content_category: 2557,
    });
  }
  changeDesc(event) {
    if (event !== undefined) {
      this.ccForm.patchValue({
        postDetail: event,
      });
    }
  }
  imageUpload() {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      let mimeType = image.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
      let letmineTypeArr = mimeType.split('/');
      if (
        mimeType !== 'image/jpeg' &&
        mimeType !== 'image/jpg' &&
        mimeType !== 'image/png'
      ) {
        this.toastr.showError(
          message_properties.MEANING_CENTRAL_PROFILE_HANDLE_FILE_INPUT_ERROR,
          ''
        );
        return false;
      }
      if (this.imageCompress.byteCount(image) > this.imageSize) {
        this.toastr.showError(
          message_properties.CREATE_MEANING_CONSELLATION_POPUP_IMAGE_PREVIEW_SIZE_ERROR,
          ''
        );
        return false;
      }
      this.imageCompress
        .compressFile(image, orientation, 40, 40) // 50% ratio, 50% quality
        .then((compressedImage) => {
          this.image_path = compressedImage;
          this.image_preview = true;

          let base64ToImage = this.upload.base64toFileInformation(
            compressedImage,
            (Math.floor(Math.random() * 1000) + 1).toString() +
              Date.now().toString() +
              '.' +
              letmineTypeArr[1]
          );
          this.upload.fileUpload(base64ToImage, base64ToImage.name, 1);
          this.file_name = `${this.accountService.AWS_BUCKET_888}${folder_path.folder_content}${base64ToImage.name}`;
          this.ccForm.patchValue({
            imageUrl: this.file_name,
          });
          this.cdRef.detectChanges();
          this.image_link_uploaded = true;
        });
    });
  }
  ngOnDestroy(): void {
    if (this.linkSubscription) {
      this.linkSubscription.unsubscribe();
    }
    if (this.createLogSubscription) {
      this.createLogSubscription.unsubscribe();
    }
  }
}
