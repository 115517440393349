<ngx-spinner
  size="small"
  class="preload-btn colorw"
  bdColor="transparent"
  [fullScreen]="false"
  template="<img src='/assets/media/loader.gif'/>"
>
</ngx-spinner>
<div class="card register_card absolutueCenter lg loginn-card">
  <div class="title_section login_title mb-2 p-0">
    <h1 mat-dialog-title class="d-flex justify-content-between">
      Privacy Policy
    </h1>
    <button class="close-dialog" routerLink="/home">X</button>
  </div>
  <p>
    Please read the following policy details. You must accept the privacy policy
    as you start your journey.
  </p>
  <div
    class="agreement scroll_class_list"
    infinite-scroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    (scrolled)="updateScrollDown($event)"
  >
    <h2>MeaningSphere Privacy Policy</h2>
    <p>
      <strong>Effective Date: [07/16/2021] </strong><br />
      <strong>Last Updated: [06/02/2022]</strong>
    </p>
    <p>
      MeaningSphere LLC (<strong
        >&ldquo;MeaningSphere,&rdquo; &ldquo;we,&rdquo;
        &ldquo;us,&rdquo;</strong
      >
      or <strong> &ldquo;our&rdquo;</strong>) respects your privacy.&nbsp; We
      are a networking platform that offers its users the opportunity to connect
      with each other regarding workplace fulfillment and other matters
      important to them.&nbsp;
    </p>
    <p>
      This Privacy Policy (<strong>&ldquo;Privacy Policy&rdquo;</strong> or
      <strong>&ldquo;Policy&rdquo;</strong>) describes the types of personal
      data we collect from you and other people with whom we communicate and
      interact via our website at www.meaningsphere.com and all related websites
      and services that enable website visitor access on our behalf (our
      &ldquo;<strong>Sites</strong>&rdquo;), as well as other online or offline
      channels, in the course of providing our workplace fulfillment services
      (&ldquo;<strong>Services</strong>&rdquo;).&nbsp; This Policy also
      describes how we use personal data, under what circumstances we may share
      it and with whom, and your rights and choices.
    </p>
    <p>
      In some instances, this Policy may be in addition to other terms,
      conditions, or disclosures that apply to other programs involving our
      collection of personal data about you (&ldquo;<strong
        >Additional Terms and Policies</strong
      >&rdquo;).&nbsp; To the extent there is a conflict between this Policy and
      any Additional Terms and Policies, the Additional Terms and Policies will
      control unless they expressly state otherwise. This Policy does not apply
      to third-party sites, products, or services, even if they link to our
      Sites or Services, and we encourage you to read the privacy policies of
      any third-party site with which you choose to interact.
    </p>
    <p>
      Through your continued use of our Services, you acknowledge the
      collection, use, and sharing of your personal data as described in this
      Privacy Policy.&nbsp; If you object to anything in this Privacy Policy,
      you may cease using the Services and, if applicable, terminate your
      account.
    </p>
    <h2>Click the links below for further details</h2>
    <ol>
      <li>
        <span style="text-decoration: underline">
          <a href="#Personal" style="cursor: pointer !important">
            <strong class="cursorp">Personal Data We Collect.</strong></a
          ></span
        >&nbsp; We collect Personal Data when you register and utilize our
        Services, including automatically collecting data.
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#How"
            ><strong class="cursorp">How We Use Your Personal Data.</strong></a
          ></span
        >&nbsp; We use your Personal Data in many ways, including to provide our
        Site and Services, communicate with you, maintain a safe and secure
        environment, and comply with legal obligations.
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Whom"
            ><strong class="cursorp"
              >Whom We Share Your Personal Data With.</strong
            ></a
          ></span
        >&nbsp; We share your Personal Data only for our own internal purposes,
        to promote our Services, and according to your notice and user
        preferences, such as for optional research purposes with trusted
        research partners.
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Your"
            ><strong class="cursorp">Your Rights and Choices.</strong></a
          ></span
        >&nbsp; You can change various privacy and communications preferences at
        any time, and you may exercise privacy rights as further described
        below.
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Cookie"
            ><strong class="cursorp">Cookie Policy.&nbsp;</strong></a
          ></span
        >
        We may collect Personal Data from you automatically through the use of
        cookies and other tracking technologies.
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Data"
            ><strong class="cursorp">Data Security and Retention.</strong></a
          ></span
        >&nbsp; We maintain appropriate technical, administrative and physical
        safeguards to help protect the security of your personal data.
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#International"
            ><strong class="cursorp">International Data Transfers.</strong></a
          ></span
        >&nbsp; We may transfer personal data, including sensitive data, to the
        United States or other countries from which the personal data was
        collected for storage, processing, and use by MeaningSphere. &nbsp;
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Use"
            ><strong class="cursorp">Use by Minors.</strong></a
          ></span
        >&nbsp; We do not knowingly collect personal data from individuals under
        the age of 18 years without parental consent. &nbsp;
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Links"
            ><strong class="cursorp">Links to Other Sites.</strong></a
          ></span
        >&nbsp; This Privacy Policy does not apply to third-party sites. &nbsp;
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Updates"
            ><strong class="cursorp">Updates to This Privacy Policy.</strong></a
          ></span
        >&nbsp; We may update this Privacy Policy from time to time.&nbsp;
      </li>
    </ol>
    <h2 id="Personal">1) Personal Data We Collect</h2>
    <p>
      For the purposes of this Privacy Policy, &ldquo;personal data&rdquo; means
      any information that relates to an identified or identifiable
      individual.&nbsp; Personal data does not include publicly available
      information or information that has been anonymized, de-identified, or
      aggregated. &nbsp;
    </p>
    <ol style="list-style-type: lower-alpha">
      <ol style="list-style-type: lower-alpha">
        <li>
          <strong>Personal Data You Provide Us </strong>MeaningSphere may
          collect the following categories of personal data about you that you
          choose to provide to us when you use the Sites and Services:
          <ul style="list-style-type: disc">
            <li>
              <strong>Contact information.</strong>&nbsp; You may be required or
              invited to provide various contact information, including your
              name, email address, and user name, in order to create an account
              with us, sign up to receive our newsletters, updates, or other
              communications, or to receive customer service.
            </li>
            <li>
              <strong>User profile.</strong>&nbsp; In order to create your user
              profile on the Service and maximize your user experience, you will
              have the opportunity throughout your experience with MeaningSphere
              to provide information about yourself, including your photograph,
              location, work history, education, experience, volunteer
              experience, skills and certifications, interest-based preferences,
              and demographic details.&nbsp; For example, MeaningSphere may
              prompt you with voluntary questions to enhance your user
              experience, such as our &ldquo;What Matters To You?&rdquo; feature
              or you may choose to complete surveys such as the Map of Meaning
              Survey.&nbsp; You also have the opportunity to provide basic
              biographic information (such as your first and last name, city,
              state, and country), work experience information (such as your
              company, role, and its location), education information (such as
              your school, degree, field of study, and dates of study), and
              volunteering information (such as your role, organization, and its
              location).
            </li>
            <li>
              <strong>Posted content.</strong>&nbsp; When you use our Service,
              including features such as Meaning Mirror&trade;, Map of Meaning,
              Meaning Circles, Meaning Network, and Meaning Communities, you
              will have the opportunity to submit and/or post content for all
              users, certain users, or individual users.&nbsp; That content may
              include personal data provided by you.
            </li>
            <li>
              <strong>Guide engagement.</strong>&nbsp; Users who choose to
              engage with MeaningSphere as a MeaningSphere Guide
              (&ldquo;<strong>Guide</strong>&rdquo;) will be required to submit
              additional personal data in order for MeaningSphere to evaluate
              new Guides, perform quality control, and help ensure that Guides
              are properly supported.&nbsp; Please note that MeaningSphere may
              have additional privacy notices that are applicable to Guides,
              which will be presented at or before the individual engages to
              become a Guide or otherwise accesses their Guide account.
            </li>
            <li>
              <strong>Communication information.</strong>&nbsp; When you respond
              to MeaningSphere emails or messages, we may collect your email
              address, name, and any other personal data you choose to include
              in the body of your email or responses.&nbsp; In addition, when
              you interact with certain MeaningSphere features, such as our My
              Guide program, we may collect the content of those communications,
              including recorded messages, videos, or audio.
            </li>
            <li>
              <strong>Payment information.</strong>&nbsp; If you sign up for a
              paid product or service from us, you may be required to provide
              your payment card or bank account information.
            </li>
          </ul>
        </li>
        <li>
          <strong>Personal Data From Other Users </strong>MeaningSphere may
          collect certain personal data about you that is included in content
          provided by other users and Guides, such as your professional
          affiliations, accomplishments, or publications, or your participation
          in specific Meaning Circles and Meaning Communities, in order to
          enhance your profile and improve the Services that we provide to you
          and our users.
        </li>
        <li>
          <strong>Personal Data From Third-Party Sources</strong> We may collect
          personal data about you from third-party sources, including service
          providers, business partners and other third parties to help us
          provide and improve the Sites and Services.&nbsp; These third parties
          collect and provide this personal data to us pursuant to their privacy
          policies.&nbsp; We may supplement the information that you provide
          with information that we obtain from these sources, including both
          online and offline data providers.
        </li>
        <li>
          <strong>Personal Data We Automatically Collect </strong>When you
          interact with our Site and Services, we may automatically collect data
          regarding your online behavior, including your browsing and usage
          history, information regarding how and when you access our Services,
          devise and browser information, and geolocation (&ldquo;<strong
            >Usage Data</strong
          >&rdquo;).&nbsp; This data may include your Internet Protocol
          (&ldquo;IP&rdquo;) address, device identification number, and similar
          identifiers.&nbsp; This data is collected using various technologies,
          including cookies, pixels, and similar tracking technologies, that may
          be administered by us or by third parties.&nbsp; Please review our
          <a href="Cookie">Cookie Policy</a> for more information regarding the
          use of these technologies.&nbsp; <br />Our Site uses Google Analytics
          in order to maintain and improve our web-based services.&nbsp; Google
          Analytics may automatically collect personal data about you, such as
          your IP address, in order to track and report website traffic.&nbsp;
          Google uses the collected data to monitor and analyze the use of our
          Sites and Services solely for our benefit.&nbsp; The third party
          cookies used for Google Analytics may be kept for up to two years.If
          you have already consented to the Google Analytics tracker, you can
          choose to block or delete the Google Analytics tracker by referring to
          your browser&rsquo;s settings and instructions.&nbsp; For more
          information on Google&rsquo;s privacy practices, please visit the
          Google Privacy Terms web page. Your browser or device may include
          &ldquo;Do Not Track&rdquo; functionality.&nbsp; At this time, the Site
          does not respond to browser &ldquo;Do Not Track&rdquo; signals.
        </li>
        <li>
          <strong>Anonymized and De-identified Information</strong> We may
          create anonymous or de-identified data from personal data by removing
          data components that make the data personally identifiable to you or
          through obfuscation or other means.&nbsp; Our use of anonymized and
          de-identified data, data which no longer can be used to identify you
          or any other particular individual, is not subject to this Privacy
          Policy.
        </li>
      </ol>
    </ol>
    <h2 id="How">2) How We Use Your Personal Data</h2>
    <p>
      We rely on one or more legal bases to process your personal data under
      applicable law.&nbsp; We may process personal data (i) as necessary to
      perform our contractual obligations to you, including, but not limited to,
      those obligations laid our in our Terms of Service; (ii) as necessary to
      pursue our legitimate interests; (iii) as necessary for our compliance
      with our legal obligation such as a request or order from courts, law
      enforcement or other government authorities; and/or (iv) with your
      consent, including to send you marketing email messages and other
      information that may be of interest to you. &nbsp;
    </p>
    <p>
      <strong>Legitimate business interests.&nbsp;</strong> We may collect,
      process, and maintain personal data to pursue the legitimate business
      interests outlined below.&nbsp; To determine these legitimate interests,
      we balance our legitimate interests against the legitimate interests and
      rights of you and others.
    </p>
    <ul style="list-style-type: disc">
      <li>
        <strong
          >Provide, improve, and develop the MeaningSphere Site and
          Services</strong
        >, including to deliver your requested Services, send you messages and
        provide user support, customize the Services to better fit your needs as
        a user, develop new Services, and perform internal analytics and
        research and development.&nbsp; This also includes sharing personal data
        with our trusted service providers that provide services on our behalf.
      </li>
      <li>
        <strong
          >Protect you and others and to create and maintain a trusted
          environment</strong
        >, such as to comply with our legal obligations, to ensure compliance
        our agreements with you and other third parties, to ensure safe, secure,
        and reliable Sites and Services, and to detect and prevent wrongdoing
        and crime, assure compliance with our policies, and protect and defend
        our rights, interests, and property.&nbsp; In connection with the
        activities above, we may conduct internal research and profiling based
        on your interactions as a participant in various MeaningSphere programs
        (such as My Guide), content you submit to the Site and Services, and
        information obtained from third parties.&nbsp; In limited cases,
        automated processes may restrict or suspend access to the Site and
        Services if such processes detect activity that we think poses a safety
        or other risk to the Site and Services , our community, or third
        parties.&nbsp; If you challenge the decisioning based on the automated
        process, please email us at dataprivacy@meaningsphere.com. &nbsp;
      </li>
      <li>
        <strong>Provide, personalize, measure and improve our marketing</strong
        >, including to send you promotional messages and other information that
        may be of interest to you with your consent, and administer and invite
        you to events sponsored or managed by MeaningSphere.&nbsp; We may also
        use personal data to understand our user base and the effectiveness of
        our marketing.&nbsp; This processing is done pursuant to our legitimate
        interest in undertaking marketing activities to offer products or
        services that may be of interest to you.
      </li>
    </ul>
    <h2 id="Whom">3) Whom We Share Your Personal Data With</h2>
    <p>
      MeaningSphere may share your personal data with the trusted entities
      described below:
    </p>
    <ul style="list-style-type: disc">
      <li>
        <strong>Our service providers,</strong> as necessary to provide services
        on our behalf, such as website hosting, data analysis, marketing, email
        delivery, technical support, and customer service.
      </li>
      <li>
        <strong>Our partners</strong> in connection with providing or promoting
        our Sites and Services. This may include the optional sharing of certain
        of your information with our select and trusted third party research
        partners (&ldquo;Research Partners&rdquo;), such as where you elect to
        share your responses in the Map of Meaning Survey, which are
        pseudonymized and shared with the Research Partners identified in
        Appendix A.Other users and Guides, consistent with your notice,
        preferences, and/or consent and in connection with your use of our Site
        and Services and interaction with various MeaningSphere features.
      </li>
      <li>
        <strong>In connection with a business transaction,</strong> subject to
        applicable law, in the event of a merger, acquisition, bankruptcy, or
        other sale or transfer of all or a portion of our assets.&nbsp; If any
        such transaction occurs, the purchaser will be entitled to use and
        disclose the personal data collected by us in the same manner that we
        are able to use it, and the purchaser will assume the rights and
        obligations regarding personal data as described in this Privacy Policy.
      </li>
      <li>
        <strong>For compliance and harm prevention,</strong> including to
        respond to subpoenas, court orders, or legal process, or to establish or
        exercise our legal rights or defend against legal claims.&nbsp; We also
        may provide information in order to investigate, prevent, or take action
        regarding illegal activities or wrongdoing; to protect the rights,
        property, or safety of MeaningSphere, its users, and others; to enforce
        our agreements; or as otherwise required by law.
      </li>
    </ul>
    <h2 id="Your">4) Your Rights and Choices</h2>
    <ol style="list-style-type: lower-alpha">
      <li>
        <strong>Electronic Marketing Communications &nbsp;</strong>If you would
        like to stop receiving a MeaningSphere newsletter via email or other
        electronic marketing or promotional messages, notifications, or updates,
        you may do so by following the unsubscribe instructions that appear in
        these email communications.&nbsp; You may also opt-out of these messages
        by <a href="#Contact">contacting us.</a>&nbsp; Please be advised that
        you may not be able to opt-out of receiving certain service or
        transactional messages from us.
      </li>
      <li>
        <strong
          >Rights for Residents of the EEA, the UK, and Switzerland </strong
        >In certain circumstances, residents of the European Economic Area
        (EEA), the United Kingdom (UK), and Switzerland are entitled to the
        following data protection rights:
        <ul style="list-style-type: disc">
          <li>
            <strong>Right to access.</strong>&nbsp; You have the right to
            request confirmation of whether MeaningSphere processes personal
            data relating to you, and if so, to request a copy of that personal
            data.
          </li>
          <li>
            <strong>Right to rectification.</strong>&nbsp; You have the right to
            request that MeaningSphere corrects or updates your personal data
            that is inaccurate, incomplete or outdated.
          </li>
          <li>
            <strong>Right to deletion.</strong>&nbsp; You have the right to
            request that MeaningSphere erase your personal data in certain
            circumstances provided by law.
          </li>
          <li>
            <strong>Right to restrict processing.</strong>&nbsp; You have the
            right to request that MeaningSphere restrict the use of your
            personal data in certain circumstances.
          </li>
          <li>
            <strong>Right to object to processing.</strong>&nbsp; You have the
            right to object to MeaningSphere&rsquo;s processing of your personal
            data, under certain conditions.
          </li>
          <li>
            <strong>Right to data portability.</strong>&nbsp; You have the right
            to request that MeaningSphere export the data that we have collected
            to you or another company, under certain conditions.
          </li>
        </ul>
        Where the processing of your personal data is based on your previously
        provided consent, you have the right to withdraw your consent at any
        time.&nbsp; If you would like to exercise any of these rights, please
        submit a written request via the email address available here. You also
        have the right to lodge a complaint about our data collection and
        processing actions with the appropriate supervisory authority.&nbsp; You
        can view the contact information for your data protection authority
        <a href="#Contact">here</a>.
      </li>
      <li>
        <strong>California &ldquo;Shine the Light&rdquo; Law </strong>California
        residents have the right to request information regarding third parties
        to whom MeaningSphere has disclosed certain categories of personal data
        during the preceding year for the third parties&rsquo; direct marketing
        purposes under California&rsquo;s &ldquo;Shine the Light&rdquo; law
        (Cal. Civ. Code &sect; 1798.83).&nbsp; We do not currently disclose this
        type of personal data to third parties for their own purposes.&nbsp;
        However, if you are a California resident and would like to inquire
        further, pleasesubmit a written request using the contact information
        available <a href="#Contact">here</a>.&nbsp; You must include in your
        email or mail request your full name, email address and postal address
        so that we can verify your California residence and respond.
      </li>
      <li>
        <strong>Your Nevada Privacy Rights </strong>Nevada residents have the
        right to request to opt out of any sale of their personal information
        under Nevada SB 220.&nbsp; We do not currently sell personal information
        under Nevada law; however, you may still request to opt out of the
        future sale of your personal information.&nbsp; If you are a Nevada
        resident and would like to make such a request, please submit a written
        request via the email address available <a href="#Contact">here</a>. You
        must include in your email or mail request your full name, email address
        and postal address so that we can verify your Nevada residence and
        respond.&nbsp; In the event we begin to sell personal information after
        the receipt of your request, we will make reasonable efforts to comply
        with your request as to your personal information.
      </li>
    </ol>
    <h2 id="Cookie">5) Cookie Policy</h2>
    <p>
      The Site may use cookies and similar technologies to improve user
      experience, for performance and analytics, and to improve our content,
      products, and services.&nbsp; A &ldquo;cookie&rdquo; is a small text file
      that a web server stores in browser software.&nbsp; A browser sends
      cookies to a server when the browser makes a connection to that server
      (for example, when requesting a web page from the same domain that created
      the cookie). &nbsp;
    </p>
    <p>
      The general purpose of cookies is to remember a browser over time and
      distinguish one browser instance (or user) from all others.&nbsp; Some
      cookies and other technologies may track the personal data users provide
      when they interact with a site, and may store such personal data.&nbsp; We
      use first-party and third-party cookies to ensure that our Sites and
      Services function properly, to understand how individuals use and engage
      with our Sites and Services and to analyze and improve our Site and
      Services.&nbsp; For example, we use cookies to help you reset your account
      password in the event you need to.&nbsp; We also use third-party cookies
      to analyze and improve our Sites and Services so that we can report on
      usage activities and trends.
    </p>
    <p>
      Most browsers are initially set up to accept cookies, but you can reset
      your browser to block or delete all cookies in your browser&rsquo;s
      settings using the links provided below.&nbsp; Please note that if you
      block essential cookies, certain parts of the Site may not function
      properly. &nbsp;
    </p>
    <ul style="list-style-type: disc">
      <li>Chrome: https://support.google.com/chrome/answer/95647?hl=en</li>
      <li>
        Explorer:
        https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
      </li>
      <li>
        Safari:
        https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
      </li>
      <li>
        Firefox:
        https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer
      </li>
      <li>Opera: https://help.opera.com/en/latest/web-preferences/#cookies</li>
    </ul>
    <h2 id="Data">6) Data Security and Retention</h2>
    <p>
      We maintain appropriate technical, administrative and physical safeguards
      to help protect the security of your personal data against unauthorized
      access, destruction, loss, alteration, misuse or disclosure.&nbsp; Your
      personal data is accessible to only a limited number of personnel who need
      access to the information to perform their duties.&nbsp; Please note,
      however, that no method of transmission over the Internet or method of
      electronic storage is 100% secure.
    </p>
    <p>
      We will retain your personal data only for as long as is necessary for the
      purposes set out in this Privacy Policy.&nbsp; We retain and use personal
      data to the extent necessary for our legitimate business purposes, to
      comply with our contractual obligations and to comply with our legal
      obligations (for example, if we are required to retain your data to comply
      with applicable laws), as well as resolve disputes, and enforce our legal
      agreements and policies.
    </p>
    <h2 id="International">7) International Data Transfers</h2>
    <p>
      We may transfer personal data, including sensitive data, to the United
      States or other countries from which the personal data was collected for
      storage, processing, and use by MeaningSphere.&nbsp; Those countries may
      have data protection rules that are different from those of your
      country.&nbsp; We will take measures to ensure that any such transfers
      comply with applicable data protection laws and that your personal data
      remains protected to the standards described in this Privacy Policy.&nbsp;
      Personal data transferred to another country may be subject to lawful
      access requests by courts, law enforcement agencies, or other government
      authorities in those other countries.&nbsp; If you are located in the EEA,
      the UK, or Switzerland, we comply with any applicable laws to provide an
      adequate level of data protection to the U.S.&nbsp; Where applicable law
      requires us to ensure that an international data transfer is governed by a
      data transfer mechanism, we use EU Standard Contractual Clauses with data
      recipients located outside the EEA or the UK.
    </p>
    <h2 id="Use">8) Use by Minors</h2>
    <p>
      We do not knowingly collect personal data from individuals under the age
      of 18 years without parental consent.&nbsp; No personal data should be
      submitted to our Sites by individuals under the age of 18.&nbsp; If we
      learn we have collected or received personal data from a child under the
      age of 18 without parental consent, we will delete that personal data as
      permitted by law.&nbsp; If you believe we may have any collected personal
      data from a child under the age of 18 without parental consent, please
      submit a written request via the email address available
      <a href="#Contact">here</a>.
    </p>
    <h2 id="Links">9) Links to Other Sites</h2>
    <p>
      Our Sites may contain links or otherwise provide access to other
      third-party sites, which may include websites, mobile applications, or
      Internet locations.&nbsp; Please note that we have no control over and are
      not responsible for third-party sites, their content, or any products or
      services available through the third-party sites.&nbsp; Our Privacy Policy
      does not apply to third-party sites.&nbsp; We encourage you to read the
      privacy policies of any third-party sites with which you choose to
      interact.
    </p>
    <h2 id="Updates">10) Updates to This Privacy Policy</h2>
    <p>
      We may update this Privacy Policy from time to time.&nbsp; The date this
      Privacy Policy was last updated is identified at the top of this
      page.&nbsp; Any changes are effective when we post the revised Privacy
      Policy on the Sites.&nbsp; If we make material changes to this Privacy
      Policy that expand our rights to collect new personal data or to use
      differently any personal data that we have previously collected about you,
      we will notify you and provide you with the disclosure by posting it on
      our Sites and/or via email.&nbsp; We encourage you to review this Privacy
      Policy often to stay informed of how we may process your personal data.
    </p>
    <h2 id="Contact">11) Contact Us</h2>
    <p>
      If you would like to submit a privacy rights request as detailed above,
      please submit a written request by emailing us at
      dataprivacy@meaningsphere.com.&nbsp; If you have any questions or concerns
      about this Privacy Policy or our privacy practices, please email us at
      dataprivacy@meaningsphere.com or write to us or call us using the
      information below:
    </p>
    <p>
      &nbsp;&nbsp; &nbsp;MeaningSphere&nbsp;&nbsp; &nbsp;LLC&nbsp;&nbsp; &nbsp;
      <br />100 Witmer Rd Suite 300&nbsp;&nbsp; &nbsp;<br />Horsham, PA, 19044
    </p>
    <h2>Appendix A &ndash; List of Current Research Partners</h2>
    <p>
      As further described in this Privacy Policy, MeaningSphere currently
      shares certain personal data with the Research Partners identified below,
      consistent with your optional election.&nbsp; MeaningSphere may add or
      revise its list of Current Research Partners over time, which will be
      immediately reflected in this list.
    </p>
    <table width="100%" border="1px">
      <thead>
        <tr>
          <th style="width: 32%">Name of Research Partner</th>
          <th style="width: 32%">Contact Information</th>
          <th style="width: 32%">Website</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="width: 32%">
            Auckland University of Technology’s (AUT) <br />
            New Zealand Work Research Institute (NZWRI)
          </td>
          <td style="width: 32%">
            NZ Work Research Institute <br />
            AUT University <br />
            Private Bag 92006 <br />
            Auckland 1142 <br />
            New Zealand
          </td>
          <td style="width: 32%">
            AUT Website: https://www.aut.ac.nz/ <br />
            NZWRI Website: https://workresearch.aut.ac.nz/
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <h2 style="text-align: center">MeaningSphere Term of Use</h2>
    <h3 style="text-align: center">Effective Date: June 2, 2022</h3>
    <p>&nbsp;</p>
    <p>Welcome!</p>
    <p>
      We&rsquo;ve drafted these Terms of Use (which we call the
      &ldquo;Terms&rdquo;) so you&rsquo;ll know the rules that govern our
      relationship with you.&nbsp; Although we have tried our best to strip the
      legalese from the Terms, there are places where these Terms may still read
      like a traditional contract.&nbsp; There&rsquo;s a good reason for that:
      These Terms do indeed form a legally binding contract between you and
      MeaningSphere LLC (<strong
        >&ldquo;MeaningSphere&rdquo;, &ldquo;we&rdquo; &ldquo;our&rdquo; </strong
      >or <strong>&ldquo;us&rdquo;</strong>) for your use of our website
      www.meaningsphere.com (the <strong>&ldquo;Site&rdquo;</strong>) and any
      related MeaningSphere mobile app (the <strong>&ldquo;App&rdquo;</strong>),
      as well as your use of any features, content, downloads and/or other
      services that we own and control and that post a link to these Terms (we
      refer to this collectively as the
      <strong>&ldquo;Service&rdquo;</strong>).&nbsp; So please read them
      carefully. &nbsp;
    </p>
    <p>
      By using the Service, you also acknowledge and accept the Service&rsquo;s
      Privacy Policy and consent to the collection and use of your data in
      accordance with the Privacy Policy
    </p>
    <p>
      <span style="text-decoration: underline">
        <strong>
          ARBITRATION NOTICE: THESE TERMS CONTAIN AN ARBITRATION CLAUSE A LITTLE
          LATER ON. EXCEPT FOR CERTAIN TYPES OF DISPUTES MENTIONED IN THAT
          ARBITRATION CLAUSE, YOU AND MEANINGSPHERE AGREE THAT DISPUTES BETWEEN
          US WILL BE RESOLVED BY MANDATORY BINDING ARBITRATION, AND YOU AND
          MEANINGSPHERE WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT
          OR CLASS-WIDE ARBITRATION.
        </strong>
      </span>
    </p>
    <p style="text-align: center">
      <strong
        ><span style="text-decoration: underline"
          >Executive Summary with Linkable Table of Contents</span
        ></strong
      >
    </p>
    <p>
      <strong
        >It is important that you read and understand these entire Terms before
        using the Service.&nbsp; To ease review, each section below includes a
        brief introductory summary and a link to the full explanation.&nbsp;
        Please note that the complete provisions, and not the headings or
        summaries shall govern.&nbsp; You can click on the headings and
        &ldquo;More&rdquo; buttons to be taken to the full explanation.&nbsp;
        Any capitalized terms have the meanings given to them where defined in
        the Terms.&nbsp;&nbsp; &nbsp;</strong
      >
    </p>
    <ol>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Accounts"
            ><strong class="cursorp">Accounts and Profiles </strong></a
          > </span
        >You may have the opportunity to open, revise and close your accounts
        and setup and change Profiles, subject to certain rules.&nbsp; We may
        offer you the ability to make choices regarding how and to whom some
        aspects of your account and Profile are used and seen, but these may not
        be completely effective.&nbsp; We may stop providing the Service at any
        time.&nbsp; You can also stop using your account or close your account
        at any time.
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Notices"
            ><strong class="cursorp">Notices and Questions </strong></a
          > </span
        >You agree we may provide you notices, including of new terms and
        conditions, by posting notice on the home page of the Service, changing
        the date at the beginning of these Terms or by other reasonable means
        that we may elect, such as to the email address you provided.&nbsp;
        <a href="#Notices">More</a>
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Content"
            ><strong class="cursorp">Content You Submit </strong></a
          > </span
        >You retain ownership you have of any intellectual property in content
        you post to the Service but you grant us a broad license, which we may
        sublicense, to the content you submit which you represent you have the
        right to allow us to use.&nbsp; You, however, retain ownership of and
        responsibility for, your content.&nbsp; <a href="#Content">More</a>
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Procedure"
            ><strong class="cursorp"
              >Procedure For Alleging Copyright Infringement
            </strong></a
          > </span
        >Users may not post content they do not own or control and may be
        suspended or terminated if they do so.&nbsp; Copyright owners may give
        us notice of infringement by following specific instructions
        specifically addressed in this Section.&nbsp;
        <a href="#Procedure">More</a>
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Content2"
            ><strong class="cursorp"
              >Content Ownership, Limited License and Restrictions
            </strong></a
          > </span
        >We only grant you a limited revocable license to use the Service for
        your own non-commercial use subject to rules and limitations. Also,
        don't do bad things to the Service or to other users. Some particularly
        egregious examples of &ldquo;bad things&rdquo; are listed in this
        Section.&nbsp; <a href="#Content2">More</a>
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Dispute"
            ><strong class="cursorp">Dispute Resolution </strong></a
          > </span
        >You agree to arbitrate most disputes and waive jury trial and class
        actions and to bring many types of claims within one (1) year.&nbsp;
        <a href="#Dispute">More</a>
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Disclaimer"
            ><strong class="cursorp"
              >Disclaimer of Representations and Warranties
            </strong></a
          > </span
        >We are not liable if something goes really wrong and so we disclaim
        warranties to the extent permitted by applicable law, and provide the
        Service &ldquo;As Is&rdquo;.&nbsp; <a href="#Disclaimer">More</a>
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Limitations"
            ><strong class="cursorp">Limitations of our Liability </strong></a
          > </span
        >Our liability is greatly limited.&nbsp; <a href="#Limitations">More</a>
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Updates"
            ><strong class="cursorp">Updates to Terms </strong></a
          > </span
        >As MeaningSphere grows and improves, we might have to make changes to
        these Terms.&nbsp; When we do, we'll let you know.
        <a href="#Updates">More</a>
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#General"
            ><strong class="cursorp">General Provisions </strong></a
          > </span
        >You agree to various other terms and conditions, which you should read
        here.&nbsp; <a href="#General">More</a>
      </li>
      <li>
        <span style="text-decoration: underline"
          ><a href="#Terms"
            ><strong class="cursorp">Terms Applicable For Apple iOS </strong></a
          > </span
        >There are some other things you should know if you are accessing or
        using our App through an Apple Device.&nbsp; <a href="#Terms">More</a>
      </li>
    </ol>
    <h2 style="text-align: center">Full Details of the Terms of Use</h2>
    <ol>
      <li>
        <strong id="Accounts">Accounts and Profiles</strong>&nbsp;&nbsp; &nbsp;
        <ol style="list-style-type: upper-alpha">
          <li>
            <span style="text-decoration: underline"
              ><strong>Accounts</strong></span
            >.&nbsp; No one under thirteen (13) years of age is allowed to
            create an account or use the Service. By using the Service, you
            agree that:
            <ul style="list-style-type: disc">
              <li>You can form a binding contract with us.</li>
              <li>You will not create more than one account for yourself.</li>
              <li>
                You will not create another account if we have already disabled
                your account, unless you have our written permission to do so.
              </li>
              <li>
                You are not a person who is barred from receiving the Service
                under the laws of the United States or any other applicable
                jurisdiction&mdash;including, for example, that you do not
                appear on the U.S. Treasury Department&rsquo;s list of Specially
                Designated Nationals or face any other similar prohibition.
              </li>
              <li>You are not a convicted sex offender.</li>
              <li>
                When using the Service, you will comply with these Terms and all
                applicable local, state, national, and international laws,
                rules, and regulations.
              </li>
              <li>
                If you are using the Service on behalf of a business or some
                other entity, you state that you are authorized to grant all
                licenses set forth in these Terms and to agree to these Terms on
                behalf of the business or entity.
              </li>
              <li>
                You will provide true, accurate, current, and complete
                registration information about yourself in connection with the
                registration process and, as permitted, to maintain and update
                it continuously and promptly to keep it accurate, current, and
                complete.
              </li>
              <li>
                You are solely responsible for all activities that occur under
                your account, password, and username &ndash; whether or not you
                authorized the activity. So it&rsquo;s important that you keep
                your account secure. One way to do that is to select a strong
                password that you don&rsquo;t use for any other account and not
                share your password. If you think that someone has gained access
                to your account, please immediately reach out to
                <strong>contact_us@meaningsphere.com</strong>.
              </li>
              <li>
                You will not buy, sell, rent, or lease access to your account,
                username or password without our written permission.
              </li>
              <li>
                We also reserve the more general and broad right to terminate
                your account or suspend or otherwise deny you access to it or
                its benefits &ndash; all in our sole discretion, for any reason,
                and without advance notice or liability.&nbsp;&nbsp; &nbsp;
              </li>
            </ul>
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Profiles</strong></span
            >.&nbsp; Your account may allow you to post a public profile (a
            &ldquo;<strong>Profile Page</strong>&rdquo;).&nbsp; Profile Pages
            may not include any form of prohibited User Content. We may offer
            you the ability to set preferences relating to your profile or
            Service activities, but settings may not become effective
            immediately or be error free, and options may change from
            time-to-time.&nbsp; We assume no responsibility or liability for
            users&rsquo; Profile Page material.&nbsp; Profile Pages may only be
            set up by an authorized representative of the individual that is the
            subject of the Profile Page.&nbsp; We do not review Profile Pages to
            determine if they were created by an appropriate party, and we are
            not responsible for any unauthorized Profile Pages that may appear
            on the Service.&nbsp; If there is any dispute as to whether a
            Profile Page has been created or is being maintained by an
            authorized representative of the individual who is the subject of
            that Profile Page, then we shall have the sole right, but are not
            obligated, to resolve such dispute as we determine is appropriate in
            our sole discretion.&nbsp; Such resolution may include, without
            limitation, deleting or disabling access to Profile Pages, or any
            portion thereof, at any time without notice.
          </li>
        </ol>
      </li>
      <li>
        <strong id="Notices">Notices and Questions</strong> <br />You agree
        that:
        <ul style="list-style-type: disc">
          <li>
            we may give you notices of new, revised or changed terms and other
            important matters by prominently posting notice on the homepage of
            the Service, or in another reasonable manner that we may elect; and
          </li>
          <li>
            we may contact you by mail or email sent to the address provided by
            you.&nbsp; You agree to promptly notify us if you change your email
            or mailing address by updating your account information. &nbsp;
          </li>
        </ul>
        If you have a question regarding using the Service, you may contact us
        at
        <strong style="text-decoration: underline"
          >contact_us@meaningsphere.com</strong
        >
      </li>
      <li>
        <strong id="Content">Content You Submit</strong>
        <ol style="list-style-type: upper-alpha">
          <li>
            <span style="text-decoration: underline"
              ><strong>General.&nbsp;</strong></span
            >
            MeaningSphere may now or in the future offer users of the Service
            the opportunity to submit messages, text, illustrations, files,
            images, graphics, photos, comments, responses, sounds, music,
            videos, information, content, ratings, reviews, data, feedback,
            questions, suggestions (collectively, &ldquo;User Content&rdquo;).
            Subject to the license you grant in these Terms below, you retain
            ownership in your User Content and so you remain ultimately
            responsible for it (legally or otherwise).
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Non-Confidentiality of Your User Content.</strong></span
            >&nbsp; You agree that: (a) your User Content will be treated as
            non-confidential &ndash; regardless of whether you mark them
            &ldquo;confidential,&rdquo; &ldquo;proprietary,&rdquo; or the like
            &ndash; and will not be returned; and (b) MeaningSphere does not
            assume any obligation of any kind to you or any third party with
            respect to your User Content.&nbsp; Upon MeaningSphere&rsquo;s
            request, you will furnish us with any documentation necessary to
            substantiate the rights to such content and to verify your
            compliance with these Terms or any Additional Terms.&nbsp; In your
            communications with MeaningSphere, please keep in mind that we do
            not seek any unsolicited ideas or materials for products or
            services, or even suggested improvements to products or services
            (collectively, &ldquo;<strong
              >Unsolicited Ideas and Materials</strong
            >&rdquo;).&nbsp; Any Unsolicited Ideas and Materials you post on or
            send to us via the Service are deemed User Content and licensed to
            us as set forth below.&nbsp; In addition, MeaningSphere retains all
            of the rights held by members of the general public with regard to
            your Unsolicited Ideas and Materials.&nbsp; MeaningSphere&rsquo;s
            receipt of your Unsolicited Ideas and Materials is not an admission
            by MeaningSphere of their novelty, priority, or originality, and it
            does not impair MeaningSphere&rsquo;s right to contest existing or
            future intellectual property rights relating to your Unsolicited
            Ideas and Materials.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong
                >License to MeaningSphere of Your User Content.</strong
              ></span
            >&nbsp; You grant to MeaningSphere the non-exclusive, unrestricted,
            unconditional, unlimited, worldwide, irrevocable, perpetual, and
            cost-free right and license to use, copy, record, distribute,
            reproduce, disclose, sell, re-sell, sublicense, display, publicly
            perform, transmit, publish, broadcast, translate, make derivative
            works of, and otherwise use and exploit in any manner whatsoever,
            all or any portion of your User Content (and derivative works
            thereof), for any purpose whatsoever in all formats, on or through
            any means or medium now known or hereafter developed, and with any
            technology or devices now known or hereafter developed, and to
            advertise, market, and promote the same.&nbsp; In order to further
            effect the rights and license that you grant to MeaningSphere to
            your User Content, you also hereby grant to MeaningSphere, and agree
            to grant to MeaningSphere, the unconditional, perpetual, irrevocable
            right to use and exploit your name, persona, and likeness in
            connection with any User Content, without any obligation or
            remuneration to you.&nbsp; Except as prohibited by law, you hereby
            waive, and you agree to waive, any moral rights (including
            attribution and integrity) that you may have in any User Content,
            even if it is altered or changed in a manner not agreeable to
            you.&nbsp; To the extent not waivable, you irrevocably agree not to
            exercise such rights (if any) in a manner that interferes with any
            exercise of the granted rights.&nbsp; You understand that you will
            not receive any fees, sums, consideration, or remuneration for any
            of the rights granted in this Section 3(C).
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Exclusive Right to Manage Our Service.</strong></span
            >&nbsp; MeaningSphere may, but will not have any obligation to,
            review, monitor, display, post, store, maintain, accept, or
            otherwise make use of, any of your User Content, and MeaningSphere
            may, in its sole discretion, reject, delete, move, re-format, remove
            or refuse to post or otherwise make use of User Content without
            notice or any liability to you or any third party in connection with
            our operation of User Content venues in an appropriate manner.&nbsp;
            Without limitation, we may do so to address content that comes to
            our attention that we believe is offensive, obscene, lewd,
            lascivious, filthy, violent, harassing, threatening, abusive,
            illegal or otherwise objectionable or inappropriate, or to enforce
            the rights of third parties or these Terms. Such User Content
            submitted by you or others need not be maintained on the Service by
            us for any period of time and you will not have the right, once
            submitted, to access, archive, maintain, or otherwise use such User
            Content on the Service or elsewhere. &nbsp;
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong
                >Representations and Warranties Related to Your User
                Content.</strong
              ></span
            >&nbsp; Each time you submit any User Content, you represent and
            warrant that you are at least the age of majority in the
            jurisdiction in which you reside or are the parent or legal
            guardian, or have all proper consents from the parent or legal
            guardian, of any minor who is depicted in or contributed to any User
            Content you submit, and that, as to that User Content:
            <ul style="list-style-type: disc">
              <li>
                you are the sole author and owner of the intellectual property
                and other rights to the User Content, or you have a lawful right
                to submit the User Content and grant MeaningSphere the rights to
                it that you are granting by these Terms and any Additional
                Terms, all without any MeaningSphere obligation to obtain
                consent of any third party and without creating any obligation
                or liability of MeaningSphere;
              </li>
              <li>the User Content is accurate;</li>
              <li>
                the User Content does not and, as to MeaningSphere&rsquo;s
                permitted uses and exploitation set forth in these Terms, will
                not infringe any intellectual property or other right of any
                third party; and
              </li>
              <li>
                the User Content will not violate these Terms or cause injury or
                harm to any person. &nbsp;
              </li>
            </ul>
          </li>
        </ol>
      </li>
      <li>
        <strong id="Procedure"
          >Procedure For Alleging Copyright Infringement</strong
        >
        <ul style="list-style-type: disc">
          <li>
            <span style="text-decoration: underline"
              ><strong>DMCA Notice.</strong></span
            >&nbsp; MeaningSphere will respond appropriately to notices of
            alleged copyright infringement that comply with the U.S. Digital
            Millennium Copyright Act (<strong>&ldquo;DMCA&rdquo;</strong>), as
            set forth below.&nbsp; If you own a copyright in a work (or
            represent such a copyright owner) and believe that your (or such
            owner&rsquo;s) copyright in that work has been infringed by an
            improper posting or distribution of it via the Service, then you may
            send us a written notice that includes all of the following:
            <ol style="list-style-type: lower-roman">
              <li>
                a legend or subject line that says: &ldquo;DMCA Copyright
                Infringement Notice&rdquo;;
              </li>
              <li>
                a description of the copyrighted work that you claim has been
                infringed or, if multiple copyrighted works are covered by a
                single notification, a representative list of such works;
              </li>
              <li>
                a description of where the material that you claim is infringing
                or is the subject of infringing activity is located that is
                reasonably sufficient to permit us to locate the material
                (please include the URL or page of the Service on which the
                material appears);
              </li>
              <li>
                your full name, address, telephone number and email address;
              </li>
              <li>
                a statement by you that you have a good faith belief that use of
                the material in the manner complained of is not authorized by
                the copyright owner, its agent, or the law;
              </li>
              <li>
                a statement by you, made under penalty of perjury, that all the
                information in your notice is accurate, and that you are the
                copyright owner (or, if you are not the copyright owner, then
                your statement must indicate that you are authorized to act on
                the behalf of the owner of an exclusive right that is allegedly
                infringed); and
              </li>
              <li>your electronic or physical signature.</li>
            </ol>
            MeaningSphere will only respond to DMCA Notices that it receives by
            mail, email or facsimile at the addresses below:<br />By Mail:
            Meaningsphere LLC, d/b/a MeaningSphere, 100 Witmer Rd, Suite 300,
            Horsham PA, 19044 (Attn: DMCA Agent). <br />By email:
            <strong>[DMCA@meaingsphere.com]</strong><br />
            It is often difficult to determine if your copyright has been
            infringed.&nbsp; MeaningSphere may elect to not respond to DMCA
            Notices that do not substantially comply with all of the foregoing
            requirements, and MeaningSphere may elect to remove allegedly
            infringing material that comes to its attention via notices that do
            not substantially comply with the DMCA. &nbsp;Please note that the
            DMCA provides that any person who knowingly materially misrepresents
            that material or activity is infringing may be subject to
            liability.We may send the information that you provide in your
            notice to the person who provided the allegedly infringing
            work.&nbsp; That person may elect to send us a DMCA
            Counter-Notification.Without limiting MeaningSphere&rsquo;s other
            rights, MeaningSphere may, in appropriate circumstances, terminate a
            repeat infringer&rsquo;s access to the Service and any other website
            owned or operated by MeaningSphere.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Counter-Notification.</strong></span
            >&nbsp; If access on the Service to a work that you submitted to
            MeaningSphere is disabled or the work is removed as a result of a
            DMCA Notice, and if you believe that the disabled access or removal
            is the result of mistake or misidentification, then you may send us
            a DMCA Counter-Notification to the addresses above.&nbsp; Your DMCA
            Counter-Notification should contain the following information:
            <ol style="list-style-type: lower-roman">
              <li>
                a legend or subject line that says:&nbsp; &ldquo;DMCA
                Counter-Notification&rdquo;;
              </li>
              <li>
                a description of the material that has been removed or to which
                access has been disabled and the location at which the material
                appeared before it was removed or access to it was disabled
                (please include the URL or page of the Service from which the
                material was removed or access to it disabled);
              </li>
              <li>
                a statement under penalty of perjury that you have a good faith
                belief that the material was removed or disabled as a result of
                mistake or misidentification of the material to be removed or
                disabled;
              </li>
              <li>
                your full name, address, telephone number, e-mail address, and
                the username of your account;
              </li>
              <li>
                a statement that you consent to the jurisdiction of the Federal
                District Court for the judicial district in which your address
                is located (or, if the address is located outside the U.S.A., to
                the jurisdiction of the United States District Court for the
                Eastern District of Pennsylvania), and that you will accept
                service of process from the person who provided DMCA
                notification to us or an agent of such person; and
              </li>
              <li>your electronic or physical signature.</li>
            </ol>
          </li>
          <li>
            Please note that the DMCA provides that any person who knowingly
            materially misrepresents that material or activity was removed or
            disabled by mistake or misidentification may be subject to
            liability.If we receive a DMCA Counter-Notification, then we may
            replace the material that we removed (or stop disabling access to
            it) in not less than ten (10) and not more than fourteen (14)
            business days following receipt of the DMCA
            Counter-Notification.&nbsp; However, we will not do this if we first
            receive notice at the addresses above that the party who sent us the
            DMCA Copyright Infringement Notice has filed a lawsuit asking a
            court for an order restraining the person who provided the material
            from engaging in infringing activity relating to the material on the
            Service.&nbsp; You should also be aware that we may forward the
            Counter-Notification to the party who sent us the DMCA Copyright
            Infringement Notice.
          </li>
        </ul>
      </li>
      <li>
        <strong id="Content2"
          >Content Ownership, Limited License and Restrictions</strong
        >
        <ol style="list-style-type: upper-alpha">
          <li>
            <span style="text-decoration: underline"
              ><strong>Content.</strong></span
            >&nbsp; The Service contains a variety of: (i) materials and other
            items relating to MeaningSphere, and its products and services, and
            similar items from our licensors and other third parties; (ii)
            trademarks, trade dress, logos, trade names, service marks, and/or
            trade identities of various parties, including those of
            MeaningSphere; and (iii) other forms of intellectual property (all
            of the foregoing collectively &ldquo;Content&rdquo;). The Service
            (including past, present, and future versions) and the Content are
            owned or controlled by MeaningSphere, our licensors and/or certain
            other third parties.&nbsp; All right, title, and interest in and to
            the Content available via the Service is the property of
            MeaningSphere or our licensors or certain other third parties, and
            is protected by U.S. and international copyright, trademark, trade
            dress, patent, and/or other intellectual property and unfair
            competition rights and laws to the fullest extent possible.&nbsp;
            MeaningSphere owns the copyright in the selection, compilation,
            assembly, arrangement, and enhancement of the Content on the
            Service. &nbsp;
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Limited License.</strong></span
            >&nbsp; Subject to your compliance with these Terms, MeaningSphere
            grants you a limited, non-exclusive, revocable, non-assignable,
            personal, and non-transferable license to: (i) download (temporary
            storage only), display, view, use, play, and/or print one copy of
            the Content (excluding source and object code in raw form or
            otherwise, other than as made available to access and use to enable
            display and functionality) on a personal computer, mobile phone or
            other wireless device, or other Internet enabled device (each, a
            &ldquo;Device&rdquo;) for your personal, non-commercial use
            only.&nbsp; This license is for the sole purpose of letting you use
            and enjoy the Service&rsquo;s benefits in a way that these Terms and
            our usage policies allow. The foregoing limited license: (i) does
            not give you any ownership of, or any other intellectual property
            interest in, any Content, and (ii) may be immediately suspended or
            terminated for any reason, in MeaningSphere&rsquo;s sole discretion,
            and without advance notice or liability.&nbsp; In some instances, we
            may permit you to have greater access to and use of Content, subject
            to certain Additional Terms.&nbsp; Any software that we provide you
            may automatically download and install upgrades, updates, or other
            new features. You may be able to adjust these automatic downloads
            through your Device&rsquo;s settings.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Restrictions.</strong></span
            >
            When using the Service, you must not (and must not assist or allow
            others to):
            <ul>
              <li>
                reverse engineer, alter, modify, create derivative works from,
                decompile, or extract code from our Service;
              </li>
              <li>
                copy, modify, distribute, sell, or lease any part of our
                Service, nor may you attempt to extract the source code of that
                software, unless laws prohibit these restrictions or you have
                our written permission to do so;
              </li>
              <li>
                send, store, or transmit viruses or other harmful computer code
                through or onto our servers or systems;
              </li>
              <li>
                gain or attempt to gain unauthorized access to our servers or
                systems;
              </li>
              <li>
                interfere with or disrupt the integrity or performance of our
                servers or systems;
              </li>
              <li>
                create accounts or send messages through unauthorized or
                automated means; or
              </li>
              <li>
                collect the information of or about our users in any
                impermissible or unauthorized manner.
              </li>
            </ul>
          </li>
        </ol>
      </li>
      <li>
        <strong id="Dispute">Dispute Resolution</strong> If you reside in the
        U.S. (and as applicable to U.S. residents), certain portions of this
        Section 6 are deemed to be a &ldquo;written agreement to
        arbitrate&rdquo; pursuant to the Federal Arbitration Act.&nbsp; You and
        MeaningSphere agree that we intend that this Section 6 satisfies the
        &ldquo;writing&rdquo; requirement of the Federal Arbitration Act. &nbsp;
        <ol style="list-style-type: upper-alpha">
          <li>
            <span style="text-decoration: underline"
              ><strong>First</strong></span
            >
            &ndash; Try to Resolve Disputes and Excluded Disputes.&nbsp; If any
            controversy, allegation, or claim arises out of or relates to the
            Service, the Content, your User Content, these Terms, or any
            Additional Terms, whether heretofore or hereafter arising
            (collectively, &ldquo;Dispute&rdquo;), or to any of
            MeaningSphere&rsquo;s actual or alleged intellectual property rights
            (an &ldquo;Excluded Dispute&rdquo;, which includes those actions set
            forth in Section 6(D), then you and we agree to send a written
            notice to the other providing a reasonable description of the
            Dispute or Excluded Dispute, along with a proposed resolution of
            it.&nbsp; Our notice to you will be sent to you based on the most
            recent contact information that you provide us.&nbsp; But if no such
            information exists or if such information is not current, then we
            have no obligation under this Section 6(A).&nbsp; Your notice to us
            must be sent via email to: contact_us@meaningsphere.com. For a
            period of sixty (60) days from the date of receipt of notice from
            the other party, MeaningSphere and you will engage in a dialogue in
            order to attempt to resolve the Dispute or Excluded Dispute, though
            nothing will require either you or MeaningSphere to resolve the
            Dispute or Excluded Dispute on terms with respect to which you and
            MeaningSphere, in each of our sole discretion, are not comfortable.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Binding Arbitration.</strong></span
            >&nbsp; If we cannot resolve a Dispute as set forth in Section 6(A)
            (or agree to arbitration in writing with respect to an Excluded
            Dispute) within sixty (60) days of receipt of the notice, then ANY
            AND ALL DISPUTES ARISING BETWEEN YOU AND MEANINGSPHERE (WHETHER
            BASED IN CONTRACT, STATUTE, REGULATION, ORDINANCE, TORT&mdash;
            INCLUDING, BUT NOT LIMITED TO, FRAUD, ANY OTHER INTENTIONAL TORT OR
            NEGLIGENCE,&mdash;COMMON LAW, CONSTITUTIONAL PROVISION, RESPONDEAT
            SUPERIOR, AGENCY OR ANY OTHER LEGAL OR EQUITABLE THEORY), WHETHER
            ARISING BEFORE OR AFTER THE EFFECTIVE DATE OF THESE TERMS, MUST BE
            RESOLVED BY FINAL AND BINDING ARBITRATION.&nbsp; THIS INCLUDES ANY
            AND ALL DISPUTES BASED ON ANY PRODUCT OR SERVICE PURCHASED THROUGH
            THE SERVICE OR ADVERTISING AVAILABLE ON OR THROUGH THE
            SERVICE.&nbsp; For U.S. residents, the Federal Arbitration Act
            (&ldquo;<strong>FAA</strong>&rdquo;), not state law, shall govern
            the arbitrability of all disputes between MeaningSphere and you
            regarding these Terms (and any Additional Terms) and the Service,
            including the &ldquo;No Class Action Matters&rdquo; Section
            below.&nbsp; BY AGREEING TO ARBITRATE, EACH PARTY IS GIVING UP ITS
            RIGHT TO GO TO COURT AND HAVE ANY DISPUTE HEARD BY A JUDGE OR
            JURY.&nbsp; MeaningSphere and you agree, however, that the
            applicable state, federal or provincial law, as contemplated in
            Section 6(H) below, shall apply to and govern, as appropriate, any
            and all claims or causes of action, remedies, and damages arising
            between you and MeaningSphere regarding these Terms and the Service,
            whether arising or stated in contract, statute, common law, or any
            other legal theory, without regard to any jurisdiction&rsquo;s
            choice of law principles.<br />Any Dispute will be resolved solely
            by binding arbitration in accordance with the then-current
            Commercial Arbitration Rules (&ldquo;<strong>Rules</strong>&rdquo;)
            of the American Arbitration Association
            (&ldquo;<strong>AAA</strong>&rdquo;), except as modified herein, and
            the arbitration will be administered by the AAA.&nbsp; If a party
            properly submits the Dispute to the AAA for formal arbitration and
            the AAA is unwilling to set a hearing then either party can elect to
            have the arbitration administered by the Judicial Arbitration and
            Mediation Services Inc. (&ldquo;<strong>JAMS</strong>&rdquo;) using
            JAMS&rsquo; streamlined Arbitration Rules and Procedures, or by any
            other arbitration administration service that you and an officer or
            legal representative of MeaningSphere consent to in writing.&nbsp;
            If an in-person arbitration hearing is required and you are a U.S.
            resident, then it will be conducted in Montgomery County,
            Pennsylvania.&nbsp; You and we will pay the administrative and
            arbitrator&rsquo;s fees and other costs in accordance with the
            applicable arbitration rules; but if applicable arbitration rules or
            laws require MeaningSphere to pay a greater portion or all of such
            fees and costs in order for this Section 6 to be enforceable, then
            MeaningSphere will have the right to elect to pay the fees and costs
            and proceed to arbitration.&nbsp; The arbitration will be conducted
            by a single arbitrator who will apply and be bound by these Terms
            and any Additional Terms, and will determine any Dispute according
            to applicable law and facts based upon the record and no other
            basis, and will issue a reasoned award only in favor of the
            individual party seeking relief and only to the extent to provide
            relief warranted by that party&rsquo;s individual claim.&nbsp; All
            issues are for the arbitrator to decide, except that issues relating
            to the scope and enforceability of the arbitration and class action
            waiver provisions are for the court to decide.&nbsp; This
            arbitration provision shall survive termination of these Terms or
            the Service.&nbsp; You can obtain AAA and JAMS procedures, rules,
            and fee information as follows: AAA: 800.778.7879 and
            http://www.adr.org and JAMS: 800.352.5267 and
            http://www.jamsadr.com.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Limited Time to File Claims.</strong></span
            >&nbsp; TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IF YOU OR
            WE WANT TO ASSERT A DISPUTE (BUT NOT AN EXCLUDED DISPUTE) AGAINST
            THE OTHER, THEN YOU OR WE MUST COMMENCE IT (BY DELIVERY OF WRITTEN
            NOTICE AS SET FORTH IN SECTION 6(A)) WITHIN ONE (1) YEAR AFTER THE
            DISPUTE ARISES -- OR IT WILL BE FOREVER BARRED.&nbsp; Commencing
            means, as applicable: (a) by delivery of written notice as set forth
            above in Section 6(A); (b) filing for arbitration as set forth in
            Section 6(B); or (c) filing an action in state, Federal or
            provincial court. &nbsp;
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Injunctive Relief.</strong></span
            >&nbsp; The foregoing provisions of this Section 6 will not apply to
            any legal action taken by MeaningSphere to seek an injunction or
            other equitable relief in connection with, any loss, cost, or damage
            (or any potential loss, cost, or damage) relating to the Service,
            any Content, your User Content and/or MeaningSphere&rsquo;s
            intellectual property rights (including such MeaningSphere may claim
            that may be in dispute), MeaningSphere&rsquo;s operations, and/or
            MeaningSphere&rsquo;s products or services. &nbsp;
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>No Class Action Matters.</strong></span
            >&nbsp; YOU AND MEANINGSPHERE AGREE THAT EACH MAY BRING CLAIMS
            AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT
            AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
            REPRESENTATIVE PROCEEDING OR AS AN ASSOCIATION.&nbsp; Disputes will
            be arbitrated only on an individual basis and will not be joined or
            consolidated with any other arbitrations or other proceedings that
            involve any claim or controversy of any other party.&nbsp; There
            shall be no right or authority for any Dispute to be arbitrated on a
            class action basis or on any basis involving Disputes brought in a
            purported representative capacity on behalf of the general public,
            or other persons or entities similarly situated.&nbsp; But if, for
            any reason, any court with competent jurisdiction holds that this
            restriction is unconscionable or unenforceable, then our agreement
            in Section 6(B) to arbitrate will not apply and the Dispute must be
            brought exclusively in court pursuant to Section 6(F).&nbsp;
            Notwithstanding any other provision of this Section 6, any and all
            issues relating to the scope, interpretation and enforceability of
            the class action waiver provisions contained herein (described in
            this &ldquo;No Class Action Matters&rdquo; section), are to be
            decided only by a court of competent jurisdiction, and not by the
            arbitrator.&nbsp; The arbitrator does not have the power to vary
            these class action waiver provisions.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Jurisdictional Issues.</strong></span
            >&nbsp; Except where arbitration is required above or with respect
            to the enforcement of any arbitration decision or award, any action
            or proceeding relating to any Dispute or Excluded Dispute arising
            hereunder may only be instituted in state or Federal court in
            Montgomery County, Pennsylvania.&nbsp; Accordingly, you and
            MeaningSphere consent to the exclusive personal jurisdiction and
            venue of such courts for such matters.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong
                >Small Claims Matters Are Excluded from Arbitration
                Requirement.</strong
              ></span
            >&nbsp; Notwithstanding the foregoing, either of us may bring
            qualifying claim of Disputes (but not Excluded Disputes) in small
            claims court of competent jurisdiction.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Governing Law.</strong></span
            >&nbsp; These Terms and any Additional Terms will be governed by and
            construed in accordance with, and any Dispute and Excluded Dispute
            will be resolved in accordance with the laws of the State of
            Pennsylvania, without regard to its conflicts of law provisions.
          </li>
        </ol>
      </li>
      <li>
        <strong id="Disclaimer"
          >DISCLAIMER OF REPRESENTATIONS AND WARRANTIES </strong
        >YOUR ACCESS TO AND USE OF THE SERVICE IS AT YOUR SOLE RISK.&nbsp; THE
        SERVICE IS PROVIDED ON AN &ldquo;AS IS&rdquo;, &ldquo;AS
        AVAILABLE&rdquo;, AND &ldquo;WITH ALL FAULTS&rdquo; BASIS.&nbsp;
        Therefore, to the fullest extent permissible by law, MeaningSphere, its
        parent company and subsidiaries, and each of their respective employees,
        officers, directors, members, managers, shareholders, agents, vendors,
        licensors, licensees, contractors, customers, successors, and assigns
        (collectively, the &ldquo;<strong>MeaningSphere Parties</strong
        >&rdquo;), hereby disclaim and make no representations, warranties,
        endorsements, or promises, express or implied, as to:
        <ol style="list-style-type: lower-alpha">
          <li>the Service (including the Content and the User Content);</li>
          <li>
            the functions, features, or any other elements on, or made
            accessible through, the Service;
          </li>
          <li>
            any products, services, job listings, or instructions offered or
            referenced at or linked through the Service;
          </li>
          <li>
            security associated with the transmission of your User Content
            transmitted to MeaningSphere via the Service;
          </li>
          <li>
            whether the Service or the servers that make the Service available
            are free from any harmful components (including viruses, Trojan
            horses, and other technologies that could adversely impact your
            Device);
          </li>
          <li>
            whether the information (including any instructions) on the Service
            is accurate, complete, correct, adequate, useful, timely, or
            reliable;
          </li>
          <li>
            whether any defects to, or errors on, the Service will be repaired
            or corrected;
          </li>
          <li>whether your access to the Service will be uninterrupted;</li>
          <li>
            whether the Service will be available at any particular time or
            location; and
          </li>
          <li>
            whether your use of the Service is lawful in any particular
            jurisdiction. &nbsp;
          </li>
        </ol>
        EXCEPT FOR ANY SPECIFIC WARRANTIES PROVIDED HEREIN OR IN ADDITIONAL
        TERMS PROVIDED BY A MEANINGSPHERE PARTY, TO THE EXTENT PERMITTED BY
        APPLICABLE LAW, THE MEANINGSPHERE PARTIES HEREBY FURTHER DISCLAIM ALL
        WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR
        MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES,
        TITLE, CUSTOM, TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION, AND FREEDOM
        FROM COMPUTER VIRUS. Some jurisdictions limit or do not allow the
        disclaimer of implied or other warranties so the above disclaimers may
        not apply to the extent such jurisdictions&rsquo; laws are
        applicable.Further, we are not responsible for the conduct, whether
        online or offline, of any user of the Service.&nbsp; You assume all risk
        when using the Service, including, but not limited to, all of the risks
        associated with any online or offline interactions with other users,
        including hiring or employing a user you meet through the Service.&nbsp;
        You agree to take all necessary precautions if you choose to contact,
        communicate and/or meet an individual through the Service. &nbsp;YOU
        UNDERSTAND THAT WE DO NOT CONDUCT BACKGROUND OR OTHER CRIMINAL
        BACKGROUND SCREENINGS ON OUR USERS OR ATTEMPT TO VERIFY THE STATEMENTS
        OF ITS USERS.&nbsp; WE RESERVE THE RIGHT TO CONDUCT ANY CRIMINAL
        BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTER
        SEARCHES), AT ANY TIME AND USING AVAILABLE PUBLIC RECORDS. &nbsp;
      </li>
      <li>
        <strong id="Limitations">LIMITATIONS OF OUR LIABILITY </strong>TO THE
        EXTENT PERMITTED UNDER ANY APPLICABLE LAW, UNDER NO CIRCUMSTANCES WILL
        ANY MEANINGSPHERE PARTIES BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR
        DAMAGES OF ANY KIND, including personal injury or death or for any
        direct, indirect, economic, exemplary, special, punitive, incidental, or
        consequential losses or damages that are directly or indirectly related
        to:
        <ol style="list-style-type: lower-alpha">
          <li>
            (a) &nbsp;&nbsp; &nbsp;the Service (including the Content and the
            User Content, including, without limitation, any individuals you
            meet based on listings or recommendations made on the Service);
          </li>
          <li>
            (b) &nbsp;&nbsp; &nbsp;your use of or inability to use the Service,
            or the performance of the Service;
          </li>
          <li>
            (c) &nbsp;&nbsp; &nbsp;any action taken in connection with an
            investigation by MeaningSphere Parties or law enforcement
            authorities regarding your access to or use of the Service;
          </li>
          <li>
            (d) &nbsp;&nbsp; &nbsp;any action taken in connection with copyright
            or other intellectual property owners or other rights owners;
          </li>
          <li>
            (e) &nbsp;&nbsp; &nbsp;any errors or omissions in the
            Service&rsquo;s technical operation; or
          </li>
          <li>
            (f) &nbsp;&nbsp; &nbsp;any damage to any user&rsquo;s computer,
            hardware, software, modem, or other equipment or technology,
            including damage from any security breach or from any virus, bugs,
            tampering, fraud, error, omission, interruption, defect, delay in
            operation or transmission, computer line, or network failure or any
            other technical or other malfunction, including losses or damages in
            the form of lost profits, loss of goodwill, loss of data, work
            stoppage, accuracy of results, or equipment failure or malfunction.
          </li>
        </ol>
        The foregoing limitations of liability will apply even if any of the
        foregoing events or circumstances were foreseeable and even if
        MeaningSphere Parties were advised of or should have known of the
        possibility of such losses or damages, regardless of whether you bring
        an action based in contract, negligence, strict liability, or tort
        (including whether caused, in whole or in part, by negligence, acts of
        god, telecommunications failure, or destruction of the Service).
        &nbsp;Some jurisdictions do not allow the exclusion or limitation of
        incidental or consequential damages of the sort that are described
        above, so the above limitation or exclusion may not apply to you.
        &nbsp;<strong
          >EXCEPT AS MAY BE PROVIDED IN ANY ADDITIONAL TERMS, TO THE FULLEST
          EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL MEANINGSPHERE
          PARTIES&rsquo; TOTAL LIABILITY TO YOU, FOR ALL POSSIBLE DAMAGES,
          LOSSES, AND CAUSES OF ACTION IN CONNECTION WITH YOUR ACCESS TO AND USE
          OF THE SERVICE AND YOUR RIGHTS UNDER THESE TERMS, EXCEED AN AMOUNT
          EQUAL TO THE AMOUNT YOU HAVE PAID MEANINGSPHERE TO ACCESS THE SERVICE
          OR IN CONNECTION WITH THE TRANSACTION(S) THAT UNDERLIE THE CLAIM(S);
          PROVIDED, HOWEVER, THIS PROVISION WILL NOT APPLY IF A TRIBUNAL WITH
          APPLICABLE JURISDICTION FINDS SUCH TO BE UNCONSCIONABLE.&nbsp; FOR
          PURPOSES OF CLARITY, THE PRIOR SENTENCE DOES NOT EXPAND OR LIMIT ANY
          EXPRESS, WRITTEN PRODUCT WARRANTY THAT IS PROVIDED BY US.</strong
        >
      </li>
      <li>
        <strong id="Updates">Updates to Terms </strong>We amend these Terms from
        time to time, for instance when we update the functionality of our
        Service, when we combine multiple apps or services operated by us or our
        affiliates into a single combined service or app, or when there are
        regulatory changes. We will use commercially reasonable efforts to
        generally notify all users of any material changes to these Terms, such
        as through a notice on our Platform, however, you should look at the
        Terms regularly to check for such changes. We will also update the
        &ldquo;Last Updated&rdquo; date at the top of these Terms, which reflect
        the effective date of such Terms. Your continued access or use of the
        Service after the date of the new Terms constitutes your acceptance of
        the new Terms. If you do not agree to the new Terms, you must stop
        accessing or using the Service.
      </li>
      <li>
        <strong id="General">General Provisions</strong>
        <ol style="list-style-type: upper-alpha">
          <li>
            <span style="text-decoration: underline"
              ><strong
                >MeaningSphere&rsquo;s Consent or Approval.
              </strong></span
            >&nbsp; As to any provision in these Terms or any Additional Terms
            that grant MeaningSphere a right of consent or approval, or permits
            MeaningSphere to exercise a right in its &ldquo;sole
            discretion,&rdquo; MeaningSphere may exercise that right in its sole
            and absolute discretion.&nbsp; No opt-in consent or approval may be
            deemed to have been granted by MeaningSphere without being in
            writing and signed by an officer of MeaningSphere.&nbsp; &nbsp;
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Indemnity. </strong></span
            >&nbsp; You agree to, and you hereby, defend, indemnify, and hold
            MeaningSphere Parties harmless from and against any and all claims,
            damages, losses, costs, investigations, liabilities, judgments,
            fines, penalties, settlements, interest, and expenses (including
            attorneys&rsquo; fees) that directly or indirectly arise from or are
            related to any claim, suit, action, demand, or proceeding made or
            brought against any MeaningSphere Party, or on account of the
            investigation, defense, or settlement thereof, arising out of or in
            connection with, whether occurring heretofore or hereafter: (i) your
            User Content; (ii) your use of the Service and your activities in
            connection with the Service; (iii) your breach or alleged breach of
            these Terms or any Additional Terms; (iv) your violation or alleged
            violation of any laws, rules, regulations, codes, statutes,
            ordinances, or orders of any governmental or quasi-governmental
            authorities in connection with your use of the Service or your
            activities in connection with the Service; (v) information or
            material transmitted through your Device, even if not submitted by
            you, that infringes, violates, or misappropriates any copyright,
            trademark, trade secret, trade dress, patent, publicity, privacy, or
            other right of any person or entity; (vi) any misrepresentation made
            by you; and (vii) MeaningSphere Parties&rsquo; use of the
            information that you submit to us (including your User Content) (all
            of the foregoing, &ldquo;Claims and Losses&rdquo;).&nbsp; You will
            cooperate as fully required by MeaningSphere Parties in the defense
            of any Claims and Losses.&nbsp; Notwithstanding the foregoing,
            MeaningSphere Parties retain the exclusive right to settle,
            compromise, and pay any and all Claims and Losses.&nbsp;
            MeaningSphere Parties reserve the right to assume the exclusive
            defense and control of any Claims and Losses.&nbsp; You will not
            settle any Claims and Losses without, in each instance, the prior
            written consent of an officer of a MeaningSphere Party.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong
                >Operation of Service; Availability of Products and Services;
                International Issues.</strong
              ></span
            >&nbsp; The Service is operated in the United States, and is
            primarily intended for users located in the U.S.&nbsp; MeaningSphere
            makes no representation that the Service is appropriate or available
            for use beyond the U.S.&nbsp; If you use the Service from other
            locations, you are doing so on your own initiative and are
            responsible for compliance with applicable local laws regarding your
            online conduct and acceptable content, if and to the extent local
            laws apply.&nbsp; We reserve the right to limit the availability of
            the Service and/or the provision of any content, program, product,
            service, or other feature described or available on the Service to
            any person, entity, geographic area, or jurisdiction, at any time
            and in our sole discretion, and to limit the quantities of any
            content, program, product, service, or other feature that we
            provide.&nbsp; You and we disclaim any application to these Terms of
            the Convention on Contracts for the International Sale of Goods.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Severability; Interpretation.</strong></span
            >&nbsp; If any provision of these Terms, or any Additional Terms, is
            for any reason deemed invalid, unlawful, void, or unenforceable by a
            court or arbitrator of competent jurisdiction, then that provision
            will be deemed severable from these Terms or the Additional Terms,
            and the invalidity of the provision will not affect the validity or
            enforceability of the remainder of these Terms or the Additional
            Terms (which will remain in full force and effect).&nbsp; To the
            extent permitted by applicable law, you agree to waive, and you
            hereby waive, any applicable statutory and common law that may
            permit a contract to be construed against its drafter.&nbsp;
            Wherever the word &ldquo;including&rdquo; is used in these Terms or
            any Additional Terms, the word will be deemed to mean
            &ldquo;including, without limitation,&rdquo;.&nbsp; The summaries of
            provisions and section headings are provided for convenience only
            and shall not limit the full Terms.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Communications.&nbsp;</strong></span
            >
            As permitted by applicable law, when you communicate with us
            electronically, such as via email and text message, you consent to
            receive communications from us electronically.&nbsp; Please note
            that we are not obligated to respond to inquiries that we
            receive.&nbsp; You agree that all agreements, notices, disclosures,
            and other communications that we provide to you electronically
            satisfy any legal requirement that such communications be in
            writing.&nbsp; You are responsible for any mobile charges that you
            may incur for using our Service, including text-messaging and data
            charges. If you&rsquo;re unsure what those charges may be, you
            should ask your service provider before using the Service.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong
                >Investigations; Cooperation with Law Enforcement; Termination;
                Survival.</strong
              ></span
            >&nbsp; MeaningSphere reserves the right, without any limitation,
            to: (i) investigate any suspected breaches of its Service security
            or its information technology or other systems or networks, (ii)
            investigate any suspected breaches of these Terms and any Additional
            Terms, (iii) investigate any information obtained by MeaningSphere
            in connection with reviewing law enforcement databases or complying
            with criminal laws, (iv) involve and cooperate with law enforcement
            authorities in investigating any of the foregoing matters, (v)
            prosecute violators of these Terms and any Additional Terms, and
            (vi) discontinue the Service, in whole or in part, or, except as may
            be expressly set forth in any Additional Terms, suspend or terminate
            your access to it, in whole or in part, including any user accounts
            or registrations, at any time, without notice, for any reason and
            without any obligation to you or any third party.&nbsp; Any
            suspension or termination will not affect your obligations to
            MeaningSphere under these Terms or any Additional Terms.&nbsp; Upon
            suspension or termination of your access to the Service, or upon
            notice from MeaningSphere, all rights granted to you under these
            Terms or any Additional Terms will cease immediately, and you agree
            that you will immediately discontinue use of the Service.&nbsp; The
            provisions of these Terms and any Additional Terms (including the
            terms applicable to User Content), which by their nature should
            survive your suspension or termination will survive, including the
            rights and licenses you grant to MeaningSphere in these Terms, as
            well as the indemnities, releases, disclaimers, and limitations on
            liability and the provisions regarding jurisdiction, choice of law,
            no class action, and mandatory arbitration.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Assignment.</strong></span
            >&nbsp; MeaningSphere may assign its rights and obligations under
            these Terms and any Additional Terms, in whole or in part, to any
            party at any time without any notice.&nbsp; These Terms and any
            Additional Terms may not be assigned by you, and you may not
            delegate your duties under them, without the prior written consent
            of an officer of MeaningSphere. &nbsp;
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>No Waiver.</strong></span
            >&nbsp; Except as expressly set forth in these Terms or any
            Additional Terms: (i) no failure or delay by you or MeaningSphere in
            exercising any rights, powers, or remedies hereunder will operate as
            a waiver of that or any other right, power, or remedy, and (ii) no
            waiver or modification of any term of these Terms or any Additional
            Terms will be effective unless in writing and signed by the party
            against whom the waiver or modification is sought to be
            enforced.&nbsp; For avoidance of doubt, nothing herein shall be
            construed to restrict MeaningSphere&rsquo;s right to amend these
            Terms or any Additional Terms as otherwise permitted in those
            agreements.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Connectivity.</strong></span
            >&nbsp; You are responsible for obtaining and maintaining all
            Devices and other equipment and software, and all internet service
            provider, mobile service, and other services needed for your access
            to and use of the Service and you will be responsible for all
            charges related to them.
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Risk Assumptions and Precautions.</strong></span
            >&nbsp; We are a networking platform that offers its users the
            opportunity to connect with each other regarding workplace
            fulfillment and other matters important to them.&nbsp; We are not
            responsible for the conduct, whether online or offline, of any user
            of the Service.&nbsp; You assume all risk when using the Service,
            including, but not limited to, all of the risks associated with any
            online or offline interactions with other users, including finding
            another user as a mentor or mentee, and others you meet on the
            Service.&nbsp; You agree to take all necessary precautions if you
            choose to contact, communicate and/or meet an individual through the
            Service.&nbsp; You understand that we make no guarantee, either
            express or implied, regarding your ultimate compatibility with
            individuals you meet through the Service or as to the conduct of
            such individuals. &nbsp;
          </li>
          <li>
            <span style="text-decoration: underline"
              ><strong>Additional Terms.</strong></span
            >&nbsp; By accessing and/or using the Service, you agree to be bound
            by these Terms.&nbsp; In some instances, both these Terms and
            separate guidelines, rules, or terms of use or sale setting forth
            additional or different terms and/or conditions will apply to your
            use of the Service or to a service or product offered via the
            Service (in each such instance, and collectively &ldquo;Additional
            Terms&rdquo;).&nbsp; To the extent there is a conflict between these
            Terms and any Additional Terms, the Additional Terms will control
            unless the Additional Terms expressly state otherwise.&nbsp;
            Additionally, certain features and services made available through
            the Service from time to time may be governed by different terms of
            use.
          </li>
          <li>
            <strong>Third Party Services.</strong>&nbsp;&nbsp; &nbsp;If you use
            a service, feature, or functionality that is operated by a third
            party and made available through our Service (including services we
            jointly offer with the third party), each party&rsquo;s terms will
            govern the respective party&rsquo;s relationship with you.
            MeaningSphere is not responsible or liable for a third party&rsquo;s
            terms or actions taken under the third party&rsquo;s terms.
          </li>
        </ol>
      </li>
      <li id="Terms">
        <strong>Terms Applicable For Apple iOS </strong>If you are accessing or
        using our App through a Device manufactured and/or sold by Apple, Inc.
        (&ldquo;<strong>Apple</strong>&rdquo;, with such a device herein
        referenced as an &ldquo;<strong>Apple Device</strong>&rdquo;):
        <ol style="list-style-type: lower-roman">
          <li>
            To the extent that you are accessing the App through an Apple
            Device, you acknowledge that these Terms are entered into between
            you and MeaningSphere and, that Apple is not a party to these Terms
            other than as third-party beneficiary as contemplated below.
          </li>
          <li>
            The license granted to you in <a href="Content2">Section 5</a>&nbsp;
            of these Terms is subject to the permitted Usage Rules set forth in
            the Apple App Store Terms and Conditions (see:
            http://www.apple.com/legal/itunes/us/terms.html) and any third party
            terms of agreement applicable to the App.
          </li>
          <li>
            You acknowledge that MeaningSphere, and not Apple, is responsible
            for providing the App and Content thereof.
          </li>
          <li>
            You acknowledge that Apple has no obligation whatsoever to furnish
            any maintenance or any support services to you with respect to the
            App.
          </li>
          <li>
            To the maximum extent permitted by applicable law, Apple will have
            no other warranty obligation whatsoever with respect to the App.
          </li>
          <li>
            Notwithstanding anything to the contrary herein, and subject to the
            terms in these Terms, you acknowledge that, solely as between Apple
            and MeaningSphere, MeaningSphere and not Apple is responsible for
            addressing any claims you may have relating to the App, or your
            possession and/or use thereof, including, but not limited, to: (i)
            product liability claims, (ii) any claim that the App fails to
            conform to any applicable legal or regulatory requirement; and (iii)
            claims arising under consumer protection or similar legislation.
            &nbsp;
          </li>
          <li>
            Further, you agree that if the App, or your possession and use of
            the App, infringes on a third party&rsquo;s intellectual property
            rights, you will not hold Apple responsible for the investigation,
            defense, settlement and discharge of any such intellectual property
            infringement claims.
          </li>
          <li>
            You acknowledge and agree that Apple, and Apple&rsquo;s
            subsidiaries, are third-party beneficiaries of these Terms, and
            that, upon your acceptance of the terms and conditions of these
            Terms, Apple will have the right (and will be deemed to have
            accepted the right) to enforce these Terms against you as a
            third-party beneficiary thereof.
          </li>
          <li>
            When using the App, you agree to comply with any and all third-party
            terms that are applicable to any platform, website, technology or
            service that interacts with the App.
          </li>
          <li>
            You represent and warrant that: (i) you are not located in a country
            that is subject to a U.S. Government embargo or that has been
            designated by the U.S. Government as a &ldquo;terrorist
            supporting&rdquo; country; and (ii) you are not listed on any U.S.
            Government list of prohibited or restricted parties.
          </li>
        </ol>
      </li>
    </ol>
    <p>
      <strong>&copy;2022 MeaningSphere, LLC.&nbsp; All Rights Reserved.</strong>
    </p>
  </div>
  <form
    class="sendmessageForm"
    [formGroup]="acceptTermsForm"
    novalidate
    autocomplete="off"
  >
    <div class="row">
      <div class="col-md-12 mt-10" style="height: 30px">
        <mat-checkbox formControlName="acceptOrReject" class="w-100 m-0"
        (change)="checkUncheck($event)"
          >I have read and agreed to the Privacy Policy and Terms of Use
        </mat-checkbox>
      </div>
    </div>
    <hr />
    <div class="row mt-3">
      <div class="form-group col-sm-12 tr m-0">
        <button
          [disabled]="!acceptTermsForm.valid"
          class="btn begin_quiz ml-2 mt-1"
          type="button"
          (click)="goTo(acceptTermsForm.value)"
        >
          Continue
        </button>

        <button
          routerLink="/home"
          class="btn begin_quiz mt-1 ml-2 button-change-scroll canceled_btn"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
