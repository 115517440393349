<div class="mat-dialog-popup private-notes-pop">
  <table width="100%">
    <tr>
      <td>
        <h1 class="popup_card_title popup_card_titlesession">Private Notes</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form novalidate enctype="multipart/form-data" autocomplete="off">
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <h6 class="sess-guid">Guided Explorer:</h6>
            <div class="img_section_mirror push_left" style="color: white">
              <img
                [src]="returnImage(mentee_dp)"
                alt=""
                style="
                  cursor: pointer;
                  width: 25px;
                  height: 25px;
                  border-radius: 50%;
                "
              />
              <label>&nbsp;&nbsp;{{ mentee }}</label>
            </div>
          </div>
          <div class="col-sm-6">
            <h6 class="sess-guid">Guide:</h6>
            <div class="img_section_mirror push_left">
              <img
                [src]="
                  user_profile_path != '0'
                    ? user_profile_path
                    : './assets/media/users/default.png'
                "
                alt=""
                style="
                  cursor: pointer;
                  width: 25px;
                  height: 25px;
                  border-radius: 50%;
                "
              />
              <label>&nbsp;&nbsp;{{ ufullname }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div class="tob-ses-main">
      <div
        class="search-results scroll_class_list"
        infinite-scroll
        [infiniteScrollDistance]="modalScrollDistance"
        [infiniteScrollThrottle]="modalScrollThrottle"
        [scrollWindow]="false"
        (scrolled)="pushValue($event)"
      >
        <ng-container *ngIf="notes_count > 0">
          <ng-container *ngFor="let note of all_notes_data">
            <p>
              Last modified:
              {{ note.creation_date | date: "MMM d, y":current_timezone }}&nbsp;
              for &nbsp;
              {{ eventType(note.event_details["discussion_type"]) }}&nbsp; held
              on &nbsp;
              {{
                note.event_details["discussion_date"]
                  | date: "MMM d, y":current_timezone
              }}
            </p>
            <p
              class="curat_descript"
              [innerHTML]="sanitizer.bypassSecurityTrustHtml(note.remarks)"
            ></p>
            <hr />
          </ng-container>
        </ng-container>
        <ng-container *ngIf="notes_count == 0">
          <table>
            <tbody>
              <tr>
                <td style="padding: 6px !important">
                  <img
                    src="./assets/media/no_data_found.png"
                    style="border-radius: 0px"
                  />
                </td>
                <td colspan="2" style="padding: 6px !important">
                  No data found!
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <table width="100%">
          <tr>
            <td width="95%">&nbsp;</td>
            <td></td>
            <td>&nbsp;</td>
            <td>
              <button mat-dialog-close class="btn begin_quiz canceled_btn">
                Cancel
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </form>
</div>
