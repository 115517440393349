import { message_properties } from './../../../../../environments/message_properties';
import { NotificationService } from './../../../../service/notification.service';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { CommunitiesService } from './../../../../service/communities.service';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-user-community-withdraw-popup',
  templateUrl: './user-community-withdraw-popup.component.html',
  styleUrls: ['./user-community-withdraw-popup.component.scss'],
})
export class UserCommunityWithdrawPopupComponent implements OnInit, OnDestroy {
  withdrawForm: UntypedFormGroup;
  communityId: number;
  loggedInUser: number;
  communityLeaveSubscription: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<UserCommunityWithdrawPopupComponent>,
    private communitiesService: CommunitiesService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private toastr: NotificationService,
    private accountService: AccountsService
  ) {
    this.communityId = data.communityId;
  }

  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
    this.withdrawForm = new UntypedFormGroup({
      is_not_active: new UntypedFormControl(false),
      not_inspired_by_the_content: new UntypedFormControl(false),
      is_not_aligned_to_my_interests: new UntypedFormControl(false),
      other_indicator: new UntypedFormControl(false),
      remarks: new UntypedFormControl('', [Validators.required]),
    });
  }

  memberLeaveCommunity(ngForm: NgForm) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to LEAVE this Community.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.spinnerService.show();
        this.communityLeaveSubscription = this.communitiesService
          .leaveGroup(this.communityId, this.loggedInUser, ngForm)
          .subscribe((response) => {
            let responseData: any = response;
            this.spinnerService.hide();
            if (responseData.status === 200) {
              this.matDialogRef.close();
              this.toastr.showSuccess(
                message_properties.LEAVE_COMMUNITY_FROM_CLIENT_SIDE,
                ''
              );
              this.router.navigate(['/meaning-communities/community']);
            }
          });
      }
    });
  }
  ngOnDestroy(): void {
    if (this.communityLeaveSubscription) {
      this.communityLeaveSubscription.unsubscribe();
    }
  }
}
