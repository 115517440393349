import { ViewProfileComponent } from './../../admin-page/view-profile/view-profile.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MeaningConstellationService } from '../../../../service/meaning-constellation.service';
import { MeaningLogAndTreasurePopupComponent } from './../meaning-log-and-treasure-popup/meaning-log-and-treasure-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MeaningNetworkService } from '../../../../service/meaning-network.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../../service/common.service';
import { AccountsService } from '../../../../service/accounts.service';

@Component({
  selector: 'kt-my-treasures',
  templateUrl: './my-treasures.component.html',
  styleUrls: ['./my-treasures.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyTreasuresComponent implements OnInit, OnDestroy {
  throttle = 300;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;
  profileData: {};
  first_name: string;
  last_name: string;
  full_name: any;
  memberid: any;
  role: boolean = false;
  memberId: string;
  adminIndicator: boolean = false;
  WhatMatters: boolean = false;
  meaningLogs: boolean = false;
  topics: Array<any>;
  profile_path: any;
  meaningLog = [];
  myTreasure = [];
  myBookmark = [];
  ccForm: UntypedFormGroup;
  loggedInUserFullName: string;
  city: string;
  state: string;
  country: string;
  loggedInUser: number;
  mentor_indicator: boolean;
  count: number;
  topic_count: number;
  meaning_log_count: number;
  memberAddressData = [];
  page: number = 2;
  subscription: Subscription;
  topicSubscription: Subscription;
  profileSubscription: Subscription;
  myTreasuresToolTip =
    'Treasures enable you to save content that you have already read, watched, or listened to and have found particularly useful to your journey.';
  constructor(
    private meaning_network_service: MeaningNetworkService,
    private meaningConstelationService: MeaningConstellationService,
    private spinner: NgxSpinnerService,
    private cdRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private aniDialog: MatDialog,
    public sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
    this.loggedInUserFullName = this.accountService.full_name;
    this.role = this.accountService.role === '2223' ? true : false;
    this.adminIndicator = this.accountService.admin_indicator ? true : false;

    this.ccForm = this.formBuilder.group({
      title: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(480),
      ]),
      collab_content_type: new UntypedFormControl(2564),
      collab_content_category: new UntypedFormControl(2557),
      collab_content_status: new UntypedFormControl(1324),
      description: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(2048),
      ]),
      tag: 'log',
      creating_user: new UntypedFormControl(this.loggedInUser),
      attachment_path: new UntypedFormControl(''),
      tenant: 1,
      no_of_views: 0,
      no_of_likes: 0,
      visible_to_all: 1,
      bucket_type: new UntypedFormControl(2552),
      loggedInUser: new UntypedFormControl(this.loggedInUser),
      limit: new UntypedFormControl(0),
      page: 1,
    });
    // 2552 === My Treasure, 2564 === Meaning Log
    this.meaningConstelationService.refreshneededTreasureList.subscribe(
      (data) => {
        this.postTreasureLogs(1);
      }
    );
    this.postTreasureLogs();
    this.getProfileWithAddress();
    this.getTopics();
  }

  getProfileWithAddress() {
    this.profileSubscription = this.meaning_network_service
      .getProfileDetails(this.loggedInUser)
      .subscribe((data) => {
        this.city = data.city;
        this.country = data.country;
        this.mentor_indicator = Boolean(data.mentor_indicator);
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.full_name = data.full_name;
        this.profile_path = data.profile_path
          ? data.profile_path
          : './assets/media/users/default.png';
        localStorage.setItem('ufullname', btoa(this.full_name));
        localStorage.setItem(
          'image',
          data.profile_path !== null ? data.profile_path : '0'
        );
        this.cdRef.detectChanges();
      });
  }
  pushValue(ev?) {
    if (this.count !== this.myTreasure.length) {
      this.ccForm.patchValue({
        page: this.page,
      });
      this.postTreasureLogs();
      this.page++;
    }
  }
  postTreasureLogs(params = 0) {
    if (params === 1) {
      this.page = 2;
      this.ccForm.patchValue({
        page: 1,
      });
      this.myTreasure = [];
    }
    const data = this.ccForm.value;
    this.spinner.show();
    this.subscription = this.meaning_network_service
      .postAllTreasureLogsOfUser(data)
      .subscribe((data) => {
        this.myTreasure = [
          ...new Map(
            this.myTreasure
              .concat(data.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.count = data.count;
        this.spinner.hide();
        this.cdRef.detectChanges();
      });
  }
  getTopics() {
    this.topicSubscription = this.meaning_network_service
      .getWhatICareAbout(this.loggedInUser)
      .subscribe((data) => {
        this.topic_count = data.count;
        this.topics = data.results;
      });
  }

  collabContentDetails(
    collabContentId,
    member_in_bucket,
    bucket_type,
    title,
    description,
    video_indicator,
    attachment_path
  ) {
    this.aniDialog.open(MeaningLogAndTreasurePopupComponent, {
      disableClose: true,
      width: '80%',
      data: {
        collabContentId: collabContentId,
        member_in_bucket: member_in_bucket,
        bucket_type: bucket_type,
        clickFrom: 2552,
        title: title,
        description: description,
        video_indicator: video_indicator,
        attachment_path: attachment_path,
      },
    });
  }

  viewProfile(
    indicator,
    member_id,
    first_name,
    last_name,
    picture_path,
    member_status
  ) {
    if (!indicator && !this.checkStatus(member_status)) {
      this.dialog.open(ViewProfileComponent, {
        width: '80%',
        disableClose: true,
        data: {
          member: member_id,
          first_name: first_name,
          last_name: last_name,
          picture_path: picture_path,
          click_from: 'my_treasures',
        },
      });
    }
  }

  toggleWhatMatters() {
    this.WhatMatters = !this.WhatMatters;
    this.meaningLogs = false;
  }
  togglemeaningLogs() {
    this.WhatMatters = false;
    this.meaningLogs = !this.meaningLogs;
  }
  checkStatus(status) {
    return CommonService.checkMemberStatus(status);
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.topicSubscription) {
      this.topicSubscription.unsubscribe();
    }
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }
}
