<kt-spinner></kt-spinner>
<div class="">
  <button mat-button mat-dialog-close class="modal_close_button_top">X</button>
  <div class="scrollDivClass" id="meaningCommunityDialogId-">
    <!-- <h1 class="mb-0" title="{{ circleData?.name }}">
      {{ circleData?.name }}
    </h1> -->
    <h1 class="mb-0" title="{{ group_name }}">
      {{ group_name }}
    </h1>
    <hr />
    <mat-dialog-content>
      <div class="scroll_class_list card-height-popup-with-heading">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <img class="img-fluid communityImgClass"  [src]="image_path" />
            <!-- <img class="img-fluid communityImgClass" *ngIf="!circleData?.image_path"
              src="../../../../../assets/media/default/community_default.png" />
            <img class="img-fluid communityImgClass" *ngIf="circleData?.image_path" [src]="circleData?.image_path" /> -->
            <div class="row">
              <div class="col-lg-12 col-md-12 text-left">
                <div class="star-rating">
                  <ul class="ratinglist finalRatings">
                    <!-- <li *ngFor="let filledNumber of counter(circleData?.group_rating)"> -->
                      <li *ngFor="let filledNumber of counter(rating)">
                      <a class="no-underline">
                        <img src="./assets/images/colored.png" />
                      </a>
                    </li>
                    <!-- <li *ngFor="let emptyNumber of counter(5 - circleData?.group_rating)"> -->
                      <li *ngFor="let emptyNumber of counter(5 - rating)">
                      <a class="no-underline">
                        <img src="./assets/images/blank.png" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between popupStats">
              <div class="brdDivClass px-3 py-2 mb-2 community-box monthsactive-box1">
                <div class="d-flex justify-content-center labelClass pb-1">
                  <h1 class="fs-24">{{ circleData?.monthActive }}</h1>
                </div>
                <div class="d-flex justify-content-center labelCardClass">
                  Months Active
                </div>
              </div>

              <div class="brdDivClass px-3 py-2 mb-2 community-box members-box1">
                <div class="d-flex justify-content-center labelClass pb-1">
                  <h1 class="fs-24">{{ circleData?.communityMemberCount }}</h1>
                </div>
                <div class="d-flex justify-content-center labelCardClass">
                  Members
                </div>
              </div>

              <div class="brdDivClass px-3 py-2 mb-2 community-box posts-box1">
                <div class="d-flex justify-content-center labelClass pb-1">
                  <h1 class="fs-24">{{ circleData?.communityPostCounts }}</h1>
                </div>
                <div class="d-flex justify-content-center labelCardClass">
                  Posts
                </div>
              </div>

              <div class="brdDivClass px-3 py-2 mb-2 community-box members-in-your-network-box1">
                <div class="d-flex justify-content-center labelClass pb-1">
                  <h1 class="fs-24">
                    {{ circleData?.myNetworkConnectedMemberCount }}
                  </h1>
                </div>
                <div class="d-flex justify-content-center text-center labelCardClass">
                  Members in your Network
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="scrool-commnity-area">
              <div class="row brdDivClass ml-2 pl-3 brdDivClass-scroll">
                <mat-tab-group animationDuration="0ms" class="w-100">
                  <mat-tab label="About this Community">
                    <div class="labelValueClass py-2">
                      <!-- <p [innerHTML]="sanitizer.bypassSecurityTrustHtml(circleData?.description)"></p> -->
                      <p [innerHTML]="sanitizer.bypassSecurityTrustHtml(group_description)"></p>
                    </div>
                    <div class="labelValueClass">
                      <p>We are focused on the following Topics:</p>
                    </div>
                    <div class="labelValueClass">
                      <ng-container *ngIf="topic_count > 0; else empty_topic_communityData">
                        <ul>
                          <li *ngFor="let circleTopicsObject of topicArr">
                            <p>{{ circleTopicsObject }}</p>
                          </li>
                        </ul>
                      </ng-container>
                      <ng-template #empty_topic_communityData>
                        <p class="labelValueClass mb-0">No Topics Found.</p>
                      </ng-template>
                    </div>
                  </mat-tab>

                  <mat-tab label="Hosts">
                    <ng-template matTabContent >
                      <kt-single-community-popup-host-list [communityId]="communityId"></kt-single-community-popup-host-list>
                    </ng-template>
                  </mat-tab>

                  <mat-tab label="Upcoming Dialogs">
                    <ng-template matTabContent >
                      <kt-single-community-popup-upcoming-dialog-list [communityId]="communityId"></kt-single-community-popup-upcoming-dialog-list>
                    </ng-template>
                  </mat-tab>

                  <!-- <mat-tab label="Hosts">
                    <div class="labelClass mt-2">
                      <p>
                        This Community is hosted by:
                        <span tooltipClass="tooltip_design" theme="light"
                          tooltip="A Community Host is responsible for moderating the Conversations and Dialogs of the Community."
                          placement="right" showDelay="500">
                          <span class="icon-info">
                            <mat-icon class="info_material_icon">info</mat-icon>
                          </span>
                        </span>
                      </p>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <ng-container *ngFor="
                            let memberObject of circleData?.hostsInCommunities
                          ">
                          <div class="row pt-1 pb-2 px-4">
                            <div class="col-lg-1 col-md-1 px-0 brdwidth">
                              <img class="img-fluid memberImgClass brdRadiusClass" *ngIf="
                                  !memberObject?.member__member_table__picture_path
                                " src="../../../../../assets/media/default/guide_default.png" />
                              <img class="img-fluid memberImgClass brdRadiusClass" *ngIf="
                                  memberObject?.member__member_table__picture_path
                                " [src]="
                                  memberObject?.member__member_table__picture_path
                                " />
                            </div>
                            <div class="col-lg-10 col-md-10 labelValueClass pl-0 py-2">
                              <p>
                                {{
                                memberObject?.member__member_table__full_name
                                }}
                              </p>
                            </div>
                          </div>
                        </ng-container>

                        <ng-template>
                          <div class="row py-2 px-4">
                            <div class="col-lg-10 col-md-10 labelValueClass pl-2">
                              No Community Host Found!
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </mat-tab> -->

                  <!-- <mat-tab label="Upcoming Dialogs">
                    <ng-container *ngIf="upcoming_circle_count > 0">
                      <div class="row py-2 px-4" *ngFor="
                          let dialogObject of upcomingDialogListUnderCircleDatas
                        ">
                        <div class="col-lg-2 col-md-2 labelValueClass pl-0">
                          {{ dialogObject.start_date | date: "MMM d" }}
                        </div>
                        <div class="col-lg-10 col-md-10 labelValueClass pl-0">
                          <p class="labelValueClass only_one_line_show mb-0"
                            title="{{ dialogObject.name | titlecase }}">
                            {{ dialogObject.name | titlecase }}
                          </p>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="upcoming_circle_count == 0">
                      <div class="row py-2 px-4">
                        <div class="col-lg-10 col-md-10 labelValueClass pl-2">
                          No Upcoming Dialogs Found!
                        </div>
                      </div>
                    </ng-container>
                  </mat-tab> -->

                </mat-tab-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
  <div class="row ml-3 mr-2 mt-3 mb-0">
    <div class="col-lg-12 col-md-12 text-right">
      <table width="100%" border="0">
        <tr>
          <td *ngIf="is_member_present_in_this_group == 0">
            <button class="btn begin_quiz" (click)="memberJoinCommunity()">
              JOIN COMMUNITY
            </button>
          </td>
          <td *ngIf="is_member_present_in_this_group == 1 && group_role == 1158">
            <button class="btn begin_quiz withdraw_btn" (click)="withDrawCommunity()">
              Leave Community
            </button>
          </td>
          <td width="5%">
            <button class="btn begin_quiz canceled_btn" mat-dialog-close>
              CANCEL
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>