<div class="col-sm-12 pl-0">
  <div class="col-sm-12">
    <div id="divchart_hor3">
    <canvas id="barchart_hor3" class="col-md-10 my-2"></canvas>
</div>
    <h2 class="section-title1 mb-10">Life-Work Balance Practical Examples</h2>

    <p class="section-text mt-3">
      Below is an example of someone who has relatively high PMP scores. Yet,
      their scores on the wellbeing items are relatively low. When this person
      considers their survey results, they may feel there's more to life than
      work and choose to rebalance their life and work as it makes sense for
      their needs.
    </p>

    <div id="divchart_hor4">
      <canvas id="barchart_hor4" class="col-md-10 my-1"></canvas>
    </div>
  </div>
</div>
