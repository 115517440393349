import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  environment;
  apiUrl;
  constructor(public http: HttpClient, private router: Router) {
    this.apiUrl = environment.apiUrl;
  }
  OnSubmit(ngForm): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post<any>(this.apiUrl + 'member/member-create', ngForm, {
      headers: headers,
    });
  }

  OnVerifyOtpSubmit(ngForm): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post<any>(this.apiUrl + 'member/member-verify', ngForm, {
      headers: headers,
    });
  }

  generateNewOtp(ngForm): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post<any>(
      this.apiUrl + 'member/member-generate-otp',
      ngForm,
      {
        headers: headers,
      }
    );
  }
  saveVisitordata(NgForm) {
    return this.http.post<RegisterData>(
      this.apiUrl + 'tenant/current-signup',
      NgForm
    );
  }
}

export interface RegisterData {
  first_name: number;
  last_name: string;
  password: string;
  email: string;
  message: string;
  status: number;
}
