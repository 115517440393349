<kt-spinner></kt-spinner>
<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1 class="popup_card_title">Education Highlights</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form
    [formGroup]="educationForm"
    (ngSubmit)="educationSave(educationForm.value)"
    novalidate
  >
    <div class="multi-collapse" id="multiCollapseExample3">
      <div class="card-body row profile_section_button">
        <mat-form-field>
          <mat-label>Educational Institution</mat-label>
          <input
            type="text"
            matInput
            tabindex="1"
            formControlName="title"
            autocomplete="off"
            maxlength="96"
            #educationSearchInput
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"
          />
        </mat-form-field>
        <div class="country-box-child-div" *ngIf="EducationBox">
          <cdk-virtual-scroll-viewport class="country-viewport">
            <div class="country-autocom-box">
              <li
                *cdkVirtualFor="let inst of InstituteList"
                (click)="setInstitute(inst.name, inst.id)"
              >
                {{ inst.name }}
              </li>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>

        <mat-form-field>
          <mat-label>Qualification</mat-label>
          <input
            matInput
            tabindex="2"
            maxlength="96"
            formControlName="company"
            autocomplete="off"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Field of Study</mat-label>
          <input
            matInput
            maxlength="96"
            tabindex="3"
            formControlName="location"
            autocomplete="off"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Start Date</mat-label>
          <input
            matInput
            readonly
            tabindex="4"
            [max]="maxDate"
            [matDatepicker]="start_date"
            formControlName="start_date"
            (dateChange)="onChangeFrom($event)"
            (click)="$event.stopPropagation()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="start_date"
          ></mat-datepicker-toggle>
          <mat-datepicker #start_date></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>End Date</mat-label>
          <input
            matInput
            [min]="minDate"
            readonly
            [matDatepicker]="end_date"
            formControlName="end_date"
            tabindex="5"
            (dateChange)="onChangeTo($event)"
            (click)="$event.stopPropagation()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="end_date"
          ></mat-datepicker-toggle>
          <mat-datepicker #end_date></mat-datepicker>
        </mat-form-field>
      </div>
      <hr />
    </div>
    <table width="100%" class="mt-5" align="right" border="0">
      <tr>
        <td width="80%">&nbsp;</td>
        <td>
          <button
            [disabled]="!educationForm.valid || saveButton"
            class="btn begin_quiz my_btn"
          >
            Save
          </button>
        </td>
        <td>
          <button
            mat-dialog-close
            class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
          >
            Cancel
          </button>
        </td>
      </tr>
    </table>
  </form>
</div>
