import { NotificationService } from './../../../../service/notification.service';
import { AuthService } from './../../../../service/auth.service';
import { SurveyService } from './../../../../service/survey.service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { message_properties } from '../../../../../environments/message_properties';
import { ReportFirstPageComponent } from '../report-first-page/report-first-page.component';
import { MatDialog } from '@angular/material/dialog';
import { FinalReportComponent } from '../final-report/final-report.component';
import { Subscription } from 'rxjs';
import { FinalReportFirstPageComponent } from '../final-report-first-page/final-report-first-page.component';
import { AccountsService } from './../../../../service/accounts.service';
@Component({
  selector: 'kt-give-survey',
  templateUrl: './give-survey.component.html',
  styleUrls: ['./give-survey.component.scss'],
})
export class GiveSurveyComponent implements OnInit, OnDestroy {
  surveyForm: UntypedFormGroup;
  selectedValue;
  survey_id;
  question_text;
  survey_sequence;
  survey_answers;
  questiontype;
  help_text;
  submit_data = {};
  question_id: number;
  max_sequence_number: number;
  progress_bar;
  number_of_save: string;
  selected_answer: number;
  sequence: number;
  first_time_number_of_save: string;
  date_time_str: string;
  qresponce_id: number;
  free_format: string;
  survey_status: number;
  participantSurveyId: number;
  submitButtonShowAndHide: boolean = false;
  participantId: number;
  saveForLater: number = 0;
  json_report = [];
  researchSubscription: Subscription;
  previousQuestionSubscription: Subscription;
  firstQuestionSubscription: Subscription;
  research_data: boolean;
  constructor(
    private questionService: SurveyService,
    private route: Router,
    private _cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private toastr: NotificationService,
    private dialog: MatDialog,
    private accountService: AccountsService
  ) {}
  async ngOnInit() {
    this.participantId = this.accountService.muser;
    this.checkSubmissionDateValidationForSharedData();
    this.participantSurveyId = localStorage.getItem('participantSurveyId')
      ? Number(localStorage.getItem('participantSurveyId'))
      : 0;
    this.sequence = localStorage.getItem('number_of_save')
      ? Number(localStorage.getItem('number_of_save'))
      : 1;

    this.date_time_str = new Date()
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ');
    this.getFirstQuestion(
      1,
      this.sequence,
      2306,
      this.date_time_str,
      this.qresponce_id
    );
    this.surveyForm = new UntypedFormGroup({
      question_answer: new UntypedFormControl('', [Validators.required]),
      sequence_type: new UntypedFormControl('N'),
    });
  }

  getFirstQuestion(survey_, sequence_, survey_type, datetime, qresponce_id) {
    this.progress_bar = 0;
    this.spinner.show();
    this.firstQuestionSubscription = this.questionService
      .getSurveyData(
        survey_,
        sequence_,
        survey_type,
        datetime,
        qresponce_id,
        this.participantSurveyId
      )
      .subscribe((data) => {
        this.help_text = data.survey_response_view['help_text'];
        this.question_text = data.survey_response_view['question_text'];
        this.survey_sequence = data.survey_response_view['sequence'];
        this.survey_answers = data.survey_response_view['answers'];
        this.survey_id = data.survey_response_view['survey'];
        this.question_id = data.survey_response_view['id'];
        this.questiontype = data.survey_response_view['question_type'];
        this.max_sequence_number =
          data.survey_response_view['max_sequence_number'];

        this.number_of_save = data.survey_response_view['number_of_save'];

        this.progress_bar = localStorage.getItem('participantSurveyId')
          ? data.survey_response_view['progress_bar']
          : 0;

        this.selected_answer =
          data.survey_response_view['submitted_answer_option'];
        this.free_format =
          data.survey_response_view['submitted_answer_free_format'];

        this.qresponce_id = data.survey_response_view['qresponce_id'];
        this.surveyForm.patchValue({
          question_answer: this.selected_answer,
        });
        this.submitButtonShowAndHide = true;
        this._cdr.detectChanges();
        this.spinner.hide();
      });
  }
  getNextPreviousQuestion(ngForm) {
    this.spinner.show();
    this.submit_data = {
      survey: this.survey_id,
      participantSurveyId: this.participantSurveyId,
      sequence: this.survey_sequence,
      sequence_type:
        ngForm.value['sequence_type'] !== ''
          ? ngForm.value['sequence_type']
          : 'N',
      survey_type: 2306,
      datetime: this.date_time_str,
      question_answer:
        ngForm.value['question_answer'] === undefined
          ? ''
          : ngForm.value['question_answer'].toString(),
    };
    this.previousQuestionSubscription = this.questionService
      .getNextQuestionData(this.submit_data)
      .subscribe((data) => {
        if (data.survey_response_view['status'] === 404) {
          this.spinner.hide();
          this.toastr.showError(data.survey_response_view['message'], '');
        } else {
          this.questiontype = data.survey_response_view['question_type'];

          this.help_text = data.survey_response_view['help_text'];
          this.question_text = data.survey_response_view['question_text'];
          this.survey_sequence = data.survey_response_view['sequence'];
          this.survey_answers = data.survey_response_view['answers'];
          this.survey_id = data.survey_response_view['survey'];
          this.question_id = data.survey_response_view['id'];

          this.max_sequence_number =
            data.survey_response_view['max_sequence_number'];
          this.progress_bar = data.survey_response_view['progress_bar'];
          this.number_of_save = data.survey_response_view['number_of_save'];
          this.qresponce_id = data.survey_response_view['qresponce_id'];
          this.free_format =
            data.survey_response_view['submitted_answer_free_format'];
          this.survey_status = data.survey_response_view['survey_status'];

          this.selected_answer =
            data.survey_response_view['submitted_answer_option'];
          this.surveyForm.patchValue({
            question_answer: this.selected_answer,
          });
          this.saveForLater = Number(
            data.survey_response_view['save_for_later']
          );
          if (this.saveForLater === 1) {
            localStorage.removeItem('number_of_save');
            localStorage.removeItem('participantSurveyId');
            this.toastr.showSuccess(
              message_properties.MEANINGFUL_SURVEY_SAVE_FOR_LATER,
              ''
            );
            this.spinner.hide();
            this.route.navigateByUrl('/meaningful-work-survey/my-surveys');
          }

          if (this.survey_status === 2252) {
            localStorage.removeItem('number_of_save');
            localStorage.removeItem('participantSurveyId');
            this.spinner.hide();
            this.toastr.showSuccess(message_properties.SAVE_SURVEY_SUCCESS, '');
            this._cdr.detectChanges();
            if (!this.research_data) {
              this.goToReportFirstPage(
                data.survey_response_view['obj_participant_survey_id'],
                data.survey_response_view['json_report']
              );
            } else {
              this.openBeforeViewReportPage(
                data.survey_response_view['obj_participant_survey_id'],
                data.survey_response_view['json_report']
              );
            }
            this.route.navigateByUrl('/meaningful-work-survey/my-surveys');
          }
          this.spinner.hide();
          this._cdr.detectChanges();
        }
        this.submitButtonShowAndHide = true;
      });
  }
  openBeforeViewReportPage(selectedSurveyId, json_report) {
    let dialogRef = this.dialog.open(FinalReportFirstPageComponent, {
      width: '65%',
      disableClose: true,
      data: {
        currentSelectedSurvey: selectedSurveyId,
        memberId: this.participantId,
        json_report1: json_report,
      },
    });
  }
  sequenceType(type) {
    this.surveyForm.patchValue({
      sequence_type: type,
    });
  }

  goToReportFirstPage(selectedSurveyId, json_report) {
    let dialogRef = this.dialog.open(ReportFirstPageComponent, {
      width: '55%',
      disableClose: true,
      data: {
        currentSelectedSurvey: selectedSurveyId,
        memberId: this.participantId,
        json_report: json_report,
      },
    });
  }

  goToSelectedSurveyReport(selectedSurveyId, json_report) {
    let dialogRef = this.dialog.open(FinalReportComponent, {
      width: '80%',
      disableClose: true,
      data: {
        memberId: this.participantId,
        currentSelectedSurvey: selectedSurveyId,
        json_report1: json_report,
      },
    });
  }
  checkSubmissionDateValidationForSharedData() {
    this.researchSubscription = this.questionService
      .checkReportSubmissionDate(this.participantId)
      .subscribe((data) => {
        if (data.status === 200) {
          // not give research data
          this.research_data = false;
        } else if (data.status === 201) {
          // given research data
          this.research_data = true;
        }
      });
  }
  ngOnDestroy(): void {
    if (this.researchSubscription) {
      this.researchSubscription.unsubscribe();
    }
    if (this.previousQuestionSubscription) {
      this.previousQuestionSubscription.unsubscribe();
    }
    if (this.firstQuestionSubscription) {
      this.firstQuestionSubscription.unsubscribe();
    }
  }
}
