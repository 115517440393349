import { MessageDetailsComponent } from './header/message/message-details/message-details.component';
import { MessageListComponent } from './header/message/message-list/message-list.component';
import { NotificationsComponent } from './header/notifications/notifications.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxPermissionsModule } from 'ngx-permissions';
// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// NgBootstrap
import {
  NgbProgressbarModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
// Translation
import { TranslateModule } from '@ngx-translate/core';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { BrandComponent } from './brand/brand.component';
import { TopbarComponent } from './header/topbar/topbar.component';
import { BaseComponent } from './base/base.component';
import { PagesModule } from '../pages/pages.module';
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { GuideDiscussionCartComponent } from './header/guide-discussion-cart/guide-discussion-cart.component';
import { GuideDiscussionEventComponent } from './header/guide-discussion-event/guide-discussion-event.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { InfoPopupComponent } from './header/info-popup/info-popup.component';
import { InfoMomDetailsComponent } from './header/info-mom-details/info-mom-details.component';
import { ReviewInformationComponent } from './header/review-information/review-information.component';
import { ReviewCartItemsComponent } from './header/review-cart-items/review-cart-items.component';
import { BraintreeComponent } from './header/braintree/braintree.component';
import { AlertHeaderComponent } from './alert-header/alert-header.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxBraintreeModule } from 'ngx-braintree';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
    declarations: [
        BaseComponent,
        FooterComponent,
        NotificationsComponent,
        // MyAccountComponent,
        // headers
        HeaderComponent,
        BrandComponent,
        HeaderMobileComponent,
        MessageDetailsComponent,
        MessageListComponent,
        // subheader
        SidebarMenuComponent,
        // topbar components
        TopbarComponent,
        GuideDiscussionCartComponent,
        GuideDiscussionEventComponent,
        InfoPopupComponent,
        InfoMomDetailsComponent,
        ReviewInformationComponent,
        ReviewCartItemsComponent,
        BraintreeComponent,
        AlertHeaderComponent
    ],
    exports: [
        BaseComponent,
        FooterComponent,
        // headers
        HeaderComponent,
        BrandComponent,
        HeaderMobileComponent,
        // subheader
        SidebarMenuComponent,
        // topbar components
        TopbarComponent,
    ],
    providers: [],
    imports: [
        CommonModule,
        RouterModule,
        NgxPermissionsModule.forChild(),
        PagesModule,
        PerfectScrollbarModule,
        FormsModule,
        MatButtonModule,
        TranslateModule.forChild(),
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatRadioModule,
        MatDialogModule,
        ReactiveFormsModule,
        ScrollingModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatMenuModule,
        NgxSpinnerModule,
        MatIconModule,
        MatTabsModule,
        NgbProgressbarModule,
        NgbTooltipModule,
        TooltipModule,
        NgxBraintreeModule,
        InfiniteScrollModule
    ]
})
export class ThemeModule {}
