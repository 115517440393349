import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../../../service/notification.service';
import { RegisterService } from '../../../../service/register.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-not-eligible-country-form',
  templateUrl: './not-eligible-country-form.component.html',
  styleUrls: ['./not-eligible-country-form.component.scss'],
})
export class NotEligibleCountryFormComponent implements OnInit, OnDestroy {
  visitorForm: UntypedFormGroup;
  saveButton: boolean = false;
  visitorSaveDataSubscription: Subscription;
  constructor(
    private spinner: NgxSpinnerService,
    private register: RegisterService,
    private _router: Router,
    private toastr: NotificationService
  ) {}

  ngOnInit(): void {
    this.visitorForm = new UntypedFormGroup({
      first_name: new UntypedFormControl('', [Validators.required]),
      last_name: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(150),
      ]),
    });
  }

  onSubmit(ngForm: NgForm) {
    this.saveButton = true;
    this.spinner.show();
    return (this.visitorSaveDataSubscription = this.register
      .saveVisitordata(ngForm)
      .subscribe((data) => {
        if (data.status === 201) {
          this._router.navigate(['/home']);
        } else {
          this.toastr.showError(
            'Oh Something went wrong try after sometime!',
            ''
          );
        }
        this.spinner.hide();
      }));
  }
  ngOnDestroy(): void {
    if (this.visitorSaveDataSubscription) {
      this.visitorSaveDataSubscription.unsubscribe();
    }
  }
}
