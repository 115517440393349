<mat-card
  class="welcomPopup archive_main_card bg-color-transp back_card_background_clr"
>
  <!-- <span class="close-dialog" (click)="close()">X</span> -->
  <mat-card-content>
    <div class="row welcom-content">
      <div class="col-lg-6 col-md-6 col-sm-12 welcom-content-desc">
        <h3>Welcome to</h3>
        <h1>MeaningSphere</h1>

        <p class="mt-30">Where you will encounter:</p>
        
        <p>
          <strong>A curious collection of tools</strong> that help you discover
          key insights about yourself and how you receive deep, personal
          fulfillment in the workplace.
        </p>
        <p>
          <strong>An inspiring and supportive community</strong> that enables you to co-create meaningful work experiences.
        </p>
        <p>
          <strong>A rare space</strong> that encourages you to slow down, reflect, and <strong><i>focus on you</i></strong> without chronic social media pressure.
        </p>
        <div class="welcome-buttons mt-30">
          <button (click)="nextPage()" type="button" class="btn canceled_btn">
            Next: Express Yourself
          </button>
          <button (click)="close()" type="button" class="btn ml-10">
            Close
          </button>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 welcom-content-bg">
        <img
          src="./assets/images/welcome1.jpg"
          id="dropdownMenuButton"
          alt=""
          srcset=""
        />
      </div>
    </div>
  </mat-card-content>
</mat-card>
