import { Component, OnInit, Input } from '@angular/core';
import { EachVedioComponent } from '../../each-vedio/each-vedio.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'kt-surveyreport4',
  templateUrl: './surveyreport4.component.html',
  styleUrls: ['./surveyreport4.component.scss'],
})
export class Surveyreport4Component implements OnInit {
  data1: any;
  report_4_body: any;
  @Input() memberId;
  @Input() currentSelectedSurvey;
  @Input() json_report;
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  mayaVideo() {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: {
        source: 'https://player.vimeo.com/video/759651319?h=085eb43f65',
      },
    });
  }
}
