import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  apiUrl = environment.apiUrl;
  environment;
  email: string;
  password: string;
  var_check: number;
  data: any;
  constructor(public http: HttpClient, private router: Router) {
    this.apiUrl = environment.apiUrl;
  }
  username: string;

  loginChecking(ngForm): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    return this.http.post<any>(
      this.apiUrl + 'accounts/token-based-auth',
      ngForm,
      { headers: headers }
    );
  }

  getToken() {
    if (localStorage.getItem('token_refresh_data')) {
      const token_refresh_data = JSON.parse(
        localStorage.getItem('token_refresh_data')
      );
      const token = token_refresh_data.token;
      if (!token) {
        return false;
      }
      const helper = new JwtHelperService();
      //const expirationDate = helper.getTokenExpirationDate(token);
      const isExpire = helper.isTokenExpired(token);
      if (isExpire) {
        return isExpire;
      }
      return token;
    } else {
      return false;
    }
  }

  get loggedIn() {
    if (localStorage.getItem('token_refresh_data')) {
      const token = this.token;
      if (!token) {
        return false;
      }
      const helper = new JwtHelperService();
      //const expirationDate = helper.getTokenExpirationDate(token);
      const isExpire = helper.isTokenExpired(token);
      if (isExpire) {
      }
      return !isExpire;
    } else {
      return false;
    }
  }

  logoutuser(postData) {
    return this.http.post<LogoutData>(
      this.apiUrl + 'accounts/token-auth-delete',
      postData
    );
  }

  //member-password-change
  passwordChange(ngForm, userid) {
    return this.http.put<PasswordData>(
      this.apiUrl + 'member/member-password-change/' + userid,
      ngForm
    );
  }
  get currentUser() {
    if (localStorage.getItem('token_refresh_data')) {
      const token = this.token;
      if (!token) {
        return false;
      }
      return new JwtHelperService().decodeToken(token).user_id;
    } else {
      return false;
    }
  }

  get token() {
    if (localStorage.getItem('token_refresh_data')) {
      const token_refresh_data = JSON.parse(
        localStorage.getItem('token_refresh_data')
      );
      const token = token_refresh_data.token;
      if (!token) {
        return false;
      }
      const helper = new JwtHelperService();
      //const expirationDate = helper.getTokenExpirationDate(token);
      const isExpire = helper.isTokenExpired(token);
      if (isExpire) {
        return isExpire;
      }
      return token;
    } else {
      return false;
    }
  }

  get refresh_token() {
    if (localStorage.getItem('token_refresh_data')) {
      const token_refresh_data = JSON.parse(
        localStorage.getItem('token_refresh_data')
      );
      const refresh = token_refresh_data.refresh;
      return refresh;
    } else {
      return false;
    }
  }

  token_key_decode() {
    if (localStorage.getItem('token_refresh_data')) {
      const token_refresh_data = JSON.parse(
        localStorage.getItem('token_refresh_data')
      );
      const token = token_refresh_data.token;
      if (!token) {
        return false;
      }
      const helper = new JwtHelperService();
      const isExpire = helper.isTokenExpired(token);
      if (isExpire) {
        return isExpire;
      }
      return JSON.parse(jwt_decode(token)['all_keys'])[0];
    } else {
      return false;
    }
  }
  get account_status() {
    return this.token_key_decode().account_status;
  }
  get admin_indicator() {
    return this.token_key_decode().admin_indicator;
  }
  get curator_indicator() {
    return this.token_key_decode().curator_indicator;
  }
  get mentor_indicator() {
    return this.token_key_decode().mentor_indicator;
  }
  get program_director_indicator() {
    return this.token_key_decode().program_director_indicator;
  }
  get employee_indicator() {
    return this.token_key_decode().employee_indicator;
  }
  get past_mentor_indicator() {
    return this.token_key_decode().past_mentor_indicator;
  }
  get past_admin_indicator() {
    return this.token_key_decode().past_admin_indicator;
  }
  get past_curator_indicator() {
    return this.token_key_decode().past_curator_indicator;
  }
  get past_program_director_indicator() {
    return this.token_key_decode().past_program_director_indicator;
  }
  get AWS_BUCKET_111() {
    return this.token_key_decode().AWS_BUCKET_111;
  }
  get AWS_BUCKET_222() {
    return this.token_key_decode().AWS_BUCKET_222;
  }
  get AWS_BUCKET_333() {
    return this.token_key_decode().AWS_BUCKET_333;
  }
  get AWS_BUCKET_444() {
    return this.token_key_decode().AWS_BUCKET_444;
  }
  get PENDO_API() {
    return this.token_key_decode().PENDO_API;
  }
  get USER_SNAP() {
    return this.token_key_decode().USER_SNAP;
  }
  get AWS_BUCKET_777() {
    return this.token_key_decode().AWS_BUCKET_777;
  }
  get AWS_BUCKET_888() {
    return this.token_key_decode().AWS_BUCKET_888;
  }
  get COMPAIR_DATE_FOR_LOGOUT() {
    return this.token_key_decode().COMPAIR_DATE_FOR_LOGOUT;
  }
  get banner_message_count() {
    return this.token_key_decode().banner_message_count;
  }
  get banner_message() {
    return this.token_key_decode().banner_message;
  }
  get banner_id() {
    return this.token_key_decode().banner_id;
  }
  get secondary_city() {
    return this.token_key_decode().secondary_city;
  }
  get secondary_state() {
    return this.token_key_decode().secondary_state;
  }
  get secondary_country() {
    return this.token_key_decode().secondary_country;
  }
  get secondary_country_id() {
    return this.token_key_decode().secondary_country_id;
  }
  get secondary_city_id() {
    return this.token_key_decode().secondary_city_id;
  }
  get hasGuide() {
    return this.token_key_decode().hasGuide;
  }
  get muser() {
    return Number(this.token_key_decode().muser);
  }
  get user_email() {
    return this.token_key_decode().user_email;
  }
  get country_id() {
    return this.token_key_decode().country_id;
  }
  get city_id() {
    return this.token_key_decode().city_id;
  }
  get number_of_save() {
    return this.token_key_decode().number_of_save;
  }
  get full_name() {
    return this.token_key_decode().full_name;
  }
  get first_name() {
    return this.token_key_decode().first_name;
  }
  get last_name() {
    return this.token_key_decode().last_name;
  }
  get city() {
    return this.token_key_decode().city;
  }
  get state() {
    return this.token_key_decode().state;
  }
  get country() {
    return this.token_key_decode().country;
  }
  get uname() {
    return this.token_key_decode().uname;
  }
  get role() {
    return this.token_key_decode().role;
  }
  get mfid() {
    return this.token_key_decode().mfid;
  }
  get image() {
    return this.token_key_decode().image;
  }
  get current_time_zone() {
    return this.token_key_decode().current_time_zone;
  }
  get show_mirror_overview() {
    return this.token_key_decode().show_mirror_overview;
  }
  get member_mood_inserted() {
    return this.token_key_decode().member_mood_inserted;
  }
  get show_mom_overview() {
    return this.token_key_decode().show_mom_overview;
  }
  get show_guide_overview() {
    return this.token_key_decode().show_guide_overview;
  }
  get show_network_overview() {
    return this.token_key_decode().show_network_overview;
  }
  get show_community_overview() {
    return this.token_key_decode().show_community_overview;
  }
  get show_circle_overview() {
    return this.token_key_decode().show_circle_overview;
  }
  get show_constellation_overview() {
    return this.token_key_decode().show_constellation_overview;
  }
  get show_portrait_overview() {
    return this.token_key_decode().show_portrait_overview;
  }
  get show_explorer_overview() {
    return this.token_key_decode().show_explorer_overview;
  }
  get show_diy_overview() {
    return this.token_key_decode().show_diy_overview;
  }
  get onboarding_complete() {
    return this.token_key_decode().onboarding_complete;
  }
  get user_id() {
    return this.token_key_decode().user_id;
  }
}

export interface LoginData {
  var_check: number;
  username: string;
  password: string;
  token: string;
  code: number;
  muser: number;
  full_name: any;
  mfid: number;
  number_of_save: any;
  admin_indicator: any;
  curator_indicator: any;
  mentor_indicator: any;
  user_email: string;
}
export interface PasswordData {
  code: number;
  password: string;
  message: string;
}
export interface LogoutData {
  response: string;
  refresh: string;
  access: string;
}
