import { MessagesService } from '../../../../service/messages.service';
import { NotificationService } from '../../../../service/notification.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Optional,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { message_properties } from './../../../../../environments/message_properties';
import { switchMap } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SetMessageToPupupComponent } from '../../../shared/set-message-to-pupup/set-message-to-pupup.component';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-send-massage',
  templateUrl: './send-massage.component.html',
  styleUrls: ['./send-massage.component.scss'],
})
export class SendMassageComponent implements OnInit, OnDestroy {
  sendmessageForm: UntypedFormGroup;
  collabContentId: number;
  creatingUser: number;
  usersdata: any[];
  messageFrom: number;
  mentee_id: number;
  clicked_from: number;
  is_admin: boolean;
  saveButton: boolean = false;
  full_name: string;
  profile_picture: string;
  valueArr = [];

  selectedMessagedataIds: any = [];
  suggestedMessagedata: any;
  suggestedHdata: any;
  messageSendSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<SendMassageComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private _cdr: ChangeDetectorRef,
    private toastr: NotificationService,
    private messageService: MessagesService,
    private dialog: MatDialog,
    private accountService: AccountsService
  ) {
    this.collabContentId = data.collabContentId;
    this.mentee_id = data.mentee_id;
    this.clicked_from = data.clicked_from;
    this.messageFrom = data.messageFrom;
    this.full_name = data.full_name;
    this.profile_picture =
      data.profile_picture != null
        ? data.profile_picture
        : './assets/media/users/default.jpg';
  }

  ngOnInit(): void {
    this.creatingUser = this.accountService.muser;
    this.sendmessageForm = this.formBuilder.group({
      message: new UntypedFormControl('', [Validators.required]),
      chat_for: new UntypedFormControl(1796),
      criticality: new UntypedFormControl(1576),
      receiving_user: new UntypedFormControl('', [Validators.required]),
      creating_user: new UntypedFormControl(this.creatingUser),
      collabContentId: new UntypedFormControl(this.collabContentId),
      tenant: new UntypedFormControl(1),
      all_admin_indicator: new UntypedFormControl(false),
      all_curator_indicator: new UntypedFormControl(false),
      all_guide_indicator: new UntypedFormControl(false),
      all_explorer_indicator: new UntypedFormControl(false),
      radio_select:
        this.clicked_from === 1009
          ? new UntypedFormControl('', [Validators.required])
          : new UntypedFormControl(''),
      check_box: new UntypedFormControl(''),
    });
    if (this.mentee_id != null) {
      this.sendmessageForm.patchValue({
        receiving_user: [this.mentee_id],
      });
    }
    this.is_admin = this.accountService.admin_indicator;
  }

  sendMessage(ngForm: NgForm) {
    this.saveButton = true;
    let observer = new Promise((resolve) => setTimeout(resolve, 1000));
    this.messageSendSubscription = this.messageService
      .postMassage(ngForm)
      .pipe(
        switchMap((event) => {
          this.saveButton = true;
          this.dialogRef.close();
          return observer;
        })
      )
      .subscribe((data) => {
        this.dialogRef.close();
        if (this.messageFrom === 0) {
          this.saveButton = false;
          this.toastr.showSuccess(
            message_properties.SEND_MESSAGE_SAVE_MESSSAGE_SUCCESS,
            ''
          );
        } else {
          this.saveButton = false;
          this.toastr.showSuccess(
            message_properties.SEND_MESSAGE_SAVE_MESSAGE_SUCCESS1,
            ''
          );
        }
      });
  }
  selectMessageType(selectMessageType) {
    if (selectMessageType === 1) {
      this.sendmessageForm
        .get('check_box')
        .setValidators([Validators.required]);
      this.sendmessageForm.get('check_box').updateValueAndValidity();
    } else {
      this.sendmessageForm.patchValue({
        all_admin_indicator: false,
        all_curator_indicator: false,
        all_guide_indicator: false,
        all_explorer_indicator: false,
      });
      this.sendmessageForm.get('check_box').clearValidators();
      this.sendmessageForm.get('check_box').updateValueAndValidity();
    }
    if (selectMessageType === 2) {
      this.sendmessageForm
        .get('receiving_user')
        .setValidators([Validators.required]);
      this.sendmessageForm.get('receiving_user').updateValueAndValidity();
    } else {
      this.sendmessageForm.patchValue({
        receiving_user: '',
      });
      this.sendmessageForm.get('receiving_user').clearValidators();
      this.sendmessageForm.get('receiving_user').updateValueAndValidity();
    }
  }

  brodCastMessageSelect(ob: MatCheckboxChange) {
    if (ob.checked) {
      this.valueArr.push(ob.source.value);
    } else {
      this.removeFromArray(this.valueArr, ob.source.value);
    }
    if (this.valueArr.length > 0) {
      this.sendmessageForm.get('check_box').clearValidators();
      this.sendmessageForm.get('check_box').updateValueAndValidity();
    } else {
      this.sendmessageForm
        .get('check_box')
        .setValidators([Validators.required]);
      this.sendmessageForm.get('check_box').updateValueAndValidity();
    }
  }
  removeFromArray(arr, checked_value = '') {
    let what,
      a = arguments,
      L = a.length,
      ax;
    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  }

  openMessageToData(type) {
    const dialogRef = this.dialog.open(SetMessageToPupupComponent, {
      disableClose: true,
      width: '30%',
      data: {
        selectedHosts: this.selectedMessagedataIds,
        type: type,
        mode: 'single-selection',
        creatingUser: this.creatingUser,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.suggestedMessagedata = result.data.hosts;
        if (this.selectedMessagedataIds.length === 0) {
          this.selectedMessagedataIds = result.data.hosts.map((item) => {
            return item['id'];
          });
        } else {
          this.selectedMessagedataIds = [];
          this.suggestedMessagedata.forEach((element) => {
            this.selectedMessagedataIds.push(element.id);
          });
        }
        this.sendmessageForm.patchValue({
          receiving_user: this.selectedMessagedataIds,
        });
        this._cdr.detectChanges();
      }
    });
  }
  deleteSuggestedData(id) {
    let temphosts1 = this.suggestedMessagedata.filter(
      (element) => element.id !== id
    );
    this.suggestedMessagedata = temphosts1;
    this.selectedMessagedataIds = this.suggestedMessagedata.filter(
      (element) => element !== id
    );
    this.sendmessageForm.patchValue({
      receiving_user: this.selectedMessagedataIds,
    });
    this._cdr.detectChanges();
  }
  ngOnDestroy(): void {
    if (this.messageSendSubscription) {
      this.messageSendSubscription.unsubscribe();
    }
  }
}
