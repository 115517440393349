<kt-spinner></kt-spinner>
<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1 class="popup_card_title">Work Highlights</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form class="experienceForm" [formGroup]="experienceForm" (ngSubmit)="experienceSave(experienceForm.value)"
    novalidate>
    <div class="multi-collapse" id="multiCollapseExample2">
      <div class="card-body row profile_section_button">
        <span>
          <div class="row" *ngFor="let data of employeeData">
            {{ data.title }}
          </div>
          <hr />
        </span>
        <mat-form-field style="color: white">
          <mat-label>Role</mat-label>

          <input matInput maxlength="96" formControlName="title" autocomplete="off"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')" />
        </mat-form-field>
        <mat-form-field style="color: white">
          <mat-label>Organization</mat-label>

          <input matInput maxlength="96" formControlName="company" autocomplete="off"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')" />
        </mat-form-field>
        <mat-form-field style="color: white">
          <mat-label>Location</mat-label>

          <input matInput maxlength="96" formControlName="location" autocomplete="off"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')" />
        </mat-form-field>
        <br />
        <mat-checkbox formControlName="check_uncheck">I am currently working in this role.</mat-checkbox>
        <div class="mt-3" style="width: 100%;color: white">
          <p>What I found meaningful was...*</p>
          <textarea matInput [ngxSummernote]="config" formControlName="workprofile"></textarea>

          <hr />
        </div>
      </div>
    </div>
    <table width="100%" align="right" border="0" class="mt-5">
      <tr>
        <td width="80%">&nbsp;</td>
        <td align="right">
          <button [disabled]="!experienceForm.valid || saveButton" class="btn begin_quiz my_btn">
            Save
          </button>
        </td>
        <td align="right">
          <button mat-dialog-close class="btn begin_quiz button-change-scroll canceled_btn">
            Cancel
          </button>
        </td>
      </tr>
    </table>
    <!-- </div>
    </div> -->
  </form>
</div>