import { FinalReportComponent } from './../../../map-of-meaning/final-report/final-report.component';
import { MentorService } from '../../../../../service/mentor.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SurveyService } from '../../../../../service/survey.service';
import { NotificationService } from '../../../../../service/notification.service';
import { DiyDetailsComponent } from '../../../map-of-meaning/diy-activity/diy-details/diy-details.component';
import { MirrorViewComponent } from '../../../meaning-mirror/mirror-view/mirror-view.component';
import { message_properties } from '../../../../../../environments/message_properties';
import { AccountsService } from './../../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-endorse-documents',
  templateUrl: './endorse-documents.component.html',
  styleUrls: ['./endorse-documents.component.scss'],
})
export class EndorseDocumentsComponent implements OnInit, OnDestroy {
  @Input() member_relationship_id;
  @Input() menteeId: string;
  @Input() first_name;
  @Input() last_name;
  @Input() fullName;
  @Input() guideDp;
  @Input() menteeDp;
  @Input() mentorId: number;
  loggedinUserid: number;
  count: number;
  survey_id: string;
  participant: any;
  momData = [];
  allDies: {};
  containerView: number;
  documentType: number = 1;
  surveySubscription: Subscription;
  shareWithSubscription: Subscription;
  allDiySubscription: Subscription;
  showContainerSubscription: Subscription;
  unShareSubscription: Subscription;
  constructor(
    private dialog: MatDialog,
    private surveyService: SurveyService,
    private _cdref: ChangeDetectorRef,
    private toastr: NotificationService,
    private mentorService: MentorService,
    private accountService: AccountsService
  ) {}

  ngOnInit() {
    this.survey_id = '1';
    this.participant = this.accountService.mfid;
    this.loggedinUserid = this.accountService.muser;
    this.containerView = 1;
    this.showContainer(1);
  }
  momFilter(data) {
    let results = data['results'];
    this.momData = results.filter(function (mom) {
      return mom['survey_status'] === '2252';
    });
    this.count = data['count'];
    this._cdref.detectChanges();
  }
  getSurveyList() {
    localStorage.removeItem('number_of_save');
    localStorage.removeItem('participantSurveyId');
    this.surveySubscription = this.surveyService
      .getAllMapOfMeaningList(this.participant, this.survey_id)
      .subscribe((data) => {
        this.momFilter(data);
      });
  }
  shareWithGuide(surveyId) {
    const data = {
      object_id: surveyId,
      shared_with_member: this.menteeId,
      relationship_type: 2177,
      member: this.loggedinUserid,
      object_type: 2560,
    };
    this.shareWithSubscription = this.surveyService
      .sharedWithGuide(data)
      .subscribe((data) => {
        this.toastr.showSuccess(
          message_properties.ENDORSE_GUIDE_ENDORSE_DOCUMENT_SUCCESS1 +
            this.fullName +
            message_properties.ENDORSE_GUIDE_ENDORSE_DOCUMENT_SUCCESS2,
          ''
        );
      });
  }
  getAllDiys(participant) {
    this.allDiySubscription = this.surveyService
      .getAllDiy(participant)
      .subscribe((res) => {
        this.allDies = res.results;
        this.count = res.count;
        this._cdref.detectChanges();
      });
  }

  diyDetails(
    id,
    activity_date,
    stop_doing,
    start_doing,
    continue_doing,
    answer_data
  ) {
    this.dialog.open(DiyDetailsComponent, {
      disableClose: true,
      width: '80%',
      data: {
        data_value: activity_date,
        participantDiyID: id,
        stop_doing: stop_doing,
        start_doing: start_doing,
        continue_doing: continue_doing,
        question_answer_list: answer_data,
      },
    });
  }

  showContainer(param) {
    if (Number(param) === 1) {
      this.momData = [];
      this.showContainerSubscription = this.mentorService
        .getSharedDiscussionsDocs(
          this.menteeId,
          'Documents',
          this.mentorId,
          'mom',
          this.member_relationship_id
        )
        .subscribe((data) => {
          this.documentType = 1;
          this.momData = data['results'];
          this.count = this.momData.length;
          this._cdref.detectChanges();
        });
    } else if (Number(param) === 2) {
      this.momData = [];
      this.showContainerSubscription = this.mentorService
        .getSharedDiscussionsDocs(
          this.menteeId,
          'Documents',
          this.mentorId,
          'diy',
          this.member_relationship_id
        )
        .subscribe((data) => {
          this.momData = data['results'];
          this.documentType = 2;
          this.count = this.momData.length;
          this._cdref.detectChanges();
        });
    } else if (Number(param) === 3) {
      this.momData = [];
      this.showContainerSubscription = this.mentorService
        .getSharedDiscussionsDocs(
          this.menteeId,
          'Documents',
          this.mentorId,
          'reflection',
          this.member_relationship_id
        )
        .subscribe((data) => {
          this.momData = data['results'];
          this.documentType = 3;
          this.count = this.momData.length;
          this._cdref.detectChanges();
        });
    }
    this.containerView = param;
  }
  unshareDoc(sharedId) {
    this.unShareSubscription = this.mentorService
      .unshareDoc(sharedId)
      .subscribe((success) => {
        this.toastr.showSuccess(
          message_properties.ENDORSE_GUIDE_ENDORSE_DOCUMENT_SUCCESS3,
          ''
        );
        this.showContainer(this.containerView);
      });
  }
  openMirrorView(
    params,
    submission_date,
    total_group_response,
    group_answer,
    free_format_answer,
    mirror
  ) {
    let dialogRef = this.dialog.open(MirrorViewComponent, {
      width: '80%',
      disableClose: true,
      data: {
        survey_participant_id: params,
        submission_date: submission_date,
        total_group_response: total_group_response,
        group_answer: group_answer,
        free_format_answer: free_format_answer,
        mirror: mirror,
      },
    });
  }

  goToSelectedSurveyReport(selectedSurveyId, json_report) {
    let dialogRef = this.dialog.open(FinalReportComponent, {
      width: '70%',
      disableClose: true,
      data: {
        memberId: this.menteeId,
        currentSelectedSurvey: selectedSurveyId,
        json_report1: json_report,
      },
    });
  }
  ngOnDestroy(): void {
    if (this.surveySubscription) {
      this.surveySubscription.unsubscribe();
    }
    if (this.shareWithSubscription) {
      this.shareWithSubscription.unsubscribe();
    }
    if (this.allDiySubscription) {
      this.allDiySubscription.unsubscribe();
    }
    if (this.showContainerSubscription) {
      this.showContainerSubscription.unsubscribe();
    }
    if (this.unShareSubscription) {
      this.unShareSubscription.unsubscribe();
    }
  }
}
