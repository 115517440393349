import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  OnDestroy,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../../../service/notification.service';
import { MeaningNetworkService } from '../../../../service/meaning-network.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  NgForm,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { message_properties } from '../../../../../environments/message_properties';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-meaning-full-work-experience-form-profile',
  templateUrl: './meaning-full-work-experience-form-profile.component.html',
  styleUrls: ['./meaning-full-work-experience-form-profile.component.scss'],
})
export class MeaningFullWorkExperienceFormProfileComponent
  implements OnInit, OnDestroy {
  config = {
    placeholder: 'Enter a brief description',
    tabsize: 2,
    height: 150,
    toolbar: [],
    disableDragAndDrop: true,
    disableResizeEditor: true,
    linkTargetBlank: false,
    callbacks: {
      onPaste: function (e) {
        const bufferText = (e.originalEvent || e).clipboardData.getData('Text');
        e.preventDefault();
        document.execCommand('insertText', false, bufferText);
      },
    },
  };
  experienceForm: UntypedFormGroup;
  memberId: string;
  addOrEdit: string;
  memberid: any;
  emcount: number;
  employeeData: Array<any>;
  experienceId: number;
  saveButton: boolean = false;
  saveSubscription: Subscription;
  employeeSubscription: Subscription;
  async findMemberId() {
    await this.route.queryParams.subscribe((params) => {
      return (this.memberId = params['member']);
    });
  }
  constructor(
    public dialogRef: MatDialogRef<MeaningFullWorkExperienceFormProfileComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private toastr: NotificationService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private meaning_network_service: MeaningNetworkService,
    private accountService: AccountsService
  ) {
    this.addOrEdit = data.addOrEdit;
    this.experienceId = data.experienceId;
  }

  ngOnInit(): void {
    this.findMemberId();
    this.memberid = this.accountService.muser;
    this.experienceForm = new UntypedFormGroup({
      title: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(96),
      ]),
      company: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(96),
      ]),
      location: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(96),
      ]),
      volunteer_id: new UntypedFormControl(this.experienceId),
      workprofile: new UntypedFormControl(''),
      check_uncheck: new UntypedFormControl(''),
      check_value: new UntypedFormControl(1),
      member: new UntypedFormControl(this.memberid),
    });
    if (this.experienceId !== 0) {
      this.getEmployee();
    }
  }
  experienceSave(ngForm: NgForm) {
    this.spinner.show();
    this.saveButton = true;
    if (this.experienceId === 0) {
      this.saveSubscription = this.meaning_network_service
        .postExperienceData(ngForm)
        .subscribe(
          (data) => {
            if (data.status === 201) {
              this.emptyForm();
              this.saveButton = false;
              this.dialogRef.close();
              this.toastr.showSuccess(
                message_properties.MEANINGFULL_WORK_EXPERIENCE_SAVE_SUCCESS,
                ''
              );
              this.cdRef.detectChanges();
              this.spinner.hide();
            } else if (data.status === 204) {
              this.saveButton = false;
              this.toastr.showError(data.message, '');
              this.cdRef.detectChanges();
              this.spinner.hide();
            } else {
              this.saveButton = false;
              this.toastr.showError(data.message, '');
              this.cdRef.detectChanges();
              this.spinner.hide();
            }
          },
          (error) => {
            this.saveButton = false;
            this.toastr.showError(
              message_properties.MEANINGFULL_WORK_EXPERIENCE_SAVE_ERROR,
              ''
            );
            this.cdRef.detectChanges();
            this.spinner.hide();
          }
        );
    } else {
      this.saveSubscription = this.meaning_network_service
        .updateExperienceData(ngForm)
        .subscribe((data) => {
          if (data.status === 201) {
            this.emptyForm();
            this.saveButton = false;
            this.dialogRef.close();
            this.toastr.showSuccess(
              message_properties.MEANINGFULL_WORK_EXPERIENCE_SAVE_SUCCESS,
              ''
            );
            this.cdRef.detectChanges();
            this.spinner.hide();
          } else if (data.status === 204) {
            this.saveButton = false;
            this.toastr.showError(data.message, '');
            this.cdRef.detectChanges();
            this.spinner.hide();
          } else if (data.status === 200) {
            this.saveButton = false;
            this.toastr.showError(
              message_properties.MEANINGFULL_WORK_EXPERIENCE_SAVE_ERROR,
              ''
            );
            this.cdRef.detectChanges();
            this.spinner.hide();
          }
        });
    }
  }

  getEmployee() {
    const formValue = this.experienceForm.value;
    this.spinner.show();
    this.employeeSubscription = this.meaning_network_service
      .getMemberCompanyDetail(formValue)
      .subscribe((data) => {
        this.experienceForm.patchValue({
          title: data.title,
          company: data.name,
          location: data.location,
          workprofile: data.workprofile,
          check_uncheck: data.association_status === 1991 ? true : false,
          check_value: 1,
          member: this.memberid,
        });
        this.cdRef.detectChanges();
        this.spinner.hide();
      });
  }
  emptyForm() {
    this.experienceForm.patchValue({
      check_uncheck: '',
      company: '',
      location: '',
      title: '',
      workprofile: '',
    });
  }
  ngOnDestroy(): void {
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    if (this.employeeSubscription) {
      this.employeeSubscription.unsubscribe();
    }
  }
}
