import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-start-making-connections',
  templateUrl: './start-making-connections.component.html',
  styleUrls: ['./start-making-connections.component.scss'],
})
export class StartMakingConnectionsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
