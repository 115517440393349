import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MeaningConstellationService {
  apiUrl = environment.apiUrl;
  private _refreshneededComments = new Subject<void>();
  private _refreshneededTreasureList = new Subject<void>();
  private _refreshneededMeaningCentralList = new Subject<void>();
  private _refreshneededMeaningLogList = new Subject<void>();
  private _refreshneededMeaningMomentsList = new Subject<void>();
  private _refreshneededCheer = new Subject<void>();
  private _refreshFlaggedContentAndComments = new Subject<void>();
  private _refreshneededFlagePostList = new Subject<void>();
  private _refreshRating = new Subject<void>();
  private _refreshneededBookmarkList = new Subject<[]>();

  get refreshneededBookmarkList() {
    return this._refreshneededBookmarkList;
  }
  get refreshneededFlagePostList() {
    return this._refreshneededFlagePostList;
  }
  get refreshneededMeaningMomentsList() {
    return this._refreshneededMeaningMomentsList;
  }
  get refreshRating() {
    return this._refreshRating;
  }
  get refreshneededComments() {
    return this._refreshneededComments;
  }
  get refreshneededTreasureList() {
    return this._refreshneededTreasureList;
  }
  get refreshneededMeaningCentralList() {
    return this._refreshneededMeaningCentralList;
  }
  get refreshneededMeaningLogList() {
    return this._refreshneededMeaningLogList;
  }
  get refreshneededCheer() {
    return this._refreshneededCheer;
  }

  get refreshFlaggedContentAndComments() {
    return this._refreshFlaggedContentAndComments;
  }

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  createComments(ngForm): Observable<CommentsData> {
    return this.http
      .post<CommentsData>(this.apiUrl + 'collabcontent/comment/create', ngForm)
      .pipe(
        tap(() => {
          this._refreshneededComments.next();
        })
      );
  }

  createAction(ngForm): Observable<CollabContentAction> {
    return this.http
      .post<CollabContentAction>(
        this.apiUrl +
          'collabcontent/collabcontentaction/collab-content-action-create',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededCheer.next(ngForm);
        })
      );
  }
  createShare(ngForm): Observable<CollabContentBucket> {
    return this.http.post<CollabContentBucket>(
      this.apiUrl +
        'collabcontent/collabcontentbucket/post-share-bucket-create',
      ngForm
    );
  }
  putInBucket(ngForm): Observable<CollabContentBucket> {
    return this.http.post<CollabContentBucket>(
      this.apiUrl +
        'collabcontent/collabcontentbucket/collab-content-bucket-create',
      ngForm
    );
  }
  giveRating(ngForm): Observable<CollabContentRatting> {
    return this.http
      .post<CollabContentRatting>(
        this.apiUrl + 'collabcontent/rating-details/rating',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshRating.next();
        })
      );
  }
  getCollabContentDetails(
    collab_content_id,
    login_member
  ): Observable<CollabContentData> {
    return this.http.get<CollabContentData>(
      this.apiUrl +
        'collabcontent/details/' +
        collab_content_id +
        '?loggedInMember=' +
        login_member
    );
  }
  deleteCollabContentDetailsWithRelative(
    collab_content_id
  ): Observable<CollabContentData> {
    return this.http
      .delete<CollabContentData>(
        this.apiUrl +
          'collabcontent/collab-content-delete-with-all-relative/' +
          collab_content_id
      )
      .pipe(
        tap(() => {
          this._refreshneededMeaningLogList.next();
        })
      );
  }

  createMeaningMoments(ngForm): Observable<CollabContentData> {
    return this.http
      .post<CollabContentData>(
        this.apiUrl + `collabcontent/collabcontentbucket/create-meaning-feed`,
        ngForm
      )
      .pipe(
        tap((data) => {
          this._refreshneededMeaningMomentsList.next();
        })
      );
  }

  deleteMeaningMomentsDetailsWithRelative(
    collab_content_id
  ): Observable<CollabContentData> {
    return this.http
      .delete<CollabContentData>(
        this.apiUrl +
          'collabcontent/collab-content-delete-with-all-relative/' +
          collab_content_id
      )
      .pipe(
        tap((data) => {
          this._refreshneededMeaningMomentsList.next();
        })
      );
  }
  removeCollabContentFlag(collab_content_id): Observable<CollabContentData> {
    const data = { id: collab_content_id };
    return this.http
      .put<CollabContentData>(
        this.apiUrl + 'collabcontent/remove-collab-content-flag',
        data
      )
      .pipe(
        tap(() => {
          this._refreshneededFlagePostList.next();
        })
      );
  }

  deleteComments(commentId): Observable<CommentsData> {
    return this.http
      .delete<CommentsData>(
        this.apiUrl + 'collabcontent/comment/delete/' + commentId
      )
      .pipe(
        tap(() => {
          this._refreshneededComments.next();
        })
      );
  }
  deleteMyTreasure(collab_content_id): Observable<CollabContentData> {
    return this.http
      .delete<CollabContentData>(
        this.apiUrl +
          'collabcontent/collab-content-bucket-delete/' +
          collab_content_id
      )
      .pipe(
        tap(() => {
          this._refreshneededTreasureList.next();
        })
      );
  }
  removeFromBucketTableOnly(
    collab_content_id,
    bucket_type,
    loggedInUserId
  ): Observable<CollabContentData> {
    let data = {
      collab_content_id: collab_content_id,
      loggedInUserId: loggedInUserId,
      bucket_type: bucket_type,
    };
    return this.http
      .post<CollabContentData>(
        this.apiUrl +
          'collabcontent/collabcontentbucket/content-delete-from-bucket',
        data
      )
      .pipe(
        tap(() => {
          this._refreshneededTreasureList.next();
          this._refreshneededMeaningMomentsList.next();
        })
      );
  }
  removeFromBookmark(
    collab_content_id,
    bucket_type,
    loggedInUserId
  ): Observable<CollabContentData> {
    let data = {
      collab_content_id: collab_content_id,
      loggedInUserId: loggedInUserId,
      bucket_type: bucket_type,
    };
    return this.http
      .post<CollabContentData>(
        this.apiUrl +
          'collabcontent/collabcontentbucket/content-delete-from-bucket',
        data
      )
      .pipe(
        tap(() => {
          this._refreshneededBookmarkList.next();
          // this._refreshneededMeaningMomentsList.next();
        })
      );
  }
  removeFromTreasure(
    collab_content_id,
    bucket_type,
    loggedInUserId
  ): Observable<CollabContentData> {
    let data = {
      collab_content_id: collab_content_id,
      loggedInUserId: loggedInUserId,
      bucket_type: bucket_type,
    };
    return this.http
      .post<CollabContentData>(
        this.apiUrl +
          'collabcontent/collabcontentbucket/content-delete-from-bucket',
        data
      )
      .pipe(
        tap(() => {
          this._refreshneededTreasureList.next();
          // this._refreshneededMeaningMomentsList.next();
        })
      );
  }
  editCollabContent(collab_content_id, ngForm): Observable<CollabContentData> {
    return this.http
      .put<CollabContentData>(
        this.apiUrl + 'collabcontent/update/' + collab_content_id,
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededMeaningCentralList.next();
        })
      );
  }
  editSharedPostContent(
    collab_content_id,
    ngForm
  ): Observable<CollabContentData> {
    return this.http
      .put<CollabContentData>(
        this.apiUrl + 'collabcontent/update-shared-post/' + collab_content_id,
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededMeaningCentralList.next();
        })
      );
  }
  deleteFlagstatus(
    flag_id,
    member,
    commment_id,
    collabContentId
  ): Observable<CollabContentAction> {
    let data = {
      flag_id: flag_id,
      member: member,
      comment_id: commment_id,
      collab_content_id: collabContentId,
    };
    return this.http
      .post<CollabContentAction>(
        this.apiUrl +
          'collabcontent/collabcontentaction/collab-content-action-delete',
        data
      )
      .pipe(
        tap(() => {
          this._refreshFlaggedContentAndComments.next(member);
        })
      );
  }

  keepCollabContentFlag(collab_content_id): Observable<CollabContentData> {
    const data = { id: collab_content_id };
    return this.http
      .put<CollabContentData>(
        this.apiUrl + 'collabcontent/keep-collab-content-flagged',
        data
      )
      .pipe(
        tap(() => {
          this._refreshneededFlagePostList.next();
        })
      );
  }
}
export interface CollabContentData {
  collab_content_category: number;
  collab_content_type: number;
  collab_content_status: number;
  additional_info: string;
  creation_date: any;
  title: string;
  active: number;
  description: string;
  attachment_path: string;
  creating_user: string;
  suggesting_user: string;
  message: string;
  status: number;
  results: [];
  count: number;
  flaggedCount: number;
  creatingUser: {};
}
export interface CollabContentRatting {
  rating: number;
  viewrating: number;
  remarks: string;
  collab_content_id: number;
  tenant_id: number;
  member_id: number;
  results: [];
  count: number;
  status: number;
}

export interface CommentsData {
  rating: string;
  remarks: string;
  collab_content_id: number;
  tenant_id: number;
  user_id: number;
  results: [];
  count: number;
  status: number;
  message: string;
}
export interface CollabContentAction {
  action_type: number;
  action_for: number;
  remarks: string;
  collab_content: number;
  tenant: number;
  member: number;
  results: [];
  count: number;
  status: number;
  selected_option: number;
}
export interface CollabContentBucket {
  bucket_type: number;
  visibility: number;
  collab_content: number;
  tenant: number;
  member: number;
  results: [];
  count: number;
  status: number;
  message: string;
}
