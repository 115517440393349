<div class="row">
  <div class="col-sm-12 col-lg-8 col-md-12 absolutueCenter">
    <mat-card class="archive_main_card welcome_page">
      <button class="close-dialog" routerLink="/home">X</button>
      <h1>Welcome To MeaningSphere</h1>
      <hr />
      <mat-card-content class="card2-style-height-with-hr-heading">
        <h1 class="fs-20">About</h1>
        <div>
          <p>
            Humans are a unique species. We need to know that our presence
            matters &ndash; that our lives have meaning.
          </p>
          <p>
            Interest in the pursuit of meaning has recently exploded.
            Corporations are focusing on wellness, spirituality, and mental
            health. At the same time, many people struggle to find satisfaction
            in their jobs. But at MeaningSphere, we believe that when work
            &ndash; any work &ndash; is infused with meaning, it can transform
            lives.
          </p>
          <p>
            MeaningSphere is a place where you can focus your energy to realize
            meaning in your work. Our uniquely crafted experiences can help
            shift perspectives, make changes, and realize goals.
          </p>
          <h2>What is MeaningSphere</h2>
          <p>
            For many people, receiving joy and meaning at work can at first seem
            like an impossible effort. So, we&rsquo;ve created an online
            platform to introduce you to highly trained guides, practical
            self-discovery tools, and communities of like-minded people who want
            to learn and grow together in a safe, secure, and respectful
            environment. Everything is uniquely designed to help you unlock what
            matters most to you and inspire your meaning journey.
          </p>
          <h2>Who is MeaningSphere</h2>
          <p>
            MeaningSphere is a community of individuals and organizations from
            around the world who bring a wealth of experience and expertise in a
            variety of fields but share one thing (maybe more&hellip;) in
            common. Like you, they are explorers on a journey to realize meaning
            in their work.
          </p>
          <p>
            We strive to adhere to the highest standards of mutual respect,
            founded on authenticity, trust, and transparency between ourselves
            and with you.
          </p>
          <h2>Our Vision</h2>
          <p>
            We see a world in which people co-create meaningful work that
            contributes to the entire human experience &ndash; work that enables
            personal growth and engagement and shifts the way organizations
            operate.
          </p>
          <h2>The Sphere</h2>
          <p>
            The Sphere is a depiction of our vision. It represents the
            connections between work, people, and purpose. Following these
            connecting paths can help people visualize where they can receive
            meaning in their work by shifting their perspective, or it may help
            people realize that there are other, more meaningful avenues they
            can take if they make some changes.
          </p>
          <h2>Our Mission</h2>
          <p>
            To inspire, support, and connect people in their quest to discover
            and experience greater meaning in their work and lives.
          </p>
          <h2>Our Values</h2>
          <p>
            We strive to deliver an organization that delivers on our vision
            while exemplifying our values &ndash; to build a place of respect,
            love, compassion, and innovation. We hope that our successes and
            mistakes will serve as examples to others.
          </p>
          <ul>
            <li>
              <strong>Unconditional Acceptance:</strong> We care for each other
              and embrace creative tension.
            </li>
            <li>
              <strong>Dynamic Openness:</strong> We listen. We learn. We grow.
            </li>
            <li>
              <strong>Inclusiveness:</strong> We invite everyone to share in the
              journey.
            </li>
            <li>
              <strong>Courageous Integrity:</strong> We speak our truth and walk
              our talk.
            </li>
            <li>
              <strong>Joyful Service:</strong> We are servant leaders who find
              fulfilment and inspiration in serving our purpose.
            </li>
          </ul>
          <h2>Protecting Your Privacy</h2>
          <p>
            Pursuing a personal journey to find meaning in your work, but its
            very nature, requires a level of openness and vulnerability. So we
            take protecting your personal information VERY SERIOUSLY. With some
            online platforms, your data is the product. At MeaningSphere your
            data is yours.
          </p>
          <h2>Our promise to you</h2>
          <p>
            The information and data you share about yourself on the
            MeaningSphere platform belongs to you and will never be sold to any
            other individual or organization:
          </p>
          <ul>
            <li>
              You can delete your personal data on the MeaningSphere platform at
              any time &ndash; we won&apos;t keep a trace of it.
            </li>
            <li>
              The only organizations besides MeaningSphere that have access to
              your personal data are the ones who help us maintain our
              platforms, tools, and services. They are listed on our website,
              and you can consult the list at any time here.
            </li>
            <li>
              If you are supported by a MeaningSphere Guide to whom you grant
              access to some of your personal data, please know your Guide has
              signed a legal agreement committing to protect your data, and has
              been trained to adhere to a code of conduct which includes data
              privacy and security.
            </li>
          </ul>
          <p>
            The only use that MeaningSphere makes of personal data shared by you
            and other members on the platform is to improve its products and
            services and to find new ways to support you in experiencing greater
            meaning in your work.
          </p>
          <ul>
            <li>
              We may be inspired by what individual members share about
              themselves and their experiences in public sections of the
              platform, but we won&apos;t delve into your private reflections,
              insights, or plans that you keep in your private space on the
              platform.
            </li>
            <li>
              The responses you share in the Map of Meaning Survey may be
              anonymized and aggregated for further research on how people find
              meaning in their work but will only be shared with trusted
              partners committed to this purpose. You can consult the list here.
            </li>
          </ul>
          <h2>A New Model for Business</h2>
          <p>
            As an organic community, we exist to help Explorers discover greater
            meaning and purpose in their work and life. We believe in being
            transparent with Explorers about:
          </p>
          <ul>
            <li>Our purpose and who we are.</li>
            <li>How we make money and what we do with it.</li>
            <li>What we do with your data.</li>
            <li>
              Our commitment to make the MeaningSphere a safe place for its
              Explorers to share authentically and without fears.
            </li>
          </ul>
          <p>
            MeaningSphere is a for-purpose organization. We are not all about
            making money. Our goal is to be sustainable so that we can continue
            to offer these experiences and contribute to the global desire for
            meaningful work.
          </p>
          <p>
            To that end, there are certain experiences<br />that we will need to
            charge a fee for in order to cover the costs of human resources or
            contractual obligations, such as Guide Services, the Meaningful Work
            Survey, and Circles. At a not-to-distant point in the future, we
            hope to offer a premium subscription for more advanced offerings.
            However, MeaningSphere believes strongly that the core offerings
            critical to helping Explorers on their meaning journeys will remain
            free and accessible to all.
          </p>
          <p>
            We will never sell, misuse, or exploit your data for profit making .
          </p>
        </div>
        <br />
      </mat-card-content>
    </mat-card>
  </div>
</div>
