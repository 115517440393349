<div class="">
  <div class="scroll_class_list" #target>
    <ng-container *ngIf="varArr['selected_member_id'] == 0">
      <ng-container *ngIf="system_message_count > 0">
        <ng-container *ngFor="let system_message of system_message_data; let i = index">
          <table width="100%" border="0">
            <tr>
              <td width="10%">&nbsp;</td>
              <td width="90%" class="message-card">
                <div class="row float-right">
                  <div class="col-sm-12">
                    <span class="dateTop">
                      <h1 class="fs-12 fs-12-right">
                        {{ dateInUserTimezome(system_message.creation_date) | date: "MMM d, y h:mm a" }}
                      </h1>
                    </span>
                  </div>
                </div>
                <div class="row messagesAll">
                  <div class="msgContent opposite">
                    <p>
                      {{ system_message.message }}
                    </p>
                  </div>
                </div>
                <div class="row"></div>
              </td>
            </tr>
          </table>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="system_message_count == 0">
        <p>You do not have any messages!</p>
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let date_details of allMessagesDetails; let i = index">
      <ng-container *ngFor="let message of date_details.final_data; let j = index">
        <table width="100%" border="0" *ngIf="
            message.creating_user_id == creatingUser &&
            message.chat_for !== 2630
          ">
          <tr>
            <td width="10%">&nbsp;</td>
            <td width="90%" class="message-card">
              <div class="row float-right">
                <div class="col-sm-12">
                  <span class="dateTop">
                    <h1 class="fs-12 fs-12-right">
                      {{ dateInUserTimezome(message.creation_date) | date: "MMM d, y h:mm a" }}
                    </h1>
                  </span>
                </div>
              </div>
              <div class="row messagesAll">
                <div class="col-sm-12 msgContent opposite">
                  <p>
                    {{ message.message }}
                  </p>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="row">
                <div class="col-sm-12">
                  <span *ngIf="message.related_object_id" (click)="
                      collabContentDetails(
                        message.related_object_id,
                        message?.content_data[0]['title']
                          ? message?.content_data[0]['title']
                          : 'This content is no longer available',
                        message?.content_data[0]['description']
                          ? message?.content_data[0]['description']
                          : '',
                        message?.content_data[0]['video_indicator']
                          ? message?.content_data[0]['video_indicator']
                          : '',
                        message?.content_data[0]['attachment_path'],
                        message?.content_data[0]['additional_info']
                          ? message?.content_data[0]['additional_info']
                          : '',
                        message?.link_preview ? message?.link_preview : ''
                      )
                    ">
                    <img class="img_radius_none post-view-from-message" src="./assets/media/view.png" title="Details" />
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <table class="message-card-left" width="100%" border="0" *ngIf="
            message.creating_user_id !== creatingUser &&
            message.chat_for !== 2630
          ">
          <tr>
            <td width="90%">
              <div class="row notificnHeadr">
                <div class="col-sm-12">
                  <div class="imagearea">
                    <img src="{{
                        message.picture_path
                          ? message.picture_path
                          : './assets/media/users/default.png'
                      }}" class="" />
                    <span class="name first-letter">
                      {{ message.full_name }}
                      <br/><span class="feedTime m-0 fs-14" *ngIf="checkStatusMember(message.member_status)">(This Explorer is no longer active)</span>
                      <h1 class="fs-12 fs-12-left">
                        {{ dateInUserTimezome(message.creation_date) | date: "MMM d, y h:mm a" }}
                      </h1>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row messagesAll">
                <div class="msgContent opposite">
                  <p>
                    {{ message.message }}
                  </p>
                  <!-- <span class="time">{{message.creation_date|date:"h:mm:a"}}</span> -->
                </div>
              </div>
            </td>
            <td width="10%">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="row">
                <div class="col-sm-12">
                  <span *ngIf="message.related_object_id" (click)="
                      collabContentDetails(
                        message.related_object_id,
                        message?.content_data[0]['title']
                          ? message?.content_data[0]['title']
                          : 'This content is no longer available',
                        message?.content_data[0]['description']
                          ? message?.content_data[0]['description']
                          : '',
                        message?.content_data[0]['video_indicator']
                          ? message?.content_data[0]['video_indicator']
                          : '',
                        message?.content_data[0]['attachment_path']
                          ? message?.content_data[0]['attachment_path']
                          : './assets/media/default/content_not_found.png',
                        message?.content_data[0]['additional_info']
                          ? message?.content_data[0]['additional_info']
                          : '',
                        message?.link_preview ? message?.link_preview : ''
                      )
                    ">
                    <img class="img_radius_none post-view-from-message" src="./assets/media/view.png" title="Details" />
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </ng-container>
    </ng-container>
  </div>

  <div class="row msgSection" *ngIf="varArr['selected_member_id'] != 0 && member_status != 0">
    <form class="messageReplyForm" [formGroup]="messageReplyForm" (ngSubmit)="createResponse(messageReplyForm.value)"
      novalidate enctype="multipart/form-data" autocomplete="off">
      <div class="wac" [ngClass]="{ postTextAreaVisible: writeACommentForm }">
        <textarea tabindex="1" matInput rows="1" formControlName="message" style="width: 100% !important; color: white"
          placeholder="Add your message/comment"
          oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.\-_+()@!?#$% ]|^ /g,'')"></textarea>
        <button [disabled]="!messageReplyForm.valid || saveButton" class="btn begin_quiz ml-2" tabindex="2"
          type="submit">
          Send
        </button>
      </div>
    </form>
  </div>
</div>