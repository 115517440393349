<div class="col-sm-12 mt-2">
	<!-- {{report_1_body}} -->
	<!-- <div [innerHTML]="report_1_body"></div> -->
	<h1 class="section-title mb-15">About Your Report</h1>
	<div class="row">
		<div class="col-lg-7 col-md-6 col-sm-12 section-text">
			<p class="section-text">
				In this Survey Report, you’ll see your results as they
				relate to the seven elements you were shown in the video
				introducing you to the Map of Meaning. These elements –
				four pathways and three tensions – provide a snapshot of
				how and where you currently find meaning at work; this is not a personality test
				– it doesn't “type” you.</p>
			<p class="section-text">
				Your Survey Report will help you answers questions, like do I:</p>
			<ul class="section-text">
				<li>Find meaning in my current work situation? Where am I gaining and losing energy?
				</li>
				<li>Have a healthy tension between focusing on “self” versus “others” and “being” versus “doing”?
				</li>
				<li>Balance what inspires me and the constraints of my current reality?</li>
			</ul>
			<p class="section-text">Once you have a basic understanding of your results, you can begin turning your
				insights into action and identify the next steps that are right for you.</p>
		</div>


		<div class="col-lg-5 col-md-6 col-sm-12 section-text mt6n">
			<img src="./assets/media/images/first_page_pdf.png" class="img-resposonsive img-fluid" /> <br>
			<p class="text-center mt-10">© Dr Marjolein Lips-Wiersma</p>
		</div>

	</div>