import { EachVedioComponent } from './../each-vedio/each-vedio.component';
import { FinalReportComponent } from './../final-report/final-report.component';
import { Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';

@Component({
  selector: 'kt-final-report-first-page',
  templateUrl: './final-report-first-page.component.html',
  styleUrls: ['./final-report-first-page.component.scss'],
})
export class FinalReportFirstPageComponent implements OnInit {
  surveyParticipantId: any;
  _data = {};
  memberId: number;
  fromDia: string;
  json_report: string;
  currentSelectedSurvey: number;
  videoUrl = 'https://player.vimeo.com/video/759651221?h=643dc2318f';
  constructor(
    public dialogRef: MatDialogRef<FinalReportFirstPageComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.currentSelectedSurvey = data.currentSelectedSurvey;
    this.memberId = data.memberId;
    this.json_report = data.json_report1;
  }

  ngOnInit(): void {}

  goToSelectedSurveyReport() {
    this.closePopUpWindows();
    let dialogRef = this.dialog.open(FinalReportComponent, {
      width: '90%',
      disableClose: true,
      data: {
        memberId: this.memberId,
        currentSelectedSurvey: this.currentSelectedSurvey,
        json_report1: this.json_report,
      },
    });
  }
  closePopUpWindows() {
    this.dialogRef.close();
  }

  onCreate() {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: { source: this.videoUrl },
    });
  }
  backToList() {
    this.router.navigate(['/map-of-meaning/submitted-survet-list']);
  }
}
