import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fromEvent, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { MeaningNetworkService } from './../../../../../service/meaning-network.service';
import { CityStateCountryService } from './../../../../../service/city-state-country.service';
import { NotificationService } from './../../../../../service/notification.service';
import { message_properties } from './../../../../../../environments/message_properties';

@Component({
  selector: 'kt-update-address',
  templateUrl: './update-address.component.html',
  styleUrls: ['./update-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateAddressComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('countrySearchInput', { static: false })
  countrySearchInput: ElementRef;
  @ViewChild('citySearchInput', { static: false }) citySearchInput: ElementRef;
  memberId: any;
  addressForm: UntypedFormGroup;

  countryList = [];
  countryCount: number;
  cityList = [];
  cityCount: number;
  cityBox: boolean = false;
  countryBox1: boolean = false;
  subbscriptionCity: Subscription;
  countrySubscription: Subscription;
  saveSubscription: Subscription;
  secondary_city: string;
  secondary_state: string;
  secondary_country: string;
  secondary_line1: string;
  secondary_line2: string;
  secondary_postalcode: string;
  secondary_countryId: string;
  secondary_city_id: string;

  searchForm: UntypedFormGroup;
  page: number = 1;
  throttle = 300;
  modalScrollDistance = 1;
  modalScrollThrottle = 30;

  constructor(
    public dialogRef: MatDialogRef<UpdateAddressComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private cityStateCountry: CityStateCountryService,
    private toastr: NotificationService,
    private cdRef: ChangeDetectorRef,
    private meaning_network_service: MeaningNetworkService,
    private spinner: NgxSpinnerService
  ) {
    this.memberId = data.memberId;
    this.secondary_city = data.secondary_city;
    this.secondary_state = data.secondary_state;
    this.secondary_country = data.secondary_country;
    this.secondary_line1 = data.secondary_line1;
    this.secondary_line2 = data.secondary_line2;
    this.secondary_postalcode = data.secondary_postalcode;
    this.secondary_countryId = data.secondary_country_id;
    this.secondary_city_id = data.secondary_city_id;
    localStorage.setItem('countryIdForEdit', this.secondary_countryId);
    this.searchForm = this.formBuilder.group({
      name: new UntypedFormControl(''),
      countryId: new UntypedFormControl(''),
      page: 1,
    });
  }

  ngOnInit(): void {
    this.addressForm = this.formBuilder.group({
      country: new UntypedFormControl('', [Validators.required]),
      city: new UntypedFormControl('', [Validators.required]),
      countryId: new UntypedFormControl(this.secondary_countryId),
      cityId: new UntypedFormControl(localStorage.getItem('cityId')),
      line1: new UntypedFormControl('', [Validators.required]),
      line2: new UntypedFormControl(''),
      state: new UntypedFormControl(''),
      postalcode: new UntypedFormControl('', [Validators.required]),
      check_value: new UntypedFormControl(10),
      member: new UntypedFormControl(this.memberId),
    });
    this.addressForm.patchValue({
      city: this.secondary_city,
      state: this.secondary_state,
      country: this.secondary_country,
      line1: this.secondary_line1,
      line2: this.secondary_line2,
      postalcode: this.secondary_postalcode,
      member: this.memberId,
    });
  }
  ngAfterViewInit(): void {
    this.citylistSearch();
  }

  saveAddress(ngForm: NgForm) {
    this.spinner.show();
    let data_ = {
      check_value: 10,
      city: ngForm['city'],
      country: ngForm['country'],
      cityId: ngForm['cityId'],
      countryId: ngForm['countryId'],
      state: ngForm['state'],
      line1: ngForm['line1'],
      line2: ngForm['line2'],
      postalcode: ngForm['postalcode'],
      member: this.memberId,
    };
    this.saveSubscription = this.meaning_network_service
      .postSecondaryAddressData(data_)
      .subscribe(
        (data) => {
          if (data.status === 204) {
            this.toastr.showError(data.message, '');
            this.cdRef.detectChanges();
            this.spinner.hide();
          } else {
            this.toastr.showSuccess(
              message_properties.MEANING_CENTRAL_PROFILE_WITH_ADDRESS_DATA_SUCCESS,
              ''
            );
            localStorage.setItem('cityId', data.address_response['cityId']);
            localStorage.setItem(
              'countryId',
              data.address_response['countryId']
            );
            localStorage.setItem(
              'countryIdForEdit',
              data.address_response['countryId']
            );
            localStorage.setItem(
              'current_time_zone',
              data.address_response['timezone_str']
            );
            this.cdRef.detectChanges();
            this.spinner.hide();
            this.dialogRef.close();
          }
        },
        (error) => {
          this.toastr.showError(
            message_properties.MEANING_CENTRAL_PROFILE_WITH_ADDRESS_DATA_ERROR,
            ''
          );
          this.cdRef.detectChanges();
          this.spinner.hide();
        }
      );
  }

  countrylistSearch() {
    fromEvent(this.citySearchInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => {
          return event.target.value;
        }),
        filter((res) => res.length > 2),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((sendData: string) => {
        this.countrySubscription = this.cityStateCountry
          .getCountry(sendData)
          .subscribe((response) => {
            this.countryList = response.results;
            this.countryCount = response.count;
            if (this.countryCount > 0) {
              this.countryBox1 = true;
            } else {
              this.countryBox1 = false;
            }
            this.addressForm.patchValue({
              city: '',
            });
            this.cdRef.detectChanges();
          });
      });
  }

  setCountry(ev) {
    this.countryBox1 = false;
    this.cityBox = false;
    localStorage.setItem('countryId', ev[0].value);
    this.addressForm.patchValue({
      country: ev[0].text,
      countryId: ev[0].value,
    });

    if (this.secondary_countryId !== ev[0].value) {
      this.secondary_countryId = ev[0].value;
      this.addressForm.patchValue({
        state: '',
        city: '',
      });
    }
  }
  citylistSearch() {
    fromEvent(this.citySearchInput.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // if character length greater then 2
        filter((res) => res.length > 2),
        // Time in milliseconds between key events
        debounceTime(500),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((sendData: string) => {
        this.searchForm.patchValue({
          name: sendData,
          countryId: localStorage.getItem('countryId'),
        });
        this.callSearchCityFunction(1);
      });
  }
  setCity(name, stateId, id) {
    this.cityBox = false;
    this.addressForm.patchValue({
      city: name,
      state: stateId,
      cityId: id,
    });
  }

  pushValue(ev?) {
    if (this.cityCount !== this.cityList.length) {
      this.page = this.page + 1;
      this.searchForm.patchValue({
        page: this.page,
      });
      this.callSearchCityFunction();
    }
  }

  callSearchCityFunction(params = 0) {
    if (params === 1) {
      this.page = 1;
      this.searchForm.patchValue({
        page: 1,
      });
      this.cityList = [];
      this.cityBox = false;
    }
    this.subbscriptionCity = this.cityStateCountry
      .getCity(this.searchForm.value)
      .subscribe((response) => {
        this.cityList = [
          ...new Map(
            this.cityList
              .concat(response.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.cityCount = response.count;
        if (this.cityCount > 0) {
          this.cityBox = true;
        } else {
          this.cityBox = false;
          this.addressForm.patchValue({
            state: '',
            countryId: this.secondary_countryId,
            cityId: '',
          });
        }
        this.countryBox1 = false;
        this.cdRef.detectChanges();
      });
  }
  ngOnDestroy(): void {
    if (this.subbscriptionCity) {
      this.subbscriptionCity.unsubscribe();
    }
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    if (this.countrySubscription) {
      this.countrySubscription.unsubscribe();
    }
  }
}
