<mat-card id="donotpopup">
  <span class="close-dialog" (click)="close()">X</span>
  <h1 mat-dialog-title>{{ name }}</h1>
  <br />
  <mat-dialog-content>
    <p class="text-justify" [innerHTML]="sanitizer.bypassSecurityTrustHtml(parameter_value)"></p>
    <!---- This is for to show the Map of Meaning image and text-->
    <img src="../../../../../assets/images/mapofme.png" alt="mapofmeaning" *ngIf="param == 102" />
  </mat-dialog-content>
  <mat-dialog-actions>
    <table width="100%">
      <tr>
        <td width="50%" align="left">
          <ng-container *ngIf="param == 100">
            <input id="do_not_show" [checked]="check_uncheck" (change)="donotSave($event, param)" [value]="1"
              type="checkbox" />&nbsp;&nbsp;
            <label for="do_not_show">Do not show this again</label>
          </ng-container>
          <ng-container *ngIf="param == 101">
            <input id="do_not_show" [checked]="check_uncheck" (change)="donotSave($event, param)" [value]="1"
              type="checkbox" />&nbsp;&nbsp;
            <label for="do_not_show">Do not show this again</label>
          </ng-container>
          <ng-container *ngIf="param == 102">
            <input id="do_not_show" [checked]="check_uncheck" (change)="donotSave($event, param)" [value]="1"
              type="checkbox" />&nbsp;&nbsp;
            <label for="do_not_show">Do not show this again</label>
          </ng-container>
          <ng-container *ngIf="param == 103">
            <input id="do_not_show" [checked]="check_uncheck" (change)="donotSave($event, param)" [value]="1"
              type="checkbox" />&nbsp;&nbsp;
            <label for="do_not_show">Do not show this again</label>
          </ng-container>
          <ng-container *ngIf="param == 104">
            <input id="do_not_show" [checked]="check_uncheck" (change)="donotSave($event, param)" [value]="1"
              type="checkbox" />&nbsp;&nbsp;
            <label for="do_not_show">Do not show this again</label>
          </ng-container>
          <ng-container *ngIf="param == 105">
            <input id="do_not_show" [checked]="check_uncheck" (change)="donotSave($event, param)" [value]="1"
              type="checkbox" />&nbsp;&nbsp;
            <label for="do_not_show">Do not show this again</label>
          </ng-container>
          <ng-container *ngIf="param == 122">
            <input id="do_not_show" [checked]="check_uncheck" (change)="donotSave($event, param)" [value]="1"
              type="checkbox" />&nbsp;&nbsp;
            <label for="do_not_show">Do not show this again</label>
          </ng-container>
          <ng-container *ngIf="param == 124">
            <input id="do_not_show" [checked]="check_uncheck" (change)="donotSave($event, param)" [value]="1"
              type="checkbox" />&nbsp;&nbsp;
            <label for="do_not_show">Do not show this again</label>
          </ng-container>
          <ng-container *ngIf="param == 133">
            <input id="do_not_show" [checked]="check_uncheck" (change)="donotSave($event, param)" [value]="1"
              type="checkbox" />&nbsp;&nbsp;
            <label for="do_not_show">Do not show this again</label>
          </ng-container>
          <ng-container *ngIf="param == 134">
            <input id="do_not_show" [checked]="check_uncheck" (change)="donotSave($event, param)" [value]="1"
              type="checkbox" />&nbsp;&nbsp;
            <label for="do_not_show">Do not show this again</label>
          </ng-container>
        </td>

        <td width="50%" align="right">
          <button *ngIf="param == 100" (click)="redirectTo(param)" type="button" class="btn begin_quiz">
            FIND A GUIDE
          </button>
          <!-- <button *ngIf="param == 102" (click)="redirectTo(param)" type="button" class="btn begin_quiz">
            Buy Survey Experience
          </button> -->
          <button *ngIf="param == 102" (click)="redirectTo(param)" type="button" class="btn begin_quiz">
            GO to My Surveys
          </button>
          <button *ngIf="param == 101" (click)="redirectTo(param)" type="button" class="btn begin_quiz">
            ADD A REFLECTION
          </button>
          <button *ngIf="param == 122" (click)="redirectTo(param)" type="button" class="btn begin_quiz">
            Explore the Library
          </button>
          <button *ngIf="param == 104" (click)="addPost(param)" type="button" class="btn begin_quiz">
            Go to the Network
          </button>
          <button *ngIf="param == 103" (click)="redirectTo(param)" type="button" class="btn begin_quiz">
            Join a Circle
          </button>
          <button *ngIf="param == 105" (click)="redirectTo(param)" type="button" class="btn begin_quiz">
            Join a Community
          </button>
          <button *ngIf="param == 124" (click)="redirectTo(param)" type="button" class="btn begin_quiz">
            Start Your DIY
          </button>
          <button *ngIf="param == 133" (click)="redirectTo(param)" type="button" class="btn begin_quiz">
            Start Your Portrait
          </button>
          <button *ngIf="param == 134" (click)="redirectTo(param)" type="button" class="btn begin_quiz">
            My Guided Explorers
          </button>
        </td>
      </tr>
    </table>
  </mat-dialog-actions>
</mat-card>