<div class="notification-users-system" ><ng-container  *ngIf="curator_indicator || admin_indicator || program_director_indicator">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="User">
      <ng-template matTabContent>
        <br />
        <div class="search-results scroll_class_list" infinite-scroll
          [infiniteScrollDistance]="user_modalScrollDistance" [infiniteScrollThrottle]="user_throttle"
          [scrollWindow]="false" (scrolled)="pushValue($event)">
          <ng-container *ngIf="user_notification_count > 0">
            <!-- <cdk-virtual-scroll-viewport class="scroll_class_list" [itemSize]="0" (scroll)="pushValue($event)"> -->
            <div class="listNotifications row">
              <mat-accordion>
                <ng-container *ngFor="let notification of allUserNotifications">
                  <mat-expansion-panel class="receivedRequest list" (opened)="onExpand(notification.id, notification)">
                    <mat-expansion-panel-header class="{{
                        notification.read_status
                          ? 'read-notification'
                          : 'un-read-notification'
                      }}">
                      <mat-panel-title>
                        <div class="userPic" *ngIf="notification.suggistingUser[0].id != 0">
                          <img src="{{
                              notification.suggistingUser[0].picture_path
                                ? notification.suggistingUser[0].picture_path
                                : '/assets/media/users/default.png'
                            }}" alt="" />
                        </div>
                        <div class="userPic" *ngIf="notification.suggistingUser[0].id == 0">
                          <img src="./assets/media/users/sphere.png" alt="" />
                        </div>
                        <div class="userInfo">
                          <span class="noti-submenu-title">{{ notification.title }}
                          </span>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="userInfo my-3">
                      <p class="notification_body">
                        {{
                        notification.description_with_timezone_converted_string
                        }}
                      </p>

                      <div class="moreInfo">
                        <div class="notifcnCnt">
                          <span class="content"> </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="pull-right">
                            <table border="0" width="100%">
                              <tr>
                                <td>
                                  <button class="view-btn-notofication" *ngIf="
                                      notification.collab_content_type == 2603
                                    " (click)="
                                      viewProfile(
                                        notification.suggistingUser[0].id,
                                        notification.suggistingUser[0].first_name,
                                        notification.suggistingUser[0].last_name,
                                        returnProfileImage(
                                          notification.suggistingUser[0]
                                            .picture_path
                                        ),
                                        notification.suggistingUser[0]
                                          .member__status
                                      )
                                    ">
                                    <img src="./assets/media/view.png" width="15px" height="15px"
                                      title="View Details" />
                                  </button>

                                  <button class="view-btn-notofication" *ngIf="
                                      notification.collab_content_type == '2604'
                                    " (click)="
                                      viewProfile(
                                        notification.suggistingUser[0].id,
                                        notification.suggistingUser[0].first_name,
                                        notification.suggistingUser[0].last_name,
                                        returnProfileImage(
                                          notification.suggistingUser[0]
                                            .picture_path
                                        ),
                                        notification.suggistingUser[0]
                                          .member__status
                                      )
                                    ">
                                    <img src="./assets/media/view.png" width="15px" height="15px"
                                      title="View Details" />
                                  </button>
                                </td>

                                <td>
                                  <span class="time">{{
                                    notification.difference
                                    }}</span>
                                  <button (click)="removeNotification(notification.id)" class="remove" title="Remove">
                                    <i class="fas fa-trash fa-one" style="display: contents; color: white"
                                      title="Remove"></i>
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </ng-container>
              </mat-accordion>
            </div>
          </ng-container>
          <ng-container *ngIf="user_notification_count == 0">
            <table>
              <tbody>
                <tr>
                  <td style="padding: 6px !important">
                    <img src="./assets/media/no_data_found.png" style="border-radius: 0px" />
                  </td>
                  <td colspan="2" style="padding: 6px !important">
                    No notification found!
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="System">
      <ng-template matTabContent>
        <br />
        <div class="search-results scroll_class_list" infinite-scroll [infiniteScrollDistance]="50"
          [infiniteScrollThrottle]="300" [scrollWindow]="false" (scrolled)="systemPushValue($event)">
          <ng-container *ngIf="system_count > 0">
            <div class="listNotifications row">
              <mat-accordion>
                <mat-expansion-panel *ngFor="let notification of allSystemUserNotifications"
                  class="receivedRequest list" (opened)="onExpand(notification.id, notification)">
                  <mat-expansion-panel-header class="{{
                    notification.read_status
                      ? 'read-notification'
                      : 'un-read-notification'
                  }} ">
                    <mat-panel-title>
                      <div class="userPic">
                        <img src="./assets/media/users/sphere.png" alt="" />
                      </div>
                      <div class="userInfo">
                        <span class="noti-submenu-title">{{ notification.title }}
                        </span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="userInfo my-3">
                    <p class="notification_body">
                      {{
                      notification.description_with_timezone_converted_string
                      }}
                    </p>

                    <div class="moreInfo">
                      <div class="notifcnCnt">
                        <span class="content"> </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="pull-right">
                          <table border="0" width="100%">
                            <tr>
                              <td>
                                <button class="view-btn-notofication" *ngIf="notification.collab_content_type == 2603"
                                  (click)="
                                  viewProfile(
                                    notification.creatingUser[0].id,
                                    notification.suggistingUser[0].full_name,
                                    notification.suggistingUser[0].picture_path,
                                    notification.suggistingUser[0].id,
                                    notification.member_connect_count,
                                    notification.suggistingUser[0]
                                      .member__status
                                  )
                                ">
                                  <img src="./assets/media/view.png" width="15px" height="15px" title="View Details" />
                                </button>

                                <button class="view-btn-notofication" *ngIf="
                                  notification.collab_content_type == '2604'
                                " (click)="
                                  viewProfile(
                                    notification.suggistingUser[0].id,
                                    notification.suggistingUser[0].full_name,
                                    notification.suggistingUser[0].picture_path,
                                    notification.creatingUser[0].id,
                                    notification.member_connect_count,
                                    notification.suggistingUser[0]
                                      .member__status
                                  )
                                ">
                                  <img src="./assets/media/view.png" width="15px" height="15px" title="View Details" />
                                </button>
                              </td>

                              <td>
                                <span class="time">{{
                                  notification.difference
                                  }}</span>
                                <button (click)="removeNotification(notification.id)" class="remove" title="Remove">
                                  <i class="fas fa-trash fa-one" style="display: contents; color: white"
                                    title="Remove"></i>
                                </button>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </ng-container>
          <ng-container *ngIf="system_count == 0">
            <table>
              <tbody>
                <tr>
                  <td style="padding: 6px !important">
                    <img src="./assets/media/no_data_found.png" style="border-radius: 0px" />
                  </td>
                  <td colspan="2" style="padding: 6px !important">
                    No notification found!
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-container>
</div>
<div class="notification-users-system-non">
<ng-container *ngIf="!curator_indicator && !admin_indicator && !program_director_indicator">
  <div class="search-results scroll_class_list" infinite-scroll [infiniteScrollDistance]="user_modalScrollDistance"
    [infiniteScrollThrottle]="user_throttle" [scrollWindow]="false" (scrolled)="pushValue($event)">
    <ng-container *ngIf="user_notification_count > 0">
      <div class="listNotifications row">
        <mat-accordion>
          <ng-container *ngFor="let notification of allUserNotifications">
            <mat-expansion-panel class="receivedRequest list" (opened)="onExpand(notification.id, notification)">
              <mat-expansion-panel-header class="{{
                  notification.read_status
                    ? 'read-notification'
                    : 'un-read-notification'
                }}">
                <mat-panel-title>
                  <div class="userPic" *ngIf="notification.suggistingUser[0].id != 0">
                    <img src="{{
                        notification.suggistingUser[0].picture_path
                          ? notification.suggistingUser[0].picture_path
                          : '/assets/media/users/default.png'
                      }}" alt="" />
                  </div>
                  <div class="userPic" *ngIf="notification.suggistingUser[0].id == 0">
                    <img src="./assets/media/users/sphere.png" alt="" />
                  </div>
                  <div class="userInfo">
                    <span class="noti-submenu-title">{{ notification.title }}
                    </span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="userInfo my-3">
                <p class="notification_body">
                  {{
                  notification.description_with_timezone_converted_string
                  }}
                </p>

                <div class="moreInfo">
                  <div class="notifcnCnt">
                    <span class="content"> </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="pull-right">
                      <table border="0" width="100%">
                        <tr>
                          <td>
                            <button class="view-btn-notofication" *ngIf="
                                notification.collab_content_type == 2603
                              " (click)="
                                viewProfile(
                                  notification.suggistingUser[0].id,
                                  notification.suggistingUser[0].first_name,
                                  notification.suggistingUser[0].last_name,
                                  returnProfileImage(
                                    notification.suggistingUser[0]
                                      .picture_path
                                  ),
                                  notification.suggistingUser[0]
                                    .member__status
                                )
                              ">
                              <img src="./assets/media/view.png" width="15px" height="15px" title="View Details" />
                            </button>

                            <button class="view-btn-notofication" *ngIf="
                                notification.collab_content_type == '2604'
                              " (click)="
                                viewProfile(
                                  notification.suggistingUser[0].id,
                                  notification.suggistingUser[0].first_name,
                                  notification.suggistingUser[0].last_name,
                                  returnProfileImage(
                                    notification.suggistingUser[0]
                                      .picture_path
                                  ),
                                  notification.suggistingUser[0]
                                    .member__status
                                )
                              ">
                              <img src="./assets/media/view.png" width="15px" height="15px" title="View Details" />
                            </button>
                          </td>

                          <td>
                            <span class="time">{{
                              notification.difference
                              }}</span>
                            <button (click)="removeNotification(notification.id)" class="remove" title="Remove">
                              <i class="fas fa-trash fa-one" style="display: contents; color: white" title="Remove"></i>
                            </button>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>
    </ng-container>
    <ng-container *ngIf="user_notification_count == 0">
      <table>
        <tbody>
          <tr>
            <td style="padding: 6px !important">
              <img src="./assets/media/no_data_found.png" style="border-radius: 0px" />
            </td>
            <td colspan="2" style="padding: 6px !important">
              No notification found!
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
</ng-container></div>