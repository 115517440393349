import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EachVedioComponent } from '../../each-vedio/each-vedio.component';

@Component({
  selector: 'kt-surveyreport11',
  templateUrl: './surveyreport11.component.html',
  styleUrls: ['./surveyreport11.component.scss'],
})
export class Surveyreport11Component implements OnInit {
  data1: any;
  report_6_body: any;
  myChart: [];
  reportStr: string;
  reportData: any[];
  lastPageStringValue: string;
  member_id: any;
  @Input() currentSelectedSurvey;
  @Input() memberId;
  @Input() json_report;
  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.reportData = JSON.parse(this.json_report);
    this.lastPageStringValue = this.reportData['lastPageStringValue'];
  }
  dmitriVideo() {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: {
        source: 'https://player.vimeo.com/video/759650793?h=9d88a710e3',
      },
    });
  }
}
