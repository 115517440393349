import { MatDialog } from '@angular/material/dialog';
import { MeaningNetworkService } from '../../../../service/meaning-network.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { ViewProfileComponent } from '../../admin-page/view-profile/view-profile.component';
import { AccountsService } from '../../../../service/accounts.service';

@Component({
  selector: 'kt-search-explorers',
  templateUrl: './search-explorers.component.html',
  styleUrls: ['./search-explorers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchExplorersComponent implements OnInit, OnDestroy {
  throttle = 300;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;
  memberForm: UntypedFormGroup;
  usersdata = [];
  count: number;
  after_search_count: number = 0;
  page: number = 1;
  after_page: number = 1;
  profile_path;
  role: boolean = false;
  timer: Observable<any>;
  loggedInUserId: number;
  explorerForm: UntypedFormGroup;
  connections = [];
  disabled = true;
  topics = [];

  searchSubscription: Subscription;
  tagListSubscription: Subscription;
  constructor(
    private spinner: NgxSpinnerService,
    private _cdref: ChangeDetectorRef,
    private searchservice: MeaningNetworkService,
    private dialog: MatDialog,
    private accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.profile_path = localStorage.getItem('image');
    this.loggedInUserId = this.accountService.muser;
    this.role = this.accountService.admin_indicator ? true : false;
    this.explorerForm = new UntypedFormGroup({
      keyword: new UntypedFormControl(''),
      edu: new UntypedFormControl(''),
      wtca: new UntypedFormControl(''),
      location: new UntypedFormControl(''),
      company: new UntypedFormControl(''),
      keyCheck: new UntypedFormControl(''),
      eduCheck: new UntypedFormControl(''),
      wtcaCheck: new UntypedFormControl(''),
      locationCheck: new UntypedFormControl(''),
      companyCheck: new UntypedFormControl(''),
      fullName: new UntypedFormControl(''),
      page: new UntypedFormControl(1),
      logged_in_user: new UntypedFormControl(this.loggedInUserId),
    });
    this.searchExplorerList(1);
    this.tagListForWhatmatter();
  }

  returnImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default_rectangle.png';
    }
  }
  setCountry(ev) {
    this.explorerForm.patchValue({
      location: ev[0].text,
    });
  }

  viewProfile(member_id, first_name, last_name, picture_path) {
    let dialogRef = this.dialog.open(ViewProfileComponent, {
      width: '80%',
      disableClose: true,
      data: {
        member: member_id,
        first_name: first_name,
        last_name: last_name,
        picture_path: picture_path,
        click_from: 'search_explorer',
      },
    });
    dialogRef
      .afterClosed()
      .subscribe(() => this.searchservice.refreshneeded.next());
  }

  pushValue(ev?) {
    if (this.count !== this.usersdata.length) {
      this.explorerForm.patchValue({
        page: this.page,
      });
      this.searchExplorerList();
      this.page++;
    }
  }

  searchExplorerList(params = 0) {
    if (params === 1) {
      this.page = 2;
      this.explorerForm.patchValue({
        page: 1,
      });
      this.usersdata = [];
    }
    let ngForm = this.explorerForm.value;
    this.spinner.show();
    this.searchSubscription = this.searchservice
      .searchExplorer(ngForm)
      .subscribe((data) => {
        this.usersdata = [
          ...new Map(
            this.usersdata
              .concat(data.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.count = data.count;
        this._cdref.detectChanges();
        this.spinner.hide();
      });
  }

  resetForm() {
    this.explorerForm.patchValue({
      fullName: '',
      keyword: '',
      edu: '',
      wtca: '',
      location: '',
      company: '',
      keyCheck: '',
      eduCheck: '',
      wtcaCheck: '',
      locationCheck: '',
      companyCheck: '',
      page: 1,
    });
    this.searchExplorerList(1);
  }
  tagListForWhatmatter() {
    this.tagListSubscription = this.searchservice
      .getTagListForWhatMatter()
      .subscribe((data) => {
        this.topics = data.results;
        this._cdref.detectChanges();
      });
  }
  ngOnDestroy(): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.tagListSubscription) {
      this.tagListSubscription.unsubscribe();
    }
  }
}
