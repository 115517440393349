import { MentorService } from './../../../../service/mentor.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../../../service/notification.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { message_properties } from '../../../../../environments/message_properties';
import { Subscription } from 'rxjs';
import { CuratorPageService } from '../../../../service/curator-page.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment-timezone';
import { TimezoneService } from '../../../../service/timezone.service';
import { AccountsService } from './../../../../service/accounts.service';

@Component({
  selector: 'kt-setup-discussion-from-request',
  templateUrl: './setup-discussion-from-request.component.html',
  styleUrls: ['./setup-discussion-from-request.component.scss'],
})
export class SetupDiscussionFromRequestComponent implements OnInit, OnDestroy {
  @Input() clicked_from;
  dialogForm: UntypedFormGroup;
  showAndHide: boolean = false;
  usersdata: any[];
  selected: number;
  groupid: number = 0;
  collabContentCategory: number = 0;
  creatingUser: number;
  titleHeader: string;
  ufullname: string;
  active: number;
  attachment_path: string = '';
  base64textString: string;
  myTime;
  minTime;
  toTime: boolean = true;
  valueArr: any;
  public value: string[];
  public current: string;
  show_address: boolean = false;
  saveButton: boolean = false;
  user_profile_path: string;
  user_profile_path_data: boolean;
  fileToUpload: File;
  imageUrl: File;
  profile_path: any;
  title: any;
  menteeDp: any;
  mentee: any;
  menteeId: any;
  loggedinUserid: number;
  eventId: number;
  start_date: string;
  GuideServiceList = [];
  guide_service_count: number;
  timeFromval;
  timeToVal;
  start_time: any;
  end_time: any;
  imageSize: number;
  endTime: string;
  count: number;
  momData = [];
  documentType: number = 1;
  containerView: number;
  header_set_up_discussion: string;
  requestor_title: string;
  collab_content_id: number;
  collb_content_type: number;
  rsvp: string;
  current_timezone: string;
  current_time_zone_date: Date;
  both_date: boolean = false;
  myDate: Date = new Date();
  request_status: number = 6;
  public from_date: moment.Moment;
  createSubscription: Subscription;
  acceptSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<SetupDiscussionFromRequestComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private toastr: NotificationService,
    private curatorService: CuratorPageService,
    private mentorService: MentorService,
    private timeZoneService: TimezoneService,
    private accountService: AccountsService
  ) {
    this.mentee = data.mentee;
    this.eventId = data.eventId;
    this.menteeDp =
      data.dp !== null ? data.dp : './assets/media/users/default.png';
    this.menteeId = data.menteeId;
    this.clicked_from = data.clicked_from;

    this.requestor_title = data.requestor_title;
    this.collab_content_id = data.collab_content_id;
    this.collb_content_type = data.collb_content_type;
    this.rsvp = data.rsvp;
  }

  ngOnInit() {
    this.header_set_up_discussion = 'Set up a Discussion';
    this.loggedinUserid = this.accountService.muser;
    this.ufullname = this.accountService.full_name;
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.myTime = moment(this.myDate)
      .tz(this.current_timezone)
      .format('hh:mm A');

    this.current_time_zone_date = this.myDate;
    this.minTime = moment(this.myDate)
      .tz(this.current_timezone)
      .format('hh:mm A');

    this.user_profile_path = localStorage.getItem('image')
      ? localStorage.getItem('image')
      : './assets/media/users/default.png';
    this.dialogForm = this.formBuilder.group({
      name: new UntypedFormControl('New Set Up'),
      virtual_link_address: new UntypedFormControl('Discussion from guide'),
      description: new UntypedFormControl('', [Validators.required]),
      max_participant: new UntypedFormControl('1'),
      visibility_type: new UntypedFormControl('1953'),
      event_type: new UntypedFormControl(2548),
      creating_member: new UntypedFormControl(this.loggedinUserid),
      active: new UntypedFormControl(3),
      start_date: new UntypedFormControl('', [Validators.required]),
      timeFrom: new UntypedFormControl('', [Validators.required]),
      timeTo: new UntypedFormControl('', [Validators.required]),
      duration: new UntypedFormControl(15, [Validators.required]),
      group: this.groupid,
      processing_member: this.loggedinUserid, // guide
      subscribing_member: this.menteeId, // explorer
      create_from: new UntypedFormControl(this.clicked_from),
      member_relationship_id: new UntypedFormControl(''),
    });
  }

  ngOnChanges(changes) {
    this.onChangeFrom(changes);
  }

  onChangedate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dialogForm.patchValue({ timeFrom: '' });
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate());
    if (tomorrow.toDateString() === event.value.toDateString()) {
      this.minTime = moment(this.myDate)
        .tz(this.current_timezone)
        .format('hh:mm A');
      this.both_date = true;
    } else {
      this.minTime = '12:00 AM';
    }
  }

  onChangeFromTime(event) {
    var timeFrm = this.dialogForm.get('timeFrom').value;
    var durat = this.dialogForm.get('duration').value;
    var timeToVal;
    timeToVal = timeFrm;
    if (timeFrm) {
      if (durat !== null || durat !== '') {
        timeToVal = moment(timeFrm, 'hh:mm A')
          .add(durat, 'minutes')
          .format('hh:mm A');
      }
      this.endTime = timeToVal;
      this.dialogForm.patchValue({
        timeTo: timeToVal,
      });
    }
    this._cdr.detectChanges();
  }
  onChangeFrom(event) {
    var durat = this.dialogForm.get('duration').value;
    var timeFrm = event;
    var timeToVal;

    if (
      (timeFrm !== null || timeFrm !== '') &&
      (durat !== '' || durat !== null)
    ) {
      timeToVal = moment(timeFrm, 'hh:mm A')
        .add(durat, 'minutes')
        .format('hh:mm A');
    } else {
      timeToVal = timeFrm;
    }
    this.endTime = timeToVal;
    this.dialogForm.patchValue({
      timeTo: timeToVal,
    });
    this._cdr.detectChanges();
  }
  async createSetupDiscussionFromRequest(ngForm: NgForm) {
    this.saveButton = true;
    this.spinner.show();
    const object1 = ngForm;
    const start_date_obj = object1['start_date'];
    let start_date = '';
    if (String(start_date_obj).includes('Z')) {
      const start_dateArr = object1['start_date'].split('T');
      start_date = start_dateArr[0];
    } else {
      start_date =
        object1['start_date'].getFullYear() +
        '-' +
        (object1['start_date'].getMonth() + 1) +
        '-' +
        object1['start_date'].getDate();
    }
    const time_from = moment(object1['timeFrom'], 'hh:mm A').format('HH:mm');
    const time_to = moment(object1['timeTo'], 'hh:mm A').format('HH:mm');
    const start_date_time = moment(
      start_date + ' ' + time_from,
      'YYYY-MM-DD HH:mm'
    ).format('YYYY-MM-DD HH:mm');
    const end_date_time = moment(
      start_date + ' ' + time_to,
      'YYYY-MM-DD HH:mm'
    ).format('YYYY-MM-DD HH:mm');

    const object2 = {
      start_date_time: moment.tz(start_date_time, this.current_timezone),
      end_date_time: moment.tz(end_date_time, this.current_timezone),
    };
    const object3 = { ...object1, ...object2 };
    this.createSubscription = this.curatorService
      .createSetUpDiscussionFromGuide(object3)
      .subscribe(
        (data) => {
          if (data.status === 201) {
            this.request_status = 7;
            this.acceptRequest(
              this.collab_content_id,
              this.rsvp,
              this.collb_content_type,
              this.loggedinUserid,
              this.request_status
            );
            this.toastr.showSuccess(
              message_properties.ADD_SETUP_DISCUSSION_SUCCESS,
              ''
            );
            this.spinner.hide();
          } else {
            this.saveButton = false;
            this.toastr.showError(
              message_properties.SETUP_DISCUSSION_ERROR,
              ''
            );
            this.spinner.hide();
          }
        },
        (error) => {
          this.saveButton = false;
          this.toastr.showError(message_properties.SETUP_DISCUSSION_ERROR, '');
          this.spinner.hide();
        }
      );
  }

  returnImage(image) {
    if (image || image !== null) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }

  acceptRequest(
    collab_content_id,
    rsvp,
    collb_content_type,
    loggedinUserid,
    request_status
  ) {
    this.acceptSubscription = this.mentorService
      .updateCollabContent(
        collab_content_id,
        rsvp,
        collb_content_type,
        loggedinUserid
      )
      .subscribe((data) => {
        this.dialogRef.close({
          data: {
            request: request_status,
            collab_content_id: collab_content_id,
          },
        });
      });
  }
  dateInUserTimezome(datetime: string) {
    this.from_date = moment(datetime).utc();
    this.timeZoneService.setTenantTimeZone(this.current_timezone);
    return this.timeZoneService.utcToTenantString(this.from_date, 'LL');
  }
  ngOnDestroy(): void {
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
    if (this.acceptSubscription) {
      this.acceptSubscription.unsubscribe();
    }
  }
}
