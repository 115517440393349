import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../../../service/notification.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Optional,
  Inject,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { message_properties } from '../../../../../environments/message_properties';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { CuratorPageService } from '../../../../service/curator-page.service';
import { AccountsService } from './../../../../service/accounts.service';

@Component({
  selector: 'kt-update-requested-session',
  templateUrl: './update-requested-session.component.html',
  styleUrls: ['./update-requested-session.component.scss'],
})
export class UpdateRequestedSessionComponent
  implements OnInit, OnChanges, OnDestroy {
  dialogForm: UntypedFormGroup;
  showAndHide: boolean = false;
  usersdata: any[];
  selected: number;
  groupid: number = 0;
  collabContentCategory: number = 0;
  creatingUser: number;
  titleHeader: string;
  ufullname: string;
  active: number;
  attachment_path: string = '';
  base64textString: string;
  myDate = new Date();
  myTime;
  minTime;
  toTime: boolean = true;
  valueArr: any;
  public value: string[];
  public current: string;
  show_address: boolean = false;
  saveButton: boolean = false;
  user_profile_path: string;
  user_profile_path_data: boolean;
  fileToUpload: File;
  imageUrl: File;
  profile_path: any;
  title: any;
  requestor_dp: any;
  requestor: any;
  requestorId: any;
  collabcontentId: number;
  subscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<UpdateRequestedSessionComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private toastr: NotificationService,
    private curatorService: CuratorPageService,
    private accountService: AccountsService
  ) {
    this.requestor = data.requestor;
    this.requestor_dp = data.requestor_dp;
    this.title = data.title;
    this.requestorId = data.requestor_id;
    this.myTime = formatDate(this.myDate, 'hh:mm a', 'en-US', '+0530');
    this.minTime = this.myTime;
    this.collabcontentId = data.ccId;
  }

  ngOnInit() {
    this.creatingUser = this.requestorId;
    this.ufullname = this.accountService.full_name;
    this.user_profile_path = localStorage.getItem('image')
      ? localStorage.getItem('image')
      : './assets/media/users/default.png';
    this.dialogForm = this.formBuilder.group({
      name: new UntypedFormControl(this.title + ' from ' + this.requestor),

      description: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(1024),
      ]),
      virtual_link_address: new UntypedFormControl(''),
      max_participant: new UntypedFormControl('', [Validators.maxLength(3)]),
      visibility_type: new UntypedFormControl('1953'),
      event_type: new UntypedFormControl('', [Validators.required]),
      creating_member: new UntypedFormControl(this.creatingUser),
      check_uncheck: new UntypedFormControl(false),
      actual_duration: new UntypedFormControl(''),
      active: 1,
      start_date: new UntypedFormControl('', [Validators.required]),
      timeFrom: new UntypedFormControl('', [Validators.required]),
      timeTo: new UntypedFormControl('', [Validators.required]),
      group: this.groupid,
      contextId: this.collabcontentId,
      imageUrl: [''],
    });
  }
  ngOnChanges(changes) {
    this.onChangeFrom(changes);
  }
  onChangeDate(event) {
    if (this.myDate.toDateString() !== event.value.toDateString()) {
      this.minTime = '12:00 AM';
    }
    this._cdr.detectChanges();
  }
  onChangeFrom(event) {
    this.minTime = event;
    if (event.value !== null || event.value !== '') {
      this.toTime = false;
    } else {
      this.toTime = true;
    }
    if (event === undefined) {
      this.toTime = false;
      this.minTime = this.myTime;
    }
    this._cdr.detectChanges();
  }
  async createDialog(ngForm: NgForm) {
    this.saveButton = true;
    this.spinner.show();

    this.subscription = this.curatorService.createEvent(ngForm).subscribe(
      (data) => {
        if (data.status === 201) {
          this.dialogRef.close();
          this.spinner.hide();

          this.toastr.showSuccess(
            message_properties.UPDATE_SESSION_REQUEST_SUCCESS,
            ''
          );
        } else {
          this.saveButton = false;
          this.toastr.showError(
            message_properties.UPDATE_SESSION_REQUEST_ERROR,
            ''
          );
        }
      },
      (error) => {
        this.saveButton = false;
        this.toastr.showError(
          message_properties.UPDATE_SESSION_REQUEST_ERROR,
          ''
        );
      }
    );
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  returnImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
