<div class="col-sm-12">
  <div class="col-sm-12 mt-4 pl-0">
   <h2 class="section-title1 mb-10 ">Healthy Tension Practical Examples</h2>
   <p>Someone whose pattern at work is almost exclusively focused on getting things done for others is at risk of not finding enough space for themselves. Over time, this kind of tension imbalance can drain your energy.</p>
   <p>Oliver’s story shown in the video linked below explains a tension he’s facing as he feels he's doing too much work on his own and wants to collaborate more with his colleagues.</p>
   <div class="row mt-30">
    <div class="col-sm-12 pl-5">
      <p class="section-text">
        <span
          style="color: #fff !important"
          class="arrow-highlight cursorp"
          style="color: #fff !important"
          (click)="oliverVideo()"
          >Watch&nbsp;&nbsp;<img
            src="/../assets/media/icon/play_arrow.png"
            class="img15"
        /></span>
       <span> Oliver: "Something's missing!"
       </span> </p>
    </div>
    <div class="col-sm-7"></div>
  </div>
  <h2 class="section-title1 mt-30 mb-20">Inspiration and Reality: A special tension</h2>
<p>The four pathways held in the two tensions play out in the overall context of our “Inspiration” and the “Reality of Self and Circumstances.” As Inspiration expands our thinking, Reality binds us to what's possible. An “unhealthy" tension may occur when our thoughts and actions are dominated by Reality so that Inspiration doesn't have a voice, and vice versa. In addition, when we cannot see a connection between our Inspiration and Reality, an unhealthy tension usually follows.</p>
<p>The survey items that highlight your perception of “Inspiration” and the “Reality of Self and Circumstances” are displayed below.</p>
<div class="text-center mb-10">
   <img class="img-fluid mt-20" src="/../assets/media/images/seventh-page.png" /></div>
      <div id="divchart_hor mt-20">
        <span class="title_number"
          ><br />The chart below displays your scores and the typical scores for other people who have completed the Survey.
        </span>
        <div class="row">
          <div class="col-md-6">
            <canvas id="barchart_hor2" class="my-2"></canvas>
          </div>
        </div>
        <div class="row mt-15">
          <div class="col-md-12">
            <div class="italic-section mb-5">
              <p>The typical score for both Reality and Inspiration is 3.5. </p>
            </div>
           </div>
        </div>
       
        <!-- <div class="row">
          <div class="col-md-6">
            <canvas id="barchart_hor" class="my-2"></canvas>
          </div>
        </div> -->
        <div class="row mt-5">
          <div class="col-md-12">
            <!-- <p class="italic-section"
              >The typical score for both these tensions is 3.5</p
            > -->
            <p class="section-text">This tension can be considered “healthy” when both Inspiration and Reality are rated relatively high. However, if one or both are low, this tension is probably “unhealthy.” </p>
           
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-30">
      <h2 class="section-title1 mb-20">
        Inspiration and Reality Practical Examples</h2>
        <p class="section-text">Our inspiration can  be suppressed if we feel stuck or experience a lack of progress. In a work situation characterized by a "heads down, get it done" environment where people are constantly reminded to “be realistic,” a person may find their inspiration suppressed. If this person desires more activities that inspire hope, ideals, or vision, they may volunteer in an organization that provides them these opportunities or consider switching roles.</p>
        <p class="section-text">In the video linked below, meet Sam who experiences a common disconnect between Inspiration and Reality. </p>
      </div>
          
<div class="col-sm-12 mt-125">
    <div class="row">
      <div class="col-sm-7 pl-5">
        <p class="section-text">
          <span class="arrow-highlight cursorp"  style="color:#fff !important;" (click)="samVideo()"
            >Watch&nbsp;&nbsp;<img
              src="/../assets/media/icon/play_arrow.png"
              class="img15"
          /></span>
          Sam: "It's a nice idea!"
        </p>
      </div>
      <div class="col-sm-7"></div>
    </div>
  </div></div>

      <div class="col-sm-12 mt-30 pl-0">
        <h2 class="section-title1 pb-15">Key Takeaways About Healthy Tensions</h2>
        <p class="section-text pb-20">
          As you think about Healthy Tensions, please remember:
        </p>
        <ol class="section-text">
          <li>
            Your tension scores are point-in-time snapshots. If something
            significant changes at work or in life away from work, tensions may
            also change, for better or worse.
          </li>
          <li>
            Research shows that imbalances may lead to an overall loss of
            meaning, and over time this can become unhealthy because it may lead
            to physical and psychological issues.
          </li>
          <li>
            There are no “correct” answers. You decide whether you are concerned
            with imbalances. Your decision to take some re-balancing action
            should be based on your work circumstances and the meaning you find
            in your work.
          </li>
        </ol>
      </div>
    

    <div id="divchart_hor2">
      

      <!-- <div class="row">
        <div class="col-md-6">
          <canvas id="barchart_hor2" class="my-2"></canvas>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b class="ml-68">The typical score for both these tensions is 3.5 </b>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b class="ml-68">The typical score for Inspiration is 3.9</b>
        </div>
      </div> -->
    </div>

