import { RegisterService } from '../../../service/register.service';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
// Core Module
import { RouteGuard } from '../../../guard/route.guard';
import { AuthService } from '../../../service/auth.service';

import { HomeComponent } from './home.component';
import { TakeMapOfMeaningQuizComponent } from './take-map-of-meaning-quiz/take-map-of-meaning-quiz.component';
import { StartMakingConnectionsComponent } from './start-making-connections/start-making-connections.component';
import { TakeToGuideComponent } from './take-to-guide/take-to-guide.component';
import { BecomeAContributorComponent } from './become-a-contributor/become-a-contributor.component';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { FooterButtonsHomeComponent } from './footer-buttons-home/footer-buttons-home.component';
import { AboutComponent } from './about/about.component';
import { MatCardModule } from '@angular/material/card';
@NgModule({
  declarations: [
    HomeComponent,
    TakeMapOfMeaningQuizComponent,
    StartMakingConnectionsComponent,
    TakeToGuideComponent,
    BecomeAContributorComponent,
    HomeHeaderComponent,
    FooterButtonsHomeComponent,
    AboutComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    RouterModule.forChild([
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'take-map-of-meaningquiz',
        component: TakeMapOfMeaningQuizComponent,
      },
      {
        path: 'start-making-connections',
        component: StartMakingConnectionsComponent,
      },
      {
        path: 'take-to-guide',
        component: TakeToGuideComponent,
      },
      {
        path: 'become-a-contributor',
        component: BecomeAContributorComponent,
      },
      {
        path: 'about-meaningsphere',
        component: AboutComponent,
      },
    ]),
  ],
  providers: [RouteGuard, AuthService, RegisterService],

})
export class HomeModule { }
