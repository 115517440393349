import { CookieService } from 'ngx-cookie-service';
// Angular
import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  DoCheck,
} from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService } from '../../../core/_base/layout';
import { Router, NavigationStart, NavigationError } from '@angular/router';
import { HtmlClassService } from '../html-class.service';
import { AccountsService } from './../../../service/accounts.service';
@Component({
  selector: 'kt-base',
  templateUrl: './base.component.html',
  styleUrls: [
    './base.component.scss',
    './all_card_height.component.scss',
    './sub-one-base.component.scss',
    './sub-two-base.component.scss',
    './responsive.scss',
    './all_post_common.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class BaseComponent implements OnInit, OnDestroy, DoCheck {
  // Public variables

  selfLayout: string;
  asideDisplay: boolean;
  asideSecondary: boolean;
  subheaderDisplay = true;
  fluid: boolean;
  show = true;
  cookies_panel_show = true;
  isLoggedInUser = false;
  sidebarExpand = '';
  step = 3;
  showSidebar = false;
  muser: number;
  ufullname: string;
  user_email: string;
  token_expire_or_not = false;
  // Private properties
  private unsubscribe: Subscription[] = [];
  banner_message_count = 0;
  headeralert = false;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private htmlClassService: HtmlClassService,
    private _router: Router,
    private cookieService: CookieService,
    private cdRef: ChangeDetectorRef,
    private authService: AccountsService
  ) {
    this.htmlClassService.setConfig(this.layoutConfigService.getConfig());
    const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(
      (layoutConfig) => {
        document.body.className = '';
        this.htmlClassService.setConfig(layoutConfig);
      }
    );
    this.unsubscribe.push(subscr);

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (event instanceof NavigationError) {
      }
    });
  }

  addItem(newItem: string) {
    if (newItem === 'expand') {
      this.step = 1;
    } else if (newItem === 'expand-hover') {
      this.step = 2;
    } else {
      this.step = 3;
    }
    this.sidebarExpand = newItem;
  }
  showSidebarTransfer(data) {
    this.showSidebar = data;
    if (data) {
      this.step = 1;
    } else {
      this.step = 3;
    }
  }
  clicked() {
    this.cookies_panel_show = false;
  }
  ngOnInit(): void {
    this.token_expire_or_not = this.authService.loggedIn;
    if (this.token_expire_or_not) {
      this.muser = this.authService.muser;
      this.ufullname = this.authService.full_name;
      this.user_email = this.authService.user_email;
      this.isLoggedInUser = true;
    } else {
      this.isLoggedInUser = false;
    }
    const config = this.layoutConfigService.getConfig();
    this.selfLayout = objectPath.get(config, 'self.layout');
    this.asideDisplay = objectPath.get(config, 'aside.self.display');
    this.asideSecondary = objectPath.get(
      config,
      'aside-secondary.self.display'
    );
    this.subheaderDisplay = objectPath.get(config, 'subheader.display');
    this.fluid = objectPath.get(config, 'content.width') === 'fluid';

    this.cookies_panel_show = this.cookieService.check('setCookiesValue')
      ? false
      : true;
  }
  getBannerMessage() {
    this.banner_message_count = Number(
      localStorage.getItem('banner_message_count')
    );
    if (this.banner_message_count > 0) {
      this.headeralert = true;
    }
  }
  ngDoCheck() {
    this.token_expire_or_not = this.authService.loggedIn;
    if (this.token_expire_or_not) {
      this.isLoggedInUser = true;
      this.getBannerMessage();
      this.cdRef.detectChanges();
    }
  }

  hasRoute(route: string) {
    return this._router.url.includes(route);
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {}

  acceptCookies() {
    this.cookieService.set('setCookiesValue', '1');
    this.cookieService.set('welcome', '1');
    if (this.cookieService.check('setCookiesValue')) {
      this.cookies_panel_show = false;
    }
  }
  rejectCookies() {
    this.cookies_panel_show = false;
    this.cookieService.set('welcome', '1');
    // this.cookieService.set("setCookiesValue", "0");
    if (this.cookieService.check('setCookiesValue')) {
      this.cookieService.delete('_gat_gtag_UA_172365930_1');
      this.cookieService.delete('_gid');
      this.cookieService.delete('_ga');
    }
  }
  showheaderAlertTransfer(data) {
    localStorage.setItem('banner_message_count', '0');
    this.headeralert = data;
  }
}
