<ngx-spinner bdColor="transparent" size="large" color="#f57116">
  <p class="colorpw">Please Wait...</p>
</ngx-spinner>
<mat-card class="example-card">
  <table class="w-100">
    <tr>
      <td align="left"><h1 class="card_title">Add Survey</h1></td>
      <td align="right"></td>
    </tr>
  </table>

  <hr />
  <mat-card-content>
    <form
      [formGroup]="surveyForm"
      (ngSubmit)="saveSurvey(surveyForm.value)"
      novalidate
      autocomplete="off"
    >
      <div class="row">
        <div class="form-group col-sm-3">
          <mat-form-field class="w-100">
            <mat-label>Name *</mat-label>

            <input matInput autocomplete="off" formControlName="name" />
          </mat-form-field>
        </div>
        <div class="form-group col-sm-3">
          <mat-form-field class="w-100">
            <mat-label>Total no participants *</mat-label>

            <input
              matInput
              autocomplete="off"
              formControlName="total_no_participants"
            />
          </mat-form-field>
        </div>
        <div class="form-group col-sm-3">
          <mat-form-field class="w-100">
            <mat-label>Completed participants *</mat-label>

            <input
              matInput
              autocomplete="off"
              formControlName="completed_participants"
            />
          </mat-form-field>
        </div>
        <div class="form-group col-sm-3">
          <mat-form-field class="w-100">
            <mat-label>Avg rating *</mat-label>

            <input matInput autocomplete="off" formControlName="avg_rating" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-3">
          <mat-form-field class="w-100">
            <mat-label>Survey type</mat-label>
            <mat-select formControlName="survey_type">
              <mat-option [value]="2306">Survey</mat-option>
              <mat-option [value]="2307">Mirror</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field appearance="fill">
						<mat-label>Survey type</mat-label>
						<mat-select
							[formControl]="selected"
							formControlName="survey_type"
						>
							<mat-option value="2306">Survey</mat-option>
							<mat-option value="2307">Mirror</mat-option>
						</mat-select>
					</mat-form-field> -->
        </div>
        <div class="form-group col-sm-3">
          <mat-form-field class="w-100">
            <mat-label>Additional details *</mat-label>
            <textarea
              matInput
              autocomplete="off"
              formControlName="additional_details"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="form-group col-sm-3">
          <mat-form-field class="w-100">
            <mat-label>Description *</mat-label>
            <textarea
              matInput
              autocomplete="off"
              formControlName="description"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="form-group col-sm-3">
          <mat-form-field class="w-100">
            <mat-label>Avg time to complete *</mat-label>
            <input
              matInput
              autocomplete="off"
              formControlName="avg_time_to_complete"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 tr">
          <button
            [disabled]="!surveyForm.valid"
            class="btn begin_quiz mt-1"
            type="submit"
          >
            Save
          </button>
          <button
            (click)="backToSurvey()"
            type="button"
            class="btn begin_quiz mt-1 ml-2"
          >
            Back
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
