import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kt-surveyreport10',
  templateUrl: './surveyreport10.component.html',
  styleUrls: ['./surveyreport10.component.scss'],
})
export class Surveyreport10Component implements OnInit {
  @Input() currentSelectedSurvey;
  @Input() memberId;
  @Input() json_report;
  constructor() {}

  ngOnInit(): void {}
}
