<div class="labelClass mt-2 pt-5">
  <p>
    This Community is hosted by:
    <span
      tooltipClass="tooltip_design"
      theme="light"
      tooltip="A Community Host is responsible for moderating the Conversations and Dialogs of the Community."
      placement="right"
      showDelay="500"
    >
      <span class="icon-info">
        <mat-icon class="info_material_icon">info</mat-icon>
      </span>
    </span>
  </p>
</div>
<div class="row">
  <div class="col-sm-12">
    <ng-container *ngIf="circle_host_count > 0">
      <ng-container *ngFor="let memberObject of circleData">
        <div class="row pt-1 pb-2 px-4">
          <div
            class="col-lg-1 col-md-1 px-0 brdwidth cursorp"
            (click)="
              viewProfile(
                memberObject?.hostDetails.member_id,
                memberObject?.hostDetails.first_name,
                memberObject?.hostDetails.last_name,
                memberObject?.hostDetails.picture_path
                  ? memberObject.hostDetails.picture_path
                  : './assets/media/users/default.png'
              )
            "
          >
            <img
              class="img-fluid memberImgClass brdRadiusClass"
              *ngIf="memberObject.hostDetails.picture_path == null"
              src="../../../../../assets/media/default/guide_default.png"
            />
            <img
              class="img-fluid memberImgClass brdRadiusClass"
              *ngIf="memberObject.hostDetails.picture_path != null"
              [src]="memberObject?.hostDetails.picture_path"
            />
          </div>
          <div class="col-lg-10 col-md-10 labelValueClass pl-0 py-2">
            <p
              class="cursorp"
              (click)="
                viewProfile(
                  memberObject?.hostDetails.member_id,
                  memberObject?.hostDetails.first_name,
                  memberObject?.hostDetails.last_name,
                  memberObject?.hostDetails.picture_path
                    ? memberObject.hostDetails.picture_path
                    : './assets/media/users/default.png'
                )
              "
            >
              {{ memberObject?.hostDetails.full_name }}
            </p>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="circle_host_count == 0">
      <div class="row py-2 px-4">
        <div class="col-lg-10 col-md-10 labelValueClass pl-2">
          No Community Host Found!
        </div>
      </div>
    </ng-container>
  </div>
</div>
