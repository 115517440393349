<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1>Get Acquainted</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <ng-container>
    <form
      [formGroup]="sendRequestForm"
      (ngSubmit)="sendRequest(sendRequestForm)"
      novalidate
      autocomplete="off"
    >
      <div class="">
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <p style="cursor: pointer">
              Requesting: &nbsp;
              <img
                class="img-fluid curator-createimg"
                [src]="picPath"
              />
              &nbsp;&nbsp;{{ fullName }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field style="width: 100%; color: #ffffff">
              <mat-label>Personalize your message</mat-label>
              <textarea
                matInput
                formControlName="description"
                cols="30"
                rows="10"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.()@!? ]|^ /g,'')"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <hr />
      <div class="row pt-5">
        <div class="col-sm-6"><p>* This is a complimentary session!</p></div>
        <div class="col-sm-6 tr">
          <button
            class="btn begin_quiz ml-2"
            type="submit"
            [disabled]="!sendRequestForm.valid"
          >
            Send
          </button>

          <button mat-dialog-close class="btn begin_quiz ml-2 canceled_btn">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</div>
