import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Chart } from 'chart.js';
import { EachVedioComponent } from '../../each-vedio/each-vedio.component';

@Component({
  selector: 'kt-surveyreport7',
  templateUrl: './surveyreport7.component.html',
  styleUrls: ['./surveyreport7.component.scss'],
})
export class Surveyreport7Component implements OnInit {
  data1: any;
  report_7_body: any;
  myChart: [];
  reportStr: string;
  reportData = [];
  horizontal_barchart_2_1: number;
  horizontal_barchart_2_2: number;
  @Input() memberId;
  @Input() currentSelectedSurvey;
  @Input() json_report;
  constructor(private dialog: MatDialog, private elementRef: ElementRef) {}

  ngOnInit() {
    this.reportData = JSON.parse(this.json_report);
    this.horizontal_barchart_2_1 = this.reportData['horizontal_barchart_2_1'];
    this.horizontal_barchart_2_2 = this.reportData['horizontal_barchart_2_2'];
    let barchart_ref2 = this.elementRef.nativeElement.querySelector(
      `#barchart_hor2`
    );

    new Chart(barchart_ref2, {
      type: 'horizontalBar',
      data: {
        labels: ['Reality', 'Inspiration'],
        datasets: [
          {
            data: [this.horizontal_barchart_2_1, this.horizontal_barchart_2_2],
            backgroundColor: ['#13B0C5', '#FECC54'],
            borderWidth: 0,
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: '#1f1547',
                max: 5,
                min: 0,
                stepSize: 1,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,

                stepSize: 1,

                fontColor: '#1f1547',
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
  }
  oliverVideo() {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: {
        source: 'https://player.vimeo.com/video/759650299?h=5b627ddf8f',
      },
    });
  }
  samVideo() {
    this.dialog.open(EachVedioComponent, {
      disableClose: true,
      data: {
        source: 'https://player.vimeo.com/video/759650591?h=6f77edd028',
      },
    });
  }
}
