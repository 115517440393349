<kt-spinner></kt-spinner>
<div id="feeds" class="mat-card-style2 p-0">
  <div class="row">
    <div class="col-sm-12 col-lg-3 sub-card-left">
      <div class="profile_card">
        <div
          class="profile-div d-flex flex-direction-column align-items-center"
        >
          <div class="image-parent w-250-pp pos-relative">
            <img alt="Pic" class="w-250-pp" [src]="profile_path" />
          </div>
          <div class="content-parent text-align-center w-100">
            <div class="profDetail w-100">
              <h1>
                {{ first_name }}
              </h1>
              <h2>
                {{ last_name }}
              </h2>
              <hr class="hr-5" />
              <h3>
                {{ city ? city+', ' : '' }} {{ country }}
              </h3>
            </div>
          </div>
        </div>
        <div class="myProdileMenu">
          <span class="title"></span>
          <ul class="list-style-none p-0">
            <li>
              <table>
                <tr>
                  <td>
                    <img
                      src="./assets/media/sidebar/profile.png"
                      class="side_bar_icon"
                    />
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <a
                      [routerLink]="
                        '/meaning-network/my-profile'
                      "
                      routerLinkActive="active"
                      style="color: white"
                      >My Portrait</a
                    >
                  </td>
                </tr>
              </table>
            </li>
            <li>
              <table>
                <tr>
                  <td>
                    <img
                      src="./assets/media/sidebar/treasures.png"
                      class="side_bar_icon"
                    />
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <a
                      [routerLink]="'/meaning-network/my-treasures'"
                      routerLinkActive="active"
                      style="color: white"
                      >My Treasures</a
                    >
                  </td>
                </tr>
              </table>
            </li>
            <!-- <li>
              <a
                [routerLink]="'/meaning-network/my-feed'"
                routerLinkActive="active"
                style="color: white"
                >My Feeds</a
              >
            </li> -->
            <li>
              <table>
                <tr>
                  <td>
                    <img
                      src="./assets/media/sidebar/bookmark.png"
                      class="side_bar_icon"
                    />
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <a
                      [routerLink]="'/meaning-network/my-bookmarks'"
                      routerLinkActive="active"
                      style="color: white"
                      >My Bookmarks</a
                    >
                  </td>
                </tr>
              </table>
            </li>
          </ul>
        </div>
      </div>
      <!-- What Matters To You -->
      <div class="profile_card mn_network1">
        <div class="edit_section">
          <span class="mb-4">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="m-0">What Matters To Me</h2>
              <button
                (click)="toggleWhatMatters()"
                class="arrow"
                [ngClass]="{ active: WhatMatters }"
              >
                <img src="./assets/media/icon/Arrow_Black@2x.png" alt="" />
              </button>
            </div>
          </span>
          <hr />
          <div
            class="multi-collapse"
            id="multiCollapseExample7"
            *ngIf="WhatMatters"
          >
            <div class="boxData left-menu-card-left">
              <div class="content">
                <ul *ngIf="topic_count > 0">
                  <li *ngFor="let topic of topics">
                    {{ topic.alternate_name }}
                  </li>
                </ul>
                <spna *ngIf="topic_count == 0">
                  You have not added any topics!
                </spna>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-9 sub-card-right">
      <!-- My Meaning Momentss -->
      <div class="profile_card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start align-items-center">
            <h1 class="m-0">Meaning Moments</h1>
            <ng-template #HtmlContentMeaningMoments>
              <p>{{myMeaningLogToolTip}}&nbsp;<a href="https://meaningsphere.zendesk.com/hc/en-us/articles/5860136371732" target="_blank">&nbsp;Learn more »</a> </p>
            </ng-template>
            <kt-tooltip-html [position]="'right'" [theme]="'light'" [htmlValue]="HtmlContentMeaningMoments"></kt-tooltip-html>
          </div>
          <div>
            <button class="btn begin_quiz" (click)="openAddLogDialog()">
              Add a Meaning Moment
            </button>
          </div>
        </div>

        <hr />
        <div
        class="search-results imagePosts card2-style-height-with-hr-buttons"
        infinite-scroll
        [infiniteScrollDistance]="modalScrollDistance"
        [infiniteScrollThrottle]="modalScrollThrottle"
        [scrollWindow]="false"
        (scrolled)="pushValue($event)">
          <div
            [ngClass]="{ heightChange: writeAPostForm }"
            style="cursor: pointer"
          >
            <div class="row">
              <ng-container *ngIf="meaning_log_count > 0">
                <div
                  *ngFor="let log of meaningLog"
                  class="col-lg-3 col-sm-6 post"
                >
                  <div
                    (click)="
                      collabContentDetails(
                        log.collab_content_details[0].collab_content_id,
                        log.member,
                        log.bucket_type,
                        log?.collab_content_details[0]?.title,
                        log?.collab_content_details[0]?.description,
                        log?.collab_content_details[0]?.video_indicator,
                        log?.collab_content_details[0]?.attachment_path,
                        log?.collab_content_details[0]?.references,
                        log?.collab_content_details[0]?.collab_content_category
                      )
                    "
                    style="cursor: pointer"
                    class="innerPost card archive_card front_card_background_clr"
                  >
                    <div class="dis-prof i-pad-sect-upload">
                      <span class="feedTime">{{
                        log?.action_date | date: "MMM d, y"
                      }}</span>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <span class="title olny_two_line_show title-height">{{
                          log?.collab_content_details[0]?.title
                        }}</span>
                      </div>
                    </div>
                    <div class="postedData">
                      <ng-container
                        *ngIf="log?.collab_content_details[0]?.video_indicator == 1"
                      >
                        <img
                          class="constellation_img all_other_img"
                          src="{{
                            log?.collab_content_details[0]?.attachment_path
                              ? log?.collab_content_details[0]?.attachment_path
                              : './assets/media/default/post_default.png'
                          }}"
                          alt=""
                        />
                      </ng-container>
                      <ng-container
                        *ngIf="log?.collab_content_details[0]?.video_indicator == 2 || log?.collab_content_details[0]?.video_indicator == 4"
                      >
                        <img
                          class="constellation_img all_other_img"
                          src="{{
                            log?.collab_content_details[0]?.attachment_path
                              ? log?.collab_content_details[0]?.attachment_path
                              : './assets/media/default/post_default.png'
                          }}"
                          alt=""
                        />
                      </ng-container>

                      <div class="postedDataContent">
                        <p
                          class="olny_three_line_show"
                          [innerHTML]="
                            sanitizer.bypassSecurityTrustHtml(
                              log?.collab_content_details[0]?.description
                            )
                          "
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="meaning_log_count == 0">
                <table class="cursor-default">
                  <tbody>
                    <tr>
                      <td style="padding: 6px !important">
                        <img
                          src="./assets/media/no_data_found.png"
                          style="border-radius: 0px"
                        />
                      </td>
                      <td colspan="2" style="padding: 6px !important">
                        <span
                          >You have not created any Meaning Moments items!</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
