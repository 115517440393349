import { NotificationService } from '../../../../service/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RegisterService } from '../../../../service/register.service';
import { CustomValidators } from './custom-validators';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { message_properties } from '../../../../../environments/message_properties';
import { CityStateCountryService } from '../../../../service/city-state-country.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';
import { AccountsService } from './../../../../service/accounts.service';

@Component({
  selector: 'kt-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('first_name') first_name: ElementRef;
  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;
  @ViewChild('countrySearchInput', { static: true })
  countrySearchInput: ElementRef;
  @ViewChild('citySearchInput', { static: true }) citySearchInput: ElementRef;
  RegisterForm: UntypedFormGroup;

  message: string;

  @ViewChild('captchaElem', { static: false })
  captchaElem: InvisibleReCaptchaComponent;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaResponse?: string;
  public captchaIsReady = false;

  public badge: 'bottomright' | 'bottomleft' | 'inline' = 'inline';
  public type: 'image' | 'audio';
  public theme: 'light' | 'dark' = 'light';

  public recaptcha: any = null;
  saveButton: boolean = false;
  queryParamshandling: any;
  returnUrl: any;
  countryList = [];
  countryCount: number;
  cityList = [];
  cityCount: number;
  countryBox: boolean = false;
  cityBox: boolean = false;
  passwordEntered: boolean = false;
  countrySelected: boolean = false;
  countryId: string = '0';
  countryListArr = [];
  cityListArr = [];
  public siteKey = '';
  showPassword: boolean = false;
  confirmPassword: boolean = false;
  pendo_api: string;
  user_snap: string;
  searchForm: UntypedFormGroup;
  page: number = 1;
  throttle = 300;
  modalScrollDistance = 1;
  modalScrollThrottle = 30;
  subbscriptionCity: Subscription;
  subbscriptionCountry: Subscription;
  logOutSubscription: Subscription;
  submitSubscription: Subscription;
  constructor(
    private register: RegisterService,
    private _cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private toastr: NotificationService,
    private _router: Router,
    private route: ActivatedRoute,
    private cityStateCountry: CityStateCountryService,
    private accountService: AccountsService
  ) {
    this.RegisterForm = this.formBuilder.group(
      {
        first_name: new UntypedFormControl('', [Validators.required]),
        last_name: new UntypedFormControl('', [Validators.required]),
        cityText: new UntypedFormControl('', [Validators.required]),
        countryText: new UntypedFormControl(null, [Validators.required]),
        email: new UntypedFormControl('', [
          Validators.required,
          Validators.email,
        ]),
        mentor_indicator: new UntypedFormControl(''),
        admin_indicator: new UntypedFormControl(''),
        curator_indicator: new UntypedFormControl(''),
        meaningsphere_employee: new UntypedFormControl(''),
        program_director_indicator: new UntypedFormControl(''),
        create_from_admin: new UntypedFormControl(0),
        beta_agreement_indicator: new UntypedFormControl('', [
          Validators.required,
        ]),
        countryId: new UntypedFormControl(''),
        cityId: new UntypedFormControl(''),
        // ------------------------------------------------
        property_name: new UntypedFormControl(''),
        property_value: new UntypedFormControl(''),
        stateId: new UntypedFormControl(''),
        // ------------------------------------------------
        password: new UntypedFormControl(
          '',
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true,
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true,
              }
            ),
            Validators.minLength(8),
          ])
        ),
        confirmpassword: new UntypedFormControl(
          '',
          Validators.compose([Validators.required])
        ),
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator,
      }
    );
    this.searchForm = this.formBuilder.group({
      name: new UntypedFormControl(''),
      countryId: new UntypedFormControl(''),
      page: 1,
    });
  }

  ngOnInit() {
    this.logoutusers();
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    this.memberAdditional();
    this.citylistSearch();
  }

  logoutusers() {
    const postData = {
      refresh: this.accountService.refresh_token,
      token: this.accountService.token,
    };
    if (this.accountService.token) {
      this.logOutSubscription = this.accountService
        .logoutuser(postData)
        .subscribe((data) => {
          localStorage.removeItem('token_refresh_data');
          localStorage.clear();
          location.reload();
        });
    }
  }
  passwordChangeEntered(ev) {
    if (ev.target.value.length > 0) {
      this.passwordEntered = true;
    } else {
      this.passwordEntered = false;
    }
  }
  onSubmit(ngForm: NgForm) {
    this.saveButton = true;
    this.spinner.show();
    return (this.submitSubscription = this.register.OnSubmit(ngForm).subscribe(
      (res) => {
        if (res.code !== 200) {
          this.saveButton = false;
          localStorage.setItem('xyze', btoa(res.email)); // email in localstorage
          this._cdr.detectChanges();
          this.spinner.hide();
          this._router.navigate(['/login/registerverification'], {
            queryParamsHandling: 'preserve',
          });
        } else {
          this.saveButton = false;
          this.message = res.message;
          this.toastr.showError(res.message, '');
          this.spinner.hide();
          this._cdr.detectChanges();
        }
      },
      (error) => {
        this.saveButton = false;
        this.toastr.showError(message_properties.LOGIN_REGISTER_ERROR, '');
        this.spinner.hide();
        this.spinner.hide();
      }
    ));
  }

  memberAdditional() {
    var ret_url = this.returnUrl;
    if (ret_url === '/meaningful-work-survey/lets-get-started') {
      this.RegisterForm.patchValue({
        property_name: 'Take the Survey',
        property_value: ret_url,
      });
    } else if (ret_url === '/meaning-network/my-feed') {
      this.RegisterForm.patchValue({
        property_name: 'Go to Network',
        property_value: ret_url,
      });
    } else if (ret_url === '/discover/search-for-a-guide') {
      this.RegisterForm.patchValue({
        property_name: 'Find a Guide',
        property_value: ret_url,
      });
    } else if (ret_url === '/library/all') {
      this.RegisterForm.patchValue({
        property_name: 'Explore the Library',
        property_value: ret_url,
      });
    }
  }

  ngAfterViewInit(): void {
    this.countrylistSearch();
    this.captchaIsLoaded = true;
    this.first_name.nativeElement.focus();
    this._cdr.detectChanges();
  }

  execute(): void {
    this.captchaElem.execute();
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this._cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this._cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this._cdr.detectChanges();
  }

  handleReady(): void {
    this.captchaIsReady = true;
    this._cdr.detectChanges();
  }

  changeBadge(
    badge: 'bottomright' | 'bottomleft' | 'inline' = 'bottomright'
  ): void {
    this.badge = badge;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert(
        `There is no response from grecaptcha script - try using 'getCurrentResponse' method or subscribe to 'success' event`
      );
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {}

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {
      // alert(currentResponse);
    }
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  countrylistSearch() {
    this.subbscriptionCountry = this.cityStateCountry
      .getAllCountryList()
      .subscribe(
        (response) => {
          this.countryList = response.results;
          this.countryCount = response.count;
          this.siteKey = response.google_captcha;
          this._cdr.detectChanges();
          localStorage.setItem('var1', response.pando_api);
          localStorage.setItem('var2', btoa(response.user_snap));
        },
        (error) => {}
      );
  }

  pushValue(ev?) {
    if (this.cityCount !== this.cityList.length) {
      this.page = this.page + 1;
      this.searchForm.patchValue({
        page: this.page,
      });
      this.callSearchCityFunction();
    }
  }
  callSearchCityFunction(params = 0) {
    if (params === 1) {
      this.page = 1;
      this.searchForm.patchValue({
        page: 1,
      });
      this.cityList = [];
      this.cityBox = false;
    }
    this.subbscriptionCity = this.cityStateCountry
      .getCity(this.searchForm.value)
      .subscribe((response) => {
        this.cityList = [
          ...new Map(
            this.cityList
              .concat(response.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.cityCount = response.count;
        if (this.cityCount > 0) {
          this.cityBox = true;
        } else {
          this.cityBox = false;
        }
        this._cdr.detectChanges();
      });
  }

  setCountry(event?) {
    this.cityBox = false;
    localStorage.setItem('countryId', event[0]['value']);
    this.RegisterForm.patchValue({
      countryText: event[0]['text'],
      countryId: event[0]['value'],
    });
    this.RegisterForm.patchValue({
      cityText: '',
    });
    this.countrySelected = true;
  }

  setCity(name, stateId, id) {
    this.cityBox = false;
    this.RegisterForm.patchValue({
      cityText: name,
      stateId: stateId,
      cityId: id,
    });
  }

  citylistSearch() {
    fromEvent(this.citySearchInput.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        // if character length greater then 2
        filter((res) => res.length > 2),
        // Time in milliseconds between key events
        debounceTime(500),
        // If previous query is diffent from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((sendData: string) => {
        this.searchForm.patchValue({
          name: sendData,
          countryId: localStorage.getItem('countryId'),
        });
        this.callSearchCityFunction(1);
      });
  }

  checkUncheck(event) {
    if (!event.checked) {
      this.RegisterForm.patchValue({
        beta_agreement_indicator: '',
      });
    }
  }
  ngOnDestroy() {
    if (this.subbscriptionCity) {
      this.subbscriptionCity.unsubscribe();
    }
    if (this.subbscriptionCountry) {
      this.subbscriptionCountry.unsubscribe();
    }
    if (this.logOutSubscription) {
      this.logOutSubscription.unsubscribe();
    }
    if (this.submitSubscription) {
      this.submitSubscription.unsubscribe();
    }
  }
}
