<div class="mat-dialog-popup">
  <table width="100%" class="mb-3">
    <tr>
      <td>
        <h1 class="cmn_title_top">{{ full_name }}'s Meaning Moments</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <mat-dialog-content class="meaninglog-content">
    <div class="fixed_cntent my-3">
      <div
        class="search-results imagePosts scrollHeigh scroll_class_list"
        infinite-scroll
        [infiniteScrollDistance]="modalScrollDistance"
        [infiniteScrollThrottle]="modalScrollThrottle"
        [scrollWindow]="false"
        (scrolled)="pushValue($event)">
        <div class="row">
          <ng-container *ngIf="count > 0">
            <div *ngFor="let log of meaningLog" class="col-md-6 col-lg-4">
              <div
                (click)="
                  collabContentDetails(
                    log.collab_content_details[0].collab_content_id,
                    log.bucket_type,
                    log.collab_content_details[0].title,
                    log.collab_content_details[0].description,
                    log.collab_content_details[0]?.video_indicator,
                    log.collab_content_details[0].attachment_path
                  )
                "
                class="card archive_card front_card_background_clr constellation cursorp"
              >
              <div class="feedBoxHeader">
                <div class="feedBoxIcon">
                 
                  <img class="profile_feedheader_img profile_img"
                        [src]="
                          log.collab_content_details[0].picture_path
                            ? log.collab_content_details[0].picture_path
                            : './assets/media/users/default.png'
                        "
                      />
                    
                      <div class="userDetail">
                        <span class="name">{{ log.collab_content_details[0].full_name }}
                        </span>
                        <span class="feedTime mt-0">{{
                          log.action_date | date: "MMM d, y"
                        }}</span>
                      </div>
                    </div>
                  </div>
                <div class="row">
                  <div class="col-sm-12">
                    <span class="title olny_two_line_show title-height">{{
                      log.collab_content_details[0].title
                    }}</span>
                  </div>
                </div>
                <div class="postedData">
                  <ng-container
                    *ngIf="log.collab_content_details[0].video_indicator == 1"
                  >
                    <img
                      class="constellation_img all_other_img"
                      src="{{
                        log.collab_content_details[0].attachment_path
                          ? log.collab_content_details[0].attachment_path
                          : './assets/media/default/post_default.png'
                      }}"
                      alt=""
                      id="constellation_img_height_{{ i }}"
                    />
                  </ng-container>
                  <ng-container
                    *ngIf="log.collab_content_details[0].video_indicator == 0"
                  >
                    <vg-player class="of-contain">
                      <video
                        [vgMedia]="media"
                        #media
                        id="singleVideo"
                        preload="auto"
                        crossorigin
                      >
                        <source
                          src="{{ log.collab_content_details[0].attachment_path }}"
                          type="video/mp4"
                        />
                        <source
                          src="{{ log.collab_content_details[0].attachment_path }}"
                          type="video/ogg"
                        />
                        <source
                          src="{{ log.collab_content_details[0].attachment_path }}"
                          type="video/webm"
                        />
                      </video>
                    </vg-player>
                  </ng-container>
                  <div class="postedDataContent">
                    <p
                      class="olny_three_line_show"
                      [innerHTML]="
                        sanitizer.bypassSecurityTrustHtml(
                          log.collab_content_details[0].description
                        )
                      "
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="count == 0"
            ><span>You have not created any Meaning Log items!</span>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
