import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewComponent } from './overview/overview.component';
import { RouterModule, CanActivate, Routes } from '@angular/router';
import { RouteGuard } from '../../../guard/route.guard';
import { AddRequestCircleComponent } from './add-request-circle/add-request-circle.component';
import { UserCircleFeedDetailsPopupComponent } from './user-circle-feed-details-popup/user-circle-feed-details-popup.component';
import { UserCircleSessionDetailsPopupComponent } from './user-circle-session-details-popup/user-circle-session-details-popup.component';
import { UserCircleCreateMessagePopupComponent } from './user-circle-create-message-popup/user-circle-create-message-popup.component';
import { UserCircleCreateActionInsightPopupComponent } from './user-circle-create-action-insight-popup/user-circle-create-action-insight-popup.component';
import { UserCircleWithdrawPopupComponent } from './user-circle-withdraw-popup/user-circle-withdraw-popup.component';
import { UserCircleAddSessionComponent } from './user-circle-add-session/user-circle-add-session.component';
import { UserCircleFeedEditComponent } from './user-circle-feed-edit/user-circle-feed-edit.component';
import { InsightActionSummaryInCircleTabComponent } from './insight-action-summary-in-circle-tab/insight-action-summary-in-circle-tab.component';
import { SharedModule } from '../../shared/shared.module';
import { SingleSessionPopupHostListComponent } from './user-circle-session-details-popup/single-session-popup-host-list/single-session-popup-host-list.component';
import { SingleSessionMessageListComponent } from './user-circle-session-details-popup/single-session-message-list/single-session-message-list.component';


@NgModule({
  declarations: [
    OverviewComponent,
    AddRequestCircleComponent,
    UserCircleFeedDetailsPopupComponent,
    UserCircleSessionDetailsPopupComponent,
    UserCircleCreateMessagePopupComponent,
    UserCircleCreateActionInsightPopupComponent,
    UserCircleWithdrawPopupComponent,
    UserCircleAddSessionComponent,
    UserCircleFeedEditComponent,
    InsightActionSummaryInCircleTabComponent,
    SingleSessionPopupHostListComponent,
    SingleSessionMessageListComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
      {
        path: 'overview',
        component: OverviewComponent,
      },
      {
        path: 'circles',
        loadChildren: () =>
          import('./user-circles/user-circles.module').then(
            (m) => m.UserCirclesModule
          ),
        canActivate: [RouteGuard],
      },
      {
        path: 'my-circles',
        loadChildren: () =>
          import(
            './user-my-circle-with-all-feed-and-all-session/user-my-circle-with-all-feed-and-all-session.module'
          ).then((m) => m.UserMyCircleWithAllFeedAndAllSessionModule),
        canActivate: [RouteGuard],
      },
      {
        path: 'my-circles/:circleId',
        loadChildren: () =>
          import(
            './user-single-circle-feed-and-session/user-single-circle-feed-and-session.module'
          ).then((m) => m.UserSingleCircleFeedAndSessionModule),
        canActivate: [RouteGuard],
      },
      {
        path: 'my-circles',
        loadChildren: () =>
          import(
            './user-my-circle-with-all-feed-and-all-session/user-my-circle-with-all-feed-and-all-session.module'
          ).then((m) => m.UserMyCircleWithAllFeedAndAllSessionModule),
        canActivate: [RouteGuard],
      },
    ]),
  ],
})
export class MeaningCircleModule { }
