<kt-spinner></kt-spinner>
<mat-card class="archive_main_card full_screen_background_card_height">
  <h1>Search Explorers</h1>
  <form (keydown.tab)="$event.stopPropagation()" [formGroup]="explorerForm">
    <div class="d-flex justify-content-between align-items-center pt-15">
      <div class="col-sm-10 col-md-8 col-lg-6 pos-relative search-style1 p-0">
        <input formControlName="fullName" type="textarea" placeholder="Search"
          class="my_search_input mb-5 input-width-change" (keyup.enter)="searchExplorerList(1)" />
        <div class="clear icons-sets-new">
          <i class="fa fa-filter posi-dots fa-one" aria-hidden="true" title="Filter"
            [matMenuTriggerFor]="menu_search_form"></i>
          <mat-menu #menu_search_form="matMenu" class="search-menunw">
            <div class="mat-menu-search" (click)="$event.stopPropagation()">
              <div class="row">
                <div class="col-xl-6 col-md-6" (click)="$event.stopPropagation()">
                  <div class="memg-btn-go">
                    <mat-checkbox class="mem-radio-btn" (click)="$event.stopPropagation()" formControlName="keyCheck">
                      Keyword
                    </mat-checkbox>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6">
                  <input class="keyword-tab mobile-pad mat-form-field-autofill-control memb-select"
                  (click)="$event.stopPropagation()" type="text" maxlength="25" formControlName="keyword"
                  [attr.disabled]="
                    !explorerForm.get('keyCheck').value ? true : null
                  " />
                          </div>
              </div>
              <div class="row">
                <div class="col-sm-6" (click)="$event.stopPropagation()">
                  <div class="memg-btn-go">
                    <mat-checkbox class="mem-radio-btn" (click)="$event.stopPropagation()" formControlName="eduCheck">
                      University
                    </mat-checkbox>
                  </div>
                </div>
                <div class="col-sm-6">
                  <input class="mobile-pad mat-form-field-autofill-control memb-select"
                    (click)="$event.stopPropagation()" type="text" maxlength="25" formControlName="edu" [attr.disabled]="
                      !explorerForm.get('eduCheck').value ? true : null
                    " />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="memg-btn-go">
                    <mat-checkbox class="mem-radio-btn" (click)="$event.stopPropagation()" formControlName="wtcaCheck">
                      Topic
                    </mat-checkbox>
                  </div>
                </div>
                <div class="col-sm-6">
                  <select class="mobile-pad mat-form-field-autofill-control memb-select"
                    (click)="$event.stopPropagation()" formControlName="wtca"
                    [attr.disabled]="!explorerForm.get('wtcaCheck').value ? true : null">
                    <option disabled selected value style="display: none"></option>
                    <option [value]="item.id" *ngFor="let item of topics">
                      {{ item.alternate_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6" (click)="$event.stopPropagation()">
                  <div class="memg-btn-go">
                    <mat-checkbox class="mem-radio-btn" (click)="$event.stopPropagation()"
                      formControlName="locationCheck">
                      Country
                    </mat-checkbox>
                  </div>
                </div>
                <div class="col-sm-6 col-sm-6 col-sm-6">

                  <kt-search-dropdown class="mt-0 mobile-pad mat-form-field-autofill-control memb-select"
                    (sendbackresponse)="setCountry($event)" [countrySelected]="true" [fieldType]="'country'"
                    [fieldLable]="''" [fieldLableDisplay]="false"
                    [disabled]="!explorerForm.get('locationCheck').value ? true : null"></kt-search-dropdown>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="memg-btn-go mt-5n">
                    <mat-checkbox class="mem-radio-btn" (click)="$event.stopPropagation()"
                      formControlName="companyCheck">
                      Organization
                    </mat-checkbox>
                  </div>
                </div>
                <div class="col-sm-6">
                  <input class="mt-0 mobile-pad mat-form-field-autofill-control memb-select"
                  (click)="$event.stopPropagation()" type="text" maxlength="25" formControlName="company"
                  [attr.disabled]="
                    !explorerForm.get('companyCheck').value ? true : null
                  " />
              </div>
              </div>

              <div align="right" class="memg-btn-go mt-2">
                <table>
                  <tr>
                    <td>
                      <img src="/assets/media/icon/Refresh@2xWhite.png" class="img25 cursorp"
                        (click)="resetForm(); $event.stopPropagation()" />
                    </td>
                    <td>
                      <button class="btn begin_quiz" (click)="searchExplorerList(1)">GO</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </mat-menu>
          <i class="fa fa-search posi-dots fa-two" aria-hidden="true" (click)="searchExplorerList(1)"></i>
        </div>
      </div>
    </div>
  </form>
  <hr />
  <div class="search-results card2-style-height-with-search-hr-heading" infinite-scroll
    [infiniteScrollDistance]="modalScrollDistance" [infiniteScrollThrottle]="modalScrollThrottle" [scrollWindow]="false"
    (scrolled)="pushValue($event)">
    <ng-container *ngIf="count > 0">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-3 card-explorer" *ngFor="let data of usersdata">
          <div class="card archive_card front_card_background_clr" (click)="
          viewProfile(
            data.id,
            data.member_table[0].first_name,
            data.member_table[0].last_name,
            returnImage(data.member_table[0].picture_path)
          )
        ">
            <div class="img_section_mirror text-center cursorp">
              <img [src]="returnImage(data.member_table[0].picture_path)" alt="" class=" cursorp archive_img {{
                  data.member_connect_count == 1 ? 'archive_img_border' : ''
                }} curator w-124-pm" />
            </div>

            <div class="title">
              <ul class="dotes-member-ul role cursorp">
                <li *ngIf="data.mentor_indicator == 1">Guide</li>
              </ul>
              <div class="member_card_name text_on_image mt-3 cursorp text-left">

                <h1> {{ data.member_table[0].first_name }}</h1>
                <h2 class="fs-18">
                  {{ data.member_table[0].last_name }}</h2>

                <h3 *ngIf="data.member_address_table.length > 0" class="tit mt-2">
                  {{
                  data.member_address_table[0].city &&
                  data.member_address_table.length > 0
                  ? data.member_address_table[0].city
                  : "--"
                  }},
                  {{
                  data.member_address_table[0].country &&
                  data.member_address_table.length > 0
                  ? data.member_address_table[0].country
                  : "--"
                  }}
                </h3>
                <h3 *ngIf="data.member_address_table.length == 0" class="tit mt-2">
                  ---- ----
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="count == 0">
      <table>
        <tbody>
          <tr>
            <td class="p-2">
              <img src="./assets/media/no_data_found.png" class="br-0" />
            </td>
            <td colspan="2" class="p-2">
              No explorers found!
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
  <!-- </cdk-virtual-scroll-viewport> -->
</mat-card>