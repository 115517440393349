<kt-spinner></kt-spinner>
<div class="mat-dialog-popup sendmessagePop">
  <table width="100%">
    <tr>
      <td>
        <h1 class="cmn_title_top">Send Message</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <mat-card-content>
    <form
      class="sendmessageForm"
      [formGroup]="sendmessageForm"
      novalidate
      autocomplete="off"
    >
    <div class="height-css">
      <div class="row">
        <div class="form-group col-sm-12">
          <ng-container *ngIf="is_admin && clicked_from == 1009">
            <div class="row">
              <div class="col-md-12">
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="radio_select"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <mat-radio-button value="1" (click)="selectMessageType(1)"
                        ><small>Broadcast Message</small></mat-radio-button
                      >
                    </div>
                    <div class="col-md-6">
                      <mat-radio-button value="2" (click)="selectMessageType(2)"
                        ><small>To Explorer</small></mat-radio-button
                      >
                    </div>
                  </div>
                </mat-radio-group>
              </div>
            </div>

            <div
              class="row mt-5"
              *ngIf="sendmessageForm.get('radio_select').value == 1"
            >
              <div class="col-md-6 col-lg-3">
                <mat-checkbox
                  formControlName="all_admin_indicator"
                  [value]="1"
                  (change)="brodCastMessageSelect($event)"
                >
                  <small> All Admin</small>
                </mat-checkbox>
              </div>
              <div class="col-md-6 col-lg-3">
                <mat-checkbox
                  formControlName="all_curator_indicator"
                  [value]="2"
                  (change)="brodCastMessageSelect($event)"
                >
                  <small> All Curator</small>
                </mat-checkbox>
              </div>
              <div class="col-md-6 col-lg-3">
                <mat-checkbox
                  formControlName="all_guide_indicator"
                  [value]="3"
                  (change)="brodCastMessageSelect($event)"
                >
                  <small> All Guide</small>
                </mat-checkbox>
              </div>
              <div class="col-md-6 col-lg-3">
                <mat-checkbox
                  formControlName="all_explorer_indicator"
                  [value]="4"
                  (change)="brodCastMessageSelect($event)"
                >
                  <small> All Explorer</small>
                </mat-checkbox>
              </div>
            </div>
          </ng-container>

         
          <ng-container *ngIf="clicked_from == 1008">
            <small
              ><img
                src="{{ profile_picture }}"
                class="img40 br-50"
              />&nbsp;&nbsp;{{ full_name }}</small
            >
            <br />
            <input
              type="hidden"
              value="mentee_id"
              formControlName="receiving_user"
            />
          </ng-container>

          <div class="row">
            <div class="col-md-12">
              <ng-container
                *ngIf="
                  (clicked_from !== 1008 &&
                    is_admin &&
                    sendmessageForm.get('radio_select').value == 2) ||
                  clicked_from == 2474
                "
              >
                <!-- <ng-select
                  formControlName="receiving_user"
                  class="color-send-message"
                  placeholder="Message To *"
                  multiple="true"
                  maxSelectedItems="1"
                >
                  <ng-option [value]="item.id" *ngFor="let item of usersdata">
                    <img
                      src="{{
                        item.image
                          ? item.image
                          : './assets/media/users/default.jpg'
                      }}"
                      class="img20 br-50"
                    />
                    {{ item.text }}
                  </ng-option>
                </ng-select> -->
                <label>Mesaage To *</label>
                <div class="d-flex justify-content-between align-items-end">
                <div class="listofdata">
                  <div class="suggested" *ngFor="let item of suggestedMessagedata">
                    <i
                      class="fa fa-close"
                      (click)="
                        deleteSuggestedData(item.id, false, 'messageto')
                      "
                      >x</i
                    >
                    <img
                    src="{{
                      item.member_table[0].picture_path
                        ? item.member_table[0].picture_path
                        : './assets/media/users/default.jpg'
                    }}"
                    class="img20 br-50"
                  />&nbsp;&nbsp;
                  {{ item.member_table[0].full_name }}
                    <!-- </ng-container> -->
                  </div>
                </div>
                <span
                  mat-button
                  class="p-0 host-add-button"
                  (click)="openMessageToData('messageto')"
                >
                  <a class="btn begin_quiz">+ Add</a>
                </span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ng-container *ngIf="clicked_from == 1007">
                <!-- <ng-select
                  formControlName="receiving_user"
                  class="color-send-message"
                  placeholder="Message To *"
                  multiple="true"
                  maxSelectedItems="1"
                >
                  <ng-option [value]="item.id" *ngFor="let item of usersdata">
                    <img
                      src="{{
                        item.image
                          ? item.image
                          : './assets/media/users/default.jpg'
                      }}"
                      class="img20 br-50"
                    />
                    {{ item.text }}
                  </ng-option>
                </ng-select> -->
                <label>Mesaage To *</label>
                <div class="d-flex justify-content-between align-items-end">
                <div class="listofdata">
                  <div class="suggested" *ngFor="let item of suggestedMessagedata">
                    <i
                      class="fa fa-close"
                      (click)="
                        deleteSuggestedData(item.id, false, 'messageto')
                      "
                      >x</i
                    >
                    <img
                    src="{{
                      item.member_table[0].picture_path
                        ? item.member_table[0].picture_path
                        : './assets/media/users/default.jpg'
                    }}"
                    class="img20 br-50"
                  />&nbsp;&nbsp;
                  {{ item.member_table[0].full_name }}
                    <!-- </ng-container> -->
                  </div>
                </div>
                <span
                  mat-button
                  class="p-0 host-add-button"
                  (click)="openMessageToData('messageto')"
                >
                  <a class="btn begin_quiz">+ Add</a>
                </span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ng-container *ngIf="clicked_from == 1009 && !is_admin">
                <!-- <ng-select
                  formControlName="receiving_user"
                  class="color-send-message"
                  placeholder="Message To *"
                  multiple="true"
                  maxSelectedItems="1"
                >
                  <ng-option [value]="item.id" *ngFor="let item of usersdata">
                    <img
                      src="{{
                        item.image
                          ? item.image
                          : './assets/media/users/default.jpg'
                      }}"
                      class="img20 br-20"
                    />
                    {{ item.text }}
                  </ng-option>
                </ng-select> -->
                <label>Mesaage To *</label>
                <div class="d-flex justify-content-between align-items-end">
                <div class="listofdata">
                  <div class="suggested" *ngFor="let item of suggestedMessagedata">
                    <i
                      class="fa fa-close"
                      (click)="
                        deleteSuggestedData(item.id, false, 'messageto')
                      "
                      >x</i
                    >
                    <img
                    src="{{
                      item.member_table[0].picture_path
                        ? item.member_table[0].picture_path
                        : './assets/media/users/default.jpg'
                    }}"
                    class="img20 br-50"
                  />&nbsp;&nbsp;
                  {{ item.member_table[0].full_name }}
                    <!-- </ng-container> -->
                  </div>
                </div>
                <span
                  mat-button
                  class="p-0 host-add-button"
                  (click)="openMessageToData('messageto')"
                >
                  <a class="btn begin_quiz">+ Add</a>
                </span>
                </div>
              </ng-container>
            </div>
          </div>

          <mat-form-field class="example-full-width w-100">
            <mat-label>Message</mat-label>
            <textarea
              matInput
              formControlName="message"
              rows="8"
              oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.\-_+()@!?#$% ]|^ /g,'')"
            ></textarea>
          </mat-form-field>
        </div>
      </div></div>
      <hr />
      <div class="row mt-3">
        <div class="form-group col-sm-12 tr m-0">
          <button
            [disabled]="!sendmessageForm.valid || saveButton"
            class="btn begin_quiz ml-2 mt-1"
            type="button"
            (click)="sendMessage(sendmessageForm.value)"
          >
            Send
          </button>

          <button
            mat-dialog-close
            class="btn begin_quiz mt-1 ml-2 button-change-scroll canceled_btn"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</div>
