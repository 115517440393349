import { DomSanitizer } from '@angular/platform-browser';
import { environment } from './../../../../../environments/environment';
import { MeaningConstellationService } from '../../../../service/meaning-constellation.service';
import { MeaningLogAndTreasurePopupComponent } from './../meaning-log-and-treasure-popup/meaning-log-and-treasure-popup.component';
import { MeaningFeedsService } from '../../../../service/meaning-feeds.service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MeaningNetworkService } from '../../../../service/meaning-network.service';

import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AddMeaningLogComponent } from '../add-meaning-log/add-meaning-log.component';
import { CuratorPageService } from '../../../../service/curator-page.service';
import { Subscription } from 'rxjs';
import { AccountsService } from '../../../../service/accounts.service';

@Component({
  selector: 'kt-meaning-log',
  templateUrl: './meaning-log.component.html',
  styleUrls: ['./meaning-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeaningLogComponent implements OnInit, OnDestroy {
  throttle = 300;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;

  writeAPostForm: boolean = false;
  config = {
    placeholder: 'Enter a brief description',
    tabsize: 2,
    height: 150,
    toolbar: [],
    disableDragAndDrop: true,
    disableResizeEditor: true,
    fontsize: ['16'],
    fontNames: ['Lato'],
    lineHeights: ['0.2'],
    shortcuts: false,
    codeviewFilter: true,
    codeviewIframeFilter: true,
    callbacks: {
      onPaste: function (e) {
        var bufferText = (e.originalEvent || e).clipboardData.getData('Text');
        e.preventDefault();
        document.execCommand('insertText', true, bufferText);
      },
    },
  };

  profileData: {};
  ecount: number;
  emcount: number;
  vcount: number;
  adcount: number;
  full_name: any;
  memberid: any;
  shwUpload: boolean;
  message: any;
  changePasswordForm: UntypedFormGroup;
  memberId: string;
  first_name: string;
  last_name: string;
  adminIndicator: boolean = false;
  topics: Array<any>;
  fileToUpload: File;
  profile_path: any;
  meaningLog = [];
  myTreasure = [];
  myBookmark = [];
  ccForm: UntypedFormGroup;
  loggedInUserFullName: string;
  city: string;
  state: string;
  country: string;
  loggedInUser: number;

  imageUrl: File;
  videoUrl: File;
  linkUrl: string;
  vedio_path: any;
  image_path: any;
  enableUrl: boolean = false;
  video_preview: boolean = false;
  link_preview: boolean = false;
  image_preview: boolean = false;
  WhatMatters: boolean = false;
  meaningTreasures: boolean = false;
  link_preview_data: any;
  loggedInUserImage: string;
  mentor_indicator: boolean = false;
  topic_count: number;
  videoSize: number;
  imageSize: number;
  saveButton: boolean = false;
  meaning_log_count: number;
  memberAddressData = [];
  page: number = 2;
  subscription: Subscription;
  topicSubscription: Subscription;
  profileSubscription: Subscription;

  myMeaningLogToolTip =
    'The Meaning Moment tag is for posts in which you share a specific moment or experience from your life that has impacted your perspective on meaningful work.';

  constructor(
    private meaning_network_service: MeaningNetworkService,
    private meaningConstellation: MeaningConstellationService,
    private spinner: NgxSpinnerService,
    private cdRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private aniDialog: MatDialog,
    public sanitizer: DomSanitizer,
    private curatorService: CuratorPageService,
    private accountService: AccountsService
  ) {
    this.videoSize = environment.videoSize;
    this.imageSize = environment.imageSize;
  }

  async findMemberId() {
    await this.route.queryParams.subscribe((params) => {
      return (this.memberId = params['member']);
    });
  }
  ngOnInit() {
    this.loggedInUser = this.accountService.muser;
    this.loggedInUserFullName = this.accountService.full_name;
    this.adminIndicator = this.accountService.admin_indicator ? true : false;

    this.memberid = this.accountService.muser;

    this.ccForm = this.formBuilder.group({
      member: new UntypedFormControl(this.loggedInUser),
      bucket_type: new UntypedFormControl(2564),
      collab_content_category: new UntypedFormControl(2557),
      loggedInUser: new UntypedFormControl(this.loggedInUser),
      limit: new UntypedFormControl(0),
      page: 1,
    });
    this.meaningConstellation.refreshneededMeaningMomentsList.subscribe(
      (data) => {
        this.postMeaningLogs(1);
      }
    );
    this.curatorService.refreshneeded.subscribe((data) => {
      this.postMeaningLogs(1);
    });
    this.postMeaningLogs();
    this.getProfileWithAddress();
    this.getTopics();
  }

  getProfileWithAddress() {
    this.profileSubscription = this.meaning_network_service
      .getProfileDetails(this.memberid)
      .subscribe((data) => {
        this.city = data.city;
        this.country = data.country;
        this.mentor_indicator = Boolean(data.mentor_indicator);
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.full_name = data.full_name;
        this.profile_path = data.profile_path
          ? data.profile_path
          : './assets/media/users/default.png';
        localStorage.setItem('ufullname', btoa(this.full_name));
        localStorage.setItem(
          'image',
          data.profile_path !== null ? data.profile_path : '0'
        );
        this.cdRef.detectChanges();
      });
  }
  pushValue(ev?) {
    if (this.meaning_log_count !== this.meaningLog.length) {
      this.ccForm.patchValue({
        page: this.page,
      });
      this.postMeaningLogs();
      this.page++;
    }
  }
  postMeaningLogs(params = 0) {
    if (params === 1) {
      this.page = 2;
      this.ccForm.patchValue({
        page: 1,
      });
      this.meaningLog = [];
    }
    const data = this.ccForm.value;
    this.spinner.show();
    this.subscription = this.meaning_network_service
      .postAllTreasureLogsOfUser(data)
      .subscribe((data) => {
        this.meaningLog = [
          ...new Map(
            this.meaningLog
              .concat(data.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.meaning_log_count = data.count;
        this.spinner.hide();
        this.cdRef.detectChanges();
      });
  }

  collabContentDetails(
    collabContentId,
    member_in_bucket,
    bucket_type,
    title,
    description,
    video_indicator,
    attachment_path,
    references,
    collab_content_category
  ) {
    this.aniDialog.open(MeaningLogAndTreasurePopupComponent, {
      disableClose: true,
      width: '80%',
      data: {
        collabContentId: collabContentId,
        member_in_bucket: member_in_bucket,
        bucket_type: bucket_type,
        clickFrom: 2564,
        title: title,
        description: description,
        video_indicator: video_indicator,
        attachment_path: attachment_path,
        references: references,
        collab_content_category: collab_content_category,
      },
    });
  }

  getTopics() {
    this.topicSubscription = this.meaning_network_service
      .getWhatICareAbout(this.memberid)
      .subscribe((data) => {
        this.topic_count = data.count;
        this.topics = data.results;
      });
  }

  changeDesc(event) {
    if (event !== undefined) {
      this.ccForm.patchValue({
        postDetail: event,
      });
    }
  }
  toggleWhatMatters() {
    this.WhatMatters = !this.WhatMatters;
    this.meaningTreasures = false;
  }
  togglemeaningTreasures() {
    this.WhatMatters = false;
    this.meaningTreasures = !this.meaningTreasures;
  }

  openAddLogDialog() {
    this.aniDialog.open(AddMeaningLogComponent, {
      disableClose: true,
      width: '50%',
    });
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.topicSubscription) {
      this.topicSubscription.unsubscribe();
    }
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }
}
