import { ChangeDateInTimezonePipe } from './../../../pipes/change-date-in-timezone.pipe';
import { ChangeTimeInTimezonePipe } from './../../../pipes/change-time-in-timezone.pipe';
import { RouteGuard } from '../../../guard/route.guard';
// import { DocumentsComponent } from './my-guided-explorers/shared-space/documents/documents.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BecomeAGuideComponent } from './become-a-guide/become-a-guide.component';
import { RequestToBecomeAGuidComponent } from './request-to-become-a-guid/request-to-become-a-guid.component';

import { SearchForAGuideComponent } from './search-for-a-guide/search-for-a-guide.component';
import { SendIntroSessionRequestComponent } from './send-intro-session-request/send-intro-session-request.component';
import { SendRequestToBeMyGuideComponent } from './send-request-to-be-my-guide/send-request-to-be-my-guide.component';
import { MyGuidesComponent } from './my-guides/my-guides.component';
import { EndorseGuideComponent } from './endorse-guide/endorse-guide.component';
import { MatTabsModule } from '@angular/material/tabs';
import { EndorsementDialogComponent } from './endorsement-dialog/endorsement-dialog.component';
import { MyGuidedExplorersComponent } from './my-guided-explorers/my-guided-explorers.component';
import { DiscontinueGuideComponent } from './discontinue-guide/discontinue-guide.component';
import { UpdateRequestedSessionComponent } from './update-requested-session/update-requested-session.component';
import { SharedSpaceComponent } from './my-guided-explorers/shared-space/shared-space.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SetupDiscussionComponent } from './setup-discussion/setup-discussion.component';
import { SessionInsightsComponent } from './my-guided-explorers/session-insights/session-insights.component';
import { DiscussionsComponent } from './my-guided-explorers/shared-space/discussions/discussions.component';
import { CurrntExplorerComponent } from './my-guided-explorers/currnt-explorer/currnt-explorer.component';
import { PastExplorerComponent } from './my-guided-explorers/past-explorer/past-explorer.component';
import { RequestsComponent } from './my-guided-explorers/requests/requests.component';
import { GuideReportsComponent } from './guide-reports/guide-reports.component';
import { EndorseDiscussionComponent } from './endorse-guide/endorse-discussion/endorse-discussion.component';
import { EndorseDocumentsComponent } from './endorse-guide/endorse-documents/endorse-documents.component';
import { PrivateNoteComponent } from './private-note/private-note.component';
import { MyGuidesReadRequestsMessageComponent } from './my-guides-read-requests-message/my-guides-read-requests-message.component';
import { PrivateNotesListComponent } from './private-notes-list/private-notes-list.component';
import { PurchaseGuideTimeComponent } from './endorse-guide/purchase-guide-time/purchase-guide-time.component';
import { YourEndorseDiscussionListComponent } from './endorse-guide/your-endorse-discussion-list/your-endorse-discussion-list.component';
import { SetupDiscussionFromRequestComponent } from './setup-discussion-from-request/setup-discussion-from-request.component';
import { SetupDiscussionFromGuideComponent } from './setup-discussion-from-guide/setup-discussion-from-guide.component';
import { EventCancellationRequestComponent } from './event-cancellation-request/event-cancellation-request.component';
import { MarkedAsCompletedComponent } from './marked-as-completed/marked-as-completed.component';
import { SetupDiscussionViewComponent } from './setup-discussion-view/setup-discussion-view.component';
import { MyGuidedExplorerReportsComponent } from './endorse-guide/my-guided-explorer-reports/my-guided-explorer-reports.component';
import { ResheduleDiscussionComponent } from './reshedule-discussion/reshedule-discussion.component';
import { SharedModule } from '../../shared/shared.module';
import { CurrentMyGuideComponent } from './my-guides/current-my-guide/current-my-guide.component';
import { PastMyGuideComponent } from './my-guides/past-my-guide/past-my-guide.component';
import { SentRequestComponent } from './my-guides/sent-request/sent-request.component';

@NgModule({
  declarations: [
    BecomeAGuideComponent,
    RequestToBecomeAGuidComponent,
    SearchForAGuideComponent,
    SendIntroSessionRequestComponent,
    SendRequestToBeMyGuideComponent,
    MyGuidesComponent,
    EndorseGuideComponent,
    EndorsementDialogComponent,
    MyGuidedExplorersComponent,
    DiscontinueGuideComponent,
    UpdateRequestedSessionComponent,
    SharedSpaceComponent,
    SetupDiscussionComponent,
    SessionInsightsComponent,
    DiscussionsComponent,
    // DocumentsComponent,
    CurrntExplorerComponent,
    PastExplorerComponent,
    RequestsComponent,
    GuideReportsComponent,
    EndorseDiscussionComponent,
    EndorseDocumentsComponent,
    PrivateNoteComponent,
    MyGuidesReadRequestsMessageComponent,
    PrivateNotesListComponent,
    PurchaseGuideTimeComponent,
    YourEndorseDiscussionListComponent,
    SetupDiscussionFromRequestComponent,
    SetupDiscussionFromGuideComponent,
    EventCancellationRequestComponent,
    ChangeTimeInTimezonePipe,
    ChangeDateInTimezonePipe,
    MarkedAsCompletedComponent,
    SetupDiscussionViewComponent,
    MyGuidedExplorerReportsComponent,
    ResheduleDiscussionComponent,
    CurrentMyGuideComponent,
    PastMyGuideComponent,
    SentRequestComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: 'search-for-a-guide',
        component: SearchForAGuideComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'my-guides',
        component: MyGuidesComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'my-guided-explorers',
        component: MyGuidedExplorersComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'my-guided-explorers/session-insights',
        component: SessionInsightsComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'my-guided-explorers/shared-space/:params',
        component: SharedSpaceComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'my-guide/shared-space/:params',
        component: EndorseGuideComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'guide-reports',
        component: GuideReportsComponent,
        canActivate: [RouteGuard],
      },
      {
        path: 'my-guided-explorer-reports',
        component: MyGuidedExplorerReportsComponent,
        canActivate: [RouteGuard],
      },
    ]),
    MatTabsModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
  ],
})
export class MyMentorModule {}
