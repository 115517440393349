import { NotificationService } from '../../../../service/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { MentorService } from '../../../../service/mentor.service';
import { message_properties } from '../../../../../environments/message_properties';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-endorsement-dialog',
  templateUrl: './endorsement-dialog.component.html',
  styleUrls: ['./endorsement-dialog.component.scss'],
})
export class EndorsementDialogComponent implements OnInit, OnDestroy {
  endorsementForm: UntypedFormGroup;
  mentorId: number;
  fullName: string;
  last_name: string;
  first_name: string;
  memberId: number;
  saveButton: boolean = false;
  endorseSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<EndorsementDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private mentorService: MentorService,
    private toastr: NotificationService
  ) {
    this.mentorId = data.mentorId;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.memberId = data.memberId;
  }

  ngOnInit(): void {
    this.endorsementForm = this.formBuilder.group({
      remarks: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(2048),
      ]),
      endorsing_member: this.mentorId,
      status: 1,
      visibility: 1,
      expertise: 0,
      creation_date: '',
    });
  }
  endorsement(ngForm) {
    this.endorseSubscription = this.mentorService
      .endorseGuide(ngForm.value, 0, this.memberId)
      .subscribe(
        (data) => {
          if (data.code === 201) {
            this.saveButton = false;
            this.endorsementForm.patchValue({ remarks: '' });
            this.dialogRef.close();
            this.toastr.showSuccess(
              message_properties.ENDORSEMENT_DIALOG_SUCCESS,
              this.fullName
            );
          } else if (data.code === 204) {
            this.toastr.showError(data.message, '');
          }
        },
        (error) =>
          this.toastr.showError(message_properties.ENDORSEMENT_DIALOG_ERROR, '')
      );
  }
  ngOnDestroy(): void {
    if (this.endorseSubscription) {
      this.endorseSubscription.unsubscribe();
    }
  }
}
