<div class="col-sm-12">
  <!-- <div [innerHTML]="report_6_body"></div> -->
  <div class="col-sm-12 mt-30">
    <div class="row">
      <div class="section-title">
        <h1 class="mb-20">Your Healthy Tensions</h1>
      </div>
      <p class="section-text">
        Finding meaning in the four pathways causes natural tensions for all of us. That’s because we have an inherent
        need to focus on ourselves <i>and</i> others. The same goes for the tension created between making time to just “be”
        <i>and</i> insuring things get done.
      </p>
      <p class="section-text">
        These tensions push and pull us in opposite directions. Although there’s no need for perfect harmony, you need
        to maintain a healthy balance. When tensions become persistently ‘unhealthy’ there’s a risk of an overall loss
        of meaning or of personal well-being.
      </p>
      <p class="section-text">
        Your aggregated responses to the survey items in the graph below, give you a sense whether your current
        “Being-Doing” and “Self-Others” tensions are healthy.
      </p>

      <div class="col-sm-12 pl-0 mt-20 text-center">
        <img class="img-fluid" src="/../assets/media/images/sixth-page.png" />
      </div>

      <div class="col-sm-12 mt-5">
        <div id="divchart_hor mt-20">
          <span class="title_number"><br />The chart below displays your scores and the typical scores for other people
            who have completed the Survey.
          </span>


          <div class="row">
            <div class="col-md-6">
              <canvas id="barchart_hor" class="my-2"></canvas>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p class="italic-section">The typical score for both these tensions is 3.5</p>
              <p class="section-text">Tension scores are "healthy" when survey items within the tension are rated
                relatively high. Conversely, if these items are rated low, this tension is probably “unhealthy.” If you
                feel stuck or unsure about your tension scores, go back to the individual survey items. Ask yourself,
                "what's going on in my work situation that's causing me to rate this item low? In what ways could I
                handle this situation better?” </p>
              <p class="section-text pt-10">Too much or too little focus on one side of a tension can impact other
                elements where you find meaning. See the examples below that point to situations in which tensions may
                not be healthy. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 mt-3">


      <!-- <div id="divchart_hor">
        <span class="title_number"
          ><br />BALANCE (Self / Others and Being / Doing)
        </span>
        <div class="row">
          <div class="col-md-6">
            <canvas id="barchart_hor" class="my-2"></canvas>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b class="ml-80"
              >The typical score for both these tensions is 3.5</b
            >
          </div>
        </div>
      </div> 
    </div> -->
    </div>
  </div>
</div>