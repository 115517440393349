import { SetupDiscussionComponent } from './../../../setup-discussion/setup-discussion.component';
import { message_properties } from './../../../../../../../environments/message_properties';
import { EventCancellationRequestComponent } from './../../../event-cancellation-request/event-cancellation-request.component';
import { CuratorPageService } from './../../../../../../service/curator-page.service';
import { NotificationService } from './../../../../../../service/notification.service';
import { Subscription } from 'rxjs';
import { SessionInsightsComponent } from './../../session-insights/session-insights.component';
import { MeaningNetworkService } from '../../../../../../service/meaning-network.service';
import { PrivateNoteComponent } from './../../../private-note/private-note.component';
import { MatDialog } from '@angular/material/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MentorService } from '../../../../../../service/mentor.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  FixedSizeVirtualScrollStrategy,
  VIRTUAL_SCROLL_STRATEGY,
} from '@angular/cdk/scrolling';
import { EventEmitter } from 'events';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SetupDiscussionFromGuideComponent } from '../../../setup-discussion-from-guide/setup-discussion-from-guide.component';
import { MarkedAsCompletedComponent } from '../../../marked-as-completed/marked-as-completed.component';
import { SetupDiscussionViewComponent } from '../../../setup-discussion-view/setup-discussion-view.component';
import * as moment from 'moment-timezone';
import { TimezoneService } from '../../../../../../service/timezone.service';
import { AccountsService } from './../../../../../../service/accounts.service';

export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(4, 200, 400);
  }
}
@Component({
  selector: 'kt-discussions',
  templateUrl: './discussions.component.html',
  styleUrls: ['./discussions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy },
  ],
})
export class DiscussionsComponent implements OnInit, OnDestroy {
  discussions = [];
  loggedinUserid: number;
  @Input() menteeId;
  @Input() fullName;
  @Input() menteeDp;
  @Input() member_relationship_id;
  @Output() changeEvent = new EventEmitter();

  first_name: string;
  last_name: string;
  guideDp: string;
  currentTab: number = 1;
  pdfUrl: File;
  current_timezone: string;
  count: number;
  params: string;
  public from_date: moment.Moment;
  searchSubscription: Subscription;
  guideSubscription: Subscription;
  discussionSubscription: Subscription;
  acceptSubscription: Subscription;
  declineSubscription: Subscription;
  markSubscription: Subscription;
  startSubscription: Subscription;
  cancelSubscription: Subscription;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private _cdr: ChangeDetectorRef,
    private meaning_network_service: MeaningNetworkService,
    private toastr: NotificationService,
    private curatorService: CuratorPageService,
    private mentor_service: MentorService,
    private active_route: ActivatedRoute,
    private timeZoneService: TimezoneService,
    private accountService: AccountsService
  ) {
    this.loggedinUserid = this.accountService.muser;
    this.params = this.active_route.snapshot.paramMap.get('params');
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.curatorService.all_status_change_refresh.subscribe((data) => {
      this.getDiscussionsLists(
        this.member_relationship_id,
        this.menteeId,
        this.loggedinUserid
      );
    });
    this.getDiscussionsLists(
      this.member_relationship_id,
      this.menteeId,
      this.loggedinUserid
    );
    this.curatorService.refreshneededEvent.subscribe((data) => {
      this.getDiscussionsLists(
        this.member_relationship_id,
        this.menteeId,
        this.loggedinUserid
      );
    });
    this.mentor_service.requestrefreshneededForMarkAsClosed.subscribe(
      (data) => {
        this.getDiscussionsLists(
          this.member_relationship_id,
          this.menteeId,
          this.loggedinUserid
        );
      }
    );
    this.getGuideProfileDetails();
  }

  onChange(deviceValue) {
    this.searchAllExplorerGuideDiscussionList(deviceValue);
  }
  searchAllExplorerGuideDiscussionList(selected_value) {
    const data = {
      selected: selected_value,
    };
    this.spinner.show();
    this.searchSubscription = this.mentor_service
      .getExplorerGuideDiscussionListByPost(
        this.member_relationship_id,
        this.menteeId,
        this.loggedinUserid,
        data
      )
      .subscribe((data) => {
        this.discussions = data.results;
        this.count = data.count;
        this._cdr.detectChanges();
        this.spinner.hide();
      });
  }

  getGuideProfileDetails() {
    this.guideSubscription = this.meaning_network_service
      .getProfileDetails(this.loggedinUserid)
      .subscribe((data) => {
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.guideDp = data.picture_path;
      });
  }

  changeTab(changeTab: number) {
    this.currentTab = changeTab;
  }

  getDiscussionsLists(member_relationship_id, explorer, guide) {
    this.spinner.show();
    this.discussionSubscription = this.mentor_service
      .getExplorerGuideDiscussionList(member_relationship_id, explorer, guide)
      .subscribe((data) => {
        this.discussions = data.results;
        this.count = data.count;
        this._cdr.detectChanges();
        this.spinner.hide();
      });
  }
  returnImage(image) {
    if (image) {
      return image;
    } else {
      return './assets/media/users/default.png';
    }
  }

  insightsActions(eventId) {
    this.dialog.open(SessionInsightsComponent, {
      disableClose: true,
      width: '70%',
      data: {
        eventId: window.btoa(eventId),
        guideFirstName: this.first_name,
        guideLastName: this.last_name,
        guideId: this.loggedinUserid,
        menteeFullName: this.fullName,
        guideDp: window.btoa(this.returnImage(this.guideDp)),
        menteeDp: window.btoa(this.menteeDp),
      },
    });
  }
  openPopupForDiscussionEditGuide(eventId, created_by_guide) {
    this.dialog.open(SetupDiscussionFromGuideComponent, {
      disableClose: true,
      data: {
        eventId: eventId,
        created_by_guide: created_by_guide,
        dp: this.menteeDp,
        menteeId: this.menteeId,
        mentee: this.fullName,
        clicked_from: 'from_guide',
      },
    }),
      this.dialog.afterAllClosed.subscribe(() => {
        this.currentTab = 1;
        this.getDiscussionsLists(
          this.member_relationship_id,
          this.menteeId,
          this.loggedinUserid
        );
      });
  }
  openPopupForDiscussionEditExplorer(eventId, created_by_guide, active) {
    this.dialog.open(SetupDiscussionComponent, {
      disableClose: true,
      data: {
        eventId: eventId,
        dp: this.menteeDp,
        menteeId: this.menteeId,
        mentee: this.fullName,
        clicked_from: 'from_guide',
        created_by_guide: created_by_guide,
        member_relationship_id: this.member_relationship_id,
        active: active,
      },
    }),
      this.dialog.afterAllClosed.subscribe(() => {
        this.currentTab = 1;
        this.getDiscussionsLists(
          this.member_relationship_id,
          this.menteeId,
          this.loggedinUserid
        );
      });
  }
  addSetup(eventId) {
    this.dialog.open(SetupDiscussionFromGuideComponent, {
      disableClose: true,
      data: {
        eventId: eventId,
        dp: this.menteeDp,
        menteeId: this.menteeId,
        mentee: this.fullName,
        member_relationship_id: this.member_relationship_id,
        clicked_from: 'from_guide',
      },
    }),
      this.dialog.afterAllClosed.subscribe(() => {
        this.currentTab = 1;
        this.getDiscussionsLists(
          this.member_relationship_id,
          this.menteeId,
          this.loggedinUserid
        );
      });
  }

  addNewPrivateNote(eventId, sessionType, start_date, end_date, private_note) {
    this.dialog.open(PrivateNoteComponent, {
      disableClose: true,
      width: '60%',
      data: {
        eventId: eventId,
        sessionType: sessionType,
        active: 0,
        menteeId: this.menteeId,
        mentee_full_name: this.fullName,
        explorerDp: this.menteeDp,
        guideFirstName: this.first_name,
        guideLastName: this.last_name,
        guideDp: this.guideDp,
        start_date: start_date,
        end_date: end_date,
        private_note: private_note,
      },
    });
  }
  acceptDiscussion(eventId, action) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to accept this request.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.acceptSubscription = this.curatorService
          .updateEventDiscussionStatusChange(
            eventId,
            action,
            0,
            this.menteeId,
            this.loggedinUserid
          )
          .subscribe((data) => {
            if (data.code === 204) {
              this.toastr.showError(data.message, '');
            } else if (data.code === 200) {
              this.toastr.showSuccess(
                message_properties.ACCEPT_DISCUSSION_SUCCESS,
                ''
              );
              this._cdr.detectChanges();
            } else {
              this.toastr.showError(
                message_properties.ACCEPT_DISCUSSION_ERROR,
                ''
              );
              this._cdr.detectChanges();
            }
          });
      }
    });
  }

  declinedDiscussion(eventId, action) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to decline this request.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.declineSubscription = this.curatorService
          .updateEventDiscussionStatusChange(
            eventId,
            action,
            0,
            this.menteeId,
            this.loggedinUserid
          )
          .subscribe((data) => {
            if (data.code === 204) {
              this.toastr.showError(data.message, '');
            } else if (data.code === 200) {
              this.toastr.showSuccess(
                message_properties.DECLINED_DISCUSSION_SUCCESS,
                ''
              );
              this._cdr.detectChanges();
            } else {
              this.toastr.showSuccess(
                message_properties.DECLINED_DISCUSSION_ERROR,
                ''
              );
              this._cdr.detectChanges();
            }
          });
      }
    });
  }
  startDiscussion(eventId, action, host_meeting_link, zoom_start_join_check) {
    if (zoom_start_join_check) {
      this.toastr.showError(message_properties.ZOOM_MEETING_TIME_ERROR, '');
    } else {
      Swal.fire({
        title: 'Please confirm!',
        text: 'You want to start this discussion.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          //start meeting
          this.startSubscription = this.curatorService
            .updateEventDiscussionStatusChange(
              eventId,
              action,
              2,
              this.menteeId,
              this.loggedinUserid
            )
            .subscribe((data) => {
              if (data.code === 200) {
                this.toastr.showSuccess(
                  message_properties.START_DISCUSSION_SUCCESS,
                  ''
                );
                window.open(host_meeting_link);
                this._cdr.detectChanges();
              } else {
                this.toastr.showSuccess(
                  message_properties.START_DISCUSSION_ERROR,
                  ''
                );
                this._cdr.detectChanges();
              }
            });
        }
      });
    }
  }
  cancelledDiscussion(eventId, action) {
    Swal.fire({
      title: 'Please confirm!',
      text: 'You want to cancel this discussion.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.cancelSubscription = this.curatorService
          .updateEventDiscussionStatusChange(
            eventId,
            action,
            0,
            this.menteeId,
            this.loggedinUserid
          )
          .subscribe((data) => {
            if (data.code === 200) {
              this.toastr.showSuccess(
                message_properties.CANCELLED_DISCUSSION_SUCCESS,
                ''
              );
              this._cdr.detectChanges();
            } else {
              this.toastr.showSuccess(
                message_properties.CANCELLED_DISCUSSION_ERROR,
                ''
              );
              this._cdr.detectChanges();
            }
          });
      }
    });
  }
  markDiscussionCompleted(eventId, action, event_type) {
    if (event_type === 2550) {
      this.dialog.open(MarkedAsCompletedComponent, {
        disableClose: true,
        width: '30%',
        data: {
          eventId: eventId,
          menteeFullName: this.fullName,
          menteeDp: this.menteeDp,
          action: action,
          event_type: event_type,
          menteeId: this.menteeId,
        },
      }),
        this.dialog.afterAllClosed.subscribe(() => {
          this.currentTab = 1;
          this.getDiscussionsLists(
            this.member_relationship_id,
            this.menteeId,
            this.loggedinUserid
          );
        });
    } else {
      Swal.fire({
        title: 'Please confirm!',
        text: 'This discussion will be marked as closed.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          const formData = {
            actual_duration: 1,
            active: 6,
            eventId: eventId,
            guideId: this.loggedinUserid,
            exploterId: this.menteeId,
            event_type: event_type,
          };
          this.markSubscription = this.mentor_service
            .discussionMarkedAsCompleted(formData)
            .subscribe(
              (data) => {
                if (data.status === 201) {
                  this.toastr.showSuccess(data.message, '');
                } else {
                  this.spinner.hide();
                  this.toastr.showError('Not Saved', '');
                }
              },
              (error) => {
                this.spinner.hide();
                this.toastr.showError('Not Saved', '');
              }
            );
          this.toastr.showSuccess(
            'Discussion marked as completed successfully!',
            ''
          );
        }
      });
    }
  }
  viewDetails(event_id, created_by_guide, member_relationship) {
    this.dialog.open(SetupDiscussionViewComponent, {
      disableClose: true,
      data: {
        eventId: event_id,
        dp: this.menteeDp,
        menteeId: this.menteeId,
        mentee: this.fullName,
        clicked_from: 'view_only',
        member_relationship_id: member_relationship,
        created_by_guide: created_by_guide,
      },
    });
  }

  eventType(event_type) {
    if (event_type === 2548) {
      return 'Get Acquainted';
    } else if (event_type === 2549) {
      return 'Be My Guide Session';
    } else if (event_type === 2733) {
      return 'Complimentary';
    } else if (event_type === 2550) {
      return 'General Discussion';
    } else if (event_type === 2554) {
      return 'Meaningful Work Survey';
    } else if (event_type === 2556) {
      return 'Mirror Reflection';
    } else {
      return 'DiY Discussion';
    }
  }
  // # 0= Draft/Requested, 1 = publish,
  // # 2 = Canceled, 3 = SCHEDULED/Accept,
  // # 4 = Auto cancelled, 5= Complete,
  // # 6 = Marked Complete,
  // # 7= Auto complete,
  // # 8 = withdrawn,
  // # 9 = Declined Request,
  // # 11 = Join
  StatusChange(status) {
    if (status === 0) {
      return 'Requested';
    } else if (status === 1) {
      return 'Published';
    } else if (status === 2) {
      return 'Canceled';
    } else if (status === 3) {
      return 'Scheduled';
    } else if (status === 4) {
      return 'Canceled'; // Auto canceled
    } else if (status === 5) {
      return 'Completed';
    } else if (status === 6) {
      return 'Closed';
    } else if (status === 7) {
      return 'Completed'; // Auto Completed
    } else if (status === 8) {
      return 'Withdrawn';
    } else if (status === 9) {
      return 'Request Declined';
    } else if (status === 10) {
      return 'Cancelation Requested';
    } else if (status === 11) {
      return 'Canceled due to request';
    } else if (status === 12) {
      return 'Auto Withdrawn';
    }
  }
  requestCancelledDiscussion(event_id, event_type) {
    this.dialog.open(EventCancellationRequestComponent, {
      disableClose: true,
      width: '40%',
      data: {
        eventId: event_id,
        guide: this.loggedinUserid,
        explorer: this.menteeId,
        clicked_from: 'shared_guide',
        eventType: event_type,
      },
    });
  }
  dateInUserTimezome(datetime: string) {
    this.from_date = moment(datetime).utc();
    this.timeZoneService.setTenantTimeZone(this.current_timezone);
    return this.timeZoneService.utcToTenantString(this.from_date, 'LLL');
  }
  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.guideSubscription) {
      this.guideSubscription.unsubscribe();
    }
    if (this.discussionSubscription) {
      this.discussionSubscription.unsubscribe();
    }
    if (this.acceptSubscription) {
      this.acceptSubscription.unsubscribe();
    }
    if (this.declineSubscription) {
      this.declineSubscription.unsubscribe();
    }
    if (this.markSubscription) {
      this.markSubscription.unsubscribe();
    }
    if (this.startSubscription) {
      this.startSubscription.unsubscribe();
    }
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
  }
}
