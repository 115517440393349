<div class="col-sm-12 mt-3">
	<div class="title_name1 mt-4">
		<div class="section-title">
			<h1>Your Next Steps</h1>
		</div>
		<h2 class="section-title1">Review Key Takeaways from Your Report</h2>

		<p class="section-text mt-20">
			Now it's time to put the insights you gained to work for you! Examining your Report will help you ask and
			answer the question "what makes my work meaningful to me?" It can also jump-start your thinking around what
			you can do to further enhance and improve your experience at work.
		</p>
		<p class="section-text mt-20">
			Many people also find their Report reveals how they go about pursuing meaningful work. It provides you with
			a common vocabulary to help you talk more freely about the concept of meaning. As one Map of Meaning user
			reflected, "It gave me the words to express what I felt all along."
		</p>
		<p class="section-text mt-20">
			Earlier in the Report, we cautioned not to become dissatisfied because you scored below average or satisfied
			just because you have high scores. Instead, a better way to interpret your results is to examine:
		</p>
		<ul class="section-text mt-20">
			<li><b>Your scores:</b> What do they signify to you, and how do they reflect what matters most to you? Tie
				your actual work experiences to these scores and consider any changes you want to make.</li>
			<li><b>Your meaning-seeking patterns:</b> What are recurring themes in your work? What's helping and
				hindering you? What's easy to solve and what's more difficult to discuss or change?</li>
			<li><b>Your wellbeing scores:</b> Do you feel you have wellbeing in all aspects of your life? Do you find
				time to take advantage of the good things in life?</li>
		</ul>

		<p class="section-text mt-20 mb-30">
			In the video linked below, Lucy decides what to do next based on her Survey results. She opted for a
			creative solution to finding a better life-work balance. </p>
		<p class="section-text">
			<span style="color: #fff !important" class="ml-0 arrow-highlight cursorp" style="color: #fff !important"
				(click)="lucyVideo()">Watch&nbsp;&nbsp;<img src="/../assets/media/icon/play_arrow.png"
					class="img15" /></span>
			Lucy: “The next chapter in my life.”
		</p>

		<p class="section-text mt-20">
			We hope the Survey and Report provide you with some useful ways to think about how and where you experience
			meaning at work. You may already have started identifying action steps you can take to make positive
			changes. Remember, the search for meaning doesn't always have to be a serious or profound matter requiring
			big, dramatic changes. Try experimenting with a few steps in small ways and see where it takes you. </p>


		<p class="section-text mt-20 mb-20">
			<b>Check out some options for next steps and decide which are right for you. </b>
		</p>
		<p class="section-text mt-20">

			<a (click)="redirectToNextStep()" class="cursorp"><b>
					<span style="color: #fff !important" class="ml-0 arrow-highlight cursorp"
						style="color: #fff !important">Learn More</span></b></a>
		</p>
		<!-- <p class="section-text">The Meaningful Work Survey is © to Marjolein Lips-Wiersma 2000-2020.</p> -->
	</div>
</div>