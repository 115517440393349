import { Router } from '@angular/router';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'kt-my-guides',
  templateUrl: './my-guides.component.html',
  styleUrls: ['./my-guides.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyGuidesComponent implements OnInit {
  constructor(private route: Router) {}

  ngOnInit(): void {}

  findAGuide(): void {
    this.route.navigate(['discover', 'search-for-a-guide']);
  }
  viewReport(): void {
    this.route.navigate(['discover', 'my-guided-explorer-reports']);
  }
}
