<div class="col-sm-12 mt-5">
	<!-- <span class="fs-20" [innerHtml]="report_4_body"></span>
	 -->
	 
	 <p class="section-text">In this case, the person may feel they make quite a difference to others at work, but work might feel less meaningful because their skills and talents are not used to their fullest. In a situation like this, taking courses, signing up for projects, or trying a different role might make work more meaningful.</p>
	 <p class="section-text">If scores were low in all four pathways, that person may not be in the right job and could consider looking for another position, seeking meaning in other life roles, or talking to a trusted individual for guidance.</p>
	 <p class="section-text pt-20">In the linked video below, watch Maya explore her Integrity with Self score.</p>
	 <div class="row">
		<div class="col-sm-12">
		  <p class="section-text">
			<!-- <span
			  class="arrow-highlight cursorp"
			  (click)="mayaVideo()"
			  >Watch</span
			> -->
			<span (click)="mayaVideo()"
			  class="arrow-highlight cursorp" style="color:white !important;"
			  >Watch &nbsp;&nbsp;<img src="/../assets/media/icon/play_arrow.png" class="img15"/></span
			>
			Maya: “My score isn’t typical”
		  </p>
		</div>
		<div class="col-sm-7"></div>
	  </div>
</div>
