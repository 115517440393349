<div class="mat-dialog-popup private-width">
  <table width="100%">
    <tr>
      <td>
        <h1 class="popup_card_title">Private Notes</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <form
    class="privatenoteForm"
    [formGroup]="privatenoteForm"
    (ngSubmit)="createPrivateNote(privatenoteForm.value)"
    novalidate
    enctype="multipart/form-data"
    autocomplete="off"
  >
    <div class="">
      <div class="row">
        <div class="col-md-12 col-lg-4">
          <table>
            <tr>
              <td>
                <img class="user" [src]="explorerDp" />
              </td>
              <td>
                <h1 class="fs-18 m-5">{{ mentee_full_name }}</h1>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-md-12 col-lg-4">
          <table>
            <tr>
              <td>
                <img class="user" [src]="guideDp" />
              </td>
              <td>
                <h1 class="fs-18 m-5">
                  {{ guideFirstName + " " + guideLastName }}
                </h1>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-md-12 col-lg-4">
          <p>
            <label>Session Type: {{ sessionType }}</label>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <table border="0">
            <tr>
              <td>Date: {{ start_date | date: "MMM d, y" }}</td>
              <td>&nbsp;</td>
              <td>{{ onlyTimeTimeZone(start_date) }}</td>
              <td>&nbsp;</td>
              <td>to</td>
              <td>&nbsp;</td>
              <td>{{ onlyTimeTimeZone(end_date) }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <label class="label-wid">Note *</label>
          <textarea
            [ngxSummernote]="config"
            formControlName="additional_information"
            rows="3"
            placeholder=""
            (summernoteModelChange)="changeDesc($event)"
          ></textarea>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-sm-12 tr">
          <button
            [disabled]="!privatenoteForm.valid || saveButton"
            class="btn begin_quiz ml-2"
            type="submit"
          >
            Save
          </button>

          <button
            mat-dialog-close
            class="btn begin_quiz ml-2 button-change-scroll canceled_btn"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
