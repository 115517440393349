import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  NgForm,
  FormArray,
} from '@angular/forms';
import {
  Component,
  OnInit,
  Optional,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminPageService } from './../../../service/admin-page.service';
import { AccountsService } from './../../../service/accounts.service';
@Component({
  selector: 'kt-set-message-to-pupup',
  templateUrl: './set-message-to-pupup.component.html',
  styleUrls: ['./set-message-to-pupup.component.scss'],
})
export class SetMessageToPupupComponent implements OnInit {
  hostsForm: UntypedFormGroup;
  postForm: UntypedFormGroup;
  postFormMessage: UntypedFormGroup;
  selectedHosts: any = [];
  unslectedHosts: any = [];
  mode: string;
  type: string;
  title: string;
  hostData: any = [];
  page = 1;
  count: number;
  loggedInUser: number;
  ids = '';
  hosts: any[] = [];
  searchkeyword = '';
  creatingUser: any;
  constructor(
    public dialogRef: MatDialogRef<SetMessageToPupupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private searchservice: AdminPageService,
    private _cdr: ChangeDetectorRef,
    private accountService: AccountsService
  ) {
    this.selectedHosts = data.selectedHosts ? data.selectedHosts : [];
    this.mode = data.mode; // multiple-selection,single-selection
    this.type = data.type;
    this.creatingUser = data.creatingUser ? data.creatingUser : null;
  }
  ngOnInit(): void {
    this.loggedInUser = this.accountService.muser;
    this.hostsForm = new UntypedFormGroup({
      hosts: this.formBuilder.array([]),
      ids: new UntypedFormControl(''),
    });
    this.postForm = new UntypedFormGroup({
      page: new UntypedFormControl(this.page),
      search_text: new UntypedFormControl(''),
    });
    this.postFormMessage = new UntypedFormGroup({
      page: new UntypedFormControl(this.page),
      search_text: new UntypedFormControl(''),
      logged_in_member: new UntypedFormControl(this.loggedInUser),
    });
    switch (this.type) {
      case 'messageto':
        this.getmesaagetoData(1);
        this.title = 'Select Explorer';
        break;
      case 'guideData':
        this.getGuideData(1);
        this.title = 'Guides';
        break;
      default:
        break;
    }
  }
  filterData(event) {
    this.searchkeyword = event.target.value;
    switch (this.type) {
      case 'messageto':
        this.postFormMessage.patchValue({
          page: 1,
          search_text: this.searchkeyword,
          logged_in_member: this.loggedInUser,
        });
        this.getmesaagetoData(1);
        break;
      case 'guideData':
        this.postForm.patchValue({
          page: 1,
          search_text: this.searchkeyword,
        });
        this.getGuideData(1);
        break;
      default:
        break;
    }
    this._cdr.detectChanges();
  }
  onChange(event, host) {
    if (event.checked || event.source._checked) {
      if (this.mode === 'single-selection') {
        this.hosts = [];
      }
      let temphosts = this.hosts.filter((element) => element.id === host.id);
      if (temphosts.length === 0) {
        this.hosts.push(host);
      }
    } else {
      let temphosts;
      temphosts = this.hosts.filter((element) => Number(element.id) !== Number(host.id));
      this.hosts = temphosts;
    }
  }
  sendData(ngForm: NgForm) {
    this.dialogRef.close({
      data: { hosts: this.hosts, ids: this.ids },
    });
  }
  checkhosts(id) {
    let temphosts = this.hosts.filter((element) => element.id === id);
    let temphosts2 = this.selectedHosts.filter(
      (element) => element.id === id || Number(element) === id
    );
    if (temphosts.length > 0 || temphosts2.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  updateScrollDown(e) {
    switch (this.type) {
      case 'messageto':
        if (Number(this.count) !== Number(this.hostData.length)) {
          this.postFormMessage.patchValue({
            page: this.page,
            search_text: this.searchkeyword != '' ? this.searchkeyword : '',
            logged_in_member: this.loggedInUser,
          });
          this.getmesaagetoData();
          this.page++;
        }
        break;
      case 'guideData':
        if (this.count != this.hostData.length) {
          this.postForm.patchValue({
            page: this.page,
            search_text: this.searchkeyword != '' ? this.searchkeyword : '',
          });
          this.getGuideData();
          this.page++;
        }
        break;
      default:
        break;
    }
    this._cdr.detectChanges();
  }
  getmesaagetoData(param = 0) {
    if (param === 1) {
      this.hostData = [];
      this.page = 2;
      this.postFormMessage.patchValue({
        page: 1,
        logged_in_member: this.loggedInUser,
      });
    }
    this.spinner.show();
    this.searchservice
      .getAllMemberForMessageWhoIsNotLoggedIn(this.postFormMessage.value)
      .subscribe((data) => {
        this.hostData = [
          ...new Map(
            this.hostData.concat(data.results).map((item) => [item['id'], item])
          ).values(),
        ];
        this.count = data.count;
        this._cdr.detectChanges();
        this.spinner.hide();
      });
  }
  getGuideData(param = 0) {
    if (param === 1) {
      this.hostData = [];
      this.page = 2;
      this.postForm.patchValue({
        page: 1,
      });
    }
    // throw new Error('Method not implemented.');
    this.spinner.show();
    this.searchservice
      .getAllMentorDataWithSearch(this.postForm.value)
      .subscribe((data) => {
        this.hostData = [
          ...new Map(
            this.hostData.concat(data.results).map((item) => [item['id'], item])
          ).values(),
        ];
        this.count = data.count;
        this._cdr.detectChanges();
        this.spinner.hide();
      });
  }
}
