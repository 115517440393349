<kt-spinner></kt-spinner>
<div class="mat-dialog-popup">
  <h1 class="cmn_title_top">Purchase History</h1>

  <button mat-button mat-dialog-close class="close-dialog">X</button>

  <hr />
  <div class="fixed_cntent">
    <mat-card-content>
      <ng-container *ngIf="purcahse_item_count > 0">
        <div class="fixTableHead">
          <table class="table">
            <thead>
              <tr>
                <th>Purchase Date</th>
                <th>Item(s) Purchased</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let itemdata of purchase_item_list; let i = index">
                <tr *ngIf="itemdata.transaction_item_details.length>0">
                  <td>
                    <span>{{ itemdata.transaction_time | date: "d MMM y" }}</span>
                  </td>
                  <td>
                    <span>
                      {{ itemdata.transaction_item_details[0]["item_name"] }}
                      <small *ngIf="itemdata.transaction_item_count > 0">
                        + {{ itemdata.transaction_item_count }} Items
                      </small>
                    </span>
                  </td>
                  <td>
                    <span>US $ &nbsp;{{
                      itemdata.transaction_total_amount | number: "1.2-2"
                      }}</span>
                  </td>
                  <td>
                    <span><a (click)="redirectUrl(itemdata.invoice_link)" style="cursor: pointer">View
                        Invoice</a></span>
                  </td>
                </tr>
              </ng-container>
            </tbody>
            <!-- </cdk-virtual-scroll-viewport> -->
          </table>
        </div>
      </ng-container>
      <ng-container *ngIf="purcahse_item_count == 0">
        <p>No Data Found!</p>
      </ng-container>
      <hr />
      <div class="row">
        <div class="col-sm-12 tr">
          <button mat-dialog-close class="btn begin_quiz mt-3 ml-2 button-change-scroll canceled_btn">
            Close
          </button>
        </div>
      </div>
    </mat-card-content>
  </div>
</div>