import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminPageService {
  apiUrl = environment.apiUrl;
  private __g_edit_member_id: any;
  private _refreshneeded = new Subject<void>();
  private _refreshneededInMessageList = new Subject<void>();
  private _refreshneededMyEndorsementList = new Subject<void>();
  private _refreshMemberList = new Subject<ResDataModal>();
  private _refreshneededUserRequestList = new Subject<ResDataModal>();
  private _refreshGuidePrivileges = new Subject<void>();
  private _refreshMemberPrivileges = new Subject<void>();
  private _refreshMemberListAfterDelete = new Subject<void>();

  private _refreshAfterDeleteByAdmin = new Subject<void>();

  get refreshAfterDeleteByAdmin() {
    return this._refreshAfterDeleteByAdmin;
  }

  get refreshMemberListAfterDelete() {
    return this._refreshMemberListAfterDelete;
  }

  get refreshGuidePrivileges() {
    return this._refreshGuidePrivileges;
  }

  get refreshMemberPrivileges() {
    return this._refreshMemberPrivileges;
  }

  get refreshneeded() {
    return this._refreshneeded;
  }
  get refreshMemberList() {
    return this._refreshMemberList;
  }
  get refreshneededInMessageList() {
    return this._refreshneededInMessageList;
  }
  get refreshneededMyEndorsementList() {
    return this._refreshneededMyEndorsementList;
  }
  get refreshneededUserRequestList() {
    return this._refreshneededUserRequestList;
  }

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  getData(param): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/manage-member-search/',
      param
    );
  }
  // getMemberData(loggedInUser, param, page): Observable<ResDataModal> {
  //   return this.http.get<ResDataModal>(
  //     `${this.apiUrl}member/manage-member-search/${loggedInUser}?q=${param}&&page=${page}`
  //   );
  // }
  getAllHost(): Observable<HostData> {
    return this.http.get<HostData>(this.apiUrl + 'member/get-all-host');
  }
  getAllMeaningSphereHost(): Observable<HostData> {
    return this.http.get<HostData>(
      this.apiUrl + 'member/get-all-meaningsphere-host'
    );
  }
  //Update by Prashanth T.K., Nov 5,2020
  getMentorData(loggedInUserId, param, page): Observable<ResDataModal> {
    return this.http.get<ResDataModal>(
      this.apiUrl +
        'member/search_mentor/' +
        loggedInUserId +
        '?q=' +
        param +
        '&&page=' +
        page
    );
  }

  getMentorSearchFromFormData(
    loggedInUserId,
    ngForm
  ): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/search_mentor/' + loggedInUserId,
      ngForm
    );
  }
  getMemberDetailsPost(ngForm): Observable<MemberDetails> {
    return this.http.post<MemberDetails>(
      this.apiUrl + 'member/member-profile-detail/',
      ngForm
    );
  }

  postDataWithStatus(ngForm): Observable<DataWithStatus> {
    return this.http
      .post<DataWithStatus>(
        this.apiUrl + 'member/member-change-status-and-stage',
        ngForm
      )
      .pipe(
        tap((data) => {
          this._refreshMemberPrivileges.next();
        })
      );
  }
  changeStatusGuideProgram(ngForm): Observable<DataWithStatus> {
    return this.http
      .post<DataWithStatus>(
        this.apiUrl + 'member/member-change-status-and-stage',
        ngForm
      )
      .pipe(
        tap((data) => {
          this._refreshGuidePrivileges.next();
        })
      );
  }

  bannerInserMemberWise(ngForm): Observable<DataWithStatus> {
    return this.http.post<DataWithStatus>(
      this.apiUrl + 'member/member-show-banner/',
      ngForm
    );
  }

  userRequestsList(collabcontentCategory, collabcontentStatus) {
    return this.http.get<ResDataModal>(
      this.apiUrl +
        'collabcontent/collab-content-user-request?collab_content_category=' +
        collabcontentCategory +
        '&&collabcontentStatus=' +
        collabcontentStatus
    );
  }
  callingFromHelpdeskAndNotificationPost(ngForm) {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'collabcontent/collab-content-type-list-with-search',
      ngForm
    );
  }

  meaningNetworkListWithSearchFromCurator(ngForm) {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'collabcontent/meaning-network-list-with-search',
      ngForm
    );
  }

  getCollabContentDetailsById(id) {
    return this.http.get<CollabeContentData>(
      this.apiUrl + 'collabcontent/request-details/' + id
    );
  }
  updateCollabContent(ngForm, collabcontentid) {
    return this.http
      .put<CollabeContentData>(
        this.apiUrl + 'collabcontent/update/' + collabcontentid,
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneeded.next();
        })
      );
  }

  userRequestUpdate(ngForm, collabcontentid) {
    return this.http
      .put<CollabeContentData>(
        this.apiUrl +
          'collabcontent/collab-content-user-request-update/' +
          collabcontentid,
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededUserRequestList.next();
        })
      );
  }

  getAllMemberWhoIsNotPresentInCommunityOrCircle(
    ngFormData
  ): Observable<HostData> {
    return this.http.post<HostData>(
      this.apiUrl +
        'member/member-list-except-it-is-not-present-in-group-or-community/',
      ngFormData
    );
  }
  getAllMemberWhoIsNotLoggedIn(member): Observable<HostData> {
    return this.http.get<HostData>(
      this.apiUrl + 'member/all-member-list-except-logged-in-user/' + member
    );
  }

  getAllMemberForMessageWhoIsNotLoggedIn(formData): Observable<HostData> {
    return this.http.post<HostData>(
      this.apiUrl + 'member/all-member-list-for-message-except-logged-in-user/',
      formData
    );
  }

  postNewUser(ngForm) {
    return this.http
      .post<ResDataModal>(
        this.apiUrl + 'member/member-create-from-admin',
        ngForm
      )
      .pipe(
        tap(() => {
          return this._refreshMemberList.next();
        })
      );
  }
  getAllMentorData() {
    return this.http.get<ResDataModal>(this.apiUrl + 'member/all-mentor-list');
  }
  getAllMentorDataWithSearch(formData) {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/all-mentor-list-with-search',
      formData
    );
  }
  getNewUserDetailsById(data) {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/member-view-from-admin/',
      data
    );
  }
  // putNewUser(ngForm, id) {
  //   return this.http.put<ResDataModal>(
  //     this.apiUrl + 'member/member-update-from-admin/' + id,
  //     ngForm
  //   );
  // }
  putUpdateUser(ngForm) {
    return this.http
      .put<ResDataModal>(
        this.apiUrl + 'member/member-update-from-admin/',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneeded.next();
        })
      );
  }

  endorse_request(ngForm) {
    return this.http
      .post<MemberEndorsementData>(
        this.apiUrl + 'member/endorsement/create',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededMyEndorsementList.next();
        })
      );
  }
  endorse_ChangeStatus(ngForm, endorse_id) {
    return this.http
      .put<MemberEndorsementData>(
        this.apiUrl + 'member/endorsement/change-status/' + endorse_id,
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededMyEndorsementList.next();
        })
      );
  }
  endorse_delete(endorse_id) {
    return this.http
      .delete<MemberEndorsementData>(
        this.apiUrl + 'member/endorsement/delete/' + endorse_id
      )
      .pipe(
        tap(() => {
          this._refreshneededMyEndorsementList.next();
        })
      );
  }
  endorse_Details(endorse_id) {
    return this.http.get<MemberEndorsementData>(
      this.apiUrl + 'member/endorsement/details/' + endorse_id
    );
  }

  myEndorseList(data) {
    return this.http.post<MemberEndorsementData>(
      this.apiUrl + 'member/endorsement/list/',
      data
    );
  }

  endorseMemberAcceptedRequests(endorse_member) {
    return this.http.get<MemberEndorsementData>(
      this.apiUrl + 'member/endorsement/accepted-request/' + endorse_member
    );
  }
  // logged-in-user-have-guide/

  loggedInUserHaveGuide(loggedInUserId) {
    return this.http.get<MemberRelationShipData>(
      this.apiUrl + 'member/logged-in-user-have-guide/' + loggedInUserId
    );
  }

  guideAcceptedToUser(relating_member, loggedInUserId) {
    return this.http.get<MemberRelationShipData>(
      this.apiUrl +
        'member/guide-accepted-to-user/' +
        relating_member +
        '/' +
        loggedInUserId
    );
  }

  getMemberTimezone(formData) {
    const data = {
      member: formData, //formData = loggedInUserId
    };
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/member-timezone/',
      data
    );
  }

  callingAllConstellationSearchInMeaningConstellationPost(
    collabcontentCategory,
    ngForm
  ) {
    return this.http.post<ResDataModal>(
      this.apiUrl +
        'collabcontent/collab-content-type-list-with-search-in-all-meaning-library?collab_content_category=' +
        collabcontentCategory,
      ngForm
    );
  }

  getAllMentorDataReport() {
    return this.http.get<ResDataModal>(
      this.apiUrl + 'member/all-mentor-list-report'
    );
  }
  pastActivity(ngForm) {
    return this.http.post<DataWithStatus>(
      this.apiUrl + 'member/member-past-activity',
      ngForm
    );
  }
  getMemberDataPagination(ngform): Observable<any> {
    return this.http.post<any>(
      this.apiUrl + 'member/all-suggested-by-member-list/',
      ngform
    );
  }
  getAllHostPagination(ngForm): Observable<any> {
    return this.http.post<any>(
      this.apiUrl + 'member/all-host-list-api/',
      ngForm
    );
  }
  getAllMeaningSphereHostPagination(ngForm): Observable<any> {
    return this.http.post<any>(
      this.apiUrl + 'member/all-meaning-sphere-host-list/',
      ngForm
    );
  }
  deletePermanentUserAllData(send_data): Observable<ResDataModal> {
    return this.http
      .post<ResDataModal>(
        this.apiUrl + 'common/delete-user-and-data-permanently',
        send_data
      )
      .pipe(
        tap(() => {
          this._refreshMemberListAfterDelete.next();
        })
      );
  }

  loggedInUserHaveGuidePost(loggedInUserId, clicked_member) {
    const data = {
      loggedInUser: loggedInUserId,
      clickedOnMember: clicked_member,
    };
    return this.http.post<MemberRelationShipData>(
      this.apiUrl + 'member/logged-in-user-have-guide-post',
      data
    );
  }

  // All templet API
  templateAllList(ngForm) {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'common/template-admin/template-list-with-search',
      ngForm
    );
  }

  updateEmailTemplate(ngForm) {
    return this.http
      .put<TemplateData>(
        this.apiUrl + 'common/template-admin/update-email-template',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneeded.next();
        })
      );
  }

  getEmailtemplateDetailsById(id) {
    return this.http.get<TemplateData>(
      this.apiUrl + 'common/template-admin/email-template-details/' + id
    );
  }

  deletedMemberByStatus(param): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/manage-deleted-explorer/',
      param
    );
  }

  deleteUserAndUpdateStatus(send_data): Observable<ResDataModal> {
    return this.http
      .post<ResDataModal>(
        this.apiUrl + 'common/deleted-explorer-update-status-from-admin',
        send_data
      )
      .pipe(
        tap(() => {
          this._refreshAfterDeleteByAdmin.next();
        })
      );
  }
  downloadAllExplorerReport(send_data): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
        this.apiUrl + 'common/all-explorer-csv-report', send_data);
  }
}

export interface TemplateData {
  template_id: number;
  name: string;
  lookup_value: string;
  collab_content_category: number;
  status: number;
  message: string;
}

export interface MemberRelationShipData {
  id: number;
  relating_member: number;
  relating_member__member_table__full_name: string;
  count: number;
  relation_count: number;
  members: any[];
  logged_in_relation_count: number;
}

export interface MemberEndorsementData {
  visibility_type: number;
  endorsement_status: number;
  endorsement_expertise: number;
  creation_date: string;
  accepted_date: string;
  remarks: string;
  member: number;
  endorsing_member: number;
  member_profile_details: {};
  endorese_member_profile_details: {};
  results: [];
  count: number;
  status: number;
}

export interface PostMassageData {
  id: number;
  chat_for: number;
  criticality: number;
  creation_date: string;
  message: string;
  attachment_path: string;
  related_object_id: number;
  chat_parent_id: number;
  creating_user: number;
  tenant: number;
}

export interface HostData {
  id: number;
  full_name: string;
  results: [];
  count: number;
}
export interface DataWithStatus {
  creating_member: number;
  lock_unlock_status: number;
  member: number;
  property_name: string;
  property_value: string;
  status_type: string;
  results: [];
  count: number;
  code: number;
  message: string;
}
export interface CollabeContentData {
  collab_content_type: string;
  collab_content_status: string;
  creation_date: string;
  title: string;
  description: string;
  additional_info: string;
  references: string;
  collab_content_category: string;
}

export interface ResDataModal {
  count: number;
  status: number;
  results: [];
  visibility_type: number;
  association_type: number;
  association_status: number;
  name: string;
  title: any;
  location: any;
  workprofile: any;
  start_date: any;
  end_date: any;
  company: number;
  first_name: any;
  last_name: any;
  property_value: any;
  user: {};
  city: string;
  state: string;
  country: string;
  fname: string;
  lname: string;
  picture_path: string;
  whatcareabout: string;
  message: string;
  address_response: {};
  relationshion_type: number;
  relating_member_id: number;
  email: string;
  mentor_indicator: string;
  curator_indicator: string;
  admin_indicator: string;
  program_director_indicator: string;
  member_type: number;
  member_table: any;
  address_details: any;
  joined_date: any;
  member_additional: [];
  current_timezone: number;
  member_relation_mentee_count: number;
  member_have_explorers: boolean;
  member_have_guides: boolean;
  download_url: string;
}

export interface MemberDetails {
  final_data: [];
  topic_id: number;
  topic_type: string;
  topic: string;
  member: string;
  tenant: string;
  member_topic_results: [];
  member_topic_count: number;
  member_profile_id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  status: number;
  mentor_indicator: boolean;
  admin_indicator: boolean;
  curator_indicator: boolean;
  picture_path: string;
  member_profile_results: [];
  member_profile_count: number;
  endorse_id: number;
  endorse_first_name: string;
  endorse_last_name: string;
  endorse_full_name: string;
  endorse_member__status: string;
  endorse_picture_path: string;
  remarks: string;
  member_endorsement_results: [];
  member_endorsement_count: number;
  expertise_id: number;
  expertise_level: string;
  description: string;
  available: string;
  highlight: string;
  no_of_years: string;
  member_expertise_results: [];
  member_expertise_count: number;
  experience_id: number;
  experience_title: string;
  experience_name: string;
  experience_location: string;
  experience_workprofile: string;
  member_employee_experience_results: [];
  member_employee_experience_count: number;
  education_id: number;
  education_title: string;
  education_name: string;
  education_location: string;
  education_workprofile: string;
  member_employee_education_results: [];
  member_employee_education_count: number;
  volunteer_id: number;
  volunteer_title: string;
  volunteer_name: string;
  volunteer_location: string;
  volunteer_workprofile: string;
  member_employee_volunteer_results: [];
  member_employee_volunteer_count: number;
  work_history_id: number;
  property_value: string;
  member_work_history_results: [];
  member_work_history_count: number;
  address_id: number;
  city: string;
  state: string;
  country: string;
  country_id: string;
  city_id: string;
  member_address_results: [];
  member_address_count: number;
  my_treasure_id: number;
  my_treasure_title: string;
  my_treasure_description: string;
  my_treasure_video_indicator: string;
  my_treasure_created_by_curator_indicator: string;
  my_treasure_references: string;
  my_treasure_collab_content_category: string;
  my_treasure_attachment_path: string;
  member_my_treasure_results: [];
  member_my_treasure_count: number;
  meaning_moments_id: number;
  meaning_moments_title: string;
  meaning_moments_description: string;
  meaning_moments_video_indicator: string;
  meaning_moments_created_by_curator_indicator: string;
  meaning_moments_references: string;
  meaning_moments_collab_content_category: string;
  meaning_moments_attachment_path: string;
  member_meaning_moments_results: [];
  member_meaning_moments_count: number;
  connection_count: number;
  connection_status: number;
  requested_from: number;
  requested_to: number;
}
