<div class="mat-dialog-popup">
  <table width="100%">
    <tr>
      <td>
        <h1 class="popup_card_title">Share your thoughts</h1>
      </td>
      <td>
        <h1 mat-dialog-title>
          <button mat-button mat-dialog-close class="modal_close_button_top">
            X
          </button>
        </h1>
      </td>
    </tr>
  </table>
  <hr />
  <mat-card-content>
    <form class="sharedpostForm" [formGroup]="sharedpostForm" (ngSubmit)="saveCollabContent(sharedpostForm.value)"
      novalidate autocomplete="off">
      <div class="row">
        <div class="form-group col-sm-12">
          <!-- <mat-form-field style="width: 100%; color: #ffffff">
            <mat-label>Your thoughts *</mat-label>
            <textarea
              [ngxSummernote]="config"
              matInput
              formControlName="additional_info"
              rows="8"
            ></textarea>
          </mat-form-field> -->

          <label style="color: #afafaf">Your thoughts *</label>
          <textarea [ngxSummernote]="config" formControlName="additional_info" rows="8"></textarea>

        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 tr m-0">
          <button [disabled]="!sharedpostForm.valid || saveButton" class="btn begin_quiz ml-2 mt-1" type="submit">
            Share
          </button>

          <button mat-dialog-close class="btn begin_quiz mt-1 ml-2 button-change-scroll canceled_btn">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</div>