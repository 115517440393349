import { MeaningNetworkService } from './../../../../service/meaning-network.service';
import { CuratorPageService } from './../../../../service/curator-page.service';
import { NotificationService } from '../../../../service/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  NgForm,
} from '@angular/forms';
import {
  Component,
  OnInit,
  Optional,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { message_properties } from '../../../../../environments/message_properties';
import { AccountsService } from './../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-event-cancellation-request',
  templateUrl: './event-cancellation-request.component.html',
  styleUrls: ['./event-cancellation-request.component.scss'],
})
export class EventCancellationRequestComponent implements OnInit, OnDestroy {
  cancellationRequestForm: UntypedFormGroup;
  eventId: number;
  fullName: string;
  relationshipId: number;
  loggedInUserId: number;
  disabled = true;
  guide: number;
  explorer: number;
  ExplorerprofileData = [];
  ExplorerFullname: string;
  ExplorerProfilePicture: string;
  GuideprofileData = [];
  GuideFullname: string;
  GuideProfilePicture: string;
  event_details: any;
  clicked_from: string;
  event_action_data: any;
  diffrence_in_hours: number;
  current_timezone: string;
  start_date: string;
  reason_text: string;
  balance_deduction: boolean = false;
  eventType: number;
  saveButton: boolean = false;
  explorerSubscription: Subscription;
  guideSubscription: Subscription;
  eventSubscription: Subscription;
  actionSubscription: Subscription;
  cancelSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<EventCancellationRequestComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private curatorService: CuratorPageService,
    private toastr: NotificationService,
    private meaning_network_service: MeaningNetworkService,
    private _cdref: ChangeDetectorRef,
    private accountService: AccountsService
  ) {
    this.eventId = data.eventId;
    this.guide = data.guide;
    this.explorer = data.explorer;
    this.clicked_from = data.clicked_from;
    this.eventType = data.eventType;
  }

  ngOnInit(): void {
    this.getEventActionDetails(this.eventId);
    this.loggedInUserId = this.accountService.muser;
    this.current_timezone = localStorage.getItem('current_time_zone');
    this.cancellationRequestForm = this.formBuilder.group({
      action: new UntypedFormControl(1),
      reason: new UntypedFormControl(
        {
          value: '',
          disabled: this.loggedInUserId === this.guide ? true : false,
        },
        [Validators.required]
      ),
      penalty_duration: new UntypedFormControl(''),
      event: new UntypedFormControl(this.eventId),
      explorer: new UntypedFormControl(this.explorer),
      guide: new UntypedFormControl(this.guide),
      tenant: new UntypedFormControl(1),
    });
    this.getProfileWithExplorer();
    this.getProfileWithGuide();
    this.getEventDetailInDialog(0, this.eventId);
  }
  getProfileWithExplorer() {
    this.explorerSubscription = this.meaning_network_service
      .getProfileDetails(this.explorer)
      .subscribe((data) => {
        this.ExplorerFullname = data.full_name;
        this.ExplorerProfilePicture = data.profile_path
          ? data.profile_path
          : './assets/media/users/default.png';
        localStorage.setItem('ufullname', btoa(this.ExplorerFullname));
        localStorage.setItem(
          'image',
          data.profile_path !== null ? data.profile_path : '0'
        );
        this._cdref.detectChanges();
      });
  }
  getProfileWithGuide() {
    this.guideSubscription = this.meaning_network_service
      .getProfileDetails(this.guide)
      .subscribe((data) => {

        this.GuideFullname = data.full_name;
        this.GuideProfilePicture = data.profile_path
          ? data.profile_path
          : './assets/media/users/default.png';

        this._cdref.detectChanges();
      });
  }
  getEventDetailInDialog(groupId, sessionId) {
    this.eventSubscription = this.curatorService
      .getEventDetailInDialog(groupId, sessionId)
      .subscribe((data) => {
        this.event_details = data;
        this.start_date = this.event_details.start_date;
        this._cdref.detectChanges();
      });
  }
  getEventActionDetails(event_id) {
    this.actionSubscription = this.curatorService
      .eventCancellationRequestDetails(event_id)
      .subscribe((data) => {
        this.event_action_data = data;
        this.reason_text = data.reason;
        this.cancellationRequestForm.patchValue({
          reason: data.reason,
        });
        this.diffrence_in_hours = this.event_action_data.request_time_difference;
        this._cdref.detectChanges();
      });
  }
  requestCancellationSave(ngForm: NgForm) {
    this.saveButton = true;
    if (this.clicked_from === 'endorse_guide') {
      this.cancelSubscription = this.curatorService
        .eventCancellationRequest(ngForm)
        .subscribe(
          (success) => {
            if (success.status === 201) {
              this.emptyForm();
              this.saveButton = false;
              this.dialogRef.close();
              this.toastr.showSuccess(
                message_properties.EVENT_CANCELLATION_SUCCESS,
                ''
              );
            } else {
              this.toastr.showError(
                message_properties.EVENT_CANCELLATION_ERROR,
                ''
              );
            }
          },
          (error) => {
            this.toastr.showError(
              message_properties.EVENT_CANCELLATION_ERROR,
              ''
            );
          }
        );
    } else {
      this.cancelSubscription = this.curatorService
        .eventCancellationRequestUpdate(this.eventId, ngForm)
        .subscribe(
          (success) => {
            if (success.status === 201) {
              this.emptyForm();
              this.saveButton = false;
              this.dialogRef.close();
              this.toastr.showSuccess(
                message_properties.EVENT_CANCELLED_SUCCESS,
                ''
              );
            } else if (success.status === 207) {
              this.toastr.showError(success.message, '');
            } else {
              this.toastr.showError(
                message_properties.EVENT_CANCELLED_ERROR,
                ''
              );
            }
          },
          (error) => {
            this.toastr.showError(message_properties.EVENT_CANCELLED_ERROR, '');
          }
        );
    }
  }

  emptyForm() {
    this.cancellationRequestForm.patchValue({
      reason: '',
      penalty_duration: '',
    });
  }

  selectDeduction(value) {
    if (value === 1) {
      this.cancellationRequestForm.patchValue({
        penalty_duration: 0,
      });
    } else {
      this.cancellationRequestForm.patchValue({
        penalty_duration: 1,
      });
    }
  }
  ngOnDestroy(): void {
    if (this.explorerSubscription) {
      this.explorerSubscription.unsubscribe();
    }
    if (this.guideSubscription) {
      this.guideSubscription.unsubscribe();
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.actionSubscription) {
      this.actionSubscription.unsubscribe();
    }
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
  }
}
