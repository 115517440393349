<div class="d-flex pt-15 justify-content-between align-items-center">
  <select
    (change)="showContainer($event.target.value)"
    class="guid-select general_select"
  >
    <option value="1">Meaningful Work Survey Report</option>
    <option value="2">DIY Activity</option>
    <option value="3">Mirror Reflections</option>
  </select>
</div>
<hr />
<div class="card2-style-height-with-tabs-hr-dropdown2">
  <ng-container>
    <div class="">
      <ng-container *ngIf="count > 0">
        <div class="row">
          <div
            class="col-sm-12 col-lg-3 col-md-6"
            *ngFor="let mom of momData;"
          >
            <div class="card archive_card front_card_background_clr">
              <div class="title">
                <div class="text_on_image mt-3 archive_title">
                  <table width="100%">
                    <tr>
                      <td align="left" *ngIf="containerView != 2">
                        {{ mom["survey_taken_on"] | date: "MMM d, y" }}
                      </td>
                      <td align="left" *ngIf="containerView == 2">
                        {{ mom["activity_date"] | date: "MMM d, y" }}
                      </td>
                      <td align="right">
                        <img
                          *ngIf="documentType == 1"
                          src="./assets/media/sidebar/mom.png"
                          style="width: 2rem; height: 2rem"
                        />
                        <img
                          *ngIf="documentType == 2"
                          src="./assets/media/sidebar/diy_black.png"
                          width="25px"
                          height="25px"
                        />
                        <img
                          *ngIf="documentType == 3"
                          src="./assets/media/sidebar/mirror.png"
                          width="25px"
                          height="25px"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
                <hr />
                <div class="archive_title">
                  <small *ngIf="documentType == 1 || documentType == 2">Shared on: {{ mom["shared_on"] | date: "MMM d, y" }}</small>
                  <ng-container *ngIf="documentType == 3">
                    <small>{{mom["mirror_title"]}}</small>
                  </ng-container>
                </div>

                <div class="archive_title">
                  <table align="right">
                    <tr>
                      <td>
                        <button
                          mat-icon-button
                          [matMenuTriggerFor]="beforeMenu"
                          aria-label="Example icon-button with a menu"
                          class="dot-button"
                        >
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #beforeMenu="matMenu" xPosition="before">
                          <button
                            *ngIf="containerView == 1"
                            mat-menu-item
                            (click)="goToSelectedSurveyReport(mom['report_id'], mom['json_report'])"
                          >
                            <span>View Report</span>
                          </button>
                          <button
                            *ngIf="containerView == 2"
                            mat-menu-item
                            (click)="
                              diyDetails(
                                mom['diy_id'], 
                                mom['activity_date'],
                                mom['all_doing_data']['stop_doing'],
                                mom['all_doing_data']['start_doing'],
                                mom['all_doing_data']['continue_doing'],
                                mom['answer_data'])">
                            <span>View DIY</span>
                          </button>
                          <button
                            *ngIf="containerView == 3"
                            mat-menu-item
                            (click)="
                              openMirrorView(
                                mom.reflection.id,
                                mom.reflection.submission_date,
                                mom.reflection['survey_response'][2][
                                  'total_group_response'
                                ],
                                mom.reflection.survey_response[2][
                                  'group_answer'
                                ],
                                mom.reflection.survey_response[2][
                                  'free_format_answer'
                                ],
                                mom.reflection
                              )
                            "
                          >
                            <span>View Reflection</span>
                          </button>

                          <button
                            *ngIf="mentorId != loggedinUserid"
                            mat-menu-item
                            (click)="unshareDoc(mom['share_id'])"
                          >
                            <span>Unshare</span>
                          </button>
                        </mat-menu>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="count == 0">
        <table>
          <tbody>
            <tr>
              <td style="padding: 6px !important">
                <img
                  src="./assets/media/no_data_found.png"
                  style="border-radius: 0px"
                />
              </td>
              <td colspan="2" style="padding: 6px !important">
                No document found!
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </ng-container>
</div>
