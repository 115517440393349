<kt-spinner></kt-spinner>
<mat-card class="full_screen_background_card_height">
  <table class="w-100">
    <tr>
      <td>
        <h1 class="">Search for a Guide</h1>
      </td>
      <td>
        <button class="btn begin_quiz cu_community_date" (click)="myGuide()">
          My Guide
        </button>
      </td>
    </tr>
  </table>

  <ng-container>
    <form [formGroup]="searchMentorForm" (keydown.tab)="$event.stopPropagation()">
      <div class="d-flex justify-content-between align-items-center pt-15">
        <div class="w-50 pos-relative search-style1">
          <input type="textarea" class="my_search_input mb-5 input-width-change" (keyup.enter)="searchGuideForm(1)"
            placeholder="Search" formControlName="q" />

          <div class="clear icons-sets-new">
            <i class="fa fa-filter posi-dots fa-one" aria-hidden="true" title="Filter"
              [matMenuTriggerFor]="menu_search_form"></i>
            <mat-menu #menu_search_form="matMenu" class="search-menunw search-for-guide-menu">
              <div class="mat-menu-search" (click)="$event.stopPropagation()">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="memg-btn-go">
                      <mat-checkbox class="mem-radio-btn" value="2" formControlName="order_by_select"
                        (click)="$event.stopPropagation()">Order By
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="col-sm-6 search-for-guide-menu-select">
                    <select class="mobile-pad mat-form-field-autofill-control memb-select" (click)="$event.stopPropagation()"
                      formControlName="orderBy" [attr.disabled]="
                        !searchMentorForm.get('order_by_select').value
                          ? true
                          : null
                      ">
                      <option disabled selected value style="display:none"></option>
                      <option value="0">Asc</option>
                      <option value="1">Desc</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <input type="hidden" formControlName="q" />
                    <div class="memg-btn-go">
                      <mat-checkbox class="mem-radio-btn" value="2" (click)="$event.stopPropagation()"
                        formControlName="keywords_select">
                        Keyword
                      </mat-checkbox>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <input
                      class="mobile-pad mat-form-field-autofill-control memb-select ng-untouched ng-pristine ng-valid"
                      type="text" (click)="$event.stopPropagation()" [attr.disabled]="!searchMentorForm.get('keywords_select').value
                    ? true
                    : null
                " formControlName="keywords" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="memg-btn-go">
                      <mat-checkbox class="mem-radio-btn" value="2" (click)="$event.stopPropagation()"
                        formControlName="country_select">
                        Country
                      </mat-checkbox>
                    </div>
                  </div>

                  <div class="col-sm-6 col-sm-6 col-sm-6">

                    <kt-search-dropdown
                      class="mat-form-field-autofill-control memb-select ng-untouched ng-pristine ng-valid"
                      (sendbackresponse)="setCountry($event)" [countrySelected]="true" [fieldType]="'country'"
                      [fieldLable]="''" [fieldLableDisplay]="false"
                      [disabled]=" !searchMentorForm.get('country_select').value ? true: null"></kt-search-dropdown>

                    <!-- <input class="input_class" type="text" (click)="$event.stopPropagation()" formControlName="country"
                      [attr.disabled]="
                        !searchMentorForm.get('country_select').value
                          ? true
                          : null
                      " /> -->
                  </div>
                </div>

                <div class="memg-btn-go">
                  <mat-checkbox class="mem-radio-btn" value="1" (click)="$event.stopPropagation()"
                    formControlName="all">
                    Include Unavailable Guides
                  </mat-checkbox>
                </div>

                <div align="right" class="memg-btn-go">
                  <table>
                    <tr>
                      <td>
                        <img src="/assets/media/icon/Refresh@2xWhite.png" class="cursorp img25"
                          (click)="resetForm(); $event.stopPropagation()" />
                      </td>
                      <td>
                        <button class="btn begin_quiz" (click)="searchGuideForm(1)">
                          GO
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </mat-menu>
            <span class="border-linespa"></span>
            <i class="fa fa-search posi-dots fa-two" aria-hidden="true" (click)="searchGuideForm(1)"></i>
          </div>
        </div>
        <div class="w-50 pos-relative">
          <span class="pos-absolute-right-center guid-time">
            Some Guide Services carry a fee
            <span tooltipClass="tooltip_design" theme="light"
              tooltip="Guides offer Explorers a complimentary 10-15 minute get-acquainted call upon request. Once you've chosen a Guide, their time working with you 'live' is charged to your account in 15-minute increments. Some activities, e.g., Guided Reflection or Guided Discussion, have a set fee."
              placement="left" showDelay="500">
              <span class="icon-info">
                <mat-icon class="info_material_icon">info</mat-icon>
              </span>
            </span>
          </span>
        </div>
      </div>
    </form>
    <hr />

    <div class="search-results scroll_class_list card2-style-height-with-search-hr-heading" infinite-scroll
      [infiniteScrollDistance]="modalScrollDistance" [infiniteScrollThrottle]="modalScrollThrottle"
      [scrollWindow]="false" (scrolled)="pushValue($event)">
      <ng-container *ngIf="count > 0">
        <div class="row card-guide-row">
          <div class="col-lg-3 col-md-6 col-sm-12 card-guide" *ngFor="let guide of guides">
            <div class="card archive_card front_card_background_clr" (click)="
                viewProfile(
                  guide.id,
                  guide['member_table'][0].first_name,
                  guide['member_table'][0].last_name,
                  guide['member_table'][0].picture_path ?? './assets/media/default/guide_default_rectangle.png'
                )
              ">
              <div class="img_section_mirror text-center cursorp">
                <img
                  [src]="guide['member_table'][0].picture_path ?? './assets/media/default/guide_default_rectangle.png'"
                  alt="" class="archive_img w-124-pm" />
              </div>
              <div class="title">
                <ul class="dotes-member-ul role cursorp">
                  <li>Guide</li>
                  <li style="background-color: white !important" *ngIf="guide['member_expertise']['available'] == 0">
                    Unavailable
                  </li>
                </ul>
                <div class="member_card_name text-left">
                  <div>
                    <h1 class="only_one_line_show">
                      {{ guide["member_table"][0].first_name }}
                    </h1>
                    <h2 class="fs-18">
                      {{ guide["member_table"][0].last_name }}
                    </h2>
                  </div>

                  <h3>
                    {{
                    guide["member_address"].city != 0
                    ? guide["member_address"].city
                    : "------"
                    }},
                    {{
                    guide["member_address"].country != 0
                    ? guide["member_address"].country
                    : "------"
                    }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="count == 0">
        <table>
          <tbody>
            <tr>
              <td class="p-2">
                <img src="./assets/media/no_data_found.png" class="br-0" />
              </td>
              <td colspan="2" class="p-2">
                There are no results that meet your search criteria.
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
    <!-- </cdk-virtual-scroll-viewport> -->
  </ng-container>
</mat-card>