import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MeaningNetworkService {
  apiUrl = environment.apiUrl;
  private __g_edit_member_id: any;

  private _refreshneeded = new Subject<void>();

  private _refreshneededExperienced = new Subject<void>();
  private _refreshneededEducation = new Subject<void>();
  private _refreshneededVolunteer = new Subject<void>();
  private _refreshneededRequestConnection = new Subject<void>();
  private _refreshneededMeaningLogOrTrasor = new Subject<void>();
  private _sidebarRefresh = new Subject<void>();
  private _refreshbillingfullName = new Subject<void>();
  private _refreshSecondaryAddress = new Subject<void>();

  get refreshSecondaryAddress() {
    return this._refreshSecondaryAddress;
  }
  get sidebarRefresh() {
    return this._sidebarRefresh;
  }
  get refreshneeded() {
    return this._refreshneeded;
  }
  get refreshneededExperienced() {
    return this._refreshneededExperienced;
  }
  get refreshneededEducation() {
    return this._refreshneededEducation;
  }
  get refreshneededVolunteer() {
    return this._refreshneededVolunteer;
  }
  get refreshneededRequestConnection() {
    return this._refreshneededRequestConnection;
  }
  get refreshneededMeaningLogOrTrasor() {
    return this._refreshneededMeaningLogOrTrasor;
  }

  get refreshbillingfullName() {
    return this._refreshbillingfullName;
  }

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  getData(param): Observable<ResDataModal> {
    return this.http.get<ResDataModal>(
      this.apiUrl + 'member/membermetadata?q=' + param + ''
    );
  }
  getAllMentorData() {
    return this.http.get<ResDataModal>(this.apiUrl + 'member/all-mentor-list');
  }

  postProfilePhotoData(ngFormData): Observable<ResDataModal> {
    return this.http.put<ResDataModal>(
      this.apiUrl + 'member/member-profile-photo',
      ngFormData
    );
  }

  postProfileData(ngFormData): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/member-profile-completed',
      ngFormData
    );
  }
  postSecondaryAddressData(ngFormData): Observable<ResDataModal> {
    return this.http
      .post<ResDataModal>(
        this.apiUrl + 'member/member-secondary-address-save',
        ngFormData
      )
      .pipe(
        tap(() => {
          this._refreshSecondaryAddress.next();
        })
      );
  }
  inspiringWorkStory(ngFormData): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/inspiring-work-story',
      ngFormData
    );
  }
  postExperienceData(ngFormData): Observable<ResDataModal> {
    return this.http
      .post<ResDataModal>(
        this.apiUrl + 'member/member-profile-completed',
        ngFormData
      )
      .pipe(
        tap(() => {
          this._refreshneededExperienced.next();
        })
      );
  }
  companyHardDelete(memberId, companyId): Observable<ResDataModal> {
    const data = {
      memberId: memberId,
      companyId: companyId,
    };
    return this.http
      .put<ResDataModal>(
        this.apiUrl + 'member/company/company-hard-delete',
        data
      )
      .pipe(
        tap(() => {
          this._refreshneededExperienced.next();
        })
      );
  }
  updateExperienceData(ngForm): Observable<ResDataModal> {
    return this.http
      .put<ResDataModal>(
        this.apiUrl + 'member/company/member-company-update/',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededExperienced.next();
        })
      );
  }
  postEducationData(ngFormData): Observable<ResDataModal> {
    return this.http
      .post<ResDataModal>(
        this.apiUrl + 'member/member-profile-completed',
        ngFormData
      )
      .pipe(
        tap(() => {
          this._refreshneededEducation.next();
        })
      );
  }
  updateEducationData(ngForm): Observable<ResDataModal> {
    return this.http
      .put<ResDataModal>(
        this.apiUrl + 'member/company/member-company-update/',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededEducation.next();
        })
      );
  }

  postVolunteerData(ngFormData): Observable<ResDataModal> {
    return this.http
      .post<ResDataModal>(
        this.apiUrl + 'member/member-profile-completed',
        ngFormData
      )
      .pipe(
        tap(() => {
          this._refreshneededVolunteer.next();
        })
      );
  }

  updateVolunteerData(ngForm): Observable<ResDataModal> {
    return this.http
      .put<ResDataModal>(
        this.apiUrl + 'member/company/member-company-update/',
        ngForm
      )
      .pipe(
        tap(() => {
          this._refreshneededVolunteer.next();
        })
      );
  }

  postProfileImage(ngFormData): Observable<ResDataModal> {
    return this.http.put<ResDataModal>(
      this.apiUrl + 'member/member-profile-image-update',
      ngFormData
    );
  }

  getAdditionalData(data): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/member-profile-completed-with-additional/',
      data
    );
  }

  getAllExperienceData(data): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/member-profile-completed-with-employee/',
      data
    );
  }

  getAllEducationData(data): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/member-profile-completed-with-education/',
      data
    );
  }

  getAllVolunteeringData(data): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/member-profile-completed-with-volunteering/',
      data
    );
  }

  getMemberCompanyDetail(data): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/member-company-details-with-association-type/',
      data
    );
  }

  // getProfileNameWithAddress(formData) {
  //   const data = {
  //     member: formData // formData = memberid
  //   }
  //   return this.http.post<ResDataModal>(
  //     this.apiUrl + 'member/member-profile-and-address/', data
  //   );
  // }
  getProfileNameWithSecondaryAddress(memberid) {
    return this.http.get<ResDataModal>(
      this.apiUrl + 'member/profile/profile_with_secondary_address/' + memberid
    );
  }
  getProfileDetails(memberprofileid) {
    const data = {
      member_id: memberprofileid,
    };
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/explorer-details-with-primary-address/',
      data
    );
  }
  // getProfileDetailsWithPrimaryAddress(data) {
  //   return this.http.post<ResDataModal>(
  //     this.apiUrl + 'member/profile/profile-with-primary-address',data
  //   );
  // }

  getProfileImage(memberprofileid) {
    return this.http.get<ResDataModal>(
      this.apiUrl + 'member/profile/details/' + memberprofileid
    );
  }

  getNewUserDetailsById(data) {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/member-view-from-admin/',
      data
    );
  }

  setEditMemId(id) {
    this.__g_edit_member_id = id;
  }

  getEditMemId() {
    return this.__g_edit_member_id;
  }

  postWhatICareAbout(ngFormData): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/topic/topics-create/',
      ngFormData
    );
  }
  //#Update by T.K. April 1,2021
  getWhatICareAbout(member): Observable<ResDataModal> {
    return this.http.get<ResDataModal>(
      this.apiUrl + 'member/topic/details/' + member
    );
  }
  //Update by T.K.April 5,2021
  searchExplorer(searchForm) {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/relationship/search-explorer',
      searchForm
    );
  }
  //Update by T.K. April 6,2021
  searchConnections(searchForm) {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/connection/search/0',
      searchForm
    );
  }
  searchConnectionNames(q, member, limit): Observable<ResDataModal> {
    return this.http.get<ResDataModal>(
      this.apiUrl +
        'member/connection/search/' +
        member +
        '?q=' +
        q +
        '' +
        '&&limit=' +
        limit
    );
  }
  requestConnection(request) {
    return this.http
      .post<ResDataModal>(this.apiUrl + 'member/connection/create', request)
      .pipe(
        tap(() => {
          this._refreshneededRequestConnection.next();
        })
      );
  }
  sentReceivedRequests(param, member) {
    return this.http.get<ResDataModal>(
      this.apiUrl +
        'member/connection/sent-received-requests/' +
        member +
        '?param=' +
        param
    );
  }
  updateConnectionRequest(ngForm, id) {
    return this.http
      .put<ResDataModal>(this.apiUrl + 'member/connection/update/' + id, ngForm)
      .pipe(
        tap(() => {
          this._refreshneededRequestConnection.next();
        })
      );
  }
  deleteConnectionRequest(requested_to, requested_from) {
    let data = { requested_to: requested_to, requested_from: requested_from };
    return this.http
      .post<ResDataModal>(this.apiUrl + 'member/connection/delete', data)
      .pipe(
        tap(() => {
          this._refreshneededRequestConnection.next();
        })
      );
  }
  //Update by T.K. Apr 7,2021
  getExplorers(q, member, page) {
    return this.http.get<ResDataModal>(
      this.apiUrl +
        'member/relationship/search-explorer?q=' +
        q +
        '&member=' +
        member +
        '&&page=' +
        page
    );
  }
  //Remove profile pic
  removeProfilePic(data) {
    return this.http.put<ResDataModal>(
      this.apiUrl + 'member/remove-profile-pic',
      data
    );
  }
  //Update by T.K. Apr 8,2021
  getConnection(requested_from, requested_to) {
    return this.http.get<ResDataModal>(
      this.apiUrl +
        'member/connection/details/' +
        requested_from +
        '/' +
        requested_to
    );
  }
  getMessageDetails(requested_from, requested_to) {
    return this.http.get<ResDataModal>(
      this.apiUrl +
        'member/connection/message-details/' +
        requested_from +
        '/' +
        requested_to
    );
  }
  createLog(ngForm) {
    return this.http
      .post<ResDataModal>(this.apiUrl + 'collabcontent/create', ngForm)
      .pipe(
        tap(() => {
          this._refreshneededMeaningLogOrTrasor.next();
        })
      );
  }
  bucketCreate(ngForm) {
    return this.http.post<ResDataModal>(
      this.apiUrl +
        'collabcontent/collabcontentbucket/collab-content-bucket-create',
      ngForm
    );
  }
  guideExpertiseCreate(ngForm) {
    return this.http.post<GuideData>(
      this.apiUrl + 'member/expertise/create',
      ngForm
    );
  }
  guideHighlightCreate(ngForm) {
    return this.http.post<GuideData>(
      this.apiUrl + 'member/expertise/guide-highlights',
      ngForm
    );
  }
  mentoringExperienceCreate(ngForm) {
    return this.http.post<GuideData>(
      this.apiUrl + 'member/expertise/mentoring-experience',
      ngForm
    );
  }
  guideExpertiseDetails(member) {
    return this.http.get<GuideData>(
      this.apiUrl + 'member/expertise/details/' + member
    );
  }
  guideVideoUpdate(ngForm, loggedInMember) {
    return this.http.put<GuideData>(
      this.apiUrl + 'member/expertise/video-path-update/' + loggedInMember,
      ngForm
    );
  }
  getTagListForAutoSelect() {
    return this.http.get<NodeData>(
      this.apiUrl + 'common/tag/tag-list-for-autoselect/'
    );
  }

  memberTimeZoneUpdate(ngForm) {
    return this.http.put<MemberTimeZoneData>(
      this.apiUrl + 'member/member-time-zone-change/',
      ngForm
    );
  }
  moodAbout(ngFormData): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'member/mood/create', ngFormData);
  }
  moodCreate(ngFormData): Observable<MoodModal> {
    return this.http.post<MoodModal>(
      this.apiUrl + 'member/mood/create',
      ngFormData
    );
  }
  postDoNotPopUp(data): Observable<DoNotModal> {
    return this.http
      .put<DoNotModal>(`${this.apiUrl}member/config/update`, data)
      .pipe(
        tap(() => {
          this._sidebarRefresh.next();
        })
      );
  }
  getDoNotPopUp(member): Observable<DoNotModal> {
    return this.http.get<DoNotModal>(
      `${this.apiUrl}member/config/details/${member}`
    );
  }
  getTagListForWhatMatter() {
    return this.http.get<NodeData>(
      this.apiUrl + 'common/tag/tag-list-for-whatmatter/'
    );
  }
  postwelcomeFinish(data): Observable<WelcomeModal> {
    return this.http.put<WelcomeModal>(
      `${this.apiUrl}member/config/update`,
      data
    );
  }
  getEducationInstitute(name) {
    const data = {
      name: name,
    };
    return this.http
      .post<EducationModal>(
        this.apiUrl + 'company/get-list-of-institute/',
        data
      )
      .pipe(
        map((data) => {
          return { results: data.results, count: data.count };
        })
      );
  }

  billingFullNameUpdate(data): Observable<ResDataModal> {
    return this.http
      .put<ResDataModal>(
        `${this.apiUrl}member/member-billing-name-update/`,
        data
      )
      .pipe(
        tap(() => {
          this._refreshbillingfullName.next();
        })
      );
  }
  searchConnectionsPostmethod(searchForm) {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'member/connection/search-all-connection-post',
      searchForm
    );
  }

  postAllTreasureLogsOfUser(data): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(
      this.apiUrl + 'collabcontent/collabcontentbucket/treasure-logs',
      data
    );
  }
}
export interface EducationModal {
  id: number;
  name: string;
  results: [];
  Institute: number;
  count: number;
}
export interface WelcomeModal {
  member_id: number;
  onboarding_complete: boolean;
}
export interface DoNotModal {
  member_id: number;
  check_overview: boolean;
  config_parameter: number;
  show_circle_overview: boolean;
  show_community_overview: boolean;
  show_constellation_overview: boolean;
  show_guide_overview: boolean;
  show_mirror_overview: boolean;
  show_mom_overview: boolean;
  show_network_overview: boolean;
  show_diy_overview: boolean;
  show_portrait_overview: boolean;
  show_explorer_overview: boolean;
}
export interface MoodModal {
  visibility_type: number;
  member_mood: number;
  mood_reason: string;
  member_mood_message: string;
  member: number;
}
export interface MemberTimeZoneData {
  id: string;
  logged_in_member_id: number;
  time_zone_id: number;
  status: number;
  current_time_zone: string;
}
export interface GuideData {
  id: string;
  visibility_type: number;
  subject_classification: number;
  expertise_level: number;
  certifying_institution: string;
  company_id: number;
  member: number;
  description: string;
  highlight: string;
  available: number;
  video_path: string;
  no_of_years: number;
  start_date: any;
  end_date: any;
  creation_data: any;
  count: number;
  status: number;
  results: [];
  message: string;
}
export interface ResDataModal {
  count: number;
  status: number;
  results: [];
  visibility_type: number;
  association_type: number;
  association_status: number;
  name: string;
  title: any;
  location: any;
  workprofile: any;
  start_date: any;
  end_date: any;
  company: number;
  first_name: any;
  last_name: any;
  property_value: any;
  user: {};
  city: string;
  state: string;
  country: string;
  fname: string;
  lname: string;
  picture_path: string;
  whatcareabout: string;
  message: string;
  address_response: {};
  relationshion_type: number;
  relating_member_id: number;
  email: string;
  mentor_indicator: string;
  curator_indicator: string;
  admin_indicator: string;
  program_director_indicator: string;
  member_table: [];
  address_details: any;
  joined_date: any;
  member_additional: [];
  member_address_table: any;
  connected_member_address: [];
  member_connect_count: number;
  connection_status: number;
  resonate_indicator: boolean;
  inspired_indicator: boolean;
  care_indicator: boolean;
  career_path_indicator: boolean;
  other_indicator: boolean;
  code: number;
  member_address: [];
  member_details_table: [];
  country_id: string;
  line1: string;
  line2: string;
  postal_code: string;
  city_id: string;
  timezone_str: string;
  cityId: string;
  countryId: string;
  billing_full_name: string;
  address_verify_indicator: boolean;
  state_code: string;
  full_name: string;
  profile_path: string;
  member_status: number;
}
export interface NodeData {
  parent_id: number;
  tenant_id: number;
  context_id: number;
  tag: string;
  tag_type: number;
  tag_status: number;
  description: string;
  image_path: string;
  user_generated_indicator: number;
  added_to_topics_indicator: number;
  mapped_for: number;
  mapped_object_id: number;
  have_parent: boolean;
  parent_node_details: {};
  results: [];
  count: number;
  status: number;
  tag_mapped_with_map_table_count: number;
  tag_mapped_type_based_count: {};
}
