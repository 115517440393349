import { Router } from '@angular/router';
import { NotificationService } from '../../../../service/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  NgForm,
} from '@angular/forms';
import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { MentorService } from '../../../../service/mentor.service';
import { message_properties } from '../../../../../environments/message_properties';
import { AccountsService } from '../../../../service/accounts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-discontinue-guide',
  templateUrl: './discontinue-guide.component.html',
  styleUrls: ['./discontinue-guide.component.scss'],
})
export class DiscontinueGuideComponent implements OnInit, OnDestroy {
  terminationForm: UntypedFormGroup;
  mentorId: number;
  fullName: string;
  relationshipId: number;
  loggedInUserId: number;
  terminationSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<DiscontinueGuideComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private mentorService: MentorService,
    private toastr: NotificationService,
    private router: Router,
    private accountService: AccountsService
  ) {
    this.mentorId = data.mentorId;
    this.fullName = data.fullName;
    this.relationshipId = data.relationshipId;
  }

  ngOnInit(): void {
    this.loggedInUserId = this.accountService.muser;
    this.terminationForm = this.formBuilder.group({
      remarks: new UntypedFormControl('', [Validators.required]),
      end_date: this.formatDate(''),
      member: this.loggedInUserId,
      active: 0,
      relating_member: this.relationshipId,
      mentorId: this.mentorId,
    });
  }

  formatDate(date) {
    var d;
    if (!date) {
      d = new Date();
    }
    var month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  termination(ngForm: NgForm) {
    this.terminationSubscription = this.mentorService
      .discontinueGuide(ngForm, this.relationshipId, this.loggedInUserId)
      .subscribe(
        (success) => {
          this.dialogRef.close();
          this.router.navigate(['discover', 'my-guides']);
          this.toastr.showSuccess(
            this.fullName + message_properties.DISCONTINEW_GUIDE_SUCCESS,
            ''
          );
        },
        (error) =>
          this.toastr.showError(
            `${message_properties.DISCONTINEW_GUIDE_ERROR_STRING1}${this.fullName}${message_properties.DISCONTINEW_GUIDE_ERROR_STRING2}`,
            ''
          )
      );
  }
  ngOnDestroy(): void {
    if (this.terminationSubscription) {
      this.terminationSubscription.unsubscribe();
    }
  }
}
